import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//import {LoginInfoComponent} from "./login-info/login-info.component";
import {RouterModule} from "@angular/router";
//import {SelectItemComponent} from "./select-item.component";

//import {ListSelectProductComponent} from "./list-select-product.component";
//import {ListSelectPeriodComponent} from "./list-select-period.component";
//import {ListSelectUserComponent} from "./list-select-user.component";
//import {ListSelectStateComponent} from "./list-select-state.component";
//import {SelectButtonComponent} from "./select-button.component";

//import {LogoutComponent} from "./logout/logout.component";
//import {ListSelectComponent} from "./list-select/list-select.component";

//import {ListPagerComponent} from "./list-pager/list-pager.component"; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!

//----------------------------------------------------------------------------

//import { PaginationModule  } from 'ngx-bootstrap';
//import {PeriodComponent} from "./period/period.component";
//import {PeriodAsideComponent} from "./period-aside/period-aside.component";


import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
/*
import {PeriodMonthComponent} from "./period/period-month.component";

import {EhisDateComponent} from "./ehis-date.component";
import {EhisPeriodComponent} from "./ehis_period.component";
import {ListSelectHeaderComponent} from "./list-select-header.component";
import {NumberPipe} from "./number.pipe";
import {UserAccountComponent} from "./user-account.component";
import {ToCcBccComponent} from "./to_cc_bcc.component";
import {CcBccComponent} from "./cc_bcc.component";
import {KeysPipe} from "./keys.pipe";
import {ItemInputComponent} from "./item-input.component";

// ehis

import {ButtonsModule, TabsModule, TypeaheadModule} from 'ngx-bootstrap';
import {TextMaskModule} from "angular2-text-mask";
import { Ng2UploaderModule } from 'ng2-uploader';
import {LaddaModule} from "angular2-ladda";
*/
import {WidgetDetail} from "./widget-detail/widget-detail.component";
import {SmartadminWidgetsModule} from "../shared/widgets/smartadmin-widgets.module";
import {AuthModule} from "../auth/auth.module";
import {WidgetList} from "./list/widget-list.component";
import {ControlModule} from "../controls/controls.module";
import {WidgetTable} from "./table/widget-table.component";
import {WidgetPivot} from "./pivot/widget-pivot.component";


import {DialogModule} from 'primeng/dialog'
import {ButtonModule} from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {DragDropModule} from 'primeng/dragdrop';
import {CardModule} from 'primeng/card';
import {WidgetBase} from "./widget-base";
//import {Loader} from "./loader/loader.component";
import {NamePipe} from "./pipes/name.pipe";
import {EnabledPipe} from "./pipes/enabled.pipe";
import {WidgetWell} from "./well/widget-well.component";

import {WidgetReport} from "./report/widget-report.component";
import {WidgetModal} from "./modal/widget-modal.component";
import {TimeLocalPipe} from "./pipes/time-local.pipe";
import {DateLocalPipe} from "./pipes/date-local.pipe";
import {AttachmentComponent} from "./attachment.component";
import {DropzoneModule} from "../shared/forms/dropzone/dropzone.module";
import {IndexPipe} from "./pipes/index.pipe";
import {CliniciansPipe} from "./pipes/clinicians.pipe";


@NgModule({
  imports: [
      CommonModule,
      RouterModule,
      /*
      FormsModule,
      ReactiveFormsModule,


      ButtonsModule,
      TabsModule,
      TypeaheadModule,
      TextMaskModule,
      Ng2UploaderModule,
      LaddaModule,


      PaginationModule.forRoot(),
*/
 //     SmartadminModule,
  //    SmartadminModule,

      AuthModule,
      SmartadminInputModule,

      SmartadminWidgetsModule,

      ControlModule, // list-select list-select-header loader

      FormsModule,   // [(ngModel)] =


      DialogModule,
      ButtonModule,
      PanelModule,
      ListboxModule,
      DragDropModule,
      CardModule,

      DropzoneModule, // for Attachment
  ],
  declarations: [
      WidgetDetail,
      WidgetList,
      WidgetTable,
      WidgetPivot,
      WidgetBase,
      WidgetWell,
   //   Loader,

      NamePipe,
      EnabledPipe,
      TimeLocalPipe,
      DateLocalPipe,

      WidgetReport,

      WidgetModal,



      AttachmentComponent,

      IndexPipe,
      CliniciansPipe,


  ],
  exports: [
    WidgetDetail,
    WidgetList,
    WidgetTable,
    WidgetPivot,
    WidgetWell,

      NamePipe,
      EnabledPipe,
      TimeLocalPipe,
      DateLocalPipe,

      WidgetReport,

      WidgetModal,

      AttachmentComponent,

      IndexPipe,
      CliniciansPipe
  ]
})
export class WidgetModule{}
