import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;


//import {ChartTemplateService} from '../../service/chart-template.service.ts'
import {RStoreService} from "../../rstore/rstore.service";

import {BaseList}       from '../../auth/base-list'
import {ChartTemplateService} from "../../service/chart-template.service";

@Component({
  selector: 'chart-template-select',

  templateUrl: './chart-template-select.template.html',

  encapsulation: ViewEncapsulation.None,
 // host: {
//    class: 'error-page app'
//  },
})
export class ChartTemplateSelect extends BaseList {

//  @Output() onSelect = new EventEmitter();

  $el: any;

//  selectItem: any = {};

//  radio2: string = 'Left';


  dateFormat: string = 'medium'; // 'mediumDate';

  constructor(el: ElementRef,private chartTemplateService: ChartTemplateService) {

    super(el,chartTemplateService);
    this.$el =  jQuery(el.nativeElement);
  }

/*
  clickRadio2(val:string) {

    if (val != this.radio2) {

      this.radio2 = val;

      switch (val) {
        case 'Left':
        {
          delete this.paramItems.chart_template_user_id;
          break;
        }
        case 'Middle':
        {
          this.paramItems.chart_template_user_id = this.rstoreService.authService.user_id();
          break;
        }
        case 'Right':
        {
          this.paramItems.chart_template_user_id = -1;
          break;
        }
        default:
        {

        }
      }
      this.updateSearchControl();
    }
  }

  addForm(): void {
    this.openForm({});
  }

  owner(item) {
    return this.rstoreService.authService.isUser(item.chart_template_user_id);
  }

  onReadItems(result) {
    for (let elem of result.data) {
      elem.free = this.rstoreService.authService.sqlToBoolean(elem.free);
      elem._price = elem.free ? 'Free' : elem.price;
    }
  }

  openForm(item: any): void {
    this.onSelect.emit(item);
  }

*/
    @ViewChild('modalForm') modalForm;


    _display = false;

    @Input('display') set display(val) {
        // if (val!=this._display) {
        this._display = val;
        if (this._display) {
            this.refresh();
            this.modalForm.open()
        }
        else {
            this.modalForm.close()
        }
        //  }
    }

    get display() {
        return this._display;
    }

    @Output('onSelect') onSelect = new EventEmitter()

    clickItem(item) {

        this.onSelect.emit(item)
        this.modalForm.close();

    }
    @Output('onCancel') onCancel = new EventEmitter()

    closeForm() {
        this.onSelect.emit(null)
        this.modalForm.close();
        //this.onCancel.emit(null)

    }
    //----------------------------------------------------------------------------------------------

    show() {
        this.modalForm.open()
    }


    iconClass(item) {
        return 'fa fa-circle-thin fa-lg'; //fa-user_md fa-eye
    }

    owner(item) {
        return this.baseService.authService.isUser(item.chart_template_user_id);
    }

    author(item) {
        return this.baseService.authService.isUser(item.user_id);
    }

    //******************************************************************************

    @ViewChild('chart_template_select_popup') chart_template_select_popup

    okPopup(item) {
        this.chart_template_select_popup.close();
        this.onSelect.emit(item)
    }

    show_popup() {   // replace show()
        this.chart_template_select_popup.open()
    }
}
