import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timex'
})

export class TimexPipe implements PipeTransform {
    transform(value: any, format: string = ""): string {

        if (!value || value==="") return "";

        return value.toString('HH:mm')   // DatePilot.Date

        console.log(value)
      //  let v = value.replace('T',' ')
        return typeof value;
//        return moment(value).format(format);

//        let value1 = value.split(' ')[0];  //date only
//        if (!value1 || value1==="") return "";

//        var fmt ='MMM DD, YYYY';
        var fmt ='HH:mm';
        return moment(value).format(fmt);
    }
}