import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'name'
})

export class NamePipe implements PipeTransform {
    transform(value: any, format: string = ""): string {

        if (!value || value==="" || value==undefined) return "";
//        return moment(value).format(format);

//        let value1 = value.split(' ')[0];  //date only
//        if (!value1 || value1==="") return "";

//        var fmt ='MMM DD, YYYY';
        return value.name
    }
}