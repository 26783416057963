import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;


import {ChartTemplateService} from '../../service/chart-template.service'

//import {RStoreService} from "../../rstore/rstore.service";

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'chart-template-list',
  templateUrl: './chart-template-list.template.html',

//  providers: [RStoreService],

 // encapsulation: ViewEncapsulation.None,
 // host: {
  //  class: 'error-page app'
 // },
})
export class ChartTemplateList extends BaseList {

  $el: any;

//  selectItem: any = {};

/*
  dateFormat: string = 'medium'; // 'mediumDate';

  get tagName () {
    return this.elem.nativeElement.tagName.toLowerCase();
  }
*/

  constructor(el: ElementRef,private chartTemplateService: ChartTemplateService) {

    super(el,chartTemplateService);
 //   constructor(el: ElementRef,private rstoreService: RStoreService) {

//      super(el,rstoreService);
    this.$el =  jQuery(el.nativeElement);

    this.paramItems.order_type = 1;
    this.paramItems.chart_template_user_id = 0;
  }

  owner(item) {
    return this.baseService.authService.isUser(item.chart_template_user_id);
  }

  author(item) {
    return this.baseService.authService.isUser(item.user_id);
  }

  onReadItems(result) {
    for (let elem of result.data) {
      elem.free = this.baseService.authService.sqlToBoolean(elem.free);
      elem._price = elem.free ? 'Free' : elem.price;
    }
  }

  openForm(item: any): void {
    //console.log('item.id',item.id);
    //this.onOpenForm.emit(item);
  }

  addForm(): void {
    this.openForm({});
  }

  addItem() {
    if (this.baseService.authService.isguest()) {
      this.baseService.authService.showMessage('Only health providers can create templates!','Sorry');
    }
    else {
      this.baseService.authService.navigate(['/app','chart_template','0']);
    }
  }


    clickAddFromRStore() {
        this.baseService.authService.navigate(['/rstore'],null);

    }
}
