import {Directive, OnInit, ElementRef, Input, Output, EventEmitter} from '@angular/core';

declare var $: any;

export function normalizeTime(valN) {
    let res = ''
    if (valN) {

        let val = valN.toString()

        if (val.trim().length == 0) {
            res = ''
        }
        else {

            let arr = val.split(':')

            let hs = +arr[0].trim()
            if (hs < 0) hs = 0;

            if (hs==24) {
                return '24:00'
            }
            if (hs > 23) hs = 23;
            let HH = ("00" + hs).substr(-2);

            let ms = (arr.length > 1) ? +arr[1].trim() : 0
            if (ms < 0) ms = 0;
            if (ms > 59) ms = 59;

            let mm = '00'
            if ((arr.length > 1) && arr[1].length == 1) {
                mm = '0'+arr[1][0]
            }
            else {
                mm = (ms + "00").substr(0, 2);
            }

            res = HH + ':' + mm

        }
    }
    else {
        return '00:00'
    }
    return res

}

function isNumberStr(val) {
    return /^\d+$/.test(val);
}

export function isNormalTime(valN) {
    let res = true;
    if (valN) {

        let val = valN.toString()

     //   console.log('val',val,val.trim())

        if (val.trim().length > 0) {

            let arr = val.split(':')

            let hs = +arr[0].trim()

            if (!isNumberStr(arr[0].trim())) res = false;
            if (hs < 0) res = false;
            if (hs > 24) res = false;  // 23

            let ms = (arr.length > 1) ? +arr[1].trim() : 0
      //      if (arr[1].length == 1) {
      //          if (ms < 0 || ms > 5) return false;
      //      }
      //      else {
                if (ms < 0) res = false;
                if (ms > 59) res = false;
       //     }

        }
    }
    return res

}

@Directive({
  selector: '[timeSelect]'
})
export class TimeSelect implements OnInit {

  @Input() saUiSpinner: any;
  @Output('timeSelected') timeSelected = new EventEmitter();

  constructor(private el: ElementRef) { }

  ngOnInit(){
    /*
    let options;
    
    if(this.saUiSpinner == 'decimal'){
      options = {
        step: 0.01,
        numberFormat: "n"
      };
    } else if (this.saUiSpinner == 'currency') {
      options = {
        min: 5,
        max: 2500,
        step: 25,
        start: 1000,
        numberFormat: "C"
      };
    }

    $(this.el.nativeElement).spinner((options || this.saUiSpinner) || {} );
    */
    
  }

  /*

    normalizeTime(val) {

        let res = ''
        if (val.trim().length == 0) {
            res = ''
        }
        else {

            let arr = val.split(':')

            let hs = +arr[0].trim()
            if (hs < 0) hs = 0;
            if (hs > 23) hs = 23;
            let HH = ("00" + hs).substr(-2);

            let ms = (arr.length > 1) ? +arr[1].trim() : 0
            if (ms < 0) ms = 0;
            if (ms > 59) ms = 59;

            let mm = ("00" + ms).substr(-2);

            res = HH + ':' + mm

        }

        return res
    }
*/

    updateVal() {

        let $elem = $(this.el.nativeElement)
        let val = $elem.val()

        if (isNormalTime(val)) {

            let res = normalizeTime(val)

            // this.el.nativeElement.value = res
            $elem.val(res)
            this.el.nativeElement.dispatchEvent(new Event('input')); // https://github.com/text-mask/text-mask/issues/696

            this.timeSelected.emit(res)
        }
        else {
            this.timeSelected.emit('')
            //console.log('null')

        }

        this.updateColorText()
   }

   updateColorText() {
       let $elem = $(this.el.nativeElement)

       let val = $elem.val()

       let color = isNormalTime(val) ? 'black' : 'red';
       $(this.el.nativeElement).css('color', color)
  }


    ngAfterViewInit() {

        this.updateColorText();

        let $elem = $(this.el.nativeElement) // .find('input.select-time')

        $elem.keypress(e=>{

            if(e.which == 13) {
              this.updateVal()
            }

            let ch = e.which
            let val = $elem.val()
            if ((ch !=8) && (ch!=46) && (val.length > 5)) return false


            //console.log('keypress',ch,e)

            if (ch==59) {
                /*
                let $container = $(this.el.nativeElement).find('typeahead-container.open')
                if ($container.length) {
                    console.log('container')
                    setTimeout(() => {

                        var event = $.Event( "keypress", { which: 58 ,keyCode: 58, charCode:58, key: ':'} );
                        //        e.preventDefault()
                        //       e.stopPropagation()
                        $container.trigger(event);

                    },100);

                }

                     e.preventDefault()
                e.stopPropagation()

                str = $elem.val()
                $elem.val(str+':');
                     $elem.trigger('keypress', { which: 58,keyCode:58,key:":"})


                setTimeout(() => {

                    var event = $.Event( "keypress", { which: 58 ,keyCode: 58, charCode:58, key: ':'} );
                    $elem.trigger(event);

                },100);


               return false


                ch = 58
                e.key = ':'
                //e.charCode = 58
                e.which = 58
                e.keyCode = 58




                if (e.originalEvent) {
                    e.originalEvent.key = ':'
                    e.originalEvent.charCode = 58
                    e.originalEvent.which = 58
                    e.originalEvent.keyCode = 58

                }




               var event = $.Event('keypress');
               event.which = 58; // Character 'A'
               event.key = 58
               event.charCode = 58
               event.keyCode = 58

               e.preventDefault()
               e.stopPropagation()
               $elem.trigger(event);
               return false


               e.key = 58
               e.which = 58
               e.charCode = 58
               e.keyCode = 58
               console.log(e)
               //var str = $elem.val();
               //e.preventDefault()
               //$elem.val(str + ':');
               //return false
               */
            }

            if (ch < 48 || ch > 58) {
                // e.which = 58
                return false
            }

            // if (ch!=)

            if (ch==58) {
                var str = $elem.val();
                if (str.indexOf(':') !== -1) return false
            }
       })

        $elem.blur(e=>{
            this.updateVal()
         })

        $elem.change(()=> {   // ???
            this.updateColorText();
        })

        $elem.on('input',()=> {
            this.updateColorText()
        })

    }

}
