import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//import { Router } from '@angular/router';
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing'
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';

// Core providers
import {CoreModule} from "./core/core.module";
import {SmartadminLayoutModule} from "./shared/layout/layout.module";


// SP++
import {AuthModule}      from "./auth/auth.module"

import {AuthService}      from "./auth/auth.service"
import {SpinnerService}   from './auth/spinner/spinner.service';
//import {SpinnerComponent} from "./auth/spinner/spinner.component";
import {AuthGuard}        from "./auth/auth-guard.service";
import {AdminGuard}   from "./auth/admin-guard.service"
import {ShippingModule} from "./shipping/shipping.module";
import {PaymentModule} from "./payment/payment.module";
import {SubscriptionModule} from "./subscription/subscription.module";
import {InvoiceModule} from "./invoice/invoice.module";
import {InvoiceItemModule} from "./invoice-item/invoice-item.module";
import {SettingModule} from "./setting/setting.module"
import {MailboxModule} from "./mailbox/mailbox.module"
import {WarningModule} from "./warning/warning.module";
import {BillingModule} from "./billing/billing.module";

//import {ProductModule} from "./product/product.module";


import {ProductUserModule} from "./product-user/product-user.module";


//import {PostModule} from "./post/post.module"
import {MessageModule} from "./message/message.module"

//import {DiagnosisModule} from "./diagnosis/diagnosis.module"
//import {ProfessionalModule} from "./professional/professional.module"

import {AddressModule} from "./address/address.module"
import {DashboardModule} from "./dashboard/dashboard.module";

import {SignupModule} from "./signup/signup.module";
import {CsvService} from "./auth/csv/csv.service";
import {ControlModule} from "./controls/controls.module";
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TaskTodoService } from './task-todo/task-todo.service';
import { WidgetModule } from "./../app/widget/widget.module";
// SP--

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
//    SpinnerComponent  // SP++
  ],
  imports: [ // import Angular's modules
  WidgetModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,

    CoreModule,
    SmartadminLayoutModule,


    AuthModule,

//    ModalModule,                  // SP++

//    Router,
    routing,

    ShippingModule,
    SubscriptionModule,
    InvoiceModule,
    InvoiceItemModule,
    SettingModule,
    MailboxModule,
    PaymentModule,
      //ProductModule,

    WarningModule,
    ProductUserModule,

  //  PostModule,
    MessageModule,

 //   DiagnosisModule,
//    ProfessionalModule,

    AddressModule,
  //  UserModule,

      DashboardModule, SignupModule,

      ControlModule,
      BillingModule,
      StoreModule.forRoot(reducers, { metaReducers }),
      !environment.production ? StoreDevtoolsModule.instrument() : []
      //EffectsModule.forRoot([UserEffects])
  ],
  exports: [
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    APP_PROVIDERS,

    AuthService,
    SpinnerService,
    AuthGuard,
    AdminGuard,
    TaskTodoService,
    CsvService
  ],
  schemas: []
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}
}

