import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import {AppointmentDialog} from "./appointemnt-dialog/appointment-dialog.component";
import {ControlModule} from "../controls/controls.module";
//import {WidgetModule} from "../widget/widget.module";
import {TypeaheadModule} from "ngx-bootstrap";
import {EhisControlModule} from "../ehis-control/ehis-control.module";
import {SessionCancellationReasonPopup} from "./popup/session-cancellation-reason-popup.component";
import {SessionCancellationReasonService} from "../session-cancellation-reason/session-cancellation-reason.service";
import {AvailableHoursDialog} from "./available-hours-dialog/available-hours-dialog.component";
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
//import {SessionCancellationReasonModule} from "../session-cancellation-reason/session-cancellation-reason.module";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,

        ControlModule,

        TypeaheadModule.forRoot(),


        SmartadminInputModule, //  [saUiDatepicker] for AvailableHoursDialog

  //      WidgetModule,
  //      SessionCancellationReasonModule

    ],
    declarations: [
        AppointmentDialog,
        AvailableHoursDialog,
        SessionCancellationReasonPopup

    ],
    exports: [
        AppointmentDialog,
        AvailableHoursDialog
    ],
    providers: [
        SessionCancellationReasonService
    ]
})
export class DialogsModule {
}

