const dollarSign = '$'
const emptyString = ''
const comma = ','
const period = '.'
const minus = '-'
const minusRegExp = /-/
const nonDigitsRegExp = /\D+/g
const number = 'number'
const digitRegExp = /\d/
const caretTrap = '[]'



function createNumberMask(config) {
  /*

   prefix : dollarSign,
   suffix : emptyString,
   includeThousandsSeparator: true,
   thousandsSeparatorSymbol: comma,
   allowDecimal: false,
   decimalSymbol: period,
   decimalLimit: 2,
   requireDecimal: false,
   allowNegative : false
   }) {
   */
  const prefix = config.prefix ? config.prefix : emptyString; // dollarSign;
  const suffix = config.suffix ? config.suffix : emptyString;
  const includeThousandsSeparator = config.includeThousandsSeparator ? config.includeThousandsSeparator : false; // true;
  const thousandsSeparatorSymbol = config.thousandsSeparatorSymbol ? config.thousandsSeparatorSymbol : ' '; //comma;
  const allowDecimal = config.allowDecimal ? config.allowDecimal : false;
  const decimalSymbol = config.decimalSymbol ? config.decimalSymbol : period;
  const decimalLimit = config.decimalLimit ? config.decimalLimit : 2;
  const requireDecimal = config.requireDecimal ? config.requireDecimal : false;
  const allowNegative = config.allowNegative ? config.allowNegative : false;


  const prefixLength = prefix.length

  function numberMask(rawValue) {
    const rawValueLength = rawValue.length

    if (
      rawValue === emptyString ||
      (rawValue[0] === prefix[0] && rawValueLength === 1)
    ) {
      return prefix.split(emptyString).concat([digitRegExp]).concat(suffix.split(emptyString))
    }

    const suffixLength = suffix.length
    var v

    if (suffixLength > 0 && rawValue.lastIndexOf(suffix)=== (rawValueLength-suffixLength)) {
      //console.log(rawValue)

      v = rawValue.slice(0,rawValueLength-suffixLength)
    }
    else {
      v = rawValue;
    }
    const vLength = v.length

    const indexOfLastDecimal = v.lastIndexOf(decimalSymbol)
    const hasDecimal = indexOfLastDecimal !== -1
    const isNegative = (v[0] === minus) && allowNegative

    var integer;
    var fraction;
    var mask;

    if (hasDecimal && (allowDecimal || requireDecimal)) {
      integer = v.slice(0, indexOfLastDecimal)

      fraction = v.slice(indexOfLastDecimal + 1, vLength)
      fraction = convertToMask(fraction.replace(nonDigitsRegExp, emptyString))
    } else {
      integer = v
    }

    integer = integer.replace(nonDigitsRegExp, emptyString)

    integer = (includeThousandsSeparator) ? addThousandsSeparator(integer, thousandsSeparatorSymbol) : integer

    mask = convertToMask(integer)

    if ((hasDecimal && allowDecimal) || requireDecimal === true) {
      if (v[indexOfLastDecimal - 1] !== decimalSymbol) {
        mask.push(caretTrap)
      }

      mask.push(decimalSymbol, caretTrap)

      if (fraction) {
        if (typeof decimalLimit === number) {
          fraction = fraction.slice(0, decimalLimit)
        }

        mask = mask.concat(fraction)
      } else if (requireDecimal === true) {
        for (var i = 0; i < decimalLimit; i++) {
          mask.push(digitRegExp)
        }
      }
    }

    if (prefixLength > 0) {
      mask = prefix.split(emptyString).concat(mask)
    }

    if (isNegative) {
      // If user is entering a negative number, add a mask placeholder spot to attract the caret to it.
      if (mask.length === prefixLength) {
        mask.push(digitRegExp)
      }

      mask = [minusRegExp].concat(mask)
    }

    if (suffixLength > 0) {
      mask = mask.concat(suffix.split(emptyString))
    }

    return mask
  }

  numberMask.instanceOf = 'createNumberMask'

  return numberMask
}

function convertToMask(strNumber) {
  return strNumber
    .split(emptyString)
    //.map((char) => digitRegExp.test(char) ? digitRegExp : char)
    .map(function(char){ return digitRegExp.test(char) ? digitRegExp : char;} )
}

// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n, thousandsSeparatorSymbol) {
  return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol)
}

export {createNumberMask};