
import {Injectable} from '@angular/core';
//import {Http, Response} from '@angular/http';
//import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
//import {Observable}     from 'rxjs';
import 'rxjs/Rx'  //country.service

//import {API_URL} from '../app.env';

import {AuthService} from '../auth/auth.service';
import {BaseService} from '../auth/base.service'
//import {CookieService} from 'angular2-cookie/core';

//import {DOCUMENT} from '@angular/platform-browser';


@Injectable()
export class AddressService extends BaseService {

    icon = "fa fa-users"

    constructor(public authService: AuthService) {
        super(authService, "/api/patient");

        this.path = '/addressbook';  // not default

        this.booleans = ['_access'];
        this.dates = ['date_of_birth'];

    }

    accessUrl(patient_id) {
        let url = `${this.baseURL}/${patient_id}/access`;
        return url;
    }

    setAccess(patient_id, access, data = '') {

        let url =  this.accessUrl(patient_id) +`?access=${access}`;

        let body = {data: data};
        return this.authService.post(url, body)
    }


    getAccess(patient_id) {
        let url =  this.accessUrl(patient_id)// `${this.baseURL}/${patient_id}/access?access=${access}`;
        return this.authService.get(url)

    }

    patient_access(patient_id) {
        let url = `${this.baseURL}/patient_access/${patient_id}`;
        return this.authService.get(url)

    }

    getPatientData(patient_id) { // Get patient Data when pass patient id
        let url = `${this.baseURL}/${patient_id}`;
        return this.authService.get(url);
    }
}