import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'item-patient',
    template: `

        <item-input class="legend item-col-md-7"  [label]="item._title"></item-input>

        <item-input class="input item-col-md-7"  name="first_name"  [(ngModel)]="item.first_name" label="First name"
                    required maxlenght="50">
        </item-input>
        <item-input class="input item-col-md-7"  name="middle_name"  [(ngModel)]="item.middle_name" label="Middle name"
                    maxlenght="50">
        </item-input>
        <item-input class="input item-col-md-7"  name="last_name"  [(ngModel)]="item.last_name" label="Last name"
                    required maxlenght="50">
        </item-input>
        <item-input class="date item-col-md-7"  name="date_of_birth"  [(ngModel)]="item.date_of_birth" label="Date of birth"
                    required >
        </item-input>
        <item-input class="select item-col-md-7"  name="sex"  [(ngModel)]="item.sex" label="Chromosome"
                    required
                    [index]="[{ id:1, name: 'XY'},{ id:2,name:'XX'}, { id:1, name: 'XXX'},{ id:2,name:'XXY'}, { id:1, name: 'XXXX'},{ id:2,name:'XXXY'}]">
        </item-input>

        <item-input class="legend item-col-md-7"  label=""></item-input>

        <item-input class="input item-col-md-7"  name="street1"  [(ngModel)]="item.street1" label="Street address 1"
                    required >
        </item-input>
        <item-input class="input item-col-md-7"  name="street2"  [(ngModel)]="item.street2" label="Street address 2"
        >
        </item-input>
        <item-input class="input item-col-md-7"  name="city"  [(ngModel)]="item.city" label="City"
                    required >
        </item-input>



    `,
})
export class ItemPatient {

    @Input('item') item;
//    @Output('onClose') onClose = new EventEmitter()


    //ngAfterViewInit() {
    //    console.log('this.Item patient.view init')
    //}

}

