import { Component, ViewEncapsulation,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Output, Input } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var jQuery: any;

//import {BaseList}       from '../../auth/base-list.ts';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'user-message',

  styleUrls: [ './user-message.style.scss' ],
  templateUrl: './user-message.template.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class UserMessage  {

    @ViewChild('chat') chat;
    @ViewChild('threads') threads;

    viewChat = false;
    chat_user:any = {};
    chat_user_id = 0;

    constructor(protected route: ActivatedRoute,private authService:AuthService) {
    }

    backLink() {
        return ['../'];
    }

    refreshContacts() {
        if (this.viewChat) {
            this.viewChat = false;
        }
        else {
            this.threads.refresh();
        }
    }

    refreshChat() {
        this.chat.refresh();
    }

    runChat(user) {

      //  console.log('run chat',user)
        this.chat_user = user;
        this.chat_user_id = user.id;
        this.viewChat = true;
    }

    closeChat() {
        this.viewChat = false;
    }

    selectThread($event) {
        this.runChat($event.conto_user);
    }

    selectContact($event) {
        this.runChat($event);
    }

    @ViewChild('user_info') userInfo;

    userClick(user) {
        this.userInfo.open(user.id);
    }

    onUserPersonalMessages(user) {
        //console.log(user.item);
        this.runChat(user.item);
    }

    ngOnInit() {
        let chat_user_id = +this.route.snapshot.params['chat_user_id'];
        if (chat_user_id) {
            let user = this.authService.routeData;
            this.authService.routeData = null;
            this.runChat(user);
        }
    }

    tab = 0;
    selectTab(tab) {
        this.tab = tab;
    }

    getUserArr() {
        if (this.viewChat) {
            return [this.chat_user.name]
        }
            return []
    }

}
