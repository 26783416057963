//import {DynamicInputControlModel,DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT} from "@ng2-dynamic-forms/core";
//import {DynamicInputModelConfig,ClsConfig} from "@ng2-dynamic-forms/core";
//import {serializable} from "@ng2-dynamic-forms/core/src/decorator/serializable.decorator";
//import {getValue} from "../../utils";

import {ClsConfig} from "../dynamic-form-control.model";
import {DynamicInputModelConfig, DynamicInputModel,DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT} from "../input/dynamic-input.model";
import {serializable} from "../../decorator/serializable.decorator";
import {getValue} from "../../utils";


//import {createNumberMaskSuffix} from 'text-mask-addons/src/createNumberMaskSuffix.js';
//import createNumberMaskSuffix from '../../number-mask/createNumberMaskSuffix.js';
import {createNumberMask} from '../../../../../number-mask/createNumberMaskSuffix';

export const DYNAMIC_FORM_CONTROL_TYPE_NUMBER = "NUMBER";

export interface DynamicNumberModelConfig extends DynamicInputModelConfig {
    precision?: number;
    unit? : string;
}

export class DynamicNumberModel extends DynamicInputModel {

    @serializable() precision: number |null = 0;
    @serializable() unit: string |null = '';

    @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_NUMBER;

//    get mask() { return   [ /\d/ ,/\d/,/\d/] }

    get mask() { //https://github.com/text-mask/text-mask/tree/master/addons/#readme
        let options :any = { prefix: '', allowNegative: true,  };
        if (this.unit) { options.suffix = ' ' + this.unit;}
        if (+this.precision) { options.allowDecimal = true; options.decimalLimit = +this.precision }

        options.includeThousandsSeparator = true;

        let m = createNumberMask(options);
        //console.log(m);
        return m;
    }

    constructor(config: DynamicNumberModelConfig, cls?: ClsConfig) {
       super(config,cls);

        this.inputType =  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT;

       this.precision = getValue(config, "precision", 0);
       this.unit = getValue(config, "unit", '');

    }


}
