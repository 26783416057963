import { Component,ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';


import {InvoiceItemService} from '../invoice-item.service';

@Component({
    selector: 'invoice-item-detail',
    templateUrl: './invoice-item-detail.template.html',
})
export class InvoiceItemDetail extends BaseItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected invoiceItemService:InvoiceItemService) {

        super(el,route, invoiceItemService);
    }

    onReadItem(result) {

        result.period = this.baseService.authService.monthFmt(result.shipping.started_at);
    }



}
