import {
  Component, OnInit, ElementRef, Input, AfterContentInit, OnChanges,
  SimpleChanges
} from '@angular/core';


import "script-loader!smartadmin-plugins/flot-bundle/flot-bundle.js";  // deleted tooltip old version  -deprecated min.js


//import "script-loader!smartadmin-plugins/flot-legacy/jquery.flot.stack.min.js";

//import "jquery.flot.tooltip/js/jquery.flot.tooltip.min.js"
/*
import "script-loader!flotjs/dom-tools.js"
import "script-loader!flotjs/EventEmitter.js"
import "script-loader!flotjs/flot.js"
import "script-loader!flotjs/flot.pie"
declare var Plot;
*/
declare var $: any;


//<div class="sa-flot-chart"> </div>
//

import html2canvas from 'html2canvas'
import * as jsPDF from 'jspdf'

@Component({

  selector: 'sa-flot-chart',
  template: `      
      <div class="sa-flot-chart" [ngStyle]="{width: width, height: height}"> </div>
  `,
  styles: [`
      :host {
          display: block;
          height: 100%;
          width: 100%;
        /*  border: 2px solid red;  */

      }

     .sa-flot-chart {
          overflow: hidden;
      }

`]
})



export class FlotChartComponent implements AfterContentInit, OnChanges {

  @Input() public data:any =[];
  @Input() public graphClass: string= '';

  @Input('options')  //_ options


    options:any ={
      series: {
          pie: {show: false } , //innerRadius:0 }
      }
      ,
      legend: {
          show: false
      }
      ,
      opacity: 0,

      xaxis: {},
      yaxis: {}

  };

  @Input() public type:string;
  @Input() width:string = '100%';
  @Input() height:string = '250px';
  @Input() title: string = '' // 'xxxx';

  private vendorLoaded = false;

    private hostVisible = false;


    constructor(private el: ElementRef) {

    }

    ngAfterContentInit() {
    //    ngAfterViewInit() {
      /*  System.import('imports-loader?this=>window!smartadmin-plugins/flot-bundle/flot-bundle.min.js').then(()=> {
            System.import('imports-loader?this=>window!smartadmin-plugins/flot-bundle/flot-bundle.js').then(()=> {

                console.log('import')
            this.vendorLoaded = true;

            this.render(this.data);

        })

        this.options.hooks = {  draw: [(plot,canvascontext) => {

                var canvas=canvascontext // .getContext("2d");
                var cx =  canvas.canvas.clientWidth / 2;

                var text = this.title;

                canvas.font="100 21px sans-serif";
                canvas.fillStyle= '#3f3f3f'
                canvas.textAlign = 'center';
                canvas.fillText(text,cx,35);


            }]};
   */
        this.vendorLoaded = true;

        this.render(this.data);

    }

    /*
    ngAfterViewInit() {
        this.vendorLoaded = true;

        this.render(this.data);

    }
    */

/*    ngAfterContentInit() {
    System.import('imports-loader?this=>window!smartadmin-plugins/flot-bundle/flot-bundle.min.js').then(()=>{
      this.vendorLoaded = true;
      this.render(this.data)

        $(this.el.nativeElement.children[0]).bind("plotclick", function (event, pos, item) {
            alert("Event: "+ event+ " at " + pos.x + ", " + pos.y);
            // axis coordinates for other axes, if present, are in pos.x2, pos.x3, ...
            // if you need global screen coordinates, they are pos.pageX, pos.pageY

            console.log(item)

                         (item) {
                            highlight(item.series, item.datapoint);
                            alert("You clicked a point!");
                        }

        });

        $(this.el.nativeElement.children[0]).bind("plothover", function (event, pos, item) {
            //alert("Event: "+ event+ " at " + pos.x + ", " + pos.y);
            // axis coordinates for other axes, if present, are in pos.x2, pos.x3, ...
            // if you need global screen coordinates, they are pos.pageX, pos.pageY

            if (item) {
               // console.log(item)
            }

                         (item) {
                            highlight(item.series, item.datapoint);
                            alert("You clicked a point!");
                        }

        });

    });
  }
*/
    drawTitle(plot,canvascontext) { // console.log('hello!')
            // var c = plot.getCanvas();

            var x = plot.getPlotOffset()

            var canvas=canvascontext // .getContext("2d");
            var cx =  canvas.canvas.clientWidth / 2;

            var text = 'xyz' // this.title
            //console.log('plot cx',this)

            //var text = ''
            //var series = plot.getData()
            //if (series.length > 0) {
            //    text = series[0].title
              //  console.log('plot serie',series)
            //}
           // if (!text) {
            //    text = ''
           // }

            //console.log('plot title',plot)
            canvas.font="bold 20px sans-serif";
            canvas.textAlign = 'center';
            canvas.fillText(text,cx,35);


        }


     $plot = null


    render(data) {


        if (data) {
        //    if (data  && this.el.nativeElement.offsetParent !== null) {
                /*
                  if (h==0) return
                  */
                var $p = $(this.el.nativeElement.children[0]);
                //var h = $p.height()
                //  if (h==0) return;
                $p.height(this.height);
          //      console.log('render 3')

                console.log(this.options,this.data)
                this.$plot = $.plot(this.el.nativeElement.children[0], data, this.options);

               // this.options.interaction = {redrawOverlayInterval: 1}
              //  this.$plot = new Plot(this.el.nativeElement.children[0], data, this.options)
                console.log('render 4')

        }
        else {
            console.log('render without data')
        }
    }


    render_old(data){
    if(data){

        //this.options.hooks = {  draw: [this.drawTitle] } // console.log('hello!')


        this.options.hooks = {  draw: [(plot,canvascontext) => { // console.log('hello!')
                // var c = plot.getCanvas();

                var x = plot.getPlotOffset()
                var series = plot.getData()
            //    console.log(series)


                var canvas=canvascontext // .getContext("2d");
                var cx =  canvas.canvas.clientWidth / 2;

                var text = this.title;
              //  console.log('plot text',text)

                /*
                var text = ''
                var series = plot.getData()
                if (series.length > 0) {
                    text = series[0].title
                    console.log('plot title',text)
                }
                if (!text) {
                    text = ''
                }
                */
                //console.log('plot title',plot)
                canvas.font="bold 20px sans-serif";
                canvas.textAlign = 'center';
                canvas.fillText(text,cx,35);


            }]};

        /*
                        //  function hellohook(plot, canvascontext) { alert("hello!"); };
              this.options.hooks = {  draw: [function(plot,canvascontext) { // console.log('hello!')
                     // var c = plot.getCanvas();

                      var x = plot.getPlotOffset()

                      var canvas=canvascontext // .getContext("2d");
                      var cx =  canvas.canvas.clientWidth / 2;
                      //console.log('plot cx',x,canvas)

                      var text = ''
                      var series = plot.getData()
                      if (series.length > 0) {
                          text = series[0].title
                          console.log('plot title',text)
                      }
                      if (!text) {
                          text = ''
                      }
                      //console.log('plot title',plot)
                      canvas.font="bold 20px sans-serif";
                      canvas.textAlign = 'center';
                      canvas.fillText(text,cx,35);


                  }]};
                  */
      var $plot = $.plot(this.el.nativeElement.children[0], data, this.options)

        // https://stackoverflow.com/questions/7179643/flot-chart-title-option
       /* var c = $plot.getCanvas();

        var x = $plot.getPlotOffset()

        var canvas=c.getContext("2d");
        var cx =  c.width / 2;
        console.log('plot cx',x,cx)
        var text="Flot chart title";
        canvas.font="bold 20px sans-serif";
        canvas.textAlign = 'center';
        canvas.fillText(text,cx,35);
       */
    }
  }

 ngOnChanges_old(changes){
    if(this.vendorLoaded && changes.data.currentValue){

      this.render(changes.data.currentValue)
    }
  }

  currentValue

    ngOnChanges(changes) {
        if (this.vendorLoaded && changes.data && changes.data.currentValue) {


            console.log('ngOnChange')

            this.currentValue = changes.data.currentValue
            this.options.series.pie.show = true
            this.render(changes.data.currentValue);
        }
    }


    ngDoCheck() {

      //  console.log('ngDoCheck')
        let visible = this.el.nativeElement.children[0].offsetParent !== null;
        if (this.hostVisible != visible) {
            if (visible) {
     //           console.log('ngDoCheck')
     //           this.render(this.data);
            }
            this.hostVisible = visible;
        }
    }


    scroll

    pdf() {
        let elem = this.el.nativeElement.children[0] //document.body //

        this.scroll = $('html,body').scrollTop()
        $('html,body').scrollTop(0);

        let $elem = $(elem)

        let h1 = $elem.height()
        let w1 = $elem.width()

        //    $elem.width(w1).height(h1)

        //    $.plot(elem, this.currentValue, this.options)

       // console.log(elem,this.scroll)


        html2canvas(elem // ,{scrollY: this.scroll}
        ).then(canvas => {


                       // image = canvas.toDataURL("image/png") // .replace("image/png", "image/octet-stream")
                       // document.location.href=image;
            var wid: number
            var hgt: number

            wid = canvas.width
            hgt = canvas.height

            var img = canvas.toDataURL("image/png")// , wid = canvas.width, hgt = canvas.height);
            var hratio = hgt/wid
            var doc = new jsPDF('p','pt','a4');
            var width = doc.internal.pageSize.width*0.8;
            var height = width * hratio
            doc.addImage(img,'JPEG',doc.internal.pageSize.width*0.1,doc.internal.pageSize.width*0.1, width, height);

            if (this.isIE()) {
                let blob = doc.output('blob') // don't work
                let extFilename = "picture.pdf"
                window.navigator.msSaveOrOpenBlob(blob, extFilename);

            }
            else {
                window.open(doc.output('bloburl'), '_blank')
            }
           // doc.output('dataurlnewwindow',{});     //opens the data uri in new window
            return

            var imgData = canvas.toDataURL(
                'image/png');
            var doc = new jsPDF('p', 'mm');

            doc.addImage(imgData, 'PNG', 10, 10);
            doc.save('sample-file.pdf');
           })


        $('html,body').scrollTop(this.scroll);

    }

    getCanvas() {
        let elem = this.el.nativeElement.children[0] //document.body //

        let $body = $('html,body')
        let scroll = $body.scrollTop()
        $body.scrollTop(0);
        let res = html2canvas(elem)
        $body.scrollTop(scroll);

        return res
    }


    isIE() {
        return window.navigator && window.navigator.msSaveBlob
    }

    print() {
        let elem = this.el.nativeElement.children[0] //document.body //

        let $body = $('html,body')

        this.scroll = $body.scrollTop()
        $body.scrollTop(0)

        html2canvas(elem // ,{scrollY: this.scroll}
        ).then(canvas => {

/*
            image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
            document.location.href=image;

            return


            $(canvas).removeAttr('style')
            console.log(canvas)
            */
            canvas.toBlob ((blob)=> { // don't work in IE
                // ===>  https://stackoverflow.com/questions/47475647/canvas-to-blob-on-edge
                if (this.isIE()) {
                   // var blob = new Blob([csv_text], { type: 'text/csv;charset=utf-8'});
                    let extFilename = 'picture.png'
                    window.navigator.msSaveOrOpenBlob(blob, extFilename);

                }
                else {
                    var windowReference = window.open();

                    var urlCreator = window.URL;

                    var locUrl = urlCreator.createObjectURL(blob);
                    windowReference.location.href = locUrl;

                    //              window.document.title = title;
                    windowReference.document.title = 'TITLE';
                }
            });
     //       $('html,body').scrollTop(this.scroll);

        })
        $body.scrollTop(this.scroll)


        return
        /*
                let element = this.el.nativeElement //.children[0]
                html2canvas(element,{
                        windowWidth: element.scrollWidth,
                        windowHeight: element.scrollHeight,
                    y:-500,
                    allowTaint: true
                    }
                ).then(canvas => {

                    canvas.toBlob(function (blob) {
                        var windowReference = window.open();

                        var urlCreator = window.URL;

                        var locUrl = urlCreator.createObjectURL(blob);
                        windowReference.location.href = locUrl;

                        //              window.document.title = title;
                        windowReference.document.title = 'TITLE';

                    });
                })
                return

        */
        var windowReference = window.open();

        var urlCreator = window.URL;

 //       windowReference.document.body. = '<div #image>';

 //       var div = windowReference.document.createElement('div');


//        div.className = "alert alert-success";
//        div.innerHTML = "<strong>Ура!</strong> Вы прочитали это важное сообщение.";
//        windowReference.document.body.appendChild(div);
//        window.document.body.appendChild(div);

  //      var div2 = this.el.nativeElement.cloneNode(true);
        var div2 = windowReference.document.createElement('div');
        windowReference.document.body.appendChild(div2);

     //   div.parentNode.insertBefore(div2,div)

        div2.style.cssText = "width: 100%;font-size:16px;";

        let h = $(window).height()
        let w = $(window).width()
        $(div2).height(this.height);
        $(div2).width(w) // this.width);

        $.plot(div2, this.currentValue, this.options)

        html2canvas(div2 //, {x:-1000,y:-1000}
        ).then(canvas => {


            canvas.toBlob(function (blob) {
                var windowReference = window.open();

                var urlCreator = window.URL;

                var locUrl = urlCreator.createObjectURL(blob);
                windowReference.location.href = locUrl;

                //              window.document.title = title;
                windowReference.document.title = 'TITLE';

                div2.remove()

            });
        })

        return

        html2canvas(this.el.nativeElement.children[0]
        ).then(canvas => {

            canvas.toBlob(function (blob) {
                var windowReference = window.open();

                var urlCreator = window.URL;

                var locUrl = urlCreator.createObjectURL(blob);
                windowReference.location.href = locUrl;

                //              window.document.title = title;
                windowReference.document.title = 'TITLE';

            });
        })
            return



            /*
                $p = this.el.nativeElement.children[0]

                    console.log($p)
                  //  html2canvas(document.body,{

                    html2canvas($p,{
                            backgroundColor: null
                        }
                        ).then ((canvas) => {

                        let x = document.body.appendChild(canvas);

                    //    $.plot(x, this.data, this.options)

                 //       document.body.appendChild(canvas);
                    });

                    return

                    var $p = this.el.nativeElement.children[0].get(0)

                    console.log($p)
                    html2canvas($p
                ).then(function(canvas) {

                        canvas.toBlob(function(blob) {
                            var windowReference = window.open();

                            var urlCreator = window.URL;

                            var locUrl = urlCreator.createObjectURL(blob);
                            windowReference.location.href = locUrl;

                            //              window.document.title = title;
                            windowReference.document.title = 'TITLE';

                        });

                        return

                        image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
                        document.location.href=image;
                    });

                    return
            */
        var myCanvas = this.$plot.getCanvas();

        myCanvas.toBlob(function(blob) {
            var windowReference = window.open();

            var urlCreator = window.URL;

            var locUrl = urlCreator.createObjectURL(blob);
            windowReference.location.href = locUrl;

            //              window.document.title = title;
            windowReference.document.title = 'TITLE';

            /*
https://developer.mozilla.org/ru/docs/Web/API/HTMLCanvasElement/toBlob
            //           var newImg = document.createElement('img'),
                url = URL.createObjectURL(blob);

            newImg.onload = function() {
                // no longer need to read the blob so it's revoked
                URL.revokeObjectURL(url);
            };

            newImg.src = url;
            document.body.appendChild(newImg);
            */
        });
        return
        var image = myCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  /// here is the most important part because if you dont replace you will get a DOM 18 exception.
        document.location.href=image;  // download
    }

}
