import { NgModule }      from "@angular/core";
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { RouterModule }   from '@angular/router';


import { ModalModule }             from 'ngx-modal';



//import { PaginationModule }          from 'ng2-bootstrap/ng2-bootstrap';



import {AddressModule}      from "../address/address.module"
//import {PatientChartModule} from "../patient-chart/patient-chart.module";

import {ChartService} from "./chart.service"

import {PdfService} from "../service/pdf.service"

//import {ChartWizard}  from "./chart-wizard/chart-wizard.component";
import {ChartList}    from "./list/chart-list.component";
import {ChartDetail}  from "./detail/chart-detail.component";

import {ChartUserList}    from "./user-list/chart-user-list.component";

//import {PatientChartList} from "./patient-chart-list/patient-chart-list.component";

/*
export const routes = [

    { path: '', component:  ChartWizard,  pathMatch: 'full'},
//    { path: ':id', component:  ChartTemplateDetail, canDeactivate: [CanDeactivateGuard]},
//    { path: 'dynamic', component:  DynamicFormComponent, canDeactivate: [CanDeactivateGuard]},

];
*/

//export {ChartWizard, ChartList,ChartDetail};  // for Layout routing


import {ChartTemplateModule} from "../chart-template/chart-template.module";

//import 'bootstrap-application-wizard/src/bootstrap-wizard.js';
//import 'twitter-bootstrap-wizard/jquery.bootstrap.wizard.js';
//import { BootstrapWizardModule } from '../components/wizard/wizard.module';


import {TextMaskModule}       from 'angular2-text-mask';

import 'parsleyjs';

import {DynamicFormsEhisUIModule} from "../chart-template/ui-ehis/ui-ehis.module";
import {DynamicFormService} from "../sp-dynamic-forms/core";

import {EhisControlModule} from '../ehis-control/ehis-control.module';



import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";

import {Control3Module} from "../control3/control3.module"

import {ControlInhModule} from "../control_inh/control_inh.module"
import {AuthModule} from "../auth/auth.module"
import {ChartTable} from "./table/chart-table.component";
import {UserModule} from "../user/user.module";


import {TrustedUserModule} from "../trusted-user/trusted-user.module";
import {WidgetModule} from "../widget/widget.module";
///--------------------------------------------------------------------------------

//import {Dialog, DialogModule} from 'primeng/dialog';
//import {ButtonModule} from 'primeng/button';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
      //  RouterModule,

        ModalModule,
//        PaginationModule,

  //      AddressModule,
 //       PatientChartModule,
 ///       ChartTemplateModule,

 //       BootstrapWizardModule,
        DynamicFormsEhisUIModule,
        //EhisControlModule,  // <- declare MaskedInput ///


        SmartadminModule,
        SmartadminInputModule,

        ControlModule,
        Control3Module,

        ControlInhModule,
        AuthModule,


        TextMaskModule,

   //     DialogModule, ButtonModule,

        // RouterModule.forChild(routes),

        UserModule,
        ChartTemplateModule,

        TrustedUserModule,

        WidgetModule

    ],
    declarations: [
 //       ChartWizard,
        ChartList,
        ChartDetail,
        ChartUserList,

        ChartTable,
    ],
    exports: [
        ChartTable,
    ],

    providers: [
      //SaveGuard,
      ChartService,
      DynamicFormService,
      PdfService,
    ]

})

//export default class ChartModule {
//    static routes = routes;
//}

export class ChartModule {}
