import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {BaseList}          from '../../auth/base-list'
//import {ParsleyFormBaseList}          from '../../auth/base-list.ts'
import {MessageService}    from "../message.service";
import {MessagePostService} from "../message-post.service";

@Component({
  selector: 'user-message-list',

//  styleUrls: [ './user-message-list.style.scss' ],
  templateUrl: './user-message-list.template.html',

  encapsulation: ViewEncapsulation.None,
//  host: {
//    class: 'error-page app'
//  },
})
export class UserMessageList extends BaseList {

  //$el: any;
  @Input() chat_user_id = 0;

  user_id = 0;
  body = '';

  private _busy: boolean = false;
  
  $parsleyForm : any = null;

  constructor(el: ElementRef,private messageService: MessageService,private messagePostService:MessagePostService) {
//    constructor(el: ElementRef,private messageService: MessageService) {

    super(el,messageService);

    this.user_id = messageService.authService.user_id();
    //this.$el = jQuery(el.nativeElement);
  }

  onReadParams(params) {
    params.conto_id = this.chat_user_id;
    params.per_page = 6;
  }

  onReadItems(result) {

    if (result.data.length > 0) {
      let message_id = result.data[0].id;
      this.messagePostService.unread(message_id,this.chat_user_id)
        .subscribe(
          result => {},
          error=> {this.handleError(error)}
      )
    }

    result.data = result.data.reverse();

  }

  get busy():boolean {
    return this._busy;
  }

  set busy(val:boolean) {
    this._busy = val;
  }


  onPost(form) {
    if (this.busy) return;

//    if (form.valid) {
      if (this.$parsleyForm.validate()) { //isValid()) {

        this.busy = true;
        this.messagePostService.postMessage(this.chat_user_id,this.body)
           .subscribe(
              result => {  this.busy = false; this.body=''; this.getList() },
              error  => {  this.busy = false; this.handleError(error) }
           )
    }
  }

  my_message = '';

  postMyMessage() {
    if (this.busy) return;
    this.busy = true;
    this.messagePostService.postMessage(this.chat_user_id,this.my_message)
        .subscribe(
            result => {  this.busy = false; this.my_message=''; this.getList() },
            error  => {  this.busy = false; this.handleError(error) }
        )

  }

  ngOnInit() {
    super.ngOnInit();
//    console.log('parsley');

    /*
    let $el = jQuery('.parsleyjs');

    if ($el) {
      this.$parsleyForm = $el.parsley();
    }
    */
  }

  @Output() onClose = new EventEmitter();

  closeChat() {
    this.onClose.emit({});

  }

  clickItem(item) {
    // do nothing
  }

  @Output() onUserClick = new EventEmitter();


  userClick($event,item) {
    $event.stopPropagation();
    this.onUserClick.emit(item.message.user) ; //conto_user);
//    this.userInfo.open(item.conto_user.id);
  }

}
