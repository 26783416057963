import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({ //"class="select form-select select-sm"

    selector: 'select-item',
    template: `
       <span  *ngIf="data && data.length > 0 "
       [ngStyle] = "{'margin-right': marginRight}"
       >
        <!--select2 class="select2"-->
                    <select  style = "margin-right:0.25rem;"
                            [ngStyle] = "{width:controlWidth}"
                            [ngModel]="item[name]? item[name]:0"
                            (change)="click($event)">
                        <option *ngIf="!hasZeroOption()" [value]="0"><ng-content></ng-content></option>
                        <ng-container *ngFor="let ref of data">
                            <option [value]="ref.id" >{{ref.name}}</option>
                        </ng-container>
       </select> 
       
       <i class="fa fa-times" 
       
       [ngStyle] =" { 'color': timesColor , 'cursor':'pointer' }"
       style="padding-left:0.1rem;border: 1px solid;border-radius: 3px;line-height:12px;width:13px;"
       
       (click)="clear()"></i>
        </span> 
  `,
})

//form-select-sm
export class SelectItemComponent {


    @Input('data') data =[];
    @Input('item') item = {}
    @Input('name') name = 'name'
    @Input('width') width = 164;

 //   @Input('obj') obj = {};


    @Output() change = new EventEmitter();

    constructor() {}

    click($event) {
        let id = $event.target.value;
        $event.stopPropagation();
        this.change.emit( { name: this.name,id: id} );

    }

    hasZeroOption() {
        return this.data.some(elem=>{return elem.id==0})
    }

    get timesColor() {
        return +this.item[this.name] ? '#719cc1' : '#bfbfbf';
    }

    get controlWidth() {
        return this.width.toString() + 'px';
    }

    get marginRight() {
        return '1.25rem'; //'0.75rem';
    }

    clear() {

        this.change.emit( { name: this.name,id: 0} );

    }

}
