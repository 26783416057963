
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
//import {Http, Response} from "@angular/http";
import {HttpClient} from "@angular/common/http";
import { map,delay,catchError } from "rxjs/operators";

import {config} from '../../shared/smartadmin.config';






@Injectable()
export class JsonApiService {

    constructor(private http: HttpClient) {}

    public fetch(url): Observable<any>{
        return this.http.get(this.getBaseUrl() + config.API_URL + url)
            .pipe(
                delay(100),
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    private getBaseUrl(){
        return location.protocol + '//' + location.hostname + (location.port ? ':'+location.port : '') + '/'
    }

    /*private extractData(res:Response) {
      let body = res.json();
      if (body){
        return body.data || body
      } else {
        return {}
      }
    }
     */
    private extractData(res) {
        if (res){
            return res.data || res
        } else {
            return {}
        }
    }


    private handleError(error:any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return observableThrowError(errMsg);
    }

}


