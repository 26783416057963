import { Component,ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {PaymentService} from '../payment.service';
import {BaseItem}  from '../../auth/base-item';

import * as moment from 'moment';

@Component({
    selector: 'payment-detail',
    templateUrl: './payment-detail.template.html',
})
export class PaymentDetail extends BaseItem {


    public validationOptions:any = {
        // Rules for form validation
        rules: {
            api_id: {
                required: true,
                range: [1,9999]
            },

       },

        // Messages for form validation ++ masks ???
        messages: {

            api_id: {
                range: 'Please enter a number between 1 and 9999.'
            },



        }
    };


    constructor(el:ElementRef,protected route:ActivatedRoute, protected paymentService:PaymentService) {

        super(el,route, paymentService);
    }


    onReadItem(result) {
        result.created_at = this.baseService.authService.toLocalTime(result.created_at);
        result.amount = this.baseService.authService.currencyFmt(result.amount);
        if (result.id) {
            let flt = {user_id: result.user_id, product_id : result.product_id};

        //    this.updateLocStorage('-table-invoice',flt);
            this.updateLocStorage('-table-shipping',flt);
 //           console.log('payment-detail',flt)
        }

    }

    isStripe() {

        if (this.item.payment_system) {
            return this.item.payment_system.match(/stripe/i)
        }
        return false;
    }

    viewInStripe() {
        if (+this.item.payment_state_id == 1) {
            this.baseService.authService.showMessage("Can't open details since the payment failed.");
            return;
        }
        window.open('https://dashboard.stripe.com/payments/'+ this.item.payment_system_payment)
    }


    isPayPal() {
        if (this.item.payment_system) {
            return this.item.payment_system.match(/paypal/i)
        }
        return false;
    }

    viewInPayPal() {
        window.open('https://www.paypal.com/activity/payment/'+ this.item.payment_system_payment)
   }

}
