import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from "@angular/forms";
import {Routes, RouterModule} from "@angular/router";


//import {routing} from "./auth.routing";
//import { AuthComponent } from './auth.component';

import {SpinnerService} from './spinner/spinner.service'
import {AuthService} from "./auth.service"

import {AuthGuard} from "./auth-guard.service"
import {SpinnerComponent} from './spinner/spinner.component'

import {ModalModule} from "ngx-modal"

export {AuthService,AuthGuard};

import {LoginComponent} from "./login/login.component";
import {SignupComponent} from "./signup/signup.component";


import {ChangePasswordComponent} from "./change-password/change-password.component"
//import {AuthValidateDirective} from "./validation/validate.directive";
//import {AuthValidClickDirective} from "./validation/button.directive";


//import {SmartadminWidgetsModule} from "../shared/widgets/smartadmin-widgets.module";
import {SmartadminModule} from '../shared/smartadmin.module'
//import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
//import {SmartadminWidgetsModule} from "../shared/widgets/smartadmin-widgets.module";


import {BaseLink} from "./base-link.component"



export const routes:Routes = [

  { path: 'login', component:  LoginComponent, pathMatch: 'full' },
  { path: 'login/:email',  component:  LoginComponent, },


  { path: 'logout', component:  LoginComponent },
  { path: 'signup', component:  SignupComponent}
//  { path: 'changepassword', component: ChangePasswordComponent}

/*
  {
    path: 'register',
    loadChildren: './+register/register.module#RegisterModule'
  },
  {
    path: 'forgot-password',
    loadChildren: './+forgot/forgot.module#ForgotModule'
  },
  {
    path: 'locked',
    loadChildren: './+locked/locked.module#LockedModule'
  }
*/
];

//export const routing = RouterModule.forChild(routes);

//import {authValidForm} from "./validation/validate.directive"
import {SaveGuard} from "./save-guard.service";
import {AdminGuard} from "./admin-guard.service"
import {ControlModule} from "../controls/controls.module";

//export {authValidForm}

export {SaveGuard}
export {AdminGuard}

import {DecoratorComponent} from "./decorator.component"
import {DialogsModule} from "../dialogs/dialogs.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,


    ControlModule,

//    DialogsModule,
//    routing,

    RouterModule,
    RouterModule.forChild(routes),

//    SmartadminWidgetsModule,
      SmartadminModule,
 //   SmartadminInputModule,
 //   SmartadminWidgetsModule

  ],
  declarations: [
    SpinnerComponent,

    LoginComponent,
    SignupComponent,
    ChangePasswordComponent,

 //   AuthValidateDirective,
 //   AuthValidClickDirective,

    BaseLink,
      DecoratorComponent,
  ],
  exports: [
     SpinnerComponent,
//     AuthValidateDirective,
//    AuthValidClickDirective,
      ChangePasswordComponent,

      BaseLink

  ],
  providers: [
//    AuthService,
//    SpinnerService,
//    AuthGuard,
  ]
})
export class AuthModule {
//  static routes = routes;

}
