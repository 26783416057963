import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-select-state',
    template: `
       <select-item [data]="data._state"        
                    [item]= "data.paramItems" 
                    [name]="'state'" 
                    (change)="data.onSelectChange($event)"
                    
                    [width]="82">
       All
       </select-item>

  `,
})
export class ListSelectStateComponent {

    @Input('data') data;
}

