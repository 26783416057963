import { Component,ViewEncapsulation, ViewChild,Input,ViewContainerRef} from '@angular/core';

@Component({
    selector: 'widget-list',
    templateUrl: './widget-list.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetList  {

    parent: any = null;

    constructor(private viewContainerRef: ViewContainerRef){
    }

    // https://github.com/angular/angular/issues/10448
    getParentComponent(): any{
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
      //  return this.viewContainerRef[ '_view' ].component
    }

    get selector() {
        return this.parent.selector;
    }

    ngOnInit() {
        this.parent = this.getParentComponent()
    }

    //--------------------------------------------------------------------------

    _title = "Widget list"

    @Input('title') set title(value)  { this._title = value }
    get title() { return this._title  }

   //----------------------------------------------------------------------------

    @Input('icon') icon = "fa fa-windows"

    @Input('backButton') backButton = true;
    @Input('addButton') addButton = true;
    @Input('itemText') itemText = 'items'

}