import { Component, Input,Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventEmitter, ElementRef } from '@angular/core';


@Component({
//    moduleId: module.id,
    selector: 'diagnoses',
    templateUrl: './diagnoses.template.html',
})
export class DiagnosesComponent {
    @Input('group') public diagnosesForm: FormGroup;
    @Output() onRemove= new EventEmitter();



    removeMe() {
        this.onRemove.emit({});

    }

}


