import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-pager',
    templateUrl: './list-pager.component.html',
})
export class ListPagerComponent {

    @Input('data') data;


//    loc : {};

    // a-la select-item

    get timesColor() {
        return this.data.searchControl.value ? '#719cc1' : '#bfbfbf';
    }

    clear() {

        if (this.data.searchControl && this.data.searchControl.value != '') {
            this.data.searchControl.setValue('');

        }
    }


}

