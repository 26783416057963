import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'clinicians'
})

export class CliniciansPipe implements PipeTransform {
    transform(value: any, format: any[] = []): string {

        if (!value || !Array.isArray(format)) return 'All clinicians';

        let names = []
        let arr = value.toString().split(',')
        names = arr.map( id => {
            let elem = format.find(item=> {return item.id == id})
            return elem ? elem.name : '?'
        })

        //console.log(value,format)
        return names.join(' + ')
    }
}