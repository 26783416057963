/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


declare var jQuery: any;



import {UserService} from '../../service/user.service';
import {AddUser} from "../list/add-user";


@Component({
    selector: '[add-admin-user]',
    templateUrl: './add-admin-user.template.html',
//    providers: [UserService],

})
export class AddAdminUser extends AddUser{


    constructor (el: ElementRef,route: ActivatedRoute,userService:UserService) {
        super(el,route,userService,1);
    }

}

