import {Injectable} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, FormArray, Validators} from "@angular/forms";
import {DynamicFormControlModel} from "../model/dynamic-form-control.model";
import {DynamicFormValueControlModel} from "../model/dynamic-form-value-control.model";
import {
    DynamicFormArrayModel,
    DynamicFormArrayGroupModel,
    DYNAMIC_FORM_CONTROL_TYPE_ARRAY
} from "../model/form-array/dynamic-form-array.model";
import {DYNAMIC_FORM_CONTROL_TYPE_GROUP, DynamicFormGroupModel} from "../model/form-group/dynamic-form-group.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
    DynamicCheckboxGroupModel
} from "../model/checkbox/dynamic-checkbox-group.model";
import {DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX, DynamicCheckboxModel,} from "../model/checkbox/dynamic-checkbox.model";
import {DYNAMIC_FORM_CONTROL_TYPE_INPUT, DynamicInputModel} from "../model/input/dynamic-input.model";
import {DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP, DynamicRadioGroupModel} from "../model/radio/dynamic-radio-group.model";
import {DYNAMIC_FORM_CONTROL_TYPE_SELECT, DynamicSelectModel} from "../model/select/dynamic-select.model";
import {DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA, DynamicTextAreaModel} from "../model/textarea/dynamic-textarea.model";
import {deserializeValidator, deserializeValidators} from "../utils";

// sp++
import {DYNAMIC_FORM_CONTROL_TYPE_NUMBER, DynamicNumberModel} from "../model/number/dynamic-number.model";
import {DYNAMIC_FORM_CONTROL_TYPE_FILE,   DynamicFileModel}  from "../model/file/dynamic-file.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT,
    DynamicAttachmentModel
} from "../model/attachment/dynamic-attachment.model";
//import {DYNAMIC_FORM_CONTROL_TYPE_TABLE,   DynamicFileModel}  from "../model/table/dynamic-table.model";

declare let jQuery: any;

@Injectable()
export class DynamicFormService {

    constructor(private formBuilder: FormBuilder) {
    }

    createFormArray(model: DynamicFormArrayModel): FormArray {

        let formArray = [];

        model.groups.forEach((arrayGroupModel: DynamicFormArrayGroupModel) => {
            formArray.push(this.createFormGroup(arrayGroupModel.group));
        });

//        console.log('formArray',formArray);
        return this.formBuilder.array(formArray, model.validator, model.asyncValidator);
    }

//    createFormGroup(group: Array<DynamicFormControlModel>, groupExtra: {[key: string]: any} | null = null): FormGroup {
    createFormGroup(group: Array<DynamicFormControlModel>, groupExtra?: {[key: string]: any} ): FormGroup {

        let formGroup = {};

        group.forEach(model => {

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_ARRAY) {

                let arrayModel = <DynamicFormArrayModel> model;

                formGroup[model.id] = this.createFormArray(arrayModel);

/*
            } else if (model.type === DYNAMIC_FORM_CONTROL_TYPE_GROUP || model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {


                let groupModel = <DynamicFormGroupModel> model,
                    groupExtra = {validator: groupModel.validator, asyncValidator: groupModel.asyncValidator};

                formGroup[model.id] = this.createFormGroup(groupModel.group, groupExtra);

*/
            } else {

                let controlModel = <DynamicFormValueControlModel<any>> model;

                formGroup[controlModel.id] = new FormControl(
                    {value: controlModel.value, disabled: controlModel.disabled},
                    Validators.compose(controlModel.validators),
                    Validators.composeAsync(controlModel.asyncValidators)
                );
            }
        });

        return this.formBuilder.group(formGroup, groupExtra);
    }

    createFormArrayGroup(dynamicFormArrayModel: DynamicFormArrayModel): FormGroup {

        return this.createFormGroup(dynamicFormArrayModel.addGroup().group);
    }

    addFormArrayGroup(formArray: FormArray, dynamicFormArrayModel: DynamicFormArrayModel): void {

        formArray.push(this.createFormArrayGroup(dynamicFormArrayModel));
    }

    insertFormArrayGroup(index: number, formArray: FormArray, dynamicFormArrayModel: DynamicFormArrayModel): void {

        formArray.insert(index, this.createFormGroup(dynamicFormArrayModel.insertGroup(index).group));
    }

    removeFormArrayGroup(index: number, formArray: FormArray, dynamicFormArrayModel: DynamicFormArrayModel): void {

        formArray.removeAt(index);
        dynamicFormArrayModel.removeGroup(index);
    }

    clearFormArray(formArray: FormArray, dynamicFormArrayModel: DynamicFormArrayModel): void {

        while (formArray.length > 0) {
            this.removeFormArrayGroup(0, formArray, dynamicFormArrayModel);
        }
    }

    findById(id: string, group: Array<DynamicFormControlModel>): DynamicFormControlModel {
        return group.find(controlModel => controlModel.id === id);
    }

    fromJSON(json: Array<Object>): Array<DynamicFormControlModel> | never {

        let formModel: Array<DynamicFormControlModel> = [];

        json.forEach(object => {

            ["asyncValidator", "validator"].forEach(prop => {
                object[prop] = deserializeValidator(object[prop]);
            });

            ["asyncValidators", "validators"].forEach(prop => {
                if (Array.isArray(object[prop])) {
                    object[prop] = deserializeValidators(object[prop]);
                }
            });

//            console.log(object["type"]);

//            object.selected = false;

            switch (object["type"]) {


                case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:
                    object["groups"].forEach(groupObject => groupObject["group"] = this.fromJSON(groupObject["group"]));
                    object["createGroup"] = () => this.fromJSON(object["originGroup"]);
                    formModel.push(new DynamicFormArrayModel(object, object["cls"]));
                    break;

/*
                case DYNAMIC_FORM_CONTROL_TYPE_TABLE:

                    object["groups"].forEach(groupObject => groupObject["group"] = this.fromJSON(groupObject["group"]));
                    object["createGroup"] = () => this.fromJSON(object["originGroup"]);
                    formModel.push(new DynamicFormArrayModel(object, object["cls"]));
                    break;
*/

                case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
                    formModel.push(new DynamicCheckboxModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
                    object["group"] = this.fromJSON(object["group"]);
                    formModel.push(new DynamicCheckboxGroupModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
                    //object["group"] = this.fromJSON(object["group"]);
                    formModel.push(new DynamicFormGroupModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
                    formModel.push(new DynamicInputModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
                    formModel.push(new DynamicRadioGroupModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
                    formModel.push(new DynamicSelectModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:
                    formModel.push(new DynamicTextAreaModel(object, object["cls"]));
                    break;
                // SP++
                case DYNAMIC_FORM_CONTROL_TYPE_NUMBER:

                    formModel.push(new DynamicNumberModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_FILE:

                    formModel.push(new DynamicFileModel(object, object["cls"]));
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT:

                    formModel.push(new DynamicAttachmentModel(object, object["cls"]));
                    break;

                // SP--


                default:
                    throw new Error(`unknown form control type defined on JSON object`);
            }
        });

        return formModel;
    }

    //------------------------------------------------------------------------------------------------------

    stripHTML(html) {
            var tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
    }


    public docDefinition(dynamicFormModel: Array<DynamicFormControlModel>):any {

        let body = [];

        dynamicFormModel.forEach(model => {

            var row =[];

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT) {

                let md =  model as DynamicInputModel;

                row = [md.label, md.value];
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_NUMBER) {

                let md =  model as DynamicNumberModel;

                row = [md.label, md.value];
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA) {

                let md =  model as DynamicTextAreaModel;

                row = [md.label, md.value];
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {

                let md = <DynamicSelectModel<any>> model;

                row = [md.label, md.options[md.value]];
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX) {

                let md = model as DynamicCheckboxModel;

                let val = md.value ? 'YES':'NO';

                row = [md.label, val];
            }
            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP) {

                let md = model as DynamicRadioGroupModel<any>;

                let ul = [];
                md.options.forEach( option=> {
                    if (option.value == md.value) {
                        ul.push(option.label);
                    }
                  //  ul.push( (option.value == md.value ? ' YES':'   ') +  '   '+ option.label);
                });
                var val:any = '';
                if (ul.length > 0) {
                    val = {ul: ul};
                }

                row = [md.label, val];
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_FILE) {

                let md = model as DynamicFileModel;
                let val = (md.base64data) ? {image: md.base64data, fit:[300,300]}:'';

                console.log('base64',md.link, md.base64data ? 'yes': 'no')
                row = [md.label, val];
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT) {

                let md = model as DynamicAttachmentModel;
                let val = md.value;
                row = [md.label, val];
            }



            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_GROUP) {

                let md = model as DynamicFormGroupModel;
                //let val = jQuery(md.legend).text();
                let val = this.stripHTML(md.legend);

                //row = ['', val];  // span .... table ....
                row = [{ text: val,colSpan:2}, ''];  // span .... table ....
            }

            if (model.type === DYNAMIC_FORM_CONTROL_TYPE_ARRAY) {

                let md = model as DynamicFormArrayModel;

                let rows = [];
                var colcount = 0;
                for (let table_row of md.groups) {
                    let cols = [];
                    var hasValue = false;
                    for (let table_col of table_row.group){
                        if (table_col.value) {
                            cols.push(table_col.value);
                            hasValue = true;
                        }
                        else {
                            cols.push('');
                        }
                    }
                    if (hasValue) {
                        rows.push(cols);
                    }
                    colcount = cols.length;
                }

                let table_top_bottom_off = 20;

                var val:any = '';
                if (rows.length > 0) {
                    let widths = [];
                    let w = Math.trunc(1 / colcount * 100);
                    while (colcount > 1) {
                        widths.push(`${w}%`);
                        colcount--;
                    }
                    widths.push('*');
                    /*
                    val = {
                        table: {
                            widths: widths,
                            headerRows: 1,
                            body: rows
                        }
                    }
                    */
                    val = {
                        table: {
                            widths: widths,
                            headerRows: 1,
                            body: rows
                        },
                        colSpan:2,
                        border:[false,false,false,false],
                        margin: [0, (md.label.length) > 0 ? 0:table_top_bottom_off,0, table_top_bottom_off],

                    }


                }

                //-- row = [md.label, val];  // old layout

                // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                // new layout: the label above table
                //
                if (md.label.length > 0 ) {
                    row=[{text:md.label,colSpan:2,
                          border:[false,false,false,false],
                          margin:[0,table_top_bottom_off,0,0]
                        },''];
                    body.push(row.map(elem=>{ return elem?elem:''}));
                }

                row=[val,''];

                //------------------------------------------------------------------------------



            }



            if (row.length > 0) {
                body.push(row.map(elem=>{ return elem?elem:''}));
            }
        })

    //console.log(body);

    //----------------------------------------------------------------------------------------------------

/*    let dd = {
        content: []
    }

    if (body.length > 0) {

            (<any> dd).header = function(currentPage, pageCount):any {
                let text = currentPage.toString() + ' page of ' + pageCount;

                return { text: text, alignment: (+currentPage % 2) ?  'right' : 'left' };
            };

            (<Array<any>> (dd.content)).splice(0,0,{ table:{

                widths: [200, '*'],

                body:body
            }});

            (<any> dd).pageMargins = [ 40, 60, 40, 60 ];


            (<any> dd).footer = {

                image: "assets/img/doctors/doctor-joke.jpg",
                alignment: 'center'
            };

        }

        return dd;
 */

        if (body.length > 0) {

            return { table:{
                    widths: [200, '*'],
                    body: body
                }};
        }
        else {
            return { text : '' };
        }

    }




}