import {Component, ElementRef, Input, ViewChild} from '@angular/core';

//import {AuthService} from "../../auth/auth.service";

import * as moment from 'moment';
import {BaseForm} from "../../auth/base-form";

import {periodStr} from "../../auth/period-str"

import {sprintf} from "sprintf-js"
import {AuthService} from "../../auth/auth.service";
//import {DayPilot} from "daypilot-pro-angular";
//import {duration} from "../../../../node_modules/smartadmin-plugins/bower_components/moment/moment";



@Component({
  selector: 'available-hours-dialog',
  templateUrl: './available-hours-dialog.component.html',
})
export class AvailableHoursDialog /* extends BaseForm */ {

    tab = 1;

    selectTab(tab) {

    }

    item: any = {}

    startTime: string
    endTime: string
    duration: string;
    resource_name: string
    currentDate = null
   // currentDateFuture = null
   // weekdays = null


     dt_period: any[] = [];



    clearProjects() {
    }

    dateFmt: string = 'DD/MM/YYYY';
    /* moment format */
    datePickerFmt = 'dd/mm/yy'

    dateEditFmt = 'dd/mm/yy'


    nullPeriod() {
        this.item.dt1 = null;
        this.item.dt2 = null;

    }

    currentPeriod() {
        this.item.dt1 = moment().startOf('month').format(this.dateFmt)
        this.item.dt2 = moment().endOf('month').format(this.dateFmt)
    }

    todayPeriod() {
        this.item.dt1 = moment().startOf('day').format(this.dateFmt)
        this.item.dt2 = moment().endOf('day').format(this.dateFmt)
    }

    periodChange(e) {
        this.item.dt1 = e.dt1;
        this.item.dt2 = e.dt2;
    }

    get column2() {
        return '38%'
    }

    compPeriodStr() {

        return periodStr(this.item.dt1, this.item.dt2, this.dateFmt)
     }



    timeArray = []
    durationArray = []

    setTimes() {


        this.startTime = moment(this.item.start.toString()).format("HH:mm")
        this.endTime = moment(this.item.end.toString()).format("HH:mm")

        let cellDuration = (this.item.cellDuration) ? this.item.cellDuration : 60

        let _timeArray = []
        for (let i = 0; i < 24; i++) {
            _timeArray.push(sprintf("%02d:00", i))
            if (cellDuration == 30) {
                _timeArray.push(sprintf("%02d:30", i))
            }
            if (cellDuration == 15) {
                _timeArray.push(sprintf("%02d:15", i))
                _timeArray.push(sprintf("%02d:30", i))
                _timeArray.push(sprintf("%02d:45", i))
            }
        }
        this.timeArray = _timeArray

        let _durationArray = []
        for (let i = 0; i < 24; i++) {
            if (i > 0) {
                _durationArray.push(sprintf("%dh", i))
                if (cellDuration == 30) {
                    _durationArray.push(sprintf("30m", i))
                }
                if (cellDuration == 15) {
                    _durationArray.push(sprintf("%dh 15m", i))
                    _durationArray.push(sprintf("%dh 30m", i))
                    _durationArray.push(sprintf("%dh 45m", i))
                }
            }
            else {
                if (cellDuration == 30) {
                    _durationArray.push(sprintf("30m", i))
                }
                if (cellDuration == 15) {
                    _durationArray.push(sprintf("15m", i))
                    _durationArray.push(sprintf("30m", i))
                    _durationArray.push(sprintf("45m", i))
                }
           }
           this.durationArray = _durationArray
        }


        this.calculateDurationFromStartEnd(this.startTime,this.endTime)
    }


    calculateDurationFromStartEnd(start,end) {

        let start_date: any = this.dateFromTimeStr(start)
        let end_date:any = this.dateFromTimeStr(end)

        this.duration = moment(end_date.diff(start_date)).utc().format("HH:mm")

        return
        let diff = new Date(end_date - start_date);
   //     this.duration = new DayPilot.Date(diff).toString("HH:mm")
        this.duration = moment(diff).format("HH:mm")

    }

    durationOnChange($event) {
    //  console.log(this.duration,$event.target.value)


    }


    private dateFromTimeStr(s) {

        let hh_mm = s.split(':')
        let hh = +hh_mm[0]
        let mm = hh_mm.length > 1 ? +hh_mm[1] :0
        let diff = new Date(0,0, 0, hh,mm )

    //    return moment().utc().startOf('day').add(hh,'h').add(mm,'m' )
        return moment().startOf('day').add(hh,'h').add(mm,'m' )
    }

    durationSelect($event) {
        let start =  this.dateFromTimeStr(this.startTime)
      //  console.log(start)

        let hh_mm = this.duration.split(':')
        let hh = +hh_mm[0]
        let mm = hh_mm.length > 1 ? +hh_mm[1] :0
       // let diff = new Date(0,0, 0, hh,mm )
      //  console.log(hh,mm)

        let end = start.add(hh,'h').add(mm,'m')

      //  console.log(end)
        this.endTime= end.format("HH:mm");

  }



    ngOnInit() {
        this.item.period = +this.item.period ? +this.item.period : 0;


    }

    periodClick(period) {

    }


    public typeaheadOnSelect(e: any): void {
      this.calculateDurationFromStartEnd(this.startTime,this.endTime)
    }

    get title() {
 //       return this.item.id ? "Edit Available Hours #" + this.item.id : "Add Available Hours"
 //       return this.item.id ? "Edit Available Hours"  : "Add Available Hours"
        return  "Edit Available Hours"
    }

   @ViewChild('week_hours') week_hours

    setItem(item) {

        this.item = Object.assign({},item)

        if (item.start && item.end) {
            this.setTimes()
            this.resource_name = item.resource ? item.resource.name : ''

            // https://momentjs.com/docs/#/displaying/
            this.currentDate = moment(item.start.toString()).format('dddd, DD MMMM YYYY')

            //let s1 = moment(item.start.toString()).format('dddd')+'s'
            //let s2 = moment(item.start.toString()).format('DD MMMM YYYY')
            //this.weekdays = s1
            //this.currentDateFuture = sprintf('All %s from %s on', s1, s2)

            this.item.period = +this.item.period ? +this.item.period : 0;
        }

        this.week_hours.item = item.week_hours
    }

    get currentDateFuture() {

        let dt = this.currentDate // .start.toString()
        if (dt) {

            let s1 = moment(dt).format('dddd') + 's'
            let s2 = moment(dt).format('DD MMMM YYYY')
            //this.weekdays = s1
            return sprintf('All %s from %s on', s1, s2)
        }
        return ''
    }

    get weekdays() {
        let dt = this.currentDate // .start.toString()
        if (dt) {

            let s1 = moment(dt).format('dddd') + 's'
            //let s2 = moment(dt).format('DD MMMM YYYY')
            //this.weekdays = s1
            return s1
        }
        return ''

    }

    get shortFormat() {
        return {
            dateFormat: 'D, dd M yy' // 'DD, dd MM yy'
        }
    }

    public getItem() {

        let dt = moment(this.currentDate).format('YYYY-MM-DD')

        let d1 = moment(this.item.apply_start).format('YYYY-MM-DD')
        let d2 = moment(this.item.apply_end).format('YYYY-MM-DD')

        let item = this.item
        item.start = dt + ' ' + this.startTime + ':00',
        item.end = dt + ' ' + this.endTime + ':00',
            item.apply_start =  d1,
            item.apply_end = d2

        //------------------------------------------------------------------------------

        item.apply = dt
        item.apply_start = d1
        item.applay_end = d2
        item.apply_mode = item.period

        item.week_hours = this.week_hours.item

        return item
    }

    @Input('data') set data(item) {
        this.setItem(item)
    }


    get data(){
        return this.getItem()
    }


    constructor(protected el:ElementRef,public authService:AuthService) {  }


    can_select_user() {
        return this.authService.acl('/calendar','select_user')
    }

    @ViewChild('win') win

    open(callback) {
        this.win.open(callback)
    }

    close() {
        this.win.close()
    }


}