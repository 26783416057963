import { Component,ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {SubscriptionService} from '../subscription.service';
import {BaseItem}  from '../../auth/base-item';

import {SubscriptionItem} from "./subscription-item"


@Component({
    selector: 'subscription-detail',
    templateUrl: './subscription-detail.template.html',
})
export class SubscriptionDetail extends SubscriptionItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected subscriptionService:SubscriptionService) {

        super(el,route, subscriptionService);
    }


    setOnHold() {  // ask ?
        this.item.subscription_state_id = 2;
        this.saveItem();
    }

    setActive() { // ask ?
        this.item.subscription_state_id = 1;
        this.saveItem();
    }

    float0 = {
        thousands: '',
        decimal: '.',
        precision:0,
        align: 'left',
        allowNegative:
        false,
        prefix : ''
    }
/*
    int0 = {
        thousands: ' ',
        decimal: '.',
        precision:0,
        align: 'left',
        allowNegative:
            false,
        prefix : ''
    }
*/
    float4 = {
        thousands: '',
        decimal: '.',
        precision:4,
        align: 'left',
        allowNegative:
            false,
        prefix : ''
    }

    public validationOptions:any = {
        // Rules for form validation
        rules: {
            billing_day: {
                required: true,
                range: [1,31],
                dayofmonth: "#billing_month"
            },

            invoice_wait: {
                required: true,
                range: [1, 23]
            },

            invoice_send_delays_interval: {
                required: true,
                range: [1, 7]
            },

            price_per_query: {
                number: true,
            },

            price_fixed: {
                number: true,
            },

            price_discount: {
                number: true,
            },

            invoice_email: {
                emails: true,
               maxlength : 250,
            },

            invoice_email1: {
                emails: true,
                maxlength : 250,
            },

            invoice_bcc: {
                emails: true,
                maxlength : 254,
            },

        },

        // Messages for form validation ++ masks ???
        messages: {

            billing_day: {
               // range: 'Please enter a day between 1 and 28.'
                dayofmonth: 'Please enter a valid day of month (current year).'
            },

            invoice_wait: {
                range: 'Please enter a number between 1 and 23.'
            },

            invoice_send_delays_interval: {
                range: 'Please enter a number between 1 and 7.'
            },


        }
    };


    get _default_recipient() {
        return this.item.invoice_email_mode ? this.item.invoice_email : this.item._invoice_email_default;
    }

    _selection_state () {
        return this.item._state.filter(elem => {return elem.id != 2})
    }

     selection_name() {

         return this._selection_state ().map(elem => {return elem.name})
     }

    selection_id() {

        return this._selection_state ().map(elem => {return elem.id})
    }


    changeState() {
        /*
        let selections = this.selection_name()
        var selected = 1;
        var i = 0;
        for (let elem of this._selection_state()) {
            if (elem.id == this.item.state) {
               selected = i;
               break;
            }
            i++;
        }
        */

        let SUBSCRIPTION_STATE_ACCESS_GRANTED    =  1;

        let SUBSCRIPTION_STATE_MANUALLY_STOPPED  =  7;

        let selections = [ 'Automatic', 'Manually stopped'];

        let selected = this.item.state == SUBSCRIPTION_STATE_MANUALLY_STOPPED ? 1 : 0 ;

        let dlg = {
            selections: selections,
            selected: selected,
            header: 'Set subscription status'
        }

        this.baseService.authService.selectDialog(dlg)
            .subscribe(result=>{ if (result.result) {
                //let selections = this.selection_id()
                //this.item.state = selections[result.selected];

                this.item.state = result.selected == 0? SUBSCRIPTION_STATE_ACCESS_GRANTED : SUBSCRIPTION_STATE_MANUALLY_STOPPED;
                this.saveItem();
           }});
    }

    onWriteItem(result) {
        result.comment = result.comment ? result.comment : null;

    }

    onReadItem(result) {
        if (result.cutoff_threshold && result._cutoff_threshold) {
            if (!result._cutoff_threshold.some(elem=>elem.id == result.cutoff_threshold)) {
                result._cutoff_threshold.push({id: result.cutoff_threshold,
                    name: this.baseService.authService.intFmt(result.cutoff_threshold)});
            }

        }
        if (result.id) {
            let flt = {user_id: result.user_id, product_id : result.product_id};

            this.updateLocStorage('-table-payment',flt);
            this.updateLocStorage('-table-invoice',flt);
            this.updateLocStorage('-table-shipping',flt);

        }
    }

    get labelBillingDay() {
        return "Billing and refillment day of month";
    }


    userAccount() {
        this.baseService.authService.showMessage('User account')
    }

}
