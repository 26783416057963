import {Component, ElementRef, forwardRef, ViewContainerRef} from '@angular/core';
import { Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

//declare var $;

import {EhisInput} from "./ehis-input"
import {AuthService} from "../auth/auth.service";
import {CsvService} from "../auth/csv/csv.service";

@Component({
    selector: 'ehis-date',
    template:
 `
        <div *ngIf="bs" class="input-group input-group-sm">
            <!-- <i class="icon-append fa fa-calendar" (click)="clickIcon($event)"></i>  -->
            <input type="text" class="datepicker form-control" placeholder="{{format}}" [(ngModel)]="value"
                 [readonly] = "readonly"
                      [saUiDatepicker]="{ dateFormat: format}"

                      (dateChanged)="dateChanged($event)"
                                   [attr.disabled]="disabled ? 'disabled':null">
                                 
                                     <span class="input-group-btn btn-group-sm">
        <!-- <button class="btn btn-default" (click)="clickIcon()" type="button"><i class="fa fa-calendar"></i></button>  -->
     </span>
                   
        </div>
      
      
         <i *ngIf="!bs" class="icon-append fa fa-calendar" (click)="clickIcon($event)"></i>
      
        <input *ngIf="!bs" type="text" class="datepicker" placeholder="{{format}}" [(ngModel)]="value"
                [readonly] = "readonly"
                
                                             
                                   [saUiDatepicker]="{ dateFormat: format}"
                                   (dateChanged)="dateChanged($event)"
                                   [attr.disabled]="disabled ? 'disabled':null"
       
                
                >
       <!--
       
                      data-parsley-trigger="change"
                data-parsley-required-message=""
                data-parsley-type-message="Should be valid email"
                required="required"
 
     -->
     <!--  </div>  -->  
      
   `,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisDateComponent), multi: true },
    ]
})
export class EhisDateComponent extends EhisInput {

    @Input('format') format = 'dd/mm/yy'
    @Input('disabled') disabled = false;

    @Input('bs') bs=false;

    dateChanged($event) {
        this.value = $event;
    }

    clickIcon(e) {
     //   this.datepicker_icon(e)
    }
/*
    constructor(private el: ElementRef) {
        super()
      //  let $el = $(el.nativeElement);
    }

    datepicker_icon($event) {
        if (this.disabled) return;

        $event.stopPropagation();
        $event.preventDefault()

        let $input = $(this.el.nativeElement).find('input') //.select().focus()
        let focused = $input.is(':focus')
        console.log('focused',focused)

        if (focused) {
            $input.blur() // blur()
            $input.datepicker( "hide" )
        }
        else {
            if ($input.val()) {
                //  $input.select().focus()
                $input.focus()
            }
            else {
                $input.focus()

            }

        }
        // let focused1 = $input.is(':focus')
        // console.log('post focused',focused1)

    }
*/

}
