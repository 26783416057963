import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-select-product',
    template: `
       <select-item [data]="data._product"        
                    [item]= "data.paramItems" 
                    [name]="'product_id'" 
                    (change)="data.onSelectChange($event)">
       All products
       </select-item>

  `,
})
export class ListSelectProductComponent {

    @Input('data') data;
}

