/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgZone } from '@angular/core';

import {UserService}        from '../../service/user.service';
import {NewsletterService}  from '../../service/newsletter.service';

import {UserDetail}  from '../detail/user-detail.component';

//import {ModalComponent } from '../../auth/modal-window/modal.component';


declare var jQuery: any;

//import {API_URL} from '../../app.env';    // photo

@Component({
    selector: 'user-profile',
    templateUrl: '../detail/user-detail.template.html',
  //  providers: [UserService,NewsletterService],

})
export class UserProfile extends UserDetail {

//    profile = true;
/*
    constructor(el: ElementRef,protected route: ActivatedRoute,protected userService: UserService,private newsletterService:NewsletterService) {

        super(el,route,userService);
    }
*/
    detailId() {
        return 'profile' // this.baseService.authService.user_id();
    }

    /*
    freeMembership() {
        return this.item.membership || !this.isLoaded;
    }

    get profileTabset() {
        if (this.item.membership || !this.isLoaded) {
            return "Registration, Newsletter, Community"
        }

        return "Registration, Newsletter, Subscriptions, Community"
    }
    */
}
