/**
 * Created by Alexander Spazhev on 26.06.2017.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


declare var jQuery: any;


import {UserService} from '../../service/user.service';
import {AddUser} from "../list/add-user";


@Component({
    selector: '[add-yearly-client-user]',
    templateUrl: './add-yearly-client-user.template.html',

})
export class AddYearlyClientUser extends AddUser{

   constructor (el: ElementRef, route: ActivatedRoute, userService:UserService) {
        super(el,route,userService,5);

       this.item.subscription_type_id = 3;
       this.savedItem = this.itemToSaveStr();
   }

}

