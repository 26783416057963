import { Component,ViewEncapsulation, ViewChild,Input,ViewContainerRef} from '@angular/core';

declare var $:any

import {WidgetBase} from "../widget-base"
@Component({
    selector: 'widget-report',
    templateUrl: './widget-report.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetReport extends WidgetBase{

    @Input('backButton') backButton = false;
    @Input('addButton') addButton = true;
    @Input('itemText') itemText = 'items'

    @Input('addButtonCaption') addButtonCaption = 'Add'
    @Input('addButtonWidth') addButtonWidth = '80px'
    @Input('toolBar') toolBar = true;
    @Input('pagerBar') pagerBar = true
    @Input('search') search = true

    @Input('tagsBar') tagsBar = false;

    @Input('headerDark')  header_dark = false
    @Input('scrollY') scroll_y = false
    @Input('fixedCol') fixed_col = 0;
    //  height:400px;width:100%;overflow: scroll"

    @Input('tableHeight') table_height = '458px'
}