import { Component, AfterViewInit,Input,Output,EventEmitter } from '@angular/core';

import jarvisWidgetsDefaults from  '../widget.defaults';
import {ElementRef} from "@angular/core";

//import 'smartadmin-plugins/smartwidgets/jarvis.widget.ng2.js'
import '../../../../assets/sp-smartwidgets/jarvis.widget.ng2.js'

declare var $: any;

@Component({

  selector: 'sa-widgets-grid',
  template: `
     <section id="widgets-grid">
       <ng-content></ng-content>
     </section>
  `,
  styles: []
})
export class WidgetsGridComponent implements AfterViewInit {

    @Input('parent') parent;
    @Input('name') name;
    @Output() delete = new EventEmitter<any>();

    constructor(public el: ElementRef) {

      this.parent = this;
      delete $.SmartMessageBox;  // remove delete prompt
    }

    ngAfterViewInit() {
      let defaults = jarvisWidgetsDefaults;
      defaults.pageKey = this.name;

      //defaults.onDelete = () => { this.delete.emit(); } // onDelete this.parent.closeModal();};

        defaults.customStart = () => {  }
        defaults.customEnd = () => { this.delete.emit(); }
      $('#widgets-grid', this.el.nativeElement).jarvisWidgets(defaults);
  }

}
