import {Component, Input, Output, EventEmitter} from '@angular/core';

import * as moment from 'moment';

@Component({

    selector: 'period-month',
    template: `
                 
 <!--
       <div class="input-group-sm">           
      <div class="input-group input-group-sm bootstrap-touchspin">
-->      
                                  <!--
                                  input-group-sm form-control-sm input-sm
                                        <span class="input-group-btn ">
                                            <button class="btn btn-default" type="button"><i class="fa fa-minus"></i></button>
                                        </span>
                                        class="form-control form-control-sm"  
                                  
                                          <a class=" btn1 bootstrap-touchspin-up" type="button" 
                                              (click)="sub()" [ngStyle]="{color: btnColor()}"
                                              
                                               
                                        >
                                         --> 
                                              <i  class="icon-prepend glyphicon glyphicon-chevron-left"></i>
                                              <i  class="icon-append glyphicon glyphicon-chevron-right"></i>
                                             
                                 
                                         <input type="text" 
                                          style="text-align: center;" [ngModel]="m"
                                        placeholder="Search for..." [readonly] = "true"
                                         
                                         [ngStyle]="{color: textColor()}"
                                         [disabled] = "disabled ? 'disabled': null">
                                        
                                    <!--  <div class="input-group-btn btn-group-sm"> 
                                     
                                     style="margin-top:0px;height:15px;"
                                     [disabled]="disabled"
                                                                      <div class="btn-group-vertical">                                             
 
                                     
       
                                            <a class="btn1 bootstrap-touchspin-down" type="button" 
                                               (click)="add()" [ngStyle]="{color: btnColor()}"
                                            >
                                            
                                            <i class="glyphicon glyphicon-chevron-right" ></i></a>
                                        
                                   </div>
                            </div>
                                    -->
<!--
      </div>
                     </div>             
                      -->
  `,
})
export class PeriodMonthComponent {

    @Input('data') data;
    @Input('disabled') disabled = false;
    @Input('mode') mode = 'month';

    get dateFmt() {
        if (this.mode=='month') return 'MMMM YYYY';
        if (this.mode=='quarter') return 'Qo YYYY';
        if (this.mode=='day') return 'DD MMM YYYY';
        if (this.mode=='week') return 'Wo YYYY';


        return 'YYYY';
    }

    m: string;
    d: any;

    change(val:any){
        if (!this.disabled) {
            this.d = this.d.add(val, this.mode);
            this.m = this.d.format(this.dateFmt)
        }
    }

    add(){
        this.change(1);
    }

    sub(){

        this.change(-1);
    }

    btnColor () {
        return this.disabled ?  '#eee': '#686868'
    }

    textColor() {
        return this.disabled ?  'rgba(0,0,0,0.3)': '#404040'

    }

    ngOnInit() {
        this.d = moment();
        this.m = this.d.format(this.dateFmt)
    }
}

