import {Component, ViewEncapsulation, Input, ViewContainerRef, ElementRef} from '@angular/core';

@Component({
    selector: 'widget-base',
    template: ``,
   // encapsulation: ViewEncapsulation.None,
})
export class WidgetBase  {

    parent: any = null;

    constructor(protected el:ElementRef, protected viewContainerRef: ViewContainerRef){
    }

    // https://github.com/angular/angular/issues/10448
    getParentComponent(): any{
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
    }

    get selector() {
        if (this.parent.selector) return this.parent.selector;

        let s = this.parent.constructor.name // className() ~  constructor.name
        let selector = s.replace(/\.?([A-Z])/g, function (x,y){return "-" + y.toLowerCase()}).replace(/^-/, "")
        //console.log('selector-01',this.parent.selector,selector)
        return selector;
    }

    ngOnInit() {
        this.parent = this.getParentComponent()
        setTimeout(()=>{this._spin=true;}, 250);
    }

    //--------------------------------------------------------------------------

    _title = "widget"

    @Input('title') set title(value)  { this._title = value }
    get title() { return this._title + this.parent.modified }


    _spin = false;

    get spin() {
        return this._spin && !this.parent.isLoaded && !this.error
    }

    get error() {
        return this.parent.error
    }

    get tags() {
        return this.parent._tag.length;

    }

}