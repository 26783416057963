import { Component, ViewEncapsulation,TemplateRef,ContentChild } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Output, Input } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';

declare var $: any;

import {BaseList} from '../../auth/base-list';

import {UserService} from '../../service/user.service';



@Component({
  selector: 'user-credits-table',

//  styleUrls: [ './user-list.style.scss' ],
  templateUrl: './user-credits-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class UserCreditsTable extends BaseList {


    @ContentChild(TemplateRef) template ;//: TemplateRef;

    @Output() onOpenForm = new EventEmitter();
    @Input()  currentItem:any;

    @Input()  routerLink1: any;


//    @Input('fields') fields;
//    @Input('html') html;
//  @Input() folderName: any;
//  router: Router;
//  private userService: UserService;
//    private error:string = "";

    routerLinkDisabled: boolean = false;

//    searchBox:string = ''; // ??

    $el:any;


    constructor(el:ElementRef, private router:Router, private root: ActivatedRoute, private userService:UserService) {

        super(el,userService);
        this.$el = $(el.nativeElement);

    }

    filterName() {
        return ''; // (this.radio1).toLowerCase();
    }


/*
    getFullName(item:any) {

        let fullName: string = item.last_name +', '+  item.first_name;
        return fullName;
    }

    routerTo(item:any) {
        return [item.id];
    }

*/

    openForm(item: any): void {
      //this.userService.authService.toDetail([item.id]); // full path

//    this.onOpenForm.emit(item);

       this.router.navigate([item.id]); // , {relativeTo: this.root}]);

    }
    /*
    clickRadio1(val:string) {

        if (val != this.radio1) {
            this.radio1 = val;

            switch (val) {
                case 'All':
                {
                        delete this.paramItems.to_approve;
                        break;
                }
                case 'To approve':
                {
                    this.paramItems.to_approve = 1;
                    break;
                }
                default:
                {

                }
            }
            this.updateSearchControl();
        }
    }


  ngOnInit() {

    if (this.currentItem) {
        this.serviceItems.filter = this.currentItem.name;
    };
    this.getList();

  }
*/

    _tag:any[] = []

    onReadItems(result) {

        if (this._tag.length==0 && result._tag) {
            this._tag = result._tag;

            let $el = $('#tags');
            for (let opt of this._tag) {
                var option = new Option(opt.name, opt.id, false, false);
                $el.append(option);
            }

            let s = this.paramItems.tags;
            let val = s ? s.split(',').filter((e)=>{ return +e}) : [];
            $('#tags').val(val).trigger('change');

        }



//        onReadItems(result) {
        for (let elem of result.data) {
            elem.completed_at = elem.activation?this.baseService.utcToLocal(elem.activation.completed_at): null;
            elem.completed = elem.activation?elem.activation.completed: 0;
        }
    }

    iconClass(item) {
        return item.service_user ? 'fa fa-user-md fa-lg' : 'fa fa-lg'; // 'fa fa-circle-thin fa-lg'; //fa-user_md fa-eye
    }

    userColor(item) {
        if (item.completed) {
            //console.log(item.naf_id)
            return '#57889c'; // '#4387bf'; // 'bg-color-blue';
        }
        return '#c79121'; //'bg-color-orange';
    }

    addItem() {
        let dlg = {
            selections: ['Add new admin',               // 0
                         'Add new accountant',          // 1
                         'Add new client',              // 2

            //             'Add new WHOIS API client with MONTHLY membership plan',    // 2
            //             'Add new WHOIS API client with YEARLY membership plan',             //3
            //             'Migrate WHOIS API user from old system'     ,                      // 4

            ],  // 4
            selected: 2,
            header: 'What do you want to do?'
        }
        this.baseService.authService.selectDialog(dlg)
            .subscribe(result=>{ if (result.result) {
                if (result.selected == 0) this.baseService.authService.navigate(['/user','admin']);
                if (result.selected == 1) this.baseService.authService.navigate(['/user','accountant']);
                if (result.selected == 2) this.baseService.authService.navigate(['/user','wizard']);


                if (result.selected == 3) this.baseService.authService.navigate(['/user','client']);
                if (result.selected == 4) this.baseService.authService.navigate(['/user','yearly']);
                if (result.selected == 5) this.baseService.authService.navigate(['/user','whois']);


            }});
    }



    selectTag($event) {
        let v =  $('#tags').select2('val');
        let arr = v ? v : [];

        this.paramItems.tags = arr.join(',');

        this.getList();
        //  console.log('select2',$event);
    }

}
