import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromUser from '../user.reducer';

export interface State {
  appState : fromUser.AppState;
}

export const reducers: ActionReducerMap<State> = {
    appState: fromUser.reducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getAppState = createFeatureSelector<fromUser.AppState>('appState');
export const getAppStateSelector =  createSelector(getAppState, fromUser.getAppState);
