import {Component, Input, Output, ElementRef,EventEmitter} from '@angular/core';
//import {BaseForm} from "../../auth/base-form";
import {DecoratorComponent} from "../../auth/decorator.component";

@Component({

    selector: 'subscription-product',
    template: `
         <div class="row">

            <section  class="col col-12">
                <label class="label">Product</label>
                <label class="select">
                    <select name="product_id" [(ngModel)]="item.product_id" (change)="productSelect($event)">
                        <option value="{{ref.id}}" *ngFor="let ref of item._product" >{{ref.name}}</option>
                    </select> <i></i> </label>
            </section>

        </div>
        <div class="row">

            <section  class="col col-12" >
                <label class="label">Subscription</label>
                <label class="select">
                    <select name="subscription_type_id" [(ngModel)]="item.subscription_type_id"

                    >
                        <option value="{{ref.id}}" *ngFor="let ref of _subscription_type" >{{ref.name}}</option>
                    </select> <i></i> </label>
            </section>
            
         </div>

 `,
})
export class SubscriptionProductComponent extends DecoratorComponent {

  //  @Input('item') item;
    @Input('_subscription_type') _subscription_type = [];

    updateSubscription() {
        this._subscription_type = this.item._subscription_type.filter(elem=> elem.product_id==this.item.product_id);
        if (!this._subscription_type.some(elem=>elem.id == this.item.subscription_type_id)) {
            this.item.subscription_type_id = (this._subscription_type.length > 0 ) ? this._subscription_type[0].id : 0;
        }

    }


    productSelect($event) {
        this.updateSubscription();
    }


}

