import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'localTime'
})
export class LocalTimePipe implements PipeTransform {

  transform(value: any, format?: any): any {

//    var date = new Date();

 //   date.setTime(value);

   return  moment(+value).format('YYYY-MM-DD HH:mm:ss');  // local time

  //  return  moment(value).format('YYYY-MM-DD HH:mm:ss')  // local time

  //  let localTime = moment.utc(date).toDate();
 //   return  moment(localTime).format('YYYY-MM-DD HH:mm:ss')  // local time


  //  return moment(value).format('YYYY-MM-DD');
  }

}
