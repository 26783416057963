import {
    Component,
    ViewEncapsulation,
    ViewChild,
    Input,
    Output,
    ViewContainerRef,
    ElementRef,
    EventEmitter
} from '@angular/core';

declare var $:any;

const dialog_selector = 'popup'

@Component({
    selector: dialog_selector,
    templateUrl: './dialog.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class Popup  {

  //@Input('parent')
  //  parent: any = null;

    @Input('modal_lg') modal_lg = false

    @Output() onClose = new EventEmitter();
    @Output() onOk = new EventEmitter()
    @Output() onCancel = new EventEmitter()

    @Output() beforeOpen = new EventEmitter()


    constructor(private el: ElementRef,private viewContainerRef: ViewContainerRef){


    }

    ngAfterViewInit() {
        $(this.el.nativeElement).children('form').css('display','none')

    }
    // https://github.com/angular/angular/issues/10448
    //getParentComponent(): any{
    //    return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
      //  return this.viewContainerRef[ '_view' ].component
   // }

   // get selector() {
   //     return this.parent.selector;
   // }

    //--------------------------------------------------------------------------

    //ngOnInit() {
    //    this.parent = this.getParentComponent()
    //}

    /*

    okModal() {
        this.parent.okModal(this.name)
    }

    closeModal() {
   //     console.log(this.parent)
        this.parent.closeModal(this.name)
    }
    */


    $form = null

    backdropElement : any = null
 //   item: any = {}

    findWidgetBody() {


        var $elem =  $(document).find('.modal-content.modal-dialog').first()

        return $elem.parent()

        var $elem =  $(document).find(dialog_selector).first()
        return $elem.children('.modal')


            var $elem = $(this.el.nativeElement)
        var $parent = $elem.parent()
        var $widget_body = $parent.find('.widget-body')

        if ($widget_body.length == 0) {
            $widget_body = $elem.parents(dialog_selector).last()
            console.log('par',$widget_body)
            $widget_body = $widget_body.children('.modal')
        }
        return $widget_body
/*
        findWidgetBody() {
            var $widget_body = $(this.el.nativeElement).closest('.widget-body')

            if ($widget_body.length) {
                this.isModal = false
            }
            else {
                $widget_body = $(this.el.nativeElement).closest(dialog_selector).children('.modal')
                this.isModal = true
                // console.log('f',$widget_body)
            }
            return $widget_body
        }
        */

    }

    findForm() {
        let $form = $(this.el.nativeElement).find('form').first()
        return $form
    }


    callback : ()=>{} = null

    createBackDrop() {
        this.backdropElement = document.createElement("div");
        this.backdropElement.classList.add("modal-backdrop");
        this.backdropElement.classList.add("fade");
        this.backdropElement.classList.add("in");

    }

    removeBackDrop() {
        document.body.className = document.body.className.replace(/modal-open\b/, "");
        if (this.backdropElement && this.backdropElement.parentNode === document.body)
            document.body.removeChild(this.backdropElement);
    }

    ngOnDestroy() {
        this.removeBackDrop()
    }

    openModal(callback=null) {

    }

    modal = true


    open(callback=null) {

        this.callback = callback

     //   console.log('call back',this.callback)

        this.beforeOpen.emit()


        if (this.$form) return

        let $elem = $(this.el.nativeElement)

        let $form = $elem.children('div').first().children('form').first()

        this.$form = $form

        let $modal =  $(document).find('form.modal-content.modal-dialog').first().parent()

        let showModal = $modal.length == 0

        this.modal = $modal.length

        if ($modal.length == 0) {
            $modal = $elem.parents(dialog_selector).last().children('div').first()
            if ($modal.length == 0) {
                $modal = $elem.children('div').first()
            }
        }

        $modal.css('overflow-y','scroll')   // ++


       // console.log('showModal',showModal)

       // console.log('showModal',$modal.parent().is($elem),$modal.parent(),$elem)

        if (!($modal.parent().is($elem))) {
            $modal.children('form').css('display', 'none') //addClass('widget_body__form--closed')
            $modal.prepend($form)
        }

        $form.addClass('widget-modal-content modal-content modal-dialog')
        $form.css('display','block')

        $modal.css('display','block')


        //this.modal = $(document).find('div.modal-backdrop').length == 0
        // of $(document).find('.modal-content.modal-dialog').length == 0
        /*

        if ($modal.length) {
            let $parent_widget_form = $elem.parents(dialog_selector).last()
            if ($parent_widget_form.length) {
                let $modal = $parent_widget_form.children('.modal').first()
                $modal.children('form').css('display', 'none') //addClass('widget_body__form--closed')
                $modal.prepend(this.$form)
            }
        }
        else {
            $modal =  $(document).find('.modal-content.modal-dialog').first().parent()

            if (!($modal.parent().is($elem))) {
                $modal.children('form').css('display', 'none') //addClass('widget_body__form--closed')
                $modal.prepend(this.$form)
            }

        }


        if (!($modal.parent().is($elem))) {
            $modal.children('form').css('display', 'none') //addClass('widget_body__form--closed')
            $modal.prepend(this.$form)
        }

        $form.addClass('widget-modal-content modal-content modal-dialog')
        $form.css('display','block')

        */

        //let backdropElement = $(document.body).children('.modal-backdrop')


        if ($(document.body).children('.modal-backdrop').length == 0) {
          //  console.log('backDrop created')

            let backdropElement = document.createElement("div");
            backdropElement.classList.add("modal-backdrop");
            backdropElement.classList.add("fade");
            backdropElement.classList.add("in");

            backdropElement.classList.add("popup");

            document.body.appendChild(backdropElement);
            //window.setTimeout(function () {
            //   return _this.modalRoot.nativeElement.focus();
            //}, 0);
            document.body.className += " modal-open";

            this.backdropElement = backdropElement
        }

        return
        //console.log('modal',this.modal)







        if (!this.modal && !this.$form) {

            this.$form = $form
            var $widget_body = this.findWidgetBody()

          //  console.log('widget_body',$widget_body)
            $widget_body.children('form').css('display','none') //addClass('widget_body__form--closed')
            $widget_body.prepend(this.$form)
            $form.addClass('widget-modal-content modal-content modal-dialog')
            $form.css('display','block')
            //$form.css('display','block') // removeClass('widget_body__form--closed').addClass('widget_body__form--opened')
            //$form.css('border','solid')
            //$form.css('padding','13px')
            //$form.css('border-radius','5px')
            //$form.css('border-color','#F1DA91')

        }


        if (this.modal && !this.backdropElement) {


            let $parentModal = $(this.el.nativeElement).closest('.modal.in.fade')
            let z_index = 0
            let count = 0

            $()
            $(document).find('div.modal-backdrop').each((ind, domElement) => {

                let z = +$(domElement).css("z-index")
                if (z > z_index) z_index = z
                count = count +1
            })
            console.log(count,z_index,$parentModal.length)

            this.backdropElement = document.createElement("div");
            this.backdropElement.classList.add("modal-backdrop");
            this.backdropElement.classList.add("fade");
            this.backdropElement.classList.add("in");

            /*
            var $widget_body = this.findWidgetBody()

            //  console.log('widget_body',$widget_body)
            $widget_body.children('form').css('display','none') //addClass('widget_body__form--closed')
            $widget_body.prepend(this.$form)
            $form.addClass('widget-modal-content modal-content modal-dialog')
            $form.css('display','block')
             */

            $form.css('display','block')
            $form.addClass('modal-content modal-dialog widget-modal-content')


            if (count > 0) {
             //   $(this.backdropElement).css('z-index',z_index+10)
                $form.parent().css('z-index',1040 + count*5) // z_index+15)
                $form.parent().css('right','-50px')
            }

            if (this.modal_lg) $form.addClass('modal-lg')

            //$form.css('border','solid')
            //$form.css('padding','13px')
            //$form.css('border-radius','5px')
            //$form.css('border-color','#F1DA91')

            document.body.appendChild(this.backdropElement);
            //window.setTimeout(function () {
            //   return _this.modalRoot.nativeElement.focus();
            //}, 0);
            document.body.className += " modal-open";

            $(this.el.nativeElement).children('div').first().css('display','block')

        }


    }

    removeValidation($form) {
        var $validator = $form.validate()
        $validator.resetForm();//remove error class on name elements and clear history
        $validator.reset();//remove all error and success data

        $('em', $form).remove()

    }

    close() {

       // console.log('close 1')
        if (this.$form) {

            let $modal = this.$form.parent()

            this.$form.removeClass('modal-content modal-dialog modal-lg')
            this.$form.css('display','none')
            $(this.el.nativeElement).children('.modal').prepend(this.$form)
            this.removeValidation(this.$form)

            //console.log($(this.el.nativeElement).children('.modal'))
            let $top =  $(document).find('form.modal-content.modal-dialog').first().parent()
            if ($top.length == 0) {
                $modal.css('display','')

                let $backdropElement = $(document.body).children('.modal-backdrop')

                $backdropElement.remove()
                document.body.className = document.body.className.replace(/modal-open\b/, "");

              }
              else {
                $modal.children('form').first().css('display','block')
            }


            this.$form = null
        //    console.log('close')

        }

        return

        if (this.$form) {
            this.$form.css('display','none') // removeClass('widget_body__form--opened').addClass('widget_body__form--closed')
            $(this.el.nativeElement).children('.modal').prepend(this.$form)

            console.log($(this.el.nativeElement).children('.modal'))

            this.$form.removeClass('modal-content modal-dialog modal-lg')

            var $widget_body = this.findWidgetBody()
            $widget_body.find('form').first().css('display','block') // removeClass('widget_body__form--closed')

            this.removeValidation(this.$form)

            this.$form = null

        }

        if (this.backdropElement) {
            let $form = this.findForm()
            $form.css('display','none').removeClass('modal-content modal-dialog modal-lg')

            $form.parent().css('z-index','').css('right','')


            this.removeValidation($form)

            $(this.backdropElement).remove()
            this.backdropElement = null
            //document.body.removeChild(this.backdropElement);
            document.body.className = document.body.className.replace(/modal-open\b/, "");

            $(this.el.nativeElement).children('div').first().css('display','')

        }

        this.onClose.emit()

    }

    cancelClick() {

        if (this.onCancel.observers.length > 0 ) {
            this.onCancel.emit()
        }
        else {
            this.close()
        }
    }

    okClick() {

     //   console.log('xxxxx',this.onOk.observers.length,this.callback)

        if (this.onOk.observers.length > 0) {
            this.onOk.emit()
        }
        else {
        if (this.callback) {
            this.callback()
        }
        else {
            this.close()
        }
        }

        // call back
        // check if unique
        //validate()//
        //call callback

        /*
        let postItem = Object.assign({},this.item)
        let d = postItem.date_of_birth.split('/')
        postItem.date_of_birth = d[2]+'-'+d[1]+'-'+d[0]

        let url = this.authService.getApiUrl() + '/api/' + this.selectMap[this.elemLink]

        this.authService.post(url,postItem)
            .subscribe(
                result => {
                    this.closePatientForm()
                    //
                    //this.busy = false;
                    //this.noChanges = true;
                    //this.back()
                },
                error => {
                    //console.log(error)
                    // this.busy = false;
                    this.authService.showError(error);
                    //this.error = error
                }
            );

        */

        //this.close()

    }


    @Input() loading = false

    _closeButton = 'Close'

    get closeButton() { return this._closeButton}
    @Input('closeButton') set closeButton(value) {
        this._closeButton = value
    }

    @Input('okButton') okButton = 'Ok'


    @Input('disabled') disabled = false
    @Input('enabled') set enabled(value) { this.disabled = !value}

    @Input('width') width = ''

    get form_style() {
        if (this.width) return { 'min-width': this.width}
        return null
    }

    isdisabled() {
        return this.disabled ? true : undefined
    }

}