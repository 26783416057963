/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import { Component,ViewEncapsulation, ViewChild,Input,ViewContainerRef} from '@angular/core';

@Component({
    selector: 'widget-modal',
    templateUrl: './widget-modal.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetModal  {

  //@Input('parent')
    parent: any = null;

    constructor(private viewContainerRef: ViewContainerRef){

       // console.log('wg',this.viewContainerRef)
    }

    // https://github.com/angular/angular/issues/10448
    getParentComponent(): any{
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
      //  return this.viewContainerRef[ '_view' ].component
    }

    get selector() {
        return this.parent.selector;
    }

    //--------------------------------------------------------------------------

    _name = "Detail"

    @Input('name') set name(value)  { this._name = value }
    get name() { return this._name /*+ this.parent.modified */ }

   //----------------------------------------------------------------------------

    @Input('icon') icon = "fa fa-windows"

    ngOnInit() {
        this.parent = this.getParentComponent()
    }

    okModal() {
        this.parent.okModal(this.name)
    }

    closeModal() {
   //     console.log(this.parent)
        this.parent.closeModal(this.name)
    }
}