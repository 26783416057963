import {Component, Input, Output, EventEmitter,forwardRef} from '@angular/core';

//import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
//import { ControlValueAccessor } from '@angular/forms';

import * as moment from 'moment';
import {EhisInput} from "./ehis-input";


@Component({

    selector: 'ehis-period',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisPeriodComponent), multi: true },
    ],

    template: `
                 
 <!--
       <div class="input-group-sm">           
      <div class="input-group input-group-sm bootstrap-touchspin">
-->      
                                  <!--
                                  input-group-sm form-control-sm input-sm
                                        <span class="input-group-btn ">
                                            <button class="btn btn-default" type="button"><i class="fa fa-minus"></i></button>
                                        </span>
                                        class="form-control form-control-sm"  
                                  
                                          <a class=" btn1 bootstrap-touchspin-up" type="button" 
                                              (click)="sub()" [ngStyle]="{color: btnColor()}"
                                              
                                               
                                        >
                                         --> 
                                              <i  class="icon-prepend glyphicon glyphicon-chevron-left" 
                                              [ngStyle]="{color: btnColor(),cursor:btnPointer()}"
                                              (click)="sub()"></i>
                                              <i  class="icon-append glyphicon glyphicon-chevron-right" 
                                              [ngStyle]="{color: btnColor(),cursor:btnPointer()}"
                                              (click)="add()"></i>
                                             
                                 
                                         <input type="text" 
                                          style="text-align: center;" [ngModel]="m"
                                        placeholder="Search for..." [readonly] = "true"
                                         
                                         [ngStyle]="{color: textColor()}"
                                         [disabled] = "disabled ? 'disabled': null">
                                        
                                    <!--  <div class="input-group-btn btn-group-sm"> 
                                     
                                     style="margin-top:0px;height:15px;"
                                     [disabled]="disabled"
                                                                      <div class="btn-group-vertical">                                             
 
                                     
       
                                            <a class="btn1 bootstrap-touchspin-down" type="button" 
                                               (click)="add()" [ngStyle]="{color: btnColor()}"
                                            >
                                            
                                            <i class="glyphicon glyphicon-chevron-right" ></i></a>
                                        
                                   </div>
                            </div>
                                    -->
<!--
      </div>
                     </div>             
                      -->
  `,
})
export class EhisPeriodComponent extends EhisInput{

//    @Input('data') data;
    @Input('disabled') disabled = false;
    @Input('mode') mode = 'month';
    @Input('format') format= 'dd/mm/yy'

    @Output() onChange = new EventEmitter();

    get dateFmt() {
        if (this.mode=='month') return 'MMMM YYYY';
        if (this.mode=='quarter') return 'Qo YYYY';
        if (this.mode=='day') return 'DD MMM YYYY';
        if (this.mode=='week') return 'Wo YYYY';

        return 'YYYY';
    }

    m: string;
    d: any;

    doEmit() {
        let val1:any = moment(this.d);
        val1.startOf(this.mode);
        let val2:any = moment(this.d);
        val2.endOf(this.mode);

        this.onChange.emit({dt1: val1.format(this.format), dt2: val2.format(this.format)});
    }
    change(val:any){
       if (!this.disabled) {

           this.d.add(val, this.mode);

           let val1:any = moment(this.d);
           val1.startOf(this.mode);

           this.value = val1.format(this.format);

           this.d = val1;
           this.m = this.d.format(this.dateFmt);

           this.doEmit();
        }
    }

    click() {
        this.doEmit();
    }

    add(){
        this.change(1);
    }

    sub(){

        this.change(-1);
    }

    btnColor () {
        return this.disabled ?  '#eee': '#686868'
    }

    btnPointer () {
        return this.disabled ?  'default': 'pointer'
    }

    textColor() {
        return this.disabled ?  'rgba(0,0,0,0.3)': '#404040'

    }

/*
    set value(v: any) {
        if (v !== this.innerValue) {
            let val: any = v ? moment(v): moment();

            this.innerValue = val;
            this.onChangeCallback(val);

        }
    }
*/
    ngOnInit() {
        let val: any = this.value ? moment(this.value,this.format): moment();
        this.d = val.startOf(this.mode);
        this.m = this.d.format(this.dateFmt)
    }
}

