/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import { Component,ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Output, Input } from '@angular/core';

declare var jQuery: any;


import {AuthService} from "../../auth/auth.module"
import {BaseItem} from "../../auth/base-item";

import {UserService} from '../../service/user.service';

@Component({
    selector: 'add-product',
    template:

    `      
    
                                <div class="row">

                                    <section *ngIf="item.mode!=3" class="col col-6">
                                        <label class="label">Product</label>
                                        <label class="select">
                                            <select name="product_id" [(ngModel)]="item.product_id">
                                                <option value="{{ref.id}}" *ngFor="let ref of item._product" >{{ref.name}}</option>
                                            </select> <i></i> </label>
                                    </section>

                                    <section *ngIf="item.mode!=3" class="col col-6" >
                                        <label class="label stat-disabled">Subscribed for</label>
                                        <label class="select stat-disabled">
                                            <select name="subscription_type_id" [(ngModel)]="item.subscription_type_id"
                                               disabled="true"
                                            >
                                                <option value="{{ref.id}}" *ngFor="let ref of item._subscription_type" >{{ref.name}}</option>
                                            </select> <i></i> </label>
                                    </section>

                                </div>
          
          
    `,

})
export class AddProductComponent {

   @Input('data') item = { mode:1 , product_id: 1 , subscription_type_id: 1};


}

