import {Component, OnInit} from '@angular/core';
import {SmUserService} from "../sm-user.service";
import {LayoutService} from "../../layout/layout.service";
import { environment } from '../../../../../src/environments/environment';
import { ApplicationStoreService } from 'app/application-store.service';
import { AuthService } from 'app/auth/auth.service';

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {

  user:any;
  themelogo : string = '';
  logoHeight: string = '';
  constructor(
    private userService: SmUserService,
    private layoutService: LayoutService,
    private appStore: ApplicationStoreService,
    private authservice: AuthService) {
  }

  ngOnInit() {
//    this.userService.getLoginInfo().subscribe(user => {
//      this.user = user
//    })
    this.appStore.appState$.subscribe(appstate => {
      if(appstate.themeLogo) {
        this.themelogo = appstate.themeLogo ? appstate.themeLogo : '';
        if(this.themelogo) {
          this.logoHeight = '105';
        } else {
          this.logoHeight = '30';
        }
      }
    });
    setTimeout(() => {
      if(!this.themelogo) {
        if(sessionStorage.getItem("theme") != 'undefined') {
          let theme = JSON.parse(sessionStorage.getItem("theme"));
          if(theme) {
            if(theme.themelogo == null || theme.themelogo == '') {
              this.logoHeight = '30';
            } else {
              this.logoHeight = '105';
            }
            let logopath = theme.themelogo ? environment.API_URL+'/api/themelogo/'+theme.themelogo : 'assets/img/LEFT_MENU_N1.png';
            this.themelogo = logopath;
          }
        } 
      }
    }, 2000);
    

  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}
