import {Injectable} from '@angular/core';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class InvoiceService extends BaseService {

    icon = "fa-dollar";

    utc = [ 'started_at'];

    constructor(public authService:AuthService){ super(authService,"/api/invoice");}

    getRecentinvoice(clinic_id:string) {
        let hostname = location.hostname;
        if(hostname === 'localhost') {
            hostname = 'http://localhost:4200';
        } else {
            hostname = 'https://'+hostname;
        }
        console.log("base===>",hostname);
        let url:string = `${hostname}/invoice/api/invoice?page=1&per_page=5&clinic_id=${clinic_id}`;
        return this.authService.get(url);
    }

    public showError(err: String) {
        this.authService.spinnerStop()
        this.authService.showError(err)
      }
    
}