/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewEncapsulation, ViewChild, Input, ViewContainerRef, ElementRef} from '@angular/core';


declare var $ :any;

@Component({
    selector: 'widget-detail',
    templateUrl: './widget-detail.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetDetail  {

  //@Input('parent')
    parent: any = null;
    context_style :any = { 'padding': '10px 14px',position:'relative'}

    constructor(private el:ElementRef,private viewContainerRef: ViewContainerRef){


       // console.log('wg',this.viewContainerRef)
    }

    // https://github.com/angular/angular/issues/10448
    getParentComponent(): any{
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
      //  return this.viewContainerRef[ '_view' ].component
    }

    get selector() {
        return this.parent.selector;
    }

    //--------------------------------------------------------------------------

    _title = "widget detail"

    @Input('title') set title(value)  { this._title = value }
    get title() { return this._title + this.parent.modified }

   //----------------------------------------------------------------------------

    @Input('icon') icon = "fa fa-windows"


    get isModal() {
        return ($(this.el.nativeElement).closest('modal-content').length > 0)
    }

    ngOnInit() {
        this.parent = this.getParentComponent()
        if (this.isModal) {
            //console.log('modal')
            this.context_style = { 'padding': '1px 1px',position:'relative'}
        }

    }
}