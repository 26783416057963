import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewContainerRef,
    forwardRef,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR,ControlValueAccessor  } from '@angular/forms';

import {AuthService} from "../../auth/auth.service";
import {CsvService} from "../../auth/csv/csv.service";

import "select2/dist/js/select2.js";
import {Subject} from "rxjs";


const noop = () => {};

export const SELECT5_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Select2),
    multi: true
};

declare var $;

@Component({

    selector: 'select2',
    templateUrl: './select2.component.html',
    providers: [SELECT5_CONTROL_VALUE_ACCESSOR]

})
export class Select2 implements ControlValueAccessor {


    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {

        //  console.log('clear write 2',v)

        // if (v !== this.innerValue) {
        this.innerValue = v;
        this.onChangeCallback(this.innerValue);
        //  }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }


    clearValue() {
        this.innerValue = null
        $(this.el.nativeElement).find("select").val(null).trigger('change')
        /*
                let selectClass= 'select5'
                $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {
                    $(domElement).val(null).trigger('change');


                })
                */
    }

    select2_writeValue(data) {

        if (data.id) {

            $(this.el.nativeElement).find("select").each((ind, domElement) => {

                if ($(domElement).find("option[value='" + data.id + "']").length) {
                    $(domElement).val(data.id).trigger('change');
                }
                else {
                    // Create a DOM Option and pre-select by default
                    var newOption = new Option(data.text, data.id, true, true);
                    // Append it to the select
                    $(domElement).append(newOption).trigger('change');
                }
            })
        }
        else if (data.text) {

            data.id = data.text

            $(this.el.nativeElement).find("select").each((ind, domElement) => {

                if ($(domElement).find("option[value='" + data.id + "']").length) {
                    $(domElement).val(data.id).trigger('change');
                } else {
                    // Create a DOM Option and pre-select by default
                    var newOption = new Option(data.text, data.id, true, true);
                    // Append it to the select
                    $(domElement).append(newOption).trigger('change');

                    // $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {

                    $(domElement).find("option[value='" + data.id + "']").each((ind, option) => {
                        $(option).attr('data-select2-tag', true)
                    })
                    //  })

                }
            })

            // console.log('tad received')
        }

    }

    //  @Input('link')
    link = 'user'

    getLinkUrl() {
        let url = this.authService.getApiUrl() + '/api/' + this.link;
        return url
    }


    getName(value) {

    }

    //From ControlValueAccessor interface
    writeValue(value: any) {

//        console.log('writeValue',value,this.mode)


        if (value != this.innerValue) {

            this.innerValue = value

            let s = this.innerValue ? this.innerValue.toString() : '';
            let val = s ? s.split(',').filter((e) => {
                return +e
            }) : [];

            this.findSelect2((domElement) => {
                $(domElement).val(val).trigger('change')
            })

        }

        return
//        if (this.elemType == "USER" || this.elemType == "ICD10") {

        if (value) {

            // console.log('select2_writeValue:3',this.parent)
            let name = ''

            /*
            if (this.parent) {
                let p = this.selectMap[this.elemLink]
                if (!p) p = this.elemLink.toLowerCase()

                let item =  this.parent.item ? this.parent.item : this.parent

                let obj = item[p]
                if (obj) {
                    name = obj ? obj.name : (this.istaged ? item[this.taged] : '')

                }
                else {
                    let url:string = this.getLinkUrl() +'/' + value.toString().trim();
                    //   console.log('select2_writeValue:4',url)

                    this.authService.get(url).subscribe(
                        result=> {
                            name = result.name // : (this.istaged ? item[this.taged] : '')
                            let data = {
                                id: value,
                                text: name
                            }
                            this.select2_writeValue(data)


                        },
                        error=>{

                        }
                    )

                    return
                }


            }
            */
            let data = {
                id: value,
                text: name
            }

            this.select2_writeValue(data)
        }
        // SP++ Empty
        else {

            this.clearValue()
        }
        //       }

    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //-----------------------------------------------------------------------

    constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef, public authService: AuthService) {

        this.pre_required = el.nativeElement.hasAttribute('required')
    }


    getParentComponent(): any {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component
        //  return this.viewContainerRef[ '_view' ].component
    }

    //----------------------------------------------------------------------------------------------------------


    public innerValue: string = '';

    /*
    @Input('item') item = {}

    $el = null

    initialize = false // !!! true

    columns = ['name']

    idKey = 'id'
    nameKey = 'name'
    linkKey = 'link'

    //fixed: false
    nameText = 'Clinician'

    links = false

    colcount = 1 // 2
    //rows = 3
    innerValue: any[] = [
        {id: 1, name: this.nameText + ' 1', link: null},
        {id: 2, name: this.nameText + ' 2', link: null},
        {id: 3, name: this.nameText + ' 3', link: null},
    ]

    updateColumns() {
        let i = this.innerValue.reduce((acc, elem) => {
            return Math.max(acc, +elem[this.idKey])
        }, 0)
        for (let item of this.innerValue) {
            if (!item[this.idKey]) {
                i = i + 1
                item[this.idKey] = i
            }
        }
    }

    @Input('options') set options(value) {

        if (value) {

            if (value.keys) {


                let names = value.keys.split(',')
                let idKey = (names[0]) ? names[0] : this.idKey
                let nameKey = (names.length > 1 && names[1]) ? names[1] : this.nameKey
                let linkKey = (names.length > 2 && names[2]) ? names[2] : this.linkKey

                for (let item of this.innerValue) {
                    if (idKey != this.idKey) {
                        item[idKey] = item[this.idKey]
                        delete item[this.idKey]
                    }
                    if (nameKey != this.nameKey) {
                        item[nameKey] = item[this.nameKey]
                        delete item[this.nameKey]
                    }

                    if (linkKey != this.linkKey) {
                        item[linkKey] = item[this.linkKey]
                        delete item[this.linkKey]
                    }

                }

                this.idKey = idKey
                this.nameKey = nameKey
                this.linkKey = linkKey

                this.columns = [this.nameKey]
            }

            if (value.initialize !== undefined) {  // !!! dummy
                this.initialize = value.initialize
            }

            if (value.links !== undefined) {
                this.links = value.links
            }

            if (value.hint !== undefined) {

                this.nameText = value.hint
                for (let item of this.innerValue) {
                    item[this.nameKey] = this.nameText + ' ' + item[this.idKey]
                }
            }
        }
    }


    set data(value) {
        console.log('user-index', value)
        if (value && Array.isArray(value)) {
            this.innerValue = value
            this.updateColumns()
        }
    }


    indexTracker(index, value) {
        return index;
    }

    change($event, row, col) {

        // console.log($event)
        let val = $event // .target.value
        //console.log(val)
        if (this.innerValue[row][col] != val) {
            this.innerValue[row][col] = val
            //    this.onChangeCallback(this.innerValue);


        }
    }

    show_data() {
        console.log(this.innerValue)
    }
    */
    /*
    get fixed() {
        return (this.options && this.options.fixed)
    }
    get colcount() {
        return this._data && Array.isArray(this._data) ? this._data.reduce((acc,elem)=>{
            return Math.max(acc,elem.length)},0) : 0
    }

    */

    /*
    get rowcount() {
        return this.innerValue && Array.isArray(this.innerValue) ? this.innerValue.length : 0
    }


    get addText() {
        //  return this.rowcount < 2 ? "Add row +" : "Add another row +"
        //   return this.rowcount < 2 ? "Add " value" : "Add another value"
        return "Add " + this.nameText.toLowerCase()
    }


    @Output('onAdd') public onAdd: EventEmitter<any> = new EventEmitter();
    @Output('onRemove') public onRemove: EventEmitter<any> = new EventEmitter();

    addMe() {
        let i = this.innerValue.reduce((acc, elem, idx) => {

            let v = +elem[this.idKey]
            return Math.max(acc, Number.isNaN(v) ? idx : v)
        }, 0) + 1

        let row: any = {}
        row[this.idKey] = i
        row[this.nameKey] = this.nameText + ' ' + i
        this.innerValue.push(row)
        this.onAdd.emit(this.innerValue.length)
    }

    removeMe(index) {
        this.innerValue.splice(index, 1)
        this.onRemove.emit(index)
    }


    uploadIndex = -1
    @ViewChild('upload_hidden') upload_hidden;


    uploadOptions() {
        return this.authService.uploadOptions();
    }

    handleUpload(data): void {
        if (data && data.response) {
            data = JSON.parse(data.response);
            let link = data.src;

            if (this.uploadIndex >= 0 && this.uploadIndex < this.rowcount) {
                this.innerValue[this.uploadIndex].link = link;
            }
            this.uploadIndex = -1;
        }
    }


    getLink(row) {
        //  console.log('link', this.innerValue[row].link)
        this.uploadIndex = row;

        this.upload_hidden.nativeElement.click();

    }

//   */

//    @Input('disabled') disabled = false


    isdisabled() {
        /*
        if (this.elemType == 'USER' && this.elemLink == 'USER') {
            if (!this.authService.can_user_list()) {
                return true
            }
        }
        */
        return this.disabled ? true : undefined
    }


    //   ngAfterViewInit() {

    ngOnInit() {

        //this.initSelect4()

       // console.log('ngOnInit')

        this.initSelect2()

    }


    /*
        initSelect4() {

            //    console.log('initSlect4')

            $(this.el.nativeElement).find("select.select5").each((ind, domElement) => {

                this.select2_on(domElement)

                let ops = this.select2Options

                console.log(ops.ajax.url)
                $(domElement).select2(ops) // this.select2Options)

                $(domElement).find('select2-search__field').each((ind,domField) => {
                    $(domField).addClass('.select2-search__add')
                })

                let s = this.innerValue;

                let val = s ? +s : null;
                $(domElement).val(val).trigger('change')
            })

        }
        */


    select2_on(domElement) {


        if (!$(domElement).hasClass("select2-hidden-accessible")) {
            //  if (!this.initSelect2) {
            $(domElement).on('select2:select', (e) => {
                // e.preventDefault()
                //console.log('select2:select')
                this.getSelect2(e);
            });


            $(domElement).on('change', () =>{  // when the value changes
                if ($(domElement).hasClass('invalid')) {
                    $(domElement).valid(); // trigger validation on this element
                }
              //  this.updateRequiredValues()
            });

            let addButtonText = this.addButtonText
            //console.log('select2_on',this.elemType,this.elemLink,this.onAdd.observers.length,addButtonText)

            if (addButtonText) {
                $(domElement).on('select2:opening', () => {  // when the value changes


                    var nome = $(domElement).data('select2').$dropdown.find("input");


                    nome.each((ind, domField) => {
                        if (!$(domField).hasClass('select2-search__add')) {
                            $(domField).addClass('select2-search__add')

                            var $a = $('<a href="javascript:void(0)" class="select2__add"></a>').text(addButtonText)
                            $(domField).after($a)
                            //let $a = $(domField).after('<a href="javascript:void(0)" class="select2__add">Add New Patient</a>');
                            $a.on('click', () => {

                                var $select2 = $(this.el.nativeElement).find("select.select4")


                                var term = $select2.data('select2').$dropdown.find("input").val();

                                $select2.select2('close')
                                /*
                                if (this.onAdd.observers.length > 0) {
                                    this.addEmit(term)
                                    //   this.onAdd.emit(term)
                                }
                                else {
                                    if (this.elemLink == 'PATIENT') {
                                        this.addPatient(term)
                                    }
                                    // this.authService.showMessage(term)
                                }
                                */
                            })
                            //   console.log($a)

                        }
                    })
                    /*
                    <a style="
                    display: inline-block;
                    right: 1rem;
                     min-width: 80px;
                    position: absolute;
                    top: 6px;
                    float: right;
                    ">ADD New patient</a>
                        */

                });
            }

            /*
            $(domElement).on('select2:unselecting', ($event) => {
                console.log('unselecting',$event)
                $event.preventDefault()

                $(this.el.nativeElement).find('select ').each((ind,domElement) => {

                    $(domElement).val('').trigger('change')
                    this.value = ''
                    this.selected.emit(this.value)

                })

            });
            */

            $(domElement).on('select2:clearing', ($event) => {

                $event.preventDefault()

                $(this.el.nativeElement).find('select ').each((ind, domElement) => {

                    // console.log('clearing', $event)

                   let dis = this.required_values_array()

                    let vals = (this.value ? this.value.toString().split(',') : [])

                    let rest = vals.filter(item=> { return dis.includes(item)})

                    let new_val = rest.join(',')

                    //console.log(dis,vals,rest)

              //      $(domElement).val(null).trigger('change')
                    $(domElement).val(new_val).trigger('change')
                    this.updateRequiredValues()

                    this.value = new_val
                    this.selected.emit(this.value)



                })

            });


            $(domElement).on('select2:unselect', (e) => {

                // prevent dropdown https://github.com/select2/select2/issues/3209#issuecomment-149663474
                if (!e.params.originalEvent) {
                    return;
                }
                e.params.originalEvent.stopPropagation();

                this.getSelect2(e);
            });


           $(domElement).on('select2:selecting', function(e) {
               /*
               var cur = e.params.args.data.id;
               var old = (e.target.value == '') ? [cur] : $(e.target).val().concat([cur]);
               $(e.target).val(old).trigger('change');
               $(e.params.args.originalEvent.currentTarget).attr('aria-selected', 'true');
               return false;
               */
              // console.log('select2:selecting')
           })


            $(domElement).on('select2:unselecting', (e) => {

                if (e.params.args && e.params.args.data) {
                    let dis = this.required_values_array()

                    //console.log(dis,e.params.args.data.id)
                    let disabled = dis.includes(e.params.args.data.id)

                    if (disabled) {
                        e.preventDefault();
                        $(domElement).select2("close");
                        return;
                    }
                }

                if (e.params.args.originalEvent) {
                    let currentTarget = e.params.args.originalEvent.currentTarget;

                    if (currentTarget === undefined) {
                        e.preventDefault();
                    }
                    else if (currentTarget.nodeName === 'LI') {
                        e.preventDefault();
                        $(domElement).select2("close");
                    }
                }

            });

            $(domElement).on('select2:open', (e) => {

                // $('.select2-container--open .select2-dropdown--below').css('display','none')
            });


            let elem = $(this.el.nativeElement); // .children('select');
      //      console.log(elem)
            elem.on('keyup', 'input.select2-search__field', (e)=> {
                //console.log(e.witch)
                if (e.which === 13) {
                    let $search = $(this.el.nativeElement).find('input.select2-search__field')
                    if ($search.length) {
                         let tag = $search[0].value.trim()
                         if (tag && (this.mode == 'taged')) {
                            // console.log('enter',tag)
                             this.addTag()
                         }

                    }


                   // alert('Pressed enter!');
                }
            });



        }

        //   $('input.select2-search__field').on('keyup', function() {
        //      console.log('search',$(this).val()); // it alerts with the string that the user is searching
        //   });



        //let elem = $(this.el.nativeElement).find('.select2-search__field');
        //console.log(elem)
        /*
        elem.on('keyup',e=> {
            // if (event.keyCode == 13) {

            console.log('keypress',e)
            // }
        });
        */
        /*
        $(document).on('keyup', '.select2-search__field', function (e) {
            if (e.which === 13) {
                alert('Pressed enter!');
            }
        });
        */

    }


    selectTemplate() {
        return null
    }  //null
    /*{
       ICD10:  (item) => {
           if (item.loading) {
               return item.text;
           }
           //var term = this.query.term || '';
           //console.log(item)
           //var term = 'H04'
           let text = item.id+'\t'+item.text
           // var $result = markMatch(text, term);
           return text;
       },


   }
   */

    query: any = {};

    @Input('shadow') shadow = 0

    disableStyle() {
        if (this.shadow && this.isdisabled()) {
            return {color: '#fafafa'}
        }
        return {}

    }


    @Input('placeholder') placeholder = ' ';
    @Input('minimumInputLength') minimumInputLength = 0

    @Input('taged') taged = ''

    get istaged() {
        return this.taged //this.taged.observers.length > 0
    }


    /*
        get select2Options() {

            let allowClear = this.clearButton && !this.disabled

            let options :any = {

                placeholder: this.placeholder,

                allowClear: allowClear,
                minimumInputLength: this.minimumInputLength,


                language: {

                    loadingMore: function () {
                        return 'More results…';
                    },


                    searching: (params) => {
                        // console.log(params)
                        this.query = params; // !!!
                        return 'Searching…';
                    }
                },

              tags: this.istaged,

               insertTag: (data, tag) => {
                  //                        var $options = this.$element.find('option[data-select2-tag]');

                    //    var $options = $(this).find('option[data-select2-tag]');

                    // Insert the tag at the end of the results
                    //console.log(data)
                    data.push(tag);
                    //data.unshift(tag)  // default
                }



            }

            options.ajax = this.ajaxOption
                options.scrollAfterSelect = false
      //      }

            var template = this.selectTemplate() // [this.elemLink]
            if (!template) {

                template = (item) => {
                    if (item.loading) {
                        return item.text;
                    }
                    var term = this.query.term || '';
                    //console.log(item)
                    //var term = 'H04'
                    // var $result = markMatch(item.text, term);
                    return item.text //$result;
                }

            }

            options.templateResult = (item) => {
                let text = template(item)
                var term = this.query.term || '';
                return text
              //  return markMatch(text, term);
            }

            options.templateSelection = template

            return options
        }

        get ajaxOption() {

           let ajax = {

                url:  this.getLinkUrl(),

                dataType: 'json',
                headers: this.authService.httpHeaders(), // {'Content-Type': 'application/json', 'X-CSRF-TOKEN': this.authService.token()},

                type: 'GET',

                allowClear: true,

                minimumInputLength: this.minimumInputLength,
                delay: 250,

                data: (params) => {



                    //  console.log($(this))
                    var term = params.term
                    if (!term) {
                        let $elem = $(this.el.nativeElement).find("select") // find("select.select5")
                        //     let val = $(this).val() // impo
                        let val = $elem.val() // impo
                        if (val && val instanceof String) {
                            term = val.substring(0,3)
                        }
                    }

                    let ops =
                        {
                            //      term: term,
                            filter: term,
                            page: params.page || 1
                        }

                    //if (this.options) {
                    //    ops = Object.assign(ops,this.options)
                   // }
                    return ops
                },

                processResults: (data,p) => {


                    for (let item of data.data) {

                        item.id = item.id || item.code
                        item.text = item.text || item.name
                    }

                    return {
                        results: data.data,
                        pagination: {
                            page: data.current_page,
                            more:  (data.to < data.total)
                        }

                    };
                },
                //cache: true

            }

            return ajax
        }

    */

    _disabled = false

    @Input('disabled') set disabled(value) {

        if (this._disabled != value) {

            this._disabled = value

            //  if (this.elemType == 'USER') {

            if (this.clearButton) {

                $(this.el.nativeElement).find("select.select5").select2({allowClear: !value})
            }
            /*
            $(this.el.nativeElement).find("select.select5").each((ind, domElement) => {


                $(domElement).select2({ allowClear: !value})

            })
            */
            //   }
        }
    }

    get disabled() {
        return this._disabled
    }

    @Input('clearButton') clearButton = false;

    @Output('selected') public selected: EventEmitter<any> = new EventEmitter();

    findSelect2(callback) {

        $(this.el.nativeElement).children('select').each((ind1, elem1) => {

            callback(elem1)

        })

        /*
                $(this.el.nativeElement).children('div.form-group').each((ind1,elem1)=> {

                    $(elem1).find('select').each((ind2, elem2) => {
                        callback(elem2)
                    })

                })
                */
    }


    _index = []

    //   indexHasGroups = false


    get index() {
        return this._index
    }

    @Input('index') set index(value) {

  //     console.log('set index', value)

        if (this._index.length == 0) {

            this._index = value || []

            this.initSelect2()  // SP++ 20190921

            //this.initSelect3()

            //this.initSelect4()

        }
    }

    get addButtonText() {
        /*
                let _add = this._add || (this.options ? this.options.add : false)

                if (_add || this.onAdd.observers.length > 0) {
                    let s = this.selectAdd[this.elemLink]
                    return s===undefined ? this._addButton : s
                }
                */
        return ''
    }

    //   @Output('onAdd') public onAdd: EventEmitter<any> = new EventEmitter();


    selectTag($event) {
        this.selected.emit($event)
    }


    select2_tree = false

    select2_addTree() {

       // let dis = this.required_values_array()

        let hasGroup = false
        for (let item of this._index) {
            item.text = item.name ? item.name : ''
            if (item.group) hasGroup = true


         //   item.info = 'info' + item.id
         //   item.disabled = dis.includes(item.id.toString())

          //  if (item.disabled) {
          //     // console.log('disabled',item)
          //  }
        }
      //  console.log(this._index,dis)
        this.select2_tree = hasGroup

        return this._index

        let data = []
        for (let item of this._index) {
            if (item.children) {

                this.select2_tree = true

                let children = []
                for (let sub_item of item.children) {
                    children.push({text: sub_item.name, id: +sub_item.id})
                }
                data.push({text: item.name, children: children})
            }
            else {
                //              data.push({text: item.name, id: +item.id})
                data.push({text: item.name, id: item.id}) // 201909
            }
        }

        return data

    }

    get singleSelection() {
        return ['one','taged'].includes(this.mode)
    }
/*
    public status: Subject <any> = new Subject<any>( ) ;

    ngOnInit() {
        this.status.subscribe(
            result => { console.log(1) ; this.updateRequiredValues()}
        )
    }
*/

    getSelect2Options() {

        let data = this.select2_addTree()

   //     console.log('data',data)

        let opt = {

            placeholder: this.placeholder,
            //width: 'resolve',

            allowClear: !this.singleSelection,

            data: data,

            templateSelection: (state) => {
                if (+state.id < 0) {
                    let $state = $(
                        //    '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
                        '<span style="color:#e6cd9a;">' + this.tag + '</span>'
                    )
                    return $state;

                }


                if (this.select2_tree) {   // no required_
                    if (state.group) {
                        return state.text.toUpperCase()
                    }
                    return state.text;
                }

                if (this.required_values_array().find( item => {return +item==+state.id})) {
                   // console.log('templateSelectio')
                    let $state = $(
                        '<span class="selected_required__value">' + state.text + '</span>'
                    )

                    if (this._init_required_values) {
                        //this._init_required_values = false
                        setTimeout(() => {
                            this.updateRequiredValues()
                        }, 0)
                    }
                    //this.status.next(1)

                   // let li = $(this.el.nativeElement).find('li.select2-selection__choice[data-select2-id="' + state.id +'"]');
                   // let li = $(this.el.nativeElement).find('li.select2-selection__choice[data-select2-id="' + state.id +'"]');
                   // console.log('li',li)
                    return $state

                }
                else {
                    return state.text;

                  //  let $state = $(
                  //      '<span style="margin-left: 2rem;">' + state.text + '</span>'
                  //  )
                }
                //return $state;
            },


            templateResult: (state) => {
                if (+state.id < 0) {
                    let $state = $(
                        //    '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
                    //    '<span>Ext.:</span><span style="color:#e6cd9a;">' + this.tag + '</span>'
                        '<span style="color:#e6cd9a;">' + this.tag + '</span>'
                    )
                    return $state;

                }

                if ((!state.id) || (!this.select2_tree)) {
                    return state.text;
                }
                //var baseUrl = "/user/pages/images/flags";

                var $state = null

                if (state.group) {

                    $state = $(
                        //    '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
                        '<span style="font-weight: 700;">' + state.text.toUpperCase() + '</span>'
                    )
                }
                else {
                    $state = $(
                        //    '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
                        '<span style="margin-left: 2rem;">' + state.text + '</span>'
                    )

                }
                return $state;
            }
            ,

            matcher: (params, data) => {
                // If there are no search terms, return all of the data

                if (+data.id == -1 ) {
                    if (!this.tag) {
                        return null;
                    }

                    if ($.trim(params.term) === '') {
                        return data;
                    }

                    if (this.tag.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                        return data;
                    }
                    return null;
                }

                // console.log('match',data)
                if ($.trim(params.term) === '') {
                   // $('.select2-container--open .select2-dropdown--below').css('display', 'block')
                    //           console.log('display:block')
                    return data;
                }
/*
                if (

                    typeof
                        data
                            .text
                    ===
                    'undefined'
                ) {
                    return
                    null;
                }
*/
// `params.term` should be the term that is used for searching
// `data.text` is the text that is displayed for the data object
                if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                   // $('.select2-container--open .select2-dropdown--below').css('display', 'block')
                    //         console.log('display:block')
                    return data;
                }

// checking if a value matches (my addition to their example)
//if (data.data.some(({Value}) => Value.includes(params.text)) {
//    return data;
//}

// Return `null` if the term should not be displayed
                return null;
            },

            language: {

                loadingMore: function () {
                    return 'More results…';
                }
                ,


                searching: (params) => {
                    // console.log(params)
                    this.query = params; // !!!
                    return 'Searching…';
                },

                noResults:
                    () => {
                        if (this.mode == 'taged') {

/*
                            let s = '\
                                <a  class="mr-n-lg" role="button" > \
                                <span class="margin-link-value-remove"> \
                                <small> \
                                    <button class="btn-link mr-n-lg" role="button"><i class="fa fa-plus-circle"></i>&nbsp;Add external</button> \
                                </small> \
                                </span> \
                                 \
                                </a> \
                                \
                                ';  // last extra </a> !!!

*/
                            //let s = '<button class="btn-link">No Result Found</button>';

                            let s = '\
                                <a  class="" role="button" > \
                                <span class=""> \
                                <small> \
                                    <button class="btn-link" role="button"><i class="fa fa-exclamation-circle"></i>&nbsp;Add simple text</button> \
                                </small> \
                                </span> \
                                 \
                                </a> \
                                \
                                ';  // last extra </a> !!!

                            let $a = $(s)

                            $a.on('click', () => {
                                this.addTag()
                            });

                            return $a

                            /*
                          let s =  '\
                            <a  class="mr-n-lg" role="button" > \
                            <span class="margin-link-value-remove"> \
                            <small> \
                                <button class="btn-link mr-n-lg" role="button" (click)="addTag()" ><i class="fa fa-plus-circle"></i>&nbsp;Add external</button> \
                            </small> \
                            </span> \
                             \
                            </a> \
                            \
                            ';  // last extra </a> !!!

                            */

                            //return s
                        }
                        return 'No results found';
                    },
            }
            ,

            //escapeMarkup: function (markup) {  // no HTML strip
            //    return markup;
            //}
            //,


//+++++  NEW

            maximumSelectionLength: 0, // 2, // 1,


            //tokenSeparators: null,

            //tags: true,

            insertTag:
                (data, tag) => {

                    console.log('insert tag')
                    data.unshift(tag)
                    data.sort((a, b) => {
                        return a.text.localeCompare(b.text)
                    })
                    return

                    let opt = data.find(item => {
                        return item.id == tag.id
                    })
                    if (opt) {
                        console.log('found')
                        opt.name = tag.text
                        opt.text = tag.text
                    }
                    else {
                        data.unshift(tag)
                    }

                    //this.index.sort((a,b)=> { return a.name.localeCompare(b.name)
                    //  if (a < b) return -1;
                    //  if (a==b) return 0;
                    //  return 1
                    //})

                    data.sort((a, b) => {
                        return a.text.localeCompare(b.text)
                    })


                    /*         let $elem = $(this.el.nativeElement).find("select.select2")

                         if ($elem) {
                             var $options = $elem.find('option[data-select2-tag]')
                             $elem.prepend($options)
                         }
                        */

                },


            createTag:
                (params) => {
                    // Don't offset to create a tag if there is no @ symbol
                    //if (params.term.indexOf('@') === -1) {
                    //    // Return null to disable tag creation
                    //    return null;
                    //}
                    //return null

                    this.tag = params.text

                    console.log('create tag', params)
                    /*
                                        let opt = this.index.find(item=> {return item.id == -1})
                                        if (opt) {
                                            opt.name = params.term
                                            opt.text = params.term
                                            //this._index.sort((a,b)=> { return a.name.localeCompare(b.name)
                                              //  if (a < b) return -1;
                                              //  if (a==b) return 0;
                                              //  return 1
                                            //})
                                           // return null
                                        }
                                        else {
                                            this._index.push({ id:-1,name: params.term, text: params.term})

                                        }
                                        this._index.sort((a,b)=> { return a.name.localeCompare(b.name) })
                    */
                    return {
                        id: -1, // params.term,
                        text: params.term
                    }
                }
//++++++++++++++++   NEW
        }

        return opt

    }

    initSelect2() {

        $(this.el.nativeElement).find("select.select2").each((ind, domElement) => {

            let data = this.select2_addTree()

            this.select2_on(domElement)
            $(domElement).select2(this.getSelect2Options())

            let s = this.innerValue

            // console.log('set Index',s)

            let val = s ? s.split(',').filter((e) => {
                return +e
            }) : [];
            $(domElement).val(val).trigger('change')

            //console.log('init select2', s)


        })

    }

    reInitSelect2() {
        $(this.el.nativeElement).find("select.select2").each((ind, domElement) => {

          //  let data = this.select2_addTree()

            //this.select2_on(domElement)
            $(domElement).select2(this.getSelect2Options())

/*            let s = this.innerValue

            // console.log('set Index',s)

            let val = s ? s.split(',').filter((e) => {
                return +e
            }) : [];
            $(domElement).val(val).trigger('change')

            //console.log('init select2', s)

*/
        })

    }

    isGroup(val) {
        let elem = this._index.find(item=> {return val == item.id})
        //console.log('isGroup',val,elem)
        return elem.group

    }

    getChildren(val) {
       if (this.isGroup(val))  {
           let res =[]
           let push = false
           this._index.forEach( item => {
               if (item.id == val) {
                   push = true
               }
               else {
                   if (item.group) {
                       push = false
                   }
                   else {
                       if (push) {
                           res.push(+item.id)
                       }
                   }
               }
           })
           return res
       }

       return []
    }



    treeParent(new_value,value) {
        //console.log('treeParent',new_value, this.getChildren(new_value),value,
        //    this.getChildren(new_value).includes(+value))
        return this.getChildren(new_value).includes(+value)
    }

    treeChild(new_value,value) {
        return this.getChildren(value).includes(+new_value)
    }



    getSelect2($event) {

        $event.preventDefault()

        this.findSelect2((domElement) => {

            let v = $(domElement).select2('val');

            this.updateRequiredValues()

            if (((domElement).hasAttribute('multiple'))) { // here always


                let arr = v ? v : [];

                if (this.singleSelection) {  // todo: disable group selection

                    let old_arr = this.value ? this.value.toString().split(',') : []
                    for (let val of old_arr) {
                        let ind = arr.indexOf(val)
                        if (ind >= 0) {
                            arr.splice(ind, 1);
                        }
                    }
                    this.value = arr.length ? arr[0] : ''
                    $(domElement).val(this.value).trigger('change');
                }
                else {
                    if (this.select2_tree) {
                        let old_arr = this.value ? this.value.toString().split(',') : []
                        let new_values = arr.filter(item=> {return !old_arr.includes(item)})
                        //console.log(old_arr,new_values)
                        for (let new_value of new_values) {

                            arr = arr.filter(value =>
                            { return (!this.treeParent(new_value,value))
                                && (!this.treeChild(new_value,value))
                            })

                        }

                     //   console.log('select val',arr)
                        this.value = arr.join(',')
                        $(domElement).val(arr).trigger('change');

                    }
                    else
                    {
                        this.value = arr.join(',')

                    }
                }

            }
            else {
                this.value = v
            }

            this.selected.emit(this.value) // ->getList


            //this.taged

            /*
            var $options = $(domElement).find('option[data-select2-tag]');

            $options.each((ind,option)  => {

                //if ((option).hasAttribute()'selected')) {

                //console.log('option', option.value)

                if (option.value == $event.params.data.id) {
                    //if ($(option).selected) {
                    let text = $(option).text()

                    //this.taged.emit(text)
                    // this.item.user_id = null;
                    // this.item.user_name = $event;

                    this.parent.item[this.taged] = text;


                    this.onChangeCallback(null);


                    //   console.log('emit tag ', text,$event)
                    //}
                }
                //})


            })

            */
            /*
                            if (this.value == '' ) {
                            console.log('getSelect2',this.value)

                            $(domElement).find(':selected').each((ind, option) => {
                                let text = $(option).text()
                                this.taged.emit(text)

                                console.log('emit',text)
                            })

                        }
                        */
        })
    }

    //@Input('multiple') multiple = 0

    @Input('allowClear') allowClear = true


    @Input('mode') mode: 'multi' | 'one' | 'taged' = 'multi'

    // [(ngTag)]

    innerTag = ''

    @Input()
    get tag() {
        return this.innerTag;
    }

    @Output() tagChange = new EventEmitter();

    set tag(val) {
        if (this.innerTag != val) {
            this.innerTag = val;
            this.tagChange.emit(this.innerTag);
        }
    }

    @ViewChild('win') win

    winTag = ''

    addTag() {

        this.findSelect2((domElement) => {

            this.winTag = ''
            let $search = $(this.el.nativeElement).find('input.select2-search__field')

            if ($search.length) {
                this.winTag = $search[0].value
            }
            $(domElement).select2('close');
            $search.blur()
        })


        this.win.open(() => {
            this.win.close()
            if (this.winTag) {
                this.tag = this.winTag //--> user_name

                // sorting...  index

                /*
                let opt =this._index.find((item)=> {return item.id == -1 })
                if ( opt ) {
                    opt.name = this.tag
                    console.log('opt',opt)
                }
                else {
                    this._index.push( { id: -1, name: this.tag})
                }
               // this._index.sort((a,b)=> { return a.name.localeCompare(b.name)})
                */
                this.findSelect2((domElement)=> {

                 //   $(domElement).select2(this.getSelect2Options())

                    $(domElement).val([-1]).trigger("change")
                    this.value = "-1"

                    //let data = $(domElement).select2('data')
                    //
                    //console.log(data)


                })

            }
           // console.log('winTag',this.winTag)
        })
    }


    pre_required = false
    @Input('required') required = false

    get attr_required() {
        return (this.pre_required || this.required) ? '': undefined
    }

    _required_values = ''
    _init_required_values = true

    updateRequiredValues() {
        $(this.el.nativeElement).find('.selected_required__value').prev().css('display','none')
        //console.log('updateRequiredValues')
    }

    required_values_array() {
        return (this.required_values ? this.required_values.toString().split(',') : [])
    }


    @Input('required_values') set required_values(val) {
        if (val != this._required_values) {
            this._required_values = val
           // this._init_required_values = true

      }

    }

    get required_values() {
        return this._required_values
    }
}