import {NgModule} from "@angular/core";
import {MomentPipe} from "./moment.pipe";
import {TimeDirective} from "./time.directive";
import { FieldFilterPipe } from './field-filter.pipe';
import {BodyService} from "./body.service";
import {NotificationService} from "./notification.service";
import {ToggleActiveDirective} from "./toggle-active.directive";

import {MonthPipe} from "./month.pipe"
import {DayPipe} from "./day.pipe"

import {LocalTimePipe} from "./local-time.pipe"

import {DatexPipe} from "./datex.pipe"
import {EhisImgSrcDirective} from "./ehis-img-src.directive";

@NgModule({
  declarations: [ToggleActiveDirective, MomentPipe, TimeDirective, FieldFilterPipe,

    MonthPipe,
    DayPipe,
    LocalTimePipe,
    DatexPipe,
    EhisImgSrcDirective,
  ],
  exports: [ToggleActiveDirective, MomentPipe, TimeDirective, FieldFilterPipe,

    MonthPipe,
    DayPipe,
    LocalTimePipe,

    DatexPipe,
    EhisImgSrcDirective
  ],
  providers: [BodyService, NotificationService]
})
export class UtilsModule{
  static forRoot(){
    return {
      ngModule: UtilsModule,
      providers: [BodyService, NotificationService]
    }
  }
}
