import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-select-user',
    template: `
       <select-item [data]="data._user"        
                    [item]= "data.paramItems" 
                    [name]="'user_id'" 
                    (change)="data.onSelectChange($event)">
       All users
       </select-item>

  `,
})
export class ListSelectUserComponent {

    @Input('data') data;
}

