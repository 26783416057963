import { Component, ViewEncapsulation, forwardRef } from '@angular/core';
import { Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

const noop = () => {};

import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'ehis-area',
    template:
  ` <div class="form-group row">
        <label for="area" [ngClass]="labelClass"><ng-content></ng-content></label>
        <div [ngClass] = "inputClass">
        <textarea id="area" type="text" class="form-control form-control-sm  pull-xs-left" placeholder="" [(ngModel)]="value"
                [readonly] = "readonly"
            rows = "rows"></textarea>
        </div>
    </div>
   `,

    encapsulation: ViewEncapsulation.None,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisAreaComponent), multi: true },
    ]
})
export class EhisAreaComponent implements ControlValueAccessor {

    @Input('readonly') readonly : boolean = false;


    private innerValue: any = '';

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled:boolean):void {    // new ???
    }

    // col-md classes ////////////////////////////////////////////////////////////////////////////////////////

    @Input('col-label') colWidth: number = 3;

    get labelClass() {
        let w = this.colWidth;
        return `form-control-label col-md-${w} col-xs-12 text-md-right`;
    }

    get inputClass() {
        let w = 12 - this.colWidth;
        return `col-md-${w} col-xs-12`;
    }

    // Province ////////////////////////////////////////////////////////////////////////////////////////
/*
    public data :Array<any> = [
        // {id: 1, name: 'Alabama',
        // {id: 4, name: 'Arkansas'},
    ];

    constructor(private authService:AuthService) {
    }

    public typeaheadOnSelect(e:any):void {

        if (e.item) {
            this.value   = e.item.name;
        }
    }

    ngOnInit() {
        this.authService.getReference('province')
          .subscribe(
            (result) => {this.data = result._province },
            (error) =>  {this.authService.showError(error)}
          );
    }

*/

    // Area  ////////////////////////////////////////////////////////////////////////////////////////


    @Input('rows') rows : number = 3;

}
