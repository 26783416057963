import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {DiagnosisService}   from "../diagnosis.service";

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'diagnosis-table',
  templateUrl: './diagnosis-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class DiagnosisTable extends BaseList {


  constructor(protected el: ElementRef,private ds: DiagnosisService) {
      super(el,ds);
  }

  @ViewChild('diagnosis_popup') diagnosis_popup
    get loading() {
      return this.ds.loading
    }

  clickAdd() {
      this.diagnosis_popup.open(0,this.paramItems, ()=> {
        this.refresh()
      })
  }

  clickItem(item) {
      this.diagnosis_popup.open(item.id,this.paramItems, ()=> {
          this.refresh()
      })

  }



}
