import {Component, ElementRef, Input} from '@angular/core';
//import { Subscription }   from 'rxjs/operators/Subscription';
//import {LoginInfoComponent} from "../../user/login-info/login-info.component";

import {AuthService} from "../../../auth/auth.service"
import {Router} from "@angular/router";
import {LayoutService} from "../layout.service";
import {Subscription} from "rxjs";
import { ApplicationStoreService } from 'app/application-store.service';
import { takeWhile } from 'rxjs/operators';
import {} from './../../../theme/theme/'
declare var $: any;

@Component({

  selector: 'sa-menu',
    template: `
        <nav *ngIf="menu">
        <ul class="{{bgcolor}}" [ngStyle]="{'font-family': fontStyle, 'background-color': sideBarBgColor}">

            <li *ngFor="let menuItem of menu" routerLinkActive="active">
                
                <a *ngIf="iMenu(menuItem)" [routerLink] ="menuLink(menuItem)" [title]="menuItem.name" [ngStyle]="{'color': sideBarFontColor}">
                    <i [ngClass]="menuItem.icon"> </i>

                    <span class="menu-item-parent">{{ menuItem.name | i18n}}</span>

                    <span *ngIf = "taskCount!=undefined && menuItem.badge=='task-count'"
                          class="badge inbox-badge bg-color-red margin-right-13 pull-right" style="margin-left:1rem;"
                    >{{taskCount}}
                    </span>

                </a>

                <a *ngIf="httpLink(menuItem)" href = "{{menuLink(menuItem)}}" target ="_blank" [ngStyle]="{'color': sideBarFontColor}">
                    <img *ngIf="assetsLink(menuItem)" src="{{menuItem.icon}}" style="margin-left:4px;margin-right:10px;margin-top: -2px;"
                         width="16" height="16">
                    <i *ngIf="!assetsLink(menuItem)" [ngClass]="menuItem.icon" [ngStyle]="iconStyle(menuItem)"></i>

                    <span class="menu-item-parent" [ngStyle]="iconStyle(menuItem)">{{ menuItem.name | i18n}}</span>
                </a>

                <a *ngIf="subMenu(menuItem)" href="#" [ngStyle]="{'color': sideBarFontColor}">
                    <i [ngClass]="menuItem.icon"> </i> <span class="menu-item-parent">{{menuItem.name| i18n}}</span>
                </a>

                <!--
                <a *ngIf="subMenu(menuItem)" href="#" (click)="toggle(menuItem)">
                    <i [ngClass]="menuItem.icon"> </i> <span class="menu-item-parent">{{menuItem.name| i18n}}</span>
                    <b  class="collapse-sign"><em class="fa fa-plus-square-o"></em></b>

                </a>
                -->



                <ul *ngIf="subMenu(menuItem)">  <!-- todo: recursive component -->

                    
                    <li *ngFor="let subItem of menuItem.children" routerLinkActive="active">
                        <!--
                        <a *ngIf="subMenu(subItem)" href="#">
                            <i [ngClass]="subItem.icon"></i>
                            <span class="menu-item-parent">{{ subItem.name | i18n}}</span>
                        </a>
                        -->
                        <a *ngIf="iMenu(subItem)" [routerLink] ="menuLink(subItem)" [title]="subItem.name" [ngStyle]="{'color': sideBarFontColor}">
                           <!-- <i [ngClass]="subItem.icon"></i>  -->
                            <span class="menu-item-parent">{{ subItem.name | i18n}}</span>
                        </a>

                        <a *ngIf="httpLink(subItem)" href = "{{menuLink(subItem)}}"  target ="_blank" [ngStyle]="{'color': sideBarFontColor}">

                            <img *ngIf="assetsLink(subItem)" src="{{subItem.icon}}" style="margin-left:4px;margin-right:10px;margin-top: -2px;"
                                 width="16" height="16">
                            <i *ngIf="!assetsLink(subItem)" [ngClass]="subItem.icon" [ngStyle]="iconStyle(subItem)"></i>

                            <span class="menu-item-parent">{{ subItem.name | i18n}}</span>
                        </a>

                    </li>


 
                </ul>


            </li>
            <sa-minify-menu></sa-minify-menu>

        </ul>    
        </nav>
 `
})
export class MenuComponent {


  menu : any = null;

  taskCount:any = undefined;

  sideBarBgColor = '';
  sideBarFontColor = '';
  fontStyle = '';

//  constructor(public authService:AuthService) {
 // }

    private $el:any;
    alive = true;

    constructor(
    private el:ElementRef,
    private router: Router,
 //   public layoutService:LayoutService,
    public authService: AuthService,
    private appStore: ApplicationStoreService
) {
        this.$el = $(this.el.nativeElement);

      this.appStore.appState$
      .pipe(takeWhile(() => this.alive))
      .subscribe(appUserState => {
          this.sideBarBgColor = appUserState.sideBarColor;
          this.sideBarFontColor = appUserState.sideBarFontColor;
          this.fontStyle = appUserState.fontStyle;
      });

    }


    isClient() {
    return this.authService.isClient();
  }

  isAdmin() {
    return this.authService.isadmin();
  }

  isAdmin0() {
    return this.authService.isadmin0();
  }

  isAccountant() {
    return this.authService.isAccountant();
  }

  get isDeveloper() {
    return this.authService.isDeveloper();
  }

  get isguest() {
    return this.authService.isguest();
  }


  get admin() { return this.authService.isadmin(); }
  //--------------------------------------------------------------

  get menuChartTitle() {
    return this.isAdmin() ? 'Chart records' : 'My charts';
  }

  get menuTaskTitle() {
    return this.isAdmin() ? 'Task managment' : 'Task managment';
  }

  hasChildren(item) {
    if (item.children) return item.children.length > 0;
    return false;
  }

  menuLink(item) {

    return item.path;

    //let a = item.path.split('/')
    //if (a[0]=='') {a[0] = '/' } else {a= a.unshift('/')}
    //return a;
  }

  iconStyle(item) {
    if (item.style) {
      let a= item.style.split(';').filter(item=>item)
      let b: any = {}
      /*
      for (let item of a) {

        let c = item.split(':')
        b[c[0]]= c[1]
      } */

      a.forEach((item)=>{let c = item.split(':'); b[c[0]]= c[1]})
      //return b[0]
      //console.log(b)
      return b

    }
    return {}
  }

  httpLink(item) {

    let r = item.path ? item.path.startsWith('http') : false;

    return r && item.acc;
  }

    assetsLink(item) {

        let r = item.icon ? item.icon.startsWith('assets/') : false;

      /*  if (r) {
            console.log(item.icon)
        }
        */
        return r && item.acc;
    }


    subMenu(item) {
   //   let res= item.acc && !this.httpLink(item)  && this.hasChildren(item)
      let res= item.acc  && this.hasChildren(item)
//    console.log(res)
      return res
  }

  iMenu(item) {
//    console.log(item)
    return item.acc && !this.httpLink(item)  && !this.hasChildren(item)
  }

/*
    ngAfterContentInit() {
        // viewChild is set after the view has been initialized
    //    this.logIt('AfterViewInit');
        this.updateSubMenu()
    //    console.log('menu after content init')

        //     this.doSomething();
    }
*/
    updateSubMenu() {
        setTimeout(() => {
         //   this.menu = this.layoutService.getMenu()
            this.updateSubMenu2()
       //     console.log('layount')
        }, 10);
    }


updateSubMenu2() {

      //  if(!this.togglersBinded){
            this.$el.find('li:has(> ul)').each((i, li)=> {
                let $menuItem = $(li);
                let $a = $menuItem.find('>a');
                if (!$a.find('>.collapse-sign>em').length) {
                    let sign = $('<b class="collapse-sign"><em class="fa fa-plus-square-o"/></b>');

                //    console.log($a)

                    $a.on('click', (e) => {
                        this.toggle($menuItem);
                        e.stopPropagation();
                        return false;
                    }).append(sign);
                }
            });
       //     this.togglersBinded = true;

       // }
    }



   private toggle($el, condition = !$el.data('open')) {

    $el.toggleClass('open', condition);

    if(condition){
        $el.find('>ul').slideDown();
    } else {
        $el.find('>ul').slideUp();
    }

    $el.find('>a>.collapse-sign>em')
        .toggleClass('fa-plus-square-o', !condition)
        .toggleClass('fa-minus-square-o', condition);

    $el.data('open', condition);

    if (condition) {
        $el.siblings('.open').each((i, it)=> {
            let sib = $(it);
            this.toggle(sib, false)
        })
    }
}

//    layoutSub
 //   routerSub

    private userSubscription: Subscription;


    ngOnInit() {

        this.userSubscription = this.authService.userSubscribe(()=> {
            this.menu = this.authService.getMenu();
            this.appStore.setAppMenu(this.menu);
            this.updateSubMenu()
            this.taskCount = this.authService.taskCount;
        })

        /*
                this.menu = this.layoutService.getMenu()

                this.layoutSub = this.layoutService.subscribe((store)=> {
                 //   setTimeout(()=>{
                        this.menu = this.layoutService.getMenu()
                        this.updateSubMenu()
                      //  console.log('layount')
                 //   }, 10);


                });

                this.routerSub = this.router.events.subscribe((event: any)=>{

                    setTimeout(()=>{
                        this.updateSubMenu()
                        console.log('router')
                    }, 1);
                    this.routerSub.unsubscribe()
                });
        */


    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe()
        //    this.layoutSub.unsubscribe();
    }

}
