import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {ProfessionalService}   from "../professional.service";

import {BaseList}       from '../../auth/base-list'
import {ProfessionalList} from "../list/professional-list.component";

@Component({
  selector: 'professional-table',
  templateUrl: './professional-table.template.html',
})
export class ProfessionalTable extends ProfessionalList { // extends BaseList {

    /*
  constructor(el: ElementRef,private ds: ProfessionalService) {
      super(el,ds);
  }


    @ViewChild('add_popup') add_popup
    get loading() {
        return this.ds.loading
    }

    clickAdd() {
        this.add_popup.open(0,this.paramItems, ()=> {
            this.ds.authService.showMessage('Share Chart task created!')
        })
    }
    */

}
