import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import {LoginInfoComponent} from "./login-info/login-info.component";
import {RouterModule} from "@angular/router";
import {SelectItemComponent} from "./select-item.component";

import {ListSelectProductComponent} from "./list-select-product.component";
import {ListSelectPeriodComponent} from "./list-select-period.component";
import {ListSelectUserComponent} from "./list-select-user.component";
import {ListSelectStateComponent} from "./list-select-state.component";
import {SelectButtonComponent} from "./select-button.component";

//import {LogoutComponent} from "./logout/logout.component";
import {ListSelectComponent} from "./list-select/list-select.component";

import {ButtonsModule, TabsModule, TypeaheadModule} from 'ngx-bootstrap';
import {ListPagerComponent} from "./list-pager/list-pager.component"; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!

import { PaginationModule  } from 'ngx-bootstrap';
import {PeriodComponent} from "./period/period.component";
import {PeriodAsideComponent} from "./period-aside/period-aside.component";

//import {SmartadminModule} from '../shared/smartadmin.module'


import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
//import {SmartadminModule} from '../shared/smartadmin.module'


import {PeriodMonthComponent} from "./period/period-month.component";
import {EhisDateComponent} from "./ehis-date.component";
//import {EhisTimeComponent} from "./ehis-time.component";
import {EhisPeriodComponent} from "./ehis_period.component";
import {ListSelectHeaderComponent} from "./list-select-header.component";
import {NumberPipe} from "./number.pipe";
import {UserAccountComponent} from "./user-account.component";
import {ToCcBccComponent} from "./to_cc_bcc.component";
import {CcBccComponent} from "./cc_bcc.component";
import {KeysPipe} from "./keys.pipe";
import {ItemInputComponent} from "./item-input.component";
import {TextMaskModule} from "angular2-text-mask";
//import {WidgetDetail} from "./detail/widget-detail.component";
import {SmartadminWidgetsModule} from "../shared/widgets/smartadmin-widgets.module";
import {AuthModule} from "../auth/auth.module";

//import {FormTextareaComponent} from "./form/form-textarea.component";

//import {AttachmentComponent} from "./attachment.component"
//import {SmartadminModule} from '../shared/smartadmin.module'
//import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";

//import { Ng2UploaderModule } from 'ng2-uploader';
import { CurrencyMaskModule } from "ng2-currency-mask";


//import {LaddaModule} from "angular2-ladda";
//import {ItemComponent} from "./item.component";

import {PivotSelect} from "./pivot-select/pivot-select.component"
//import {AvailableHoursDialog} from "./available-hours-dialog/available-hours-dialog.component";
//import {AppointmentDialog} from "./appointemnt-dialog/appointment-dialog.component";
import {FlotChartModule} from "../shared/graphs/flot-chart/flot-chart.module";
import {LoadImageDirective} from "./load-image/load-image.directive";
import {ItemPatient} from "./item-patient.component";
//import {AuthValidateDirective} from "../auth/validation/validate.directive";
import {AuthValidClickDirective} from "../auth/validation/button.directive";
import {Loader} from "./loader/loader.component";

//import {CsvService} from "../auth/csv/csv.service";

import {Popup} from "./dialog/dialog.component";
import {AddPatientDialog} from "./add-patient-dialog/add-patient-dialog.component";
import {AddressService} from "../address/address.service";
import {EhisControlModule} from "../ehis-control/ehis-control.module";
import {DatexPipe} from "./datex.pipe";
import {WeekHours} from "./week-hours/week-hours.component";
import {TimeSelect} from "./week-hours/time-select.directive";
import {Dynamic} from "./dynamic/dynamic.component";
import {DynamicArray} from "./dynamic-array/dynamic-array.component";
import {DynamicRadios} from "./dynamic-radios/dynamic-radios.component";
import {UtilsModule} from "../shared/utils/utils.module";
import {DynamicIndex} from "./dynamic-index/dynamic-index.component";
import {Ng2UploaderModule} from "ng2-uploader";
import {DragulaModule} from "ng2-dragula";
//import {SmartadminModule} from "../shared/smartadmin.module";
import {SmartadminLayoutModule} from "../shared/layout";
import {Switcher} from "./switcher/switcher.component";
import {UserIndex} from "./user-index/user-index.component";
import {Select2} from "./select2/select2.component";
import {StdPopup} from "./std-popup/std-popup.component";
import {CheckboxGroup} from "./checkbox-group/checkbox-group.component";

@NgModule({
  imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,


      ButtonsModule,
      TabsModule,
      TypeaheadModule.forRoot(),

      PaginationModule.forRoot(),

      SmartadminWidgetsModule,

   //   SmartadminModule,
      SmartadminInputModule,

      TextMaskModule,

   //   Ng2UploaderModule,

/*
      LaddaModule.forRoot({
          //style: "contract",
          //spinnerSize: 32,
          //spinnerColor: "red",
          //spinnerLines: 12
          //        "../node_modules/ladda/dist/ladda.min.css",

      }),

      LaddaModule,
*/
      CurrencyMaskModule,

      FlotChartModule,

      UtilsModule,
  //    AuthModule

      Ng2UploaderModule, // dynamic-index upload file vs. load-image directive : Unify!
      DragulaModule.forRoot(),

      //SmartadminLayoutModule

    //  SmartadminLayoutModule

  ],
  declarations: [
      LoginInfoComponent,

      SelectItemComponent,
      SelectButtonComponent,

      ListSelectComponent,
      ListPagerComponent,

      ListSelectUserComponent,
      ListSelectProductComponent,
      ListSelectPeriodComponent,
      ListSelectStateComponent,

      PeriodComponent,
      PeriodAsideComponent,

      PeriodMonthComponent,

   //   EhisTimeComponent,
      EhisDateComponent,
      EhisPeriodComponent,

      ListSelectHeaderComponent,

      NumberPipe,

      UserAccountComponent,

      ToCcBccComponent,
      CcBccComponent,

      KeysPipe,


 //     FormTextareaComponent,


//      LogoutComponent,
 //     AttachmentComponent,




      ItemInputComponent,
  //    ItemComponent,

      PivotSelect,

  //    AvailableHoursDialog,
 //     AppointmentDialog,

      LoadImageDirective,


      ItemPatient, // <item-patient>

  //    AuthValidateDirective,
      AuthValidClickDirective,

      //     OpenChartComponent,

      Loader,
      Popup,
      AddPatientDialog,

      DatexPipe,  //    several definitions


      WeekHours,
      TimeSelect,

      Dynamic,
      DynamicArray,
      DynamicIndex,
      DynamicRadios,

      Switcher,

      Select2,
      UserIndex,

      StdPopup,

      CheckboxGroup,

  ],
  exports: [
    LoginInfoComponent,
//    LogoutComponent

    SelectItemComponent,
    SelectButtonComponent,

      ListSelectComponent,
      ListPagerComponent,

      ListSelectUserComponent,
    ListSelectProductComponent,
    ListSelectPeriodComponent,
      ListSelectStateComponent,

      ListSelectHeaderComponent,

    PeriodComponent,
    PeriodAsideComponent,

   // EhisTimeComponent,
    EhisDateComponent,
    EhisPeriodComponent,

    NumberPipe,
    KeysPipe,
      DatexPipe,

    UserAccountComponent,

    ToCcBccComponent,
    CcBccComponent,

    ItemInputComponent,

 //     ItemComponent,

//      WidgetDetail,

 //     AttachmentComponent

  //    FormTextareaComponent

//      OpenChartComponent

      PivotSelect,

   //   AvailableHoursDialog,
  //    AppointmentDialog,

      LoadImageDirective,

    //  AuthValidateDirective,
      AuthValidClickDirective,

      Loader,
      Popup,
      AddPatientDialog,

  //    EhisControlModule   // datex pipe

      WeekHours,
      TimeSelect,

      Dynamic,
      DynamicArray,
      DynamicIndex,
      DynamicRadios,
      Switcher,

      //-------------------
      UserIndex,
      Select2,
      //-------------------
      StdPopup,

      CheckboxGroup

  ],
    providers: [

   //     AddressService
    ]

})
export class ControlModule{}
