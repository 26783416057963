import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as fromRoot from '../app/reducers/index';
import * as fromAppState from '../app/user.reducer';
import * as fromAppActions from '../app/user.actions';
import { select, Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApplicationStoreService {
  appState$: BehaviorSubject<fromAppState.AppState> = new BehaviorSubject(fromAppState.initialState);
  alive = true;

  constructor(
    private store: Store<fromRoot.State>
  ) 
  {

    this.store.pipe(
      select(fromRoot.getAppState),
      takeWhile(() => this.alive)
    ).subscribe(appUserState => this.appState$.next(appUserState));
   
  }

  public setUserName(val:any) {
    this.store.dispatch(new fromAppActions.setUserName(val));
  }

  public setUserTheme(val:string) {
    this.store.dispatch(new fromAppActions.setUserThenme(val));
  }

  public setsideBarColor(val:string) {
    this.store.dispatch(new fromAppActions.setsideBarColor(val));
  }

  public setfontStyle(val:string) {
    this.store.dispatch(new fromAppActions.setfontStyle(val));
  }

  public setpagesColor(val:string) {
    this.store.dispatch(new fromAppActions.setpagesColor(val));
  }

  public setsideBarFontColor(val:string) {
    this.store.dispatch(new fromAppActions.setsideBarFontColor(val));
  }

  public setpagesFontColor(val:string) {
    this.store.dispatch(new fromAppActions.setpagesFontColor(val));
  }

  public setthemeLogo(val:string) {
    this.store.dispatch(new fromAppActions.setthemeLogo(val));
  }

  public setAppMenu(val:any) {
    this.store.dispatch(new fromAppActions.setAppMenu(val));
  }
}
