import {Directive, Input, Output, ElementRef, EventEmitter } from '@angular/core';


import {AuthService} from "../../../auth/auth.service"

import * as Dropzone from "dropzone";
Dropzone.autoDiscover = false;

//declare var Dropzone: any;

@Directive({
  selector: '[saDropzone]'
})
export class DropzoneDirective {

  @Input() saDropzone:any;

  @Output() onUpload = new EventEmitter();

  @Input() disabled = false;

  @Input() set data(value) {
     //console.log('old',value)
    if (value) {
      let files = value; // JSON.parse(value)
      for (let file of files) {
       // file.accepted = true;
       // file.processing = true;
        //file.width = 3000,
       // file.height = 3000,
        this.dropzone.files.push(file)


        this.dropzone.emit("addedfile", file);
        if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(file.link)) {

          let url = this.authService.imageURL(file.link)
          this.dropzone.createThumbnailFromUrl(file,url,null,'anonymous')

        //  this.dropzone.createThumbnailFromUrl(file,"http://ehis/api/image/" + file.link,null,'anonymous')
       //   this.dropzone.emit("thumbnail", file, "http://ehis/api/image/" + file.link)

          //let dz = this.dropzone;

          //file.dataURL = "http://ehis/api/image/" + file.link;

         // console.log('thumbnail',dz.options)

         /* dz.createThumbnailFromUrl(file,
              dz.options.thumbnailWidth, dz.options.thumbnailHeight,
         //     "GET", true,
              function (thumbnail) {
                console.log('thumbnail',thumbnail)
                dz.emit('thumbnail', file, thumbnail);
              },
              'anonymous');
              */
/*
          dz.createThumbnailFromUrl(file,
              dz.options.thumbnailWidth, dz.options.thumbnailHeight,
              dz.options.thumbnailMethod, true, function (thumbnail) {
                dz.emit('thumbnail', file, thumbnail);
              },
              'anonymous');
*/
        }
        this.dropzone.emit("complete", file);
      }

    }


  }


  private dropzone:any;

  constructor(private el:ElementRef,private authService:AuthService) {
 //   System.import('dropzone').then((Dropzone)=> {
      this.initDropzone(Dropzone)
 //   })
  }

  getFileDesc(dropzoneFile) {
    return { name: dropzoneFile.name, link: dropzoneFile.link , status: dropzoneFile.status ,
      size: dropzoneFile.size, type: dropzoneFile.type}

  }

  uploadFiles() {
    let files = [];
    for (let elem of this.dropzone.files) {
      files.push(this.getFileDesc(elem))
    }
    this.onUpload.emit(files);

  }

  initDropzone(Dropzone) {


    Dropzone.autoDiscover = false;
    Dropzone.url = this.authService.uploadURL()

    let options = this.saDropzone || {};
    options.autoDiscover = false;
    options.url = this.authService.uploadURL();
    options.headers = { 'X-CSRF-TOKEN': this.authService.token() } ;
    options.addRemoveLinks = this.disabled ? false : true;

    this.dropzone = new Dropzone(this.el.nativeElement,options );

    if (this.disabled) {
      this.dropzone.disable();
    }

    this.dropzone.on("complete", (file) => {

      if (file.xhr) {
        file.link = JSON.parse(file.xhr.response).file;
      }

      this.uploadFiles();
    })


  this.dropzone.on("addedfile", (file) => {

        file.previewElement.addEventListener("click", () => {
          let strictSecurity = true;
          if (strictSecurity) {  // blob with autorization
            this.authService.downloadImage(file.link,file.name)
          }
          else { // direct download without security
            let w = window.open();
            w.location.href = this.authService.imageURL(file.link)
            w.document.title = file.name;
          }
        });
    });

    this.dropzone.on("removedfile", (file) => {

      this.uploadFiles()

    });

  }

}
