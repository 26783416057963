import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';

import { RouterModule } from '@angular/router';


import { SignupComponent } from './signup.component';

//export
///const routes = [
//  { path: '', component: Dashboard, pathMatch: 'full' }
//];

import {SmartadminModule} from "../shared/smartadmin.module"
//import {WidgetControlModule} from "../widget-control/widget-control.module"
import {ControlModule} from "../controls/controls.module"


import {RegisterService} from "./register.service"
import {WidgetModule} from "../widget/widget.module";

//export {SignupComponent}

@NgModule({
  imports: [
    CommonModule,
   // RouterModule.forChild(routes),

    SmartadminModule,
    ControlModule,
    //WidgetControlModule,
      WidgetModule
  ],
  declarations: [
    SignupComponent,
  ],
  exports: [
    SignupComponent
  ],
  providers: [
    //SaveGuard,
    RegisterService,
  ]

})
export class SignupModule {}
