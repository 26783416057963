/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import { Component,ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Output, Input } from '@angular/core';

declare var jQuery: any;


import {AuthService} from "../../auth/auth.module"
import {BaseItem} from "../../auth/base-item";

import {UserService} from '../../service/user.service';

@Component({
    selector: 'add-credentials',
    template:

    `      
    
                                        <section>
                                    <legend>Account Credentials</legend>
                                    </section>

                                    <div class="row">
                                        <section class="col col-6">

                                        <label class="label">E-mail</label>
                                        <label class="input">
                                            <i class="icon-append fa fa-envelope"></i>
                                            <input type="text" name="email" [(ngModel)]="item.email" required/>
                                        </label>
                                    </section>


                                    <section class="col col-6">
                                        <label *ngIf="item.mode!=3" class="label">Username</label>
                                        <label *ngIf="item.mode==3" class="label">Whois api username</label>
                                            <label class="input">
                                                <i class="icon-append fa fa-user"></i>
                                                <input type="text" name="username" [(ngModel)]="item.username" required/>
                                            </label>
                                        </section>
                                    </div>


                                    <div class="row">
                                        <section class="col col-6">
                                        <label *ngIf="item.mode!=3" class="label">Password</label>
                                            <label *ngIf="item.mode==3" class="label">Whois api password</label>

                                            <label class="input">
                                                <i class="icon-append fa fa-lock"></i>
                                                <input id="password" type="password" name="password"   [(ngModel)]="item.password"/>
                                            </label>
                                        </section>

                                        <section class="col col-6">
                                            <label class="label">Confirm password</label>
                                            <label class="input"> <i class="icon-append fa fa-lock"></i>
                                                <input type="password" name="password_confirm" placeholder="" [(ngModel)]="item.password_confirm"/>
                                            </label>
                                        </section>
                                    </div>

          
          
    `,

})
export class AddCredentialsComponent {

   @Input('data') item = { email: '', username: '', password: '', password_confirm: '', mode:1,};
//   @Input('mode') mode = 1;

}

