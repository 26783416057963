/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


declare var jQuery: any;


import {AuthService} from "../../auth/auth.module"
import {BaseItem} from "../../auth/base-item";

import {UserService} from '../../service/user.service';
import {AddUser} from "../list/add-user";


@Component({
    selector: '[wizard-client]',
    templateUrl: './wizard-client.template.html',
//    providers: [UserService],

})
export class WizardClientComponent extends AddUser{

   step = 0;

   _subscription_type = [];

   updateSubscription() {
       this._subscription_type = this.item._subscription_type.filter(elem=> elem.product_id==this.item.product_id);
       if (!this._subscription_type.some(elem=>elem.id == this.item.subscription_type_id)) {
           this.item.subscription_type_id = (this._subscription_type.length > 0 ) ? this._subscription_type[0].id : 0;
       }


   }

   next0() {
       this.baseService.authService.spinnerStart();
       this.userService.checkUserCredentials(this.item)
           .subscribe(result=> {
               this.baseService.authService.spinnerStop();
               if (result['exists']) {
                   this.baseService.authService.showError('User with such email or username already exists')
               } else {
                       this.step++;
                       this.updateSubscription();
               }
           },
           error=>{this.baseService.authService.spinnerStop();
           this.baseService.authService.showError(error);}
           )
   }

   productSelect($event) {
       this.updateSubscription();
   }

   productName() {
       return this.lookupName(this.item._product,this.item.product_id);

   }

    subscriptionName() {
        return this.lookupName(this.item._subscription_type,this.item.subscription_type_id);

    }
/*
    lookupSchema() {
        let id = this.item.subscription_type_id;
        for (let elem of this.item._subscription_type) {
            if (elem.id == id) {
                return +elem.plan_schema;
            }
        }
        return 0;
    }


    isYearly() {
       return this.lookupSchema() == 1;  // yearly schema subscription
    }

    isQuarterly() {
        return this.lookupSchema() == 4;
    }

    isMonthly() {
        return this.lookupSchema() == 3;  // monthly fixed price schema subscription
    }
*/

    next1() {
       if (this.item.product_id==1) {   // whois api
           this.baseService.authService.spinnerStart();
           this.userService.checkWhoisCredentials(this.item)
               .subscribe(result => {
                       this.baseService.authService.spinnerStop();
                       this.item.migrate = result['exists'] ;
                     this.step++;
                   },
                   error => {
                       this.baseService.authService.spinnerStop();
                       this.baseService.authService.showError(error);
                   }
               )
       }
       else {
           this.item.migrate = false;
           this.step++;
       }
   }

   next2() {


       this.step++;
   }

   prev() {
       this.step--;
   }


   constructor (el: ElementRef, route: ActivatedRoute, userService:UserService) {
        super(el,route,userService,10);
    }

}

