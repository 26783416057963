import {Component} from '@angular/core';
import { Subscription }   from 'rxjs';
//import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import {ClinicService} from "../../../billing/billing-service.service";

import {AuthService} from "../../../auth/auth.service"
import { ApplicationStoreService } from 'app/application-store.service';
import { takeWhile } from 'rxjs/operators';
@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {



  taskCount:any = undefined;
  taskSubscription: Subscription;
  invoicingEnabled = false;
  alive = true;
  sideBarBgColor : string = '';

  constructor(public authService:AuthService, private ClinicService: ClinicService,private appStore: ApplicationStoreService) {
    this.taskSubscription = authService.taskAnnounced$.subscribe((count) =>
        {
          if (!isNaN(count)) {
              this.taskCount = count

             // console.log('menu subscription', count)
          }
        });
        this.appStore.appState$
        .pipe(takeWhile(() => this.alive))
        .subscribe(appUserState => {
            this.sideBarBgColor = appUserState.sideBarColor;
        });
  }


  isClient() {
    return this.authService.isClient();
  }

  isAdmin() {
    return this.authService.isadmin();
  }

  isAdmin0() {
    return this.authService.isadmin0();
  }

  isAccountant() {
    return this.authService.isAccountant();
  }

  isInvoicingEnabled(callback?: Function) {
    if(this.ClinicService.clinicDetails) {
      this.invoicingEnabled = this.ClinicService.clinicDetails.inv_enabled;
      this.authService.spinnerStop()
      if (callback) {
        callback()
      }
    } else {
      this.ClinicService.loadClinicDetails(() => {
        this.invoicingEnabled = this.ClinicService.clinicDetails.inv_enabled;
        this.authService.spinnerStop()
        if (callback) {
          callback()
        }
      })
    }
  }

  get isDeveloper() {
    return this.authService.isDeveloper();
  }

  get isguest() {
    return this.authService.isguest();
  }


  get admin() { return this.authService.isadmin(); }
  //--------------------------------------------------------------

  get menuChartTitle() {
    return this.isAdmin() ? 'Chart records' : 'My charts';
  }

  get menuTaskTitle() {
    return this.isAdmin() ? 'Task managment' : 'Task managment';
  }

  hasChildren(item) {
    if (item.children) return item.children.length > 0;
    return false;
  }

  menuLink(item) {

    return item.path;

    //let a = item.path.split('/')
    //if (a[0]=='') {a[0] = '/' } else {a= a.unshift('/')}
    //return a;
  }

  iconStyle(item) {
    if (item.style) {
      let a= item.style.split(';').filter(item=>item)
      let b: any = {}
      /*
      for (let item of a) {

        let c = item.split(':')
        b[c[0]]= c[1]
      } */

      a.forEach((item)=>{let c = item.split(':'); b[c[0]]= c[1]})
      //return b[0]
      //console.log(b)
      return b

    }
    return {}
  }

  httpLink(item) {

    let r = item.path ? item.path.startsWith('http') : false;

    return r && item.acc;
  }


  subMenu(item) {
    return item.acc && !this.httpLink(item)  && this.hasChildren(item)
  }

  iMenu(item) {
    return item.acc && !this.httpLink(item)  && !this.hasChildren(item)
  }

  menu = []

    menu7: any = null


    private userSubscription: Subscription;

    ngOnInit() {
    this.isInvoicingEnabled(() => {
    let menu = this.authService.menu();
    this.menu = menu ? menu : this.getMenu()

      this.userSubscription = this.authService.userSubscribe(()=> {
          this.userNext()
      })
    });
    

  }

    ngOnDestroy() {
        this.userSubscription.unsubscribe()
        //    this.sub.unsubscribe()
    }

    interfaceName
    userNext() {
        this.interfaceName = this.authService.interfaceName()
        this.menu7 = this.authService.getMenu(this.invoicingEnabled)
    }


    getMenu() {

    let admin =  this.authService.isadmin();
    let guest =  this.authService.isguest();
    let client = this.isClient()
    let accountant = this.isAccountant()
    let developer = this.isDeveloper;

    return [

    { name: 'Admin', icon: "fa fa-lg fa-fw fa-cogs", acc: admin,
        children: [
          { name: 'Users',     icon: "fa fa-lg fa-fw fa-user-md",    path: '/user' , acc: admin},
          { name: 'Mail List', icon: "fa fa-lg fa-fw fa-envelope-o", path: '/user/mail_list' , acc: admin},
          { name: 'Involved professionals', icon: "fa fa-lg fa-fw fa-medkit", path: '/professional' , acc: admin},
          { name: 'Diagnoses', icon: "fa fa-lg fa-fw fa-envelope-o", path: '/diagnosis' , acc: admin},
          { name: 'Payments',  icon: "fa fa-lg fa-fw fa-cc-stripe", path: 'https://dashboard.stripe.com', style:"color:lightskyblue;", acc: admin},

        ]},

      { name: 'Manage training', icon: "fa fa-lg fa-fw fa-graduation-cap", acc: admin,
        children: [
          { name: 'Training categories',     icon: "fa fa-lg fa-fw fa-university",    path: '/blog_category' , acc: admin},
          { name: 'Training articles',       icon: "fa fa-lg fa-fw fa-film", path: '/blog' , acc: admin},

        ]},

    { name: 'Address book', icon: "glyphicon glyphicon-user", path: "/addressbook",     acc: !guest },
      { name: this.menuChartTitle, icon: "fa fa-lg fa-fw fa-briefcase", path: "/chart", acc: !guest },

      { name: 'My templates', icon: "fa fa-lg fa-fw fa-desktop", path: "/chart_template", acc: client || admin || accountant },
      { name: 'R-Store', icon: "fa fa-lg fa-fw fa-shopping-cart", path: "/rstore", acc: client || admin || accountant },


      { name: 'Training area', icon: "fa fa-lg fa-fw fa-cloud", path: "/training", acc: client || admin || accountant },
//        { name: 'Community', icon: "fa fa-lg fa-fw fa-tree", path: "/post", acc: client || admin || accountant },
        { name: 'Community', icon: "fa fa-lg fa-fw fa-tree", path: "/post_section", acc: client || admin || accountant },

      { name: this.menuTaskTitle, icon: "fa fa-lg fa-fw fa-clock-o", path: "/task", acc: client || admin || accountant },

      { name: 'My tasks', icon: "fa fa-lg fa-fw fa-bell-o", path: "/todo", acc: client || admin || accountant,
          badge: 'task-count'
      },


      { name: 'Utils', icon: "fa fa-lg fa-fw fa-wrench", acc: developer,
        children: [
          { name: 'Task type',     icon: "fa fa-lg fa-fw fa-circle-o-notch",    path: '/task_type' , acc: admin},
          { name: 'Task states',   icon: "fa fa-lg fa-fw fa-square-o", path: '/task_state' , acc: admin},
          { name: 'Task roles',   icon: "fa fa-lg fa-fw fa-share-alt", path: '/task_type_role' , acc: admin},

        ]},

      { name: ' FreshBooks', icon: "fa fa-lg fa-fw fa-leaf", path: "https://secure.freshbooks.com/login",
        style:"color:#85c51f;",
        acc: client || admin || accountant },


    ]}


}
