import { UserActionTypes, UserActions } from "./user.actions";

export interface AppState {
  users: any;
  error: string;
  userTheme: string;
  sideBarColor: string;
  fontStyle: string;
  pagesColor: string;
  sideBarFontColor: string;
  pagesFontColor: string;
  themeLogo: string;
  AppMenu: any;
}

export const initialState: AppState = {
  users: "",
  error: "",
  userTheme: "default",
  sideBarColor: "#3a3633",
  fontStyle: "'Open Sans',Arial,Helvetica,Sans-Serif",
  pagesColor: "rgb(248 247 249)",
  sideBarFontColor: "#c0bbb7",
  pagesFontColor: "#3a3633",
  themeLogo: "",
  AppMenu: "",
};

export function reducer(
  appState: AppState = initialState,
  action: UserActions
): AppState {
  switch (action.type) {
    case UserActionTypes.setUserName:
      return {
        ...appState,
        users: action.payload,
        error: "no data found",
      };

    case UserActionTypes.setUserThenme:
      return {
        ...appState,
        userTheme: action.payload,
      };

    case UserActionTypes.setsideBarColor:
      return {
        ...appState,
        sideBarColor: action.payload,
      };

    case UserActionTypes.setfontStyle:
      return {
        ...appState,
        fontStyle: action.payload,
      };

    case UserActionTypes.setpagesColor:
      return {
        ...appState,
        pagesColor: action.payload,
      };

    case UserActionTypes.setsideBarFontColor:
      return {
        ...appState,
        sideBarFontColor: action.payload,
      };

    case UserActionTypes.setpagesFontColor:
      return {
        ...appState,
        pagesFontColor: action.payload,
      };

    case UserActionTypes.setthemeLogo:
      return {
        ...appState,
        themeLogo: action.payload,
      };

    case UserActionTypes.setAppMenu:
      return {
        ...appState,
        AppMenu: action.payload,
      };

    default:
      return appState;
  }
}

export const getAppState = (appState: AppState) => appState;
