import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'send-to-email',
    template: `
         <section>

              <label class="label">{{title}}</label>

              <table class="table table-bordered table-hover table-widget table-striped price-table">

              <thead>
                                                    <!--
                                                    <tr>
                                                        <th style="width:15%"></th>
                                                        <th style="width:85%;">Bcc</th>
                                                    </tr>
                                                    -->
              </thead>
              <tbody>
              <tr >
                  <td style="width:15%">
                         <label class="radio">
                  <!--           <input type="radio" name="radio6" [(ngModel)]="item.invoice_email_mode"  -->
                             <input type="radio"  name="{{name}}"[(ngModel)]="_mode"
                                  [value]="0">
                                  <i></i>Default
                              </label>
                  </td>
                      <td class="td-input">
                          <label *ngIf="_mode==0" class="input state-disabled">
                               <i class="icon-append fa fa-envelope" ></i>
                            <input type="text" name="invoice_email_default" [ngModel] ="_default"
                                                                [readonly]="true" >
                          </label>
<!-- item._invoice_email_default -->
                       </td>
                  </tr>

                  <tr >
                      <td >
                          <label class="radio">
                              <input type="radio" name="{{name}}" [(ngModel)]="_mode"
                                 [value]="1">
                              <i></i>Custom
                          </label>
                      </td>
                      <td class="td-input">
                          <label *ngIf="_mode==1" class="input">
                          <i class="icon-append fa fa-envelope" ></i>
                              <input type="text" name="invoice_email" [(ngModel)] ="_value"

                                                                >
                          </label>
                      </td>
                  </tr>

                  <tr >
                      <td >
                          <label class="radio">
                              <input type="radio" name="{{name}}" [(ngModel)]="_mode"
                                 [value]="2">
                              <i></i>None
                          </label>
                      </td>
                      <td class="td-input">
                      <!--
                          <label *ngIf="item.invoice_email_mode==1" class="input">
                          <i class="icon-append fa fa-envelope" ></i>
                              <input type="text" name="invoice_email" [(ngModel)] ="item.invoice_email"

                                                                >
                          </label>
                          -->
                      </td>
                  </tr>



              </tbody>
         </table>


       </section>

 `,
})
export class SendToEmailComponent {

    @Input('item') item = {};
    @Input('title') title = "Email to"
/*
    _default = '';

    @Input('default') set setDefault(val) {
        this._default = val;
    }
*/
    @Input('default') _default = '';
    @Input('mode') modeName = '';
    @Input('value') valueName = '';

 //   _mode = 0;

    @Input('name') name = "radio6"


    set _mode(value) {
       // this._mode = value;
        if (this.modeName) {
            console.log('set',this.modeName,value)
            this.item[this.modeName] = value;
        }

    }

    get _mode() {
        if (this.modeName) {
            let value = this.item[this.modeName];
           // console.log('get',this.modeName,value)
            return value;
        }
        return 0;
       // return this._mode;
    }

    set _value(value) {
        if (this.valueName) {
            this.item[this.valueName] = value;
        }
    }

    get _value() {
        if (this.valueName) {
            return this.item[this.valueName];
        }
        return null;
    }

}

