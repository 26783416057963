import { Component, forwardRef } from '@angular/core';
import { Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EhisInfoComponent),
    multi: true
};

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'ehis-info',
    template:
  `    <div class="form-group row">
    <label for="first_name" class="col-md-5  col-form-label text-md-right"><ng-content></ng-content></label>
<div class="col-md-7">
<input type="text"  id="first_name" class="form-control form-control-sm  pull-xs-left" name="first_name"  placeholder="" [(ngModel)]="value"
>
    </div>
    </div>
   `,

    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class EhisInfoComponent implements ControlValueAccessor {

//    @Input() label: string ='';

    //The internal data model
    private innerValue: any = '';

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

}
