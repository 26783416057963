import {Component, ViewEncapsulation, ElementRef} from '@angular/core';

import {BaseList} from '../../auth/base-list';
import {MailboxService} from "../mailbox.service";

@Component({
  selector: 'mailbox-list',
  templateUrl: './mailbox-list.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class MailboxList extends BaseList {

    constructor(el:ElementRef, private mailboxService:MailboxService) {

        super(el,mailboxService);
    }



}
