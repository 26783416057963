import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-select',
    templateUrl: './list-select.component.html',
})
export class ListSelectComponent {

    @Input('data') data;
    @Input('search') search = true;
    @Input('period') period = false;
    @Input('add') addButton = false;
    @Input('addButtonWidth') addButtonWidth = "80px";

    // a-la select-item

    get timesColor() {
        return this.data.searchControl.value ? '#719cc1' : '#bfbfbf';
    }

    clear() {

        if (this.data.searchControl && this.data.searchControl.value != '') {
            this.data.searchControl.setValue('');

        }
    }

    clickAdd() {
        this.data.clickAdd();  //-> baseList

    }

    @Input('addButtonCaption') addButtonCaption = 'Add'


}

