import {Component, OnInit, OnDestroy, ElementRef} from '@angular/core';

import {config} from '../smartadmin.config';

import {LayoutService} from './layout.service'
import {Subscription} from "rxjs";
import {AuthService} from "../../auth/auth.service";

declare var $: any;



@Component({
  selector: 'sa-layout-switcher',
  templateUrl: './layout-switcher.component.html'
})
export class LayoutSwitcherComponent implements OnInit, OnDestroy {
  isActivated:boolean;
  smartSkin:string;
  store: any;
  private sub: Subscription;

   private userSubscription: Subscription;

    constructor(private el:ElementRef,public authService:AuthService,public layoutService:LayoutService) {}

  ngOnInit() {
    this.sub = this.layoutService.subscribe((store)=>{
      this.store = store;
    });
    this.store = this.layoutService.store;

    this.userSubscription = this.authService.userSubscribe(()=> {
      this.renderInterfaces()
    })

      $(document).on('click', event =>{
          var container = $(this.el.nativeElement);
          if (!container.is(event.target) &&            // If the target of the click isn't the container...
              container.has(event.target).length === 0) // ... nor a descendant of the container
          {
              // Do whatever you want to do when click is outside the element
              this.hide()
          }
      });
  }

  ngOnDestroy(){

    this.userSubscription.unsubscribe()

    this.sub.unsubscribe()
  }


  onToggle() {
    this.isActivated = !this.isActivated
  }

  hide() {
      this.isActivated = false  // hide LayoutSwitcherComponent

  }

  onSmartSkin(skin) {
    this.layoutService.onSmartSkin(skin)
 //   this.hide()
  }


  onFixedHeader() {
    this.layoutService.onFixedHeader()
  }


  onFixedNavigation() {
    this.layoutService.onFixedNavigation()
  }


  onFixedRibbon() {
    this.layoutService.onFixedRibbon()
  }


  onFixedPageFooter() {
    this.layoutService.onFixedPageFooter()
  }


  onInsideContainer() {
    this.layoutService.onInsideContainer()
  }


  onRtl() {
    this.layoutService.onRtl()
  }


  onMenuOnTop() {
    this.layoutService.onMenuOnTop()
  }


  onColorblindFriendly() {
    this.layoutService.onColorblindFriendly()
  }


  factoryReset() {
    this.layoutService.factoryReset()
  }


    interfaces: string[] = []
    selectedInter: string = ''
  //  logo =  "assets/img/logo-blue.png"
    btnClass = "btn btn-xs btn-block txt-color-white margin-top-5"
    interstyle = {
        background: '#3276b1'
    }
    //label: "Administrator"

    renderInterfaces() {
      this.interfaces = [];
      this.selectedInter = this.authService.interfaceName()
      let intf = this.authService.intf;
      if (intf) {
         let menu = intf.menu
         if (menu) {
           for (let key in menu) {
             this.interfaces.push(key)
           }
         }
      }
      //  console.log(this.interfaces, this.selectedInter)

    }

    onInterface(inter) {
        this.authService.setIntf(inter)
    //    this.hide()

        //  this.layoutService.onInterface(inter)
    }

    get debug() {
      return this.authService.debug
    }

    onClearDB() {
      this.authService.confirmationDialog('Are you sure to delete all data?')
         .subscribe( result => { if (result) {
             this.authService.home()
             this.authService.clearDB()

         }});

    }


}
