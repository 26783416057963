import {ClsConfig} from "../dynamic-form-control.model";
import {DynamicInputModelConfig, DynamicInputModel,DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE} from "../input/dynamic-input.model";
import {serializable} from "../../decorator/serializable.decorator";
import {getValue} from "../../utils";


export const DYNAMIC_FORM_CONTROL_TYPE_FILE = "FILE";

export interface DynamicFileModelConfig extends DynamicInputModelConfig {
    link? : string;
    base64data? : string;
}

export class DynamicFileModel extends DynamicInputModel {

    @serializable() link:string | null = '';
    @serializable() base64data: string |null = '';


    @serializable() readonly
    type:string = DYNAMIC_FORM_CONTROL_TYPE_FILE;

 //   public base64data = '';


    clearUpload() {
          this.link = '';
    }

    handleUpload(data): void {
        if (data && data.response) {
            data = JSON.parse(data.response);

            this.link = data.src;
        }
    }

    constructor(config: DynamicFileModelConfig, cls?: ClsConfig) {
       super(config,cls);

       this.inputType =  DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE;
       this.accept = "image/jpeg,image/png";

        this.link = getValue(config, "link", '');
        this.base64data = getValue(config, "base64data", '');

    }
}
