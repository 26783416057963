import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import {AuthModule} from "../auth/auth.module";
// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap';


//import { ModalModule }             from 'ng2-modal';
//import { TypeaheadModule }         from "ngx-bootstrap" //from 'ng2-bootstrap/ng2-bootstrap';

//import {EhisControlModule}  from '../ehis-control/ehis-control.module';

import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!


import { SettingService} from './setting.service';

//import {SettingList} from './list/subscription-type-list.component'
import {SettingDetail} from './detail/setting-detail.component'

export const routes = [
//    { path: '', component: SettingList , pathMatch: 'full' },
    { path: ':id', component: SettingDetail, pathMatch: 'full', canDeactivate: [SaveGuard],},
];

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {MailboxModule} from "../mailbox/mailbox.module";
import {ControlModule} from "../controls/controls.module";
import {WidgetModule} from "../widget/widget.module";
//import {MailboxService} from "./mailbox.service"; // Masked input

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
//        ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        ButtonsModule,

//        RouterModule.forChild(routes),

        SmartadminModule,
        SmartadminInputModule,

        MailboxModule,

        ControlModule,

        WidgetModule,
   ],

    declarations: [
  //      SettingList,
        SettingDetail,
   ],

    exports: [
        SettingDetail,

    ],
    providers: [
        SaveGuard,
        SettingService,
//        MailboxService,
    ]

})
export class SettingModule {
}

