/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


declare var jQuery: any;


import {AuthService} from "../../auth/auth.module"
import {BaseItem} from "../../auth/base-item";

import {UserService} from '../../service/user.service';
import {AddUser} from "../list/add-user";


@Component({
    selector: '[add-client-user]',
    templateUrl: './add-client-user.template.html',
//    providers: [UserService],

})
export class AddClientUser extends AddUser{

   constructor (el: ElementRef, route: ActivatedRoute, userService:UserService) {
        super(el,route,userService,2);
    }

}

