import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {finalize} from "rxjs/operators";


//import {sprintf} from "sprintf-js"
import {AddressService} from "../../address/address.service";

//declare var $;

@Component({
  selector: 'add-patient-dialog',
  templateUrl: './add-patient-dialog.template.html',
})
export class AddPatientDialog /* extends BaseForm */ {

    tab = 1;

    selectTab(tab) {
    }



    @ViewChild('win') win
    btnClick() {
        this.win.open(() => {

            this.win.close()
        })
    }


    @ViewChild('hello2') hello2
    btnClick2() {
        this.hello2.open(() => {

            this.hello2.close()
        })

    }

    @ViewChild('hello3') hello3
    btnClick3() {
        this.hello3.open() /*() => {

            this.hello3.close()
        })
*/
    }

    @Input('openChart') _tryOpenChart =  false


    item :any = {}
    shortForm = true
    longForm = false
    openChart = false
    patients = []
    callback = null


    init() {
        this.item = {}
        this.shortForm = true
        this.longForm = false
        this.openChart = false
        this.patients = []
        this.callback = null
    }

    constructor(protected el:ElementRef,private ds: AddressService) {
        this.init()
    }

    beforeOpen() {
        this.init()

        this.callback = this.win.callback
        this.win.callback = null
   }


    showError(error) {
        this.ds.authService.showError(error)
    }

    action(item) {
        if (this._tryOpenChart) return item._action
        return 'Select'
    }


    get patientExists() {
        return this.patients && this.patients.length > 0
    }

    execDialog() {
        this.win.close()
        if (this.callback) {
            this.callback()
        }
    }


    onOk() {

        if (this.openChart) {

            if (this.item.id) {
                this.win.loading = true
                this.ds.setAccess(this.item.id, 1,this.item.reason)
                    .pipe(
                        finalize(() => {
                            this.win.loading = false
                        }),
                    )
                    .subscribe(
                        result => {
                            this.execDialog()
                        },
                        error => {
                            this.showError(error)
                        })
            }
            else {
                this.shortForm = false
                this.longForm = true
                this.openChart = false
            }
        }
        else if (this.shortForm) {

            this.item._check = 1;

            this.item.name = this.item.last_name +', '+ this.item.first_name

            this.win.loading = true
            this.ds.getItems(null, this.item)
                .pipe(
                    finalize(() => { this.win.loading = false }),
                )
                .subscribe(
                    result => {

                        this.patients = result.data;

                        this.shortForm = this.patientExists
                        this.longForm = !this.shortForm
                        this.openChart = false

                        if (this.longForm && this._tryOpenChart && (result._action.toLowerCase() == 'Open Chart'.toLowerCase())) {

                            this.shortForm = false
                            this.longForm = false
                            this.openChart = true // ask the doctor to set the reason
                        }

                    },
                    error => {
                        this.showError(error)
                    }
                )
        }
        else if (this.longForm) {

            this.win.loading = true
            this.ds.post(this.item)
                .pipe(
                    finalize(() => { this.win.loading = false }),
                )
                .subscribe(
                    result => {

                        this.item = result
                        this.execDialog()
                   },
                    error => {
                        this.showError(error)
                    }
                )


        }
    }


    clickPatient(item) {
        this.item = item

        if (this.action(item).toLowerCase()=='Open Chart'.toLowerCase()) {
            this.shortForm = false
            this.longForm = false
            this.openChart = true
            return
        }

        this.execDialog() // action == select
   }

   open(callback) {
       this.win.open(callback)
   }

}