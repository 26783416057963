import { Component,ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';

import {InvoiceService} from '../invoice.service';


import * as moment from 'moment';

@Component({
    selector: 'invoice-detail',
    templateUrl: './invoice-detail.template.html',
})
export class InvoiceDetail extends BaseItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected invoiceService:InvoiceService) {

        super(el,route, invoiceService);
    }

   payment() {
        this.baseService.authService.confirmationDialog('Mark invoice as paid ?')
            .subscribe( result => { if (result) this.item.payed= 1; this.saveItem()});
    }

    unpayment() {
        this.baseService.authService.confirmationDialog('Mark invoice as unpaid ?')
            .subscribe( result => { if (result) this.item.payed= 0; this.saveItem()});
    }


    paymentAllowed() {
        return this.item.id && !this.item.payed &&
            (this.baseService.authService.isadmin() || this.baseService.authService.isAccountant());
    }

    unPaymentAllowed() {
        return this.item.id && this.item.payed &&
            (this.baseService.authService.isadmin() || this.baseService.authService.isAccountant());
    }



    onReadItem(result) {
        //let dt = moment(result.created_at).format('YYYY-MM-DD HH:mm:ss');
        let localTime = moment.utc(result.created_at).toDate();
        result.created_at = moment(localTime).format('YYYY-MM-DD HH:mm:ss')  // local time
        //moment().format('MMMM Do YYYY, h:mm:ss a');
    }


    pdf() {
        let url = this.baseService.baseURL + '/download/' + this.item.id;
        this.baseService.authService.download(url,'Invoice.pdf');
    }

    changeState() {
        if (+this.item.payed == 2) {
            this.baseService.authService.showMessage('You cannot change state of pre-paid invoice.','Sorry');
        }
        else {

            let selections = ['Not paid', 'Paid', 'Waiting for manual payment'];
            let selected = [0, 1, 'Pre-paid', 2][+this.item.payed];

            let dlg = {
                selections: selections,
                selected: selected,
                header: 'Set invoice state'
            }

            this.baseService.authService.selectDialog(dlg)
                .subscribe(result => {
                    if (result.result) {
                        this.item.payed = [0, 1, 3][result.selected];
                        this.saveItem();
                    }
                });
        }
    }

}
