import {Component, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';


import {WarningService} from '../warning.service';

@Component({
    selector: 'warning-detail',
    templateUrl: './warning-detail.template.html',
})
export class WarningDetail extends BaseItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected warningService:WarningService) {

        super(el,route, warningService);
    }


}
