import { Component,ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {ProfessionalService} from '../professional.service';
import {BaseItem}  from '../../auth/base-item';

@Component({
    selector: 'professional-detail',
    templateUrl: './professional-detail.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class ProfessionalDetail extends BaseItem {


    constructor(el:ElementRef,protected route: ActivatedRoute, protected professionalService: ProfessionalService) {
        super(el,route, professionalService);
    }


    deletePrompt = 'Are you sure to close the chart?'

    /*
    tagedUser($event) {
        this.item.user_id = null;
        this.item.user_name = $event;

    }
    */
}