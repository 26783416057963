import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';
import {BaseList} from "../../auth/base-list";
import {InvoiceService} from "../invoice.service";

@Component({
  selector: 'invoice-list',
  templateUrl: './invoice-list.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class InvoiceList extends BaseList {

    constructor(el:ElementRef, private invoiceService:InvoiceService) {

        super(el,invoiceService);

    }

    @Input('title') title = 'Invoices';
//    @Input('widget_name') widget_name= 'invoice-table';

    downloadLink(item) {
        let url= this.baseService.baseURL+'/download/'+item.id;
        return url;
    }
    /*
     invoiceColor(item) {
        return +item.paid == 0  ? 'text-color-danger': '#009900';

     }
     */
    notPaid(item) {
        //return item.state == 'Not paid' || item.state == 'Expired' || item.state =="Waiting for manual payment";
        return item.alert
    }

    reminder() {
        return this.paramItems.mode==2;
    }

    doRemind(item) {
       // this.invoiceService.remind(item);
    }

    remind(item) {
        let s = 'Are you sure to email reminder to "' + item.user.name + '"?';
        this.baseService.authService.confirmationDialog(s)
            .subscribe( result=> {if (result) this.doRemind(item)},
                error=> {this.baseService.authService.showMessage(error)})


    }

}
