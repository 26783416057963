
import {ClsConfig} from "../dynamic-form-control.model";
import {DynamicInputModelConfig, DynamicInputModel,DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE} from "../input/dynamic-input.model";
import {serializable} from "../../decorator/serializable.decorator";
import {getValue} from "../../utils";

export const DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT = "ATTACHMENT";

export interface DynamicAttachmentModelConfig extends DynamicInputModelConfig {
    link? : string;
}

export class DynamicAttachmentModel extends DynamicInputModel {

    @serializable() link:string | null = '';

    @serializable() readonly
    type:string = DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT;


    constructor(config: DynamicAttachmentModelConfig, cls?: ClsConfig) {

        super(config,cls);

       this.inputType =  DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE;
       this.accept = "application/pdf";

       this.link = getValue(config, "link", '');

    }
}
