import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {BaseList}       from '../../auth/base-list'

import {TrustedUserService}   from "../trusted-user.service";

@Component({
  selector: 'trusted-user-list',
  templateUrl: './trusted-user-list.template.html',
})
export class TrustedUserList extends BaseList {


  constructor(el: ElementRef,private trustedUserService: TrustedUserService) {
    super(el,trustedUserService);
  }

  patient_name(item) {
    if (item) {
      if (item.chart) {
        if (item.chart.patient) {
          return item.chart.patient.name
        }
      }

    }
    return '';
  }

    owner_name(item) {
        if (item) {
            if (item.chart) {
                if (item.chart.user) {
                    return item.chart.user.name
                }
            }

        }
        return '';
    }

    onReadItems(result) {
        for (let elem of result.data) {
            let chart = elem.chart
            if (chart) {
                if (!chart.name) {
                    chart.name = "#" + chart.id
                }
            }
        }


    }

}
