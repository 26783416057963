import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import {SmartadminModule} from '../shared/smartadmin.module'
import {DropzoneModule} from "../shared/forms/dropzone/dropzone.module";

import {AuthService} from '../auth/auth.service'


import {AttachmentComponent} from "./attachment.component"
import {DynamicComponent} from "./dynamic.component"
//import {DynamicForm} from "../chart-template/dynamic-form/dynamic-form.component"

//import {DynamicFormsEhisUIModule} from "../chart-template/ui-ehis/ui-ehis.module"

import {WidgetDetail} from "./detail/widget-detail.component";
import {SmartadminWidgetsModule} from "../shared/widgets/smartadmin-widgets.module";
import {AuthModule} from "../auth/auth.module";
import {WidgetModal} from "./modal/widget-modal.component";
import {WidgetList} from "./list/widget-list.component";

import {ControlModule} from "../controls/controls.module"

import {WidgetWell} from "./well/widget-well.component"
@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      ControlModule,

      SmartadminModule,
      DropzoneModule,

   //   DynamicFormsEhisUIModule,
      //    SmartadminInputModule,
      SmartadminWidgetsModule,

      AuthModule,
  ],
  declarations: [
     AttachmentComponent,
     //DynamicForm,
   // DynamicComponent,

      WidgetDetail,
      WidgetModal,
      WidgetList,

      WidgetWell,
  ],
  exports: [
      AttachmentComponent,
   //   DynamicComponent,
      WidgetDetail,
      WidgetModal,
      WidgetList,

      WidgetWell
  ],
  providers: [
  //  AuthService  // do not do this !!!
  ]

})
export class WidgetControlModule{}
