import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewContainerRef,
    forwardRef,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR,ControlValueAccessor  } from '@angular/forms';

import {AuthService} from "../../auth/auth.service";
import {CsvService} from "../../auth/csv/csv.service";

const noop = () => {};

export const DYNAMIC_INDEX_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DynamicIndex),
    multi: true
};



@Component({

    selector: 'dynamic-index',
    templateUrl: './dynamic-index.component.html',
    providers: [DYNAMIC_INDEX_CONTROL_VALUE_ACCESSOR]

})
export class DynamicIndex implements ControlValueAccessor {


    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {

        console.log('clear write 2',v)

        // if (v !== this.innerValue) {
        this.innerValue = v;
        this.onChangeCallback(this.innerValue);
        //  }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }


    //From ControlValueAccessor interface
    writeValue(value: any) {

      //  console.log('clear write 1',value)

        if (value) {
            this.innerValue = value

        }
        else {
            //  console.log('clear write 2',value)
            //https://github.com/angular/angular/issues/14988
            if (this.initialize) {
                this.onChangeCallback(this.innerValue);

                let parent = this.getParentComponent()
                if (parent) {
                    parent.clear = true
                    //    console.log(parent)
                }
            }
            // console.log('clear write 2')
        }
        // if (value !== this.innerValue) {
        //     this.innerValue = value;
        //     this.innerTypedValue = value
//
        // }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //-----------------------------------------------------------------------

    constructor(private viewContainerRef: ViewContainerRef, public authService: AuthService) {

        // console.log('wg',this.viewContainerRef)
    }


    getParentComponent(): any {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component
        //  return this.viewContainerRef[ '_view' ].component
    }

    //----------------------------------------------------------------------------------------------------------

    @Input('item') item = {}

    $el = null

    initialize = false // !!! true

    columns = ['name']

    idKey = 'id'
    nameKey = 'name'
    linkKey = 'link'

    //fixed: false
    nameText = 'Value'

    links = false

    colcount = 1 // 2
    //rows = 3
    innerValue: any[] = [
        {id: 1, name: this.nameText + ' 1', link: null},
        {id: 2, name: this.nameText + ' 2', link: null},
        {id: 3, name: this.nameText + ' 3', link: null},
    ]

    updateColumns() {
        let i = this.innerValue.reduce((acc, elem) => {
            return Math.max(acc, +elem[this.idKey])
        }, 0)
        for (let item of this.innerValue) {
            if (!item[this.idKey]) {
                i = i + 1
                item[this.idKey] = i
            }
        }
    }

    @Input('options') set options(value) {

        if (value) {

            if (value.keys) {


                let names = value.keys.split(',')
                let idKey = (names[0]) ? names[0] : this.idKey
                let nameKey = (names.length > 1 && names[1]) ? names[1] : this.nameKey
                let linkKey = (names.length > 2 && names[2]) ? names[2] : this.linkKey

                for (let item of this.innerValue) {
                    if (idKey != this.idKey) {
                        item[idKey] = item[this.idKey]
                        delete item[this.idKey]
                    }
                    if (nameKey != this.nameKey) {
                        item[nameKey] = item[this.nameKey]
                        delete item[this.nameKey]
                    }

                    if (linkKey != this.linkKey) {
                        item[linkKey] = item[this.linkKey]
                        delete item[this.linkKey]
                    }

                }

                this.idKey = idKey
                this.nameKey = nameKey
                this.linkKey = linkKey

                this.columns = [this.nameKey]
            }

            if (value.initialize !== undefined) {  // !!! dummy
                this.initialize = value.initialize
            }

           if (value.links !== undefined) {
                this.links = value.links
            }

            if (value.hint !== undefined) {

                this.nameText = value.hint
                for (let item of this.innerValue) {
                    item[this.nameKey] = this.nameText + ' ' + item[this.idKey]
                }
            }
        }
    }


    set data(value) {
        console.log('dynindex',value)
        if (value && Array.isArray(value)) {
            this.innerValue = value
            this.updateColumns()
        }
    }


    indexTracker(index, value) {
        return index;
    }

    change($event, row, col) {

        // console.log($event)
        let val = $event // .target.value
        //console.log(val)
        if (this.innerValue[row][col] != val) {
            this.innerValue[row][col] = val
            //    this.onChangeCallback(this.innerValue);


        }
    }

    show_data() {
        console.log(this.innerValue)
    }

    /*
    get fixed() {
        return (this.options && this.options.fixed)
    }
    get colcount() {
        return this._data && Array.isArray(this._data) ? this._data.reduce((acc,elem)=>{
            return Math.max(acc,elem.length)},0) : 0
    }

    */

    get rowcount() {
        return this.innerValue && Array.isArray(this.innerValue) ? this.innerValue.length : 0
    }


    get addText() {
        //  return this.rowcount < 2 ? "Add row +" : "Add another row +"
        //   return this.rowcount < 2 ? "Add " value" : "Add another value"
        return "Add " + this.nameText.toLowerCase()
    }


    @Output('onAdd') public onAdd: EventEmitter<any> = new EventEmitter();
    @Output('onRemove') public onRemove: EventEmitter<any> = new EventEmitter();

    addMe() {
        let i = this.innerValue.reduce((acc, elem, idx) => {

            let v = +elem[this.idKey]
            return Math.max(acc, Number.isNaN(v) ? idx : v)
        }, 0) + 1

        let row: any = {}
        row[this.idKey] = i
        row[this.nameKey] = this.nameText + ' ' + i
        this.innerValue.push(row)
        this.onAdd.emit(this.innerValue.length)
    }

    removeMe(index) {
        this.innerValue.splice(index, 1)
        this.onRemove.emit(index)
    }

    ngOnInit() {
        // console.log('on init')
    }

    uploadIndex = -1
    @ViewChild('upload_hidden') upload_hidden;


    uploadOptions() {
        return this.authService.uploadOptions();
    }

    handleUpload(data): void {
        if (data && data.response) {
            data = JSON.parse(data.response);
            let link = data.src;

            if (this.uploadIndex >=0 && this.uploadIndex < this.rowcount) {
                this.innerValue[this.uploadIndex].link = link;
            }
            this.uploadIndex = -1;
        }
    }


    getLink(row) {
      //  console.log('link', this.innerValue[row].link)
        this.uploadIndex = row;

        this.upload_hidden.nativeElement.click();

    }

}
