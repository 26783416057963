import { Component, ElementRef ,ViewEncapsulation } from '@angular/core';

import {BaseList} from '../../auth/base-list';
import {PaymentService} from "../payment.service";

@Component({
  selector: 'payment-list',
  templateUrl: './payment-list.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class PaymentList extends BaseList {

    //tagName = '';

    constructor(el: ElementRef, private paymentService:PaymentService) {

        super(el,paymentService);
    //    this.tagName = el.nativeElement.tagName.toLowerCase();
   }

    onReadItems(result) {

        for (let elem of result.data) {
            elem.created_at = this.baseService.authService.toLocalTime(elem.created_at);

            elem.amount = this.baseService.authService.currencyFmt(elem.amount);
        }
    }
}
