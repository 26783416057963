import {Component, Input, Output,ElementRef, EventEmitter} from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {BaseForm} from "../auth/base-form"

@Component({

    selector: 'attachment',
    template: `
       
            <sa-widget [deletebutton]="false" [name]="selector">
            <header>
                <span class="widget-icon"> <i class="fa fa-paperclip"></i> </span>
                <h2>Attachments </h2>
            </header>

            <!-- widget div-->
            <div>


                <!-- widget content   //  autoProcessQueue: true,
            //  url: uploadURL,
            -->
                <div class="widget-body">

                    <section>

                    <form class="dropzone" [saDropzone]="{maxThumbnailFilesize: 100}"
                          (onUpload) = "onUpload($event)"
                          [data] = "this._initdata"
                          [disabled] = "disabled"
                    >

                        <div *ngIf=" disabled" class="dz-default dz-message">&nbsp;<!-- &nbsp;  -->
                                      <span><span class="text-center"><span
                                class="font-sm "><span class="font-lg"><i
                                class="fa fa-caret-right text-danger"></i> No files <span
                                class="font-sm"></span></span><span>&nbsp;&nbsp;<h4
                                class="display-inline"></h4></span></span></span></span>
                 
                        </div>
                        <div *ngIf="!disabled" class="dz-default dz-message">
                                <span><span class="text-center"><span
                                class="font-sm "><span class="font-lg"><i
                                class="fa fa-caret-right text-danger"></i> Drop files <span
                                class="font-sm">to upload</span></span><span>&nbsp;&nbsp;<h4
                                class="display-inline"> (Or Click)</h4></span></span></span></span></div>
                    </form>
                </section>

                </div>
                <!-- end widget content  visible-xs-block visible-sm-block visible-lg-block  -->

            </div>
            <!-- end widget div -->

        </sa-widget>
       
  `,
})

export class AttachmentComponent extends BaseForm{

    @Input('disabled') disabled = false;


    @Input('parent') parent = null;
  //  @Input('initdata')
    _initdata = null;
    @Input('key')   key = 'data';
    /*
    @Input('isLoaded') set isLoaded(value) {
        if (value) {
            this._initdata = this.parent.item[this.key];
        }
    }
    */
    constructor(el: ElementRef,private authService:AuthService) {
        super(el)
        this.parent = this;
    }


    onUpload($event) {
        this.parent.item[this.key] = JSON.stringify($event);
    }

  //  ngOnInit() {
    ngAfterViewInit()
     {
            this.parent.readAnnounced.subscribe((item) => {
 //           console.log("Subscription got", item);
            if (item) {
                if (item[this.key]) {
                    this._initdata = JSON.parse(item[this.key])
                }
            }

        })
    }


}
