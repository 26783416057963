import { Component,ViewEncapsulation, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl,FormArray } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { EventEmitter, ElementRef } from '@angular/core';


import {BaseItem}  from '../../auth/base-item';
import {TaskService} from "../../task/task.service";
import {ProfessionalService} from "../professional.service";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'share-popup',
    templateUrl: './share-popup.template.html',
})
export class SharePopup extends BaseItem {

    constructor(el: ElementRef, protected route: ActivatedRoute, public ds: TaskService,
                public professionalService:ProfessionalService) {
        super(el, route, ds);
    }

    @ViewChild('win') win

    paramId = 0
    callback = null

    detailId() {
        return this.paramId
    }

    _title = "Create share chart task"

    get title() {
        let s = +this.item.assigned_to < 0 ? 'Create open chart record' : this._title
        return s +(this.hasChanges() ?' *' : '');
    }

    getLabel() {
        return +this.item.assigned_to < 0 ? 'Reason' : 'Task details'
    }

    open(paramId, paramItems, callback) {

        this.task = false
        this.item = {
            user_id: paramItems.user_id ,
            assigned_to: null,
            user_name: '', // paramItems.user_name,
            patient_id: paramItems.patient_id,
            type: 2, // TYPE_ACCEPT_PATIENT

            _users: paramItems._users
        }
        this.savedItem = this.itemToSaveStr();

        this.paramId = paramId
        this.paramItems = paramItems

       // this.getDetail()
        this.callback = callback
        this.win.open()
    }

    task = false

    onOk() {
        if (+this.item.assigned_to < 0) {  // external doctor
            let item = {
                id: 0,
                user_id: this.item.assigned_to,
                user_name: this.item.user_name,
                patient_id: this.item.patient_id,
                data: this.item.details
            }
            this.ds.loading = true
            this.professionalService.post(item)
                .pipe(
                    finalize(() => {
                        this.ds.loading = false
                    }),

                )
                .subscribe(result =>{
                    this.back()
                    this.task = false

                },
                    error=> {
                    this.ds.authService.showError(error)
                    })
            return ;
        }

        delete this.item.user_name
        this.task = true
        this.saveAndBack()
    }

    back() {
        this.win.close()
        if (this.callback) this.callback(this.task) // refresh ....
    }

    onCancel() {
        if (this.hasChanges()) {
            this.ds.authService.quitDialog()
                .subscribe(result => {
                    if (result) {
                        this.win.close()
                    }
                })
            return
        }
        this.win.close()
    }

    showmessage2 () {
        this.ds.authService.showMessage2('HELLO')
    }

}