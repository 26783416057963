/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewEncapsulation, ViewChild, Input, ViewContainerRef, ElementRef} from '@angular/core';

import {WidgetBase} from "../widget-base";

declare var $ :any;

@Component({
    selector: 'widget-detail',
    templateUrl: './widget-detail.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetDetail extends WidgetBase {

    context_style :any = { 'padding': '10px 14px',position:'relative'}


    get isModal() {
        return ($(this.el.nativeElement).closest('modal-content').length > 0)
    }


    ngOnInit() {
       super.ngOnInit()
        if (this.isModal) {
            //console.log('modal')
            this.context_style = { 'padding': '1px 1px',position:'relative'}
        }

    }

    get footerInfo() {
        return this.parent.footerInfo ? this.parent.footerInfo() : null
    }

    _saveButton = 'Save'

    @Input('saveButton') set saveButton(value) { this._saveButton = value }
    get saveButton() {
        /*  signup form
        if (this.parent.acl) {
            if (this.parent.acl('update') || this.parent.acl('profile')) {
                return this._saveButton
            }
            //return this.parent.acl('update,profile') ? this._saveButton : ''
            return ''
        }
        */
        return this._saveButton
    }

    _copyButton = true
    @Input() set copyButton(value) { this._copyButton = value }
    get copyButton() {
        if (this.parent.acl) {
            return  this._copyButton &&  this.parent.acl('insert')
        }
        return this._copyButton

    }

    //---------------------------------------------------------------------------------

    _disabled = false
    @Input('disabled') set disabled(val) {

        console.log('set disabled',this._disabled,val)
        if (this._disabled != val) {
            this._disabled = val

            let elem = this.el.nativeElement
            console.log(elem)

            $(elem).find('item-input').each( (ind1,elem1) => {
                console.log(elem1)

            })

        }
    }

    get disabled() {
        return this._disabled
    }

    ngAfterContentInit() {
        //   if (this.elemType == 'INPUT') {
        let elem = this.el.nativeElement

        let val = this.disabled ? 1 : null

        $(elem).find('item-input').each( (ind1,elem1) => {
            console.log(elem1)

        })
/*
            $(elem).find(':input').each((ind2, elem2) => {
                attr('data-widget-disabled', 1)
                */

    }

    //---------------------------------------------------------------------------------
}