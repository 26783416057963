/**
 * Created by Alexander Spazhev on 26.10.2016.
 */
import { Injectable }       from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable }    from 'rxjs';

import { AuthService }      from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate,CanActivateChild {

    constructor(private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> | Promise<boolean> |boolean {// } boolean {
        let url: string = state.url;

        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> |boolean {//boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {


        //console.log('AuthGuard')
        this.authService.setRedirectUrl(url); // Store the attempted URL for redirecting
        if (this.authService.isLoggedIn()) { return true; }

        this.authService.redirectLogin();
        return false;
    }
}
