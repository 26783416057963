import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';


//import MaskedInput from 'angular2-text-mask'

import { TextMaskModule } from 'angular2-text-mask';

import {MaskedInputDirective} from "angular2-text-mask/src/angular2TextMask"

import { EhisEmailComponent } from './ehis-email.component';
//import { EhisPasswordComponent } from './ehis-password.component';
//import { EhisConfirmPasswordComponent } from './ehis-confirm-password.component';
import { EhisDateComponent } from './ehis-date.component';
import { EhisTimeComponent } from './ehis-time.component';

import {EhisImgSrcDirective} from '../ehis-directive/ehis-img-src.directive';

//import { EhisStripeComponent } from './ehis-stripe.component';

import { EhisRadioButtonsComponent } from './ehis-radio-buttons.component';

//----------------------------------------------------------------

import {SubscriptionComponent} from './subscription/subscription.component';

import {EhisUserComponent}     from './ehis-user/ehis-user.component';
import {EhisPostComponent}     from './ehis-post/ehis-post.component';

//----------------------------------------------------------------

import {DatexPipe}             from "../ehis-directive/datex.pipe";

//----------------------------------------------------------------

// importScripts()  ????????????????????????????????????????????????

// sp-- import { TypeaheadModule }           from 'ng2-bootstrap/ng2-bootstrap';

//import { TypeaheadModule }           from 'ngx-bootstrap/typeahead';


//import { Select2Module }             from 'ng2-select2';

//import 'bootstrap/dist/css/bootstrap.css';
//import {ConfirmationPopoverModule} from 'angular-confirmation-popover';

//import {EhisSuggestProvinceComponent} from "./ehis-suggest-province/ehis-suggest-province.component";

//import {EhisConfirmationComponent} from "./ehis-confirmation.component";


//import { ButtonsModule /*, DropdownModule */ } from 'ng2-bootstrap/ng2-bootstrap';


import {EhisAreaComponent} from "./ehis-area.component";
//import {EhisSelect2Component} from "./ehis-select2.component";

//import {EhisTableComponent} from "./ehis-table/ehis-table.component";
//import {EhisNotesComponent} from "./ehis-table/ehis-notes.component";

//import {EhisTrustedUsersComponent} from "./ehis-trusted-users/ehis-trusted-users.component";

//import {EhisDiagnosesComponent}     from "./ehis-diagnoses/ehis-diagnoses.component";
//import {EhisProfessionalsComponent} from "./ehis-professionals/ehis-professionals.component";

//import { ModalModule }               from 'ng2-modal';
//import  {ModalModule} from "ngx-modal";


//import {PaginationModule}          from 'ng2-bootstrap/ng2-bootstrap';
//import {EhisPaginationComponent}  from "./ehis-pagination.component";

import {EhisFileComponent} from "./ehis-file.component";

import { Ng2UploaderModule } from 'ng2-uploader';

import {LaddaModule} from "angular2-ladda";
import {Control3Module} from "../control3/control3.module";


//import {Control3Module} from "../control3/control3.module";

import {DateMaskDirective} from "../ehis-directive/date-mask.directive"
import {ControlModule} from "../controls/controls.module";
import {TimexPipe} from "../ehis-directive/timex.pipe";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,

        TextMaskModule,
      //  MaskedInput,

        Control3Module,

 //       TypeaheadModule,
 //       ConfirmationPopoverModule.forRoot({
 //           confirmButtonType: 'danger' // set defaults here
 //       }),
   //     Select2Module, // <select2

  //      ButtonsModule, // <ehis-radio-buttons>

//        ModalModule,
   //     PaginationModule,

        Ng2UploaderModule,

        LaddaModule.forRoot({
            //style: "contract",
            //spinnerSize: 32,
            //spinnerColor: "red",
            //spinnerLines: 12
            //        "../node_modules/ladda/dist/ladda.min.css",

        }),
        LaddaModule,

        ControlModule

   //     Control3Module,

    ],
    declarations: [
        EhisEmailComponent,
/*
        EhisPasswordComponent,

        */
        //MaskedInput,

     //   EhisConfirmPasswordComponent,
        EhisDateComponent,
        EhisTimeComponent,

      //  MaskedInputDirective,

        EhisImgSrcDirective,

   //     EhisStripeComponent,

        EhisUserComponent,
        EhisPostComponent,

        SubscriptionComponent,

        DatexPipe,
        TimexPipe,

    //    EhisSuggestProvinceComponent,
        EhisAreaComponent,
  //      EhisSelect2Component,

   //     EhisConfirmationComponent,
   //     EhisTableComponent,
   //    EhisNotesComponent,
   //     EhisTrustedUsersComponent,

    //    EhisRadioButtonsComponent,

    //    EhisDiagnosesComponent,
     //   EhisProfessionalsComponent,

    //    EhisPaginationComponent,

        EhisFileComponent,

   //     DateMaskDirective,


    ],
    exports: [
        EhisEmailComponent,
        /*
        EhisPasswordComponent,
        EhisConfirmPasswordComponent,

        EhisStripeComponent,
        */

        //MaskedInput,

        EhisTimeComponent,
        EhisDateComponent,

      //  MaskedInputDirective,

        EhisImgSrcDirective,
        EhisUserComponent,
        EhisPostComponent,

        SubscriptionComponent,

        DatexPipe,
        TimexPipe,

        //EhisSuggestProvinceComponent,
        EhisAreaComponent,
     //   EhisSelect2Component,

    ///    EhisConfirmationComponent,
    //    EhisTableComponent,
     //   EhisNotesComponent,
    //    EhisTrustedUsersComponent,

     //   EhisRadioButtonsComponent,

        //EhisDiagnosesComponent,
        //EhisProfessionalsComponent,

    //    EhisPaginationComponent,

        EhisFileComponent,

 //       DateMaskDirective

    ]
})
export class EhisControlModule {
}

