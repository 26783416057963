import { Component ,ElementRef} from '@angular/core';
import {Router,  ActivatedRouteSnapshot,ActivatedRoute} from "@angular/router";


//import {AuthService, THIS_MARKET_ID} from "../auth.service"
import {AuthService} from "../auth/auth.service"

import {RegisterService} from './register.service';
import {BaseItem}  from '../auth/base-item';

declare var $:any;

@Component({
  selector: 'signup',
  templateUrl: './signup.template.html'
})
export class SignupComponent extends BaseItem {

  email: string = '';
  password: string = '';
  password_confirm = '';
  message: string ='';


    constructor(el: ElementRef,protected route: ActivatedRoute,private registerService: RegisterService) {
        super(el, route, registerService);
    }

    itemCol = {'item-col-xs-8':true}

    detailId() {  // override
        return 0;
    }


    saveAndBack() {

        if (this.busy) return;
        this.busy = true;

        let save_item = this.itemToSave();

        this.baseService.post(save_item)
            .subscribe(
                result => {
                    if (result.error) {
                        this.baseService.authService.showError(result.error);
                        this.busy = false;

                    } else {
                        this.baseService.authService.showMessage(result.message);
                        this.busy = false;
                        this.noChanges = true;
                        this.baseService.authService.home();
                    }
                },
                error => {
                    this.busy = false;
                    this.baseService.authService.showError(error);

                }
            );

    }

    /*
      handleError(err) {
        this.authService.showError(err);
      }

      handleResult(result) {
            this.authService.showMessage(result.message);
            this.authService.home(); // navigate('/');
      }
    */


    signup() {

        let item = {
            'role_id': 1,
            'email': this.email,
            'password': this.password,
            'password_confirm': this.password_confirm,
        } //'market_id': THIS_MARKET_ID        }

        this.baseService.authService.spinnerStart();

     this.baseService.authService.register(item).subscribe(

          (result) => { this.baseService.authService.spinnerStop();

              if (result.error) {
                  this.handleError(result.error);
              }
              else {
                  this.handleResult(result);
              }
            },
          (error) => { this.baseService.authService.spinnerStop();
            this.handleError(error)
         });
  }
/*
  ngOnInit() {

   // jQuery('.parsleyjs').parsley();


    let lastUrlSegment = this.route.snapshot.url.slice(-1)[0]; // path();
    let path = lastUrlSegment.path;

    if (path == "logout") {
      this.authService.logout();
    }
    else {
      let email = this.route.snapshot.params['email'];

      if (email) {
        this.email = email;
        this.authService.checkRegister(email)
            .subscribe(
                result => {
                    if (result.error) {
                        this.message = result.error;
                    }
                    else {
                        this.message = result.message
                    }},
                error =>  {
                  this.handleError(error)
                }
            );
      }
    }
  }

    handleError(err) {
        this.authService.showError(err);
    }

    handleResult(result) {
        this.authService.showMessage(result.message);
        this.authService.home();
    }

    handle(Ob: Observable<any>,spinner:boolean= true) {
        if (spinner) { this.authService.spinnerStart() }
        Ob.subscribe(
            result => { this.authService.spinnerStop();
                if (result.error) {
                    this.handleError(result.error);
                }
                else {
                    this.handleResult(result);
                }
            },
            error  => { this.authService.spinnerStop();
                this.handleError(error)
            }
        );
    }

*/


     get modified() {
         return ''
     }



}
