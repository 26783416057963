import {Component, Input, Output, EventEmitter} from '@angular/core';


import {BaseForm} from "./base-form"

@Component({

    selector: 'base-link',

    templateUrl: './base-link.template.html',
/*
    template: `
       
          <label class=" input " >
          <div class="input-group">
              <input  type="text" [readonly]="true" name="_locname" [ngModel] = "parent[name](parent.item[value])"
                [attr.required]="required ? required:undefined"
              >
                           <div class="input-group-btn">
                              <button type="button" class="btn btn-default btn-input" (click) = "lookup()" >
                                ...
                              </button>
                              <button *ngIf="clearButton" type="button" class="btn btn-default btn-input" (click)="clear()">
                                X
                              </button>
                            </div>
 
          </div>
          </label>
       
  `,

*/


/*

 "parent.userName(parent.item[value])"
    template: `
       
          <label class="label  state-disabled">User</label>
          <label class=" input " >
          <span class=" icon-append" style="width:3rem;" ><a (click) = "userAccount()"> ... </a></span>

              <input  type="text" disabled="" name="_username" [ngModel] = "item._username">

          </label>
       
  `,
  */
})

export class BaseLink /* extends BaseForm */ {

    @Input('parent') parent;

    @Input('key') key;
    @Input('value') value;
    @Input('name') name = "userName"

    @Input('link') link = ['/user']  // []
    @Input('index') index = [];

    @Input('dropdown') dropdown = false;

    @Input('clearButton') clearButton = false;
    @Input('required') required = true;

    @Input('disabled') disabled = false;

    @Input('viewButton') viewButton = false;
    @Input('lookupButton') lookupButton = true;

    @Input('bs') bs = false;
   /*
    @Input('view') view = '';
     x

     item: any = {}
     _data: any ={}
     _name = ''


     @Input('data') set data(value) {
        this._data = value;
        let id = +this._data.patient_id;
     }

    getItem(id) {
        this.addressService.getItem(id,{})
            .subscribe(
                result => { this.item = result; },
                error  => { this.item = {}}
            )
    }


    constructor(private addressService:AddressService) {
        super();
    }

*/
    clear() {
         delete this.parent.item[this.key];
         this.parent.item[this.value] = null;
    }

    lookup() {

        //console.log('102',this)
        // this.parent.open(['/','user',this._data.assigned_to]);

        this.parent.lookup(this.link,this.key,this.value);

    }

    view() {
        let id = this.parent.item[this.key]
        if (id) {
           let link = this.link.concat([id]) ;  // detail
           this.parent.view(link,this.key,this.value);
        }
    }

    get viewName() {
      //  if (this.parent.item) {
            let view = this.parent.item[this.value];
            return view ? view[this.name] : ""
        //}
      //  return "";
    }

    get disabledColor() {
        return this.disabled ?  '#eee': '#fff'
    }

}
