import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';

import {isYearlyPrePaid} from "../../app.const"


export class SubscriptionItem extends BaseItem {


    lookupSchema() {   // duplicate wizard-client -------------------------------------------

        let arr = this.item._subscription_type ? this.item._subscription_type : [];

        let id = this.item.subscription_type_id;
        for (let elem of arr) {
            if (elem.id == id) {
                return +elem.plan_schema;
            }
        }
        return 0;
    }

    isQuarterly() {
        return this.lookupSchema() == 4;
    }


    isYearly() {
        return this.lookupSchema() == 1;  // yearly schema subscription
    }

    isMonthly() {
        return this.lookupSchema() == 3;  // monthly fixed price schema subscription
    }

    //  Std product subscriptions: Free Retail + Month + Year : Paypal + Stripe 7,9,15,16---------------------------

    isRetail() {
        return this.lookupSchema() == 15;  // One-time purchase
    }

    isTrial() {
        return this.lookupSchema() == 16;  // trial subscription
    }

    isInitSubscription() {
        return this.lookupSchema() == 5;
    }

    // 7,9,15

    isMonthlyPurchased() {
        return this.lookupSchema() == 7;  // monthly purchased
    }

    isYearlyPurchased() {
        return this.lookupSchema() == 9;  // yearly purchased
    }


    //--------------------------------------------------------------------------------------

    isMonthlyPostPaid() {
        return this.lookupSchema() == 140;
    }

    isYearlyPrePaid() {
       // return this.lookupSchema() == SUBSCRIPTION_PLAN_YEARLY_PREPAID;
        return isYearlyPrePaid(this.item)
    }

}
