import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';
import { OnInit } from '@angular/core';
import { FormGroup, FormControl,FormArray } from '@angular/forms';
import {ReactiveFormsModule} from "@angular/forms";
import {DynamicFormControlModel, DynamicFormService, DynamicFormArrayModel} from "../../sp-dynamic-forms/core";

//import 'pdfmake/build/pdfmake.min.js';
//import 'pdfmake/build/vfs_fonts.js';
//declare var pdfMake: any;


import {PdfService} from "../../service/pdf.service";

declare var $:any;

@Component({
    selector: "dynamic-form",
    styleUrls: [ "./dynamic-form.style.scss"],
    templateUrl: "./dynamic-form.template.html",
    encapsulation: ViewEncapsulation.None
})
export class DynamicForm  {

    @Input() readonly: boolean = false;

    dynamicFormModel: Array<DynamicFormControlModel>=[];
    form: FormGroup;

    name = '';

    constructor(el:ElementRef,private dynamicFormService: DynamicFormService,private pdfService:PdfService) {

        // =hasAttribute
        let name = $(el.nativeElement).attr('name')
        if (typeof name !== typeof undefined && name !== false) {
            // Element has this attribute
            this.name = name;
        }

        this.createForm();

    }

    createForm() {
        this.form = this.dynamicFormService.createFormGroup(this.dynamicFormModel);
    }

    addItem(arrayModel: DynamicFormArrayModel,formGroup:FormGroup) {

        console.log('arrayModel',arrayModel)

        let formArray = <FormArray> formGroup.get(arrayModel.id);
        this.dynamicFormService.addFormArrayGroup(formArray,arrayModel);
    }

    removeItem(arrayModel: DynamicFormArrayModel,formGroup:FormGroup,index:number) {

        let formArray = <FormArray> formGroup.get(arrayModel.id);

        arrayModel.removeGroup(index);
        formArray.removeAt(index);
    }


    @Input() set data(src:string) {

        if (src) {
            this.dynamicFormModel = this.dynamicFormService.fromJSON(JSON.parse(src));
        }
        else {
            this.dynamicFormModel = []; // this.dynamicFormService.fromJSON(JSON.parse(result.data));
        }
        this.createForm();

    }


    get data(): string {
        return  JSON.stringify(this.dynamicFormModel);
    }

    reset() {  // clear values
    }


    content() {
        return this.dynamicFormService.docDefinition(this.dynamicFormModel);
    }


    pdfOpen() {
        let dd = {
            pageSize: 'A5',
            // by default we use portrait, you can change it to landscape if you wish
            pageOrientation: 'portrait',

            content: [ this.content() ]
        }
//        pdfMake.createPdf(dd).open();
        this.pdfService.open(dd);
    }

    pdfDownload(fileName) {
        let dd = {
            content: [ this.content() ]
        }
//        pdfMake.createPdf(dd).download(fileName);
        this.pdfService.download(dd,fileName);
    }


}