import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {BaseList} from "../../auth/base-list";
import {PaypalAccountService} from "../../paypal-account/paypal-account.service";
import {SubscriptionService} from "../subscription.service";

@Component({
  selector: 'subscription-list',
  templateUrl: './subscription-list.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionList extends BaseList {

    constructor( el:ElementRef,private subscriptionService:SubscriptionService) {
        super(el,subscriptionService);
    }


}
