import { Component, ViewEncapsulation ,ElementRef,Input} from '@angular/core';

import {DiagnosisService}   from "../diagnosis.service";

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'diagnosis-list',
  templateUrl: './diagnosis-list.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class DiagnosisList extends BaseList {


  constructor(protected el: ElementRef,private ds: DiagnosisService) {
    super(el,ds);
  }

}
