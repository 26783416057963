import { Component,ViewEncapsulation, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl,FormArray } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { EventEmitter, ElementRef } from '@angular/core';


import {DiagnosisService} from '../diagnosis.service';
import {BaseItem}  from '../../auth/base-item';

@Component({
    selector: 'diagnosis-popup',
    templateUrl: './diagnosis-popup.template.html',
})
export class DiagnosisPopup extends BaseItem {

    constructor(el: ElementRef, protected route: ActivatedRoute, public ds: DiagnosisService) {
        super(el, route, ds);
    }

    @ViewChild('win') win

    paramId = 0
    callback = null

    detailId() {
        return this.paramId
    }

    _title = "Diagnosis"

    get title() {
        return this._title + this.modified
    }

    open(paramId, paramItems, callback) {

        this.item = {user_id: paramItems.user_id}
        this.paramId = paramId
        this.paramItems = paramItems

        this.getDetail()
        this.callback = callback
        this.win.open()
    }

    onOk() {
        this.saveAndBack()
    }

    back() {
        /*
        if (this.hasChanges()) {
            this.ds.authService.quitDialog()
                .subscribe(result => {
                    if (result) {
                        this.win.close()
                    }
                })
            return
        }
        */
        this.win.close()
        if (this.callback) this.callback() // refresh ....
    }

    onCancel() {
        if (this.hasChanges()) {
            this.ds.authService.quitDialog()
                .subscribe(result => {
                    if (result) {
                        this.win.close()
                    }
                })
            return
        }
        this.win.close()
    }
}