import {Component, Input, Output, ElementRef, EventEmitter} from '@angular/core';
import {DecoratorComponent} from "../../auth/decorator.component";

@Component({

    selector: 'subscription-yearly',
    template: `
                                             
                                          <div class="row">
                                                <section class="col col-6">
                                                    <label class="label">Patient</label>
                           
                           
                                                    <label class="input">
                                                        <i class="icon-prepend fa fa-edit" ></i>
                                                        <input type="text" name="cutoff_threshold"
                                                               [(ngModel)] ="item.cutoff_threshold"
                                                               currencyMask [options]="int0"
                                                               [readonly]="false">
                                                    </label>
                                                    <!--
                                                    <label class="select">
                                                        <select name="cutoff_threshold" [(ngModel)]="item.cutoff_threshold">
                                                            <option value="{{ref.id}}" *ngFor="let ref of item._cutoff_threshold" >{{ref.name}}</option>
                                                        </select> <i></i> </label>
                                                     -->
                                                </section>


                                                <section class="col col-6">
                                                    <label class="label">Price/year</label>

                                                    <label class="input">
                                                        <i class="icon-prepend fa fa-dollar" ></i>
                                                        <input type="text" name="price"
                                                               [(ngModel)] ="item.price"
                                                               currencyMask [options]="float2"
                                                               [readonly]="false">
                                                    </label>
                                                </section>

                                            </div>


 `,
})

export class SubscriptionYearlyComponent extends DecoratorComponent{

   // @Input('item') item;

    constructor(el:ElementRef) { super(el) }

}
