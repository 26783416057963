import {Injectable} from '@angular/core';

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';

@Injectable()
export class NewsletterService extends BaseService{

    constructor(public authService:AuthService){
        super(authService,"/api/newsletter");

        this.booleans = ['subscribed'];
    }

    doSubscription(value:boolean) {

        let url:string = `${this.baseURL}`;
        let opt: any = value ? {} : {unsubscribe:1};
        return this.authService.post(url, opt);
    }


}