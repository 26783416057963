import { Component, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
declare var jQuery: any;


import {ChartService}   from '../chart.service'

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'chart-user-list',

  styleUrls: [ './chart-user-list.style.scss' ],
  templateUrl: './chart-user-list.template.html',

  providers: [ChartService],

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class ChartUserList extends BaseList {

  @ViewChild('user_chart') user_chart:any;

  $el: any;

  selectItem: any = {};

  dateFormat: string = 'medium'; // 'mediumDate';

  constructor(el: ElementRef,private chartService: ChartService) {

    super(el,chartService);

    this.paramItems.user_id = chartService.authService.user_id();

    this.$el = jQuery(el.nativeElement);
  }


  openForm(item: any): void {
//    console.log('item.id',item.id);
    //this.onOpenForm.emit(item);
  }

  addForm(): void {
    this.openForm({});
  }
/*
  getList() {
    console.log(this.patient);
    this.paramItems.patient_id = this.patient;
    super.getList();
  }
*/
/*
  ngOnInit() {
    // no List
  }
*/

  //-------------------------------------------------------------------------------------------------------------

  addLink() {
     return ['chartwiz'];
  }

  visit_dt(item) {
    return (item.amended)? item.amend_visit_datetime: item.visit_datetime;
  }


}
