import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewContainerRef,
    forwardRef,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR,ControlValueAccessor  } from '@angular/forms';

import {AuthService} from "../../auth/auth.service";
import {CsvService} from "../../auth/csv/csv.service";
import {DYNAMIC_RADIOS_CONTROL_VALUE_ACCESSOR} from "../dynamic-radios/dynamic-radios.component";

const noop = () => {};

export const DYNAMIC_ARRAY_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DynamicArray),
    multi: true
};


@Component({

    selector: 'dynamic-array',
    templateUrl: './dynamic-array.component.html',
    providers: [DYNAMIC_ARRAY_CONTROL_VALUE_ACCESSOR]

})
export class DynamicArray implements ControlValueAccessor {

 //   @Input('item') item = {}

  //  $el = null


   // _fixed = false
    @Input('fixed') fixed = false

    @Input('index') index = [
        {id:1, name: 'Column 1'},
        {id:2, name: 'Column 2'},
        {id:3, name: 'Column 3'}

    ]
 //   colcount = 3
  //  rows = 3
    innerValue = [
        [],[],[]
    ]

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;



    get value(): any {
       // this.updateColumns(this.index)

        return this.copy(this.innerValue);
    }

    //set accessor including call the onchange callback
    set value(v: any) {

        if (Array.isArray(v)) {
        // if (v !== this.innerValue) {
            this.innerValue = this.copy(v);

            this.call_onChangeCallback() // this.onChangeCallback(this.innerValue);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    copy(val) {

        return val;
        /*
        let arr = val.map(item=>{
            let end =0
            let i = 0
            let x =[]
            for(let elem of item) {
                if (elem) {
                    end = i + 1;
                    x.push(elem)
                }
                else {
                    x.push('')
                }
                i = i+1;
            }
            return x.slice(0,end)
        })

        return arr;
        */
    }


    //From ControlValueAccessor interface
    writeValue(value: any) {

        if (value && Array.isArray(value)) {
            this.innerValue = this.copy(value)
        }

    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }




    @Input('options') set options(value) {

        if (value) {
            //this.fixed = value.fixed

            if (value.data && Array.isArray(value.data) && value.data.length) {
                this.innerValue = this.copy(value.data)

            }

            /*
            if (value.columns && Array.isArray(value.columns) && value.columns.length) {
                this.colcount = value.columns.length

                this._data[0] = value.columns.map((elem)=> { return elem})
                this.updateColumns()
            }

            let h = +value.rows
            if (h) {
                this.rows = h

                while (this.rows > this._data.length) {
                    let item = this._data[0].map((elem) => {
                        return ''
                    })
                    this._data.push(item)
                }
            }
            */
        }

    }


    ngOnInit() {
    }

    /*
    set data(value) {
        if (value && Array.isArray(value)) {
            this.innerValue = value
          //  this.updateColumns()
        }
    }
    */

/*
    //get data() {
    //
    //}

    formName(elem) {
        let name = this.$el.attr('name')
        if (!name) name = 'dynamic'
        return name + '_'+ this.itemName(elem)
    }

    constructor(private el: ElementRef) { //, private viewContainerRef: ViewContainerRef,public authService:AuthService, public csv: CsvService) {
        this.$el = $(el.nativeElement)
    }




    table_readonly() {
        if (this.builder) {
            return false;
        }
        if ((<DynamicFormArrayModel>this.model).fixedColumns > 0) return true;

        return this.readonly;
    }

    get rows() {
        return this._data && Array.isArray()
    }
*/

    indexTracker(index,value) {
        return index;
    }

    cell(row,col) {
       // if (row==0 && this.index && Array.isArray(this.index) && col < this.index.length) {
       //     return this.index[col].name
      //  }
        if (row < this.innerValue.length && col < this.innerValue[row].length ) {
            return this.innerValue[row][col]
        }

    }

    call_onChangeCallback() {
        let v = this.copy(this.innerValue)
        this.onChangeCallback(v);
    }

    change($event,row,col) {


        let val = $event // .target.value

        while (this.innerValue.length <= row ) {
            this.innerValue.push([])
        }
        while (this.innerValue[row].length <= col) {
            this.innerValue[row].push('')
        }
        this.innerValue[row][col] = val

        let item = this.innerValue[row]
        let end =0
        let i = 0
        let x =[]
        for(let elem of item) {
            if (elem) {
                end = i + 1;
                x.push(elem)
            }
            else {
                x.push('')
            }
            i = i+1;
        }
        this.innerValue[row] =  x.slice(0,end)




        this.call_onChangeCallback();
    }

    show_data() {
       console.log(this.innerValue)
    }

    /*
    get fixed() {
        return (this.options && this.options.fixed)
    }
     */
    get colcount() {
        return this.index && this.index.length ? this.index.length : 0
       // return this.innerValue && Array.isArray(this.innerValue) ? this.innerValue.reduce((acc,elem)=>{
       //     return Math.max(acc,elem.length)},0) : 0
    }



    get rowcount() {
        return this.innerValue && Array.isArray(this.innerValue) ? this.innerValue.length : 0
    }


    get addText() {
      //  return this.rowcount < 2 ? "Add row +" : "Add another row +"
        return this.rowcount < 2 ? "Add row" : "Add another row"
    }

    @Output('onAdd') public onAdd: EventEmitter<any> = new EventEmitter();
    @Output('onRemove') public onRemove: EventEmitter<any> = new EventEmitter();

    addMe() {
     //   if (this.rowcount > 0) {
          //  let row = this.innerValue[this.rowcount-1].map((elem)=> {return '' /*elem */})
            this.innerValue.push([]) // row)
    //    }
        this.onAdd.emit(this.rowcount)
        this.call_onChangeCallback() // this.onChangeCallback(this.innerValue);


    }
    removeMe(index) {
        this.innerValue.splice(index,1)
        this.onRemove.emit(index)
 //       this.onChangeCallback(this.innerValue);
        this.call_onChangeCallback() // this.onChangeCallback(this.innerValue);
    }

/*
    updateColumns(val) {

        if (val && Array.isArray(val) && this.innerValue) {

            let len = val.length

            console.log('set dyn index',val)

            for(let item of this.innerValue) {
                while (item.length < len) {
                    item.push('')
                }
                if (item.length > len) {
                    item.splice(len,item.length-len)
                }
            }

            if (this.innerValue  && this.innerValue.length) {

                let i = 0
                for  (let col of val) {
                    this.innerValue[0][i] = col.name
                    i = i +1
                }
            }

        }
    }

    */

/*
    @Input('index') //index = null
    set index(val) {

        console.log('set dyn index',val)
        if (val && Array.isArray(val)) {

            let len = val.length
            let updated = false



            for(let item of this.innerValue) {
                while (item.length < len) {
                    item.push('')
                    updated = true

                }
                if (item.length > len) {
                    item.splice(len,item.length-len)
                    updated = true
                }
            }

            if (this.innerValue.length) {

                let i = 0
                for  (let col of val) {
                    if (this.innerValue[0][i] != col.name) {
                        this.innerValue[0][i] = col.name
                        updated = true
                    }
                    i = i +1
                }
            }

            if (updated) this.onChangeCallback(this.innerValue)

        }
    }
    */

   @Input('disabled') disabled = false

}

