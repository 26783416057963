import {Injectable} from '@angular/core';

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';

@Injectable()
export class ShippingService extends BaseService{

    utc = [ 'started_at'];

    icon = 'fa fa-ship';

    constructor(public authService:AuthService){ super(authService,"/api/shipping");}
}