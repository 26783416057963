import {Component,ViewChild} from '@angular/core';
import {SpinnerService} from './spinner.service';

@Component({
    selector: 'spinner',
    styleUrls: ['spinner.component.css'],
    //I'm using in modal-backdrop classes from bootstrap
    template:
        ` <div *ngIf="spinner" class="spinner loading">
        <div class="in modal-backdrop spinner-overlay"></div>
     <div class="spinner-message-container" aria-live="assertive" aria-atomic="true">
 <!--       <div class="spinner-message" [ngClass]="spinnerMessageClass">{{ state.message }}</div>  -->
        <div class="spinner-message">{{ state.message }}</div>
    </div></div>

       <modal #info  (onClose) = "onInfoClose()" [hideCloseButton]="false"
          >
          <modal-header>
             <h1>{{modalHeader}}</h1>
          </modal-header>
          <modal-content>
                {{modalContent}}
          </modal-content>
          <modal-footer>
              <button class="btn btn-default " style="width:10rem;" (click)="info.close()">Close</button>
          </modal-footer>
        </modal>

       <modal #quitDialog  (onClose) = "quitDialogClose()" [hideCloseButton]="false"
          >
          <modal-header>
             <h1>Are you sure ?</h1>
          </modal-header>
          <modal-content>
               Data has changed
          </modal-content>
          <modal-footer>
              <button class="btn btn-default  pull-xs-left" (click)="quitDialog.close()"  style="width:10rem;">Cancel</button>
              <button class="btn btn-primary " style="width:11rem;" (click)="quitDialogOk()">Quit anyway</button>
          </modal-footer>
        </modal>

       <modal #yesDialog  (onClose) = "yesDialogClose()" >
          <modal-header>
             <h1>{{modalHeader}}</h1>
          </modal-header>
          <modal-content>
                {{modalContent}}
          </modal-content>
          <modal-footer>
              <button class="btn btn-primary " style="width:10rem;" (click)="yesDialogOk()">Yes</button>
              <button class="btn btn-default " style="width:10rem;" (click)="yesDialog.close()">No</button>
              <button class="btn btn-default " style="width:10rem;" (click)="yesDialog.close()">Cancel</button>
           </modal-footer>
        </modal>

       <modal #periodDialog  (onClose) = "periodDialogClose()" [hideCloseButton]="false">
          <modal-header>
             <h1>Select period</h1>
          </modal-header>
          <modal-content>
               <period #period [data]="this"></period>
          </modal-content>
          <modal-footer>
              <button class="btn btn-default  pull-xs-left" (click)="periodDialog.close()"  style="width:10rem;">Cancel</button>
              <button class="btn btn-primary " style="width:11rem;" (click)="periodDialogOk()">OK</button>
          </modal-footer>
        </modal>

      <modal #selectDialog  (onClose) = "selectDialogClose()" [hideCloseButton]="false">
          <modal-header>
             <h1>{{selectHeader}}</h1>
          </modal-header>
          <modal-content>
               <form  class="smart-form">
                     <fieldset>

                         <section *ngFor=" let item of selections; let i=index">
                             <label class="radio">
                                 <input type="radio" name="radio1" [(ngModel)]="selected"
                                        [value]="item.id">
                                 <i></i>{{item.name}}
                             </label>
                         </section>

                         <!--
                                                  <section *ngFor=" let item of selections; let i=index">
                                                                 <label class="radio">
                                                                     <input type="radio" name="radio1" [(ngModel)]="selected"
                                                                            [value]="i">
                                                                     <i></i>{{item}}
                                                                 </label>
                                                             </section>
                           -->
                     </fieldset>
               </form>
          </modal-content>
          <modal-footer>
              <button class="btn btn-default  pull-xs-left" (click)="selectDialog.close()"  style="width:10rem;">Cancel</button>
              <button class="btn btn-primary " style="width:11rem;" (click)="selectDialogOk()">OK</button>
          </modal-footer>
        </modal>

    <modal #pivotDialog  (onClose) = "dialogClose()" [hideCloseButton]="false">
        <modal-header>
            <h1>Select data & data layout</h1>
        </modal-header>
        <modal-content>
            <pivot-select [data]="dialogItem"></pivot-select>
        </modal-content>
        <modal-footer>
            <button class="btn btn-default  pull-xs-left" (click)="pivotDialog.close()"  style="width:10rem;">Cancel</button>
            <button class="btn btn-primary " style="width:11rem;" (click)="dialogOk();pivotDialog.close()">OK</button>
        </modal-footer>
    </modal>


    <modal #availableHoursDialog  (onClose) = "dialogClose()" [hideCloseButton]="false">
        <modal-header>
            <h1>Edit Availability</h1>
        </modal-header>
        <modal-content>
            <form class=" form-horizontal">
                <fieldset>
                    <!--
                    <available-hours-dialog [data]="dialogItem" #avalableHoursDialogComponent></available-hours-dialog>
                    -->
                </fieldset>
            </form>
        </modal-content>
        <modal-footer>
            <button class="btn btn-default  pull-xs-left" (click)="availableHoursDialog.close()"  style="width:10rem;">Cancel</button>
            <button class="btn btn-primary " style="width:11rem;" (click)="availableHoursDialogOk()">OK</button>
        </modal-footer>
    </modal>

    <modal #appointmentDialog  (onClose) = "dialogClose()" [hideCloseButton]="false">
        <modal-header>
            <h1>Edit Appointment</h1>
        </modal-header>
        <modal-content>
          <!--  <div class="widget-body">  -->
                <form class=" form-horizontal">

                    <fieldset>
                        
    <!--        <appointment-dialog #appointmentDialogComponent></appointment-dialog>   -->
                    </fieldset>
                </form>
        <!--    </div>  -->
        </modal-content>
        <modal-footer>
            <button class="btn btn-default  pull-xs-left" (click)="appointmentDialog.close()"  style="width:10rem;">Cancel</button>
            <button class="btn btn-primary " style="width:11rem;" (click)="appointmentDialogOk()">OK</button>
        </modal-footer>
    </modal>

    `
})
export class SpinnerComponent {

    @ViewChild ('info') info: any;
    @ViewChild('quitDialog') quitDialog;
    @ViewChild('yesDialog') yesDialog;

    state = {
        message: 'Please wait...'
    };


    public spinner: boolean = false;

    modalHeader: string = '';
    modalContent: string = '';


    quitResult: boolean = false;


    onInfoClose() {
        //console.log('onInfoClose');
    }

    quitDialogClose() {
        //console.log('quitDialogClose');
        if (this.quitResult) {
            this.quitResult = false;
        }
        else {
            this.spinnerService.quitDialogResult.next(false);
        }
    }
    quitDialogOk() {
        //console.log('quitDialogOk');
        this.quitResult = true;
        this.spinnerService.quitDialogResult.next(true);
        this.quitDialog.close();
    }

    //-----------------------------------------------------------------------------------------

    yesResult: boolean = false;

    yesDialogClose() {
        if (this.yesResult) {
            this.yesResult = false;
        }
        else {
            this.spinnerService.yesDialogResult.next(false);
        }
    }

    yesDialogOk() {
        this.yesResult = true;
        this.spinnerService.yesDialogResult.next(true);
        this.yesDialog.close();
    }


   // @ViewChild('period') periodComponent;


    public constructor(private spinnerService: SpinnerService) {

        spinnerService.status.subscribe((status: any) => {


            if (status.dialog=='appointmentDialog') {
                this.appointmentDialogComponent.setItem(status.item);
                this.appointmentDialog.open();
                return
            }

            //console.log('status',status)

            //console.log('spinner',status)
            this.spinner = status.spinner;
            //}
            if (status.message) {
                this.modalHeader = status.message.header;
                this.modalContent = status.message.content;
                this.info.open();
            }
            else {
                this.info.close();
            }

            this.quitResult = false;
            if (status.quitDialog) {
                this.quitDialog.open();
            }
            else {
                this.quitDialog.close();
            }

            this.yesResult = false;
            if (status.yesDialog) {
                this.modalHeader = status.header;
                this.modalContent = status.content;
                this.yesDialog.open();
            }
            else {
                this.yesDialog.close();
            }

            //this.periodResult = { result: false}
            if (status.periodDialog) {
               this.periodResult = status.item;
          //      if ((this.period == 5)) {   // sp ++
           //         this.periodComponent.dt_period[5] = this.dt1;
           //     }

                this.periodDialog.open();
            }


            if (status.selectDialog) {
                let item = status.item;

                this.selected = +item.selected ;

                this.selections = item.selections ? item.selections : [];
                this.selectHeader = item.header ? item.header : 'Select action';
 //               console.log(this.selected);

                this.selectDialog.open();
            }


            if (status.pivotDialog) {
                this.dialogItem = status.item;
                this.pivotDialog.open();
            }

            if (status.dialog=='availableHoursDialog') {
                this.dialogItem = status.item;
                this.availableHoursDialog.open();
            }


        });



    }

    //-----------------------------------------------------------------------------------------

//    periodResult: any = { result: false};

    @ViewChild('periodDialog') periodDialog;
    dt1: any = null;
    dt2: any = null;
    period = 0;

    set periodResult(item) {

        this.dt1 = item.dt1;
        this.dt2 = item.dt2;
        this.period = +item.period ? +item.period: 0 ;

    }

    get periodResult() {
        //console.log(this.dt1);
        return {dt1: this.dt1, dt2: this.dt2, period: this.period}
    }

    periodDialogClose() {
    /*
        if (this.periodResult.periodResult) {
            this.periodResult.result = false;
        }
        else {
            this.spinnerService.periodDialogResult.next({ result: false,});
        }

        this.spinnerService.periodDialogResult.next({ result: false,});
    */
        this.spinnerService.periodDialogResult.next({ result: false, item: {}});
    }

    periodDialogOk() {
        this.spinnerService.periodDialogResult.next({result: true, item: this.periodResult});
        this.periodDialog.close();
    }

    //-----------------------------------------------------------------------------------------

    @ViewChild('selectDialog') selectDialog;
    selected = 0;
    selections=[];
    selectHeader = '';


    selectDialogClose() {
        //     this.spinnerService.selectDialogResult.next({ result: false, selected: -1});
        this.spinnerService.selectDialogResult.next({ result: false, selected: 0});
    }

    selectDialogOk() {
        this.spinnerService.selectDialogResult.next({result: true, selected: this.selected});
        this.selectDialog.close();
    }


    dialogItem = {}

    dialogClose() {
        this.spinnerService.dialogResult.next({ result: false, item: {}});
    }

    dialogOk() {
        this.spinnerService.dialogResult.next({result: true, item: this.dialogItem});
        //   this.pivotDialog.close();
    }

    @ViewChild('pivotDialog') pivotDialog;

    //-----------------------------------------------------------------------------

    @ViewChild('availableHoursDialog') availableHoursDialog;
    @ViewChild('avalableHoursDialogComponent') availableHoursDialogComponent


    availableHoursDialogOk() {
        let item = this.availableHoursDialogComponent.getItem()
        this.spinnerService.dialogResult.next({result: true, item: item});
        this.availableHoursDialog.close()

    }

    //-----------------------------------------------------------------------------

    @ViewChild('appointmentDialog') appointmentDialog;
    @ViewChild('appointmentDialogComponent') appointmentDialogComponent


    appointmentDialogOk() {
        let item = this.appointmentDialogComponent.getItem()
        this.spinnerService.dialogResult.next({result: true, item: item});
        this.appointmentDialog.close()

    }

}