import { Component, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
declare var jQuery: any;


import {ChartService}   from '../chart.service'

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'chart-table',
  templateUrl: './chart-table.template.html',
/*
  styleUrls: [ './chart-list.style.scss' ],
  providers: [ChartService],

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
*/
})
export class ChartTable extends BaseList {


  constructor(el: ElementRef,private chartService: ChartService) {

    super(el,chartService);

 //   this.paramItems.user_id = chartService.authService.user_id();

  }

  user_id = 0;

  onReadItems(result) {
    this.user_id = result.user_id;
  }

  get title() {
    return this.isAdmin ?  'Chart notes' : 'My charts notes'
  }

  addLink() {
     return ['chartwiz'];
  }

  visit_dt(item) {
    return (item.amended)? item.amend_visit_datetime: item.visit_datetime;
  }

  clickItem(item) {  // override
//    console.log(item)
    if (!this.baseService.authService.isUser(item.user_id)) {
      if (item.doc_state == "draft") {
        this.baseService.authService.showError('The record is draft.')
        return
      }
      if (item.doc_state ==  "locked") {
        this.baseService.authService.showError('The record is locked.')
        return
      }

    }
    super.clickItem(item)
  }


    @ViewChild('template_select') templateSelect


    clickAdd() {
        this.templateSelect.show();
    }


    onSelectTemplate(template) {
        if (template) {

            this.paramItems.chart_template_id = template.id;
            this.baseService.authService.tag = this.paramItems; // {patient_id:this.paramItems.patient_id}

            //console.log('paramItems',this.paramItems)
            this.baseService.authService.navigate([this.baseService.path,0],null)

        }
    }

    onCancelTemplate() {
        //   this.displayUser = false;
    }

//****************************************************************************************************
/*
    @ViewChild('template_select_popup') template_select_popup

    clickAdd() {
        this.template_select_popup.open();
    }


*/

}
