import {Component, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';


import {ProductUserService} from '../product-user.service';

@Component({
    selector: 'product-user-detail',
    templateUrl: './product-user-detail.template.html',
})
export class ProductUserDetail extends BaseItem {

    constructor(el: ElementRef,protected route:ActivatedRoute, protected productUserService:ProductUserService) {

        super(el,route, productUserService);
    }

}
