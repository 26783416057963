import {Component, ViewEncapsulation, forwardRef, ElementRef} from '@angular/core';
import { ViewChild,Output, Input,EventEmitter} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

const noop = () => {};

import { AuthService } from '../auth/auth.service';
import {saveAs} from 'file-saver';
//let fileSaver = require('filesaver.js');

//accept="application/pdf,image/jpeg,image/png,text/plain"

@Component({
    selector: 'ehis-file',
    template:
  ` 

<!--    
    
        <div class="form-group row">
<label for="input-area" [ngClass]="labelClass"><ng-content></ng-content></label>
 
        <div [ngClass] = "inputClass">
        ngFileSelect
                  <input #upload_hidden type="file" name="upload" id="upload_hidden"
                                      [options]="uploadOptions()"
     
 onclick="this.value = null"
 
 
            <a href="javascript:void(0)" target="_blank" role="button" (click)="download()" download="{{fileName}}">{{getFileName()}}</a>

 -->
        <div class="input-group">
        
        
           <input type="file" name="upload"

                  load-image
                  
                         style="position: absolute; display: block; overflow: hidden; width: 0; height: 0; border: 0; padding: 0;"
                         
                         [accept]="accept()"
                  
                         (onUpload)="handleUpload($event)"
                         (beforeUpload)="beforeUpload($event)"
                         (onUploadRejected)="onUploadRejected($event)"

                 
           
           >
           <p *ngIf="!photo && value" style="margin-right: 1rem;padding-top: 0.52rem">
           <a class="btn btn-link" role="button" (click)="download()">{{getFileName()}}</a>
           </p>
                  
                   <div class="input-group-btn">
                        <button *ngIf="!photo" class="btn btn-success ladda-button btn-rounded" type="button" style="line-height: 22px;"
                            (click)="download()" 
                            [hidden]="(!value) || photo"
                            [disabled]="(!value) || is_uploading"
                            [ladda]="is_downloading" data-size="xs">
                            <span ladda-label><i class="fa fa-download"></i>&nbsp;&nbsp;Download</span>
                        </button>
                         
                        <button class="btn btn-primary ladda-button btn-rounded" type="button" style="line-height: 22px;"
                            (click)="upload()"
                            [hidden]="readonly && !builder"

                            [disabled]="readonly || is_downloading"
                             [ladda]="is_uploading" data-size="xs">
                             <span ladda-label>
                             <i *ngIf="!photo" class="fa fa-upload"></i>
                             <i *ngIf=" photo" class="fa fa-file-photo-o"></i>
                             &nbsp;&nbsp;Upload</span>
                        </button>
                                                
                        <button class="btn btn-secondary" type="button" style="line-height: 22px;" 
                            (click)="clear()" 
                            [hidden]="(!value) || readonly" 
                            [disabled]="(!value) || readonly || is_downloading || is_uploading">
                            <span ladda-label ><i class="fa fa-close"></i>&nbsp;&nbsp;Clear</span>
                        </button>
                        

                    </div>
      
                  
        </div> 
 
                <div *ngIf="hasPhoto()">
                   <img [src]="img_src"  class="img-fluid float-xs-center"  style="width:100%; height: auto;">

                </div>
        
        
        
        <!--
       </div>
                    <a *ngIf="!_readonly()" type="button" class="btn btn-sm btn-secondary "  (click)="clearUpload()"><i class="fa fa-times" aria-hidden="true"></i></a>
                    <img (onImageLoaded)="onImgLoaded($event)" [ehis-img-src]="model.link" 
                     class="img-fluid float-xs-center"  style="width:100%; height: auto;">
 


                          accept="image/jpeg,image/png"
        
                                 <input #upload_hidden type="file" name="upload" id="upload_hidden"
                                       style="position: absolute; display: block; overflow: hidden; width: 0; height: 0; border: 0; padding: 0;"
                                       accept="image/jpeg,image/png"
                                       ngFileSelect
                                       [options]="uploadOptions()"
                                       (onUpload)="handleUpload($event)"
                                       (beforeUpload)="beforeUpload($event)"
                                       (onUploadRejected)="onUploadRejected($event)"
                                        >
       
        
        
                        <div class="input-group">
                    <input type="text" autofocus placeholder="Search by domain name, IP address, URL ..." name="target" class="form-control"
                           [(ngModel)]="target"
                           autocomplete="off" autocorrect="off" (autocapitalize)="off" spellcheck="false"
                    >

                    <div class="input-group-btn">
                        <button class="btn btn-primary" type="submit" style="line-height: 22px;"
                           [ladda]="isInvestigating()" data-size="xs"
                           (click)="investigate()">
                            <span ladda-label ><strong>Investigate</strong></span>
                        </button>
                    </div>
                </div>

    </div>
        -->
        
        
   `,


    encapsulation: ViewEncapsulation.None,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisFileComponent), multi: true },
    ]
})
export class EhisFileComponent implements ControlValueAccessor {

    @Input('readonly') readonly : boolean = false;
//    @ViewChild('input_area') input_area;

  //  @ViewChild('upload_hidden') upload_hidden; // = document.getElementById('module-component');


    constructor(private el: ElementRef,protected authService:AuthService) {

 //       super();
    }

    private innerValue: any = '';

    @Input() fileName: string = "";
    @Output() fileNameChange = new EventEmitter<string>()


    @Input() set link(val) {
        this.innerValue = val;
    }
    @Input() tag:any = {};

    @Output() onUpload = new EventEmitter();

    @Input() photo: boolean = false;

    @Input() builder: boolean = false;



    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled:boolean):void {    // new ???
    }

    // col-md classes ////////////////////////////////////////////////////////////////////////////////////////

    @Input('col-label') colWidth: number = 3;

    get labelClass() {
        let w = this.colWidth;
        return `form-control-label col-md-${w} col-xs-12 text-md-right`;
    }

    get inputClass() {
        let w = 12 - this.colWidth;
        return `col-md-${w} col-xs-12`;
    }


    //////////////////////////////////////////////////////////////////////////////////////////////

    is_uploading: boolean = false;
    is_downloading: boolean = false;



    uploadOptions() {
        return this.authService.uploadOptions();
    }

    /*
    beforeUpload(uploadingFile: any ) {

       let sizeLimit = this.authService.upload_max_size();


        if (uploadingFile.size > sizeLimit) {
            uploadingFile.setAbort();
            let errMsg = 'File is too large! Maximum allowed file size is '+ this.authService.upload_max_size_str()+'.';
            this.authService.showError(errMsg);
        }
        else {

            this.is_uploading = true;
//        this.authService.spinnerStart();
        }
    }
    onUploadRejected($event) {
        this.is_uploading = false;
//        this.authService.spinnerStop();
        this.authService.showError('Upload rejected');
    }
    */

    onFileLoaded($event) {

//        this.authService.spinnerStop();
    }

    emitOnUpload() {
        if (this.photo) {
            this.ehis_img_src(this.value);
        }
        else {
            this.is_uploading = false;
        }
        this.onUpload.emit({fileName: this.fileName, link: this.value, tag: this.tag});
    }

    /*
    handleUpload(data): void {
        if (data && data.response) {
//            this.authService.spinnerStop();
            data = JSON.parse(data.response);

            if (data.error) {
                this.is_uploading = false;

                this.authService.showError('Upload error. Upload rejected.');
                return;
            }

            let link = this.urlName(data.src);

 //           let url = this.authService.getApiUrl() + '/api/image/' + raw_link.slice(i + 1);


            this.value = link;

            let s = this.upload_hidden.nativeElement.value;

            let fileName = s.split(/(\\|\/)/g).pop();
//            console.log(fileName); // file name

            this.fileName = fileName;

//            this.is_uploading = false;
            this.emitOnUpload();


 //           this.onUpload.emit({ fileName: this.fileName, link: this.value , tag: this.tag });

        }
    }


    clear() {
        this.value = '';
        this.fileName = '';
        this.emitOnUpload();
    }

     */

    download() {
        this.is_downloading = true;

        let link = this.urlName(this.value);
        let url = this.apiUrl(link);

        this.authService.getBlob(url)
            .subscribe(
                (result)=> {
                    var blob = result // .blob();

                    if (blob.size==0) {
                        this.is_downloading = false;
                        this.authService.showError('Download file error: no file.')
                    }
                    else {

                      if (this.authService.ie) {
                          window.navigator.msSaveOrOpenBlob(blob, this.fileName);

                      }
                      else {
                          var windowReference = window.open();

                          var urlCreator = window.URL;
                          var locUrl = urlCreator.createObjectURL(blob);
                          windowReference.location.href = locUrl;
                          windowReference.document.title = this.fileName;
                      }

                      this.is_downloading = false;

                        /*
                         if (blob.size==0) {
                         this.photoURL = '';
                         }
                         else {
                         var urlCreator = window.URL;//|| window.webkitURL;
                         var imageUrl = urlCreator.createObjectURL(blob);

                         this.photoURL = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
                         }
                         this.onImageLoaded();
                         */
                    }
                },
                (error)=> {
                    this.is_downloading = false;
                    this.authService.showError(error);
                })


    }

    urlName(value) {

        var i = value.lastIndexOf('/');
        if (i < 0) { i = value.lastIndexOf('\\'); }
        if (i < 0) { return value; };

        return  value.slice(i + 1);
    }

    apiUrl(value) {
        return this.authService.getApiUrl() + '/api/image/' + this.urlName(value);
    }

//-------------------------------------------------------------------------------------------

    img_src: any = '';

    hasPhoto() {
       return this.photo && this.value;
    }


    ehis_img_src(value:string) {

        // map value ...
        if (value) {

           let url = this.apiUrl(value); // this.authService.getApiUrl() + this.urlName(value);

            this.authService.getBlob(url)
                .subscribe(
                    (result)=>{
                        var blob = result // .blob();

                        /*
                         var urlCreator = window.URL ;//|| window.webkitURL;
                         let imageUrl: any  = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL( blob ));
                         this.img_src = imageUrl;
                         */
                        var reader = new FileReader(); //window.FileReader();

                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            let base64data = reader.result;
// !!!                  (this.model as DynamicFileModel).base64data = base64data;
                            this.img_src = base64data;
                            this.is_uploading = false;

                            if (this.tag) {
                                this.tag.base64data = base64data.toString(); // + toString  +7
                            }
                        }

                    },
                    (error)=>{ this.is_uploading = false;})

        }
        else {
            this.img_src = '';
            if (this.tag) {
                this.tag.base64data = null
            }

            this.is_uploading = false;
        }
    }

    ngOnInit() {
        //super.ngOnInit();
        if (this.photo) {
            this.ehis_img_src(this.value);
        }
    }

/*
    upload() {

        this.upload_hidden.nativeElement.click();
    }

*/
    ////////////////////////////////////////////////////////////////////////////////////////////////

    accept() {
        if (this.photo) {
            return "image/jpeg,image/png";
        }
        else {
            return "application/pdf,image/jpeg,image/png,text/plain";
        }
    }

    upload() {
        this.el.nativeElement.querySelector('input').click()
    }

    clear() {
        this.value = '';
        this.fileName = '';
        if (this.tag) {
            this.tag.base64data = null; // + toString  +7
        }

        this.emitOnUpload();


    }


    handleUpload(data): void {

        console.log('handleUpload')

        if (data && data.done && data.response) {
//            this.authService.spinnerStop();
            data = JSON.parse(data.response);

            if (data.error) {
                this.is_uploading = false;

                this.authService.showError('Upload error. Upload rejected.');
                return;
            }
            let link = this.urlName(data.src);

            this.value = link;

            let s = this.el.nativeElement.querySelector('input').value


            let fileName = s.split(/(\\|\/)/g).pop();

            //console.log(fileName); // file name

            this.fileName = fileName;

            this.fileNameChange.emit(fileName);

            this.emitOnUpload();


            //           this.onUpload.emit({ fileName: this.fileName, link: this.value , tag: this.tag });

         }
    }

    beforeUpload(uploadingFile: any /* UploadedFile */) {

        this.is_uploading = true;
    }

    onUploadRejected($event) {
        this.is_uploading = false;
//        this.authService.spinnerStop();
        this.authService.showError('Upload rejected');
    }

    getFileName() {
        return this.fileName ? '['+this.fileName+']' : ''
    }


}
