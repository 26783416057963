import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-select-period',
    template: `
       <select-item [data]="data._period"        
                    [item]= "data.paramItems" 
                    [name]="'period_id'" 
                    (change)="data.onSelectChange($event)">
       All periods
       </select-item>

  `,
})
export class ListSelectPeriodComponent {

    @Input('data') data;
}

