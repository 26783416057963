import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-add-services',
  templateUrl: './add-services.component.html',
  styleUrls: ['./add-services.component.scss']
})
export class AddServicesComponent implements OnInit {

  // drop down values
  charge_types_list = [{ id: 1, name: 'Per Hour' }, { id: 2, name: 'Per Session' }];

  // variables to be used as models in form
  _name: String = "";
  _description: String = "";
  _price: Number = 0;
  _charge_type: any = this.charge_types_list[0].id;
  _tax: boolean = false;
  _currency_format: String = 'CAD';

  add_service: Boolean = true;
  delete_service: Boolean = false;

  nameError: boolean = false;
  descError: boolean = false;

  constructor() {

  }
  ngOnInit(): void {
    this.nameError = false;
    this.descError = false;
  }

  @Input() item = null

  @ViewChild('Servicesform') modalForm;
  @ViewChild('heroForm') heroForm;


  formOnOpen() {
  }

  formOnClose() {
    // this.resetFormData()
  }

  show() {
    this.modalForm.open()
    if (this.add_service) {
      this.nameError = false;
      this.descError = false;
    }
  }


  @Output('onDelete') onDelete = new EventEmitter()
  @Output('onSelect') onSelect = new EventEmitter()

  validateData() {
    let isDataValid: boolean = true
    if (this._name.trim() === '') {
      this.nameError = true;
      isDataValid = false
    }
    if (this._description.trim() === '') {
      this.descError = true
      isDataValid = false
    }
    return isDataValid
  }
  clickOk() {
    if (!this.validateData()) {
      return
    }
    this.updateItemValues()
    this.onSelect.emit(this.item)
    this.resetFormData()
    this.modalForm.close();
  }

  clickDelete() {
    this.delete_service = true;
    this.clickOk()
  }

  closeForm() {
    this.descError = false;
    this.nameError = false;
    // this.onSelect.emit(null)
    this.resetFormData()
    // this.heroForm.form.reset()
    this.modalForm.close();
  }

  resetFormData() {
    this._name = "";
    this._description = "";
    this._price = 0;
    this._charge_type = this.charge_types_list[0].id;
    this._tax = false;
    this.add_service = true;
    this._currency_format = 'CAD'
  }

  getChargeTypeByName(name: String) {
    return this.charge_types_list.filter(item => item.name === name)[0]
  }

  getChargeTypeById(id: Number) {
    return this.charge_types_list.filter(item => item.id === id)[0]
  }

  updateItemValues() {
    if (this.item == null || this.item == undefined) {
      this.item = {}
    }
    this.item.serviceName = this._name.trim();
    this.item.description = this._description.trim();
    this.item.price = this._price;
    this.item.priceType = parseInt(this._charge_type);
    this.item.tax = this._tax;
    this.item.currencyFormat = this._currency_format;
  }

  populateFormValues() {
    if (this.item != null || this.item != undefined) {
      this._name = this.item.serviceName;
      this._description = this.item.description;
      this._price = this.item.price;
      this._charge_type = this.item.priceType;
      this._tax = this.item.tax;
      this._currency_format = this.item.currencyFormat;
    }
  }

}
