import {Injectable} from '@angular/core';
//import {Http, Response} from '@angular/http';
//import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
//import {Observable}     from 'rxjs/Observable';
//import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class MessageService extends BaseService{

    constructor(public authService:AuthService){
        super(authService,"/api/notification");
    }

    postMessage(conto_id, body ){

        let url:string = `${this.baseURL}`;

        let item = {
          conto_id: conto_id,
          body: body,
        };

        return this.authService.post(url,item);
    }


    unread(message_id,conto_id){

        let url:string = `${this.baseURL}/unread`;

        let item = {
            id: message_id,
            conto_id: conto_id,
        };

        return this.authService.post(url,item);
    }

}