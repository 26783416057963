import {Injectable} from '@angular/core';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class ProductUserService extends BaseService{

    constructor(public authService:AuthService){ super(authService,"/api/product_user");}


}