import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BaseForm} from "../../auth/base-form";
import {DecoratorComponent} from "../../auth/decorator.component";

@Component({

    selector: 'product-user-warnings',
    template: `


<!--
                                            <div class="row">
 

                                       <section class="col col-6">
                                            <label class="label">Email to</label>
                                            <label class="input">
                                                <i class="icon-append fa fa-envelope"></i>
                                                <input type="text" name="low_query_address" emails="true" [(ngModel)]="item.low_query_address">
                                               </label>
                                        </section>
                                        
                                        <section class="col col-6">
                                            <label class="label">Bcc</label>
                                            <label class="input">
                                                <i class="icon-append fa fa-envelope"></i>
                                                <input type="text" name="low_query_bcc" emails="true" [(ngModel)]="item.low_query_bcc">
                                               </label>
                                        </section>


                                        </div>
                                        
-->                                        
                                        <div class="row">
                                        <section class="col col-12">
                                                      <label class="label">Warnings</label>

              <table class="table table-bordered table-hover table-widget table-striped price-table">

              <thead>
                                                    <!--
                                                    <tr>
                                                        <th style="width:15%"></th>
                                                        <th style="width:85%;">Bcc</th>
                                                    </tr>
                                                    -->
              </thead>
              <tbody>
              <tr >
                  <td style="width:50%">
                                           <label class="radio">
                                                <input type="checkbox" name="low_query" class="checkbox style-0"
                                                       [(ngModel)]="item.low_query"/>
                                                <span> Low query balance </span></label>
                   </td>
                      <td class="td-input">
                     
                                                   <label *ngIf="item.low_query" class="input">
                                                        <i class="icon-append fa fa-edit" ></i>
                                                        <input type="text" name="low_query_balance"
                                                               [(ngModel)] ="item.low_query_balance"
                                                               placeholder="Enter the balance"
                                                               currencyMask [options]="int0"
                                                               [readonly]="false">
                                                    </label>
                       </td>
                  </tr>

                  <tr>
                      <td >
                                           <label class="radio">
                                                <input type="checkbox" name="zero_query" class="checkbox style-0"
                                                       [(ngModel)]="item.zero_query"/>
                                                <span> Zero query balance </span></label>
                      </td>
                      <td class="td-input">
                       </td>
                  </tr>


              </tbody>
         </table>


       </section>
    </div>
 

 `,
})
export class ProductUserWarningsComponent extends DecoratorComponent{

}

