
import {Injectable} from '@angular/core';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class TaskTodoService extends BaseService{

    icon="fa fa-bell-o"

    utc = [ 'start_at', 'started_at','executed_at'];

  //  constructor(public authService:AuthService) { super(authService,"/api/task","/todo") }
    constructor(public authService:AuthService) { super(authService,"/api/todo") }

  getTask() {
      let url:string = `${this.baseURL}/getTask?per_page=50&_task_state=1`;
      return this.authService.get(url);
  }
}