import { Component, ViewEncapsulation, forwardRef } from '@angular/core';
import { Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

const noop = () => {};

//import { AuthService } from '../../auth/auth.service';

// https://github.com/valor-software/ng2-bootstrap/issues/371
// index.html !!!!!
//<script type="text/javascript">
//    // temporary hack for enable bootstrap 4  for typeahead
//window.__theme = 'bs4';
//</script>

@Component({
    selector: 'ehis-suggest-province',
    template:
  ` <div class="form-group row">
        <label for="province" [ngClass]="labelClass"><ng-content></ng-content></label>
        <div [ngClass] = "inputClass">
        <input id="province" class="form-control form-control-sm  pull-xs-left" placeholder="Province" [(ngModel)]="value"
            [typeahead]="data"
            [typeaheadOptionField]="'name'"
            (typeaheadOnSelect)="typeaheadOnSelect($event)"
            autocomplete="off"

             >
        </div>
    </div>
   `,
//    styleUrls: ['./ehis-suggest-province.style.scss'],
//    type="text"
//  providers: [ChartTemplateService],

    encapsulation: ViewEncapsulation.None,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisSuggestProvinceComponent), multi: true },
    ]
})
export class EhisSuggestProvinceComponent implements ControlValueAccessor {

    private innerValue: any = '';

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled:boolean):void {    // new ???
    }

    // col-md classes ////////////////////////////////////////////////////////////////////////////////////////

    @Input('col-label') colWidth: number = 3;

    get labelClass() {
        let w = this.colWidth;
        return `form-control-label col-md-${w} col-xs-12 text-md-right`;
    }

    get inputClass() {
        let w = 12 - this.colWidth;
        return `col-md-${w} col-xs-12`;
    }

    // Province ////////////////////////////////////////////////////////////////////////////////////////
/*
    public data :Array<any> = [
        // {id: 1, name: 'Alabama',
        // {id: 4, name: 'Arkansas'},
    ];

    constructor(private authService:AuthService) {
    }
*/

    @Input() data: any[] = [];

    public typeaheadOnSelect(e:any):void {

        if (e.item) {
            this.value   = e.item.name;
        }
    }

    ngOnInit() {
/*
        this.authService.getReference('province')
          .subscribe(
            (result) => {this.data = result._province },
            (error) =>  {this.authService.showError(error)}
          );
*/
    }


}
