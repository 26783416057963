/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


declare var jQuery: any;


import {AuthService} from "../../auth/auth.module"
import {BaseItem} from "../../auth/base-item";

import {UserService} from '../../service/user.service';
import {AddUser} from "../list/add-user";


@Component({
    selector: '[add-whois-user]',
    templateUrl: './add-whois-user.template.html',
})
export class AddWhoisUser extends AddUser{

    constructor (el: ElementRef,route: ActivatedRoute,userService:UserService) {
        super(el,route,userService,3);
    }

}

