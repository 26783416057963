import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import {AuthModule} from "../auth/auth.module";
// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap';


//import { ModalModule }             from 'ng2-modal';
//import { TypeaheadModule }         from "ngx-bootstrap" //from 'ng2-bootstrap/ng2-bootstrap';

//import {EhisControlModule}  from '../ehis-control/ehis-control.module';

import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!

import { CurrencyMaskModule } from "ng2-currency-mask";

//import {AmoTable} from "./amo-table/amo-table.component"

import { SubscriptionService} from './subscription.service';

import {SubscriptionList} from './list/subscription-list.component'
import {SubscriptionDetail} from './detail/subscription-detail.component'
import {SubscriptionTable} from './table/subscription-table.component'
import {SubscriptionAdd} from './add/subscription-add.component'

export const routes = [
    { path: '', component: SubscriptionList , pathMatch: 'full' },
    { path: 'add/:id', component: SubscriptionAdd, /* canDeactivate: [SaveGuard], */},
    { path: ':id', component: SubscriptionDetail, canDeactivate: [SaveGuard],},
];

import {SmartadminModule} from '../shared/smartadmin.module'

import {ShippingModule} from "../shipping/shipping.module";
import {ControlModule} from "../controls/controls.module";

import {SendToEmailComponent} from "./detail/send-to_email.component";
import {SubscriptionRetailComponent} from "./detail/subscription-retail.component";
import {SubscriptionTrialComponent} from "./detail/subscription-trial.component";
import {SubscriptionMonthlyComponent} from "./detail/subscription-monthly.component";
import {SubscriptionYearlyComponent} from "./detail/subscription-yearly.component";

import {SubscriptionWarningsComponent} from "./detail/subscription-warnings.component";

import {SubscriptionProductComponent} from "./detail/subscription-product.component";
import {WidgetModule} from "../widget/widget.module";
//import {ToCcBccComponent} from "./detail/to_cc_bcc.component";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
//        ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        ButtonsModule,

 //       RouterModule.forChild(routes),

        SmartadminModule,

        ShippingModule,

        ControlModule,

        CurrencyMaskModule,

        WidgetModule,

   ],


    declarations: [
        SubscriptionList,
        SubscriptionDetail,
        SubscriptionTable,
        SubscriptionAdd,

        SendToEmailComponent,

        SubscriptionRetailComponent,
        SubscriptionTrialComponent,
        SubscriptionMonthlyComponent,
        SubscriptionYearlyComponent,


        SubscriptionWarningsComponent,

        SubscriptionProductComponent,
       // ToCcBccComponent,
   ],
    exports: [
        SubscriptionTable,

        SubscriptionRetailComponent,
        SubscriptionTrialComponent,

        SubscriptionProductComponent,  //-> wizard-client

    ],
    providers: [
        SaveGuard,
        SubscriptionService,
    ]

})
export class SubscriptionModule {
}

