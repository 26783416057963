import {Injectable} from '@angular/core';
//import {Http, Response} from '@angular/http';
//import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
//import {Observable}     from 'rxjs/Observable';
//import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class MessagePostService extends BaseService{

    constructor(public authService:AuthService){ super(authService,"/api/message_post");}

    postMessage(conto_id, body ){

       // let url:string = `${this.baseURL}`;

        let item = {
            conto_id: conto_id,
            body: body,
        };

        return this.post(item);
    }

    unread(message_id,conto_id){

        let url:string = `${this.baseURL}` //  `/api/message_unread`;
        let url1 = url.replace('message_post','message_unread')


        let item = {
            id: message_id,
            conto_id: conto_id,
        };

        return this.authService.post(url1,item);
    }



}