import { NgModule }      from "@angular/core";
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { RouterModule }   from '@angular/router';

import { PaginationModule  } from 'ngx-bootstrap';
import { ButtonsModule } from 'ngx-bootstrap';
import { SaveGuard} from '../auth/save-guard.service';

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";


import {ProfessionalService} from "./professional.service";
import {ProfessionalList}  from "./list/professional-list.component";
import {ProfessionalDetail}  from "./detail/professional-detail.component";
import {ProfessionalTable}  from "./table/professional-table.component";

/*
export const routes = [
    { path: '', component: ProfessionalList, pathMatch: 'full' },
    { path: ':id', component: ProfessionalDetail, canDeactivate: [SaveGuard],},
];
*/
import {AuthModule} from "../auth/auth.module";
import {WidgetModule} from "../widget/widget.module";
import {TaskService} from "../task/task.service";
import {SharePopup} from "./share-popup/share-popup.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonsModule,
        ControlModule,
        AuthModule,


        PaginationModule.forRoot(),
      //  RouterModule, // .forChild(routes),

        SmartadminModule,
        SmartadminInputModule,

        WidgetModule

    ],
    declarations: [
        ProfessionalList,
        ProfessionalDetail,
        ProfessionalTable,

        SharePopup,
    ],
    exports: [
        ProfessionalList,
        ProfessionalDetail,
        ProfessionalTable,
    ],

    providers: [
      SaveGuard,
      ProfessionalService,
        TaskService

    ]

})
export class ProfessionalModule {}
