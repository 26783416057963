import {Component, Input, Output, EventEmitter, ContentChild, TemplateRef, ViewChild, ElementRef} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {DomSanitizer} from '@angular/platform-browser';


//import {DynamicFormControlComponent, DynamicFormControlModel,DynamicCheckboxModel,DynamicInputModel} from "@ng2-dynamic-forms/core";
import {DynamicFormControlComponent, DynamicFormControlModel,DynamicCheckboxModel,DynamicInputModel,DynamicNumberModel,DynamicFormArrayModel} from "../../sp-dynamic-forms/core";

import {DYNAMIC_FORM_CONTROL_TYPE_FILE,   DynamicFileModel}  from "../../sp-dynamic-forms/core/src/model/file/dynamic-file.model";

//import createNumberMask from 'text-mask-addons/dist/createNumberMask.js';
//import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe.js';

export const DYNAMIC_FORM_UI_EHIS = "EHIS";

//import {DynamicFileModel} from "./dynamic-file.model";

import {AuthService} from '../../auth/auth.service';

//import {EhisImgSrcDirective} from '../../ehis-directive/ehis-img-src.directive';
//import {EhisControlModule} from '../ehis-control/ehis-control.module';

declare var $:any;

@Component({

//    moduleId: module.id,
    selector: "dynamic-form-ehis-control",
    templateUrl: "./dynamic-form-ehis.template.html",
    styleUrls: [ './dynamic-form-ehis.style.scss' ],
//    providers:[ EhisImgSrcDirective],

})

export class DynamicFormEhisComponent extends DynamicFormControlComponent {



    @Input() bindId: boolean = true;
    @Input() controlGroup: FormGroup;
    @Input() hasErrorMessaging: boolean = false;
    @Input() model: DynamicFormControlModel;
    @Input() nestedTemplate: TemplateRef<any>;

    @Input() builder:boolean = false;
//    @Output() remove: EventEmitter<DynamicFormControlModel> = new EventEmitter<DynamicFormControlModel>();

    @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
    @Output() change: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

    @ContentChild(TemplateRef) customTemplate;

    @Input() dataParsleyExcluded:boolean = true;

    @Output() onAdd: EventEmitter<any> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

 //   @ViewChild('fc') fileInput;

    @Input() readonly: boolean = false;


    readonly type: string = DYNAMIC_FORM_UI_EHIS;

 //   img_src: any = '';

    @Input('name') name = '';

    constructor(private el:ElementRef,private sanitizer:DomSanitizer,private authService:AuthService) {

        super();

        // =hasAttribute
        //let name = $(el.nativeElement).attr('name')
        //if (typeof name !== typeof undefined && name !== false) {
        //    // Element has this attribute
        //    this.name = name;
        //}

    }

    removeMe(index) {
        // if (!this.builder) {this.onRemove.emit(index) }
        this.onRemove.emit(index);
    }

    addMe() {
        // if (!this.builder) { this.onAdd.emit() }
        console.log('addMe')
        this.onAdd.emit();
    }

    /*
    uploadOptions() {
        return this.authService.uploadOptions();
    }
    */

    _readonly() {

        if (this.builder) {
            return true;
        }
        return this.readonly;
    }

    _readonly_radio() {

        return this.readonly;

    }

    table_readonly() {
        if (this.builder) {
            return false;
        }
        if ((<DynamicFormArrayModel>this.model).fixedColumns > 0) return true;

        return this.readonly;
    }

    /*
        uploadClick(id) {
            this.fileInput.nativeElement.click();
        }


        //--------------------------------------------------------------------------------------------------------


        handleUpload(data): void {
            if (data && data.response) {
                data = JSON.parse(data.response);

                let link = data.src;
                (this.model as DynamicFileModel).link = link;
                this.ehis_img_src(link);
            }
        }

        clearUpload() {
            (this.model as DynamicFileModel).link = '';
            this.ehis_img_src('');
        }

        ehis_img_src(value:string) {

            // map value ...
            if (value) {

                var i = value.lastIndexOf('/');
                if (i < 0) { i = value.lastIndexOf('\\'); }

                let url = this.authService.getApiUrl() + '/api/image/' + value.slice(i+1);

                this.authService.getBlob(url)
                    .subscribe(
                    (result)=>{
                        var blob = result // .blob();

                        var reader = new FileReader(); //window.FileReader();

                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            let base64data = reader.result;
                            (this.model as DynamicFileModel).base64data = base64data.toString(); // + toString  +7
                            this.img_src = base64data;
                        }

                    },
                    (error)=>{ })

            }
            else {
                this.img_src = '';
            }
        }



        ngOnInit() {
            super.ngOnInit();
            if (this.model.type === DYNAMIC_FORM_CONTROL_TYPE_FILE) {
                let model = this.model as DynamicFileModel;
                this.ehis_img_src(model.link);
            }
        }



        */



    fileUpload($event) { // attachment + file

        let tag = $event.tag;
        tag.link = $event.link;
        tag.value = $event.fileName;

        console.log(tag)
    }

}