import {Component, OnInit} from '@angular/core';
import { Subscription }   from 'rxjs';


import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent {
  /*
  projects: Array<any>;


  ngOnInit() {
    this.projects = this.projectsService.getProjects()
  }

  clearProjects(){
    this.projectsService.clearProjects();
    this.projects = this.projectsService.getProjects()

  }
*/

  userName() {

    let user =  this.authService.user()
    let s = user.pp_name;

    return s;
    /*  !!! backend !!! ---->
    let s1 = this.authService.email();
    let s2 = this.authService.username();
    if (s1==s2) {
       return s1;
    }
    return s1 + ' ['+s2+']';
    */
  }



  taskCount:any = undefined;
  taskSubscription: Subscription;

  unreadCount:any = undefined;
  unreadSubscription: Subscription;

  constructor(public authService:AuthService) {
    this.taskSubscription = authService.taskAnnounced$.subscribe((count)  => { this.taskCount = +count });
    this.unreadSubscription = authService.unreadAnnounced$.subscribe((count)  => { this.unreadCount = +count });
    this.toApproveSubscription = authService.toApproveAnnounced$.subscribe((count)  => { this.toApprove = +count });
  }

  toApprove = 0;
  toApproveSubscription: Subscription;


  clearProjects(){
    console.log('click')
  }

  get isAdmin() {
    return this.authService.isadmin()
  }


  clickMenu() {
    this.authService.announce()
  }

  get title() {
    let intf = this.authService.interfaceName();
    return this.userName()+ (intf ? ' ['+ intf+ ']' : '')
  }

  get http() {
    return this.authService.guestLink;
  }

  navigate(link) {
    this.authService.navigate(link)
  }
}
