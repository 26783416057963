import { Component, OnInit } from '@angular/core';
import {LayoutService} from "../layout.service";
import {AuthService} from "../../../auth/auth.service";
import { ApplicationStoreService } from 'app/application-store.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'sa-ribbon',
  templateUrl: './ribbon.component.html'
})
export class RibbonComponent  {
  alive = true;
  sideBarBgColor : string = '';
  constructor(private authService:AuthService,private layoutService: LayoutService, private appStore: ApplicationStoreService) {
    this.appStore.appState$
      .pipe(takeWhile(() => this.alive))
      .subscribe(appUserState => {
          this.sideBarBgColor = appUserState.sideBarColor;
      });
  }

  resetWidgets() {
    this.layoutService.factoryReset()
  }

  home() {
    this.authService.home();
  }
}
