/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef, SimpleChanges} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import {ChartService} from '../chart.service';

import {BaseItem} from "../../auth/base-item"
//import {ModalComponent } from '../../auth/modal-window/modal.component';

import { FormGroup, FormControl,FormArray,FormBuilder } from '@angular/forms';
import {ReactiveFormsModule} from "@angular/forms";
//import {DynamicFormControlModel, DynamicFormService,
//    DynamicInputModel,DynamicTextAreaModel,DynamicRadioGroupModel,DynamicCheckboxModel,DynamicSelectModel} from "../../sp-dynamic-forms/core";

import {DynamicFormControlModel, DynamicFormService,DynamicOptionControlModel,DynamicFormValueControlModel,
    DynamicInputModel,DynamicTextAreaModel,DynamicRadioGroupModel,DynamicCheckboxModel,DynamicSelectModel,
    DynamicFormArrayModel} from "../../sp-dynamic-forms/core";

import { DynamicFormOption } from "../../sp-dynamic-forms/core";

//import {DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE} from "../../sp-dynamic-forms/core";

//import {DynamicNumberModel} from "@ng2-dynamic-forms/core";
//import {DynamicNumberModel} from "@ng2-dynamic-forms/core/src/model/number/dynamic-number.model";
//import {DynamicNumberModel} from "../../sp-dynamic-forms/core/src/model/number/dynamic-number.model";

import {DynamicNumberModel} from "../../sp-dynamic-forms/core";
import {DynamicFileModel}   from "../../sp-dynamic-forms/core";

//import {DynamicFileModel} from "../ui-ehis/dynamic-file.model";

import {PdfService}   from "../../service/pdf.service";

declare var $: any;


import * as moment from 'moment';
import {dynamicInit} from "../../controls/dynamic/dynamic.component";

@Component({
    selector: 'chart-detail',
    templateUrl: './chart-detail.template.html',

})
export class ChartDetail extends BaseItem { //BaseDetail {

    //mainForm: FormGroup;
    @ViewChild('dynamic_form') dynamicForm;
    @ViewChild('amend_dynamic_form') amend_dynamicForm;
    @ViewChild('notes') notes;
    @ViewChild('trusted_users') trusted_users;



    categoryName: string = '';
    itemName: string = '';
    newStr: string = '';
    //priceText: string ='';
/*
    tab: number = 0;

    selectTab(tab) {
        if (tab != this.tab) {
            this.tab = tab;
        }
    }
*/

    @ViewChild('dyn1') dyn1;
    @ViewChild('dyn2') dyn2;


    constructor(el:ElementRef,protected route: ActivatedRoute,protected chartService: ChartService,private pdfService:PdfService) { //,private dynamicFormService: DynamicFormService) {

        super(el,route,chartService);
        //this.mainForm = fb.group({});

        this.tab = 0; // !!!
    }

    fullName():string {
        if (!this.isLoaded) {return ''};
        return +this.item.id ? this.item.name : 'New';
    }

    onSubmit(form) {
        console.log('realize! isValid')

        let instance = $('.parsleyjs').parsley();
        if (instance.isValid()) {
            //    console.log(this.item); // form.value);
            this.saveAndBack();
        }
    }

//    init_chart_template_id = 0;
//    init_data = null;

    preLoaded = false;

    onReadItem(result){

        this.preLoaded = true;

        // local time for new record
        if (!result.id) {

            let d= new Date();
            let fmt = 'YYYY-MM-DD HH:mm';
            result.visit_datetime = moment(d).format(fmt);


           // console.log(result.visit_datetime);

        }


        //

        let d= new Date();
        let fmt = 'YYYY-MM-DD HH:mm';
        result.amend_visit_datetime = moment(d).format(fmt);




        this.itemName = result.name;

        // console.log(result);

        if (!(+result.id)) {
            this.newStr = ' (new)';
        }


        result._visit_date = this.baseService.authService.sqlToDate(result.visit_datetime);
        result._visit_time = this.baseService.authService.sqlToTime(result.visit_datetime);

        //----------------------------------------------------------------
        /*
        if (result.data) {
           this.dynamicForm.data = result.data;
        }
        */
        if (!result.model) {
            result.model = []
        }
        //convert
        //result.data = result.data1
        //result.amend_data = result.amend_data1

        if (!result.data) {
            result.data = {}
        }
        if (!result.amend_data) {
            result.amend_data = {}
        }
        dynamicInit(result.model,result.data)  // initialize data arrays
        dynamicInit(result.model,result.amend_data)


        //-----------------------------------------------------------------

        //this.categoryName = result._patient.full_name; //last_name +', ' + result._patient.first_name;

        if (result.patient) {
            this.categoryName = result.patient.full_name;
        }

        if (result._chart_template) {
            this.itemName = result._chart_template.name;
        }

        if (this.notes) {
            this.notes.data = result.notes;
        }
        /* trusted--
        if (this.trusted_users) {
            this.trusted_users.data = result.trusted_users;
        }
        */
        this.amend_state = result.amended;
        if (this.amend_state) {
            result._amend_visit_date = this.baseService.authService.sqlToDate(result.amend_visit_datetime);
            result._amend_visit_time = this.baseService.authService.sqlToTime(result.amend_visit_datetime);

           /*
            if (result.amend_data) {
                this.amend_data = result.amend_data;
                this.amend_dynamicForm.data = result.amend_data;
            }
            */

            //this.tab = 4;
        }

        //-------------------------------------------------------------

        this.updateAccess(result);
        //-------------------------------------------------------------
    }

    onWriteItem(result) {

        result.visit_datetime = this.baseService.authService.dateToSql(this.item._visit_date,this.item._visit_time);

        result.amend_visit_datetime =  result.visit_datetime

        /*
        if (this.dynamicForm) {
            result.data = this.dynamicForm.data;
        }
        */
        if (this.notes) {
            result.notes = this.notes.data;
        }
        /* trusted--
        if (this.trusted_users) {
            result.trusted_users = this.trusted_users.data;
        }
        */
        if (this.amend_state) {
            result.amend_visit_datetime = this.baseService.authService.dateToSql(this.item._amend_visit_date, this.item._amend_visit_time);

           /* if (this.amend_dynamicForm) {
                result.amend_data = this.amend_dynamicForm.data;
            }
            */
        }

    }

    isAuthor() {
        return this.isAdmin || this.baseService.authService.isUser(this.item.user_id);
    }

    canEditMain() {
        if (this.item.signed) return false;

        return this.isAuthor();
    }

    get canEdit1() {
        if (!this.isLoaded) return false;
        return this.item._can_edit1
    }

    readonly() {
        if (!this.isLoaded) return false;
        return !this.canEditMain();
    }

    readonly2() {
        if (!this.isLoaded) return false;
        return !this.isAuthor() || this.item.amend_signed;
    }


    readonlyStr() {
        return '';
//        if (!this.isLoaded) return '';
//        return this.canEditMain() ? '' :  ' (readonly)'  ;
    }

//    wizard_patient_id: number = 0;

 //   navigatedFromAddressBook: boolean = false;

    detailId() {   // refactoctor:  => remove to base-detail
        let chart_template_id = +this.route.snapshot.params['chart_template_id'];
        if (chart_template_id) { this.paramItems.chart_template_id = chart_template_id };

        let patient_id = +this.route.snapshot.params['patient_id'];
        if (patient_id) {
            this.paramItems.patient_id = patient_id;

//            this.wizard_patient_id = +patient_id;
        }

//        console.log(this.route);
//        this.navigatedFromAddressBook = patient_id ? true : false;

        return this.route.snapshot.params['id'];
    }

    printPdf: boolean = false;



    isValid() {  // il tappo
        return true
    }

    pdf() {

        if (this.hasChanges()) {
            if (!this.isValid()) return;

            this.printPdf = true;
            this.saveItem();
        }
        else {
            this.doPdf();
        }
    }

    //--------------------------------------------------------------------------------------------------------

    @ViewChild('formSign') formSign;
    @ViewChild('formSignAmended') formSignAmended;

    amend_state: boolean = false;
    amend_data: string= '';

    sign() {
        this.formSign.open();
    }

    signOk () {
        this.formSign.close();
        if (this.isValid()) {
            this.item.signed = true;
            this.saveItem();
        }
    }

    amend() {
        if (this.isValid()) {
            this.item.amended = true;
            this.saveItem();
        }
    }

    sign_amended() {
        this.formSignAmended.open();
    }

    sign_amendedOk() {
        this.formSignAmended.close();
        if (this.isValid()) {
            this.item.amend_signed = true;
            this.saveItem();
        }
    }

    //-----------------------------------------------------------------------------------

    state(name) {
        let states = name.split('|');
//        let current_states = this.item.doc_state_auth.split('|');
        let current_state = this.item.doc_state_auth;
        for (let state of states) {
 //           for (let current_state of current_states) {
                if (current_state == state) return true;

                let state1 = state.replace("_", "-");
            if (current_state == state1) return true;

//            }
        }
        return false;
    }

    author(name='*') {
        if (!this.item.is_author && !this.isAdmin) return false;
        if (name=='*') return true;

        return this.state(name);
    }

    //-----------------------------------------------------------------------------------
/*
    isAmended() {
        // state('*') ==  isAuthor
        // state('') === not isAuthor

        // state('') &&
        if (!this.isLoaded) return true;

        if (this.isAuthor()) return false;

        return this.item.amended;       //
    }

    state2() {
        if (!this.isLoaded)    return false;

        if (this.item.amended) return true;
        if (this.item.amend_signed) return true;
        return false;

    }
*/
    /*
        signButton() {

            //if (!this.isLoaded)    return false;
            //if (!this.isAuthor())  return false;

            //if (this.item.signed) return false;

            return this.state('draft');
        }

        amendButton() {
            //if (!this.isLoaded)    return false;
            //if (!this.isAuthor())  return false;


            //if (this.item.amended) return false;

            //if (!this.item.signed) return false;

            //return true;
            return this.state('signed');
        }

        signAmendButton() {
            return this.state('amend_signed');
        }
    */

    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    onBack() {
        this.back();
        //this.chartService.authService.back();
    }
/*
    back() {
        this.baseService.authService.navigate(this.backLink(),null);
    }


    backLink() {

        if (this.navigatedFromAddressBook) {
            return ['/addressbook', this.item.patient_id, {tab: 2}];
        }
        else {
            return ['/chart'];
        }

    }
    */


 //   call_lookup() {  //used in SaveGuard
 //      return false;
  //  }




    getDetail() {  // with resolver -------------------------------

        super.getDetail();
   //     console.log('tab',this.tab)


/*  resolver- removed


        let data = this.route.snapshot.data['result'];

        this.handleRead(data.item);
        if (data.routerData) {
            this.routerData = data.routerData;
        }
        if (data.savedItem) {
            this.savedItem = data.savedItem;
        }
        else {
            this.savedItem = this.itemToSaveStr();
        }
        //let result = this.route.snapshot.data['result'];

        //this.handleRead(result);

        this.isLoaded = true;
*/

/*
        let id = this.detailId();
        if (!isNaN(id) && (id >= 0)) {
            this.baseService.getItem(id,this.paramItems)
                .subscribe(
                    result => {

                        this.handleRead(result);
                        this.savedItem = this.itemToSaveStr();
                        this.isLoaded = true;
                        //console.log(result);
                    },

                    error =>  this.error = error)
        }
        else
        {this.isLoaded = true};
*/
    }


 //   public get timeMask(): any { return [/[0-2]/, /\d/, ':', /[0-6]/, /\d/]; }


    get head_disabled() {
        return ! (this.isAuthor() || this.isAdmin)
    }

    get tab_state() {
        return false;
    }

    get form_state() {
        return false;
    }

    disabled(name) {
        if (this.item._acc) {
            return !this.item._acc.includes(name)
        }
        return true;
    }

    updateAccess(result) {

         if (1) return;

        console.log()
        if ('_disabled' in result) {
          let arr = result._disabled;


          $(this.el.nativeElement).find('item-input').each( function(){

              let name = $(this).attr('name')
              if (arr.includes(name)) {

                  console.log(name)

                  $(this).find('input').each(function() {

                      console.log( ' '+ $(this).val())
                      $(this).attr('disabled','disabled') // .attr('readonly',true)
                  })
                  $(this).find('button').attr('disabled','disabled').css({

                      'background-color': '#eee','opacity': '1',
                  })

                  //cursor: not-allowed; 'pointer-events': 'none',  'cursor': 'not-allowed'
                //$(this).attr('disabled','disabled');
              }
          })

         }
    }

    beforeLookup(key,oldvalue,newvalue) {
        /*
       for (let tmp of this.item.user.template) {  //= on WriteItem
            if (oldvalue == tmp.id) {
                tmp.data = this.dynamicForm.data ;
            }
        }
        */

    }


 //   dynamicFormData  = null

    afterLookup(key,oldvalue,newvalue)
    {

        //this.dynamicFormData = null
        if (key == 'chart_template_id') {
            for (let tmp of this.item.user.template) {  // == onReadItem
                if (newvalue == tmp.id) {
                    //this.dynamicFormData = tmp.data;
                    this.item.model = tmp.model

                    this.item.data = tmp.data  // preview added
                }
            }

        }
        /*
 //     if (key=='chart_template_id') {
        console.log(key,oldvalue,newvalue)

        console.log('dynamicForm',this.dynamicForm)

        if (this.dynamicForm) {


            for (let tmp of this.item.user.template) {  // == onReadItem
                if (newvalue == tmp.id) {
                    this.dynamicForm.data = tmp.data;
                }
            }
        }
        */
/*
          console.log(oldvalue,newvalue,this.init_chart_template_id)
          if (newvalue==this.init_chart_template_id) {
              this.dynamicForm.data = this.init_data;
          }
          else {
              if (oldvalue==this.init_chart_template_id) {
                  console.log('save')
                  this.init_data = this.dynamicForm.data;
              }
          //    if (newvalue==this.init_chart_template_id) {
          //        this.dynamicForm.data = this.init_data;
          //    }
          //    else {
                  for (let tmp of this.item.user.template) {
                      if (newvalue == tmp.id) {
                          this.dynamicForm.data = tmp.data;
                      }
                  }
          //    }
          }
          */
      //}

    }

    canSave() {
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    _spin = false;

    get spin() {
        return false // this._spin && !this.preLoaded;
    }

    get version1() {
      //  return this.preLoaded
      //  if (!this.isLoaded) return false;
        return true;
    }

    ngOnInit() {
      //  if (this.dynamicFormData) {
      //      this.selectTab(3)
      //  }
        let bk = this.baseService.authService.backUpContext
        if (bk) {
            if ('tab' in bk) {
                this.selectTab(bk.tab)
            }
            this.baseService.authService.backUpContext = null
        }
        super.ngOnInit();

   }

   /*
    ngAfterViewChecked() {
        if (this.dynamicFormData) {
            this.dynamicForm.data = this.dynamicFormData
            this.dynamicFormData = null
         }
    }
    */

    onItemLoaded() {

        if (this.printPdf) {
            this.printPdf = false;
            this.doPdf();
        }
    }

    doPdf() {

        if (this.item.amended) {
            //         this.pdfService.openChart(this.amend_dynamicForm, this.item);
            this.pdfService.openChart(this.dyn2, this.item);
        }
        else {
//            this.pdfService.openChart(this.dynamicForm, this.item);
            this.pdfService.openChart(this.dyn1, this.item);
        }

    }


    getDynamicData() {

    }


}