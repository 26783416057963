import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



declare var jQuery: any;


//import {ChartTemplateService} from '../../service/chart-template.service.ts'
import {RStoreService} from "../../rstore/rstore.service";

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: '[chart-template-select-list]',

  styleUrls: [ './chart-template-select-list.style.scss' ],
  templateUrl: './chart-template-select-list.template.html',

  providers: [RStoreService],

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class ChartTemplateSelectList extends BaseList {

  $el: any;

  selectItem: any = {};

//  radio2: string = 'All';


  dateFormat: string = 'medium'; // 'mediumDate';

  constructor(el: ElementRef,protected route: ActivatedRoute,private rstoreService: RStoreService) {

    super(el,rstoreService);
    this.$el =  jQuery(el.nativeElement);

    this.paramItems.order_type = 1;
  }

  selectRadio2($event) {

      switch ($event.value) {

        case 0:
        {
          delete this.paramItems.chart_template_user_id;
          break;
        }
        case 1:
        {
          this.paramItems.chart_template_user_id = this.rstoreService.authService.user_id();
          break;
        }
        case 2:
        {
          this.paramItems.chart_template_user_id = -1;
          break;
        }
      }
      this.updateSearchControl();
  }

  patient_id = '';


  addForm(): void {
    this.openItem({id:0});
  }

  addItem() {
    if (this.baseService.authService.isguest()) {
      this.baseService.authService.showMessage('Only health providers can create templates!','Sorry');
    }
    else {
      this.baseService.authService.navigate(['/app','chart_template','0']);
    }
  }

  owner(item) {
    return this.rstoreService.authService.isUser(item.chart_template_user_id);
  }

  onReadItems(result) {
    for (let elem of result.data) {
      elem.free = this.rstoreService.authService.sqlToBoolean(elem.free);

      //console.log('elem.free',elem.free);
      elem._price = elem.free ? 'Free' : elem.price;
    }
  }


  openItem(item: any): void {
//    this.baseService.authService.navigate(['/app/chart/0', {patient_id: this.patient_id , chart_template_id: item.id}]);
    this.baseService.authService.navigate(['/','app','addressbook',this.patient_id,{tab:2},'chart','0',{chart_template_id: item.id}]);
  }


  ngOnInit(): void {
    this.patient_id = this.route.snapshot.params['patient_id'];

    super.ngOnInit();
  }

}
