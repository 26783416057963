import {Injectable} from '@angular/core';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class MailboxService extends BaseService{

    constructor(public authService:AuthService){ super(authService,"/api/mailbox");}

    checkMail(mail_id,send_to) {
        let item = { mail:mail_id, email:send_to};
        this.authService.post(this.baseURL+'/check',item)
            .subscribe( result => { this.authService.showMessage('Ok')},
            error=>this.authService.showError(error));

    }
}