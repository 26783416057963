import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Output, Input } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {BaseList}       from '../../auth/base-list';
import {UserService}    from '../../service/user.service';
import {MessageContactService} from "../message-contact.service";

@Component({
  selector: 'user-contact-list',

  styleUrls: [ './user-contact-list.style.scss' ],
  templateUrl: './user-contact-list.template.html',

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class UserContactList extends BaseList {

    @Output() onOpenForm = new EventEmitter();
    @Input()  currentItem:any;

    @Output() onSelect = new EventEmitter();
    @Output() onUserClick = new EventEmitter();

    user_id = 0;

    $el:any;


    constructor(el:ElementRef, private ds:MessageContactService) {
        //constructor(el:ElementRef, private userService:UserService) {

        super(el,ds);

        this.user_id = ds.authService.user_id();

        this.$el = jQuery(el.nativeElement);

    }

    filterName() {
        return '';//(this.radio1).toLowerCase();
    }

    clickEventItem($event,item: any): void {
        this.onSelect.emit(item);
    }

    userClick($event,item) {
        $event.stopPropagation();
        this.onUserClick.emit(item);
    }



}
