import { InvoiceComponent } from './../../+miscellaneous/+invoice/invoice.component';
import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { BaseItem } from '../../auth/base-item';
import { BillingServiceService, ClinicService, InvoicingService, EmailService } from "../billing-service.service";
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { PdfService } from 'app/service/pdf.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';
import { AddressService } from 'app/address/address.service';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent extends BaseItem implements OnInit {

  patientDetails = {
    billToCustomer: {
      id: undefined,
      full_name: ""
    },
    serviceProvidedTo: {
      id: undefined,
      house_address: undefined,
      phone: "",
      email: "",
      full_name: ""
    },
    serviceProvidedBy: {
      id: undefined,
      full_name: ""
    },
    supervisor: {
      id: undefined,
      full_name: ""
    },
  }

  servicesList = []
  invoiceServices = []
  total = 0
  notes = ""
  invoiceTotal = 0.00;
  title: String = "Invoice"

  serviceList = []

  invoiceObject = {};
  isNewInvoice = true;
  invoiceId: number = 0;
  clinicDetails = undefined;
  invoiceStatus = undefined;

  areClinicDetailsComplete = true;
  taxInfo = {'gst': 0, 'pst': 0, 'hst': 0}
  totalTax = 0;
  updatedPatientData : any = '';
  patientId: number;

  canadianProvinces = [
    {id: 1, name: 'Alberta'},
    {id: 2, name: 'British Columbia'},
    {id: 3, name: 'Manitoba'},
    {id: 4, name: 'New Brunswick'},
    {id: 5, name: 'Newfoundland and Labrador'},
    {id: 6, name: 'Northwest Territories'},
    {id: 7, name: 'Nova Scotia'},
    {id: 8, name: 'Nunavut'},
    {id: 9, name: 'Ontario'},
    {id: 10, name: 'Prince Edward Island'},
    {id: 11, name: 'Quebec'},
    {id: 12, name: 'Saskatchewan'},
    {id: 13, name: 'Yukon'}
  ];
  
  @ViewChild('addServicesModal') serviceModal;
  @ViewChild('emailModal') emailModal;
  @ViewChild('emailForm') emailForm;

  dueDateForPdf: string;
  isDisableStatusBtn : boolean;
  dueDate : string;
  statuses : any;
  loadedStatus : any;

  constructor(el: ElementRef, protected route: ActivatedRoute, private billingService: BillingServiceService, private invoiceService: InvoicingService, private clinicService: ClinicService, public router:Router, private emailService: EmailService, private pdfService: PdfService, private addressService: AddressService) {
    super(el, route, invoiceService);
    
    if (this.clinicService.clinicDetails != undefined) {
      this.clinicDetails = this.clinicService.clinicDetails;
      this.clinicDetails['province_name'] = this.canadianProvinces.find(item => item.id === parseInt(this.clinicDetails.province)).name
      this.loadServices(() => {
        this.invoiceId = parseInt(this.route.snapshot.paramMap.get('id'));
        if (this.invoiceId > 0) {
          this.loadInvloce(this.invoiceId);
        }
        // check if clinic details are complete
        if (this.clinicDetails.phone != undefined 
          && this.clinicDetails.email != undefined 
          && this.clinicDetails.gst != undefined
          && (this.clinicDetails.pst != undefined || this.clinicDetails.hst != undefined) 
          && this.clinicDetails.paymentStandards != undefined
          && this.clinicDetails.confidentialityDeclarations != undefined) {
            this.areClinicDetailsComplete = true
            this.taxInfo.gst = parseInt(this.clinicDetails.gst);
            this.taxInfo.pst = parseInt(this.clinicDetails.pst);
            this.taxInfo.hst = parseInt(this.clinicDetails.hst);
            this.totalTax = this.taxInfo.gst + this.taxInfo.pst + this.taxInfo.hst;
          } else {
            this.areClinicDetailsComplete = false
          }
          this.invoiceService.authService.spinnerStop();
      })
    } else {
      this.clinicService.loadClinicDetails(() => {
        this.clinicDetails = this.clinicService.clinicDetails;
        this.clinicDetails['province_name'] = this.canadianProvinces.find(item => item.id === parseInt(this.clinicDetails.province)).name
        this.loadServices(() => {
          this.invoiceId = parseInt(this.route.snapshot.paramMap.get('id'));
          if (this.invoiceId > 0) {
            this.loadInvloce(this.invoiceId);
          } 
          // check if clinic details are complete
        if (this.clinicDetails.phone != undefined 
          && this.clinicDetails.email != undefined 
          && this.clinicDetails.gst != undefined
          && (this.clinicDetails.pst != undefined || this.clinicDetails.hst != undefined) 
          && this.clinicDetails.paymentStandards != undefined
          && this.clinicDetails.confidentialityDeclarations != undefined) {
            this.areClinicDetailsComplete = true
            this.taxInfo.gst = parseInt(this.clinicDetails.gst);
            this.taxInfo.pst = parseInt(this.clinicDetails.pst);
            this.taxInfo.hst = parseInt(this.clinicDetails.hst);
            this.totalTax = this.taxInfo.gst + this.taxInfo.pst + this.taxInfo.hst;
          } else {
            this.areClinicDetailsComplete = false
          }
          this.invoiceService.authService.spinnerStop();
        })
      }, () => {
        this.onBack()
      })
    }
  }

  goBack() {
    this.onBack()
  }
  
  goToClinicProfile() {
    this.router.navigate(["clinic", this.clinicDetails.id])
  }
  updateStatus(status = '') {
    if (status == '1') {
      this.invoiceStatus = status;
      this.isDisableStatusBtn = true;
    }
    // this.invoiceObject["invoiceStatus"] = this.invoiceStatus; 
    this.invoiceService.getItem(null, {"id": this.invoiceId, "status": this.invoiceStatus}, 'ChangeStatus', true).subscribe(response => {
      //console.log(response)
    })
  }
  showError(err: String) {
    this.billingService.authService.spinnerStop()
    this.billingService.authService.showError(err)
    // this.error = "error occured while retriving clinic details"
  }

  ngOnInit() {
    this.isLoaded = true;
    const statuses = this.invoiceService.getStatusList(true); // It was calling in [index]="invoiceService.getStatusList(true)"
    this.statuses = statuses;
  }

  // ---- invoice CRUD section -------------
  saveIt() {
    this.createNewInvoice()
  }

  calculateAmountAfterTax(amount: number) {
    const gst = amount * (this.taxInfo.gst/100)
    const pst = amount * (this.taxInfo.pst/100)
    const hst = amount * (this.taxInfo.hst/100)
    return amount + gst + pst + hst;
  }

  getInvoiceTotal() {
    let newTotal = 0;
    this.invoiceServices.forEach((item, index) => {
       newTotal += item.amount
    })
    this.invoiceTotal = newTotal;
  }


  // ------------
  loadServices(callback: Function) {
    this.billingService.getItems(null, { 'per_page': 50, 'page': 1, 'clinic_id': this.clinicDetails.id }).subscribe(data => {
      data.data.forEach(item => {
        item['name'] = item['serviceName']
        return item;
      })
      this.servicesList = data.data;
      this.serviceModal.serviceList = [this.servicesList]
      callback()
    }, error => this.showError("An error occured while retriving services"))
  }

  // ------------------

  createNewInvoice() {
    
    if (this.patientDetails.billToCustomer.full_name.trim() === "") {
      this.invoiceService.authService.showMessage("Fields marked with * are required")
      return;
    }

    if (this.patientDetails.serviceProvidedTo.full_name.trim() === "") {
      this.invoiceService.authService.showMessage("Fields marked with * are required")
      return;
    }

    if (this.patientDetails.serviceProvidedBy.id === undefined) {
      this.invoiceService.authService.showMessage("Fields marked with * are required")
      return;
    }

    if(this.invoiceServices.length == 0) {
      this.invoiceService.authService.showMessage("Cannot create an Invoice without services. Please add services.")
      return;
    }

    const currDate = moment().add(15, "days");
    this.dueDate = currDate.format('DD-MM-YYYY');

    let newInvoice = {
      address: this.patientDetails.serviceProvidedTo.house_address,
      billTo: this.patientDetails.billToCustomer.id,
      clientId: this.patientDetails.serviceProvidedTo.id,
      clinicId: this.clinicDetails.id + "",
      billToName: this.patientDetails.billToCustomer.full_name,
      clientName: this.patientDetails.serviceProvidedTo.full_name,
      confidentialityStandard: this.clinicDetails.confidentialityDeclarations,
      email: this.patientDetails.serviceProvidedTo.email,
      notes: this.notes,
      paymentStandard: this.clinicDetails.paymentStandards,
      phone: this.patientDetails.serviceProvidedTo.phone,
      serviceInvoiceMappers: this.getServiceInvoiceMapList(),
      serviceProvidedBy: this.patientDetails.serviceProvidedBy.id,
      serviceProvidedTo: this.patientDetails.serviceProvidedTo.id,
      supervising: this.patientDetails.supervisor.id,
      total: this.invoiceTotal,
      duedate: this.dueDate
    }
    
    this.invoiceService.post(newInvoice).subscribe(data => {
      sessionStorage.setItem('pageMode','createNew');
      this.isNewInvoice = false
      this.invoiceObject = newInvoice;
      this.invoiceId = data;
      this.invoiceServices = [];
      this.invoiceStatus = '0';
      this.isDisableStatusBtn = true;
      this.router.navigate(['bills', this.invoiceId], {relativeTo: this.route.parent, replaceUrl: true})
      this.loadInvloce(this.invoiceId)
    }, error => this.showError("error occured while creating invoice."))
  }

  deleteInvoice() {
    this.invoiceService.delete(this.invoiceId).subscribe(data => this.back(), 
    error => this.showError("error occured while removing."))
  }



  loadInvloce(id) {
    this.invoiceService.getItem(id, null).subscribe(data => {
      this.patientId = data.serviceProvidedTo;
      sessionStorage.setItem('pageMode','edit');
      const createdDate = moment(data.createdDate).add(15, "days");
      this.dueDateForPdf = createdDate.format('DD-MM-YYYY');
      this.loadedStatus = data.invoiceStatus;
      this.isDisableStatusBtn = true;
      this.isNewInvoice = false;
      this.invoiceObject = data;
      this.invoiceStatus = data.invoiceStatus
      this.patientDetails = {
        billToCustomer: {
          id: data.billTo,
          full_name: data.billToName
        },
        serviceProvidedTo: {
          id: data.serviceProvidedTo,
          house_address: data.address,
          phone: data.phone,
          email: data.email,
          full_name: data.clientName
        },
        serviceProvidedBy: {
          id: data.serviceProvidedBy,
          full_name: ""
        },
        supervisor: {
          id: data.supervising,
          full_name: ""
        },
      }
      this.addressService.getPatientData(data.serviceProvidedTo).subscribe(patientDetails => {
        this.updatedPatientData = patientDetails;
      });
      this.invoiceTotal = data.total
      this.notes = data.notes
      if (data.serviceInvoiceMappers) {
        data.serviceInvoiceMappers.forEach((item, index) => {
          const service = this.servicesList.find(element => element.id === item.serviceId);
          this.invoiceServices.push({
            id: this.invoiceServices.length + 1,
            service: service,
            name: service.serviceName,
            description: item.serviceDescription,
            date_of_service: item.serviceDate.split(' ')[0],
            price: item.price,
            quantity: item.serviceQuantity,
            amount: item.amount
          })
        })
      }
      this.billingService.authService.spinnerStop();
    }, error => this.showError("Failed to retrive Invoice Details"))
  }
  // ---- invoide services section ---------
  getServiceInvoiceMapList() {
    let listToRetuen = []
    if (this.invoiceServices) {
      this.invoiceServices.forEach((item, index) => {
        listToRetuen.push({
          "serviceId": item.service.id,
          "serviceDescription": item.description,
          "serviceDate": item.date_of_service.replaceAll(/\//gi, '-'),
          "price": item.service.price,
          "serviceQuantity": item.quantity + "",
          "amount": item.amount
        })
      })
    }
    return listToRetuen;
  }


  // Add service modal
  openServieModal(add: boolean = true) {
    this.serviceModal.add_service = add;
    this.serviceModal.show()
  }

  handleService(event: any) {
    if (this.serviceModal.add_service) {
      event['id'] = this.invoiceServices.length + 1;
      if (event.service.tax) 
        event.amount = this.calculateAmountAfterTax(event.service.price) * event.quantity
      else
        event.amount = event.service.price * event.quantity
      this.invoiceServices.push(event)
      this.serviceModal.add_service = false;
    }
    else {
      const elemIndex = this.invoiceServices.findIndex(element => element.id === event.id)
      if (elemIndex !== -1) {
        if (event.service.tax) 
        event.amount = this.calculateAmountAfterTax(event.service.price) * event.quantity
        else
        event.amount = event.service.price * event.quantity
        this.invoiceServices[elemIndex] = event;
      }
    }
    this.getInvoiceTotal()
  }

  editService(service) {
    this.serviceModal.item = service;
    this.serviceModal.add_service = false;
    this.serviceModal.show()
  }

  removeService(service) {
    this.invoiceServices.splice(this.invoiceServices.findIndex(item => item.id === service.id), 1)
    this.getInvoiceTotal()
  }

  personSelected(event, updateObject) {
    if (event.id === "") {
      return
    } else if (updateObject === 'serviceProvidedTo') {
      const phone = event.phone1 ? event.phone1 : (event.phone2 ? event.phone2 : (event.phone3 ? event.phone3 : null))
      if (this.isNewInvoice) {
        this.patientDetails.serviceProvidedTo = {
          id: event.id,
          full_name: event.full_name,
          phone: phone,
          email: event.email,
          house_address: event.house_address
        }
      } else {
        this.patientDetails.serviceProvidedTo.id = event.id,
          this.patientDetails.serviceProvidedTo.full_name = event.full_name ? event.full_name : event.text
        if (event.house_address) {
          this.patientDetails.serviceProvidedTo.house_address = event.house_address
        }
        if (event.email) {
          this.patientDetails.serviceProvidedTo.email = event.email
        }
        if (phone) {
          this.patientDetails.serviceProvidedTo.phone = phone
        }
      }

    }
    else {
      this.patientDetails[updateObject] = {
        id: event.id,
        full_name: event.full_name ? event.full_name : event.text
      }
    }
  }

  // --- printable invoice data
  getDownloadInvoiceFormat(isEncrypted:boolean = false) {
    // -----------------------------------------------------------------------------
    // -------------------------EXTERNAL DATA---------------------------------------
    // -----------------------------------------------------------------------------

    // external data for clinic logo and address
    // let externalDataLogo = 'https://leanfrontiers.com/wp-content/uploads/2018/12/logo-placeholder-png.png';
    let externalDataName = this.clinicDetails.name;
    const city = this.clinicDetails.city;
    let externalDataAddress = [
      { 'lineName': 'Clinic Address:', 'lineValue': this.clinicDetails.address + "," + city },
      { 'lineName': 'Province:', 'lineValue': this.clinicDetails.province_name },
      { 'lineName': 'Postal-Code:', 'lineValue': this.clinicDetails.postalCode },
      { 'lineName': 'Phone:', 'lineValue': this.clinicDetails.phone },
      { 'lineName': 'Email:', 'lineValue': this.clinicDetails.email },
      { 'lineName': 'Due Date:', 'lineValue': this.dueDateForPdf ? this.dueDateForPdf : this.dueDate }
    ];

    // external data for the invoice
    var externalDataInvoiceInfo = [
      { 'lineName': 'Bill to:', 'lineValue': this.patientDetails.billToCustomer.full_name },
      { 'lineName': 'Phone number:', 'lineValue': this.patientDetails.serviceProvidedTo.phone },
      { 'lineName': 'Email:', 'lineValue': this.patientDetails.serviceProvidedTo.email },
      { 'lineName': 'Billing Address:', 'lineValue': this.patientDetails.serviceProvidedTo.house_address },
      { 'lineName': 'Service provided to:', 'lineValue': this.patientDetails.serviceProvidedTo.full_name },
      { 'lineName': 'Service provided by:', 'lineValue': this.patientDetails.serviceProvidedBy.full_name },
      { 'lineName': 'Supervisor:', 'lineValue': this.patientDetails.supervisor.full_name },
    ];

    // external data on the services
    let externalDataServiceInfo = [
    ];
    this.invoiceServices.forEach((item, index) => {
      externalDataServiceInfo.push({
        'servNum': item.id,
        'servDate': item.date_of_service,
        'servName': item.name,
        'servDesc': item.description,
        'servPrice': item.price,
        'servQuant': item.quantity,
        'servCharge': item.priceType === 1 ? "Per Hour" : "Per Session",
        'servTax': item.service.tax ? this.totalTax + "%": "N/A",
        'servAmt': item.amount
      })
    });
    let externalDataNotes = this.notes;
    let externalDataTotal = this.invoiceTotal;


    // -----------------------------------------------------------------------------
    // -------------------------DATA FORMATTING FUNCTIONS---------------------------
    // -----------------------------------------------------------------------------

    // Get property value by key/nested key path
    // Source: https://stackoverflow.com/questions/6491463/
    let byString = function (o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    }

    // Table body builder
    function buildTableBody(data, columns, showHeaders, headers) {
      var body = [];
      // Inserting headers
      if (showHeaders) {
        body.push(headers);
      }

      // Inserting items from external data array
      data.forEach(function (row) {
        var dataRow = [];
        var i = 0;

        columns.forEach(function (column) {
          dataRow.push({ text: byString(row, column), alignment: headers[i].alignmentChild });
          i++;
        })
        body.push(dataRow);

      });

      return body;
    }

    // Func to return generated table
    function table(data, columns, witdhsDef, showHeaders, headers, layoutDef) {
      return {
        table: {
          headerRows: 1,
          widths: witdhsDef,
          body: buildTableBody(data, columns, showHeaders, headers)
        },
        layout: layoutDef
      };
    }
    // -----------------------------------------------------------------------------
// -------------------------DOC DEFINITION -------------------------------------
// -----------------------------------------------------------------------------
let pwd1 = moment(this.updatedPatientData.date_of_birth).year();
let pwd2 = this.updatedPatientData.first_name ? this.updatedPatientData.first_name.substr(0,3) : '';
// let pwd_str1 = this.patientDetails.billToCustomer.full_name ? this.patientDetails.billToCustomer.full_name.substr(0,3) : '';
// let pwd_str2 = this.patientDetails.serviceProvidedTo.email ? this.patientDetails.serviceProvidedTo.email.substr(0,3) : '';
let pdfPassword = pwd1+'@'+pwd2;

return {
  userPassword: isEncrypted ? pdfPassword.toLowerCase() : '',
  content: [
      // Top bar - logo address
      {
          columns: [
              // {
              //     image: externalDataLogo,
              //     height: 120
              // },
              {
                  text: externalDataName,
                  style: 'clinicName'
              }
          ]
      },
      // Detailed information
      {
          columns: [
              table(
                  // External data
                  externalDataAddress,
                  // Columns display order
                  ['lineName', 'lineValue'],
                  // Custom columns widths
                  ['auto', 'auto'],
                  // Show headers?
                  false,
                  // Custom headers
                  ['', '', ''],
                  // Custom layout
                  'noBorders'),
              table(
                  // External data
                  externalDataInvoiceInfo,
                  // Columns display order
                  ['lineName', 'lineValue'],
                  // Custom columns widths
                  ['auto', 'auto'],
                  // Show headers?
                  false,
                  // Custom headers
                  ['', '', ''],
                  // Custom layout
                  'noBorders')
          ]
      },
      {text: '', style: 'spacing'},
      // Service information
      table(
          // External data
          externalDataServiceInfo,
          // Columns display order
          ['servNum', 'servDate', 'servName', 'servDesc', 'servCharge', 'servPrice', 'servQuant', 'servTax', 'servAmt'],
          // Custom columns widths
          ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          // Show headers?
          true,
          // Custom headers
          [{text:'#', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Date of Service', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Service Provided', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Description', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Charge Type', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Price', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Quantity', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Tax', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'},
          {text:'Amount', fillColor: 'lightgray', color:'black', alignment: 'center', alignmentChild: 'right'}],
          // Custom layout
          ''),
      {text: 'Total: $' + externalDataTotal, style: 'total'},
      {text: 'Notes', style: 'header'},
      {text: (externalDataNotes != null && externalDataNotes.trim().length > 0) ? externalDataNotes : "No Notes"},
      {text: "Payment Standard Declaration", style: "header"},
      {text: this.clinicDetails.paymentStandards},
      {text: "Confidentiality Declaration", style: "header"},
      {text: this.clinicDetails.confidentialityDeclarations}
  ],
  // Styling
  styles: {
    header: {
      fontSize: 16,
      bold: true,
      margin: [0, 10, 0, 10]
    },
    spacing: {
      fontSize: 16,
      bold: true,
      margin: [0, 0, 0, 20]
    },
    total: {
      alignment: 'right',
      bold: true,
      margin: [0, 20, 0, 10]
    },
    clinicName: {
      fontSize: 20,
      alignment: 'right',
      bold: true,
      margin: [0, 20, 20, 70]
    }
  }
}


  }
  downloadPdf() {
    const dd = this.getDownloadInvoiceFormat()
    this.invoiceService.makeInvoicePdf(dd)
  }
  sendEmail(data) {
    const dd = this.getDownloadInvoiceFormat(true);
    this.emailService.creatBlobPdf(dd);
    let paramCc = '';
    const isValidToEmail = this.validateSingleEmails(data.toEmail);
    if(!data.toEmail) {
      return;
    }
    if(!isValidToEmail) {
      this.showError("Please enter valid TO email address. Ex: john@gmail.com");
      return;
    }
    if(data.ccEmail) {
      let multiCc = data.ccEmail.replace(/\s/g, "").split(/,|;/)  // multiple CC
      const isValidCc = this.validateMutlipleEmails(data.ccEmail);
      if(!isValidCc) {
        this.showError("Please enter valid  CC email address. Ex: john@gmail.com, michel@gmail.com");
        return;
      }
      paramCc = multiCc.toString();
    }
    let paramBcc = '';
    if(data.bccEmail) {
      let multiBcc = data.bccEmail.replace(/\s/g, "").split(/,|;/) // multiple BCC
      const isValidBcc = this.validateMutlipleEmails(data.bccEmail);
      if(!isValidBcc) {
        this.showError("Please enter valid  BCC email address. Ex: john@gmail.com, michel@gmail.com");
        return;
      }
      paramBcc = multiBcc.toString();
    }

    this.billingService.authService.spinnerStart();
    setTimeout(()=>{                           
      const params = {
        'to': data.toEmail,
        'cc': paramCc,
        'bcc': paramBcc,
        'subject': data.subject,
        'body': data.emailBody,
        'invoicenumber': this.invoiceId,
        'file': sessionStorage.getItem('invoice') ? sessionStorage.getItem('invoice') : ''
      }
      this.emailService.sendEmailData(params).pipe(
          catchError(error => {
            this.billingService.authService.spinnerStop();
            this.billingService.authService.showError("Something went wrong!",);
              return of(false);
          })
      ).subscribe(result => {
        if (result) {
          this.billingService.authService.spinnerStop();
          if(this.loadedStatus != 1) {
            this.updateStatus('1'); // change status to pyament
          }
          this.billingService.authService.showMessage("Email has been send successfully!", "Thank you");
          this.emailForm.reset();
          this.emailModal.close();
        } else {
          this.billingService.authService.spinnerStop();
          this.billingService.authService.showMessage("Email has not send, You can try again later.", "Sorry!");
        }
      });
      this.billingService.authService.spinnerStop();
    }, 3000);
  }

  validateMutlipleEmails(string) {
    var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    var result = string.replace(/\s/g, "").split(/,|;/);        
    for(var i = 0;i < result.length;i++) {
        if(!regex.test(result[i])) {
            return false;
        }
    }       
    return true;
  }
  validateSingleEmails(toemail){
    var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;       
    if(!regex.test(toemail)) {
      return false;
    }       
    return true;
  }

  enableStatusBtn(e){
    const pageMode = sessionStorage.getItem('pageMode');
    if(pageMode == 'createNew' && e == '0') {
      this.isDisableStatusBtn = true;
    } else if (pageMode == 'edit' && (e == this.loadedStatus || e == '0')) {
      this.isDisableStatusBtn = true;
    } else {
      this.isDisableStatusBtn = false;
    }
  }

  sendPaymentLink(data) {
    // formulate email data
    let paramCc = "";
    const isValidToEmail = this.validateSingleEmails(data.toEmail);
    if (!data.toEmail) {
      return;
    }
    if (!isValidToEmail) {
      this.showError("Please enter valid TO email address. Ex: john@gmail.com");
      return;
    }
    if (data.ccEmail) {
      let multiCc = data.ccEmail.replace(/\s/g, "").split(/,|;/); // multiple CC
      const isValidCc = this.validateMutlipleEmails(data.ccEmail);
      if (!isValidCc) {
        this.showError("Please enter valid  CC email address. Ex: john@gmail.com, michel@gmail.com");
        return;
      }
      paramCc = multiCc.toString();
    }
    let paramBcc = "";
    if (data.bccEmail) {
      let multiBcc = data.bccEmail.replace(/\s/g, "").split(/,|;/); // multiple BCC
      const isValidBcc = this.validateMutlipleEmails(data.bccEmail);
      if (!isValidBcc) {
        this.showError("Please enter valid  BCC email address. Ex: john@gmail.com, michel@gmail.com");
        return;
      }
      paramBcc = multiBcc.toString();
    }

    // send email
    let serviceNameArr = [];
    this.invoiceServices.forEach((item, index) => {
      serviceNameArr.push(item.description);
    });
    const serviceName = this.invoiceId.toString() + ":" + serviceNameArr.toString()
    this.billingService.authService.spinnerStart();
    const param = {
      "stripe_acc_id": this.clinicDetails.stripe_acc_id,
      "total": this.invoiceTotal,
      "invoiceNumber": this.invoiceId.toString(),
      "clinicId": this.clinicDetails.id.toString(),
      "userId": this.patientId,
      "fees": this.clinicDetails.stripe_fees,
      "fromPage": "invoice",
      "serviceName": serviceName,
      "to": data.toEmail,
      "cc": paramCc,
      "bcc": paramBcc,
      "subject": data.subject,
      "body": data.emailBody,
    }
    this.invoiceService.authService.sendPaymentLink(param).subscribe(res => {
      this.billingService.authService.spinnerStop();
      if(!res.error) {
        this.updateStatus("1"); // set invoice status as sent for payment
        this.billingService.authService.showMessage("Successfully sent payment link to patient!");
      } else {
        this.billingService.authService.showMessage("Something went wrong please contact to E-his team!");
      } 
    });
    this.billingService.authService.spinnerStop();
  }

  sendInvoiceEmail(data) {
    // if invoicing is enabled and clinic is not yet connected to stripe
    // else if invoice is already paid then no need to send the payment link 
    if ((!this.clinicDetails.stripe_acc_id || this.clinicDetails.stripe_acc_id.length === 0) || (this.invoiceStatus != 2 && this.invoiceStatus != 5)) {
      this.sendEmail(data);
    } else {
      // else send the stripe payment link for the email
      this.sendPaymentLink(data)
    }
  }
}
