import * as moment from 'moment';


export function  periodStr (dt1,dt2,fmt) {

        let date1 = dt1 ? moment(dt1,fmt): null;
        let date2 = dt2 ? moment(dt2,fmt): null;


        if (date1) {

            let y1 = date1.year();
            let m1 = date1.month();
            let d1 = date1.date();
            let q1 = date1.quarter();

            if (date2) {

                let y2 = date2.year();
                let m2 = date2.month();
                let d2 = date2.date();
                let q2 = date2.quarter();

                if ((y1 == y2) && (m1 == 0) && (d1 == 1) && (m2 == 11) && (d2 == 31)) {  // year
                    return date1.format('YYYY');
                }
                if ((y1 == y2) && (m1 == m2) && (d1 == d2)) {  // day
                    //return date1.format('DD MMM YYYY')
                    return date1.format(fmt)
                }


                if ((y1 = y2) && (m1 == m2) && (d1 == 1)) {    // month
                    let date2_1 = moment(date2).endOf('month');
                    let d2_1 = date2_1.date();

                    if (d2 == d2_1) {
                        return date1.format('MMMM YYYY')

                    }
                }

                if ((y1 = y2) && (q1 == q2)) {                   // quarter
                    let date1_1 = moment(date1).startOf('quarter');
                    let date2_1 = moment(date2).endOf('quarter');

                    if ((d1==date1_1.date()) && (m1==date1_1.month()) && (d2==date2_1.date()) && (m2==date2_1.month())){
                        return date1.format('Qo') + ' quarter ' + date1.format('YYYY');

                    }
                }

                return date1.format(fmt) +' - ' + date2.format(fmt);
            }

            return date1.format(fmt) +' - ... ';
        }

        if (date2) {
            return ' ...  - ' + date2.format(fmt);

        }

        return "";


}
