import {Component, Input, Output, ElementRef,EventEmitter} from '@angular/core';
import {BaseForm} from "../../auth/base-form";

@Component({

    selector: 'subscription-retail',
    template: `
                                             <div class="row">
                                                <section class="col col-6">
                                                    <label class="label">Number of queries</label>
                           
                           
                                                    <label class="input">
                                                        <i class="icon-prepend fa fa-edit" ></i>
                                                        <input type="text" name="cutoff_threshold"
                                                               [(ngModel)] ="item.cutoff_threshold"
                                                               currencyMask [options]="int0"
                                                               [readonly]="false">
                                                    </label>
                                                    <!--
                                                    <label class="select">
                                                        <select name="cutoff_threshold" [(ngModel)]="item.cutoff_threshold">
                                                            <option value="{{ref.id}}" *ngFor="let ref of item._cutoff_threshold" >{{ref.name}}</option>
                                                        </select> <i></i> </label>
                                                     -->
                                                </section>


                                                <section class="col col-6">
                                                    <label class="label">Amount due</label>

                                                    <label class="input">
                                                        <i class="icon-prepend fa fa-dollar" ></i>
                                                        <input type="text" name="price"
                                                               [(ngModel)] ="item.price"
                                                               currencyMask [options]="float2"
                                                               [readonly]="false">
                                                    </label>
                                                </section>

                                            </div>


 `,
})
export class SubscriptionRetailComponent extends BaseForm {

    @Input('item') item;

    constructor(el:ElementRef) { super(el) }
/*
    float2 = {
        thousands: ' ',
        decimal: '.',
        precision:2,
        align: 'left',
        allowNegative: false,
        prefix : ''
    }

    int0 = {
        thousands: ' ',
        decimal: '',
        precision:0,
        align: 'left',
        allowNegative: false,
        prefix : ''
    }
*/

}

