import {  map,finalize,catchError,tap} from 'rxjs/operators';
import * as moment from 'moment';


import {AuthService} from './auth.service';

export class BaseService {

    public baseURL:string = '';
    public booleans: string[] = [];
    public dates: string[] = [];

    public utc: string[] = [];

    path:string  = '/';

    error = null

    constructor( public  authService:AuthService, private prefix:string,private pref_path = null, public useInvoicingUrl:Boolean = false){

        if (useInvoicingUrl) {
            this.baseURL = authService.getInvoicingUrl() + prefix
        } else {
        this.baseURL = authService.getApiUrl() + prefix;
        }

        if (pref_path) {
            this.path = pref_path;
        }
        else {
            let path = prefix.split('/');
            if (path[1].toLowerCase() == 'api') {
                path.splice(1, 1)
            }
            this.path = path.join('/')
        }
    }


    param(item:any, key:string):string {
        return (item)? (item[key] ? `&${key}=${item[key]}` : '' ) : '';
    }

    getItem(id:any,params:any, suffix_url:any = undefined, makeitPut:boolean = false) {

        this.loading = true;  // SP+++++
        const targetUrl = suffix_url == undefined ? this.baseURL : `${this.baseURL}/${suffix_url}`
        let url:string = `${targetUrl}`;
        if (id != null) {
            url = `${url}/${id}`
        }
        let amp = '?';
        if (params) {
            for (let key in params) {
                url = url + `${amp}${key}=${params[key]}`;
                amp = '&';
            }
        }


        return (makeitPut ? this.authService.put(url, null) : this.authService.get(url))
            .pipe(
                finalize(() => {
                    this.loading = false
                }),

                map((item)=>{
                    for (let key of this.booleans) {
                        if (key in item) {
                            item[key] = this.authService.sqlToBoolean(item[key]);
                        }
                    }

                    for (let key of this.dates) {
                        if (key in item) {
                            item[key] = this.authService.sqlToDate(item[key]);
                        }
                    }

                    this.processReadItem(item);
                    return item;
                }));

    }

    utcToLocal(dt) {
        let dt1 = moment.utc(dt);
        if (dt1.isValid()) {
            let dt2 = moment(dt1).local();
            return dt2.format("YYYY-MM-DD HH:mm:ss");
        }
        return dt;
    }


    processReadItem(item) {
        if (item) {
            for (let key of this.utc) {
                if (key in item) {
                    item[key] = this.utcToLocal(item[key]);
                }
            }


            for (let typeahead of this.typeaheads) {
                if (typeahead.id in item) {
                    if (item[typeahead.id]) {
                        if (item[typeahead.desc]) {
                            item[typeahead.name] = item[typeahead.desc].name
                        }
                        else {
                            let res = this.getTypeaheadIndexItem(item, typeahead.id, typeahead.index)
                            if (res) {
                                item[typeahead.name] = res.name
                            }
                        }
                    }
                    else {
                        if (item[typeahead.name]) {
                            item[typeahead.id] = item[typeahead.name]
                        }
                    }

                }
            }
        }

    }

    getItems(item:any,params:any, suffix_url:any = null, makeitPut:boolean = false){

        let url = suffix_url? this.baseURL + suffix_url : this.baseURL;

        var paramStr = '';

        let amp = '?';

        if (item) {

            for (let key of ['page', 'filter', 'orderby','per_page']) {
                let val = item[key]
                if (val) {
                    let uriVal = encodeURIComponent(val)
                    paramStr = paramStr + `${amp}${key}=${uriVal}`;
                    amp = '&';
                }
            }
        }
        if (params) {
            for (let key in params) {

                if (key == '__path__') {
                    url = url + '/'+ params[key];
                } else {
                    paramStr = paramStr + `${amp}${key}=${params[key]}`;
                    amp = '&';
                }
            }
        }

        /*

        if (this.authService.auth_role) {
            url = url + `${amp}auth_role=${this.authService.auth_role}`;
        }
        */

        this.loading = true;

        return this.authService.get(url + paramStr)
            .pipe(
                finalize(() => {
                    this.loading = false
                }),
                map((result) => {
                    if (result.data) {
                        for (let item of result.data) {
                            for (let key of this.booleans) {
                                if (key in item) {
                                    item[key] = this.authService.sqlToBoolean(item[key]);
                                }
                            }

                            this.processReadItem(item);

                        }
                    }

                    return result;
                })
            );
    }

    prePost(item) {

        let post_item:any = {}

        for (let key in item) {
            post_item[key] = item[key];
        }

        for (let typeahead of this.typeaheads) {
            if (typeahead.id in post_item) {
                this.postTypeahead(post_item,typeahead.id,typeahead.index,typeahead.name)
            }
        }

        for (let key in item) {
            if (key.startsWith('_')) {
                delete post_item[key];
            }
        }

        for (let key of this.dates) {
            if (key in post_item) {
                post_item[key] = this.authService.dateToSql(post_item[key]);
            }
        }


        return post_item;
    }

    typeaheads = []

    post(item:any ){

        


        this.loading = true  // used only in base-calendar.service

        let post_item = this.prePost(item)


        let url:string = `${this.baseURL}`;

        if (post_item.id) {
            url = `${url}/${post_item.id}`;
        }


         return this.authService.post(url,post_item)
            .pipe(
                finalize(() => {
                    this.loading = false
                }),

                map(result=>{

                this.processReadItem(result);
                return result;
            }));
    }



    patch(item:any ){

        this.loading = true  // used only in base-calendar.service

        let url:string = `${this.baseURL}`;

        //if (item.id) {
        //    url = `${url}/${item.id}`;
       // }

        return this.authService.patch(url,item)
            .pipe(
                finalize(() => {
                    this.loading = false
                }),

                map(result=>{

                    return result;
                }));
    }

    put(item:any, resource_id:number = null){

        this.loading = true  // used only in base-calendar.service
        
        let url:string = resource_id !== null ? `${this.baseURL}?id=${resource_id}`:`${this.baseURL}`;

        //if (item.id) {
        //    url = `${url}/${item.id}`;
       // }

        return this.authService.put(url,item)
            .pipe(
                finalize(() => {
                    this.loading = false
                }),

                map(result=>{

                    return result;
                }));
    }



    delete(id:any ){
        let url:string = `${this.baseURL}/${id}`;
        return this.authService.delete(url);
    }

    // helper
    lookupName(arr,id) {
        var name = '';
        for (let elem of arr) {
            if (elem.id == id) {
                name = elem.name;
                break;
            }
        }
        return name;
    }

    _icon = 'fa-windows'

    get icon() {
        return 'fa fa-fw '+ this._icon
    }

    set icon(val) {
        this._icon = val;
    }

    check(item) {
        this.authService.post(this.baseURL+'/check',item)
            .subscribe( result => {
                if (result.message) {this.authService.showMessage(result.message)} else { this.authService.showMessage('Ok')}
                },
                error=>this.authService.showError(error));

    }

    csv(fileName) {
        this.authService.csv(this.baseURL+'/download/csv',fileName)
    }

    csvWithParams(params: any, fileName) {
        let url = this.baseURL+'/download/csv';
        var paramStr = '';
        let amp = '?';
        if (params) {
            for (let key in params) {
                if (key == '__path__') {
                    url = url + '/'+ params[key];
                } else {
                    paramStr = paramStr + `${amp}${key}=${params[key]}`;
                    amp = '&';
                }
            }
        }
        console.log(url)
        this.authService.csv(url + paramStr,fileName, true)
    }
    widgetName(name ='') {
        let x = this.path.split('/').concat([name]);
        return x.join('_');
    }

    getTypeaheadIndexItem(item,id_name,index) {


        let tags = item[index]
        if (!tags) return null

        let id =  item[id_name]

        //  console.log(id,item)

        let res = []
        for (let item of tags) {
            if (item.children) {
                for(let subItem of item.children) {
                    if (id == subItem.id) {
                        res.push(subItem)
                    }
                }
            }
            else {
                if (id == item.id) {
                    res.push(item)
                }
            }
        }

        return res.length == 0 ? null : res[0]
    }

    postTypeahead(item,id_name,index,name) {


        let id =  item[id_name]

        //  console.log(id,item)
        let res = this.getTypeaheadIndexItem(item,id_name,index)
/*
        let res = []
        for (let item of tags) {
            if (item.children) {
                for(let subItem of item.children) {
                    if (id == subItem.id) {
                        res.push(subItem)
                    }
                }
            }
            else {
                if (id == item.id) {
                    res.push(item)
                }
            }
        }
*/
        if (res) {
            item[name] = null
        }
        else {
            item[name] = id
            item[id_name] = null
        }
    }


    acl(access) {
        let s = this.prefix.toLowerCase();
        let prefix = s.startsWith('/api') ? s.slice('/api'.length) : s


        return this.authService.acl(prefix,access)
    }


    _loading = 0 // boolean = true

    set loading(value) {
        if (value) {
            this._loading = this._loading + 1
        }
        else {
            if (this._loading > 0) {
                this._loading = this._loading - 1
            }
        }
    }

    get loading() {
        return this._loading > 0
    }

}