import {Component, Input, Output, EventEmitter} from '@angular/core';
import {AuthService} from "../auth/auth.service";

@Component({

    selector: 'user-account',
    template: `
       
          <label class="label  state-disabled">User</label>
          <label class=" input " >
          <span class=" icon-append" style="width:10rem;" ><a (click) = "userAccount()"> View account </a></span>

              <input  type="text" disabled="" name="_username" [ngModel] = "item._username">

          </label>
       
  `,
})

export class UserAccountComponent {


    @Input('item') item: any = {}




    constructor(private authService:AuthService) {

    }


    userAccount() {
        //this.authService.showMessage('User account')
        this.authService.navigate(['/user',this.item.user_id]);
    }

}
