import {Component, Input, Output, ElementRef, EventEmitter} from '@angular/core';
import {BaseForm} from "./base-form";

import {isYearlyPrePaid} from "../../app.const"

@Component({

    selector: 'decorator',
    template: ` `,
})
export class DecoratorComponent extends BaseForm{

    @Input('item') item;
    @Input('parent') parent:any;
    @Input('disabled') disabled = false;


    constructor(el:ElementRef) {
        super(el);
        this.parent = this;
    }
}

