import { Component, forwardRef } from '@angular/core';
import { Output, Input,OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { FormControl } from '@angular/forms';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe.js';

const noop = () => {};

/*
export const EHIS_EMAIL_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EhisEmailComponent),
    multi: true
};
*/

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
/*
export function createCounterRangeValidator(maxValue, minValue) {
    return (c: FormControl) => {
        let err = {
            rangeError: {
                given: c.value,
                max: maxValue || 10,
                min: minValue || 0
            }
        };

        return (c.value > '') ? null: err;
    }
}

function validateDate(c: FormControl) {

   let err =  {
       validateDate: {
           valid: false,
           message: 'yyyyy'
       }};

   if (!c.value) {
       return err;
   }

   let [dd,mm, yyyy] = c.value.split('/');


   if (!((+dd) && (+mm) && (+yyyy))) {
      return err;
   }

   let dateStr = `${yyyy}-${mm}-${dd}`
   let dateObj = new Date(dateStr);
   if ((dateObj.getDate() != +dd) || (dateObj.getMonth()+1 != (+mm)) || (dateObj.getFullYear() != (+yyyy))) {
       console.log(dateObj.getDate(),+dd);
       return err;
   }
   return null;
}
*/
/*
export function createConfirmPasswordValidator(ehisConfirmPasswordComponent:EhisConfirmPasswordComponent) {

    return (c: FormControl) => {
        console.log('value',c.value);
        console.log('confirm',ehisConfirmPasswordComponent.confirmValue);


        let err = {
            confirmError: {
                value: c.value,
                confirm: ehisConfirmPasswordComponent.confirmValue,
            }
        };

        return (c.value == ehisConfirmPasswordComponent.confirmValue) ? null: err;
    }
}
*/
/*
export function createConfirmPasswordValidator() {
    return (c: FormControl) => {
        console.log('value',c.value);
        console.log('confirm',c);


        let err = {
            confirmError: {
                given: c.value,
            }
        };

        return (c.value == (<EhisConfirmPasswordComponent> c).confirmValue) ? null: err;
    }
}
*/
/*
export function createMyValidator(comp: EhisDateComponent) {

    return function validateFunction(c: FormControl):any {

        //console.log('c.value',c.value);
        //console.log('confirm',comp);
        if (!(typeof c.value === "string") || (c.value.length < 6)) {

        //if (comp.innerValue.length < 6) {
            return {
                tooShortError: {
                    value: comp.innerValue,
                }}}


        if (c.value != comp.confirmValue) {
            return {
                confirmError: {
                    value: c.value,
                    confirm: comp.confirmValue,
                }}}

        return null;
    }
}
*/

//---------------------------------------------------------------col-md-5
@Component({
    selector: 'ehis-date',
    template:
  `
  <div class="form-group row">
        <label for="dob" [ngClass]="labelClass"><ng-content></ng-content></label>
        <div [ngClass] = "inputClass">
        <input type="text"  #dob id="dob" class="form-control form-control-sm  pull-xs-left" placeholder="dd/mm/yyyy" [(ngModel)]="value"
                data-parsley-trigger="change"
                [readonly] = "readonly"
                data-parsley-required-message=""
                [textMask]="{mask: dateMask,  keepCharPositions: true, pipe: autoCorrectedDatePipe}"
                data-parsley-minlength="8"
                data-parsley-date="3"
                data-parsley-error-message="Should be valid date dd/mm/yyyy"
                required="required">
        </div>
    </div>
   `,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisDateComponent), multi: true },
//        { provide: NG_VALIDATORS, useValue: validateDate , multi: true }
    ]
})
export class EhisDateComponent implements ControlValueAccessor { //},OnInit {

    @Input('col-label') colWidth: number = 3;

    @Input('readonly') readonly : boolean = false;

    //The internal data model
    public innerValue: string = '';
    public confirmValue: string = '';

    validateFn: Function; //any = () => {};

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    public get autoCorrectedDatePipe(): any { return createAutoCorrectedDatePipe('dd/mm/yyyy'); }
    public get dateMask(): any { return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; }


    get labelClass() {
        let w = this.colWidth;
        return `form-control-label col-md-${w} col-xs-12 text-md-right`;
    }

    get inputClass() {
        let w = 12 - this.colWidth;
        return `col-md-${w} col-xs-12`;
    }


    ngOnInit() {
        this.validateFn = noop; // validateDate;
    }

    ngOnDestroy () {
        this.validateFn = noop;
    }

/*
    ngOnChanges(changes) {
        console.log('changes');
        if (changes.confirmValue ) {
            this.validateFn = createMyValidator(this);
        }
    }
*/

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    get confirm() {
        return this.confirmValue;
    }

    set confirm(v: any) {
        if (v !== this.confirmValue) {
            this.confirmValue = v;
            this.onChangeCallback(this.innerValue);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled:boolean):void {    // new ???
    }
/*
    validate(c: FormControl) {
        return this.validateFn(c);
    }
*/

}
