import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'to-cc-bcc',
    template: `
         <section>

              <label class="label">{{title}}</label>

              <table class="table table-bordered table-hover table-widget table-striped price-table">

              <thead>
                   <!--
                       <tr>
                           <th style="width:5rem;"></th>
                           <th style="width:10rem;"></th>
                       </tr>
                   -->
              </thead>
              <tbody>
              
              <tr >
                  <td style="width:6rem;" class="td-input-4">
                    <h6 class="font-sm" style="padding-top: 0.65rem;">To:</h6>
                  </td>
                  <td style="width:12rem;" class="td-input-4">
                      <label class="select" >
                          <select name="{{modeName}}" [(ngModel)]="_mode" >
                               <option value="0">Default</option>
                               <option value="1">Custom</option>
                               <option value="2">None</option>
                      </select> <i></i> </label>

                  </td>
                     <td  class="td-input-4" >
                      
                          <label *ngIf="_mode==0" class="input state-disabled">
                               <i class="icon-append fa fa-envelope" ></i>
                            <input type="text" name="{{defaultName}}" [ngModel] ="_default"
                                                                [readonly]="true" >
                          </label>


                         <label *ngIf="_mode==1" class="input">
                          <i class="icon-append fa fa-envelope" ></i>
                              <input type="text" name="{{valueName}}" [(ngModel)] ="_value"
                              >
                          </label>



                       </td>
                  </tr>
                  
                  <!-- cc ------------------------>
                  
              <tr >
                  <td style="width:6rem;" class="td-input-4">
                    <h6 class="font-sm" style="padding-top: 0.65rem;">Cc:</h6>
                  </td>
                  <td style="width:12rem;" class="td-input-4">
                      <label class="select" >
                          <select name="{{modeName_cc}}" [(ngModel)]="_mode_cc" >
                               <option value="0">Default</option>
                               <option value="1">Custom</option>
                               <option value="2">None</option>
                      </select> <i></i> </label>

                  </td>
                     <td  class="td-input-4">
                      
                          <label *ngIf="_mode_cc==0" class="input state-disabled">
                               <i class="icon-append fa fa-envelope" ></i>
                            <input type="text" name="{{defaultName_cc}}" [ngModel] ="_default_cc"
                                                                [readonly]="true" >
                          </label>


                         <label *ngIf="_mode_cc==1" class="input">
                          <i class="icon-append fa fa-envelope" ></i>
                              <input type="text" name="{{valueName_cc}}" [(ngModel)] ="_value_cc"
                              >
                          </label>



                       </td>
                  </tr>

              <!-- bcc ------------------------>
                  
              <tr >
                  <td style="width:6rem;" class="td-input-4">
                    <h6 class="font-sm" style="padding-top: 0.65rem;">Bcc:</h6>
                  </td>
                  <td style="width:12rem;" class="td-input-4">
                      <label class="select" >
                          <select name="{{modeName_bcc}}" [(ngModel)]="_mode_bcc" >
                               <option value="0">Default</option>
                               <option value="1">Custom</option>
                               <option value="2">None</option>
                      </select> <i></i> </label>

                  </td>
                     <td  class="td-input-4">
                      
                          <label *ngIf="_mode_bcc==0" class="input state-disabled">
                               <i class="icon-append fa fa-envelope" ></i>
                            <input type="text" name="{{defaultName_bcc}}" [ngModel] ="_default_bcc"
                                                                [readonly]="true" >
                          </label>


                         <label *ngIf="_mode_bcc==1" class="input">
                          <i class="icon-append fa fa-envelope" ></i>
                              <input type="text" name="{{valueName_bcc}}" [(ngModel)] ="_value_bcc"
                              >
                          </label>



                       </td>
                  </tr>

    
              </tbody>
         </table>


       </section>

 `,
})
export class ToCcBccComponent {

    @Input('item') item = {};
    @Input('title') title = "Email to"

    @Input('name') name = null;

    //===============================================================

    get modeName_cc() {
        return this.name ? this.name + '_cc_mode': null;
    }

    set _mode_cc(value) {
        if (this.modeName_cc) {this.item[this.modeName_cc] = value;}
    }

    get _mode_cc() {
        return this.modeName_cc ? this.item[this.modeName_cc] : 0;
    }

    //---------------------------------------------------------

    get valueName_cc() {
        return this.name ? this.name + '_cc' : null;
    }

    set _value_cc(value) {
        if (this.valueName_cc) {this.item[this.valueName_cc] = value;}
    }

    get _value_cc() {
        return this.valueName_cc ? this.item[this.valueName_cc] : null;
    }

    //---------------------------------------------------------

    get defaultName_cc() {
        return this.name ? '_'+ this.name + '_cc_default': null;
    }

    set _default_cc(value) {
        if (this.defaultName_cc) {this.item[this.defaultName_cc] = value;}
    }

    get _default_cc() {
        return this.defaultName_cc ? this.item[this.defaultName_cc] : null;
    }

    //===============================================================

    get modeName() {
        return this.name ? this.name + '_email_mode': null;
    }

    set _mode(value) {
        if (this.modeName) {this.item[this.modeName] = value;}
    }

    get _mode() {
        return this.modeName ? this.item[this.modeName] : 0;
    }

    //---------------------------------------------------------

    get valueName() {
        return this.name ? this.name + '_email' : null;
    }

    set _value(value) {
        if (this.valueName) {this.item[this.valueName] = value;}
    }

    get _value() {
        return this.valueName ? this.item[this.valueName] : null;
    }

    //---------------------------------------------------------

    get defaultName() {
        return this.name ? '_'+ this.name + '_email_default': null;
    }

    set _default(value) {
        if (this.defaultName) {this.item[this.defaultName] = value;}
    }

    get _default() {
        return this.defaultName ? this.item[this.defaultName] : null;
    }

    //===============================================================

    get modeName_bcc() {
        return this.name ? this.name + '_bcc_mode': null;
    }

    set _mode_bcc(value) {
        if (this.modeName_bcc) {this.item[this.modeName_bcc] = value;}
    }

    get _mode_bcc() {
        return this.modeName_bcc ? this.item[this.modeName_bcc] : 0;
    }

    //---------------------------------------------------------

    get valueName_bcc() {
        return this.name ? this.name + '_bcc' : null;
    }

    set _value_bcc(value) {
        if (this.valueName_bcc) {this.item[this.valueName_bcc] = value;}
    }

    get _value_bcc() {
        return this.valueName_bcc ? this.item[this.valueName_bcc] : null;
    }

    //---------------------------------------------------------

    get defaultName_bcc() {
        return this.name ? '_'+ this.name + '_bcc_default': null;
    }

    set _default_bcc(value) {
        if (this.defaultName_bcc) {this.item[this.defaultName_bcc] = value;}
    }

    get _default_bcc() {
        return this.defaultName_bcc ? this.item[this.defaultName_bcc] : null;
    }


}

