import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-export-invoice',
  templateUrl: './export-invoice.component.html',
  styleUrls: ['./export-invoice.component.scss']
})


export class ExportInvoiceComponent {

    _client_id: any = undefined
    _client_name: string = undefined
    _start_date_for_export: any = undefined;
    _end_date_for_export: any = undefined;
    error_string = "start date must not be greater or equal to end date";
    show_error = false;
  constructor() {

  }

  @Input() item = null;

  @ViewChild('exportInvoiceModal') modalForm;

  formOnOpen() {
    this.resetFormData()
  }

  formOnClose() {
    this.resetFormData()
  }

  show() {
    this.modalForm.open()
    this.resetFormData()
  }


  @Output('onDelete') onDelete = new EventEmitter()
  @Output('onSelect') onSelect = new EventEmitter()

  personSelected(event) {
    if (event.id === "") {
      return
    } else {
      this._client_name = event.full_name ? event.full_name : event.text;
    }
  }

  clickOk() {
    if (this._start_date_for_export != undefined
      && this._start_date_for_export.trim() != "" 
      && this._end_date_for_export != undefined
      && this._end_date_for_export.trim() != "") {
        const start_date = this.stringToDate(this._start_date_for_export,"dd/MM/yyyy","/");
        const end_date = this.stringToDate(this._end_date_for_export,"dd/MM/yyyy","/");
        if (end_date <= start_date) {
          this.show_error = true;
          return
        } else {
          this.show_error = false;
        }
        this.item = {
          "start_date":  this.changeDateFormatForCsvExport(start_date) + "T00:00:00",
          "end_date": this.changeDateFormatForCsvExport(end_date) + "T23:59:59",
          "client_name": this._client_name
        }
        this.onSelect.emit(this.item)
        this.modalForm.close();
      } else {
        return;
      }
    
  }

  changeDateFormatForCsvExport(dateObj) {
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const date = ('0' + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    return year + '/' + month + '/' + date;
  }
  stringToDate(_date,_format,_delimiter)
  {
              var formatLowerCase=_format.toLowerCase();
              var formatItems=formatLowerCase.split(_delimiter);
              var dateItems=_date.split(_delimiter);
              var monthIndex=formatItems.indexOf("mm");
              var dayIndex=formatItems.indexOf("dd");
              var yearIndex=formatItems.indexOf("yyyy");
              var month=parseInt(dateItems[monthIndex]);
              month-=1;
              var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
              return formatedDate;
  }
  closeForm() {
    this.resetFormData()
    this.modalForm.close();
  }

  resetFormData() {
    this.show_error = false;
    this._start_date_for_export = undefined;
    this._end_date_for_export = undefined;
    this._client_name = undefined;
    this._client_id = undefined;
    this.item = null;
   $("select.select4").each((ind, domElement) => {
    $(domElement).val(null).trigger('change')
    })
  }

  calculateLineTotal() {
    return 10
  }

  populateFormValues() {
    if (this.item != null || this.item != undefined) {
      this._start_date_for_export = this.item.start_date_for_export;
      this._end_date_for_export = this.item.end_date_for_export;
    }
  }

}
