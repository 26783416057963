import {ElementRef, Injectable, ViewChild} from '@angular/core';

import * as jsPDF from 'jspdf'
import 'jspdf-autotable'

import * as moment from 'moment';
import {AuthService} from "../auth.service";

@Injectable()
export class CsvService {

    constructor(public authService: AuthService) {
        
    }


    private quote(text) {
        return "\"" + text.replace("\"", "\"\"") + "\"";
    }

    get delimiter() {
        return (this.isIE()) ? "\t" : ","
    }

    private formatCSV(input) {  // todo: 1) IE --> TAB DELIMITER + 2) date format
        // double " according to rfc4180
        var regexp = new RegExp(/["]/g);
        var output = input.replace(regexp, '""');
        //HTML
        var regexp = new RegExp(/\<[^\<]+\>/g);
        var output = output.replace(regexp, "");
        output = output.replace(/&nbsp;/gi,' '); //replace &nbsp;
        output = output.trim()
        if (output == "") return '';
        if (output.includes(this.delimiter) ) {
            //if (output.includes(',') || output.includes('+') || output.includes('-')) {
            return '"' + output + '"';

        }
        return output // return '"' + output.trim() + '"';
    }


    private exportTableToCSV(elem) {
        var csv = [];
        var rows = elem.querySelectorAll("tr");

        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");

            for (var j = 0; j < cols.length; j++)
                row.push(this.formatCSV(cols[j].innerText));

            csv.push(row.join(this.delimiter));
        }

        return csv.join("\n")
    }

    private addExtension(fileName,ext='.csv') {

        if (fileName.toLowerCase().lastIndexOf(ext) != (fileName.length-ext.length)) {
            return fileName + ext
        }
        return fileName
    }
    //@ViewChild('table2') table2: ElementRef;
    //let elem = this.table2.nativeElement

    isIE() {
        return window.navigator && window.navigator.msSaveBlob
    }

    public download(tableElem, filename) {

        let csv_text = this.exportTableToCSV(tableElem)
        let extFilename = this.addExtension(filename)

        if (this.isIE()) {  // IE
           
            var blob = new Blob([csv_text], { type: 'text/csv;charset=utf-8'});
            window.navigator.msSaveOrOpenBlob(blob, extFilename);
        }
        else {

            var element = document.createElement("a");
            element.setAttribute("href", "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(csv_text));
            element.setAttribute("download", extFilename);
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }

    tablePdf(tableElem,filename="download") {

        var doc = new jsPDF();
        doc.autoTable({
            html: tableElem,
        //    margin: {top: 20, right: 40, bottom: 20, left: 40},
       //     headStyles: {fillColor: '#696969', textColor: '#fff'},
            useCss:true
        });

        this.openPdf(doc,filename)
    }

    openPdf(doc,filename) {
        if (this.isIE()) {
            let blob = doc.output('blob')
            let extFilename = this.addExtension(filename,".pdf")
            window.navigator.msSaveOrOpenBlob(blob, extFilename);
        }
        else {
            window.open(doc.output('bloburl'), '_blank')
            // doc.output('dataurlnewwindow')
        }

    }

    // jsPdf

    textWidth(doc,text) {
        var fontSize = doc.internal.getFontSize();
        var txtWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;

        return txtWidth

    }

    centerText(doc,text,y) {

        var txtWidth = this.textWidth(doc,text)

        let x = (doc.internal.pageSize.width - txtWidth) /2
        doc.text(text, x, y);

    }

    addFooters(doc) {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFontSize(8);// optional

        let localTime = moment.utc().toDate();
        let dt = moment(localTime).format('Do MMM YYYY HH:mm:ss')
        let str1 = 'Printed at '+dt+ ' by '+ this.authService.user().pp_name
        let str2 = '1/1'
        let w = this.textWidth(doc,str2)
        for(var i = 0; i < pageCount; i++) {

            doc.setTextColor('#333')
            let y = doc.internal.pageSize.height - 10

            doc.text(str1, 20, y)
            doc.text(str2, doc.internal.pageSize.width-20-w, y)


        }
    }




}


