import {Injectable} from '@angular/core';

import {AuthService} from '../auth/auth.service';
import {BaseService} from '../auth/base.service'

@Injectable()
export class SessionCancellationReasonService extends BaseService {

    icon = "fa fa-ban"

    constructor(public authService:AuthService) { super( authService, "/api/session_cancellation_reason"); }
}