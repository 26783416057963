import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";

import {DynamicFormsCoreModule} from "../../sp-dynamic-forms/core";

import {DynamicFormEhisComponent} from "./dynamic-form-ehis.component";
import {DynamicLineEhisComponent} from "./dynamic-line-ehis.component";

import { Ng2UploaderModule } from 'ng2-uploader';
import { EhisControlModule } from "../../ehis-control/ehis-control.module" ;  //MaskedInput from 'angular2-text-mask'

import {DynamicForm} from "../dynamic-form/dynamic-form.component"
//import {EhisImgSrcDirective} from '../../ehis-directive/ehis-img-src.directive';
//import {EhisControlModule}   from '../../ehis-control/ehis-control.module';

//import {EhisControlModule} from "../../ehis-control/ehis-control.module"

import { TextMaskModule } from 'angular2-text-mask';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DynamicFormsCoreModule.forRoot(),
        Ng2UploaderModule,
        EhisControlModule,

        TextMaskModule,
    ],
    declarations: [
        DynamicFormEhisComponent,
        DynamicLineEhisComponent,
//        EhisImgSrcDirective,
        DynamicForm,
    ],
    exports: [
//        DynamicFormsCoreModule,
        DynamicFormEhisComponent,
        DynamicLineEhisComponent,

        DynamicForm
    ],
    providers: [
//        EhisImgSrcDirective
    ]
})
export class DynamicFormsEhisUIModule {}