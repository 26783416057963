import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import { UserList } from './list/user-list.component';
import { UserDetail } from './detail/user-detail.component';
//import { UserDetailResolve} from './auth/user-detail-resolve';
import { UserService} from '../service/user.service';
import { UserDetailResolve} from './user-detail-resolve';
import {AddWhoisUser} from "./add-whois-user/add-whois-user.component";
import {AddAdminUser} from "./add-admin-user/add-admin-user.component";
import {AddClientUser} from "./add-client/add-client-user.component";
import {AddAccountantUser} from "./add-accountant-user/add-accountant-user.component";

import {AddYearlyClientUser} from "./add-yearly-client/add-yearly-client-user.component";

import {WizardClientComponent} from "./wizard-client/wizard-client.component";

//import { ModalComponent } from '../auth/modal-window/modal.component';

import {AuthModule, AdminGuard} from "../auth/auth.module";

// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap'; // ???
//import { Ng2PaginationModule} from 'ng2-pagination'; // <-- import the module

//import { NgxPaginationModule} from 'ngx-pagination'; // <-- import the module

//import {LaddaModule} from "angular2-ladda";


//import { ModalModule }             from 'ng2-modal';
import { TypeaheadModule }         from "ngx-bootstrap" //from 'ng2-bootstrap/ng2-bootstrap';

//import {EhisControlModule}  from '../ehis-control/ehis-control.module';

import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!

import {UserProfile} from "./profile/user-profile.component";

//import {AmoTable} from "./amo-table/amo-table.component"

//import {ContactFormComponent} from "./contact-form/contact-form.component"

import {MailList} from "./mail-list/mail-list.component"
//export
const routes = [
    { path: '', component: UserList , pathMatch: 'full',}, // canActivate: [AdminGuard]  },
    { path: 'profile', component: UserProfile, canDeactivate: [SaveGuard]},
    { path: 'whois', component: AddWhoisUser, canActivate: [AdminGuard], canDeactivate: [SaveGuard]},
    { path: 'admin', component: AddAdminUser, canActivate: [AdminGuard], canDeactivate: [SaveGuard]},
    { path: 'client', component: AddClientUser, canActivate: [AdminGuard], canDeactivate: [SaveGuard]},

    { path: 'yearly', component: AddYearlyClientUser, canActivate: [AdminGuard], canDeactivate: [SaveGuard]},

    { path: 'accountant', component: AddAccountantUser, canActivate: [AdminGuard], canDeactivate: [SaveGuard]},

    { path: 'wizard', component: WizardClientComponent, canActivate: [AdminGuard], canDeactivate: [SaveGuard]},

    { path: 'mail_list', component: MailList, canActivate: [AdminGuard]},

    { path: ':id', component: UserDetail, canActivate: [AdminGuard] ,  canDeactivate: [SaveGuard],
        // resolve: {item: UserDetailResolve}


},
];

//export {UserList,UserDetail,UserProfile}

import {SmartadminModule} from '../shared/smartadmin.module'
//import {AnalyticsModule} from "../+dashboard/+analytics/analytics.module";

import {UserSelect} from "./select/user-select.component"


import {FlotChartModule} from "../shared/graphs/flot-chart/flot-chart.module";
import {ActivityService} from "../service/activity.service";
import {SubscriptionModule} from "../subscription/subscription.module";
import {InvoiceModule} from "../invoice/invoice.module"
import {ShippingModule} from "../shipping/shipping.module";
import {ControlModule} from "../controls/controls.module";

import { CurrencyMaskModule } from "ng2-currency-mask";
import {AddCredentialsComponent} from "./list/add-credentials.component";
import {AddProductComponent} from "./list/add-product.component";
import {PaymentModule} from "../payment/payment.module";
import {ProductUserModule} from "../product-user/product-user.module";
import {UserCreditsTable} from "./user-credits-table/user-credits-table.component";

import {ModalModule} from "ngx-modal"


import {UserInfo} from "./info/user-info.component"


import {EhisControlModule}  from '../ehis-control/ehis-control.module';


import { Ng2UploaderModule } from 'ng2-uploader';

import {NewsletterService} from "../service/newsletter.service";

import {WidgetModule} from "../widget/widget.module";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
   //     ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        TypeaheadModule,

//        Ng2PaginationModule,
//        NgxPaginationModule,

   //     EhisControlModule,
        //AccountModule,
        ButtonsModule,

        ControlModule,

        CurrencyMaskModule,

      //  RouterModule.forChild(routes),
      //  RouterModule, //.forChild(routes),

/*
        LaddaModule.forRoot({
            //style: "contract",
            //spinnerSize: 32,
            //spinnerColor: "red",
            //spinnerLines: 12
            //        "../node_modules/ladda/dist/ladda.min.css",

        }),
 */
        SmartadminModule,
 //   AnalyticsModule,
        FlotChartModule,

        SubscriptionModule,
        InvoiceModule,
        ShippingModule,
        PaymentModule,
        ProductUserModule,

        ModalModule,

        EhisControlModule,
        Ng2UploaderModule,  // photo

        WidgetModule,
   ],


    declarations: [
        UserList,
        UserDetail,
        UserProfile,
        AddWhoisUser,
        AddAdminUser,
        AddClientUser,
        AddAccountantUser,

        AddCredentialsComponent,
        AddProductComponent,
        AddYearlyClientUser,

        WizardClientComponent,

        UserCreditsTable,

        UserInfo,

        MailList,

        UserSelect,

    ],

    exports: [
        UserInfo,

        UserSelect,
        UserList,UserDetail,UserProfile
    ],
    providers: [
        UserService,
     //   UserDetailResolve,

        NewsletterService,
        ActivityService,
        SaveGuard,
        AdminGuard
    ]

})
export class UserModule {
}

