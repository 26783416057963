import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
declare var jQuery: any;

import { AuthService }  from '../../auth/auth.service';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.template.html',
  encapsulation: ViewEncapsulation.None,
//  styleUrls: ['./subscription.style.scss']
})
export class SubscriptionComponent {

  private data: any[] = [];

  dateFormat: string = 'mediumDate';

  @ViewChild('stripe') stripe;


    constructor(private authService: AuthService) { }

    back() {
        this.authService.back()
    }

    hasSubscriptions() {
        return this.data.length > 0;
    }

    getData() {
        this.authService.getSubscription()
            .subscribe(
                result => { this.data = result.data ;},
                error =>  { this.authService.showError(error) }
            );
    }


    ngOnInit(): void {
        this.getData();
        jQuery('.parsleyjs').parsley();
    }

    openCheckout() {

        let amount = this.authService.basicMembershipPaymentAmount();
        this.stripe.openCheckoutSubscription(amount);

    }

    onCharge($event) {
        this.authService.showMessage($event);
        this.getData();
    }

}
