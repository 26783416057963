import { NgModule }      from "@angular/core";
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { RouterModule }   from '@angular/router';

import { PaginationModule  } from 'ngx-bootstrap';
import { ButtonsModule } from 'ngx-bootstrap';
//import { SaveGuard} from '../auth/save-guard.service';

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";


import {DiagnosisService} from "./diagnosis.service";
import {DiagnosisList}  from "./list/diagnosis-list.component";
import {DiagnosisDetail}  from "./detail/diagnosis-detail.component";
import {DiagnosisTable}  from "./table/diagnosis-table.component";

/*
export const routes = [
    { path: '', component: DiagnosisList, pathMatch: 'full' },
    { path: ':id', component: DiagnosisDetail, canDeactivate: [SaveGuard],},
];
*/
import {AuthModule} from "../auth/auth.module";
import {WidgetModule} from "../widget/widget.module";
import {DiagnosisPopup} from "./popup/diagnosis-popup.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonsModule,
        ControlModule,
        AuthModule,


        PaginationModule.forRoot(),
        //RouterModule.forChild(routes),

        SmartadminModule,
        SmartadminInputModule,

        WidgetModule,

    ],
    declarations: [
        DiagnosisList,
        DiagnosisDetail,
        DiagnosisTable,
        DiagnosisPopup
    ],
    exports: [
        DiagnosisList,
        DiagnosisDetail,

        DiagnosisTable,
        DiagnosisPopup
    ],

    providers: [
 //     SaveGuard,
      DiagnosisService,
    ]

})
export class DiagnosisModule {}
