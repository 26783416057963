import {Component, ViewEncapsulation, ViewChild, Input, ViewContainerRef, ElementRef} from '@angular/core';
import {WidgetBase} from "../widget-base";


declare var $ :any;

@Component({
    selector: 'widget-well',
    templateUrl: './widget-well.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetWell extends WidgetBase {

    get isModal() {
        return ($(this.el.nativeElement).closest('modal-content').length > 0)
    }

}