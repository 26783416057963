import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {BaseList}       from '../../auth/base-list'

import {ProfessionalService}   from "../professional.service";

@Component({
  selector: 'professional-list',
  templateUrl: './professional-list.template.html',
})
export class ProfessionalList extends BaseList {


  constructor(el: ElementRef,private ds: ProfessionalService) {
    super(el,ds);
  }

  closeChart(item) {

    this.delete_id = item.id;
    this.baseService.authService.confirmationDialog('Are you sure to close the chart #'+ item.id +'?').
          subscribe( result => { if (result) this.deleteItem(this.delete_id)});
  }

    @ViewChild('add_popup') add_popup
    get loading() {
        return this.ds.loading
    }

    users = []
    onReadItems(result) {
        this.users = result._users // for popup
    }

    clickAdd() {
        let item = Object.assign({},this.paramItems)
        item._users = this.users

        this.add_popup.open(0,item, (task)=> {
            if (task) {
                this.ds.authService.showMessage('Share chart task created!')
            }
            else {
//                this.ds.authService.showMessage('Open chart record created!')
                this.ds.authService.showMessage('Share chart record created!')

                this.refresh()

            }
        })
    }

    showmessage2 () {
      this.ds.authService.showMessage2('HELLO')
    }

}
