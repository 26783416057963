/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


declare var jQuery: any;


import {AuthService} from "../../auth/auth.module"
import {BaseItem} from "../../auth/base-item";

import {UserService} from '../../service/user.service';

import {SubscriptionItem} from "../../subscription/detail/subscription-item"

export class AddUser extends SubscriptionItem{


    public validationOptions:any = {
        // Rules for form validation
        rules: {
            password: {
                required: true,

            },
            billing_day: {
                required: true,
                range: [1,28]
            },



        },

        messages: {

          password: {
                required: 'Please enter whois api user password.'
            },

            billing_day: {
                range: 'Please enter a day between 1 and 28.'
            },


        }
    };



    constructor (el: ElementRef,protected route: ActivatedRoute,protected userService:UserService,mode) {
        super(el,route,userService);

        this.item = { email: '', username: '', password: '', password_confirm: '', billing_day: 1 , mode: mode ,
                      product_id: 1, subscription_type_id: 1,
                      billing_month: 1, cutoff_threshold: 0,
                      price_year: 0, price_quarter:0, price: 0 , //price_month:0,
                      migrate: false};

        var dt = new Date();
        this.item.billing_month = dt.getMonth()+1;

        this.baseService.authService.getReference('product,use_subscription_type,cutoff_threshold')
            .subscribe(result=>{
                this.item._subscription_type = result._use_subscription_type;
                this.item._product = result._product;
                this.item._cutoff_threshold = result._cutoff_threshold;
            });
        this.savedItem = this.itemToSaveStr();
    }

    invitation() {
        this.handle(this.userService.invitation(this.item.email,1),false);
    }

    subscribe() {

        this.userService.subscribe(this.item)
            .subscribe(result => {
                if (result.error) { this.baseService.authService.showError(result.error)}
                else {
                    this.baseService.authService.showMessage(result.message ? result.message : 'Ok');
                    //this.forceQuit = true;
                    let subscription_id = +result.subscription_id;
                    this.noChanges = true;
                    /*
                    if (subscription_id) {
                        this.baseService.authService.navigate(['/subscription',subscription_id]); // back ???
                    }
                    else {
                        this.back();
                    }
                    */
                    this.back();

                }
            },
            error => {this.baseService.authService.showError(error)})

    }



}

