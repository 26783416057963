import {Component, Input} from '@angular/core';

//import {AuthService} from "../../auth/auth.service";

import * as moment from 'moment';
import {BaseForm} from "../../auth/base-form";

import {periodStr} from "../../auth/period-str"

@Component({
  selector: 'period',
  templateUrl: './period.component.html',
})
export class PeriodComponent /* extends BaseForm */{

  tab = 1;

  selectTab(tab) {

  }

  @Input('data') data:any = {}

/*  dt1: any;
  dt2: any;

  m1 = '';
  m2= '';
*/
  dt_period: any[] = [];


//  constructor(private authService:AuthService) {
//
//  }

  clearProjects(){
    console.log('click')
  }

  dateFmt :string = 'DD/MM/YYYY'; /* moment format */
  datePickerFmt = 'dd/mm/yy'

  dateEditFmt = 'dd/mm/yy'

/*
  start(d) {
    return d == '' ? null : moment().startOf(d).format(this.dateFmt);

  }
*/
/*
  startOf(d) {
    this.data.dt1 = (d == '') ? null : moment().startOf(d).format(this.dateFmt);
  }

  endOf(d) {
    this.data.dt2 = (d == '') ? null : moment().endOf(d).format(this.dateFmt);
  }

  period(d) {
      // this.data.period = +d;
  }

  periodOf(d) {
    this.dt1 = (d == '') ? null : (moment().startOf(d).format(this.dateFmt));
    this.dt2 = (d == '') ? null : (moment().endOf(d).format(this.dateFmt));
    console.log(d,this.dt2);

  }
*/

/*
  isStartOf(d) {
    return (this.data.dt1 == this.start(d))
  }

  isAnyStart() {

    let x =  !this.isStartOf('') && !this.isStartOf('year') && !this.isStartOf('quarter') &&
           !this.isStartOf('month') && !this.isStartOf('week') //&& !this.isStartOf('day') ;
    console.log('x',x)
    return x;

  }
*/

  nullPeriod() {
    this.data.dt1 = null;
    this.data.dt2 = null;

  }

  currentPeriod() {
    this.data.dt1 = moment().startOf('month').format(this.dateFmt)
    this.data.dt2 = moment().endOf('month').format(this.dateFmt)
  }

  todayPeriod() {
    this.data.dt1 = moment().startOf('day').format(this.dateFmt)
    this.data.dt2 = moment().endOf('day').format(this.dateFmt)
  }

  periodChange(e) {
    this.data.dt1 = e.dt1;
    this.data.dt2 = e.dt2;
 //   console.log(this.dt1,this.dt2);

    //  console.log(this.dt_period);
  }

  get column2() {
    return '38%'
  }

  compPeriodStr() {


    return periodStr(this.data.dt1,this.data.dt2,this.dateFmt)
    /*
    let fmt = this.dateFmt;
    let date1 = this.data.dt1 ? moment(this.data.dt1,fmt): null;
    let date2 = this.data.dt2 ? moment(this.data.dt2,fmt): null;


    if (date1) {

      let y1 = date1.year();
      let m1 = date1.month();
      let d1 = date1.date();
      let q1 = date1.quarter();

      if (date2) {

        let y2 = date2.year();
        let m2 = date2.month();
        let d2 = date2.date();
        let q2 = date2.quarter();

        if ((y1 == y2) && (m1 == 0) && (d1 == 1) && (m2 == 11) && (d2 == 31)) {  // year
          return date1.format('YYYY');
        }

        if ((y1 == y2) && (m1 == m2) && (d1 == d2)) {  // day
          return date1.format('DD MMM YYYY')
        }


        if ((y1 = y2) && (m1 == m2) && (d1 == 1)) {    // month
          let date2_1 = moment(date2).endOf('month');
          let d2_1 = date2_1.date();

          if (d2 == d2_1) {
            return date1.format('MMMM YYYY')

          }
        }

        if ((y1 = y2) && (q1 == q2)) {                   // quarter
          let date1_1 = moment(date1).startOf('quarter');
          let date2_1 = moment(date2).endOf('quarter');

          if ((d1==date1_1.date()) && (m1==date1_1.month()) && (d2==date2_1.date()) && (m2==date2_1.month())){
            return date1.format('Qo') + ' quarter ' + date1.format('YYYY');

          }
        }

        return date1.format(this.dateFmt) +' - ' + date2.format(this.dateFmt);
      }

      return date1.format(this.dateFmt) +' - ... ';
    }

    if (date2) {
      return ' ...  - ' + date2.format(this.dateFmt);

    }

    return "";

    */
  }



/*
  dt1_change($event) {
    this.dt2 = $event;
    console.log('event 1',$event)
  }


  dt1_change1($event) {
    console.log('event 2',$event)
  }
*/

  ngOnInit() {
//    this.data.start = this.data.start ? +this.data.start : 0;
//    this.data.end = this.data.end ? +this.data.end : 0;
    this.data.period = +this.data.period ? +this.data.period : 0;
//    console.log('this.data.period',this.data.period)

  }
  /*
  ngAfterViewInit() {
//    this.data.start = this.data.start ? +this.data.start : 0;
//    this.data.end = this.data.end ? +this.data.end : 0;
    this.data.period = +this.data.period ? +this.data.period : 0;
    console.log('this.data.period',this.data.period)

    // sp++
    if (this.data.period == 5) { // day
      console.log('set period 5')
      this.dt_period[5] = this.data.dt1;
    }

  }
  */
}
