import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-add-invoice-service',
  templateUrl: './add-invoice-service.component.html',
  styleUrls: ['./add-invoice-service.component.scss']
})
export class AddInvoiceServiceComponent {


  public serviceList = []
  public clinic = null
  public _tax = {};
  _selectedService = null;
  _quantity = 1
  _description: String = undefined;
  _date_of_service: any = undefined;

  public add_service: Boolean = true;

  constructor() {

  }


  @Input() item = null

  @ViewChild('addServicesModal') modalForm;
  @ViewChild('servicesForm') heroForm;


  formOnOpen() {
    if (!this.add_service) {
      this.populateFormValues()
    }
  }

  formOnClose() {
    // this.resetFormData()
  }

  show() {
    this.modalForm.open()
  }


  @Output('onDelete') onDelete = new EventEmitter()
  @Output('onSelect') onSelect = new EventEmitter()

  updateDescription(event) {
    const serv = this.serviceList.find(item => item.id === parseInt(this._selectedService));
    this._description = `${serv.description}`;
  }

  clickOk() {
    this.updateItemValues()
    this.onSelect.emit(this.item)
    this.resetFormData()
    this.modalForm.close();
  }


  closeForm() {
    this.resetFormData()
    this.modalForm.close();
  }

  resetFormData() {
    this._selectedService = null;
    this._quantity = 1
    this._description = undefined;
    this._date_of_service = undefined;
    this.item = null;
  }

  calculateLineTotal() {
    return 10
  }
  updateItemValues() {
    let returnServiceData = {
      service: this.serviceList.find(item => item.id === parseInt(this._selectedService)),
      quantity: this._quantity,
      description: this._description,
      date_of_service: this._date_of_service,
      tax: this._tax
    }
    if (this.item != null || this.item != undefined) {
      returnServiceData['id'] = this.item.id
      this.item = returnServiceData
    } else {
      this.item = returnServiceData
    }
  }

  populateFormValues() {
    if (this.item != null || this.item != undefined) {
      this._selectedService = this.item.service.id;
      this._quantity = parseInt(this.item.quantity);
      this._description = this.item.description;
      this._date_of_service = this.item.date_of_service;
    }
  }

}
