import { Component,ViewEncapsulation, ViewChild,Input,ViewContainerRef} from '@angular/core';

declare var $:any

import {WidgetBase} from "../widget-base"
@Component({
    selector: 'widget-list',
    templateUrl: './widget-list.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetList extends WidgetBase{

  //  parent: any = null;

  //  constructor(private viewContainerRef: ViewContainerRef){
  //  }

    // https://github.com/angular/angular/issues/10448
  //  getParentComponent(): any{
  //      return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
  //    //  return this.viewContainerRef[ '_view' ].component
  //  }

 //   get selector() {
 //       return this.parent.selector;
 //   }

 //   ngOnInit() {
 //       this.parent = this.getParentComponent()
 //   }

    //--------------------------------------------------------------------------

   // _title = "Widget list"

   // @Input('title') set title(value)  { this._title = value }
   // get title() { return this._title  }

   //----------------------------------------------------------------------------

//    @Input('icon') icon = "fa fa-windows"

    @Input('backButton') backButton = false;
    @Input('itemText') itemText = 'items'

    @Input('addButtonCaption') addButtonCaption = 'Add'
    @Input('addButtonWidth') addButtonWidth = '80px'
    @Input('toolBar') toolBar = true;
    @Input('pagerBar') pagerBar = true
    @Input('search') search = true

    @Input('tagsBar') tagsBar = false;

    @Input('headerDark')  header_dark = false
    @Input('scrollY') scroll_y = false
    @Input('fixedCol') fixed_col = 0;
    //  height:400px;width:100%;overflow: scroll"

    @Input('tableHeight') table_height = '458px'

    _addButton = true
    @Input('addButton') set addButton(value) { this._addButton = value }
    get addButton() { return this._addButton && this.parent.acl('insert')}



}