import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import {AuthModule} from "../auth/auth.module";
// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap';


//import { ModalModule }             from 'ng2-modal';
//import { TypeaheadModule }         from "ngx-bootstrap" //from 'ng2-bootstrap/ng2-bootstrap';

//import {EhisControlModule}  from '../ehis-control/ehis-control.module';

import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!



import {SmartadminModule} from '../shared/smartadmin.module'
import {SubscriptionTypeModule} from "../subscription-type/subscription-type.module";
import {UtilsModule} from "../shared/utils/utils.module";
import {WarningList} from "./list/warning-list.component";
import {WarningDetail} from "./detail/warning-detail.component";
import {WarningTable} from "./table/warning-table.component";
import {WarningService} from "./warning.service";
import {ControlModule} from "../controls/controls.module";

export const routes = [
    { path: '', component: WarningList , pathMatch: 'full' },
    { path: ':id', component: WarningDetail, canDeactivate: [SaveGuard],},
];

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
//        ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        ButtonsModule,

 //       RouterModule.forChild(routes),

        SmartadminModule,
        ControlModule,

    ],

    declarations: [
        WarningList,
        WarningDetail,
        WarningTable
   ],

    exports: [
        WarningList,
        WarningDetail,
        WarningTable
    ],

    providers: [
        SaveGuard,
        WarningService,
    ]

})
export class WarningModule  {
}

