import { Component, forwardRef } from '@angular/core';
import { Output, Input, OnInit } from '@angular/core';
//import { FormControl } from '@angular/forms';
//import { NG_VALUE_ACCESSOR } from '@angular/forms';
//import { ControlValueAccessor } from '@angular/forms';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

const noop = () => {
};
/*
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EhisTextComponent),
    multi: true
};
*/
// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
//http://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html


export function createCounterRangeValidator(maxValue, minValue) {
    return (c: FormControl) => {
        let err = {
            rangeError: {
                given: c.value,
                max: maxValue || 10,
                min: minValue || 0
            }
        };

        return (c.value > '') ? null: err;
    }
}


@Component({
    selector: 'ehis-text',
    template:
//`<div class="form-group">
//                    <label><ng-content></ng-content>
//                        <input [(ngModel)]="value"
//                                class="form-control"
//                                (blur)="onBlur()" >
//                    </label>
//                </div>`,

  `    <div class="form-group row">
    <label for="first_name" class="col-md-5  col-form-label text-md-right"><ng-content></ng-content>*</label>
<div class="col-md-7">
<input type="text"  id="first_name" class="form-control form-control-sm  pull-xs-left" name="first_name"  placeholder="" [(ngModel)]="value"
data-parsley-trigger="change"

                         data-parsley-group="tab-0"

data-parsley-validation-threshold="1"
required="required">
    </div>
    </div>
   `,

//    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisTextComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EhisTextComponent), multi: true }
    ]

})
export class EhisTextComponent implements ControlValueAccessor,OnInit {


//    @Input() label: string ='';

    //The internal data model
    private innerValue: any = '';
    validateFn: any = () => {};

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    ngOnInit() {
        this.validateFn = createCounterRangeValidator(0,100); // this.counterRangeMax, this.counterRangeMin);
    }


    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    validate(c: FormControl) {

        return this.validateFn(c);


//        let err = {
//            requiered: false,
//        };

//        return (c)=> {return (this.innerValue > '')? null : err}; // function!
        //let err = c.valid;
        //let err1 = (this.innerValue > '')? null : true;
        //console.log('validation:', err,err1);
        //return err1
    }

}
