import {Component, forwardRef ,Input, Output, EventEmitter, ElementRef, ViewContainerRef} from '@angular/core';
import { NG_VALUE_ACCESSOR,ControlValueAccessor  } from '@angular/forms';
import {AuthService} from "../../auth/auth.service";
import {CsvService} from "../../auth/csv/csv.service";

declare var $;

const noop = () => {};

export const CHECKBOX_GROUP_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxGroup),
    multi: true
};

@Component({

    selector: 'checkbox-group',
    templateUrl: './checkbox-group.component.html',
    providers: [CHECKBOX_GROUP_CONTROL_VALUE_ACCESSOR]

})
export class CheckboxGroup implements ControlValueAccessor{

    private innerValue: any = null;
    public innerTypedValue: any


    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {

        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(this.innerValue);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    clickPicture(option) {
        if (!this.disabled) {
            this.value = this.getOptionValue(option)
        }
    }

    getOptionValue(option) {
        if (Number.isInteger(this.innerTypedValue)) {
            return +option.id
        }
        else if (typeof this.innerTypedValue === 'string') {
            return option.id.toString()
        }
        else {
            return option.id
        }

    }

    //From ControlValueAccessor interface
    writeValue(value: any) {

        if (value) {
            if (Array.isArray(value)) {
                this.innerValue = value
            }
            else {
                this.innerValue = value.split(',')
            }
        }
        else {
            this.innerValue = []
        }

        this.index.forEach(val=>{
            val.checked = false
        })
        this.innerValue.forEach(val => {
            const element = this.index.find(x => x.id == val);
            if (element) {
                element.checked = true;
            }
        });
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }


    //----------------------------------------------------------------------------------------------------------

    _data = [
        { id: 1, name: 'Option 1', link: null},
        { id: 2, name: 'Option 2', link: null},
        { id: 3, name: 'Option 3', link: null},
    ]
    @Input('disabled') disabled = false



    get hasLinks() {
     //   this.hasLinks =
            return this._data.some((item)=>{return item.link ? true: false})

    }

    @Input('options') set options(value) {
        if (value) {
            if (value.index) this._data = value.index
        }
    }

    isdisabled() {
      return this.disabled ? true : undefined
    }

    labelMarginLeft(option) {
        return this.hasLinks ? // && option.loaded ?
        /*return option.link ?*/ { 'margin-left': '40px'} : null
    }

    //style="margin-left: 40px;

  //  indexTracker(index, value) {
   //     return value;
   // }

   // _name = 'rd'

    constructor(private el: ElementRef) {} //, private viewContainerRef: ViewContainerRef,public authService:AuthService, public csv: CsvService) {

        //  getRadioName() {
  //      return this._name
  //  }


    @Input() index: any[] = [];

    onToggle(item) {
       // item.checked = !item.checked

        let res= []
        this.index.forEach(val=>{
            if ( val.checked) {
                res.push(val.id)
            }
        })
        this.value = res;
    }


    updateLabelFor() {
        $(this.el.nativeElement).find(':input').each((ind2, elem2) => {

            let $elem2 = $(elem2);
            var id = "id" + Math.random().toString(16).slice(2)

            $elem2.attr('id', id )

            $elem2.next('label').attr('for',id)
            //  if ($elem2.next().is('label')) {
            //      console.log('label')
            //  }

         //   console.log('id')
        })

    }

    ngAfterViewInit() {
       this.updateLabelFor()
    }

}

