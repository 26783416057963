import {Component, OnInit, Input} from '@angular/core';
//import {ChatService} from "../chat.service";

@Component({
  selector: 'period-aside',
  templateUrl: './period-aside.component.html',
})
export class PeriodAsideComponent {

  @Input() users: Array<any>;

  public filter: string = '';

  public isOpen = false;

  public openToggle(){
    this.isOpen = !this.isOpen
  }

//  constructor(private chatService: ChatService) { }


  messageTo(user){
//    this.chatService.messageTo(user)

  }


}
