import {Injectable} from '@angular/core';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
//import {Observable}     from 'rxjs/Observable';
//import 'rxjs/Rx'  //country.service

import {AuthService} from '../auth/auth.service';
//import {BaseService} from '../auth/base.service'

@Injectable()
export class ActivityService  {

    baseURL = "/api/activity";

    data: any[] = [];
    total = 200;


    constructor(private authService:AuthService) {
         console.log('activity service created');
    }

    getData(seconds) {

        let url:string = this.authService.getApiUrl() + this.baseURL + '?seconds='+seconds;

        this.authService.get(url)
            .subscribe( result => { this.data = result},
              error=>{this.authService.showError(error)})

        return this.data;
    }


    getRandomData(){

        this.getData(200);

        //console.log(this.data);
        return this.data;

 /*       if (this.data.length > 0)
            this.data = this.data.slice(1);

        // do a random walk
        while (this.data.length < this.total) {
            var prev = this.data.length > 0 ? this.data[this.data.length - 1] : 50;
            var y = prev + Math.random() * 10 - 5;
            if (y < 0)
                y = 0;
            if (y > 100)
                y = 100;
            this.data.push(y);
        }

        // zip the generated y values with the x values
        var res = [];
        for (var i = 0; i < this.data.length; ++i)
            res.push([i, this.data[i]])

//        console.log(res);
        return res;
        */
    }


}