import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {MailboxService} from '../mailbox.service';
import {BaseItem}  from '../../auth/base-item';


@Component({
    selector: 'mailbox-detail',
    templateUrl: './mailbox-detail.template.html',
})
export class MailboxDetail extends BaseItem {

    constructor(el:ElementRef, protected route:ActivatedRoute, protected mailboxService:MailboxService) {

        super(el,route, mailboxService);
    }

    @ViewChild('formCheck') formCheck;
    check_email = '';


    check() {
        this.formCheck.open();
    }

    doCheck() {
        this.saveItem();
        // modal dialog
//        this.saveItem();
        this.mailboxService.checkMail(this.item.id,this.check_email);
        // modal

    }

}
