
import { Injectable }    from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable }    from 'rxjs';

import {SpinnerService} from "./spinner/spinner.service"


export interface SaveComponent {
    hasChanges:  () => Observable<boolean> | Promise<boolean> | boolean;
    call_lookup: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class SaveGuard implements CanDeactivate <SaveComponent> {

    public constructor(private spinnerService: SpinnerService) {
    }

    canDeactivate(target: SaveComponent): Observable<boolean> | Promise<boolean> |boolean {

        if (target.call_lookup()) {
            return true;
        }
        if (target.hasChanges()) {
            return this.spinnerService.quitDialog(); 
        }

        return true;
    }
}
