import { Component,ViewEncapsulation, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl,FormArray } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { EventEmitter, ElementRef } from '@angular/core';


import {BaseItem}  from '../../auth/base-item';
import {TaskService} from "../../task/task.service";
import {ProfessionalService} from "../professional.service";
import {finalize} from "rxjs/operators";
import {AuthService} from "../../auth/auth.service";

@Component({
    selector: 'std-popup',
    templateUrl: './std-popup.template.html',
})
export class StdPopup {

    item:any = {}

    @ViewChild('message') message


    constructor(el: ElementRef, public authService:AuthService) {

        authService.status.subscribe((status: any) => {

            this.item = Object.assign({},status)

            if (status.id == 'show_message') {

                   if (!this.item.title) {
                       this.item.title = 'Info'
                   }
                   this.message.open();
            }


        })
    }


}