import { Component, Input,Output } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import { EventEmitter, ElementRef } from '@angular/core';

@Component({
//    moduleId: module.id,
    selector: 'health-professionals',
    templateUrl: './health-professionals.template.html',
})
export class HealthProfessionalsComponent {
    @Input('group') public group: FormGroup;
    @Output() onRemove = new EventEmitter();
    @Input('doctors') doctors: any;

    id = '';
    selected_name: string;
    selected_id: string;


    removeMe() {
        this.onRemove.emit({});
    }
/*
    get id() {
        return this.group.controls['id'].value;
    }
*/

    nameOnSelect(e:any):void {
       if (e.item) {
           this.selected_id = e.item.id;
           this.selected_name = e.item.name;
           this.id = this.selected_id;
//           this.group.controls['id'].updateValue(this.selected_id);  // ���������� control.id value
       }
    }

    ngOnInit() {
        this.id = this.group.controls['id'].value;
        if (this.id) {
            this.selected_name = this.group.controls['name'].value;
            this.selected_id = this.id;
        }
        this.group.controls['name'].valueChanges.subscribe((result) => {
           this.id = (this.selected_name == result) ? this.selected_id : ''});
    }
}

