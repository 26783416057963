import {Component, Input, Output, EventEmitter, ElementRef, ViewContainerRef, ViewChild} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {CsvService} from "../../auth/csv/csv.service";
import {DynamicFormControlModel, DynamicInputModel} from "../../sp-dynamic-forms/core";

import {forkJoin} from "rxjs"

declare var $;


import {DragulaService} from "ng2-dragula"
import {DYNAMIC_FORM_CONTROL_TYPE_INPUT} from "../../sp-dynamic-forms/core/src/model/input/dynamic-input.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_NUMBER,
    DynamicNumberModel
} from "../../sp-dynamic-forms/core/src/model/number/dynamic-number.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,
    DynamicTextAreaModel
} from "../../sp-dynamic-forms/core/src/model/textarea/dynamic-textarea.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_SELECT,
    DynamicSelectModel
} from "../../sp-dynamic-forms/core/src/model/select/dynamic-select.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
    DynamicCheckboxModel
} from "../../sp-dynamic-forms/core/src/model/checkbox/dynamic-checkbox.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
    DynamicRadioGroupModel
} from "../../sp-dynamic-forms/core/src/model/radio/dynamic-radio-group.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_FILE,
    DynamicFileModel
} from "../../sp-dynamic-forms/core/src/model/file/dynamic-file.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT,
    DynamicAttachmentModel
} from "../../sp-dynamic-forms/core/src/model/attachment/dynamic-attachment.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_GROUP,
    DynamicFormGroupModel
} from "../../sp-dynamic-forms/core/src/model/form-group/dynamic-form-group.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
    DynamicFormArrayModel
} from "../../sp-dynamic-forms/core/src/model/form-array/dynamic-form-array.model";
//import {ForkJoinObservable} from "../../../../node_modules/rxjs-compat/observable/ForkJoinObservable";

function itemName(elem) {
    return (elem.name ? elem.name : 'e'+elem.id)
}
export function dynamicInit(model,data) {
    if (model && data) {
        model.forEach((item) => {
            if (item.class == 'array') {
                let key = itemName(item)
                let value = data[key]
                if (!value && item.data) {
                    data[key] = item.data
                }
            }
        })
    }

}



export function buildInit(model,data) {
    if (model && data) {
        model.forEach((item)=>{
            if (item.class == 'array') {
                let key = itemName(item)
                let value = data[key]
                if (!value && item.options && item.options.data) {
                    //let colcount = item.options && item.options.columns ? item.options.columns.length : 3
                    //this.data[key] = this.defaultArrayData(item)               // =  dynamic-array._data
                    data[key] = item.options.data             // =  dynamic-array._data
                }
            }
        })
    }
}

@Component({

    selector: 'dynamic',
    templateUrl: './dynamic.component.html',
})
export class Dynamic {

    @Input('editor')

    editor = false;

    _data: any = {}
    @Input('data') set data(value) {
        if (value) {
            this._data = value
         //   this.initData()
            //this.initModelData()
        }
    }

    get data() {
        return this._data
      //  this.initModelData()
    }

    _model: any[] = []
    @Input('model') set model(value) {
        if (value) {
            this._model = value
          //  this.initData()
        }
    }

    get model() {
        return this._model
    }

    /*
    defaultArrayData(item) {

        let colcount = item.options && item.options.columns ? item.options.columns.length : 3
        let rowcount = item.options && item.options.rows ? item.options.rows : 3

        let data = [];
        while (data.length < rowcount) {
            let item = []
            while (item.length < colcount) {
                item.push('')
            }
            data.push(item)
        }

        if (item.options && item.options.columns && data.length > 0) {
            data[0] = item.options.columns.map(item => {
                return item
            })
        }

        console.log('default', data)
        return data
    }
    */
    indexTracker(index, value) {
        return index;
    }

    /*
    initModelData() {

        return

        if (this.model) {
            this.model.forEach((item) => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = this.data[key]
                    if (!value) {
                        //let colcount = item.options && item.options.columns ? item.options.columns.length : 3
                        this.data[key] = this.defaultArrayData(item)               // =  dynamic-array._data
                    }
                }
            })
        }


    }


    buildInit() {
        if (this.model) {
            this.model.forEach((item) => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = this.data[key]
                    if (!value && item.options && item.options.data) {
                        //let colcount = item.options && item.options.columns ? item.options.columns.length : 3
                        //this.data[key] = this.defaultArrayData(item)               // =  dynamic-array._data
                        this.data[key] = item.options.data             // =  dynamic-array._data
                    }
                }
            })
        }
    }

    buildDone() {
        if (this.model) {
            this.model.forEach(item => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = this.data[key]
                    if (value) {
                        item.options.data = value
                        //  this.data[key] = this.defaultArrayData(item)               // =  dynamic-array._data
                    }
                }
            })
        }

    }


    public init(model, data) {

        if (!data) data = {}
        if (model) {
            model.forEach((item) => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = data[key]
                    if (!value) {
                        data[key] = this.defaultArrayData(item)               // =  dynamic-array._data
                    }
                }
            })
        }

        this._model = model
        this._data = data

        return data
    }
 */

    //  $el = null

    _name = ''

    compName() {
        //let name = this.el.nativeElement.getAttribute('name')
        let name = this._name
        if (!name) name = 'dynamic'
        return name
    }


    itemName(elem) {
        return (elem.name ? elem.name : 'e' + elem.id)
    }

    className(elem) {
        return elem.class == 'legend' ? elem.class + ' legend-title' : elem.class
    }

    fileName(elem) {
        return this.itemName(elem) + '_filename'
    }


    dragulaName() {


        return this.compName()

        if (this.editor) return this.compName()
        return null
    }

    get dragulaModel() {
        return this._model
    }

    set dragulaModel(val) {

        //console.log('set dragulaModel')

        let i = 0
        val.forEach((item) => {
            this._model[i] = item
            i = i + 1
        })
    }

    formName(elem) {
        return this.compName() + '_' + this.itemName(elem)
    }

    constructor(private el: ElementRef, public authService: AuthService, private dragulaService: DragulaService) { //, private viewContainerRef: ViewContainerRef,public authService:AuthService, public csv: CsvService) {


        this._name = "dr" + Math.random().toString(16).slice(2)
    }


    ngOnInit() {
        this.dragulaService.createGroup(this.dragulaName(), {
            moves: (el, source, handle, sibling) => this.editor
        });

   //     this.initData();

    }

    ngOnDestroy() {
        this.dragulaService.destroy(this.dragulaName())
    }

    //
    // @Output('modelChange') public modelChange: EventEmitter<any> = new EventEmitter();

    _selectedControlModel = null

    get selectedControlModel() {
        if (this.editor) return this._selectedControlModel
        return null
    }

    set selectedControlModel(val) {
        // this._selectedControlModel =val

        if (this.editor) {
            this._selectedControlModel = val
        }
        else {
            this._selectedControlModel = null
        }
    }

    /*
        selectedClass(elem) {
            if (elem== this.selectedControlModel) return 'dynamic-ehis-selected'
            return 'dynamic-ehis-unselected'
        }
        */
    selectedClass(elem) {
        if (elem == this.selectedControlModel) return 'dynamic-selected'
        return 'dynamic-unselected'
    }

    selectModel(elem) {
        if (elem != this.selectedControlModel) {
            this.selectedControlModel = elem
           // console.log('select:', this.model)
        }
    }

    removeModel(ctrl) {
        let ind = this.model.indexOf(ctrl);
        if (ind >= 0) {

            this.model.splice(ind, 1);
            let key = this.itemName(ctrl)
            if (this._data) {


                delete this._data[key]
            }
            this.selectModel(null);
        }
    }

    btnClass = "btn btn-xs btn-block txt-color-white margin-top-5"
    interstyle = {
        background: '#c79121' // '#3276b1'
    }

    adds = [
        {id: 'input', name: 'Single line text'},
        {id: 'text', name: 'Multi line text'},
        {id: 'number', name: 'Number'},
        {id: 'checkbox', name: 'Checkbox'},
        {id: 'radios', name: 'Radios'},
        {id: 'select', name: 'Select'},
        {id: 'photo', name: 'Photo'},
        {id: 'array', name: 'Table'},

        {id: 'skip', name: ''},

        {id: 'legend', name: 'Title', label: 'Title'},
        {id: 'file', name: 'File'},


    ]

    idKey = 'id'

    newControlId() {
        let i = this._model.reduce((acc, elem, idx) => {

            let v = +elem[this.idKey]
            return Math.max(acc, Number.isNaN(v) ? idx : v)
        }, 0) + 1
        return i
    }

    scrollToModel = 0

    addModel(e) {
        this._model.push(e);
        //this.createBuild();
        this.selectModel(e);

        this.scrollToModel = this._model.length

        //https://stackoverflow.com/questions/1890995/jquery-scroll-to-bottom-of-page-iframe
        /*let elem2 = $(this.el.nativeElement).find("ul") //.last()//.get[0]
        if (elem2.length) {

            elem2[0].scrollIntoView({block: "end", behavior: "smooth"})
            /*
            console.log('elem2',elem2)
            let elem3 = elem2.children().first()
            if (elem3) {
                console.log('elem3',elem3)
                elem2.animate({ scrollTop: elem3.height() }, "slow");
            }

        }
        */
    }

    ngDoCheck() {

        if (this.scrollToModel) {
            this.scrollToModel = 0
            let elem2 = $(this.el.nativeElement).children().last()//.find("ul") //.last()//.get[0]
            if (elem2.length) {

                setTimeout(() => {
                    elem2[0].scrollIntoView({block: "end", behavior: "smooth"})
                    // this.events.emit(this.commandsList[j])
                }, 0)


                /*
                console.log('elem2',elem2)
                let elem3 = elem2.children().first()
                if (elem3) {
                    console.log('elem3',elem3)
                    elem2.animate({ scrollTop: elem3.height() }, "slow");
                }
                */
            }
        }

    }


    @ViewChild('add_switcher') add_switcher

    onAdd(add) {

        let cls = add.id
        let title = add.name
        let label = add.label ? add.label : cls.charAt(0).toUpperCase() + cls.slice(1)

        let id = this.newControlId()
        let e: any = {
            id: id,
            class: cls,
            label: `${label} ${id}`,
        }

        switch (cls) {

            case 'input':
                e.checkminlength = 0

                break;

            case 'number':
                // e.number = true
                e.precision = 0
                e.unit = ''
                break;

            case 'text':
                e.rows = 3
                break;

            case 'select':
                e.index = [
                    {id: 1, name: 'value 1'},
                    {id: 2, name: 'value 2'},
                    {id: 3, name: 'value 3'},
                ]
                break;

            case 'radios':
                e.index = [
                    {id: 1, name: 'Option 1', link: null},
                    {id: 2, name: 'Option 2', link: null},
                    {id: 3, name: 'Option 3', link: null},
                ]
                break;

            case 'array':
                e.fixed = false

                e.index = [
                    {id: 1, name: 'Column 1'},
                    {id: 2, name: 'Column 2'},
                    {id: 3, name: 'Column 3'},
                ]
                break;


        }
        this.addModel(e)
        this.add_switcher.hide()
    }

    /*
        addInput() {

            let id = this.newControlId();
            let e =  //<DynamicFormControlModel>
                new DynamicInputModel({

                        id: `el${id}`,
                        label: `Input ${id}`,
    //            maxLength: 42,
                        minLength: 0, //   > 0 ??? ==  required
                        placeholder: '',
                        required: true,

                    }
    //            ,
    //            {
    //            element: {label: "form-control-label", control: "form-control-sm  pull-xs-left"},
    //            grid: {   label: "col-md-3", control: "col-md-9"}
    //            }
                );
    //        e.name = `el${id}`;
            this.addModel(e);
        }
    */

    addIndexColumn($event) {
    }

    removeIndexColumn($event) {
    }


    blobs = {}

    onBeforePhoto($event, md) {
 //       console.log('onBeforePhoto', md) // ,$event)
    }

    onClearBlob($event, md) {
        //  if (md.class=='photo') {
        let key = this.itemName(md)
        delete this.blobs[key]
        //  }
    }


    onAfterPhoto($event, md) {
   //     console.log('onAfterPhoto', md) // ,$event)
        if (md.class == 'photo') {
            let key = this.itemName(md)
            // if ($event) {
            this.blobs[key] = $event
            //  }
            //  else {
            //    delete this.blobs[key]
            //  }
        }
    }


    get loaded() {


        let res = this.model.every((md) => {

            if (md.class == 'photo') {
                let key = this.itemName(md)
                if (this.data[key]) {
                    return (key in this.blobs);
                }
            }
            return true
        })

        // console.log('loaded',this.model.length,res)
        return res
    }

    stripHTML(html) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }


    public docDefinition(): any {

        let body = [];


        this.model.forEach(md => {

            var row = [];

            let key = this.itemName(md)
            let value = this.data[key]

         //   console.log(md, value)

            if (md.class == 'input') {

                row = [md.label, value];
            }

            if (md.class == 'number') {

                row = [md.label, value];
            }

            if (md.class == 'text') {

                row = [md.label, value];
            }

            if (md.class == 'select') {

                let ul = [];
                md.index.forEach(option => {
                    if (option.id == value) {
                        ul.push(option.name);
                    }
                });
                var val: any = '';
                if (ul.length > 0) {
                    val = ul[0]
                }


                row = [md.label, ul];
            }

            if (md.class == 'checkbox') {

                let val = value ? 'YES' : 'NO';

                row = [md.label, val];
            }

            if (md.class == 'radios') {

                let ul = [];
                md.index.forEach(option => {
                    if (option.id == value) {
                        ul.push(option.name);
                    }
                });
                var val: any = '';
                if (ul.length > 0) {
                    val = {ul: ul};
                }

                row = [md.label, val];
            }

            if (md.class == 'file') {

                let key = this.fileName(md)
                let val = this.data[key]

                row = [md.label, val];
            }


            if (md.class == 'legend') {

                let val = this.stripHTML(md.label);

                row = [{text: val, colSpan: 2}, ''];  // span .... table ....
            }

            if (md.class == 'photo') {

                let base64data = this.blobs[key]

                let val = (base64data) ? {image: base64data, fit: [300, 300]} : '';

                // console.log('base64',md.link, md.base64data ? 'yes': 'no')
                row = [md.label, val];
            }

            if (md.class == 'array') {

                //   let md = model as DynamicFormArrayModel;

                let rows = []

                let colcount = md.index.length;

                let cols = []
                var hasValue = false;
                for (let col of md.index) {
                    let val = col.name
                    if (val) {
                        cols.push(val)
                        hasValue = true
                    }
                    else {
                        cols.push('')
                    }
                }
                if (hasValue) {
                    rows.push(cols);
                }

                if (value) {
                    for (let row of value) {

                        let cols = []
                        var hasValue = false;

                        let i = 0
                        for (let col of md.index) {
                            let val = ''
                            if (row.length <= colcount) {
                                val = row[i]
                            }
                            i = i + 1

                            if (val) {
                                cols.push(val)
                                hasValue = true
                            }
                            else {
                                cols.push('')
                            }
                        }
                        if (hasValue) {
                            rows.push(cols);
                        }

                    }
                }


                /*



                let rows = [];
                var colcount = 0;
                for (let table_row of md.groups) {
                    let cols = [];
                    var hasValue = false;
                    for (let table_col of table_row.group){
                        if (table_col.value) {
                            cols.push(table_col.value);
                            hasValue = true;
                        }
                        else {
                            cols.push('');
                        }
                    }
                    if (hasValue) {
                        rows.push(cols);
                    }
                    colcount = cols.length;
                }

                */

                let table_top_bottom_off = 20;

                var val: any = '';
                if (rows.length > 0) {
                    let widths = [];
                    let w = Math.trunc(1 / colcount * 100);
                    while (colcount > 1) {
                        widths.push(`${w}%`);
                        colcount--;
                    }
                    widths.push('*');
                    /*
                    val = {
                        table: {
                            widths: widths,
                            headerRows: 1,
                            body: rows
                        }
                    }
                    */
                    val = {
                        table: {
                            widths: widths,
                            headerRows: 1,
                            body: rows
                        },
                        colSpan: 2,
                        border: [false, false, false, false],
                        margin: [0, (md.label.length) > 0 ? 0 : table_top_bottom_off, 0, table_top_bottom_off],

                    }


                }

                //-- row = [md.label, val];  // old layout

                // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                // new layout: the label above table
                //
                if (md.label.length > 0) {
                    row = [{
                        text: md.label, colSpan: 2,
                        border: [false, false, false, false],
                        margin: [0, table_top_bottom_off, 0, 0]
                    }, ''];
                    body.push(row.map(elem => {
                        return elem ? elem : ''
                    }));
                }

                row = [val, ''];

                //------------------------------------------------------------------------------


            }


            if (row.length > 0) {
                body.push(row.map(elem => {
                    return elem ? elem : ''
                }));
            }
        })

        //console.log(body);

        //----------------------------------------------------------------------------------------------------

        /*    let dd = {
                content: []
            }

            if (body.length > 0) {

                    (<any> dd).header = function(currentPage, pageCount):any {
                        let text = currentPage.toString() + ' page of ' + pageCount;

                        return { text: text, alignment: (+currentPage % 2) ?  'right' : 'left' };
                    };

                    (<Array<any>> (dd.content)).splice(0,0,{ table:{

                        widths: [200, '*'],

                        body:body
                    }});

                    (<any> dd).pageMargins = [ 40, 60, 40, 60 ];


                    (<any> dd).footer = {

                        image: "assets/img/doctors/doctor-joke.jpg",
                        alignment: 'center'
                    };

                }

                return dd;
         */

        if (body.length > 0) {

            return {
                table: {
                    widths: [200, '*'],
                    body: body
                }
            };
        }
        else {
            return {text: ''};
        }

    }


    /*
        pdfOpen() {
            let dd = {
                pageSize: 'A5',
                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'portrait',

                content: [ this.content() ]
            }
    //        pdfMake.createPdf(dd).open();
            this.pdfService.open(dd);
        }

        pdfDownload(fileName) {
            let dd = {
                content: [ this.content() ]
            }
    //        pdfMake.createPdf(dd).download(fileName);
            this.pdfService.download(dd,fileName);
        }
    */

    content() {

        return this.docDefinition();

        /*
                let blobs = []
                this.model.forEach(md => {
                    if (md.class == 'photo') {

                        let key = this.itemName(md)
                        let value = this.data[key]
                        if (value) {

                            blobs.push(this.authService.getImageBlob(value))
                        }
                    }
                })


                if (blobs.length ) {
                    forkJoin(blobs).subscribe(results => {
                            console.log('done', blobs.length)
                            return this.docDefinition()
                        }
                    );
                }
                else {
                    return this.docDefinition();
                }
                */
    }

    onModelChange($event,elem) {
        //console.log(elem.class)

        if (this.editor) {
            if (elem.class == 'array') {
                //console.log($event,elem.fixed)
                elem.data = $event
            }
        }
    }

    initData() {

        if (this.model && this._data) {
            this.model.forEach((item) => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = this._data[key]
                    if (!value) {
                        this._data[key] = item.data
                    }
                }
            })
        }

    }


    @Input('disabled')  disabled = false

}

