import {Component, OnInit, Input,ViewContainerRef,Output,EventEmitter} from '@angular/core';
//import {PostSectionList} from "./post-section-list/post-section-list.component";
import {AuthService} from "../auth/auth.service";
////import {PostSectionDetail} from "./post-section-detail/post-section-detail.component";

@Component({

  selector: 'message-breadcrumbs',
  template: `
   <div class="txt-color-blueDark" style="display:block;margin-bottom:2rem;">
   <h1 style="font-style: italic;"><i class="fa-fw fa fa-{{icon}}" ></i>
   
   <span *ngIf="!f1">My messages</span>
   <a *ngIf="f1" class="button"  style="text-decoration: underline;" (click)="f1Click($event)">My messages</a>
 
 <!--
   <span *ngIf="f2">
    >
   <a *ngIf="f2" class="button"  style="font-style: italic;text-decoration: underline;"[routerLink]="routerLink2">{{item2.name}}</a>
   
   </span>
   
   -->
   
   <span *ngFor="let item of items" class="">> {{item}}</span>
   <!--
   <span *ngFor="let item of items.slice(1)">> {{item}}</span>
   -->
    </h1>
 
</div>
  `,
})
export class MessageBreadcrumbsComponent implements OnInit {

  @Input() public icon: string ='paper-plane-o';
  @Input() public items: Array<string>;


  @Input('parent') parent = this;


  constructor(private viewContainerRef: ViewContainerRef,authService:AuthService) {

  }

  getParentComponent(): any{
     return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component
  //  return this.viewContainerRef[ '_view' ].component
  }

  @Output() onF1 = new EventEmitter();
  @Input('f1') f1 = false
  f2 = false

  item2 :any = {}
  routerLink2=[]

  ngOnInit() {
    let parent = this.getParentComponent()

    //this.f1 =  !(parent instanceof PostSectionList) ;
    /*
    this.f2 =  (parent instanceof PostSectionDetail)
    if (this.f2) {
      this.item2 = parent.item;
      console.log(parent.item)
      this.routerLink2 = ['/post_section/'+this.item2.id]
    }
    */
  }

  f1Click($event) {
    $event.stopPropagation();
    this.onF1.emit();
  }




}
