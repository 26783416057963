import {Component, Input, Output, ElementRef, EventEmitter} from '@angular/core';
import {BaseForm} from "../../auth/base-form";

@Component({

    selector: 'subscription-trial',
    template: `

                                                <section>
                                                    <label class="label">Number of queries</label>
                                                    <label class="input">
                                                    <!--
                                                        <select name="cutoff_threshold" [(ngModel)]="item.cutoff_threshold">
                                                            <option value="{{ref.id}}" *ngFor="let ref of item._cutoff_threshold" >{{ref.name}}</option>
                                                        </select> <i></i>
                                                         -->
                                                        <i class="icon-prepend fa fa-edit" ></i>
                           
                                                 <input type="text" name="cutoff_threshold"
                                                               [(ngModel)] ="item.cutoff_threshold"
                                                               currencyMask [options]="float0"
                                                               [readonly]="false">
                                                       </label>
                                                </section>
<!--

                                                <section class="col col-6">
                                                    <label class="label">Amount due</label>

                                                    <label class="input">
                                                        <i class="icon-prepend fa fa-dollar" ></i>
                                                        <input type="text" name="price"
                                                               [(ngModel)] ="item.price"
                                                               currencyMask [options]="float2"
                                                               [readonly]="false">
                                                    </label>
                                                </section>
                        -->                        

 `,
})
export class SubscriptionTrialComponent extends BaseForm {

    @Input('item') item;

    constructor(el:ElementRef) { super(el) }

    /*
        float0 = {
            thousands: ' ',
            decimal: '.',
            precision:0,
            align: 'left',
            allowNegative: false,
            prefix : ''
        }
        */

}

