import {Observable} from "rxjs";

import { ElementRef,Input} from '@angular/core';


export class BaseForm {

    _tab = 0;

    get tab() {
        return this._tab
    }

    set tab(val) {
        this._tab =val
    }

    selectTab(tab) {
        this.tab = tab;
      //  console.log('tab click',tab)
    }

    float2 = {
        thousands: ' ',
        decimal: '.',
        precision:2,
        align: 'left',
        allowNegative: false,
        prefix : ''
    }

    float0 = {
        thousands: ' ',
        decimal: '.',
        precision:0,
        align: 'left',
        allowNegative: false,
        prefix : ''
    }


    int0 = {
        thousands: ' ',
        decimal: '.',
        precision:0,
        align: 'left',
        allowNegative: false,
        prefix : ''
    }

    //-----------------------------------------------------------------------------------------------

    @Input('parent') parent = null;
/*
    constructor() {
        this.parent = this;
    }
*/
    back() {}

    close() {
        this.back();
    }

    @Input ('well') well  = false;

    constructor(protected el:ElementRef) { this.parent = this; }

    get selector() {
        return this.el.nativeElement.tagName.toLowerCase();
    }


}