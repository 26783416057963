import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';

import {BaseList} from "../../auth/base-list";
import {ShippingService} from "../shipping.service";

@Component({
  selector: 'shipping-list',
  templateUrl: './shipping-list.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class ShippingList extends BaseList {

    constructor(el: ElementRef, private shippingService:ShippingService) {
        super(el,shippingService);
    }


    get isRawData() {
        let mode = this.paramItems.mode? +this.paramItems.mode : 0;
        return mode == 0
    }

}
