import { Component, forwardRef } from '@angular/core';
import { Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
const noop = () => {};


@Component({
    selector: 'ehis-legend',
    template: `
              <legend>
                  <ng-content></ng-content>
              </legend>
   `,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisLegendComponent), multi: true },
//        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EhisLegendComponent), multi: true }
    ]
})
export class EhisLegendComponent implements ControlValueAccessor {

    private innerValue: any = '';

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

}
