import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';

import {BaseList} from '../../auth/base-list';

import {WarningService} from "../warning.service";

@Component({
  selector: 'warning-table',
  templateUrl: './warning-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class WarningTable extends BaseList {

    constructor(el:ElementRef, private warningService:WarningService) {
        super(el,warningService);
    }

    @Input('title') title = 'Notifications';

}
