import {Component} from '@angular/core';
import {AuthService} from "../auth.service";
import {Observable} from "rxjs";

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent  {

  password = '';
  password_confirm = '';

  constructor(private authService:AuthService) {}

  hasChanges() {
      return false;
  }

  handleError(err) {
    this.authService.showError(err);
  }

  handleResult(result) {
    this.authService.showMessage(result.message);
    this.authService.home();
  }

  handle(Ob: Observable<any>,spinner:boolean= true) {
    if (spinner) { this.authService.spinnerStart() }
    Ob.subscribe(
        result => { this.authService.spinnerStop();
          if (result.error) {
            this.handleError(result.error);
          }
          else {
            this.handleResult(result);
          }
        },
        error  => { this.authService.spinnerStop();
          this.handleError(error)
        }
    );
  }

  changePassword() {
    this.handle(this.authService.changeregister(this.password, this.password_confirm));
  }

  home() {
      this.authService.home();
  }

  call_lookup() {   // save in SaveGuard
      return false;
  }
}
