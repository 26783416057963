/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import { Component,ViewEncapsulation, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl,FormArray } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { EventEmitter, ElementRef } from '@angular/core';


import {DiagnosisService} from '../diagnosis.service';
import {BaseItem}  from '../../auth/base-item';

@Component({
    selector: 'diagnosis-detail',
    templateUrl: './diagnosis-detail.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class DiagnosisDetail extends BaseItem {

    constructor(el: ElementRef, protected route: ActivatedRoute, protected ds: DiagnosisService) {
        super(el,route, ds);
    }

    can_user_list() {
        return this.ds.authService.can_user_list()
    }
}