import { Component,ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';
import {SettingService} from '../setting.service';


@Component({
    selector: 'setting-detail',
    templateUrl: './setting-detail.template.html',
})
export class SettingDetail extends BaseItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected settingService:SettingService) {
        super(el,route, settingService);
    }

}
