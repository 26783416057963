import { NgModule } from "@angular/core";
import { RouterModule }   from '@angular/router';
import { CommonModule }  from '@angular/common';
import { ReactiveFormsModule} from "@angular/forms";

//import { PaginationModule }          from 'ng2-bootstrap/ng2-bootstrap';


//import {DynamicFormsCoreModule} from "@ng2-dynamic-forms/core";
//import {DynamicFormControlModel, DynamicFormService} from "@ng2-dynamic-forms/core";

import {DynamicFormsCoreModule} from "../sp-dynamic-forms/core";
import {DynamicFormControlModel, DynamicFormService} from "../sp-dynamic-forms/core";

import {AuthModule, SaveGuard} from "../auth/auth.module";


//import {DynamicFormComponent} from "./dynamic-form/dynamic-form.component.ts";

//import {DynamicFormsBootstrapUIModule} from "@ng2-dynamic-forms/ui-bootstrap";
import {DynamicFormsEhisUIModule} from "./ui-ehis/ui-ehis.module";

import { DragulaModule }             from "ng2-dragula" ;

import { CanDeactivateGuard }      from '../auth/can-deactivate-guard.service';

import {ChartTemplateList} from "./list/chart-template-list.component";
import {ChartTemplateDetail} from "./detail/chart-template-detail.component";
//import {ChartTemplateView} from "./view/chart-template-view.component";
//import {ChartTemplate} from "./chart-template.component";

import {ChartTemplateSelect}     from "./select/chart-template-select.component";
import {ChartTemplateSelectList} from "./select-list/chart-template-select-list.component";


import {EhisControlModule} from '../ehis-control/ehis-control.module';

import { Ng2UploaderModule } from 'ng2-uploader';

//import {RStoreModule} from "../rstore/rstore.module";

import { TextMaskModule } from 'angular2-text-mask';


import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!


import {PdfService} from "../service/pdf.service";
import {ChartTemplateService} from "../service/chart-template.service"

export {ChartTemplateList,  ChartTemplateDetail , ChartTemplateSelectList};


import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";

import {WidgetControlModule} from '../widget-control/widget-control.module'

import { ModalModule }             from 'ngx-modal';
import {WidgetModule} from "../widget/widget.module";
import {ChartService} from "../chart/chart.service";



@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        //RouterModule,



//        DynamicFormsCoreModule.forRoot(),
        DynamicFormsEhisUIModule,

    //    PaginationModule,
        ModalModule,

        //TypeaheadModule,

        DragulaModule.forRoot(),
        // ...all remaining imports

        EhisControlModule, // <-- with MaskedInput

        Ng2UploaderModule,

//        RouterModule.forChild(routes)
//        RStoreModule

        ButtonsModule,   // btnRadio
  //      DropdownModule,

        ControlModule,
        SmartadminModule,
        SmartadminInputModule,

        AuthModule,

        WidgetControlModule,

       // WidgetModule,

        TextMaskModule,



   ],
    declarations: [
        ChartTemplateList,
        ChartTemplateDetail,
        ChartTemplateSelectList,

//        ChartTemplateView,
//        ChartTemplate,
        ChartTemplateSelect,

    ],
    exports: [
        ChartTemplateSelect,
    ],
    providers: [
        PdfService,
        DynamicFormService,
        ChartTemplateService,
        SaveGuard,

        ChartService  // ++convert
    ]

})
export class ChartTemplateModule {
}
