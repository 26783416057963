import {Component, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {BaseItem}  from '../../auth/base-item';

import {ShippingService} from '../shipping.service';

@Component({
    selector: 'shipping-detail',
    templateUrl: './shipping-detail.template.html',
})
export class ShippingDetail extends BaseItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected shippingService:ShippingService) {

        super(el,route, shippingService);
    }

    setOnHold() {  // ask ?
        this.item.subscription_state_id = 2;
        this.saveItem();
    }

    setActive() { // ask ?
        this.item.subscription_state_id = 1;
        this.saveItem();
    }


    onReadItem(result) {
     //   result.period = this.baseService.authService.monthFmt(result.started_at);
        result.period = this.baseService.authService.dayFmt(result.started_at);
    }

}
