import { Component, forwardRef } from '@angular/core';
import { Output, Input,OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { FormControl } from '@angular/forms';

const noop = () => {};


@Component({
    selector: 'ehis-time',
    template:

  `
  <div class="form-group row">
        <label for="dob" [ngClass]="labelClass"><ng-content></ng-content></label>
        <div [ngClass] = "inputClass">
        <input type="text"  #dob id="dob" class="form-control form-control-sm  pull-xs-left" placeholder="hh:mm" [(ngModel)]="value"
                data-parsley-trigger="change"
                [readonly] = "readonly"
                data-parsley-required-message=""
                [textMask]="{mask: timeMask}"
                data-parsley-minlength="5"
                data-parsley-time="4"
                data-parsley-error-message="Should be valid time hh:mm"
                required="required">
        </div>
    </div>
   `,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisTimeComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EhisTimeComponent), multi: true },
   //     { provide: NG_VALIDATORS, /* useValue: validateTime ,*/  multi: true }
    ]
})
export class EhisTimeComponent implements ControlValueAccessor,OnInit {

    @Input('col-label') colWidth: number = 3;
    @Input('readonly') readonly : boolean = false;

    //The internal data model
    public innerValue: string = '';
    public confirmValue: string = '';

    validateFn: Function; //any = () => {};

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

//    public get autoCorrectedDatePipe(): any { return createAutoCorrectedDatePipe('dd/mm/yyyy'); }
    public get timeMask(): any { return [/[0-2]/, /\d/, ':', /[0-6]/, /\d/]; }


    get labelClass() {
        let w = this.colWidth;
        return `form-control-label col-md-${w} col-xs-12 text-md-right`;
    }

    get inputClass() {
        let w = 12 - this.colWidth;
        return `col-md-${w} col-xs-12`;
    }


    ngOnInit() {

        let func = function validateTime(c: FormControl) {

            let err =  {
                validateTime: {
                    valid: false,
                    message: 'time'
                }};

            if (!c.value) {
                return err;
            }

            let [hh,mm] = c.value.split(':');

            if (!((+hh) && (+mm))) {
                return err;
            }

            let str = `${hh}:${mm}`
            let dateObj = new Date('2016/01/01 '+str);
            if ((dateObj.getHours() != +hh) || (dateObj.getMinutes() != (+mm)) ) {
                console.log(hh,mm);
                return err;
            }
            return null;
        }


        this.validateFn = func;
    }

    ngOnDestroy () {
        this.validateFn = noop;
    }

/*
    ngOnChanges(changes) {
        console.log('changes');
        if (changes.confirmValue ) {
            this.validateFn = createMyValidator(this);
        }
    }
*/

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    get confirm() {
        return this.confirmValue;
    }

    set confirm(v: any) {
        if (v !== this.confirmValue) {
            this.confirmValue = v;
            this.onChangeCallback(this.innerValue);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled:boolean):void {    // new ???
    }
/*
    validate(c: FormControl) {
        return this.validateFn(c);
    }
*/

}
