import {Injectable} from '@angular/core';
import {Subject,  Observable } from 'rxjs';

@Injectable()
export class SpinnerService {
    public status: Subject <any> = new Subject<any>( ) ;
    private _active: any = {};

    public quitDialogResult = new Subject<boolean>();
    public yesDialogResult = new Subject<boolean>();

    periodDialogResult = new Subject<any>();
    selectDialogResult = new Subject<any>();

//    pivotDialogResult = new Subject<any>();
    dialogResult = new Subject<any>();


    constructor() {
        
    }

    public get active(): any {
        return this._active;
    }

    public set active(v: any) {


        if (v!= this._active) {
            this._active = v;
//
            this.status.next(v);
        }
    }

    public start(): void {
        this.active = { spinner: true};
    }

    public stop(): void {
        this.active = { spinner: false };
    }

    public showMessage(message:string,header ='Info') {


        this.active = { message: { header:header , content: message }};
    }

    public quitDialog(): Observable <boolean>  {
        this.active = { quitDialog: true }; // message: {header: 'Are you sure?', content: 'Data has changes!'} }
        return this.quitDialogResult.asObservable();
    }

    public yesDialog(header,content): Observable <boolean>  {
        this.active = { yesDialog: true , header: header, content: content} ;
        return this.yesDialogResult.asObservable();
    }

    public periodDialog(item): Observable <any>  {
        this.active = { periodDialog: true ,item: item} ;
        return this.periodDialogResult.asObservable();
    }


    public selectDialog(item): Observable <any>  {
        this.active = { selectDialog: true ,item: item} ;
        return this.selectDialogResult.asObservable();
    }

    public pivotDialog(item): Observable <any>  {
        this.active = { pivotDialog: true ,item: item} ;
        return this.dialogResult.asObservable();
    }

    public dialog(dialog,item): Observable <any>  {
        this.active = { dialog: dialog ,item: item} ;
        return this.dialogResult.asObservable();
    }


}