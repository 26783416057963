import {Component, ViewEncapsulation, ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {BaseList}       from '../../auth/base-list'

import {SessionCancellationReasonService}   from "../../session-cancellation-reason/session-cancellation-reason.service";

@Component({
  selector: 'session-cancellation-reason-popup',
  templateUrl: './session-cancellation-reason-popup.template.html',
})
export class SessionCancellationReasonPopup extends BaseList {

  constructor(el: ElementRef,protected route: ActivatedRoute,public ds: SessionCancellationReasonService) {
      super(el,ds);
  }

    @ViewChild('win') win

    paramId = 0
    callback = null

    detailId() {
        return this.paramId
    }

    _title = "Select cancellation reason"

    get title() {
        return this._title //  +(this.hasChanges() ?' *' : '');
    }

    openWin(callback) {

      //  this.item = {}
        this.callback = callback
        this.win.open()
    }

    onOk() {
        //this.saveAndBack()
        this.back()  // if selected
    }

    back() {
        this.win.close()
        if (this.callback) this.callback() // refresh ....
    }

    onCancel() {
        this.win.close()
    }

    clickItem(line) {
        this.item.session_cancellation_reason_id = line.id
    }

    item:any = {}

}
