import { Directive, ElementRef, HostListener, Input,Output ,EventEmitter } from '@angular/core';

// import 'rxjs/Rx';  // ?


import { AuthService }   from '../auth/auth.service';



@Directive({
    selector: '[ehis-img-src]'
})
export class EhisImgSrcDirective {

    private _defaultColor = 'red';
    private _defaultImgSrc = ''
    private _altImgSrc = ''

    @Output() onImageLoaded = new EventEmitter();

    constructor(private el: ElementRef,private authService:AuthService) {
    }

    @Input() set defaultColor(colorName: string){
        this._defaultColor = colorName || this._defaultColor;
    }

    @Input() set defaultImgSrc(url: string){
        this._defaultImgSrc = url;
    }

    @Input('alt-img-src') set altImgSrc(url: string){
        this._altImgSrc = url;
    }


    @Input('ehis-img-src') set ehis_img_src(value:string) {

       // map value ...
        if (value) {

            var i = value.lastIndexOf('/');
            if (i < 0) {
                i = value.lastIndexOf('\\');
            }

            let url = this.authService.getApiUrl() + '/api/image/' + value.slice(i + 1);

            this.authService.getBlob(url)
                .subscribe(
                (result)=> {
                    var blob = result // .blob();

                    if (blob.size==0) {
                        this.el.nativeElement.src = this._altImgSrc;
                        this.onImageLoaded.emit('');
                    }
                    else {
                        var urlCreator = window.URL;//|| window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL(blob);

                        this.el.nativeElement.src = imageUrl;
                        this.onImageLoaded.emit(imageUrl);
                    }//console.log('emit',this.onImageLoaded);
                },
                (error)=> {
                    this.el.nativeElement.src = this._altImgSrc;
                    this.onImageLoaded.emit('');
                })
        }
        else {
            this.el.nativeElement.src = this._defaultImgSrc; // '';
        }
    }


}
