import {Component, Input} from '@angular/core';

//import {AuthService} from "../../auth/auth.service";

//import * as moment from 'moment';
import {BaseForm} from "../../auth/base-form";

//import {periodStr} from "../../auth/period-str"

@Component({
  selector: 'pivot-select',
  templateUrl: 'pivot-select.component.html',
})
export class PivotSelect { // extends BaseForm{

  @Input('data') data:any = {}

  measure_name(measure) {
      return 'mes'+ measure
  }

  dict(item) {
      if (item in this.data.dict) {
          return this.data.dict[item]
      }
      return item
  }



  clickRow(dimension) {
        console.log(dimension,this.data.row)
    }
    clickCol(dimension) {
        console.log(dimension,this.data.row)
    }
    clickMes(measure) {
        console.log(measure,this.data.values)
    }
}
