import { Component } from '@angular/core';
import {Router,  ActivatedRouteSnapshot,ActivatedRoute} from "@angular/router";


//import {AuthService, THIS_MARKET_ID} from "../auth.service"
import {AuthService} from "../auth.service"

@Component({
  selector: 'signup',
  templateUrl: './signup.template.html'
})
export class SignupComponent {

  email: string = '';
  password: string = '';
  password_confirm = '';
  message: string ='';

  constructor(private route: ActivatedRoute,private authService: AuthService,) {}


  handleError(err) {
    this.authService.showError(err);
  }

  handleResult(result) {
        this.authService.showMessage(result.message);
        this.authService.home(); // navigate('/');
  }



    signup() {

        let item = {
            'role_id': 1,
            'email': this.email,
            'password': this.password,
            'password_confirm': this.password_confirm,
        } //'market_id': THIS_MARKET_ID        }

        this.authService.spinnerStart();

     this.authService.register(item).subscribe(

          (result) => { this.authService.spinnerStop();

              if (result.error) {
                  this.handleError(result.error);
              }
              else {
                  this.handleResult(result);
              }
            },
          (error) => { this.authService.spinnerStop();
            this.handleError(error)
         });
  }
/*
  ngOnInit() {

   // jQuery('.parsleyjs').parsley();


    let lastUrlSegment = this.route.snapshot.url.slice(-1)[0]; // path();
    let path = lastUrlSegment.path;

    if (path == "logout") {
      this.authService.logout();
    }
    else {
      let email = this.route.snapshot.params['email'];

      if (email) {
        this.email = email;
        this.authService.checkRegister(email)
            .subscribe(
                result => {
                    if (result.error) {
                        this.message = result.error;
                    }
                    else {
                        this.message = result.message
                    }},
                error =>  {
                  this.handleError(error)
                }
            );
      }
    }
  }

    handleError(err) {
        this.authService.showError(err);
    }

    handleResult(result) {
        this.authService.showMessage(result.message);
        this.authService.home();
    }

    handle(Ob: Observable<any>,spinner:boolean= true) {
        if (spinner) { this.authService.spinnerStart() }
        Ob.subscribe(
            result => { this.authService.spinnerStop();
                if (result.error) {
                    this.handleError(result.error);
                }
                else {
                    this.handleResult(result);
                }
            },
            error  => { this.authService.spinnerStop();
                this.handleError(error)
            }
        );
    }

*/

}
