import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
//import { ReactiveFormsModule } from '@angular/forms';


//import { TypeaheadModule,TypeaheadDirective } from 'ngx-bootstrap/typeahead';

//import {TextMaskModule}       from 'angular2-text-mask';
//import {MaskedInputDirective} from "angular2-text-mask/src/angular2TextMask"

import {EhisAreaComponent} from "./ehis-area.component"
//--------------------------------------------------------------------------------------------

//import {EhisSuggestProvinceComponent} from "./ehis-suggest-province.component"

//import {EhisTimeComponent} from "./ehis-time.component"

@NgModule({
    imports: [
        FormsModule,
//        ReactiveFormsModule,
        CommonModule,

//        TypeaheadModule,
//        TextMaskModule,
    ],
    declarations: [
//        EhisSuggestProvinceComponent,
//        EhisTimeComponent,

        EhisAreaComponent,
    ],
    exports: [
       // TypeaheadDirective,
      //  MaskedInputDirective,

//        EhisSuggestProvinceComponent,
//        EhisTimeComponent,

        EhisAreaComponent
    ]
})
export class ControlInhModule {
}

