import { Component, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
declare var jQuery: any;


import {ChartService}   from '../chart.service'

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'chart-list',
  templateUrl: './chart-list.template.html',
/*
  styleUrls: [ './chart-list.style.scss' ],
  providers: [ChartService],

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
*/
})
export class ChartList extends BaseList {


  constructor(el: ElementRef,private chartService: ChartService) {

    super(el,chartService);

 //   this.paramItems.user_id = chartService.authService.user_id();

  }

  user_id = 0;

    loadKeys() {  // override
       super.loadKeys()
       this.paramItems.state= 1; // draft only
    }

        onReadItems(result) {
    this.user_id = result.user_id;

    for (let elem of result.data) {
      if (elem.task && elem.task.length > 0) {
        elem.task = elem.task[0].id;
      }
      else {
        elem.task = ""
      }

    }

  }

  get title() {
    return this.isAdmin ?  'Chart notes' : 'My chart notes'
  }

  addLink() {
     return ['chartwiz'];
  }

  visit_dt(item) {
    return (item.amended)? item.amend_visit_datetime: item.visit_datetime;
  }

//   isAuthor() {
   //  return this.baseService.authService.isUser(this.item.user_id);

//   }

  clickItem(item) {  // override
    //console.log(item)
    if (!this.isAdmin) {
      if (!this.baseService.authService.isUser(item.user_id)) {
        if (item.doc_state == "draft") {
          this.baseService.authService.showError('The record is draft.')
          return
        }
        if (item.doc_state == "locked") {
          this.baseService.authService.showError('The record is locked.')
          return
        }

      }
    }
    super.clickItem(item)
  }

}
