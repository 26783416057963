import {Component, Input, Output, EventEmitter, ContentChild, TemplateRef} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {DynamicFormControlComponent, DynamicFormControlModel,DynamicFormArrayModel,DynamicCheckboxModel,DynamicInputModel,DynamicNumberModel} from "../../sp-dynamic-forms/core";

//import createNumberMask from 'text-mask-addons/dist/createNumberMask.js';
//import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe.js';

export const DYNAMIC_FORM_UI_EHIS_LINE = "EHIS_LINE";

//import {DynamicFileModel} from "./dynamic-file.model";

//import {API_URL} from '../../app.env';

import {AuthService} from '../../auth/auth.service';

@Component({

//    moduleId: module.id,
    selector: "dynamic-line-ehis-control",
    templateUrl: "./dynamic-line-ehis.template.html",
//    styleUrls: [ './dynamic-line-ehis.style.scss' ],
})

export class DynamicLineEhisComponent extends DynamicFormControlComponent {

    @Input() bindId: boolean = true;
    @Input() controlGroup: FormGroup;
    @Input() hasErrorMessaging: boolean = false;
    @Input() model: DynamicFormControlModel;
    @Input() nestedTemplate: TemplateRef<any>;

    @Input() builder:boolean = false;
//    @Output() remove: EventEmitter<DynamicFormControlModel> = new EventEmitter<DynamicFormControlModel>();

    @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
    @Output() change: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

    @ContentChild(TemplateRef) customTemplate;

    @Input() table: DynamicFormArrayModel ;//DynamicFormControlModel;
    @Input() row: number = 0;
    @Input() col: number =0;
    @Input() dataParsleyExcluded:boolean = true;

    @Input() readonly: boolean = false;

    readonly type: string = DYNAMIC_FORM_UI_EHIS_LINE;


    _readonly() {
        if (this.builder) {
            return false;
        }

        if (this.readonly) return true;

        if (this.row < 1) return true; // first row - title
        if (this.col < this.table.fixedColumns) return true; // fixed column
        return false;
    }

    constructor(private authService:AuthService) {

        super();

    }

    uploadOptions() {
        return this.authService.uploadOptions();
    }




}