import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Output, Input } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {BaseList} from '../../auth/base-list';
import {NewsletterService} from '../../service/newsletter.service';

//import mydownload from 'downloadjs/mydownload.js';
import mydownload02 from 'assets/downloadjs/mydownload02.js';

@Component({
  selector: 'mail-list',
  templateUrl: './mail-list.template.html',

//  providers: [NewsletterService],

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class MailList extends BaseList {

    get icon() { return "fa fa-envelope-o" }

    radio1: string = 'New';
    radio2: string = 'Subscribed';


    $el:any;

    constructor(el:ElementRef, private newsletterService:NewsletterService) {

        super(el,newsletterService);
        this.$el = jQuery(el.nativeElement);

        this.paramItems.is_new     = 1;
       // this.paramItems.subscribed = 1;
    }

    filterName() {
        /*
        var text = '';
        switch (this.radio1) {
            case '0': { text = 'all'; break }
            case '1': { text = 'new'; break }
        }
        switch (this.radio2) {
            case '1': { text = text + ' subscribed'; break }
            case '2': { text = text + ' unsubscribed'; break }
        }
        */
        return (this.radio1 + ' '+ this.radio2).toLowerCase();
    }


    clickRadio1(val:string) {

        if (val != this.radio1) {

            this.radio1 = val;

            switch (val) {
                case 'All':
                {
                    delete this.paramItems.is_new;
                    break;
                }
                case 'New':
                {
                    this.paramItems.is_new = 1;
                    break;
                }
                default:
                {

                }
            }
            this.updateSearchControl();
        }
    }

    clickRadio2(val:string) {

        if (val != this.radio2) {

            this.radio2 = val;

            switch (val) {
                case 'Subscribed':
                {
                    this.paramItems.subscribed = 1;
                    break;
                }
                case 'Unsubscribed':
                {
                    this.paramItems.subscribed = -1;
                    break;
                }
                default:
                {

                }
            }
            this.updateSearchControl();
        }
    }


    clickItem(item: any): void {
     // this.newsletterService.authService.toDetail(['app','user',item.user_id]);
        let link = ['/user',item.user_id];
        this.baseService.authService.navigate(link, null);
    }


    onReadItems(result) {
       delete this.paramItems.download;

/*
       for (let elem of result.data) {
          elem.subscribed = this.newsletterService.authService.sqlToBoolean(elem.subscribed);
       }
*/

       if (result._download) {
           let lst =  new Blob([result._download.join("\r\n")],{ type: 'text/plain' });
           console.log('mydownload02');
           mydownload02(lst,result._download_file + '.txt',"text/plain");
       }
    }

    downloadClick() {

        this.paramItems.download = 1;
        this.updateSearchControl();
    }

}
