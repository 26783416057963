import {Component, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AddressService} from "../../address/address.service";
import {FormBuilder} from "@angular/forms";
import {ProfessionalService} from "../../professional/professional.service";


import * as moment from 'moment';
declare var $;

import {normalizeTime,isNormalTime} from "./time-select.directive";

@Component({

    selector: 'week-hours',
    templateUrl: './week-hours.component.html',
})
export class WeekHours {

    @Input('data') data: any[] = [];

    _days = 7

    @Input('days') set days(value) {
        this._days = value
        this.initData()
    };
    get days() {
        return this._days
    }

    dayNames = ['Sunday', 'Monday', 'Tuesday','Wednesday','Thursday','Friday','Saturday']

    day_name(item) {
        return this.dayNames[+item.day]
    }

    constructor(protected el:ElementRef) {

        /*
        this.data = [
            { day: 0 , business_day: 0 , rowspan: 2 , start: '9:00', end: '13:00'},
            { day: 0 , business_day: 0 , rowspan: 0},
            { day: 1,  business_day: 1, rowspan: 1},
            { day: 2,  business_day: 1, rowspan: 2},
            { day: 2,  business_day: 1, rowspan: 0},

        ]
        */
        this.initData()
    }

    check(item) {
        let business_day = !item.business_day  // ? 0 : 1
        this.data.forEach( elem => {
            if (elem.day == item.day) {
                elem.business_day = business_day
            }
        })
    }

    itemClass(item) {
        return item.rowspan ? 'border' : ''
    }

    addTime(idx) {

        let item = Object.assign({},this.data[idx])
        item.start = ''
        item.end = ''
        item.duration = ''

        this.data.splice(idx+1,0,item)
        this.updateRowspan()
    }

    delTime(idx) {
        this.data.splice(idx, 1)
        this.updateRowspan()
    }

    updateRowspan() {
        let pos = null
        for(let item of this.data) {
            if (pos && (item.day == pos.day)) {
                item.rowspan = 0
                pos.rowspan = pos.rowspan+1
            }
            else {
                pos = item
                pos.rowspan = 1
            }
        }

    }

    unionIntervals() {
        let pos = null
        let d = []

        for(let item of this.data) {
            if (pos && (item.day == pos.day)) {
                if (pos.start && pos.end && item.start && item.end) {
                    if (pos.end >= item.start) {
                        pos.end = item.end
                        d.push(item)
                      //  var index = this.data.indexOf(item);
                      //  if (index > -1) {
                      //      this.data.splice(index, 1);
                      //  }
                   }
                   else {
                      // d.push(item)
                    }
                }
                else {
                  //  d.push(item)
                }

            }
            else {
            //    d.push(item)
                pos = item
            }
        }

       // this.data = d
       // inplace remove
        for(let item of d) {
              var index = this.data.indexOf(item);
              if (index > -1) {
                  this.data.splice(index, 1);
              }

        }



    }


    flash() {
        this.initData()
  }

    private dateFromTimeStr(s) {

        if (!s) return null
        if (!isNormalTime(s)) return null;

        let hh_mm = s.split(':')
        let hh = +hh_mm[0]
        let mm = hh_mm.length > 1 ? +hh_mm[1] :0

        let mins = hh*60+mm + 1000

        return mins
/*
        let diff = new Date(0,0, 0, hh,mm )
        return moment().startOf('day').add(hh,'h').add(mm,'m' )
        */
    }

    calculateDurationFromStartEnd(start,end) {

        let start_date: any = this.dateFromTimeStr(start)
        if (!start_date) return ''
        let end_date:any = this.dateFromTimeStr(end)
        if (!end_date) return ''


        let d = end_date-start_date
        if (d <=0) return ''

        let mm = d % 60
        let hh = (d - mm*60) / 60

        let arr = []
        if (hh) {
            arr.push(hh + 'h')
        }
        if ( mm ) {
            arr.push(mm +   'min')
        }
        return arr.join(' ')
        /*

        let d = moment(end_date.diff(start_date)).utc()

        let arr = []

        if (d.hour() != 0 ) {
            arr.push(d.hour() + 'h')
        }
        if ( d.minute() != 0 ) {
            arr.push(d.minute() +   'min')
        }
        return arr.join(' ')
        */
    }


    timeSelected($event,item) {

        item.duration = this.calculateDurationFromStartEnd(item.start,item.end)
    }

    removeElems() {
       // this.data = this.data.filter(elem=>{ return elem.duration })

        let data =         this.data.filter((elem, index, data)=> {

            if ((!elem.duration) || (elem.day >= this.days) || (elem.day < 0)) {
                return false; // data.splice(index, 1);
            }
            else {
                return true
            }
        })

        this.data = data;

        /*
        this.data.forEach((elem, index, data)=> {

            if ((!elem.duration) || (elem.day >= this.days) || (elem.day < 0)) {
                data.splice(index, 1);
            }
        })
        */
    }

    initData() {
        this.data.forEach(elem=>{

            elem.start = isNormalTime(elem.start) ? normalizeTime(elem.start) : ''
            elem.end = isNormalTime(elem.end) ? normalizeTime(elem.end) : ''
            elem.duration = this.calculateDurationFromStartEnd(elem.start,elem.end)
        })
        this.removeElems()

        this.data.forEach((elem, index, data)=>{

           if (elem.start > elem.end) {
                let s = elem.start
                elem.start = elem.end
                elem.end = s
                elem.duration = this.calculateDurationFromStartEnd(elem.start,elem.end)
            }
        })
        this.removeElems()

        for (let i=0;i<this.days;i++) {
            if (!this.data.find(elem=>{return elem.day==i})) {
                this.data.push({day: i, business_day: false ,start:'',end: '', duration:''})
            }
        }

        this.data.sort((a,b)=>{
            var d = a.day - b.day
            if (d) return d
            return a.start > b.start ? 1 :(a.start < b.start ? -1: 0);
        })

        // intersect
        this.unionIntervals()



        this.updateRowspan()
   }

   get totalTime() {
       let hres = 0
       let mres = 0
       this.data.forEach(elem=>{
           if (elem.business_day && elem.duration) {
               let a = elem.duration.split('h')
               if (a.length == 1) {
                   let m = +(a[0].replace(/\D/g,''))

                   let t = mres+ m
                   mres = t % 60
                   hres = hres + (t - t % 60) / 60;

               }
               else  {
                   let h = +(a[0].trim())
                   hres = hres + h

                   let m = +(a[1].replace(/\D/g,''))

                   let t = mres+ m
                   mres = t % 60
                   hres = hres + (t - t % 60) / 60;

               }
           }
        })

       let arr = []
       if (hres) arr.push(hres+'h')
       if (mres) arr.push(mres+'min')

       return arr.join(' ')
   }


    minHour() {

        let d = this.data

        let hmin = null
        let hmax = null;
        d.forEach(item=>{
            if (item.business_day) {
                var h = +(item.start.split(':')[0])
                if (h) {
                    hmin = hmin ? Math.min(hmin, h) : h
                    hmax = hmax ? Math.max(hmax, h) : h
                }
                h = +(item.end.split(':')[0])
                if (h) {
                    hmin = hmin ? Math.min(hmin, h) : h
                    hmax = hmax ? Math.max(hmax, h) : h
                }
            }
        })
        hmin = hmin ? Math.max(Math.min(hmin,24),0) : 0
        hmax = hmax ? Math.max(Math.min(hmax,24),0) : 24

        return hmin
    }

    maxHour() {

        let d = this.data

        let hmax = null;
        d.forEach(item=>{
            if (item.business_day) {
                var a = item.start.split(':')
                var h = +(a[0])
                if (a.length>1 && (+a[1])) h = h+1
                if (h) {
                    hmax = hmax ? Math.max(hmax, h) : h
                }
                a = item.end.split(':')
                h = +(a[0])
                if (a.length>1 && (+a[1])) h = h+1
                if (h) {
                    hmax = hmax ? Math.max(hmax, h) : h
                }
            }
        })
        hmax = hmax ? Math.max(Math.min(hmax,24),0) : 24

        return hmax
    }


    set item(value) {

       this.data = (value) ? value :[]
       this.initData()
   }

   get item() {
        this.initData()
        return this.data

   }

   public init(value) {
       this.item = value
       return this.data
   }


   @Input('disabled') disabled = false

    isdisabled() {
        return this.disabled ? true : undefined
    }

    get isStd() {

        let isEmpty = this.item.reduce((acc,elem)=>{ return acc && elem.business_day==false},true);
        if (isEmpty) {
            return true
        }

        let isWorkDay = this.item.reduce((acc,elem)=>{ return acc && (
            ((elem.day==0  || elem.day == 6) && elem.business_day==false) ||
            ( elem.day!=0  && elem.day!=6  && elem.business_day==true))},true);
        if (!isWorkDay) {
            return false
        }

        let start = this.item[1].start
        let end = this.item[1].end

        let isTheSamePeriod = this.item.reduce((acc,elem)=>{ return acc && (elem.day==0 || elem.day==6 ||
            (elem.start==start && elem.end==end))},true);
        if (!isTheSamePeriod) {
            return false
        }

        return true

    }

    setStd(start,end) {

        let data = []
        data.push({day:0 , business_day:false})
        for (let i = 1; i<6; i++) {
            data.push({day:i, business_day:true, start:start,end:end})
        }

        data.push({day:6 , business_day:false})

        this.data = data;
        this.initData()
    }

}

