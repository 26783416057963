import { WHOIS_API_MARKET_ID } from "../../auth/auth.service";
/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

declare var $: any;

import {
  Component,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

declare var jQuery: any;

import { AuthService } from "../../auth/auth.module";
import { BaseItem } from "../../auth/base-item";

import { UserService } from "../../service/user.service";

@Component({
  selector: "user-detail",
  templateUrl: "./user-detail.template.html",
  //    providers: [UserService],

  encapsulation: ViewEncapsulation.None,
})
export class UserDetail extends BaseItem {
  //   profile = false;
  occupations = [];
  supervisorData: any;
  isDisable: boolean;
  constructor(
    el: ElementRef,
    protected route: ActivatedRoute,
    protected userService: UserService
  ) {
    super(el, route, userService);

    // loadKeys override
    if (!this.profile) {
      this.tab = 0;
    }
    this.userService.authService
      .get(this.userService.authService.getApiUrl() + "/api/register/0")
      .subscribe((result) => {
        this.occupations = [...result._occupation];
      });
    
    let user = JSON.parse(sessionStorage.getItem('user'));
    let params = 'role_id=0&_clinic_id='+user.clinic_id;
    this.userService.authService
      .get(this.userService.authService.getApiUrl() + "/api/user?"+`${params}`)
      .subscribe((allClinicUsers) => {
        this.supervisorData = allClinicUsers.data;
      });
    if (this.route.snapshot.paramMap.get("id") !== "0") {
      const userId = this.route.snapshot.paramMap.get("id");
      this.userService.authService
        .get(
          this.userService.authService.getApiUrl() + "/api/user/" + `${userId}`
        )
        .subscribe((userData) => {
          if (userData.role.indexOf("5") != -1) { // check suppervisor id
            this.isDisable = true;
          } else {
            this.isDisable = false;
          }
        });
    }
  }

  invitation() {
    this.handle(this.userService.invitation(this.item.email, 1), false);
  }

  clickSave() {
    console.log("clickSave");
  }

  validClick() {
    console.log("validClick");
  }

  isClientDetail() {
    return this.item.role_id == 8;
  }

  // tags .....................................................

  _tag: any[] = [];

  onReadItem(result) {
    /*
        if (this._tag.length == 0 && result._tag) {
            this._tag = result._tag;

            let $el = $('#tags');
            for (let opt of this._tag) {
                var option = new Option(opt.name, opt.id, false, false);
                $el.append(option);
            }

        }
        let str = result.tag;
            let arr = str ? str.split(',').filter((e) => {return +e}) : [];
            $('#tags').val(arr).trigger('change');

        if (result.id) {
            let flt = { user_id: result.id};
            this.updateLocStorage('-table-subscription',flt)
            this.updateLocStorage('-table-payment',flt);
            this.updateLocStorage('-table-invoice',flt);
            this.updateLocStorage('-table-shipping',flt);

        }
*/
  }

  password = "";
  password_confirm = "";

  onWriteItem(result) {
    if (!result.id) {
      result.password = this.password;
      result.password_confirm = this.password_confirm;
    }
  }

  selectTag($event) {
    let v = $("#tags").select2("val");
    let arr = v ? v : [];

    arr.sort(function (a, b) {
      return +a - b;
    });

    this.item.tag = arr ? arr.join(",") : "";

    //console.log('tags',arr);
  }

  canAddWhoisApiCredits() {
    return +this.item.market_id == WHOIS_API_MARKET_ID; // ~ this user for the product whois api
  }

  //  Headers editor //////////////////////////////////////////////////////////

  @ViewChild("bill_to_dlg") bill_to_dlg; //: any | ElementRef;
  _bill_to = "";
  _bill_to_mode = 0;
  //    _column2 ; // = '';

  close_bill_to_dlg() {
    this.clearModalDlg(this.bill_to_dlg);
  }

  open_bill_to_dlg() {
    this._bill_to = this.item.bill_to;
    this.bill_to_dlg.open();
  }

  clearModalDlg(dlg) {
    let $form = $(dlg.modalRoot.nativeElement).find("form");
    $form.validate().resetForm();

    $("[name]", $form).each(function () {
      $(this).parent().removeClass("state-success"); // label smart-form
    });
  }

  ok_bill_to_dlg() {
    this.baseService.authService.spinnerStart();
    this.userService
      .post_bill_to({
        user_id: this.item.id,
        bill_to: this._bill_to,
        mode: this._bill_to_mode,
      })
      .subscribe(
        (result) => {
          this.baseService.authService.spinnerStop();
          this.refresh();
        },
        (error) => {
          this.baseService.authService.spinnerStop();
          this.baseService.authService.showError(error);
        }
      );
  }

  //   routerData = null;

  call(link) {
    let data = {
      data: 1,
      item: this.parent.item,
      savedItem: this.parent.savedItem,
    };
    this.baseService.authService.routeData = this.routerData;
  }

  selectedRole() {
    if (this.item.role.indexOf("5") != -1) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }

  get title() {
    return this.profile ? "My profile" : "Clinician";
  }

  getSaveButton() {
    //  console.log(this.isAdmin,this.profile)
    return this.isAdmin || this.profile ? "Save" : "";
  }

  freeMembership() {
    return this.item.membership || !this.isLoaded;
  }

  get acl() {
    return null;
  }
}
