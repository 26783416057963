
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class SubscriptionService extends BaseService{

    // utc = [ 'date_start'];
    icon = 'fa fa-table';

    constructor(public authService:AuthService){ super(authService,"/api/subscription");}


    addWhoisCredits(addedItem) {


        let url:string = `${this.baseURL}/add`;
        //console.log(url)

        return this.authService.post(url,addedItem).pipe(
                map(result=>{

                    return result;
                }));


    }

}