import { Component, ViewEncapsulation } from '@angular/core';
//import { AppConfig } from '../app.config';


import { environment } from '../../environments/environment';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.template.html',
  styleUrls: ['./dashboard.style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Dashboard {
  config: any;
  month: any;
  year: any;

   //legalInformationURL = environment.API_URL+'/legal_links';

    legalInformationURL =  "http://www.electronic-health-information-system.ca/legal-links/";
/*
  constructor(config: AppConfig) {
    this.config = config.getConfig();
  }

  ngOnInit(): void {
    let now = new Date();
    this.month = now.getMonth() + 1;
    this.year = now.getFullYear();
  }
  */
}
