/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, Output, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter } from '@angular/core';

import { NgZone } from '@angular/core';

import {UserService}        from '../../service/user.service';

import {BaseDetail}  from '../../auth/base-detail';

//import {ModalComponent } from '../../auth/modal-window/modal.component';


declare var jQuery: any;

//import {API_URL} from '../../app.env';    // photo

@Component({
    selector: 'user-info',
    templateUrl: './user-info.template.html',

})
export class UserInfo extends BaseDetail {

    @ViewChild('formInfo') formInfo;

    @Output('onPersonalMessages') onPersonalMessages = new EventEmitter();

    user_id = -1;

    constructor(el: ElementRef,protected route: ActivatedRoute,protected userService: UserService) {
        super(el,route,userService);
        this.parsley = false;
    }

    onReadItem(result) {

        result.provinceName = this.lookupName(result._province, result.province_id);
    }



    handleRead(result) { // override
        super.handleRead(result);
        //console.log(result);
        this.formInfo.open();
    }

    detailId() {   // override
        return this.user_id;
    }


    open(user_id) {
        this.user_id = user_id;
        this.getDetail();
        //console.log('user',user_id);
    }

    formInfoOnClose() {
        this.user_id = -1;
        this.item = {};
    }

    pm() {
        let item = this.item;
        let user = Object.assign({},{item});

        this.formInfo.close();
        this.onPersonalMessages.emit(user);
//        this.baseService.authService.navigate(['/app','message',{user_id:id}]);
    }

}
