import {Injectable} from '@angular/core';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'  //country.service

import {AuthService} from '../auth/auth.service';
import {BaseService} from '../auth/base.service'

@Injectable()
export class RegisterService extends BaseService {

    constructor(public authService:AuthService) {
        super( authService, "/api/register");
      //  this.booleans=['subscribe'];
        //this.dates = ['dob'];
        this.booleans = ['approved','subscribe','email_notification'];
        this.dates = ['dob'];

    }

}