import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {

  /**
   * Number.prototype.format(n, x, s, c)
   *
   * @param integer n: length of decimal
   * @param integer x: length of whole part
   * @param mixed   s: sections delimiter
   * @param mixed   c: decimal delimiter

   number_format = function(n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = this.toFixed(Math.max(0, ~~n));

            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        };

   //12345678.9.format(2, 3, '.', ',');  // "12.345.678,90"
   //123456.789.format(4, 4, ' ', ':');  // "12 3456:7890"
   //12345678.9.format(0, 3, '-');       // "12-345-679"
   */

  number_format = function(num,n, x, s, c) {  // try http://numeraljs.com
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        str = num.toFixed(Math.max(0, ~~n));

    return (c ? str.replace('.', c) : str).replace(new RegExp(re, 'g'), '$&' + (s || ','));
  };


  transform(value: any, decimals: any = 0,zero:any= false): any {
    //return moment(value).format(format);

      //* @param integer n: length of decimal
      //* @param integer x: length of whole part
      //* @param mixed   s: sections delimiter
      //* @param mixed   c: decimal delimiter


      return  +value ? this.number_format(+value,decimals,3,' ',''): (zero? 0 : '');

  }

}
