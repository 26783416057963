import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';

import {BaseList} from '../../auth/base-list';

import {InvoiceService} from "../invoice.service";

@Component({
  selector: 'invoice-table',
  templateUrl: './invoice-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class InvoiceTable extends BaseList {

    constructor(el:ElementRef, private invoiceService:InvoiceService) {

        super(el,invoiceService);

    }

    @Input('title') title = 'Invoices';
//    @Input('widget_name') widget_name= 'invoice-table';

   downloadLink(item) {
        let url= this.baseService.baseURL+'/download/'+item.id;
        return url;
    }
   /*
    invoiceColor(item) {
       return +item.paid == 0  ? 'text-color-danger': '#009900';

    }
    */
    notPaid(item) {
        //return item.state == 'Not paid' || item.state == 'Expired' || item.state =="Waiting for manual payment";
        return item.alert
    }

}
