import { Injectable } from '@angular/core';
// import { BillingModule } from './billing.module';
import {AuthService} from '../auth/auth.service';
import {BaseService} from '../auth/base.service'
import {PdfService} from '../service/pdf.service';

@Injectable()
export class BillingServiceService  extends BaseService{

  // icon = "fa fa-hospital-o"
  constructor(public authService:AuthService) { super( authService, "/api/service",null, true); }

}

@Injectable()
export class InvoicingService  extends BaseService {

  // icon = "fa fa-hospital-o"
  constructor(public authService:AuthService, private pdfService: PdfService) { super( authService, "/api/invoice",null, true); }
  getStatus(statusId) {
    switch(statusId) {
      case 0:
        return "Created"
      case 1:
        return "Sent for Payment"
      case 2:
        return "Paid" 
      case 3:
        return "Third Party Pending"
      case 4:
        return "Third Party Acknowledged"
      case 5:
        return "Third Party Paid"
      case 6:
        return "Deleted"   
    }
  }

  getStatusList(omit_delete = false) {
    let statuses = [
      {"id": 0, name: "Created"},
      {"id": 1, name: "Sent for Payment"},
      {"id": 2, name: "Paid"},
      {"id": 3, name: "Third Party Pending"},
      {"id": 4, name: "Third Party Acknowledged"},
      {"id": 5, name: "Third Party Paid"},
    ]
    if (!omit_delete) {
      statuses.push({"id": 6, name: "Deleted"})
    }
    return statuses
  }
  makeInvoicePdf(invoiceData) {
    this.pdfService.open(invoiceData)
  }

  public showError(err: String) {
    this.authService.spinnerStop()
    this.authService.showError(err)
  }
}

@Injectable()
export class ClinicService extends BaseService {
    constructor(public authService:AuthService) { super( authService, "/api/clinic"); }

    public clinicDetails:any = undefined;

    public loadClinicDetails(callback?:Function, callback2?:Function) {
      this.loading = true;
    // get current clinic details
    this.authService.spinnerStart()
    this.getItems(null, null, '/profile').subscribe(data => {
      this.getItems(null, { 'filter': data.name }, null).subscribe(response => {
        this.clinicDetails = response.data[0];
        if (callback != undefined) {
          callback()
        } else {
          this.authService.spinnerStop()
        }
      }, error =>  this.showError("error occured while retriving clinic details"))
    }, error => this.showError("error occured while retriving clinic details"))
    }

    public showError(err: String) {
      this.authService.spinnerStop()
      this.authService.showError(err)
    }

}
@Injectable()
export class EmailService extends BaseService {
  constructor(public authService:AuthService, private pdfService: PdfService) { super( authService, "/api/email",null, true); }

  creatBlobPdf(dd) {
    this.pdfService.createPdfAsBlob(dd);
  }
  sendEmailData(params) {
    return this.post(params);
  }
}