import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'list-select-header',
    template: `
         <!--       <header>  -->
         <!--
                    <span class="widget-icon"> <i class="fa fa-ship"></i> </span>
                    -->
                    <!--
                    <h3 class="widget-title">
                        <div style="display:inline-block">
                        <strong> Credits</strong> </div>
                        <div style="display:inline-block;width:40%;text-align: center;">
                            {{compPeriodStr()}}</div>
                    </h3>
                    -->
                    <h3 class="widget-title">
                            <strong style="margin-right: 1.75rem;"><ng-content></ng-content>
                        <!--    <span *ngIf="compPeriodStr()!=''">:</span> -->
                            </strong>
                    </h3>

                        <div *ngIf="hasPeriod()" class="widget-toolbar no-border pull-left">
                            <a (click)="data.selectPeriod()" class="btn btn-default"><i class="fa fa-calendar"></i></a>
                        </div>


<!--
                        <a (click)="selectPeriod()" class="btn btn-default"><i class="fa fa-calendar"></i></a>
-->
                    <h3 *ngIf="hasPeriod()" class="widget-title">
                        <span  style="cursor:pointer;margin-right:0.75em;" (click)="data.selectPeriod()">{{data.compPeriodStr()}}</span>
                        <!--
                                <i *ngIf="data.compPeriodStr()!=''" class="fa fa-times"
                                   style="cursor:pointer;color: #719cc1;border: 1px solid;border-radius: 3px;
                                    background-color: white;line-height:12px;width:14px;"
                                   (click)="data.clearPeriod($event)">

                                </i>
                                -->
                    </h3>


         <div *ngIf="refresh" class="widget-toolbar">
          <!--   <a *ngIf="data.print" (click)="data.print()" class="btn btn-default"><i class="fa fa-print"></i></a>  -->
             <a (click)="data.refresh()" class="btn btn-default"><i class="fa fa-refresh"></i></a>
                    </div>
         
         <div *ngIf="data.print" class="widget-toolbar">
             <a (click)="data.print()" class="btn btn-default"><i class="fa fa-print"></i></a>
         </div>
         

  `,
})
export class ListSelectHeaderComponent {

    @Input('data') data;
    @Input('icon') icon = 'fa fa-edit';
    @Input('refresh') refresh = true;

    get iconClass() {
        return this.icon
    }


    hasPeriod() {
       return this.data._period.length > 0;
    }
}

