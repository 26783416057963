import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';

import {BaseList} from '../../auth/base-list';
import {SubscriptionService} from "../subscription.service";

@Component({
  selector: 'subscription-table',
  templateUrl: './subscription-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionTable extends BaseList {

    @Input('title') title = 'Subscriptions';

    constructor( el:ElementRef,private subscriptionService:SubscriptionService) {
        super(el,subscriptionService);
    }

}
