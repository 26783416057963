import { Component, ElementRef ,ViewEncapsulation } from '@angular/core';

import {BaseList} from '../../auth/base-list';
import {PaymentService} from "../payment.service";

@Component({
  selector: 'payment-table',
  templateUrl: './payment-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class PaymentTable extends BaseList {


    constructor(el: ElementRef,private paymentService:PaymentService) {

        super(el,paymentService);
   }

    onReadItems(result) {

        for (let elem of result.data) {
            elem.created_at = this.baseService.authService.toLocalTime(elem.created_at);

            elem.amount = this.baseService.authService.currencyFmt(elem.amount);
        }
    }
}
