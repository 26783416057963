import {Observable, BehaviorSubject, Subject} from "rxjs";


//import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

//import {ParsleyForm} from './parsley-form'
import {BaseService} from './base.service';
import {BaseForm} from "./base-form"
import {ElementRef} from "@angular/core";

declare var $: any;

//import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe.js';
//import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore'

import { environment } from '../../environments/environment';


export class BaseItem extends BaseForm {

    /* protected */
    item: any = {};
    protected error: any = '';
    /*protected*/
    public isLoaded: boolean = false;
    public paramItems: any = {};

    private _busy: boolean = false;


    protected savedItem: string = ''; // any = {};
    protected noChanges: boolean = false;

//    public readAnnounced: Subject<any> = new Subject(); //BehaviorSubject<any> = new BehaviorSubject(null);
    public readAnnounced: BehaviorSubject<any> = new BehaviorSubject(null);


    constructor(el:ElementRef,protected route: ActivatedRoute, protected baseService: BaseService) {
        super(el);

        this.loadKeys();



        //      this.readAnnounced = new BehaviorSubject({});

//        this.savedItem = this.itemToSaveStr();
    }


    canDeactivate() {
        return true;
    }

    get busy(): boolean {
        return this._busy;
    }

    set busy(val: boolean) {
        this._busy = val;
    }

    lookupName(arr, id) {
        return this.baseService.lookupName(arr, id);
    }

    backLink() {
        //if (this.item.id){ return ['../', {id: this.item.id}] }
        return ['../'];
    }

    onBack() {
        this.baseService.authService.router.navigate(this.backLink(), {relativeTo: this.route});
        /*
         if (this.item.id) {
         this.baseService.authService.router.navigate(['../', {id: this.item.id}], {relativeTo: this.route});
         }
         else {
         this.baseService.authService.router.navigate(['../'], {relativeTo: this.route});
         }*/
    }

    handleRead(result) {
        this.onReadItem(result);
        this.item = result;
        this.readAnnounced.next(this.item)
    }

//---------------------------------------------------------------------------------------------------------

    isadmin(): boolean {
        return this.isAdmin;
    }

    get isAdmin(): boolean {
        return this.baseService.authService.isAuthAdmin();

    }

    isAuthor() {
        return this.baseService.authService.isUser(this.item.user_id);
    }

    get isGuest () {
        return this.baseService.authService.isguest();
    }

//-----------------------------------------------------------------------------------------------------------

    equal(a, b) {
        if (a) {
            if (b) {
                //++
                if (a instanceof Object) {
                    if (b instanceof Object) {
                        return JSON.stringify(a) == JSON.stringify(b);

                    }
                    return false;
                }
                //--
                return (a == b);
            }
            return false;
        }

        if (b) return false;
        return true;
    }

    areEqualShallow(a, b) {
        for (let key in a) {
            if (key in b) {
                if (a[key] != b[key]) return false
            }
            else {
                if (a[key]) return false
            }
            if (key in b) {
                a[key] !== b[key]
            }
            if (!(key in b) || a[key] !== b[key]) {
                return false;
            }
        }
        for (let key in b) {
            if (!(key in a)) {
                return false;
            }
        }
        return true;
    }


    public hasChanges() {

        if (this.noChanges) return false;

        let changedItem = this.itemToSaveStr();
/*
        var a1 = { x1: null}
        var a2 = JSON.stringify(a1);
        var a3 = JSON.parse(a2)
        var b1 = { x: "0"}

        let v1 = a3['x'] ? a3['x']: 0
        let v2 = b1['x'] ? b1['x']: 0

        let res1 = (v1==v2)
        console.log('==',res1);

     //   let res = ((!a3['x'] && !b1['x']) || (a3['x']==b1['x']))
     //     { console.log('equal?',res,res1, !a3['x'],!b1['x'])}

     //   console.log('==',a==b,!a,!b);
*/
        return changedItem != this.savedItem;

    }




    itemToSave() {
        let save_item = {};
        for (let key in this.item) {  //.filter
           if (key == 'updated_at') continue;
            if (!key.startsWith('_')) {

                // sp++ !!!
                if (this.item[key] === '') {
                    save_item[key] = this.item[key];
                }
                // sp--
                //sp+++++++++++++++++++++++++++++++++++++++++++++++
                //else if (this.item[key] == +this.item[key]) {
                //    save_item[key] = +this.item[key];
                //}
                // sp-----------------------------------------------
                else {
                   save_item[key] = this.item[key];
                }
            }


        }
        //console.log('ItemToSave:user_id 1',save_item['user_id'])

        this.onWriteItem(save_item);

        //console.log('ItemToSave:user_id 2',save_item['user_id'])


        return save_item;
    }

    itemToPost(postItem?: any) {
        let item_to_post = postItem!=undefined ? postItem : this.item;
        let post_item = {}
        for (let key in item_to_post) {  //.filter
         //   if (key == 'updated_at') continue;
        //    if (!key.startsWith('_')) {

                  post_item[key] = this.item[key];
              //  }
      //      }


        }
        this.onWriteItem(post_item);

        this.onPostItem(post_item)

        return post_item;
    }

    itemToSaveStr() {
        return JSON.stringify(this.itemToSave());
    }


    deletePrompt = ''

    deleteAndBack(prompt = this.deletePrompt) {
        if (this.busy) return;
        if (this.item.id) {
            let s = prompt ? prompt : 'Are you sure to delete this record?';
            this.baseService.authService.confirmationDialog(s)
                .subscribe(result => {
                        if (result) {
                            this.doDelete(this.item.id)
                        }
                    },
                    error => {
                        this.baseService.authService.showMessage(error)
                    });
        }
        else {
            this.back();
        }
    }

    doDelete(id) {
        if (id) {
            this.busy = true;
            this.baseService.delete(id)
                .subscribe(
                    result => {
                        this.busy = false;
                        /* this.item= {}; */
                        this.noChanges = true;
                        this.back();
                    },
                    error => {
                        this.busy = false;
                        this.error = error;
                        this.baseService.authService.showMessage(error)
                    });

        }
    }

    detailId() {
        return this.route.snapshot.params['id'];
    }

    onReadParamItems(paramItems) {

    }

    getDetail() {
        this.isLoaded = false;
        let id = this.detailId();

        //---------------------------------------------------------

        let tag = this.baseService.authService.tag;
        if (tag) {
            this.paramItems = tag;
            this.onReadParamItems(tag);
            this.baseService.authService.tag = null;
        }

        //--------------------------------------- super.getDetail()


        if ((!isNaN(id) && (id >= 0)) || (id == 'first') || (id=='profile')) {

            this.baseService.getItem(id, this.paramItems)
                .subscribe(
                    result => {
                        this.handleRead(result);

                        this.isLoaded = true;
                        this.clear = true; // this.savedItem = this.itemToSaveStr();
                    },

                    error => {
                        this.error = error;
                        this.noChanges = true
                      //  this.baseService.authService.showError(error)
                    })
        }
        else {
            this.isLoaded = true
        }
        ;

    }


    onReadItem(result) {
    }

    onItemLoaded() {
    }

    onWriteItem(result) {
    }

    //---------------------------------------------------------------------------------------------------------
    /*
     onSubmitAndBack() {
     if (this.isValid()) this.onSave();
     }
     */
    //----------------------------------------------------------------------------------------------------------


    saveAndBack() {

        //     if (!this.isValid()) return;

        if (this.busy) return;
        this.busy = true;

        let item = this.itemToPost();

        this.baseService.post(item)
            .subscribe(
                result => {
                    this.busy = false;
                    if (result.error) {
                        this.baseService.authService.showError(result.error);
                    }
                    else {
                        this.noChanges = true;
                        this.back()
                    }
                },
                error => {
                    //console.log(error)
                    this.busy = false;
                    this.baseService.authService.showError(error);
                    //this.error = error
                }
            );

    }

    onSave() {
        if (this.busy) return;
        this.busy = true;

        let item = this.itemToPost();

        this.baseService.post(item)
            .subscribe(
                result => {
                    this.busy = false;
                    this.handleRead(result);
                    this.savedItem = this.itemToSaveStr();
                    this.onBack()
                },
                error => {
                    this.busy = false;
                    this.baseService.authService.showError(error);
                    this.error = error
                }
            );
    }

    onPostItem(item) {

    }


    saveItem() {
        if (this.busy) return;
        this.busy = true;

        let item = this.itemToPost();

        this.baseService.post(item)
            .subscribe(
                result => {

                    this.busy = false;
                    this.handleRead(result);

                    this.savedItem = this.itemToSaveStr();
                    this.isLoaded = true;
                    this.onItemLoaded();
                    this.onItemSaved();
                },
                error => {
                    this.busy = false;
                    
                    this.baseService.authService.showError(error);
                    this.error = error
                }
            );
    }


    onItemSaved() {
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////


    handleError(err) {
        this.baseService.authService.showError(err);
    }

    handleResult(result) {
        this.baseService.authService.showMessage(result.message);
    }

    handle(Ob: Observable<any>, spinner: boolean = true) {
        if (this.busy) return;
        this.busy = true;
        if (spinner) {
            this.baseService.authService.spinnerStart()
        }
        Ob.subscribe(
            result => {
                this.busy = false;
                this.baseService.authService.spinnerStop();
                if (result.error) {
                    this.handleError(result.error);
                }
                else {
                    this.handleResult(result);
                }
            },
            error => {
                this.busy = false;
                this.baseService.authService.spinnerStop();
                this.handleError(error)
            }
        );
    }

    home() {
        this.baseService.authService.home();
    }

    refresh() {
        // check if modified
        this.getDetail();
        //this.getList();
    }

    updateLocStorage(key, flt) {

        let loc = localStorage.getItem(key);
        let upd = (loc) ? JSON.parse(loc) : {};
        for (let key in flt) {
            upd[key] = flt[key];
        }
        localStorage.setItem(key, JSON.stringify(upd));
    }

    isdeveloper() {
        return this.baseService.authService.isDeveloper();
    }

    //-------------------------------------------------------------------

   protected _call_lookup: boolean = false;

    call_lookup() {  //used in SaveGuard
        return this._call_lookup;
    }

    routerData = [];



    ngOnInit() {
        let routerData = this.baseService.authService.routeData;
        this.baseService.authService.routeData = null;

        //console.log(routerData)

        if (routerData) {
            let data = routerData.shift();
            this.routerData = routerData;
            if (data) {

                //if (data.tab) {
                //    this.tab = data.tab
                //}

                //console.log(this.tab, data.tab)

                let lookupkey = 'key' in data ? data['key'] : null;
                let oldvalue = lookupkey ? data.old_id: null
                let newvalue = lookupkey ? data.item[lookupkey]: null


                this.item = data.item;


                let key = 'modified'
                if (key in data) {
                    this.savedItem = this.itemToSaveStr();
                }
                else {
                    this.savedItem = data.savedItem;
                }
                this.isLoaded = true;
                this.readAnnounced.next(this.item)

                if (lookupkey ) { // && oldvalue != newvalue) {
                    this.afterLookup(lookupkey,oldvalue,newvalue)
                }
                return;
            }
        }

        this.getDetail();
    }

    /*
     open(link,key,value) {
     */
    lookup(link, key, value) {

        let old_id = this.parent.item[key];
        this.beforeLookup(key,old_id,old_id)

        let context = {key: key, value: value,
                       item: this.parent.item, savedItem: this.parent.savedItem,
                       //tab: this.parent.tab,
                       old_id: old_id};
        let rdata = [null, context].concat(this.routerData);
        this.parent._call_lookup = true;
        this.parent.baseService.authService.navigate(link, rdata);
    }

    beforeLookup(key,oldvalue,newvalue) {

    }

    afterLookup(key,oldvalue,newvalue) {

    }

    view(link, key, value) {

        let context = {key: key, value: value,
            item: this.parent.item, savedItem: this.parent.savedItem,
 //           tab: this.parent.tab
        };

        let rdata = [null,context].concat(this.routerData);

        //       console.log('lk122',rdata)
        this.parent._call_lookup = true;

        this.parent.baseService.authService.navigate(link, rdata);
    }



    /*
     lookup(link,key,value) {
     let context = { key:key, value:value, item:this.item, savedItem: this.savedItem};
     let rdata = [null,context].concat(this.routerData);
     this._call_lookup = true;

     this.baseService.authService.navigate(link,rdata);
     }
     */

    get uploadURL() {
        return this.baseService.authService.uploadURL();
    }

    userName(obj) {
        return obj ? obj.last_name + ', ' + obj.first_name : '';
    }

    task_type(obj) {
        return obj ? obj.name : '';
    }

    //----------------------------------------------------------------------------------


    loadKeys() {
        let locStorage = localStorage.getItem(this.storageKey());
        if (locStorage) {
            let loc = JSON.parse(locStorage);
            if (!environment.STATELESS_APPLICATION) {
                if (loc.tab) this.tab = +loc.tab;
            }
        }
    }

    saveKeys() {
        let loc: any = {};
        loc.tab = this.tab;

        if (loc && !environment.STATELESS_APPLICATION) {
            localStorage.setItem(this.storageKey(), JSON.stringify(loc));
        }
        else {
            localStorage.removeItem(this.storageKey());
        }
    }


    ngOnDestroy() {
        this.saveKeys();
    }
/*
    get widgetName() {
        return this.baseService.widgetName('detail');
    }

    storageKey() {
        let storageKey = this.baseService.path + '/:id';
        return storageKey;
    }
*/

    storageKey() {
        return this.widgetName;
    }

    get tagName () {
        return this.widgetName;
    }

    get widgetName() {
        return this.selector;
        //return this.el.nativeElement.tagName.toLowerCase();

        //return this.baseService.widgetName('list');
    }




    public validationOptions: any = {

    };


   ngAfterViewChecked() {

       if (1) {
           return;
       }
       
         //+ required field label  *

        $('.form-group :input[required]').each (function() {

            let $input = $(this);

            if (!$input.attr('disabled')) {

                $input.closest('.form-group').each(function () {

                    let $group = $(this);

                    $group.find('label').each(function () {

                        let $label = $(this)

                        $label.addClass('label-required'); // css( "border", "13px solid red" );

                    })
                })
            }

       })

/*
       $('form :input[time]').each (function() {
           if (!$(this).attr('time')) $(this).attr('time','true')
       })


*/
       $('.form-group :input[data-ehis-time]').each (function() {

           let $input = $(this);

           //placeholder="hh:mm"

           $input.attr('placeholder','hh:mm')
           /*
           public get autoCorrectedDatePipe(): any { return createAutoCorrectedDatePipe('dd/mm/yyyy'); }
           public get date_Mask(): any { return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; }

           public get _dateMask () {
               return {
                   mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
                   keepCharPositions: true,
                   pipe: () => {return createAutoCorrectedDatePipe('dd/mm/yyyy')}
               }
           }


            this.textMaskInputElement = createTextMaskInputElement(
            Object.assign({inputElement: this.inputElement}, this.textMaskConfig)
            )



            */
/*
             if (!$input.attr('text_mask')) {
                 $input.attr('text_mask','1')
                 let z = {
                     inputElement: this,
                     mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
                     keepCharPositions: true,
                     pipe: () => {
                         return createAutoCorrectedDatePipe('dd/mm/yyyy')
                     }
                 }

                 console.log('z',createTextMaskInputElement(z))

                 //createTextMaskInputElement(z);
             }
             */
           //$input.textMask(z);

        //   if (!$input.('textMask')) {

          //     $input.attr('[textMask]',z)
          // }
           //$input.attr('[text')
           //console.log('input-time',$input.attr('textMask'))
/*
           if (!$input.attr('disabled')) {

               $input.closest('.form-group').each(function () {

                   let $group = $(this);

                   $group.find('label').each(function () {

                       let $label = $(this)

                       $label.addClass('label-required'); // css( "border", "13px solid red" );

                   })
               })
           }
           */

       })
   }

    public get timeMask(): any { return [/[0-2]/, /\d/, ':', /[0-6]/, /\d/]; }


    getModal() {
        return $(this.el.nativeElement).closest('modal-content').closest('modal')
    }

    back() {
       /*
        let $modal = this.getModal();
        console.log('modal',$modal)
        if ($modal.length > 0 ) {
            // changed ?
            $modal.close()
            return;
        }
   */
   //     console.log('this',this)
        this.baseService.authService.routeData = this.routerData;
        this.baseService.authService.back();
    }


    validate() {
        let res = true
        $(this.el.nativeElement).find('form').each(function () {
            var $form = $(this)
            res = res && $form.valid()
        })
        return res
    }


    get icon() {
        return this.baseService.icon
    }

    acl(access) {
        return this.baseService.acl(access)
    }

    isDoctor(item) {
       return this.baseService.authService.isDoctor(item)
    }

    get profile() {
        return this.item.id == 'profile'
    }
    /*
    get modified() {
        if (this.isLoaded) {
            let s = ' '+ (this.item.id ? '#' + this.item.id : '#new');
            return s+ (this.hasChanges() ?' *' : '');
        }
        return '';
    }
    */
    get modified() {
        if (this.isLoaded) {
            let s = this.profile? '' : (' '+ (this.item.id ? '#' + this.item.id : '#new'));
            return s+ (this.hasChanges() ?' *' : '');
        }
        return '';
    }

    clear = false



    ngDoCheck() {

        if (this.clear) {
            this.clear = false
            this.savedItem = this.itemToSaveStr();
            //console.log('itemToSaveStr')
        }

    }


}