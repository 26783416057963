import {Component, forwardRef ,Input, Output, EventEmitter, ElementRef, ViewContainerRef} from '@angular/core';
import { NG_VALUE_ACCESSOR,ControlValueAccessor  } from '@angular/forms';


import {AuthService} from "../../auth/auth.service";
import {CsvService} from "../../auth/csv/csv.service";


const noop = () => {};

export const DYNAMIC_RADIOS_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DynamicRadios),
    multi: true
};

@Component({

    selector: 'dynamic-radios',
    templateUrl: './dynamic-radios.component.html',
    providers: [DYNAMIC_RADIOS_CONTROL_VALUE_ACCESSOR]

})
export class DynamicRadios implements ControlValueAccessor{

    private innerValue: any = null;
    public innerTypedValue: any


    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {

        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(this.innerValue);
/*
            if (this.innerTypedValue === null) {
                this.onChangeCallback(v ? +v : null);
            }
            else if (Number.isInteger(this.innerTypedValue)) {
                this.onChangeCallback(v ? +v : 0);
            }
            else if (typeof this.innerTypedValue === 'string') {
                this.onChangeCallback(v ? v.toString() : '');
            }
            else if (typeof this.innerTypedValue === 'undefined') {
                this.onChangeCallback(v ? v.toString : undefined);
            }
            else {
                this.onChangeCallback(this.innerValue);
            }
*/
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    clickPicture(option) {
        if (!this.disabled) {
            this.value = this.getOptionValue(option)
        }
    }

    getOptionValue(option) {
        if (Number.isInteger(this.innerTypedValue)) {
            return +option.id
        }
        else if (typeof this.innerTypedValue === 'string') {
            return option.id.toString()
        }
        else {
            return option.id
        }

    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
      //  if (value && value !== this.innerValue) {
            this.innerValue = value;
       //     this.innerTypedValue = value

      //  }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }


    //----------------------------------------------------------------------------------------------------------

    _data = [
        { id: 1, name: 'Option 1', link: null},
        { id: 2, name: 'Option 2', link: null},
        { id: 3, name: 'Option 3', link: null},
    ]
    @Input('disabled') disabled = false

    get hasLinks() {
     //   this.hasLinks =
            return this._data.some((item)=>{return item.link ? true: false})

    }

    @Input('options') set options(value) {
        if (value) {
            if (value.index) this._data = value.index

            //if (value.disabled) this.disabled = true
        }
    }



    isdisabled() {
      return this.disabled ? true : undefined
    }

    labelMarginLeft(option) {
        return this.hasLinks ? // && option.loaded ?
        /*return option.link ?*/ { 'margin-left': '40px'} : null
    }

    //style="margin-left: 40px;

    indexTracker(index, value) {
        return value;
    }

    _name = 'rd'

    constructor() {
        this._name = "rd" + Math.random().toString(16).slice(2)

    }

    getRadioName() {
        return this._name
    }

    @Input('index') set index(val) {
        if (val) {
            this._data = val
        }
    }
}

