import {Component, Input} from "@angular/core";
//import { HttpClient } from "@angular/common/http";
//import { LoaderService } from "../services/loader.service";
//import { load } from "@angular/core/src/render3";

@Component({
    selector: 'loader',
    template: `

        <div *ngIf="info" class="info-wrapper">
            <ng-content></ng-content>
        </div>

        <div *ngIf="error" class="loader-wrapper opaque">
                  {{error}}
           <div *ngIf="loading" class="loader"></div>
       </div>
       
       <div *ngIf="(!error && loading)" class="loader-wrapper" [ngClass]="{opaque:!loaded}" >
           <div  class="loader"></div>
       </div>
        
       
`,

    styles: [`
        /*
              :host {
                  min-height: 344px;
              }
              */

        .info-wrapper {
             position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            opacity: 1;
            height: 100%;
            justify-content: center;
            min-height: 100px;
            background: rgba(239, 239, 239, 1);
            z-index: 1;
        }
        


        .loader-wrapper {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            display: flex;
          /*  align-items: center; */
            padding-top: 62px;

            justify-content: center;
            z-index: 900;

            background: rgba(239, 239, 239, 0.4);
        }

        .loader-wrapper.opaque {
            background: white; /* rgba(239, 239, 239, 1); */
            opacity: 1;
        }
        
        .loader-error {
            /*  background: #efefef; */ /* #F0F4F7; / * #fafafa; /* #CCDADF; /* #FFF;  #2b542c; */
            /*  opacity: 0.33; */ /* 0.23; /*.87; 1.0;*/
            
            background: rgba(255,255,255,1);

            

            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            display: flex;
           /* align-items: center;  */
            padding-top: 62px;
            justify-content: center;
            z-index: 950;
        }

        .loader {

            
            opacity: 0.8; /* 0.23; /*.87; 1.0;*/
            border: 16px solid #F1DA91; /* #c0bbb7; #f3f3f3; /* Light grey */
            border-top: 16px solid  #1c3959; /* #474544; rgba(71, 69, 68,1);*/
            border-radius: 50%;
            width: 72px;
            height: 72px;
            /*  opacity: 1.0;  */
            position: absolute;
            top: 36px; /* 10%; */
            /*position: absolute;
            top: 10%;
            left: 50%;
            align: center; 
            transform: translate(-50%, -50%);  
            */
            z-index: 960; /*1100; */

            animation: spin 1.5s linear infinite;
            
       }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `],
 //  styleUrls: \`
//    ["loader.component.css"],
})
export class Loader {

     //set loading(value): any = false
    @Input('error') _error: any = null


    _spin = false;
    _loading: any = false;
    _loaded: any = false

    get spin() {
        return this._spin && this.loading
    }

    get error() {
        return this._error
    }

    @Input('loaded') set loaded(value) {
        this._loaded = value
    }

    get loaded() {
        return this._loaded
    }
    @Input('loading') set loading(value) {

        if (value) {
           //if (!this.spin) {
           //    setTimeout(()=>this._spin=true, 1200);
           //}
            this._spin = true;

        }
        else {
            this._spin = false;
        }
        this._loading = value
    }

    get loading() {
        return this._loading
    }

    constructor() {} //private http: HttpClient, public loaderService: LoaderService) {}

    @Input('info') info = false
}