import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({

    selector: 'select-button',
    template: `
       
       <label *ngIf="data.length > 0 " class="btn btn-default btn-sm" btnRadio="0" 
            [ngModel]="(item[name]? item[name]:0).toString()" (click)="clickRadio('0')"
            style="margin-right: -4px;margin-left:0px"
            ><ng-content></ng-content></label>
       <label *ngFor="let ref of data" class="btn btn-default btn-sm" btnRadio="{{ref.id}}" 
          [ngModel]="(item[name]? item[name]: 0).toString()" (click)="clickRadio(ref.id.toString())"
          style="margin-right: -1px;margin-left:0px">{{ref.name}}</label>
       
  `,
})

//style="margin-right: -1px;"
//style="margin-right: 0px!important;"
export class SelectButtonComponent {

    @Input('data') data =[];
    @Input('item') item = {}
    @Input('name') name = 'name'

    @Output() change = new EventEmitter();

    clickRadio(id) {
        this.change.emit( { name: this.name, id: +id} );
    }

}
