import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BaseList}       from '../../auth/base-list'
import {InvoicingService, ClinicService}   from "../billing-service.service";
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvoicesComponent extends BaseList implements OnInit {

  @ViewChild('exportInvoiceModal') exportInvoiceModal;

  public invoicelist= [];
  public momentInstance = moment;
  constructor(el: ElementRef,protected route: ActivatedRoute,private invoiceService: InvoicingService, private clinicService: ClinicService, public router:Router) {
    super(el, invoiceService);
    this.clinicService.loadClinicDetails(() => {
      this.serviceItems['page'] = 1;
      // this.serviceItems['invoicing'] = true;
      this.paramItems['clinic_id'] = this.clinicService.clinicDetails.id;
      // this.paramItems['clinic_id'] = 5
      this.doGetList()
      this.invoiceService.authService.spinnerStop();
    })
  }

  

  ngOnInit() {
    // super.doGetList()
    // this.doGetList()
    // this.data = this.data;
  }

  loadInvoiceList() {
    // use billing services to call api
  }

  selectInvoice(item: any) {
    // when an invoice item is selected from the invoice list
  }

  goToServices() {
    this.router.navigate(["services"], {relativeTo: this.route.parent})
  }

  clickAdd() {
    this.router.navigate(["bills", 0], {relativeTo: this.route.parent})
  }

  editInvoice(id) {
    this.router.navigate(['bills', id], {relativeTo: this.route.parent})
  }

  exportInvoice() {
    this.exportInvoiceModal.show();
  }

  exportInvoiceModalOK(event: any) {
    let client_name = ""
    if (event.client_name !== undefined && event.client_name !== null && event.client_name.trim() !== "") {
      client_name = event.client_name
    }
     let param = {
       clinic_id: this.paramItems['clinic_id'],
       start_date: event.start_date,
       end_date: event.end_date,
       client_name: client_name
     }  
     this.invoiceService.csvWithParams(param, `export_all_invoices_${event.start_date}_to_${event.end_date}.csv`)
   }

}
