import { NgModule }      from "@angular/core";
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import {RouterModule, Routes} from '@angular/router';

import { PaginationModule  } from 'ngx-bootstrap';
import { ButtonsModule } from 'ngx-bootstrap';
import { SaveGuard} from '../auth/save-guard.service';

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";


import {TrustedUserService} from "./trusted-user.service";
import {TrustedUserList}  from "./list/trusted-user-list.component";
import {TrustedUserDetail}  from "./detail/trusted-user-detail.component";
import {TrustedUserTable}  from "./table/trusted-user-table.component";
/*
export const routes:Routes = [
    { path: '', component: TrustedUserList, pathMatch: 'full' },
    { path: ':id', component: TrustedUserDetail, canDeactivate: [SaveGuard],},
];
*/
import {AuthModule} from "../auth/auth.module";
import {WidgetModule} from "../widget/widget.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonsModule,
        ControlModule,
        AuthModule,


        PaginationModule.forRoot(),
       // RouterModule.forChild(routes),

        SmartadminModule,
        SmartadminInputModule,

        WidgetModule

    ],
    declarations: [
        TrustedUserList,
        TrustedUserDetail,
        TrustedUserTable,
    ],
    exports: [
        TrustedUserList,
        TrustedUserDetail,
        TrustedUserTable,
    ],

    providers: [
      SaveGuard,
      TrustedUserService,

    ]

})
export class TrustedUserModule {}
