import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Output, Input } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

//import {BaseList}       from '../../auth/base-list.ts';
import {AuthService}    from '../../auth/auth.service';

@Component({
  selector: 'ehis-post',

  styleUrls: [ './ehis-post.style.scss' ],
  templateUrl: './ehis-post.template.html',

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class EhisPostComponent{

//    @Output() onOpenForm = new EventEmitter();
    @Input()  user:any;
    @Input()  post:any;

//    @Output() onSelect = new EventEmitter();

    user_id = 0;

    $el:any;


    constructor(el:ElementRef, private authService:AuthService) {

        this.user_id = authService.user_id();

        this.$el = jQuery(el.nativeElement);

    }

    clickItem(item: any): void {
//        this.onSelect.emit(item);
    }

}
