import {Injectable} from '@angular/core';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'  //country.service

import {AuthService} from '../auth/auth.service';
import {BaseService} from '../auth/base.service'

@Injectable()
export class TrustedUserService extends BaseService {

    icon = "fa fa-check"

    constructor(public authService:AuthService) { super( authService, "/api/trusted_user"); }

}