import {Injectable} from '@angular/core';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'  //country.service

//import 'pdfmake/build/pdfmake.min.js';
//import 'pdfmake/build/vfs_fonts.js';


//import 'pdfmake-client/pdfmake/build/pdfmake.min.js';

//declare var pdfMake: any = require('pdfmake-client/pdfmake/build/pdfmake.min.js');
//require('pdfmake/build/vfs_fonts.js');


//import 'pdfmake-master/build/pdfmake.min.js';
//import 'pdfmake-master/build/vfs_fonts.js';

//working....
//import 'pdfmake-client/pdfmake/build/pdfmake.min.js';
//import 'pdfmake-client/pdfmake/build/vfs_fonts.js';

import {  AuthService}  from '../auth/auth.service';

declare var pdfMake: any;

// SP--

@Injectable()
export class PdfService  {

    constructor(public authService:AuthService) { }

    isIE() {
        return window.navigator && window.navigator.msSaveBlob
    }

    open(dd:any) {
        if (this.isIE()) {
            this.download(dd,'download')
            return
        }
        pdfMake.createPdf(dd).open();
    }

    download(dd,name) {
        let fileName = name + '.pdf';
        pdfMake.createPdf(dd).download(fileName);
    }

    createPdfAsBlob(dd) {
        const pdfDocGenerator = pdfMake.createPdf(dd);
        pdfDocGenerator.getBase64((data) => {
            sessionStorage.setItem("invoice",data);
            return data;
        });
    }

    print(dd:any){
        if (this.isIE()) {
            this.download(dd,'download')
            return
        }

        pdfMake.createPdf(dd).print();
    }

    openChart(dyForm:any, chartRec:any) {

        let dyFormContent = dyForm.content();

        dyFormContent.margin = [0, 10,0,10];


        let patient = chartRec.patient ? chartRec.patient : chartRec._patient;
        let user    = chartRec.user ? chartRec.user: chartRec._user;


        let rule0:any =
        {

 //         text : ['xxx']

           table: {
                widths: ['*'],
                headerRows: 1,
                body: [
                    ['  ',],
                    ['  ',],
                ]
            },

            //layout: 'headerLineOnly',

        layout: {      //layout: 'headerLineOnly',
                hLineColor: function(i, node) {
                    return  'gray';
                },

                hLineWidth: function(i, node) {
                    if (i === 0 || i === node.table.body.length) return 0;
                    return (i === node.table.headerRows) ? 1: 0;
                },
                vLineWidth: function(i) { return 0; },
                paddingLeft: function(i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function(i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 8;
                }

           },

       };


        let rule1:any =
            {

                //         text : ['xxx']

                table: {
                    widths: ['*'],
                    //headerRows: 1,
                    body: [
                        ['  ',],
        //                ['  ',],
                    ]
                },

                //layout: 'headerLineOnly',

                layout: {      //layout: 'headerLineOnly',
                    hLineColor: function(i, node) {
                        return  'gray';
                    },

                    hLineWidth: function(i, node) {
                        if (i === 0 || i === node.table.body.length) return 0;
                        return (i === node.table.headerRows) ? 0: 0;
                    },
                    vLineWidth: function(i) { return 0; },
                    paddingLeft: function(i) {
                        return i === 0 ? 0 : 8;
                    },
                    paddingRight: function(i, node) {
                        return (i === node.table.widths.length - 1) ? 0 : 8;
                    }

                },

            };

        let rule2:any =
            {

                //         text : ['xxx']

                table: {
                    widths: ['*'],
                    //headerRows: 1,
                    body: [
                        ['  ',],
                        //                ['  ',],
                    ]
                },

                //layout: 'headerLineOnly',

                layout: {      //layout: 'headerLineOnly',
                    hLineColor: function(i, node) {
                        return  'gray';
                    },

                    hLineWidth: function(i, node) {
                        if (i === 0 || i === node.table.body.length) return 0;
                        return (i === node.table.headerRows) ? 0: 0;
                    },
                    vLineWidth: function(i) { return 0; },
                    paddingLeft: function(i) {
                        return i === 0 ? 0 : 8;
                    },
                    paddingRight: function(i, node) {
                        return (i === node.table.widths.length - 1) ? 0 : 8;
                    }

                },

            };



        //      let rule1 = Object.create(rule0); //JSON.parse(JSON.stringify(rule0));
        //let rule2 = Object.create(rule1); // JSON.parse(JSON.stringify(rule0));

       let patient_full_name = patient ? patient.full_name : 'Patient : '+           '__________________________' ;
       let patient_dob = patient ? this.authService.dateFmt(patient.date_of_birth) : '__________________________' ;
       //let patient_social_insurance = patient ? patient.social_insurance :           '__________________________' ;
       let patient_health_card = patient ? patient.health_card :                     '__________________________' ;


       //let  chartRec_occupation_name = chartRec._occupation ? chartRec._occupation.name  : '_______________________________________';

        let occupation1 = user ? user.occupation : null // id ==10  ->other
        let occupation2 = occupation1 ? occupation1.name : null
        let occupation3 = occupation2 ? (occupation2.toLowerCase().startsWith('other') ? null : occupation2): null
        let  chartRec_occupation_name = occupation3 ? occupation3  : '_______________________________________';

        // EH-92 20190225
        //let visit_dt = chartRec.amended ? chartRec.amend_visit_datetime : chartRec.visit_datetime ;
        let visit_dt = chartRec.visit_datetime ;
        //

       let  chartRec_visit_datetime  = visit_dt ? this.authService.dateFmt(visit_dt) : '________________________________________';

       let user_name = user ? user.name : '_____________';

       let date_at = chartRec.amended ? chartRec.amend_signed_at : chartRec.signed_at;
       let chartRec_date_at = date_at ? this.authService.dateFmt(date_at) : '___________________________________________________';

        let dd = {
            content: [

                {
                    text: [
                        { text: patient_full_name , style: 'header' },
                    ]
                },

                { text: 'Birth date: ' + patient_dob, style: 'anotherStyle' },
//                { text: 'Social insurance # '+ patient_social_insurance,style: 'anotherStyle' },
                { text: 'Health card # '+ patient_health_card,style: 'anotherStyle' },

                rule0 ,


                { text: "Profession: " + chartRec_occupation_name },

                { text: 'Visit date: ' + chartRec_visit_datetime,
                    margin: [0, 0 , 0, 10],},

 //               rule1,

                //{ text : 'Record data '},

                dyFormContent,


                rule2,


                { text: '______________________________________ ' +user_name,
                    style: 'anotherStyle', margin: [0, 10], },
                { text: 'Signed date: ' + chartRec_date_at , style: 'anotherStyle' },

            ],
            styles: {
                header: {
                    fontSize: 22,
                    bold: true,
                    alignment: 'right'
                },
                anotherStyle: {
                    italic: true,
                    alignment: 'right',
                }
            },

            pageMargins: [ 40, 60, 40, 60 ],

            pageSize : 'a4',
             }

        /*
         var docDefinition = this.dynamicForm.docDefinition;
         var fileName = this.categoryName + '_'+this.itemName;
         //(<any>window).
         pdfMake.createPdf(docDefinition).download(fileName);
         */

        //dd.content[6].table.body.splice(18,1)
        //dd.content[6].table.body[18] = dd.content[6].table.body[19]
        //console.log('pdf',dd.content[6].table.body[18])

     //   console.log('pdf',dd)

       //     let fileName = 'aaaa1' + '.pdf';
        //    pdfMake.createPdf(dd).download(fileName);

            this.open(dd);

    }

}