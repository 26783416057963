import { Component, OnInit } from '@angular/core';
import { ApplicationStoreService } from 'app/application-store.service';
import { map, takeWhile } from 'rxjs/operators';
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { UserService } from './../../../service/user.service';
import { AuthService } from './../../../auth/auth.service';
import { TaskTodoService } from 'app/task-todo/task-todo.service';
import { InvoiceService } from 'app/invoice/invoice.service';
@FadeZoomInTop()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  single: any[];
  view: any[] = [600,400];

  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#684613', '#2ed31b', '#822def', '#20e5e5', '#AAAAAA']
  };


  alive = true;
  menuItem: any;
  route: string;
  role_id : string = '';
  clinic_id: string = '';
  userid: string = '';
  widget : Array<string>;
  assignedWidget: Array<string>;
  totalWidget: Array<string>;
  filteredWidget: Array<string>;
  taskData: Array<string>;
  invoiceData: Array<string>;
  calenderData: Array<string>;
  checkInitalData: any = 1;
  chartdata: any;
  constructor(
    private appStore: ApplicationStoreService,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private authService: AuthService,
    private taskTodoService: TaskTodoService,
    private invoiceService: InvoiceService
    
    ) {
      const aa = router.events.subscribe(val => {
        //console.log("============route--->",this.location.path());
        this.route = this.location.path();
      });
      //Object.assign(this, { single });
    //   this.single = [
    //     {
    //       "name": "Patient-Booked",
    //       "value": 10,
    //       "label": "20%"
    //     }
    //   ];
      
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('user'));
    if(userData) {
      this.userid = userData.id ? userData.id : '';
      this.role_id = userData.role_id ? userData.role_id : '';
      this.clinic_id = userData.clinic_id ? userData.clinic_id : ''
    }

    this.appStore.appState$
        .pipe(takeWhile(() => this.alive))
        .subscribe(appUserState => {
            this.menuItem = appUserState.AppMenu;
        });

    this.totalWidget = ["Calender", "Task", "Billing", "BookedPatient"];
    this.getWidget();
    
  }

  OpenWidgetModal(){
    this.getWidget();
  }

  selectWidget(widget_name:string) {
    this.authService.spinnerStart();
    if(widget_name) { 
        let params = {
          "userid" : this.userid,
          "clinic_id": this.clinic_id,
          "role_id": this.role_id,
          "widget_name": widget_name
        }
        this.authService.saveWidget(params).subscribe(res=> {
          if(res.result && !res.error) {
            this.getWidget(widget_name);
          }
        });
    }
  }

  getWidget(widget_name:string = '') {
    let params = {
      "userid" : this.userid,
      "clinic_id": this.clinic_id
    }
    this.authService.getWidget(params)
      .pipe(map(val => { this.assignedWidget = val.result; return val.result.map(res => { return res.widget_name })}))
      .subscribe(val => { 
        if(val.length > 0) {
          this.widget = val;
          this.filteredWidget = this.totalWidget.filter(f => !this.widget.includes(f));
          this.authService.spinnerStop();
        } else {
          this.checkInitalData = 0;
          this.filteredWidget = this.totalWidget;
        }
      });
      if(this.checkInitalData === 1) {
        // For Task Data
        this.taskTodoService.getTask().subscribe(res=> {
          if(res.data.length > 0) {
              this.taskData = res.data;
          }
        });

        // For Billing Data
        this.invoiceService.getRecentinvoice(this.clinic_id).subscribe(invoice=> {
          if(invoice.data.length > 0) {
              this.invoiceData = invoice.data;
          }
        });

        // For calender Data
        let param2 = {
          "clinic_id" : this.clinic_id
        }
        this.authService.getcalenderdata(param2).subscribe(calenderData=> {
          if(calenderData.result.length > 0) {
              this.calenderData = calenderData.result;
          }
        });

        let param3 = {
          "clinic_id" : this.clinic_id
        }
        this.authService.getAppointmentData(param3).subscribe(chart=> {
          if(chart.result.length > 0) {
              this.single = chart.result;
          }
        });
      }
  }

  deleteWidget(id:number) {
    this.authService.spinnerStart();
    let par = {
      "widget_id": id
    }
    this.authService.deletewidget(par).subscribe(resp=> {
      if(resp.result === 1) {
          this.getWidget();
          this.authService.spinnerStop();
      }
    });
  }

  gotocalender() {
    this.router.navigate(['calender'], {})
  }

}
