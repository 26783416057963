import {Component, ElementRef, Input, ViewChild} from '@angular/core';

//import {AuthService} from "../../auth/auth.service";

import * as moment from 'moment';
import {BaseForm} from "../../auth/base-form";

import {periodStr} from "../../auth/period-str"

import {sprintf} from "sprintf-js"
import {AuthService} from "../../auth/auth.service";
import { BillingServiceService } from 'app/billing/billing-service.service';
//import {DayPilot} from "daypilot-pro-angular";
//import {duration} from "../../../../node_modules/smartadmin-plugins/bower_components/moment/moment";

declare var $;

@Component({
  selector: 'appointment-dialog',
  templateUrl: './appointment-dialog.component.html',
})
export class AppointmentDialog /* extends BaseForm */ {

    tab = 1;

    selectTab(tab) {

    }

    item: any = {}


    startTime: string
    endTime: string
    duration: string;
    resource_name: string
    currentDate = null
    currentDateFuture = null
    weekdays = null


    dt_period: any[] = [];


    dateFmt: string = 'DD/MM/YYYY';
    /* moment format */
    datePickerFmt = 'dd/mm/yy'

    dateEditFmt = 'dd/mm/yy'


    nullPeriod() {
        this.item.dt1 = null;
        this.item.dt2 = null;
    }

    timeArray = []
    durationArray = []

    setTimes() {

        this.startTime = moment(this.item.start.toString()).format("HH:mm")
        this.endTime = moment(this.item.end.toString()).format("HH:mm")
        this.item.cellDuration = 5;
        let cellDuration = (this.item.cellDuration) ? this.item.cellDuration : 60

        let _timeArray = []
        for (let i = 0; i < 24; i++) {
            _timeArray.push(sprintf("%02d:00", i))
            if (cellDuration == 30) {
                _timeArray.push(sprintf("%02d:30", i))
            }
            if (cellDuration == 5) {
                _timeArray.push(sprintf("%02d:05", i))
                _timeArray.push(sprintf("%02d:10", i))
                _timeArray.push(sprintf("%02d:15", i))
                _timeArray.push(sprintf("%02d:20", i))
                _timeArray.push(sprintf("%02d:25", i))
                _timeArray.push(sprintf("%02d:30", i))
                _timeArray.push(sprintf("%02d:35", i))
                _timeArray.push(sprintf("%02d:40", i))
                _timeArray.push(sprintf("%02d:45", i))
                _timeArray.push(sprintf("%02d:50", i))
                _timeArray.push(sprintf("%02d:55", i))
            }
            if (cellDuration == 15) {
                _timeArray.push(sprintf("%02d:15", i))
                _timeArray.push(sprintf("%02d:30", i))
                _timeArray.push(sprintf("%02d:45", i))
            }
        }
        this.timeArray = _timeArray

        let _durationArray = []
        for (let i = 0; i < 24; i++) {
            if (i > 0) {
                _durationArray.push(sprintf("%dh", i))
                
                if (cellDuration == 30) {
                    _durationArray.push(sprintf("30m", i))
                }
                if (cellDuration == 15) {
                    _durationArray.push(sprintf("%dh 15m", i))
                    _durationArray.push(sprintf("%dh 30m", i))
                    _durationArray.push(sprintf("%dh 45m", i))
                }
            }
            else {
                if (cellDuration == 30) {
                    _durationArray.push(sprintf("30m", i))
                }
                if (cellDuration == 15) {
                    _durationArray.push(sprintf("15m", i))
                    _durationArray.push(sprintf("30m", i))
                    _durationArray.push(sprintf("45m", i))
                }
            }
            this.durationArray = _durationArray
        }


        this.calculateDurationFromStartEnd(this.startTime, this.endTime)
    }


    calculateDurationFromStartEnd(start, end) {

        let start_date: any = this.dateFromTimeStr(start)
        let end_date: any = this.dateFromTimeStr(end)

        this.duration = moment(end_date.diff(start_date)).utc().format("HH:mm")

        return
        let diff = new Date(end_date - start_date);
        //     this.duration = new DayPilot.Date(diff).toString("HH:mm")
        this.duration = moment(diff).format("HH:mm")

    }

    durationOnChange($event) {
        // console.log(this.duration,$event.target.value)
    }


    private dateFromTimeStr(s) {

        let hh_mm = s.split(':')
        let hh = +hh_mm[0]
        let mm = hh_mm.length > 1 ? +hh_mm[1] : 0
        let diff = new Date(0, 0, 0, hh, mm)

        //    return moment().utc().startOf('day').add(hh,'h').add(mm,'m' )
        return moment().startOf('day').add(hh, 'h').add(mm, 'm')
    }

    durationSelect($event) {
        let start = this.dateFromTimeStr(this.startTime)
        // console.log(start)

        let hh_mm = this.duration.split(':')
        let hh = +hh_mm[0]
        let mm = hh_mm.length > 1 ? +hh_mm[1] : 0
        // let diff = new Date(0,0, 0, hh,mm )
        // console.log(hh,mm)

        let end = start.add(hh, 'h').add(mm, 'm')

        // console.log(end)
        this.endTime = end.format("HH:mm");

    }


    public typeaheadOnSelect(e: any): void {
        this.calculateDurationFromStartEnd(this.startTime, this.endTime)
    }


    get isPatient() {
        return +this.item.appointment_type_id == 1
    }

    get isRoutine() {
        return +this.item.appointment_type_id == 2
    }


    typeChanged() {
   //     if (this.item.appointment_type_id == 2) {
            //revalidate

            $(this.el.nativeElement).find('em.invalid').css('display','none')
   //     }
    }


    copy = false

    get title() {
        //let s = this.item.id ? "Edit Appointment #" + this.item.id : "Add Appointment"
        let t = this.isRoutine ? 'routine' : 'appointment';

        if (!this.item.id && this.copy) {
            return "New " + t
        }
        else {
            let s = (this.item.id ? "Edit " : "Add ") +  t
            let s1 = this.item.onWork ? '' : '  [OFF WORK]'
            return s + s1
        }
    }

    get cancellationTitle() {
        let t = this.isRoutine ? 'Routine' : 'Appointment';

        return t + ' cancellation'
    }



    public setItem(item) {


        this.item = Object.assign({}, item)

        this.item.appointment_type_id = +item.appointment_type_id ? +item.appointment_type_id : 1

        //console.log('setItem',item,this.item.appointment_type_id)

        let event_dt = item.start ? moment(item.start.toString()) : moment();

        if (item.start && item.end) {
            this.setTimes()
            this.resource_name = item.resource ? item.resource.name : ''

            // https://momentjs.com/docs/#/displaying/
            this.currentDate = event_dt.format('dddd, DD MMMM YYYY')

            let s1 = event_dt.format('dddd') + 's'
            let s2 = event_dt.format('DD MMMM YYYY')
            this.weekdays = s1
            this.currentDateFuture = sprintf('All %s from %s on', s1, s2)

        }

        this.item.repeat = item.repeat ? +item.repeat : 0
        this.item.end_repeat = item.end_repeat ? +item.end_repeat : 0

        let apply_dt = item.apply_end ? moment(item.apply_end) : event_dt
        if (apply_dt < event_dt) {
            apply_dt = event_dt
        }


        this.item.apply_end = apply_dt.format('ddd, DD MMM YYYY') //  Thu, 26 Dec 2019

        if (!item.id) {
            this.item.repeat_days = [event_dt.weekday()]
        }

    }

    public getItem() {

        let dt = moment(this.currentDate).format('YYYY-MM-DD') + ' '

        let d2 = moment(this.item.apply_end).format('YYYY-MM-DD')

        this.item.start = dt + this.startTime + ':00'
        this.item.end = dt + this.endTime + ':00'

        this.item.apply_end = d2;

        return this.item
    }

    @Input('data') set data(item) {
        this.setItem(item)
    }


    get data() {
        return this.getItem()
    }

    ////////////////////////////////////

    @ViewChild('hello') hello

    btnClick() {
        this.hello.open(() => {

            this.hello.close()
        })
    }


    @ViewChild('hello2') hello2

    btnClick2() {
        this.hello2.open(() => {

            this.hello2.close()
        })

    }

    serviceData : any;
    constructor(protected el: ElementRef, public authService: AuthService, private billingServiceService:BillingServiceService) {
        this.getServiceData();
    }

    getServiceData() {
        let user = JSON.parse(sessionStorage.getItem('user'));
        const params = {
            per_page : 50,
            page : 1,
            clinic_id : user.clinic_id
        }
        this.billingServiceService.getItem(null,params).subscribe(resp => {

            this.serviceData = resp.data;
        });
    }

    /*
    substituteChar(charCode, tgt){
        var keyEvt = document.createEvent("KeyboardEvent");
        if(keyEvt.initKeyEvent){
            keyEvt.initKeyEvent("keypress", true, true, null, false, false, false, false, 0, charCode);
            tgt.dispatchEvent(keyEvt);
            keyEvt.stopPropagation;
        } else if (keyEvt.initKeyboardEvent){
            // Safari?
        }
    }
    */

    ngAfterViewInit() {

        let $elem = $(this.el.nativeElement).find('input.select-time')
        /*
        $elem.keydown(e=>{
            console.log('keydown',e)
            if (e.key ==";") e.key= ':'
        })
        */
        $elem.keypress(e => {
            let ch = e.which
            //console.log('keypress',ch,e)

            if (ch == 59) {
                /*
                let $container = $(this.el.nativeElement).find('typeahead-container.open')
                if ($container.length) {
                    console.log('container')
                    setTimeout(() => {

                        var event = $.Event( "keypress", { which: 58 ,keyCode: 58, charCode:58, key: ':'} );
                        //        e.preventDefault()
                        //       e.stopPropagation()
                        $container.trigger(event);

                    },100);

                }

                     e.preventDefault()
                e.stopPropagation()

                str = $elem.val()
                $elem.val(str+':');
                     $elem.trigger('keypress', { which: 58,keyCode:58,key:":"})


                setTimeout(() => {

                    var event = $.Event( "keypress", { which: 58 ,keyCode: 58, charCode:58, key: ':'} );
                    $elem.trigger(event);

                },100);


               return false


                ch = 58
                e.key = ':'
                //e.charCode = 58
                e.which = 58
                e.keyCode = 58




                if (e.originalEvent) {
                    e.originalEvent.key = ':'
                    e.originalEvent.charCode = 58
                    e.originalEvent.which = 58
                    e.originalEvent.keyCode = 58

                }




               var event = $.Event('keypress');
               event.which = 58; // Character 'A'
               event.key = 58
               event.charCode = 58
               event.keyCode = 58

               e.preventDefault()
               e.stopPropagation()
               $elem.trigger(event);
               return false


               e.key = 58
               e.which = 58
               e.charCode = 58
               e.keyCode = 58
               console.log(e)
               //var str = $elem.val();
               //e.preventDefault()
               //$elem.val(str + ':');
               //return false
               */
            }

            if (ch < 48 || ch > 58) {
                // e.which = 58
                return false
            }

            // if (ch!=)

            if (ch == 58) {
                var str = $elem.val();
                if (str.indexOf(':') !== -1) return false
            }

        })


    }

    can_select_user() {
        //console.log(this.item)
        if (this.item._required_user) return true;
        return this.authService.acl('/calendar', 'select_user')
    }

    @ViewChild('win') win

 //   @ViewChild('appointment_cancel') appointment_cancel_popup

    open(callback) {

       // console.log('open',this.item,this.currentDate)
        if (this.item.cancellation_id) {

            if (this.isRoutine) {

                if (this.item.repeat) {
                    this._routine_cancellation = this._routine_cancellation_array.map( item=>{return item})
                }
                else {
                    this._routine_cancellation = this._routine_cancellation_array.filter( item=>{return item.id == 1})

                }
                for (let item of this._routine_cancellation) {
                    item.name = this.routine_cancellation_name(item.id)
                }


                this.routine_cancel_popup.open(() => {
                    this.routine_cancel_popup.close()
                    if (callback) callback()
                })
            }
            else {

                this.cancel_reason.item = this.item
                this.cancel_reason.openWin(() => {

                    //this.cancel_reason.close()
                    if (callback) callback()

                    //this.win.okClick()

                })

                //this.appointment_cancel_popup.open(() => {
                //    this.appointment_cancel_popup.close()
                //    if (callback) callback()
                //})

            }
        }
        else {
            this.win.open(callback)
        }
    }

    close() {
        this.win.close()
    }

    @ViewChild('cancel_reason') cancel_reason
/*
    cancelAppointment() {
        // this.cancel_reason.item = this.item
        this.cancel_reason.openWin(() => {
            this.item = Object.assign(this.item, this.cancel_reason.item)
            this.item.cancel = true
            this.win.okClick()
            console.log('cancel Appointment', this.cancel_reason.item)
        })
    }
*/
    copyAppointment() {
        this.copy = true
        delete this.item.id
    }


    get longFormat() {
        return {
            dateFormat: 'D, dd M yy' // 'DD, dd MM yy'
        }
    }

    _repeat = [
        {id: 0, name: 'None'},
        {id: 1, name: 'Daily'},
        {id: 2, name: 'Every weekday'},
        {id: 3, name: 'Weekly on'},

    ]

    _end_repeat = [
        {id: 0, name: 'Never'},
        {id: 1, name: 'On date'},
    ]

    _week_days = [
        {id: 0, name: 'Su', checked: 1},
        {id: 1, name: 'Mo'},
        {id: 2, name: 'Tu'},
        {id: 3, name: 'We', checked: true},
        {id: 4, name: 'Th', checked: false},
        {id: 5, name: 'Fr'},
        {id: 6, name: 'Sa'},

    ]


    get shortFormat() {
        return {
            dateFormat: 'D, dd M yy' // 'DD, dd MM yy'
        }
    }

    datepicker_icon($event) {
        //   if (this.disabled) return;

       // console.log($event)

        $event.stopPropagation();
        $event.preventDefault()

        let $input = $($event.target).closest('div.input-group').children(':input')


        //let $input = $(this.el.nativeElement).find('input') //.select().focus()
        let focused = $input.is(':focus')
        //  console.log('focused',focused)

        if (focused) {
            $input.blur() // blur()
            $input.datepicker("hide")
        }
        else {
            if ($input.val()) {
                //  $input.select().focus()
                $input.focus()
            }
            else {
                $input.focus()

            }

        }

    }

    @ViewChild('routine_cancel') routine_cancel_popup

    routine_cancellation_name(id) {

        //console.log('routine_cancellation_name',id )
        switch (+id) {
            case 1: return 'This event on '+ this.currentDate;
            case 2: return 'This event and all future events';
            case 3: return 'All future events after '+ this.currentDate;

            default:
                return ''
        }
    }

    _routine_cancellation_array =
        [
            {id: 1, name: 'This event'},
            {id: 2, name: 'All future events'},
            {id: 3, name: 'This event and all future events'}
        ]


    _routine_cancellation =
        [
            {id: 1, name: 'This event'},
            {id: 2, name: 'All future events'},
            {id: 3, name: 'This event and all future events'}
        ]

    /*

    cancel() {
        for (let item of this._routine_cancellation) {
            item.name = this.routine_cancellation_name(item.id)
        }
        this.item.routine_cancellation_id = 1

        this.routine_cancel_popup.open(()=> {
          this.routine_cancel_popup.close()
        })
    }
    */

    cancellationCancel() {
        delete this.item.cancellation_id
        this.routine_cancel_popup.close()
    }

    cancelAppointment() {


        if (this.isRoutine) {

            if (this.item.repeat) {
                this._routine_cancellation = this._routine_cancellation_array.map( item=>{return item})
            }
            else {
                this._routine_cancellation = this._routine_cancellation_array.filter( item=>{return item.id == 1})

            }
            for (let item of this._routine_cancellation) {
                item.name = this.routine_cancellation_name(item.id)
            }

            this.item.cancellation_id = 1
            this.routine_cancel_popup.open(() => {
                this.routine_cancel_popup.close()

                //this.item.cancellation_id = 1
                this.win.okClick()
            })
        }
        else {

            this.cancel_reason.item = this.item
            this.cancel_reason.openWin(() => {
                // this.item = this.cancel_reason
                //  this.item = Object.assign(this.item, this.cancel_reason.item)
                //  this.item.cancel = true

                this.item.cancellation_id = 1
                this.win.okClick()
            })

            //this.appointment_cancel_popup.open(() => {
            //    this.appointment_cancel_popup.close()
            //    if (callback) callback()
            //})

        }
        /*
        // this.cancel_reason.item = this.item
        this.cancel_reason.openWin(() => {
            this.item = Object.assign(this.item, this.cancel_reason.item)
            this.item.cancel = true
            this.win.okClick()
            console.log('cancel Appointment', this.cancel_reason.item)
        })
        */
    }

    openHealthRecord(patient_id) {
        this.authService.navigate(['/addressbook',patient_id]);
    }


}