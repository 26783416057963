import { NgModule }      from "@angular/core";
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { RouterModule }   from '@angular/router';

//import { PaginationModule }          from 'ng2-bootstrap/ng2-bootstrap';

//-------------------------------------------------------------------------------------------

import {EhisControlModule}  from '../ehis-control/ehis-control.module';
//import {UserProfileModule}  from  '../user/user-profile.module';
import {UserModule} from "../user/user.module"
//-----------------------------------------------------------------------------------------------

import { UserService}    from '../service/user.service';
import { MessageService} from './message.service';

import {UserMessage}              from "./user-message/user-message.component";
import {UserContactList}          from "./user-contact-list/user-contact-list.component";
import {UserThreadList}           from "./user-thread-list/user-thread-list.component";
import {UserMessageList}          from "./user-message-list/user-message-list.component";
import {WidgetControlModule} from "../widget-control/widget-control.module";

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";
import {MessageBreadcrumbsComponent} from "./message-breadcrumbs.component";
import {AuthModule} from "../auth/auth.module";

import {MessageThreadService} from "./message-thread.service";
import {MessageContactService} from "./message-contact.service";
import {MessagePostService} from "./message-post.service";

//-----------------------------------------------------------------------------------------------

export {UserMessage};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

 //       PaginationModule,
      //  EhisControlModule,
       // UserProfileModule,
        UserModule,
        AuthModule,

        WidgetControlModule,
        ControlModule,
        SmartadminModule,
        SmartadminInputModule,

    ],
    declarations: [
        UserMessage,

        UserThreadList,
        UserContactList,
        UserMessageList,

        MessageBreadcrumbsComponent,
    ],
    exports: [
    ],

    providers: [
   //   UserService,
      MessageService,
      MessageThreadService,
        MessageContactService,
        MessagePostService
    ]

})
export class MessageModule {}
