import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'enabled'
})

export class EnabledPipe implements PipeTransform {
    transform(value: any, format: string = ""): string {

        return +value ? "Enabled" : "Disabled"
    }
}