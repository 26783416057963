

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {I18nModule} from "../../i18n/i18n.module";
import {BigBreadcrumbsComponent} from "./big-breadcrumbs.component";
import {MinifyMenuComponent} from "./minify-menu.component";
import {NavigationComponent} from "./navigation.component";
import {SmartMenuDirective} from "./smart-menu.directive";
import {SmUserModule} from "../../user/sm-user.module";
import {RouterModule} from "@angular/router";
import {ChatModule} from "../../chat/chat.module";
import {MenuComponent} from "./menu.component";
import {ClinicService} from "../../../billing/billing-service.service";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    SmUserModule,
    ChatModule
  ],
  declarations: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    NavigationComponent,
    SmartMenuDirective,
    MenuComponent,
  ],
  exports: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    NavigationComponent,
    SmartMenuDirective,
  ], 
  providers: [
    ClinicService,
  ]
})
export class NavigationModule{}
