import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices/invoices.component';
import { ServicesComponent } from './services/services.component';
import { ExportInvoiceComponent } from './export-invoice/export-invoice.component';
import { WidgetModule } from "../widget/widget.module";
import { BillingServiceService, ClinicService, InvoicingService, EmailService } from './billing-service.service';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { AddServicesComponent } from './add-services/add-services.component';
import {ModalModule} from "ngx-modal";
import {ControlModule} from "../controls/controls.module";
import { AddInvoiceServiceComponent } from './add-invoice-service/add-invoice-service.component';

export const routes = [
  { path: 'services', component: ServicesComponent },
  { path: 'bills', component: InvoicesComponent, pathMatch: 'full'},
  { path: 'bills/:id', component: AddInvoiceComponent}
];

@NgModule({
  declarations: [InvoicesComponent, ServicesComponent, AddInvoiceComponent, AddServicesComponent, AddInvoiceServiceComponent, ExportInvoiceComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    WidgetModule,
    ModalModule,
    ControlModule,
    FormsModule
  ],
  providers: [
    BillingServiceService,
    InvoicingService,
    ClinicService,
    EmailService,
    ExportInvoiceComponent
  ]
})
export class BillingModule { }
