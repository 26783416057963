import {Directive, Input, Output,ElementRef,EventEmitter,ViewContainerRef} from '@angular/core';
// import {isUndefined} from "util";

declare var $:any;

import * as moment from 'moment';

//import 'script-loader!jquery-validation'


@Directive({
  selector: '[validation]'
})
export class AuthValidClickDirective {

  @Output() validClick: any = new EventEmitter;
  @Input()  validation: any;
  @Output() onErrorTab: any = new EventEmitter;

  //@Input('parent')

  parent = null;

  isModal = false;  /// ?++++

  predefinedOptions = {
    // Rules for form validation
    rules: {
      email: {required: true, email: true},
      password: {required: true, rangelength: [8, 48]},
      password_confirm: {equalTo: "#password"},
    },

    messages: {
      login: {
        required: 'Please enter your login'
      },
      email: {
        required: 'Please enter your email address',
        email: 'Please enter a VALID email address'
      },
      password: {
        required: 'Please enter your password'
      },
      password_confirm: {
        required: 'Please enter your password one more time',
        equalTo: 'Please enter the same password' //  as above'
      },
      firstname: {
        required: 'Please select your first name'
      },
      lastname: {
        required: 'Please select your last name'
      },
      gender: {
        required: 'Please select your gender'
      },
      terms: {
        required: 'You must agree with Terms and Conditions'
      }


    },
  }


  findForm($a) {

    var $form = $a.closest('form');

    if (!$form[0]) {
      var $modal = $a.closest('.modal');
      if ($modal[0]) {
        this.isModal = true;
        $form = $modal.find('form');

      }
    }
    return $form;
  }

  getParentComponent(): any{
    return this.viewContainerRef[ '_view' ].component.parent;
  }

//  constructor(private el:ElementRef) {

   // console.log(el)

  constructor(private el:ElementRef,private viewContainerRef:ViewContainerRef) {


    this.parent = viewContainerRef[ '_view' ].component //.parent
     import('script-loader!jquery-validation').then(() => {
      this.attach()
    })

   // this.attach()





    const $a = $($(this.el.nativeElement))
    $a.on('click', (e) => {

      const $form = this.findForm($a); //$a.closest('form');
       if (this.isModal) {
        this.attach();   //????????
      }

      // ++++++++---------------------------------
      ///-----------------------------------------
      /*
      let validateCommonOptions:any = { rules: {}, messages: {}}

      $form.find('input[digits]').each(function () {


        var $input = $(this);
        //$input.attr('digits','1');

        var fieldName = $input.attr('name');

        if(fieldName) {
          if (!validateCommonOptions.messages[fieldName])
            validateCommonOptions.messages[fieldName] = {};

          if (!validateCommonOptions.rules[fieldName])
            validateCommonOptions.rules[fieldName] = {};

          let messageKey = 'digits'
          validateCommonOptions.rules[fieldName][messageKey] = true


          if ($input.attr('digits-error-message')) {
            let messageKey = 'digits'
            validateCommonOptions.messages[fieldName][messageKey] = $input.attr('digits-error-message');
   //         console.log(messageKey)
          }
        }
        //console.log($input.val(),'digits');
        //if ($input.attr('digits')) {
//
        //}
      });


      let x = $.extend(true,{},this.predefinedOptions, validateCommonOptions);
      console.log(x)
      $form.validate(x); //validateCommonOptions);
      */
      //--------------------------------------------------------------------------------

   //     $form.validate()
      if ($form.valid()) {
        this.validClick.next();
      }
 //     e.stopPropagation();
 //     return false;
      return true;
    })
  }

/*
  ngAfterViewInit() {
    System.import('script-loader!jquery-validation').then(() => {

      this.attach()
    })


  }
  */

  selTab = function(t)  {this.selectTab(t)}

  attach() {
    //console.log('attach',this.el.nativeElement)
    const $form = this.findForm($(this.el.nativeElement)); //$(this.el.nativeElement).closest('form');
 //   console.log('1',$form);

    if (!$form[0]) {
 //     console.log('form not found')
      return;
    }
   //console.log('form is found');

   // this.attached = true; // not used

 //   $form.attr('saValidation','saValidation');
    $form.attr('novalidate','novalidate'); //

    /*****************************************
    jQuery('#userEditForm').validate({
      ignore: ".ignore",
      invalidHandler: function(e, validator){
        if(validator.errorList.length)
          $('#tabs a[href="#' + jQuery(validator.errorList[0].element).closest(".tab-pane").attr('id') + '"]').tab('show')
      }
    });
     *****************************************/


    const validateCommonOptions = {
      rules: {},
      messages: {},
      errorElement:  'em', //'small', //
      errorClass: 'invalid',


      ignore: ".editor",  // ??????  // default :hidden

      /*
      invalidHandler: (e, validator) => {

//        var onSel = this.selectTab;

      //  console.log('event',e);

        if(validator.errorList.length) {
    //      console.log('invalidHandler',$(validator.errorList[0].element.closest("tab")).attr('id'))

          let $tab = $(validator.errorList[0].element.closest("tab"));
          if ($tab[0]) {
            let tab_id = $tab.attr('id');

            if (tab_id !== undefined) {

              var tab = tab_id.replace('tab', '').replace('-','').trim();

              //       $(validator.errorList[0].element.closest("tab")).addClass('active');
              //console.log($tab)

              //var onSel = this.selectTab.bind(this);
              //console.log($('a#tab-' + tab))
              //$('a#tab-' + tab).tab('show');
              //$('a#tab-' + tab).click();

              this.onErrorTab.emit(+tab);
            }
          }
          else {
           // console.log('no tab');
          }


          //onSel(tab);
         // validator.options.selTab(tab)
          //let tab = $(validator.errorList[0].element.closest("tab")).attr('active','true');
          //this.tab = tab;

          //console.log(this);
          // tab.active = true;
          //this.selectTab(tab_id);
          //$('#tabs a[href="#' + jQuery(validator.errorList[0].element.closest("tab")).attr('id') + '"]').tab('show')
        }
      },
      */


      highlight: (element, errorClass, validClass)=> {
        $(element).addClass(errorClass).removeClass(validClass);
        $(element).parent().addClass('state-error').removeClass('state-success');
      },
      unhighlight: (element, errorClass, validClass) => {
        //console.log('unhighlight')
        $(element).removeClass(errorClass).addClass(validClass);
        $(element).parent().removeClass('state-error').addClass('state-success');
      },

      errorPlacement: (error, element) => {
        if((element.hasClass('select2') || element.hasClass('select4')) && element.next('.select2-container').length) {
              error.insertAfter(element.next('.select2-container'));
          }

          else if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        }
          else {
          error.insertAfter(element);
        }
      },

      /*submitHandler: function(form) {
        // do other things for a valid form
        //form.submit();
      }
      */

    };

   // https://forum.jquery.com/topic/adding-custom-rule-to-jquery-validate-25-3-2013
   // https://gist.github.com/ianoxley/1118247/58d98fad14b005cc4c482136340cee7a27b7900f
   //------------------------------------------------------------------------------------

    //console.log('attach')
    $.validator.addMethod("emails", function (value, element) {
     // console.log('emails validator')
      if (this.optional(element)) {
        return true;
      }
      var emails = value.split(','),
          valid = true;
      for (var i = 0, limit = emails.length; i < limit; i++) {
        value = emails[i].trim();
        valid = valid && $.validator.methods.email.call(this, value, element);
      }
      return valid;
    }, "Invalid email format (please use a comma to separate multiple email addresses).");


    //------------------------------------------------------------------------------------

    $.validator.addMethod("dayofmonth",  function( value, element, param ) {

      // Bind to the blur event of the target in order to revalidate whenever the target field is updated
      var target = $( param );
      if ( this.settings.onfocusout && target.not( ".validate-equalTo-blur" ).length ) {
        target.addClass( "validate-equalTo-blur" ).on( "blur.validate-equalTo", function() {
          $( element ).valid();
        } );
      }
      var m = +target.val();

      //console.log(target.val())
      var month = m < 1 ? 1 : (m > 12 ? 12 : m);

      var y = new Date().getFullYear();

      var days = new Date(y, month, 0).getDate();

      return (+value > 0) && (+value <= days);
    }, "Invalid day of month");

    $.validator.addMethod("time",  function( value,element,param) {

      //console.log('time validator',value,param)

      if (this.optional(element)) {
        return true;
      }
      let [hh,mm] = value.split(':');

      //if (!((+hh) && (+mm))) {
       // return false;
      //}

      let str = `${hh}:${mm}`

      //console.log(str)
      //let dateObj = new Date('2016-01-01 '+str);
      let dateObj = moment('2015-07-06 '+str, 'YYYY-MM-DD HH:mm').toDate();

      if ((dateObj.getHours() != +hh) || (dateObj.getMinutes() != (+mm)) ) {

        return false;
      }
      return true;

    }, "Should be valid time hh:mm");


    $.validator.addMethod("datem",  function( value,element,param) {

      if (this.optional(element)) {
        return true;
      }
      let [dd,mm, yyyy] = value.split('/');


      //if (!((+dd) && (+mm) && (+yyyy))) {
      //  return err;
      //}

      let dateStr = `${yyyy}-${mm}-${dd}`

      let dateObj = moment(dateStr, 'YYYY-MM-DD').toDate();

      if ((dateObj.getDate() != +dd) || (dateObj.getMonth()+1 != (+mm)) || (dateObj.getFullYear() != (+yyyy))) {
        //console.log(dateObj.getDate(),+dd);
        return false;
      }
      return true;
    }, "Please enter a valid date dd/mm/yyyy");


    $.validator.addMethod("alphanum", function(value, element) {
      return this.optional(element) || /^[\w._-]+$/i.test(value);
    }, "Letters, numbers, and underscores only please");

    //------------------------------------------------------------------------------------
    /*
    $(this.el.nativeElement).find('input').each(function () {

      var $input = $(this);
      console.log('input',$input)
      if ($input.attr('digits')) {
         console.log('digits');
      }
    });
    */
    /*
      $form.find('input').each(function () {
          var $input = $(this), fieldName = $input.attr('name');
          if ($input.attr('emails') != undefined) {   // <input ....  emails="true" ... >
                 console.log(fieldName);
              validateCommonOptions.rules[fieldName].emails = true;
          }
      })
    */
      $form.find('[data-smart-validate-input], [smart-validate-input]').each(function () {
  //    $form.find('input, textarea').each(function () {

        var $input = $(this), fieldName = $input.attr('name');

      validateCommonOptions.rules[fieldName] = {};

      //console.log('fieldname',fieldName)

      if ($input.data('required') != undefined) {
        validateCommonOptions.rules[fieldName].required = true;
      }
      else {
        validateCommonOptions.rules[fieldName].required = false;

      }

      //  console.log(fieldName);
        if ($input.data('emails') != undefined) {   // <input ....  emails="true" ... >
       //   console.log(fieldName);

            validateCommonOptions.rules[fieldName].emails = true;
        }

        if ($input.data('email') != undefined) {
        validateCommonOptions.rules[fieldName].email = true;
      }
      else {
        validateCommonOptions.rules[fieldName].email = false;

      }

      if ($input.data('maxlength') != undefined) {
        validateCommonOptions.rules[fieldName].maxlength = $input.data('maxlength');
      }

      if ($input.data('minlength') != undefined) {
        validateCommonOptions.rules[fieldName].minlength = $input.data('minlength');
      }

      if ($input.data('message')) {
        validateCommonOptions.messages[fieldName] = $input.data('message');
      } else {
        Object.keys($input.data()).forEach((key) =>{
          if (key.search(/message/) == 0) {
            if (!validateCommonOptions.messages[fieldName])
              validateCommonOptions.messages[fieldName] = {};

            var messageKey = key.toLowerCase().replace(/^message/, '')
            validateCommonOptions.messages[fieldName][messageKey] = $input.data(key);
          }
        });
      }
    });


    this.validation = {
      rules: {
        professional_reg: {
          alphanum: true
        },
      },

      messages: {
        professional_reg: {
          alphanum : 'not alpanum'
        }
      }
    }

   // let x = $.extend(true,{},this.predefinedOptions, validateCommonOptions, this.validation);


    let x = $.extend(true,{ focusInvalid: false,

    //  invalidHandler: function(form, validator) {
        invalidHandler: (form, validator) => {

        if (!validator.numberOfInvalids())
          return;

        //$('#myTab1 a[href="#' + jQuery(validator.errorList[0].element).closest("tab").attr('id') + '"]').tab('show')

         let $a =$('a[href="#' + $(validator.errorList[0].element).closest(".tab-pane").attr('id') + '"]')
          $a.tab('show')
          //$a.click()

          //let tab = +$a.attr('data-tab')
          //this.parent.parent.selectTab(tab)



          //console.log('sss',s)
        /*

          $(validator.errorList[0].element).closest("tab").each((ind,element)=> {

            console.log('parent v',this.parent)
            this.parent.parent.selectTab(0)


        })
        */
        validator.errorList[0].element.focus()
        /*
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 320
        }, 1000);
        */
      }


    },validateCommonOptions);

    //console.log('validate',$form)
    $form.validate(x)
   }



}
