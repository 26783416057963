import { Action } from '@ngrx/store';

export enum UserActionTypes {
  setUserName = '[AppState] Set User name',
  setUserThenme = '[AppState] Set Theme name',
  setsideBarColor = '[AppState] Set Sidebar Color',
  setfontStyle = '[AppState] Set Font Style',
  setpagesColor = '[AppState] Set pages color',
  setsideBarFontColor = '[AppState] Set sidebar font color',
  setpagesFontColor = '[AppState] Set pages font color',
  setthemeLogo = '[AppState] Set theme logo',
  setAppMenu = '[AppState] Set App Menu Item'
}

export class setUserName implements Action {
  readonly type = UserActionTypes.setUserName;
  constructor(public payload: any) {

  }
}

export class setUserThenme implements Action {
  readonly type = UserActionTypes.setUserThenme;
  constructor(public payload: string) {

  }
}

export class setsideBarColor implements Action {
  readonly type = UserActionTypes.setsideBarColor;
  constructor(public payload: string) {

  }
}

export class setpagesColor implements Action {
  readonly type = UserActionTypes.setpagesColor;
  constructor(public payload: string) {

  }
}

export class setsideBarFontColor implements Action {
  readonly type = UserActionTypes.setsideBarFontColor;
  constructor(public payload: string) {

  }
}

export class setpagesFontColor implements Action {
  readonly type = UserActionTypes.setpagesFontColor;
  constructor(public payload: string) {

  }
}

export class setfontStyle implements Action {
  readonly type = UserActionTypes.setfontStyle;
  constructor(public payload: string) {

  }
}

export class setthemeLogo implements Action {
  readonly type = UserActionTypes.setthemeLogo;
  constructor(public payload: string) {

  }
}

export class setAppMenu implements Action {
  readonly type = UserActionTypes.setAppMenu;
  constructor(public payload: string) {

  }
}

export type UserActions = 
setUserName | 
setUserThenme | 
setsideBarColor | 
setfontStyle | 
setpagesColor | 
setsideBarFontColor | 
setpagesFontColor | 
setthemeLogo |
setAppMenu;

