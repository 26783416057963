import {Directive, Input, ElementRef, OnInit, HostBinding, HostListener, EventEmitter, Output} from '@angular/core';

import {UploadedFile} from "./load-image-file.class";
import {Ng2UploaderOptions} from "./load-image-options.class";
import {UploadRejected} from "./load-image-upload-rejected.class";


import {AuthService} from "../../auth/auth.service";

declare var $: any;

@Directive({
  selector: '[load-image]'
})
export class LoadImageDirective implements OnInit {

  @Input() saBootstrapValidator:any;


  @HostListener('submit')  s = ()=>{
    const bootstrapValidator = this.$form.data('bootstrapValidator');
    bootstrapValidator.validate();
    if(bootstrapValidator.isValid())
      this.$form.submit();
    else return;
  }

  constructor(private el:ElementRef,public authService:AuthService) {

      this.opts = new Ng2UploaderOptions(authService.uploadOptions())
    //  console.log('imageLoad constructor')

  }

  ngOnInit(){
 //   console.log('imageLoad ngOnInit')
  }

  private $form: any;


  private attach() {

    this.$form = $(this.el.nativeElement)
    this.$form.bootstrapValidator(this.saBootstrapValidator || {})

    this.$form.submit(function(ev){ev.preventDefault();});

  }

  //--------------------------------------------------------------------------------

    opts: Ng2UploaderOptions;

    @Output('onUpload') _emitter = new EventEmitter<any>();
    @Output() _previewEmitter = new EventEmitter<any>();
   @Output('beforeUpload') _beforeEmitter = new EventEmitter<any>();
    @Output('onUploadRejected')  onUploadRejected = new EventEmitter<any>();

  /*
    setOptions(opts: Ng2UploaderOptions) {
        this.opts = opts;
    }
    */

   uploadFilesInQueue(): void {
        let newFiles = this._queue.filter((f) => { return !f.uploading; });
        newFiles.forEach((f) => {
            this.uploadFile(f);
        });
    };


    uploadFile(file: any): void {
        let xhr = new XMLHttpRequest();
        let form = new FormData();
        form.append(this.opts.fieldName, file, file.name);

        Object.keys(this.opts.data).forEach(k => {
            form.append(k, this.opts.data[k]);
        });

        let uploadingFile = new UploadedFile(
            this.generateRandomIndex(),
            file.name,
            file.size
        );

        let queueIndex = this._queue.indexOf(file);

        let time: number = new Date().getTime();
        let load = 0;
        let speed = 0;
        let speedHumanized: string = null;

        xhr.upload.onprogress = (e: ProgressEvent) => {
            if (e.lengthComputable) {
                if (this.opts.calculateSpeed) {
                    time = new Date().getTime() - time;
                    load = e.loaded - load;
                    speed = load / time * 1000;
                    speed = parseInt(<any>speed, 10);
                    speedHumanized = this.humanizeBytes(speed);
                }

                let percent = Math.round(e.loaded / e.total * 100);
                if (speed === 0) {
                    uploadingFile.setProgres({
                        total: e.total,
                        loaded: e.loaded,
                        percent: percent
                    });
                } else {
                    uploadingFile.setProgres({
                        total: e.total,
                        loaded: e.loaded,
                        percent: percent,
                        speed: speed,
                        speedHumanized: speedHumanized
                    });
                }

                this._emitter.emit(uploadingFile);
            }
        };

        xhr.upload.onabort = (e: Event) => {
            uploadingFile.setAbort();
            this._emitter.emit(uploadingFile);
        };

        xhr.upload.onerror = (e: Event) => {
            uploadingFile.setError();
            this._emitter.emit(uploadingFile);
        };

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                uploadingFile.onFinished(
                    xhr.status,
                    xhr.statusText,
                    xhr.response
                );
                this.removeFileFromQueue(queueIndex);
                this._emitter.emit(uploadingFile);
             //   console.log('DOne')
            }
        };

        xhr.open(this.opts.method, this.opts.url, true);
        xhr.withCredentials = this.opts.withCredentials;

        if (this.opts.customHeaders) {
            Object.keys(this.opts.customHeaders).forEach((key) => {
                xhr.setRequestHeader(key, this.opts.customHeaders[key]);
            });
        }

        if (this.opts.authToken) {
            xhr.setRequestHeader('Authorization', `${this.opts.authTokenPrefix} ${this.opts.authToken}`);
        }

        this.beforeUpload(uploadingFile)

        if (!uploadingFile.abort) {
            this._beforeEmitter.emit(uploadingFile);
            xhr.send(form);
        } else {
            this.removeFileFromQueue(queueIndex);
        }
    }


    _queue: any[] = [];

    addFilesToQueue(files: File[]): void {
        this.clearQueue();
        [].forEach.call(files, (file: File, i: number) => {
            if (!this.inQueue(file)) {
                this._queue.push(file);
            }
        });

        if (this.opts.previewUrl) {
            [].forEach.call(files, (file: File) => this.createFileUrl(file));
        }

        if (this.opts.autoUpload) {
            this.uploadFilesInQueue();
        }
    }

    createFileUrl(file: File){
        let reader: FileReader = new FileReader();
        reader.addEventListener('load', () => {
            this._previewEmitter.emit(reader.result);
        });
        reader.readAsDataURL(file);
    }

    removeFileFromQueue(i: number): void {
        this._queue.splice(i, 1);
    }

    clearQueue(): void {
        this._queue = [];
    }

    getQueueSize(): number {
        return this._queue.length;
    }

    inQueue(file: any): boolean {
        let fileInQueue = this._queue.filter((f) => { return f === file; });
        return fileInQueue.length ? true : false;
    }

    generateRandomIndex(): string {
        return Math.random().toString(36).substring(7);
    }

    humanizeBytes(bytes: number): string {
        if (bytes === 0) {
            return '0 Byte';
        }
        let k = 1024;
        const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let i: number = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i] + '/s';
    }

    is_uploading = false

    beforeUpload(uploadingFile: any /* UploadedFile */) {

        let sizeLimit = this.authService.upload_max_size();


        if (uploadingFile.size > sizeLimit) {
            uploadingFile.setAbort();
            let errMsg = 'File is too large! Maximum allowed file size is '+ this.authService.upload_max_size_str()+'.';
            this.authService.showError(errMsg);
        }
        else {

            this.is_uploading = true;
//        this.authService.spinnerStart();
        }
    }

    files

    @HostListener('change') onChange(): void {

  //      console.log('change')

        this.files = this.el.nativeElement.files;
        if (!this.files) {
            console.log('return');
            return;
        }

        let options = this.opts
    //    console.log(this.files,this.opts)

        if (options.filterExtensions && options.allowedExtensions) {
            //     this.files = this.files.filter(f => {
            this.files = [].filter.call(this.files, f => {

                if (options.allowedExtensions.indexOf(f.type) !== -1) {
                    return true;
                }

                let ext: string = f.name.split('.').pop();
                if (options.allowedExtensions.indexOf(ext) !== -1 ) {
                    return true;
                }


                this.onUploadRejected.emit({file: f, reason: UploadRejected.EXTENSION_NOT_ALLOWED});

                return false;
            });
        }

        if (this.files.length) {
       //     this.uploader.addFilesToQueue(this.files);
            this.addFilesToQueue(this.files);
        }
    }


}
