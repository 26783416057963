import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import {AuthModule} from "../auth/auth.module";
// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap';


import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!


import { PaymentService} from './payment.service';

import {PaymentList} from './list/payment-list.component'
import {PaymentDetail} from './detail/payment-detail.component'

export const routes = [
    { path: '', component: PaymentList , pathMatch: 'full' },
    { path: ':id', component: PaymentDetail, canDeactivate: [SaveGuard],},
];

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";
import {MarketProductService} from "../market-product/market-product.service";
import {PaymentTable} from "./table/payment-table.component";
import {ShippingModule} from "../shipping/shipping.module";
import {InvoiceModule} from "../invoice/invoice.module"; // Masked input

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
//        ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        ButtonsModule,
        ControlModule,

 //       RouterModule.forChild(routes),

        SmartadminModule,
        SmartadminInputModule,

        ShippingModule,
        InvoiceModule

    ],


    declarations: [
        PaymentTable,
        PaymentList,
        PaymentDetail,
   ],

    exports: [
        PaymentList,
        PaymentDetail,
        PaymentTable

    ],
    providers: [
        SaveGuard,
        PaymentService,
    ]

})
export class PaymentModule {
}

