import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import {AuthModule} from "../auth/auth.module";
// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap';


//import { ModalModule }             from 'ng2-modal';
//import { TypeaheadModule }         from "ngx-bootstrap" //from 'ng2-bootstrap/ng2-bootstrap';

//import {EhisControlModule}  from '../ehis-control/ehis-control.module';

import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!


//import {AmoTable} from "./amo-table/amo-table.component"

import { InvoiceService} from './invoice.service';

import {InvoiceList} from './list/invoice-list.component'
import {InvoiceDetail} from './detail/invoice-detail.component'
import {InvoiceTable} from './table/invoice-table.component'

export const routes = [
    { path: '', component: InvoiceList , pathMatch: 'full' },
    { path: ':id', component: InvoiceDetail, canDeactivate: [SaveGuard],},
];

import {SmartadminModule} from '../shared/smartadmin.module'
import {SubscriptionTypeModule} from "../subscription-type/subscription-type.module";
import {InvoiceItemModule} from "../invoice-item/invoice-item.module";
import {UtilsModule} from "../shared/utils/utils.module";
import {ControlModule} from "../controls/controls.module";
import {WidgetModule} from "../widget/widget.module";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
//        ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        ButtonsModule,

 //       RouterModule.forChild(routes),

        SmartadminModule,
        UtilsModule,
        ControlModule,
        InvoiceItemModule,

        WidgetModule,
   ],

    declarations: [
        InvoiceList,
        InvoiceDetail,
        InvoiceTable,
   ],

    exports: [
        InvoiceTable
    ],

    providers: [
        SaveGuard,
        InvoiceService,
    ]

})
export class InvoiceModule  {
}

