import { Component,ViewEncapsulation } from '@angular/core';
import { Output, Input, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ElementRef } from '@angular/core';
declare var jQuery: any;

import {AddressService} from '../address.service'
import {BaseDetail}       from '../../auth/base-detail'

import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

//import { TypeaheadMatch } from 'ng2-bootstrap/components/typeahead/typeahead-match.class';


//import { Component, ViewEncapsulation, Injector } from '@angular/core';

import {BaseItem} from "../../auth/base-item"
//import {ActivityLogService} from "../../activity-log/activity-log.service";
import {finalize, map} from "rxjs/operators";
import {ProfessionalService} from "../../professional/professional.service";


@Component({
  selector: '[address-detail]',
  templateUrl: './address-detail.template.html',
 // styleUrls: [ './address-detail.style.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [AddressService],
})

export class AddressDetail extends BaseItem {
  @Output() backToList = new EventEmitter();
  @Input() item: any;

  @ViewChild('formAddProfessional') formAddProfessional;
  @ViewChild('health_professionals') health_professionals;
  @ViewChild('diagnoses') diagnoses;

  @ViewChild('formAddDS') formAddDS;
  @ViewChild('formEditDS') formEditDS;

 // @ViewChild('formEditHP') formEditHP;

  @ViewChild('formRemoveDS') formRemoveDS;
  @ViewChild('formRemoveHP') formRemoveHP;

  //@ViewChild('patient_chart') patient_chart;

  public myForm: FormGroup;   // diagnoses

  @ViewChild('formAccess') formAccess;
  @ViewChild('formCloseChart') formCloseChart;
  request: string = '';


  @ViewChild('ehis_diagnoses') ehis_diagnoses;
  @ViewChild('ehis_professionals') ehis_professionals;


  $el: any;

//  diagnosis_list :any= [];// { id:1, text: 'D1'},{id:2, text: 'D2'}];
//  health_professionals_data: any= [ { id:1, name: 'Name1'},{id:2, name: 'Name2'}];
  health_professionals_data: any= [];
  health_professionals_index = 0;

//  addedHealthProfessionalId = '';
  addedHealthProfessional: string ='';
  selectedHealthProfessionalId = '';
  selectedHealthProfessional: string ='';


  //--------------------------------------------------------------------------------------------

    ds_data: any[] =[];
    ds_index = 0;
    addedDS: string = '';

    //tab = 0;

  //--------------------------------------------------------------------------------------------

    removeDSText = '';
    removeHPText = '';
    removeIndex = -1;

    //--------------------------------------------------------------------------------------------

    _province: any[] = [];
    public doctors:Array<any> = [];



    // *** TEXT MASKS ********************************************************************************************

    public get social_insurance_mask(): any { return [ /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]; }

   fillArray(value, len) {
        var arr = [];
        for (var i = 0; i < len; i++) { arr.push(value) }
        return arr;
    }

    public get reg_mask(): any { return this.fillArray(/[\da-zA-Z' '\-]/,12) }

    // ************************************************************************************************************

    public typeaheadOnSelect(e:any):void {

//        console.log('Selected'); //  value: ', e);

        if (e.item) {
            this.selectedHealthProfessionalId = String(e.item.id);
            this.selectedHealthProfessional   = e.item.name;
        }
//        this.addedHealthProfessionalChange(e);
//        this.addedHealthProfessionalId = UnderscoreStringStaticExports()1;
    }

    addedHealthProfessionalId() {
        if (this.selectedHealthProfessional == this.addedHealthProfessional) {
            return this.selectedHealthProfessionalId;
        }
        else {
            return '';
        }

    }

    addHealthProfessionalOk() {
        this.health_professionals_data.push({id: this.addedHealthProfessionalId(), name: this.addedHealthProfessional});
        this.health_professionals.data = this.health_professionals_data;
        this.formAddProfessional.close();
        //console.log(this.health_professionals);
    }

//  error:string = ''

  constructor(el: ElementRef,protected route: ActivatedRoute,protected addressService: AddressService,
              private fb: FormBuilder,
              //private activityLogService:ActivityLogService
              private professionalService: ProfessionalService
  ) {

    super(el,route,addressService);

    this.$el = jQuery(el.nativeElement);

  }

 fullName():string {

     if (!this.isLoaded) {return ''};
     if (+this.item.id) { return this.item.full_name };
     return 'New'
  }

     addHealthProfessional($event) {
         this.formAddProfessional.open();
     }


  editHealthProfessional(index) {
     this.health_professionals_index = index;
     this.formAddProfessional.open();
  }

    deleteHealthProfessional(index) {
        this.health_professionals_index = index;
        this.formAddProfessional.open();
    }

    addDS($event) {
        this.addedDS = '';
        this.formAddDS.open();
    }

    addDSOk() {
        this.ds_data.push({id: '' , text: this.addedDS});
        this.diagnoses.data = this.ds_data;
        this.formEditDS.close();
    }

    editDS($event) {

        //console.log($event);
        this.ds_index = $event;
        this.addedDS = this.ds_data[this.ds_index].text;
        //console.log(this.addedDS);
        this.formEditDS.open();
    }

    editDSOk() {
        this.ds_data[this.ds_index].text =  this.addedDS;
        this.diagnoses.data = this.ds_data;
        this.formEditDS.close();
    }

    removeDS() {
        this.ds_data.splice(this.ds_index,1);
        this.diagnoses.data = this.ds_data;
        this.formEditDS.close();
    }


    // HP ----------------------------------------------------------------------------------------------
    /*
    removeHP() {
        this.health_professionals_data.splice(this.health_professionals_index,1);
        this.ds_data.splice(this.ds_index,1);
        this.health_professionals.data = this.health_professionals_data;
        this.formEditHP.close();
    }
    */
    // --------------------------------------------------------------------------------------------------
/*
    @ViewChild('phone1') phone1:any;

    onValidated() {

        let $el = jQuery('.phone1').parsley();

        $el.removeError('required');

        if (this.item.preferred_method_of_contact == 0) {
            if (!this.item.phone1 || this.item.phone1.trim().length==0) {
                //addError('rname, {message: , assert: , updateClass: true});
                //let $el = jQuery('.phone1').parsley();
                $el.addError('required', { message: 'Required field (phone1)' , updateClass: true});
                this.$parsleyForm.validationResult = false;
            }
        }

        console.log('onValidated:',this.$parsleyForm.validationResult);
//        this.$parsleyForm.validationResult = false;
    }

    onValidate() {

        console.log('onValidate',this.$parsleyForm.validationResult);
//        this.$parsleyForm.validationResult = false;
    }
*/

 /*   selectTab(tab) {
        if (tab!=this.tab) {
            this.tab = tab;
        }
    }
*/
    onSubmit(form) {

        for ( var i=0; i < 3; i++) {
            if (!this.isValid(`tab-${i}`)) {
                this.tab = i;
                return;
            }
        }

        if (this.isValid()) {
            this.onSave();
        }
    }

    onPmChange() {
        this.isValid('contact');
//        console.log('item.preferred_method_of_contact',this.item.preferred_method_of_contact);
    }




      ngOnInit(): void {
        //  console.log('ngOnInit');
        super.ngOnInit();

        let tab = +this.route.snapshot.params['tab'];
        if (tab) {
            this.tab = tab;
        }

        this.myForm = this.fb.group({
            //name: ['', [Validators.required, Validators.minLength(5)]],
          //  id: ['',]
            diagnos: this.fb.array([
                //this.initAddress(),
            ]),
            prof: this.fb.array([]),
        });


      }

    // Diagnoses ---------------------------------------------------------------------------

    initDiagnos(txt) {
        return this.fb.group({
            txt: [txt, Validators.required],
            //postcode: [postcode]
        });
    }

    diagnosArray() {
        return <FormArray>this.myForm.controls['diagnos'];
    }

    addDiagnos() {
        const control = this.diagnosArray();
        control.push(this.initDiagnos(''));
    }

    removeDiagnos(i: number) {
        const control = this.diagnosArray();
        this.removeDSText = control.value[i].txt;
        this.removeIndex = i;

        if (this.removeDSText) {
           this.formRemoveDS.open();
        }
        else {
            this.doRemoveDS();
        }
    }

    doRemoveDS() {
        const control = this.diagnosArray();
        control.removeAt(this.removeIndex);
    }

    readDiagnos(result) {
        let d = result.diagnosis_list;
        if (d) {
            const lst = JSON.parse(d);
            const control = this.diagnosArray();
            control.controls.length = 0;

            for (let item of lst) {
                control.push(this.initDiagnos(item.txt));
            }
        }
    }

    writeDiagnos(result) {
        result.diagnosis_list = JSON.stringify(this.myForm.value.diagnos);
    }

    // Professionals  ---------------------------------------------------------------------------

    initProf(id,name,phone) {
        return this.fb.group({
            id: [id,],
            name: [name, Validators.required],
            phone:[phone,]
        });
    }

    profArray() {
        return <FormArray>this.myForm.controls['prof'];
    }

    addProf() {
        const control = this.profArray();
        control.push(this.initProf('','',''));
    }

    removeProf(i: number) {
        const control = this.profArray();
        this.removeHPText = control.value[i].name;
        this.removeIndex = i;

        if (this.removeHPText) {
            this.formRemoveHP.open();
        }
        else {
            this.doRemoveHP();
        }
    }

    doRemoveHP() {
        const control = this.profArray();
        control.removeAt(this.removeIndex);
    }


/*
    readProf(result) {

        let d = result.health_professionals;
        if (d) {
            const lst = JSON.parse(d);
            const control = this.profArray();

            control.controls.length = 0;

            for (let item of lst) {
                control.push(this.initProf(item.id,item.name,item.phone ));
            }
        }

    }


    writeProf(result) {
        result.health_professionals = JSON.stringify(this.myForm.value.prof);

    }
    */

    //-----------------------------------------------------------------------------------------------

    onReadItem(result) {

        this.doctors  = result._user.map( (elem : any)=>{elem.name = elem.name + '  ' + (elem.member_id? elem.member_id : ''); return elem});

        if (this.ehis_diagnoses) {this.ehis_diagnoses.data = result.diagnosis_list}
        if (this.ehis_professionals) {this.ehis_professionals.data = result.health_professionals}

        this._province = result._province;

        this._professional_id = result.professional_id

        this._chart_opened = result._chart_opened

        delete result._professional_id
        delete result._chart_opened
    }

    onWriteItem(result) {

        if (this.ehis_diagnoses) {result.diagnosis_list = this.ehis_diagnoses.data}
        if (this.ehis_professionals) {result.health_professionals = this.ehis_professionals.data}
    }


    accessQuery = '';

    handleRead(result) {
        super.handleRead(result);

        if ((this.accessQuery == 'open') && (this.item._access)) this.baseService.authService.showMessage('The chart was successfully opened.');
        if ((this.accessQuery == 'close') && (!this.item._access)) this.baseService.authService.showMessage('The chart was successfully closed.');
        this.accessQuery = '';

    }


    doCloseOpenChart(access,data='') {

        this.addressService.setAccess(this.item.id,access,data)
          .subscribe(result=> {
                this.getDetail();
                //this.saveItem();
                this.accessQuery = access ? 'open' : 'close';
            },
                error=> {this.baseService.authService.showError(error)});
    }


    $parsleyModal : any = null;


    requestAccess() {


//        if (this.request.length > 0) {
        let $el:any = jQuery('.parsleyjs1');
        if ($el) {
            this.$parsleyModal = $el.parsley();
        }

        //console.log(this.$parsleyModal);
        if (this.$parsleyModal) {


        if (this.$parsleyModal.validate()) {
            this.doCloseOpenChart(1, this.request);
            this.formAccess.close();
        }
    }}

    //----------------------------------------------------------------

    reason = null;

    /*
    chartOk(mode,reason) {
        this.baseService.loading = true
        let item = {
            patient_id: this.item.id,
            user_id: this.baseService.authService.user_id(),
            reason:
        }
        this.professionalService.post(item) // Chart(this.item.id,this.baseService.authService.user_id(),mode,reason)
            .pipe(
                finalize(() => {
                    this.baseService.loading = false
                }),

            )
            .subscribe(
                result=>{
                    this._chart_opened = result.opened ? 'yes':'no'
                },
                error=> {this.baseService.authService.showError(error)}
            );

    }
    */

    openChartOk() {
        this.formAccess.close()
        //this.chartOk('open',this.reason)

        this.baseService.loading = true
/*
        let item = {
            patient_id: this.item.id,
            user_id: this.baseService.authService.user_id(),
            data: this.reason,
        }

        this.professionalService.post(item) // insert
            .pipe(
                finalize(() => {
                    this.baseService.loading = false
                }),

            )
            .subscribe(
                result=>{
                    this._professional_id = result.id
                    this._chart_opened = result._can_close_chart ? 'yes':'no'
                },
                error=> {this.baseService.authService.showError(error)}
            );

*/

        this.addressService.setAccess(this.item.id,1,this.reason)
            .pipe(
                finalize(() => {
                    this.baseService.loading = false
                }),

            )
            .subscribe(result=> {
                    this.getDetail();
                    //this.saveItem();
                    this.accessQuery = 'open';

                },
                error=> {this.baseService.authService.showError(error)});


    }

    openChart() {
        this.formAccess.open();
    }

    formAccessOnOpen() {
    }

    formAccessOnClose() {
        this.$parsleyModal = null;
    }


    closeChartOk() {
        this.formCloseChart.close();
/*
        this.baseService.loading = true

        console.log(this._professional_id)

        this.professionalService.delete(this._professional_id) // delete
            .pipe(
                finalize(() => {
                    this.baseService.loading = false
                }),

            )
            .subscribe(
                result=>{
                    this._professional_id = null
                    this._chart_opened = 'no'
                },
                error=> {this.baseService.authService.showError(error)}
            );

*/
//        this.chartOk('close','')


/*
        this.baseService.loading = true
        this.activityLogService.postChart(this.item.id,this.baseService.authService.user_id(),'close','')
            .pipe(
                finalize(() => {
                    this.baseService.loading = false
                }),

            )
            .subscribe(
                result=>{
                    this._chart_opened = result.opened ? 'yes':'no'
                },
                error=> {this.baseService.authService.showError(error)}
            );
*/

        this.addressService.setAccess(this.item.id,0,'')
            .subscribe(result=>{
               // console.log(result)
                this.tab = 0;
                this.back()

            },error=>{ this.baseService.authService.showError(error)});


    }


    closeChart() {
        this.formCloseChart.open();
    }

    //------------------------------------------------------------------------------------------------------------

    isValid(tap=null) {
        return true;  // затычка  на таб  ////
    }

    set tab(val) {
      //  console.log('tab',val)
        this._tab = val
    }

    //-------------------------------------------------------------------------------------------------------------

    _chart_opened = ''
    _professional_id = null


    hasOpenButton() {
        return this.isLoaded && this.item.id && this._chart_opened == 'no'
    }

    hasCloseButton() {
        return this.isLoaded && this.item.id && this._chart_opened == 'yes'
    }

    hasAccess() {
        return this.isLoaded && this.item.id && (this._chart_opened == 'yes' || this._chart_opened == 'full')
   }

    /*

    hasNoAccess() {
        return this.isLoaded && this.item.id && this._chart_opened == 'no'
    }
    */

    //------------------------------------------------------------------------------------------------------------

    hasDiagnoses() {
        return this.baseService.authService.hasDiagnoses()
    }

    hasCharts() {
        return this.baseService.authService.hasCharts()
    }

}
