/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import {Component, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

//import {createNumberMaskSuffix} from 'text-mask-addons/src/createNumberMaskSuffix.js';

import {createNumberMask} from '../../number-mask/createNumberMaskSuffix';


//import {API_URL} from '../../app.env';


import {ChartTemplateService} from '../../service/chart-template.service';

import {PdfService} from '../../service/pdf.service';


import {BaseDetail}  from '../../auth/base-detail';
import {BaseItem}  from '../../auth/base-item';
//import {ModalComponent } from '../../auth/modal-window/modal.component';

import { FormGroup, FormControl,FormArray } from '@angular/forms';
//import {ReactiveFormsModule} from "@angular/forms";

import {DynamicFormControlModel, DynamicFormService,DynamicOptionControlModel,DynamicFormValueControlModel,
    DynamicInputModel,DynamicTextAreaModel,DynamicRadioGroupModel,DynamicCheckboxModel,DynamicSelectModel,
    DynamicFormArrayModel,
    DynamicFormGroupModel } from "../../sp-dynamic-forms/core";

import { DynamicFormOption } from "../../sp-dynamic-forms/core";

import {DynamicNumberModel} from "../../sp-dynamic-forms/core";
import {DynamicFileModel}   from "../../sp-dynamic-forms/core";
import {
    DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT,
    DynamicAttachmentModel
} from "../../sp-dynamic-forms/core/src/model/attachment/dynamic-attachment.model";
import {deserializeValidator, deserializeValidators} from "../../sp-dynamic-forms/core/src/utils";
import {DYNAMIC_FORM_CONTROL_TYPE_ARRAY} from "../../sp-dynamic-forms/core/src/model/form-array/dynamic-form-array.model";
import {DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX} from "../../sp-dynamic-forms/core/src/model/checkbox/dynamic-checkbox.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
    DynamicCheckboxGroupModel
} from "../../sp-dynamic-forms/core/src/model/checkbox/dynamic-checkbox-group.model";
import {DYNAMIC_FORM_CONTROL_TYPE_GROUP} from "../../sp-dynamic-forms/core/src/model/form-group/dynamic-form-group.model";
import {DYNAMIC_FORM_CONTROL_TYPE_INPUT} from "../../sp-dynamic-forms/core/src/model/input/dynamic-input.model";
import {DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP} from "../../sp-dynamic-forms/core/src/model/radio/dynamic-radio-group.model";
import {DYNAMIC_FORM_CONTROL_TYPE_SELECT} from "../../sp-dynamic-forms/core/src/model/select/dynamic-select.model";
import {DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA} from "../../sp-dynamic-forms/core/src/model/textarea/dynamic-textarea.model";
import {DYNAMIC_FORM_CONTROL_TYPE_NUMBER} from "../../sp-dynamic-forms/core/src/model/number/dynamic-number.model";
import {DYNAMIC_FORM_CONTROL_TYPE_FILE} from "../../sp-dynamic-forms/core/src/model/file/dynamic-file.model";
import {ChartService} from "../../chart/chart.service";
import {dynamicInit} from "../../controls/dynamic/dynamic.component";


declare var $: any;


@Component({
    selector: 'chart-template-detail',
    templateUrl: './chart-template-detail.template.html',
//    providers: [ChartTemplateService],

})
export class ChartTemplateDetail extends BaseItem { //BaseDetail {

    @ViewChild('upload_hidden') upload_hidden; // = document.getElementById('module-component');

    dynamicFormModel: Array<DynamicFormControlModel> =[];

//    dynamicPreviewFormModel:  Array<DynamicFormControlModel> =[];

    selectedControlModel: DynamicFormControlModel = null;
    selectedUpdate: boolean = false;  // update Chart-template-detail.template ---> DynamicFormControlComponent.ngOnInit()

    dynamicOptions : Array<DynamicFormArrayModel> = [];
    formOptions: FormGroup;

//    myArrayControl: FormArray;

    readOnly: boolean = false;

//    check_min_length: boolean = true;

    formBuild: FormGroup;

    formPreview: FormGroup;


    @ViewChild('dynamic_form') dynamicForm;

    tab: number = 0;


    public get digitMask(): any { return [ /[1-9]/]; }
    public get minLengthMask():  any { return { mask: [ /\d/ ,/\d/,/\d/], guide: false} }
    public get precisionMask (): any { return { mask: [ /\d/ ], guide: false} }

    get currencyMask() { //https://github.com/text-mask/text-mask/tree/master/addons/#readme
        let options :any = { prefix: '', allowNegative: false,  };
        //if (this.unit) { options.suffix = ' ' + this.unit;}
        //if (+this.precision) { options.allowDecimal = true; options.decimalLimit = +this.precision }
        options.includeThousandsSeparator = true;

        options.allowDecimal = true; options.decimalLimit = 2;
        let m = createNumberMask(options);
        //console.log(m);
        return m;
    }

    uploadModel: DynamicOptionControlModel<any>;
    uploadIndex: number;



   constructor(el:ElementRef,protected route: ActivatedRoute,protected chartTemplateService: ChartTemplateService,
                private dynamicFormService: DynamicFormService,
                protected pdfService:PdfService,
                private chartService:ChartService     // ++convert

               ) {

        super(el,route,chartTemplateService);

        this.createBuild();
        //this.createPreviewFromBuild();
        this.createOptions();

    }

    uploadOptions() {
        return this.chartTemplateService.authService.uploadOptions();
    }

    fullName():string {
        if (!this.isLoaded) {return ''};
        return +this.item.id ? this.item.name : 'New';
    }


    onSubmit(form) {

        if (!this.isValid('tab-0')) {
            this.tab = 0;
            return;
        }

        if (!this.isValid('tab-1')) {
            this.tab = 1;
            return;
        }

        if (this.isValid()) {
            this.saveAndBack();
        }
    }


    createPreviewFromBuild(){
      //  this.dynamicForm.data = JSON.stringify(this.dynamicFormModel);
    }

    createBuild() {
        this.formBuild = this.dynamicFormService.createFormGroup(this.dynamicFormModel);

        this.formPreview = this.dynamicFormService.createFormGroup(this.dynamicFormModel);


        // internal initialization


        if (this.dynamicForm) {
           // console.log('dynamicForm', this.dynamicForm)
            this.dynamicForm.dynamicFormModel = this.dynamicFormModel;
            this.dynamicForm.createForm();
        }


    }




    onReadItem(result){

        this.readOnly = result.user_id  != this.baseService.authService.user_id();

        if (false && result.preview) { // convert
       //     if (false && result.preview) { // convert
            this.fromJSON(JSON.parse(result.preview))  // array

            result.model = this.modelArray
            result.model_data = this.modelData

            result.preview = null
            result.data = null

            //this.modelArray = []
            //this.modelData= {}

        }
        else { // new Template

            if (!result.model) {
                result.model = []
            }
            //result.data = result.data1     // convert

            if (!result.data) {
                result.data = {}
            }
        }

        dynamicInit(result.model,result.data)  // data1 --> data !!!
 //       this.initBuild(result.model,result.model_data)

        this.noChanges = (this.baseService.authService.user_id() != result.user_id);

        return




        if (result.data) {
            this.dynamicFormModel = this.dynamicFormService.fromJSON(JSON.parse(result.data));
            // for buider ....
        }
        else {
            this.dynamicFormModel = []; // this.dynamicFormService.fromJSON(JSON.parse(result.data));
        }
        this.selectModel(null);
        this.createBuild();

        this.fromJSON(JSON.parse(result.data))

       // this.dynamicForm.data = result.data; // result.preview;

    }

    @ViewChild('dyn1') dyn1

    onWriteItem(result) {
        if (!result.free)
            result.price = this.chartTemplateService.authService.numToSql(result.price);

        //this.dyn1.buildDone()
        //this.buildDone(result.model,result.model_data) //



        return

       // SP ++
        //
        //this.dynamicForm.data = JSON.stringify(this.dynamicFormModel);
        result.data = JSON.stringify(this.dynamicFormModel);

        result.preview = result.data; //this.dynamicForm.data;

    }

    newControlId (id_offset = 2){
        return 1+ Math.max(0,Math.max.apply(Math,this.dynamicFormModel.map(
                function(elem){ let elem_id = +elem.id.substring(id_offset); return elem_id? elem_id:0}
            )));
    }


    newColumnId(arr: any[]) {

        var id = 0;
        for (let elem of arr ) {
            let elem_id_str = elem.id.substring(2);
            if (+elem_id_str) { id = Math.max(id,+elem_id_str)};
        }
        id = id + 1;
        return `er${id}`;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////

    addModel(ctrl:DynamicFormControlModel){
        this.dynamicFormModel.push(ctrl);
        //this.createBuild();
        this.selectModel(ctrl);

       //https://stackoverflow.com/questions/1890995/jquery-scroll-to-bottom-of-page-iframe
        /*let elem2 = $(this.el.nativeElement).closest('#main') // find("#work_top")


        if (elem2) {
            let elem3 = elem2.children().first()
            if (elem3) {
                elem2.animate({ scrollTop: elem3.height() }, "slow");
            }
        }
        */

        let elem2 = $(this.el.nativeElement).find("li").last()


        if (elem2) {
            let elem3 = elem2.children().first()
            if (elem3) {
                elem2.animate({ scrollTop: elem3.height() }, "slow");
            }
        }

    }

    removeModel(ctrl) {
        let ind=this.dynamicFormModel.indexOf(ctrl);
        if (ind >= 0) {
            this.dynamicFormModel.splice(ind,1);
            //this.createBuild();
            this.selectModel(null);
        }
    }

/*
    selectModel1(ind){

        //console.log('select',ind);
        this.createBuild();
        this.selectedControlModel = this.dynamicFormModel[ind];
    }
*/

    selectModel(ctrl) {
        this.createBuild();
        this.selectedControlModel = ctrl;
        this.selectedUpdate = !this.selectedUpdate;

/*        if (this.selectedControlModel instanceof DynamicInputModel) {

            this.check_min_length = (<DynamicInputModel>this.selectedControlModel).minLength > 0;
        }
  */  }

    ///////////////////////////////////////////////////////////////////////////////////////////////

    addInput() {

        let id = this.newControlId();
        let e =  //<DynamicFormControlModel>
            new DynamicInputModel({

            id: `el${id}`,
            label: `Input ${id}`,
//            maxLength: 42,
            minLength: 0, //   > 0 ??? ==  required
            placeholder: '',
            required: true,

        }
//            ,
//            {
//            element: {label: "form-control-label", control: "form-control-sm  pull-xs-left"},
//            grid: {   label: "col-md-3", control: "col-md-9"}
//            }
        );
//        e.name = `el${id}`;
        this.addModel(e);
    }



    addPhoto() {

        let id = this.newControlId();
        let e =  new DynamicFileModel({

                id: `el${id}`,
                label: `Photo ${id}`,

            }
        );
        this.addModel(e);
    }


    addFile() {

        let id = this.newControlId();
        let e =  new DynamicAttachmentModel({

                id: `el${id}`,
                label: `File ${id}`,

            }
        );
        this.addModel(e);
    }


    newControlIdStr():string {
        let id = this.newControlId();
        return `el${id}`;
    }

    addTable() {

        let id = this.newControlId();
        let e =  new DynamicFormArrayModel({
            id: `el${id}`,
            label: `Table ${id}`,
            initialCount: 3,

            createGroup: () => {  // structure of the row ....
                return [
                    new DynamicInputModel({

                        id: 'er1',
                    }),
                    new DynamicInputModel({

                        id: 'er2',
                        //label: "F2",
                        //suffix: '$'
                    }),
                    new DynamicInputModel({

                        id: 'er3',
                        label: "F3",
                    }),
                ];
            }


        })
        this.addModel(e)
    }


    addTextArea() {

        let id = this.newControlId();
        let e =  <DynamicFormControlModel> new DynamicTextAreaModel({

            id: `el${id}`,
            label: `Text ${id}`,
            rows: 3,
            wrap: 'true',
            placeholder: '',
        });

        this.addModel(e);
    }

    addCheckBox() {

        let id = this.newControlId();
        let e =  <DynamicFormControlModel> new DynamicCheckboxModel({
            id: `el${id}`,
            label: "I do agree",
        });

        this.addModel(e);

        $
    }


    addRadios() {

        let id = this.newControlId();
        let e =  <DynamicFormControlModel> new DynamicRadioGroupModel({

            id: `el${id}`,
            label: "Example Radio Group",
            options: [
                {
                    label: "Option 1",
                    value: "option-1",
                },
                {
                    label: "Option 2",
                    value: "option-2"
                },
                {
                    label: "Option 3",
                    value: "option-3"
                }
            ],
            value: "option-3"
        });

        this.addModel(e);
    }

    modelArray =[]
    modelData:any =  {}


    addModelItem(modelClass) {
        let id = this.newControlId();

        let config: any = {
            id: `el${id}`
        }

        switch(modelClass) {

            case 'title':
                config.legend = `Title ${id}`
                var e =  new DynamicFormGroupModel(config)

                var m = {

                }
                break

        }
        this.addModel(e)
    }



    addSelect() {

        let id = this.newControlId();
        let e =  <DynamicFormControlModel> new DynamicSelectModel({
            id: `el${id}`,
            label: "Example select",
            options: [
                {
                    label: "Option 1",
                    value: "0",
                },
                {
                    label: "Option 2",
                    value: "1"
                },
                {
                    label: "Option 3",
                    value: "2"
                }
            ],
            value: "0"
        });

        this.addModel(e);
    }


/*
    clickControl(ctrl) {
        this.selectModel(ctrl);
 //       for (var formCtrlName in this.formBuild.controls ){
 //           console.log(this.formBuild.controls[formCtrlName]);
 //       }
    }
*/

/*
    clearPhoto() {

        this.uploadFile = null;
    }
*/
    addNumber() {

        let id = this.newControlId();
        let e =  new DynamicNumberModel({

//                inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,

                id: `el${id}`,
                label: `Number ${id}`,
//            maxLength: 42,
                minLength: 0, //   > 0 ??? ==  required
                placeholder: '',
                required: true,

            }
//            ,
//            {
//            element: {label: "form-control-label", control: "form-control-sm  pull-xs-left"},
//            grid: {   label: "col-md-3", control: "col-md-9"}
//            }
        );
//        e.name = `el${id}`;
        this.addModel(e);
    }




    /////////////////////////////////////////////////////////////////////////////////////////////////

    addItem(arrayModel: DynamicFormArrayModel,formGroup:FormGroup) {

        let formArray = <FormArray> formGroup.get(arrayModel.id);

        this.dynamicFormService.addFormArrayGroup(formArray,arrayModel);


        //let addedModelItem = this.dynamicFormService.createFormArrayGroup(arrayModel);
        //console.log(addedModelItem);
        //formArray.push(addedModelItem);

    }

    removeItem(arrayModel: DynamicFormArrayModel,formGroup:FormGroup,index:number) {

        let formArray = <FormArray> formGroup.get(arrayModel.id);

        arrayModel.removeGroup(index);
        formArray.removeAt(index);
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////

    insertItem(context: DynamicFormArrayModel, model,index: number) {
      //  console.log(model);
        let myArrayControl = <FormArray> this.formBuild.get("myFormArray");
        this.dynamicFormService.insertFormArrayGroup(index, myArrayControl, context);
    }

    createOptions() {
        this.formOptions = this.dynamicFormService.createFormGroup(this.dynamicOptions);
    }


    selectModel_old(ctrl){
//        console.log(ctrl);

        /*
         for (var model of this.dynamicFormModel ){
         (<any> model).selected = (model==ctrl );
         }
         */
        this.selectedControlModel = ctrl;

        this.dynamicOptions = [];
        if (this.selectedControlModel) {

            if (this.selectedControlModel instanceof DynamicOptionControlModel) {

                let model = this.selectedControlModel;
/*
                let elemArray = [];
                for (let opt of model.options ) {
                    //console.log(opt);
                    let elem = new DynamicInputModel({
                            id: this.newControlIdStr(),
                            value: opt.text,
                        });

                     elemArray.push(elem);
                }

                let e =  new DynamicFormArrayModel({
                    id: this.newControlIdStr(),
                    initialCount: 1,
                    createGroup: () => { return elemArray },
                })
                //console.log(e);
*/
                let title = ()=> {return [new DynamicInputModel({
                    id: this.newControlIdStr(),
                    value: 'Values',
                })]};
//                let elemArray = [title];

                let id_0 = this.newControlIdStr();
                let e =  new DynamicFormArrayModel({
                    id: id_0,
                    initialCount: 1, // + model.options.length,
                    createGroup: title, //te() => { return title },
                });

//                console.log(e);

                //let ind = 0;
                for (let opt of model.options ) {
//                    ind = ind + 1;
//                    e.groups[ind].group[0]._value = opt.text;
                    //console.log(opt);
//                    console.log(e);
                    let group = e.addGroup().group;
//                    console.log('group', group);  //group.index
//                    console.log('opt',opt.text, opt.label, opt.value);
                    group[0].value = opt.text;
                }
//                console.log(e);


                this.dynamicOptions = [e];
            }
        }

 //       console.log(this.dynamicOptions);
        this.createOptions();
    }



    changeOption(ind) {

//        console.log((<DynamicOptionControlModel<any>> this.selectedControlModel).options);
        (<DynamicOptionControlModel<any>> this.selectedControlModel).options[ind-1].label = this.dynamicOptions[0].groups[ind].group[0]._value;
//       console.log('changeOption',ind);
    }

    addOption() {

        let model = (<DynamicOptionControlModel<any>> this.selectedControlModel);
        let len = model.options.length;
        model.options.push(
            new DynamicFormOption({
                    value: len,
                    label: `Option ${len+1}`,
                })
        );
    }

    removeOption(ind) {

        let model = (<DynamicOptionControlModel<any>> this.selectedControlModel);
        model.options.splice(ind,1);

    }

    handleUpload(data): void {
        if (data && data.response) {
            data = JSON.parse(data.response);
            let link = data.src;
            if (this.uploadModel) {
                if (this.dynamicFormModel.indexOf(this.uploadModel) >= 0) {
                    if (this.uploadIndex >=0 && this.uploadIndex < this.uploadModel.options.length) {
                        this.uploadModel.options[this.uploadIndex].link = link;
                    }
                }
            }
            this.uploadModel = null;
            this.uploadIndex = -1;
        }
    }


    linkOption(ind) {

        let model = (<DynamicOptionControlModel<any>> this.selectedControlModel);

        this.uploadModel = model;
        this.uploadIndex = ind;

        this.upload_hidden.nativeElement.click();
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////


    addColumn() {

        let model = (<DynamicFormArrayModel> this.selectedControlModel);

        let newColumnId = this.newColumnId(model.originGroup);
        let newColumn = new DynamicInputModel({
            id: newColumnId,
            value: '',
        });
        model.originGroup.push(newColumn);
        model.createGroup = () => {return <any>(this.dynamicFormService.fromJSON(model.originGroup))};
        model.groups.forEach(group => { group.group.push(
            new DynamicInputModel({
                id: newColumnId,
                value: '',
            })
        );})

        this.selectModel(model);
    }

    removeColumn(ind) {

        let model = (<DynamicFormArrayModel> this.selectedControlModel);

        model.originGroup.splice(ind,1);
        model.createGroup = () => {return <any>(this.dynamicFormService.fromJSON(model.originGroup))};
        model.groups.forEach(group => { group.group.splice(ind,1);})

        this.selectModel(model);
        //this.selectedUpdate = !this.selectedUpdate;
    }

    //--------------------------------------------------------------------------------------------------------

    removeIndexColumn(ind) {

        let model = (<DynamicFormArrayModel> this.selectedControlModel);

        model.originGroup.splice(ind,1);
        model.createGroup = () => {return <any>(this.dynamicFormService.fromJSON(model.originGroup))};
        model.groups.forEach((group,idx) => { if (idx) {group.group.splice(ind,1)}})

        this.selectModel(model);
        //this.selectedUpdate = !this.selectedUpdate;
    }

    addIndexColumn() {

        let model = (<DynamicFormArrayModel> this.selectedControlModel);

        let newColumnId = this.newColumnId(model.originGroup);
        let newColumn = new DynamicInputModel({
            id: newColumnId,
            value: '',
        });
        model.originGroup.push(newColumn);
        model.createGroup = () => {return <any>(this.dynamicFormService.fromJSON(model.originGroup))};

         // object --> DynamicInputModel

        let row:any = model.groups[0].group
        let obj = row.pop()
        obj.id = newColumnId
        row.push(new DynamicInputModel(obj))

        model.groups.forEach((group,idx) => { if(idx) { group.group.push(
            new DynamicInputModel({
                id: newColumnId,
                value: '',
            })
        );}})

        this.selectModel(model);
    }


    //---------------------------------------------------------------------------------------------------------




    columnChange($event,id) {

        let model = (<DynamicFormArrayModel> this.selectedControlModel);
        let formArray = <FormArray> this.formBuild.get(model.id);
        let formTitle = <FormGroup> formArray.controls[0];
        let formControl = <FormControl> formTitle.get(id);

        formControl.setValue($event);
    }

    /*
    selectTab(tab) {

        if (tab!=this.tab) {
            this.tab = tab;

            if (tab==2) {
                this.createPreviewFromBuild();
            }

            if (tab==1) {
                this.createBuild();

            }
        }

    }
    */

    author() {
        return this.baseService.authService.isUser(this.item.user_id);
    }


    addGroup() {

        let id = this.newControlId();
        let e =  new DynamicFormGroupModel({
            id: `el${id}`,
            legend: `Title ${id}`,
            /*
                group: [
                    new DynamicSelectModel<string>(
                        {
                            id: "bootstrapSelect",
                            label: "Example Select",
                            options: [
                                {
                                    label: "Option 1",
                                    value: "option-1",
                                },
                                {
                                    label: "Option 2",
                                    value: "option-2"
                                },
                                {
                                    label: "Option 3",
                                    value: "option-3"
                                },
                                {
                                    label: "Option 4",
                                    value: "option-4"
                                }
                            ],
                            value: "option-3"
                        },
                        {
                            element: {
                                label: "control-label"
                            },
                            grid: {
                                control: "col-sm-9",
                                label: "col-sm-3"
                            }
                        }
                    ),

                    new DynamicInputModel(
                        {
                            hint: "Just a sample help text",
                            id: "bootstrapInput",
                            label: "Example Input",
                            maxLength: 51,
                            placeholder: "example input",
                        }

//                        {
//                            element: {
//                                label: "control-label"
//                            },
//                            grid: {
//                                control: "col-sm-9",
//                                errors: "col-sm-offset-3 col-sm-9",
//                                label: "col-sm-3"
//                            }
//                        }
                    ),

               ]
*/
            });

        this.addModel(e)
    }


    //------------------------------------------------------------------------------------------------------------

    onBack() {
        this.back();
    }

    back() {
        this.baseService.authService.navigate(this.backLink(),null); // null !!!s
    }

    backLink() {
       return ['/chart_template'];
    }

    //-------------------------------------------------------------------------------------------------------------

    isValid(form='') {   // base-detail
        return true;
    }



    @ViewChild('pdf_form') pdfForm;

    pdf() {

        let user = this.baseService.authService.user();
        let chartRec:any = {
            //_user:  user,
            //_occupation: user._occupation,
        };
       // if (this.tab!=0) {
       //     this.createPreviewFromBuild();
       // }
       // this.dynamicForm.data = JSON.stringify(this.dynamicFormModel);  // pdf

      //  this.pdfService.openChart(this.dynamicForm,chartRec);

        this.pdfService.openChart(this.dyn1,chartRec);
        return


        this.pdfForm.data = JSON.stringify(this.dynamicFormModel);  // pdf

        this.pdfService.openChart(this.pdfForm,chartRec);
    }


    // call_lookup() {  //used in SaveGuard
    //    return false;
    //}
/*
    initBuild(model,data) { // when using template

        if (model && data) {
            model.forEach(item => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = data[key]
                    if (!value && item.data && item.fixed) { // && item.fixed  ?
                        data[key] = item.data
                    }
                }
            })
        }
    }
    */
/*
    buildDone(model,data) {  // after edit template
        if (model && data ) {
            model.forEach(item => {
                if (item.class == 'array') {
                    let key = this.itemName(item)
                    let value = data[key]
                    if (value && item.fixed) {
                        item.data = value
                    }
                }
            })
        }

    }



    itemName(elem) {
        return (elem.name ? elem.name : 'e'+elem.id)
    }
    */

    // converting ....
    fromJSON(json: Array<Object>) {

       // let modelArray:any[] = []
        this.modelArray = []
        this.modelData= {}

        let id = 0

        json.forEach((object) => {

            id++;
            let name = 'e'+id;
            let modelItem:any = {

                class: object["type"].toLowerCase(),
                id: id,
                label: object['label'],

              //  options: {
              //    //  label: object['label']
              //  }

            }

            switch (object["type"]) {

                case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:

                    this.modelData[name] = object['value']
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_GROUP:

                    modelItem.class = 'legend'
   //                 modelItem.options.label = object['legend']
                    modelItem.label = object['legend']

                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:

                    modelItem.class= 'text'
     //               modelItem.options.hint =  object['placeholder']
     //               modelItem.options.rows =  object['rows']
     //               modelItem.hint =  object['placeholder']
                    modelItem.placeholder =  object['placeholder']
                    modelItem.rows =  object['rows']

                    this.modelData[name] = object['value']
                    break;


                case DYNAMIC_FORM_CONTROL_TYPE_ATTACHMENT:

                    modelItem.class = 'file'

                    this.modelData[name] = object['link']
                    this.modelData[name+'_filename'] = object['value']
                    break;


                case DYNAMIC_FORM_CONTROL_TYPE_FILE:

                    modelItem.class = 'photo'

                    this.modelData[name] = object['link']  // base64 .......
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_INPUT:

//                    modelItem.options.hint =  object['placeholder']
//                    modelItem.options.checkminlength = object['checkMinLength']
//                    modelItem.options.minlength =  object['minLength']
//                    modelItem.hint =  object['placeholder']
                    modelItem.placeholder =  object['placeholder']
                    modelItem.checkminlength = object['checkMinLength']
                    modelItem.minlength =  object['minLength']


                    //console.log(object)

                    this.modelData[name] = object['value']
                    break;


                case DYNAMIC_FORM_CONTROL_TYPE_SELECT:

                    let index= []
                    if (object['options']) {
                        for (let opt of object['options']) {
                            index.push( {
                                id: opt.value,
                                name: opt.label
                            })
                        }
                    }
      //              modelItem.options.index = index
                    modelItem.index = index

                    this.modelData[name] = object['value']
                    break;

                case DYNAMIC_FORM_CONTROL_TYPE_NUMBER:

                    modelItem.class= 'input'

       //             modelItem.options.number =  true
       //             modelItem.options.hint = object['placeholder']
       //             modelItem.options.precision =  object['precision']
        //            modelItem.options.unit = object['unit']
                    modelItem.number =  true
                    modelItem.placeholder = object['placeholder']
                    modelItem.precision =  object['precision']
                    modelItem.unit = object['unit']

                    this.modelData[name] = object['value']
                    break;


                case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:

                    modelItem.fixed = +object['fixedColumns'] != 0

               //     modelItem.options.rows = object['groups'].length

                    let data = []
                    let first = true
                    for(let grp of object['groups']) {
                        let item = []
                        for(let elem of grp.group) {
                            item.push(elem.value)
                        }
                        if (first) {
                            let idx = []
                            let i = 0
                            for (let elem of item) {
                                idx.push( { id: i, name: elem})
                                i = i +1;
                            }
                            first = false
                            modelItem.index = idx
                        }
                        else {
                            data.push(item)
                        }
                    }
                    modelItem.data = data

               //     console.log(object)
               //     console.log(data)

                    this.modelData[name] = data
                    break;



                case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:

                    let ops = []
                    let op_id = 0;
                    for(let option of object['options']) {
                        op_id++;
                        let item = {
                            id: op_id,
                            value: option.value,
                            name: option.label,
                            link: option.link
                        }
                        ops.push(item)
                    }

                    let val = object['value'];
                    let val_id =0
                    ops.forEach(item=> {
                        if (item.value==val) {
                            val_id = item.id
                        }
                        delete item.value
                    })

                    modelItem.class = "radios"
//                    modelItem.options.index = ops
                    modelItem.index = ops
                    //modelItem = null

                    this.modelData[name] = val_id

                   // console.log(object)
                    //console.log(this.modelData)

                    break;



                /*
                                               case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
                                                    object["group"] = this.fromJSON(object["group"]);
                                                    formModel.push(new DynamicCheckboxGroupModel(object, object["cls"]));
                                                    break;
                   */
                default:
                    throw new Error(`Unknown form control type defined in JSON object`);

            }

            if (modelItem) {
                this.modelArray.push(modelItem)
            }

        });

   //     console.log(this.modelArray)

    }

    // copy from dynamic
    /*
        fileName(elem) {

            return this.itemName(elem)+ '_filename'
        }


        formName(elem) {
            let name = null // this.$el.attr('name')
            if (!name) name = 'dynamic'
            return name + '_'+ this.itemName(elem)
        }

        getDragula() {
            return null
        }

        getDragulaModule() {
            return null
        }
        */

    ready() {
        return this.isLoaded && this.dyn1.loaded
    }


    convertTemplates() {
        this.chartTemplateService.getItems({per_page: 100},{convert:1})
            .subscribe(
                (result) => {

                    for(let rec of result.data) {
                        this.chartTemplateService.getItem(rec.id,{})
                            .subscribe(
                                (result) => {
                                    if (result.preview) { // convert
                                        this.fromJSON(JSON.parse(result.preview))  // array

                                        result.model = this.modelArray
                                        result.model_data = this.modelData

                                        //this.initBuild(result.model,result.model_data)


                                        //this.buildDone(result.model,result.model_data) //


                                        //delete result.preview
                                        //delete result.data
                                        //result.preview = null
                                        //result.data = null

                                        let newResult = {
                                            id: result.id,
                                            model: result.model,
                                            data1: result.model_data
                                        }

                                            this.chartTemplateService.post(newResult)
                                            .subscribe(
                                                (result)=> {},
                                                (error) => {
                                                    console.log(error)
                                                }
                                            )

                                    }


                                },
                                (error) => {
                                    console.log(error)
                                }
                            )


                    }

                },
                (error)=> {
                    console.log(error)
                }
            )
    }

    convertCharts() {
        this.chartService.getItems({per_page: 100},{convert:1})
            .subscribe(
                (result) => {

                    for(let rec of result.data) {
                        this.chartService.getItem(rec.id,{})
                            .subscribe(
                                (result) => {
                                    if (result.data) { // convert
                                        //this.fromJSON(JSON.parse(result.data))  // array

                                        this.fromJSON(result.data)

                                        result.model = this.modelArray
                                        result.data1 = this.modelData


                                        //result.model = this.modelArray
                                        //result.model_data = this.modelData

                                        //this.initBuild(result.model,result.data1)


                                        //this.buildDone(result.model,result.data1) //

                                        this.modelArray = []
                                        this.modelData = {}

                                         if (result.amend_data) {
                                             //this.fromJSON(JSON.parse(result.amend_data))
                                             this.fromJSON(result.amend_data)

                                             result.amend_data1 = this.modelData

                                             //this.initBuild(result.model,result.amend_data1)
                                             //this.buildDone(result.model,result.amend_data1) //


                                         }
                                        //delete result.preview
                                        //delete result.data
                                        //result.preview = null
                                        //result.data = null

                                        let newResult = {
                                            id: result.id,
                                            model: result.model,
                                            data1: result.data1,
                                            amend_data1: result.amend_data1,
                                            done: 2,
                                        }

                                        this.chartService.post(newResult)
                                            .subscribe(
                                                (result)=> {},
                                                (error) => {
                                                    console.log(error)
                                                }
                                            )

                                    }


                                },
                                (error) => {
                                    console.log(error)
                                }
                            )


                    }

                },
                (error)=> {
                    console.log(error)
                }
            )
    }



}