export const environment = {
  production: true,

  API_TWO: "https://qa.e-his.ca/invoice", //New QA
  API_URL: "https://qa.e-his.ca/core",
  
  API_REMEMBER_ME: false,
  STATELESS_APPLICATION: true,

  API_NOTIFICATION: false, // true;
  API_NOTIFICATION_INTERVAL: 30,    // sec

};
