import { Directive, ElementRef, OnInit,Input } from "@angular/core";
import { addClassName, removeClassName } from "../../../utils/dom-helpers";
//import "select2/dist/js/select2.min.js";
import "select2/dist/js/select2.js";

declare var $: any;

@Directive({
    selector: "[select2]"
})
export class Select2Directive implements OnInit {


    @Input('placeholder') placeholder: string = ' ';
    $el

    constructor(private el: ElementRef) {
        addClassName(this.el.nativeElement, ["sa-cloak", "sa-hidden"]);
        this.$el = $(el.nativeElement);

    }

    ngOnInit() {
/*
        this.$el.select2({
            placeholder: this.placeholder,
            //width: 'resolve',

            allowClear: true,
        }

        )
        */
        removeClassName(this.el.nativeElement, ["sa-hidden"]);
    }
}


/*

import {Directive, ElementRef, OnInit, Input,Output,EventEmitter} from '@angular/core';
import {addClassName, removeClassName} from "../../../utils/dom-helpers";

declare var $: any;

@Directive({
  selector: '[select2]'
})
export class Select2Directive implements OnInit{


  @Input ('storage') storage: string = '';
  @Input('placeholder') placeholder: string = ' ';

  @Output() public selected: EventEmitter<any> = new EventEmitter();

  $el:any;


  getValue() {
    let arr = this.$el.select2('val');
    return arr ?arr : [];
  }


  constructor(private el: ElementRef) {
       addClassName(this.el.nativeElement, ['sa-cloak', 'sa-hidden']);
       this.$el = $(el.nativeElement);


  }



  getData() {
    let data = this.getValue(); // this.$el.select2('data');
    this.selected.next(data)
    return data;
  }


  ngOnInit(){


    let val = this.storage ? localStorage.getItem(this.storage).split(',').filter((e)=>{ return +e}) : [];


    System.import('script-loader!select2/dist/js/select2.min.js').then(()=>{
      console.log('select2')

      //$(this.el.nativeElement).html('<option></option>').trigger('change');
      $(this.el.nativeElement).select2({

          placeholder: this.placeholder,
        //width: 'resolve',

          allowClear: true,

     //     data: []

      })

      removeClassName(this.el.nativeElement, ['sa-hidden'])


      $(this.el.nativeElement).on('select2:select', (e) => {
       this.getData();
      });

      $(this.el.nativeElement).on('select2:unselect', (e) => {
        this.getData();
      });

      //console.log('select2 on init')
      //$(this.el.nativeElement).select2().val(val);
      //$(this.el.nativeElement).select2().trigger('change');

    })
  }

  ngOnDestroy() {


    if (this.storage) {
      let s = this.getValue().join(',');
      localStorage.setItem(this.storage,s);
      console.log('setItem',this.storage,s)

    }
  }


}

*/
