
/* export */ const SUBSCRIPTION_PLAN_YEARLY_PREPAID = 130; // external payments

function  lookupSchema(item) {   // duplicate wizard-client -------------------------------------------

 let arr = item._subscription_type ? item._subscription_type : [];

 let id = item.subscription_type_id;
 for (let elem of arr) {
    if (elem.id == id) {
      return +elem.plan_schema;
    }
 }
 return 0;
}

//-----------------------------------------------------------------------------------

export function isYearlyPrePaid(item) {

  return lookupSchema(item) == SUBSCRIPTION_PLAN_YEARLY_PREPAID;
}
