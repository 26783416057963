import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

//import 'parsleyjs';

//import 'ng2-datetime/src/vendor/bootstrap-datepicker/bootstrap-datepicker.min.js';
//import { ButtonsModule, DropdownModule, PaginationModule  } from 'ng2-bootstrap/ng2-bootstrap';
//import { PaginationModule  } from 'ng2-bootstrap/ng2-bootstrap';

//import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';


import { EhisTextComponent } from './ehis-control/ehis-text.component';
import { EhisInfoComponent } from './ehis-control/ehis-info.component';
import { EhisLegendComponent } from './ehis-control/ehis-legend.component';

import {CanDeactivateGuard} from '../auth/can-deactivate-guard.service';

import {EhisControlModule} from '../ehis-control/ehis-control.module';
//----------------------------------------------------------------

//import { DataTableDirectives } from 'angular2-datatable/datatable';
//import { Ng2TableModule } from 'ng2-table';
//import { Ng2PaginationModule} from 'ng2-pagination'; // <-- import the module

import { ModalModule }               from 'ngx-modal';
import { TypeaheadModule }          from 'ngx-bootstrap/typeahead'; // from 'ng2-bootstrap/ng2-bootstrap';


import {DiagnosesComponent} from './diagnoses/diagnoses.component'
import {HealthProfessionalsComponent} from './health-professionals/health-professionals.component'


//import { Address } from './address.component';
import { AddressList } from './list/address-list.component';
import { AddressDetail } from './detail/address-detail.component';
import { AddressService} from './address.service';

//import { AddressSelect } from './select/address-select.component';

//-------------------------------------------------------------------


//import {PatientChartModule} from "../patient-chart/patient-chart.module";

//export { AddressList, AddressDetail};  // for Layout routing

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";

import { SaveGuard} from '../auth/save-guard.service';

import {DateMaskDirective} from "../ehis-directive/date-mask.directive"


import { TextMaskModule } from 'angular2-text-mask';


///--------------------------------------------------------------------------------

//import {DialogModule} from 'primeng/dialog';

export const routes = [
    { path: '', component: AddressList, pathMatch: 'full' },
    { path: ':id', component: AddressDetail, canDeactivate: [SaveGuard],},
];

import {Control3Module} from "../control3/control3.module"


import {DiagnosisModule} from '../diagnosis/diagnosis.module'
import {ProfessionalModule} from '../professional/professional.module'
//import {WidgetControlModule} from "../widget-control/widget-control.module";
import {ChartModule} from "../chart/chart.module";
//import {ActivityLogService} from "../activity-log/activity-log.service";
import {WidgetModule} from "../widget/widget.module";
import {ProfessionalService} from "../professional/professional.service";
import {DialogsModule} from "../dialogs/dialogs.module";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,

     //   PaginationModule,
        ModalModule,
        TypeaheadModule,

        EhisControlModule,  // <- declare MaskedInput ///

     //   PatientChartModule,

        Control3Module,

        DiagnosisModule,
        ProfessionalModule,
        ChartModule,
    //    RouterModule.forChild(routes),


        SmartadminModule,
        SmartadminInputModule,

        ControlModule,

        TextMaskModule,

//        WidgetControlModule,
        WidgetModule,

        DialogsModule

    ],

    declarations: [
      //  Address,
        AddressList,
        AddressDetail,

        DiagnosesComponent,
        HealthProfessionalsComponent,

        EhisTextComponent,
        EhisInfoComponent,
        EhisLegendComponent,

   //     AddressSelect,

        DateMaskDirective,
    ],

    exports: [
   //     AddressSelect,
        DateMaskDirective,
        AddressList, AddressDetail

    ],
    providers: [
        SaveGuard,
        AddressService,
        //ActivityLogService,
        ProfessionalService,
        DateMaskDirective
    ]
})
export class AddressModule {}

