import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';

declare var jQuery: any;


import {AddressService} from '../address.service'
import {BaseList}       from '../../auth/base-list'
import {finalize} from "rxjs/operators";

@Component({
  selector: 'address-list',

//  styleUrls: [ './address-list.style.scss' ],
  templateUrl: './address-list.template.html',

//  providers: [AddressService],

  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'error-page app'
  },
})
export class AddressList extends BaseList {
    @Output() onOpenForm = new EventEmitter();
    @Input() currentItem: any;

    $el: any;

    selectItem: any = {};


    searchItem: any = {first_name: '', 'last_name': '', 'date_of_birth': '', 'health_card': ''};

    dateFormat: string = 'mediumDate';

    constructor(el: ElementRef, private addressService: AddressService) {

        super(el, addressService);
        this.$el = jQuery(el.nativeElement);

        //console.log('create address list');

    }

    /*  clearSearch() {
          for(var key in this.searchItem) {
            this.searchItem[key] = '';
          }
          this.getAddresses();
      }
    */
    onSearch(item: any) {

        this.searchItem.last_name = item.last_name;

        this.getList();

    }

    openForm(item: any): void {
        //mail.unread = false;
        this.onOpenForm.emit(item);
    }

    addForm(): void {
        this.openForm({});
    }

    downloadText() {

        //this.addressService.downloadText();
        /*
             //this.region)
                .subscribe(
                    result => {console.log(result);
        //              let blob= window.URL.createObjectURL(result);
        //              window.open(blob)
                    },
                    error => this.error = error
        //    );
        */
    }

    getList() {
//      this.serviceItems.filter = (this.searchItem) ? (this.searchItem.last_name ? this.searchItem.last_name: ''): '';
        this.serviceItems.orderby = 'last_name,first_name,date_of_birth';
        super.getList();
    }


    dataLen() {
        if (this.data) {
            return this.data.length;
        }
        return 0;
    }

    //*****************************************************************************************************

//    @ViewChild('formNewPatient') formNewPatient;
    @ViewChild('formOpenChart') formOpenChart;


    item: any = {} // id: 0 }

    openChartName = "Open chart"

    /*
        formNewPatientOnOpen() {
        }

        formNewPatientOnClose() {
        }

        closeModal(name) {
            this.formNewPatient.close()
            this.formOpenChart.close()
        }

        patterns = [];

        newPatientDetail() {
            this.baseService.getItem(0, {}).subscribe(result => {

                for (let key in this.item) {
                    result[key] = this.item[key]
                }
                delete result['patient_id'];
                delete result['patient'];

                let routeData = [{item: result, modified: false}];

                let link = [this.baseService.path, 0]

                this.baseService.authService.navigate(link, routeData)
                this.formNewPatient.close()

            }, error => {
                this.handleError(error)
            })

        }

        okModal(name) {

            if (name == "Open chart") {

                let data = this.item.reason;

                this.addressService.setAccess(this.item.patient_id, 1, data)  // open chart
                    .subscribe(result => {

                            let link = [this.baseService.path, this.item.patient_id]

                            this.baseService.authService.navigate(link, null)

                        },
                        error => {
                            this.baseService.authService.showError(error.error)
                        });


                this.formOpenChart.close()
                return
            }

            if (name == "Open new chart") {
                this.newPatientDetail();
                this.formOpenChart.close()
                return
            }

            this.item._check = 1;

            this.baseService.getItems(null, this.item)
                .subscribe(
                    result => {

                        let patterns = result.data;

                        //if (patterns.length == 1 && patterns)

                        if (patterns.length == 0) {
                            // navigate to 0 .....
                            // after "open"  chart
                            this.openChartName = "Open new chart"
                            this.item.patient_id = -1;
                            this.item.patient = {id: -1, name: this.item.last_name + ', ' + this.item.first_name}

                            console.log('authDoc', this.baseService.authService.isAuthDoctor())
                            if (this.baseService.authService.isAuthDoctor()) {
                                this.formOpenChart.open();
                            }
                            else {
                                this.newPatientDetail()
                            }


                        }
                        else {
                            this.patterns = patterns;
                        }
                    },
                    error => {
                        this.handleError(error)
                    }
                );


        }


        clickPattern(item) {
            this.item.patient_id = item.id;

            this.item.patient = item;

            this.formNewPatient.close();

            this.addressService.getAccess(item.id).subscribe(result => {

                if (result.access) {
                    let link = [this.baseService.path, item.id]
                    this.baseService.authService.navigate(link, null)
                }
                else {
                    this.openChartName = "Open chart"
                    this.formOpenChart.open()

                }
            }, error => {
                this.baseService.authService.showError(error)
            })

        }


        clickAdd_old() {  // override

            this.patterns = [];

            this.formNewPatient.open();
        }
        */
    //-------------------------------------------------------------------------

   // @ViewChild('add_patient_dialog') add_patient_dialog

    //--------------------------------------------------------------------------------------------------

    @ViewChild('add_client') add_client

    //openChart = false
    client_id = null
    client_access = ''
    reason = ''


    client_selected() {
     //   this.baseService.loading = true
        this.add_client.loading = true
        if (this.client_id) {
            this.addressService.patient_access(this.client_id)
                .pipe(
                    finalize(() => {
                       // this.baseService.loading = false
                        this.add_client.loading = false
                    })
                )
                .subscribe(
                    result => { this.client_access = result;
                    if (this.client_access == 'yes' || this.client_access == 'full') {
                        this.add_client.close();
                        this.baseService.authService.navigate([this.baseService.path, this.client_id], null)

                    }
                    },
                    error=> { this.baseService.authService.showMessage(error)}
                )
        }
    }

    //needOpenChart() {
    //    return this.client_access == 'no'
    //}

    get openChart() {
    return this.client_access == 'no'
}

    clientOk() {
        this.add_client.close()
        if (this.openChart) {
            this.addressService.setAccess(this.client_id,1,this.reason).subscribe(
                result=> this.baseService.authService.navigate([this.baseService.path,this.client_id],null)
            )
        }
        else {
            this.baseService.authService.navigate([this.baseService.path, this.client_id], null)
        }
        /*

        if (this.needOpenChart()) {
            if (this.openChart) {
                this.add_client.close()
                // open card....
                this.baseService.authService.navigate([this.baseService.path,this.client_id],null)
            }
            else {
                this.openChart = true
            }
        }
        else {
            this.add_client.close()
            this.baseService.authService.navigate([this.baseService.path,this.client_id],null)
        */

    }


    clickAdd() {  // override

      this.client_id = null
     // this.openChart = false
      this.client_access = ''
      this.reason = ''

      this.add_client.open()

        return

        this.add_client.open(()=> {

          this.baseService.authService.navigate([this.baseService.path,this.client_id],null)

      })

        /*
      return


      let dialog = this.add_patient_dialog

        //dialog.hello2.open()

        //return

        dialog.open(() => {

            //console.log('dialog',dialog.item)
            //this.refresh() // address_list

            this.baseService.authService.navigate([this.baseService.path,dialog.item.id],null) // address-detail


        })
        */

    }


    loadKeys() {  // override
        super.loadKeys()
        this.paramItems.client= 1; // my_client
    }



}
