import {Component, ElementRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import {SubscriptionService} from '../subscription.service';
import {BaseItem}  from '../../auth/base-item';

import {SubscriptionItem} from "../detail/subscription-item"


@Component({
    selector: 'subscription-add',
    templateUrl: './subscription-add.template.html',
})
export class SubscriptionAdd extends SubscriptionItem {

    constructor(el:ElementRef,protected route:ActivatedRoute, protected subscriptionService:SubscriptionService) {

        super(el,route, subscriptionService);
    }



    public validationOptions:any = {
        // Rules for form validation
        rules: {
            balance: {
                required: true,
//                number: true,
            },

        },

        // Messages for form validation ++ masks ???
        messages: {

            balance: {
               // range: 'Please enter a day between 1 and 28.'
                dayofmonth: 'Please enter a valid number.'
            },

     }
    };



    //=====================================================================

    user: any = null;
    product: any = null;

    ngOnInit():void {

        let data = this.baseService.authService.routeData;
        //console.log(data)
        this.user = data.user;
        this.product = data.product;

        this.item = { user_id: this.user ? this.user.id: 0,
                      product_id: this.product ? this.product.id: 0,
                      _product: data._product,
                      balance:1000, comment: ""};
        this.savedItem = this.itemToSaveStr();
        this.isLoaded = true;

       // this.getDetail();
    }

    saveAndBack() {

        if (this.busy) return;
        this.busy = true;

        var save_item : any = this.itemToSave();
        let s : string = save_item.comment ? save_item.comment.trim() : "";
        if (s.length == 0) {
            save_item.comment = null;
        }

        this.subscriptionService.addWhoisCredits(save_item)
        //this.baseService.post(save_item)
            .subscribe(
                result => {
                    this.busy = false;
                    this.baseService.authService.showMessage(result.message);
                    this.noChanges = true;
                    this.back()
                },
                error =>  { this.busy = false; this.baseService.authService.showError(error); this.error = error}
            );

    }


}
