
import {map} from 'rxjs/operators';
///<reference path="..\..\..\node_modules\@angular\http\src\http.d.ts"/>
/**
 * Created by Alexander Spazhev on 16.10.2016.
 */

import {Injectable} from '@angular/core';
//import {Http, Response} from '@angular/http';
//import {Headers, RequestOptions,URLSearchParams} from '@angular/http';
import {Observable}     from 'rxjs';
import 'rxjs/Rx'

import {  AuthService}  from '../auth/auth.service';
import {  BaseService}  from '../auth/base.service';


@Injectable()
export class UserService extends BaseService{

    icon = "fa fa-user-md"

    utc = ['last_login','created_at','completed_at'];

    constructor(public authService:AuthService){

        super(authService,"/api/user");

 //       this.booleans = ['approved','subscribe','email_notification','email_approved'];
        this.booleans = ['approved','email_notification','email_approved'];
        this.dates = ['dob'];
    }
/*
    downloadText() {
        let url:string = `${this.baseURL}/download`;

        window.location.href=url;

        return this.authService.get(url)
            .map(res => new Blob([res.json()],{ type: 'text/plain' }))

    }
*/
    postProfile(item:any ){   // = ??? post()

        let url:string = `${this.baseURL}`;

        if (item.id) {
            url = `${url}/${item.id}`;
        }

        return this.authService.post(url,item);
    }


    invitation(email,role) {
        return this.authService.invitation(email,role,);
    }

    //----------------------------------------------------------

    post_whois_user(item) {

        let url:string = `${this.baseURL}/whois_user`;
        return this.authService.post(url,item);

    }

    subscribe(item) {

        let url:string = `${this.baseURL}/subscribe`;
        return this.authService.post(url,item);

    }


    checkUserCredentials(item) {
        let url:string = `${this.baseURL}/check`;
        return this.authService.post(url,item);

    }


    checkWhoisCredentials(item) {
        let url:string = `${this.baseURL}/check_whois`;
        return this.authService.post(url,item);

    }

    user_credits(user_id) {
        let url:string = `${this.baseURL}/credits/$(user_id)`;

        return this.authService.get(url);
    }


    post_bill_to(p) {


        let url:string = `${this.baseURL}/bill_to`;
        //console.log(url)

        return this.authService.post(url,p).pipe(
            map(result=>{return result;}));


    }

    getUserData(id) {
        let url:string = `${this.baseURL}/${id}`;;

        return this.authService.get(url);
    }

    // saveUserTheme(params) {
    //     let url:string = `${this.baseURL}/app/savetheme`;
    //     return this.authService.post(url,params);
    // }

    // selecttheme(params) {
    //     let url:string = `${this.baseURL}/selecttheme`;
    //     return this.authService.post(url,params);
    // }
}