import {Component, forwardRef, ElementRef,ViewContainerRef,ViewChild,EventEmitter} from '@angular/core';
import { Output, Input,OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { FormControl } from '@angular/forms';


import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe.js';
import {CsvService} from "../auth/csv/csv.service";

const noop = () => {};

declare var $:any;

import * as moment from 'moment';

//import {createNumberMask} from '../../../../../number-mask/createNumberMaskSuffix';


//import "select2/dist/js/select2.min.js";
import "select2/dist/js/select2.js";
import {AuthService} from "../auth/auth.service";
import {finalize, map} from "rxjs/operators";
import {createNumberMask} from "../number-mask/createNumberMaskSuffix";

function markMatch(text, term) {
    var match = text.toUpperCase().indexOf(term.toUpperCase());
    var $result = $('<span></span>');
    if (match < 0) {
        return $result.text(text);
    }
    $result.text(text.substring(0, match));
    var $match = $('<span class="select2-rendered__match"></span>');
    $match.text(text.substring(match, match + term.length));
    $result.append($match);
    $result.append(text.substring(match + term.length));
    return $result;
}



@Component({
    selector: 'item-input',
    template:
  `

     <div *ngIf = "elemType=='SUGGEST'"  class="form-group">
             <label class="control-label col-sm-2">{{label}}</label>
    <div class="col-sm-10">
    <input type="text" class="form-control" [(ngModel)]="value" name="sug"
        [typeahead]="typeaheadArray"
        [typeaheadOptionField]="'name'"
        (typeaheadOnSelect)="typeaheadOnSelect($event)"
        autocomplete="off"
    >
    </div>
    </div>

        <div *ngIf = "elemType=='INPUT'" class="form-group">

            <label class="control-label" [ngClass]="label_required">{{label}}</label>
            <div class="" [ngClass]="inputClass">

            <div *ngIf="icon; else input_template">
                <div class="input-group">
                    <ng-container *ngTemplateOutlet="input_template"></ng-container>
                    <a class="input-group-addon" (mousedown)="input_focus($event)"><i [ngClass] = "icon" ></i></a>
                </div>
            </div>
            <ng-template #input_template>

                <input type="text"  [(ngModel)] = "value" class="form-control" name="inp"
                       
                       [attr.disabled] = "isdisabled()"

                       [textMask]="mask"

                       [attr.required]="attr_required"
                       [attr.placeholder] = "attr_placeholder"
                       [attr.minlength] = "attr_minlength"

                >
            </ng-template>
            </div>
<!--
           <label class="control-label col-sm-2" [ngClass]="label_required">{{label}}</label>
 
            <div class="col-sm-10">
                  
                  <div *ngIf="icon" class="input-group">
                        <input type="text"  [(ngModel)] = "value"  name="inp"
                               [attr.disabled] = "isdisabled()"
                               
                               [textMask]="mask"
                               
                               [attr.required]="attr_required"
                               class="form-control"
                               [attr.placeholder] = "attr_placeholder"
                               [attr.minlength] = "attr_minlength"

                        >
                      <span class="input-group-addon">
                          <i [ngClass] = "icon"></i>
                      </span>
                   </div>             
                         
                   <input *ngIf= "!icon" type="text" class="form-control" [(ngModel)] = "value"  name="inp"
                               [textMask]="mask"
                               [attr.required]="attr_required"
                               [attr.disabled] = "isdisabled()"
                          
                               [attr.placeholder] = "attr_placeholder"
                               [attr.minlength] = "attr_minlength"
                   >
                  
             </div>
             -->
        </div>

     <div *ngIf = "elemType=='COLOR'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">

             <div *ngIf="icon" class="input-group">
                 <input type="color"  [(ngModel)] = "value"  name="clr"
                        [attr.disabled] = "isdisabled()"

                        [textMask]="mask"

                        [attr.required]="required? required:undefined"
                        class="form-control" style="padding: 2px 8px;">



                 <span class="input-group-addon">
                                     <i [ngClass] = "icon"></i>
                               </span>
             </div>

             <input *ngIf= "!icon" type="color" class="form-control" [(ngModel)] = "value"  name="clr"
                    [textMask]="mask"
                    style="padding: 2px 8px;"
                    [attr.required]="required? required:undefined"
                    [attr.disabled] = "isdisabled()">

         </div>
     </div>

     
     <div *ngIf = "elemType=='NUMBER'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">

             <div *ngIf="icon" class="input-group">
                 <input type="text" [(ngModel)] = "value"  name="inp"
                        [attr.disabled] = "isdisabled()"

                        currencyMask [options]="currencyOptions"

                        [attr.required]="required? required:undefined"

                        [placeholder] = "placeholder"

                        class="form-control">



                 <span class="input-group-addon">
                                     <i [ngClass] = "icon"></i>
                               </span>
             </div>

             <input *ngIf= "!icon" type="text" class="form-control" [(ngModel)] = "value"  name="inp"
                    
                    currencyMask [options]="currencyOptions"
                    [attr.required]="required? required:undefined"
                    [placeholder] = "placeholder"

                    [attr.disabled] = "isdisabled()">

         </div>
     </div>

     
<!--
     <ng-template #content><ng-content></ng-content></ng-template>
-->
     <div *ngIf = "elemType=='WARNING'" class="form-group" style="margin-bottom: -6px;padding-top: 3px;font-weight:bold;">
             <div class="col-sm-12">
                  <div class="alert alert-warning" [innerHTML]="label">
                    <!--
                      <ng-container *ngTemplateOutlet="content"></ng-container>
                      -->
                  </div>
              </div>
          </div>

     <!--

     <div *ngIf = "elemType=='ARRAY'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">
             <dynamic-array #dynamic_array [options]="options" 
                (onAdd)="addEmit($event)"
                (onRemove)="removeEmit($event)"
             ></dynamic-array>
         </div>
     </div>
     
     -->

     <div *ngIf = "elemType=='ARRAY'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">
             <dynamic-array #dynamic_array
                            [(ngModel)]="value" 
                            [options]="options"
                            (onAdd)="addEmit($event)"
                            (onRemove)="removeEmit($event)"
                            
                            [fixed] = "fixed"
                            [index] = "index"
                            [disabled] = "disabled"
             ></dynamic-array>
         </div>
     </div>

     <div *ngIf = "elemType=='RADIOS'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">
             <dynamic-radios [options]="options" [(ngModel)]="value"
                             [disabled] = "disabled"
                             [index] = "index"
             >
                 
             </dynamic-radios>
         </div>
     </div>

     <div *ngIf = "elemType=='INDEX'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">
             <dynamic-index #dynamic_index [options]="options" [(ngModel)]="value"
                 (onAdd)="addEmit($event)" 
                 (onRemove)="removeEmit($event)"
             ></dynamic-index>
         </div>
     </div>

     <div *ngIf = "elemType=='USER-INDEX'" class="form-group">

         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">
             <user-index #user_index [options]="options" [(ngModel)]="value"
                            (onAdd)="addEmit($event)"
                            (onRemove)="removeEmit($event)"
             ></user-index>
         </div>
     </div>

     
     
     <div *ngIf = "elemType=='PASSWORD'" class="form-group">

             <label class="control-label" [ngClass]="label_required">{{label}}</label>
             <div class="col-sm-10">

                 <div *ngIf="icon; else password_template">
                     <div class="input-group">
                         <ng-container *ngTemplateOutlet="password_template"></ng-container>
                         <a class="input-group-addon" (mousedown)="input_focus($event)"><i [ngClass] = "icon" ></i></a>
                     </div>
                 </div>
                 <ng-template #password_template>
                     <input id="password" type="password"  [(ngModel)] = "value"
                            
                            autofill="false"
                            autocomplete="new-password"
                            [attr.disabled] = "isdisabled()"
                            required="true"
                            minlength = "8"
                            maxlength = "48"
                            
                        
                            
            class="form-control">
                 </ng-template>
<!--
 [textMask]="mask"
                           
                 <div *ngIf="icon" class="input-group">
                        <input id="password" type="password"  [(ngModel)] = "value"  name="password"
                               [attr.disabled] = "isdisabled()"
                               autocomplete="xxx-new-password" autofill="false"
                               [textMask]="mask"
                               minlength = "8"
                               maxlength = "48"
                               [attr.required]="required? required:undefined"

                               
                               
                               class="form-control">
                               
                               
                                
                               <span class="input-group-addon">
                                     <i [ngClass] = "icon"></i>
                               </span>
                   </div>             
                         
                   <input id="password" *ngIf= "!icon" type="password" class="form-control" [(ngModel)] = "value"  name="password"
                               [textMask]="mask"
                               minlength = "8"
                                maxlength = "48"

                          [attr.required]="required? required:undefined"
                               [attr.disabled] = "isdisabled()">
                               -->
                  
             </div>
        </div>                       

              <div *ngIf = "elemType=='PASSWORD-CONFIRM'" class="form-group">

             <label class="control-label" [ngClass]="label_required">{{label}}</label>
             <div class="col-sm-10">

                 <div *ngIf="icon; else password_confirm_template">
                     <div class="input-group">
                         <ng-container *ngTemplateOutlet="password_confirm_template"></ng-container>
                         <a class="input-group-addon" (mousedown)="input_focus($event)"><i [ngClass] = "icon" ></i></a>
                     </div>
                 </div>
                 <ng-template #password_confirm_template>

                        <input type="password"  [(ngModel)] = "value"  name="password_confirm"
                               [attr.disabled] = "isdisabled()"
                               autocomplete="new_password" autofill="false"
                               [textMask]="mask"
                               maxlength="48"
                               required="true"

                               equalTo = "#password"
                               
                               data-msg-equalTo ="Please enter the same password"
                              
                               class="form-control">
                 </ng-template>
                               
          </div>
        </div>                       
                      
      <div *ngIf = "elemType=='TIME'" class="form-group">

             <label class="control-label col-sm-2">{{label}}</label>
             <div class="col-sm-10">
             
                  <div *ngIf="icon" class="input-group">
                       <input type="text" class="form-control" [(ngModel)] = "value"  name="tim"
                              [attr.disabled] = "isdisabled()"
                              [attr.required]="attr_required"
                              time="1"
                               [textMask]="{mask: timeMask}"
                               placeholder="hh:mm">
                       <span class="input-group-addon">
                           <i [ngClass] = "icon"></i>
                       </span>
                   </div>              
          
                  <input *ngIf= "!icon" type="text" class="form-control" [(ngModel)] = "value"  name="tim"
                         [attr.disabled] = "isdisabled()"
                         [attr.required]="attr_required"
                         time="1"
                               [textMask]="{mask: timeMask}"
                               placeholder="hh:mm">
                                    
             </div>
        </div>                       
 
 
   <div *ngIf = "elemType=='DATE'" class="form-group">
        <label class="control-label" [ngClass]="label_required"><ng-content></ng-content>{{label}}</label>
            <div class="col-sm-10">
 
                  <div *ngIf="icon" class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="value" 
                           [attr.disabled] = "isdisabled()" 
                           [attr.required]="attr_required"

                           autocomplete="new_password" autofill="false"
                           datem="1" 
                          [textMask]="dateTextMask"
                              placeholder="dd/mm/yyyy" >
                     <span class="input-group-addon">
                           <i [ngClass] = "icon"></i>
                      </span>
                   </div>             
          
                
        <input *ngIf="!icon" type="text"  [(ngModel)]="value" name="dat"
               class="form-control" 
                datem="1" 
                [textMask]="{mask: dateMask,  keepCharPositions: true, pipe: autoCorrectedDatePipe}"
                placeholder="dd/mm/yyyy"
               [attr.disabled] = "isdisabled()"
               [attr.required]="attr_required"
        >
        </div>
    </div>


     <div *ngIf = "elemType=='DATEPICKER'" class="form-group">
         <label class="control-label" [ngClass]="label_required"><ng-content></ng-content>{{label}}</label>
         <div [ngClass]="inputClass">

             <div *ngIf="icon" class="input-group">
                 <input type="text" class="datepicker form-control" [(ngModel)]="value" name="datpick"
                        [attr.disabled] = "isdisabled()"
                        
                        [saUiDatepicker]="{ dateFormat: format }"
                        (dateChanged)="dateChanged($event)"
                        [placeholder]="placeholder"

                        (focus)="focus(true)"
                        (blur)="focus(false)"
                 >
                 <a class="input-group-addon" (mousedown)="datepicker_icon($event)"><i [ngClass] = "icon"></i></a>
             </div>


             <input *ngIf="!icon" class="datepicker form-control" type="text"  [(ngModel)]="value" name="datpick"
                    [attr.disabled] = "isdisabled()"

                    [saUiDatepicker]="{ dateFormat: format}"
                    (dateChanged)="dateChanged($event)"
                    [placeholder]="placeholder"
             >
         </div>
         <!--  display inline https://jqueryui.com/datepicker/#inline
         <div [saUiDatepicker]="{ dateFormat: format }"></div>
         -->
     </div>
     

     <div *ngIf = "elemType=='CHECKBOX'" class="form-group" >
            <label class="control-label" [ngClass]="label_required" ></label>  
            <div [ngClass]="inputClass">
                        <div class="checkbox" style="margin-top:-1rem;">
                            <label>
                                <input type="checkbox"  [(ngModel)] = "value" name="chk"
                                                [attr.disabled] = "isdisabled()"
 
                                                class="checkbox style-1">
                                 <span> {{label}}</span>
                              </label>
                    </div>
            </div>
        </div>
        
       <div *ngIf = "elemType=='TEXTAREA'" class="form-group">

             <label class="control-label" [ngClass]="label_required">{{label}}</label>
             <div [ngClass]="inputClass">
                  <textarea type="text"  [(ngModel)] = "value"  name="txt"
                               class="form-control custom-scroll"
                               [attr.disabled] = "isdisabled()"
                               [rows]="rows"
                               style="resize:vertical;"
                               [ngClass]="{'shadow-text': isdisabled() && shadow }"

                               [attr.placeholder] = "attr_placeholder"
                               [attr.required]="attr_required"

                  >
                  </textarea>
              </div>
        </div>                       
                 
       <div *ngIf = "elemType=='SELECT'" class="form-group">
            <label class="control-label" [ngClass]="label_required">{{label}}</label>
            <div [ngClass]="inputClass" style="display: flex;align-items: baseline;justify-content: space-between;flex-flow: row wrap;">
      
                <!-- [ngStyle]="{'background-color': disabledColor}" -->
           <select class="form-control" [(ngModel)]="value"
                    [attr.required]="attr_required"
                    [attr.disabled]="isdisabled()"
                    (change)="selectChange($event)"
                   
                    [ngStyle] = "item_style"
           >
            <option value="{{ref.id}}" *ngFor="let ref of index">
                {{ref.name}}
            </option>
        </select>
                
                <ng-content select="span"></ng-content>
                
                
                <!-- CLEAR BUTTON FOR SELECT
               <span> <a (click)="selectClear()" class="">
                   <i class="fa fa-times"

                                                              [ngStyle] =" { 'color': timesColor , 'cursor':'pointer' }"
                                                              style="padding-left:0.1rem;border: 1px solid;border-radius: 3px;line-height:12px;width:13px;"

                                                              (click)="selectClear()"></i> 
                    Clear</a> </span>
                 -->
            </div>
        </div>
<!--
     <legend *ngIf = "elemType=='LEGEND'" class="col-md-12"><span>{{label}}</span>
     <span class="pull-right"><ng-content></ng-content></span>
     </legend>
    
     <div *ngIf="elemType=='LEGEND'" class="col-md-12">
         <legend>{{label}}</legend><div class="pull-right"><ng-content></ng-content></div>
     </div>
      -->
     <div *ngIf="elemType=='LEGEND'" class="form-group">
         <div class="col-sm-12">
             <legend >{{label}}</legend>
         </div>
     </div>
  <!--
     <div *ngIf="elemType=='DYN-TITLE'" class="form-group">
         <div class="col-sm-12">
             <legend class="legend-title">{{label}}</legend>
         </div>
     </div>  
-->
     <!--
     <legend *ngIf = "elemType=='TITLE'" class="col-sm-12" style="text-align: center;" [innerHTML]="innerLabel"></legend>
     -->


     <!--
         [ngStyle]="{'background-color': disabledColor}"  <ng-content></ng-content>
      <legend *ngIf = "elemType=='LEGEND'" class="col-md-12">{{label}}</legend>
                
                
                 -->  

     
     <div *ngIf="elemType=='LOOKUP'" class="form-group"> 
         <label class="control-label" [ngClass]="label_required">{{label}}</label>
          <div [ngClass]="inputClass">
             <div class="input-group">
 
            <input  type="text" [readonly]="true" [attr.name]="key" [ngModel] = "viewName" class="form-control"
             
                    [attr.required]="attr_required"
                    [attr.disabled]="isdisabled()"

               [ngStyle]="{'background-color': disabledColor}"
                [name]="key"

            >
            <div  class="input-group-btn">
                <button *ngIf="lookupButton" type="button" class="btn btn-default"
                    [attr.disabled]="disabled? disabled:undefined" (click) = "lookup()"
                    style="height: 32px;
                    font-size: 16px;
                    width: 34px;padding-top: 5px;">
                    <span style="font-weight: 900;margin-left:-2px;">...</span>
                </button>

                <button *ngIf="viewButton" type="button" class="btn btn-default" (click)="view()">

                <!-- <i class="fa fa-external-link"></i> 
                
                 <button *ngIf="viewButton" [attr.disabled]="disabled? disabled:undefined" type="button" class="btn btn-default" (click)="view()">
               
                -->
                    <i class="fa fa-search"></i>
                </button>

                <button *ngIf="clearButton" [attr.disabled]="disabled? disabled:undefined" type="button" class="btn btn-default" (click)="clearLink()">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            </div>
                

           </div>

     </div>
                
     <ul *ngIf="elemType=='TABSET'" id="myTab1" class="nav nav-tabs" style="margin-top: -2.5rem;">
           <li *ngFor="let item of label.split(','); let i= index"  [class.active]="parent.tab == i">
                    <a (click)="parent.selectTab(i)">{{item}}</a>
                </li>
     </ul>

     <div *ngIf="elemType=='INFO-S' && index">

     <label class="control-label col-sm-2">{{label}}</label>

         

         <dl class="dl-horizontal col-sm-10" style="border-color: coral;border-style: solid;border-width: 1px;padding-bottom: 0.5em;">
         <ng-container *ngFor="let item of index">
             <dt style="color: coral; font-weight: 500;padding-top: 0.5em;" [ngStyle]="dt_style">{{item.id}}{{item.id ? ':':'&nbsp;'}}</dt>
             <dd *ngIf = "!item.link" style="padding-top: 0.5em;" [ngStyle]="dd_style">{{item.name}}</dd>
             <dd *ngIf = "item.link" style="padding-top: 0.5em;" [ngStyle]="dd_style"><a (click)="infoClick(item.link)">{{item.name}}</a></dd>
             
            
         </ng-container>
     </dl>    
         
     </div>

     <div *ngIf="elemType=='INFO' && index">

         <label class="control-label col-sm-2">{{label}}</label>



         <dl class="dl-horizontal col-sm-10" style="border-color: lightgrey;border-style: solid;border-width: 1px;padding-bottom: 0.5em;">
             <ng-container *ngFor="let item of index">
                 <dt style="color: grey; font-weight: 500;padding-top: 0.5em;">{{item.id}}:</dt>
                 <dd style="padding-top: 0.5em;">{{item.name}}</dd>

             </ng-container>
         </dl>

     </div>



     <!--
         <tabset class="padding-10">
             <ng-content></ng-content>
         </tabset>    
                          
                                            <img [src]="img_src"  class="img-fluid float-xs-center"  style="width:100%; height: auto;">
    
                            
      <div *ngIf="elemType=='PHOTO2'" class="form-group">
         <label class="control-label col-sm-2">{{label}}</label>
                     <div class="col-sm-10">
                 
                   <img *ngIf="hasPhoto()" [src]="img_src"  class="img-fluid"  style="max-width:100%;margin-bottom:1rem;">

                
 
        <div >
        
           <input #upload_hidden type="file" name="upload" load-image
                  style="display: none;"
                  
                  ngFileSelect
                         [accept]="accept()"
                         [options]="uploadOptions()"
                         (onUpload)="handleUpload($event)"
                         (beforeUpload)="beforeUpload($event)"
                         (onUploadRejected)="onUploadRejected($event)">
                         
           <p *ngIf="!photo && value" style="margin-right: 1rem;padding-top: 0.52rem">
           <a href="javascript:void(0)" target="_blank" role="button" (click)="download()" download="{{fileName}}">{{fileName}}</a>
           </p>
                  
                   <div >
                        <button *ngIf="!photo" class="btn btn-success ladda-button" type="button" style="line-height: 22px;margin-right:0.5rem;"
                            (click)="download()" 
                            [hidden]="(!value) || photo"
                            [disabled]="(!value) || is_uploading"
                            [ladda]="is_downloading" data-size="xs">
                            <span ladda-label><i class="fa fa-download"></i>&nbsp;&nbsp;Download</span>
                        </button>
                         
                        <button class="btn btn-primary ladda-button " type="button" style="line-height: 22px;margin-right:0.5rem;margin-left:0;"
                            (click)="upload()"
                            [hidden]="readonly && !builder"

                            [disabled]="readonly || is_downloading"
                             [ladda]="is_uploading" data-size="xs">
                             <span ladda-label>
                             <i *ngIf="!photo" class="fa fa-upload"></i>
                             <i *ngIf=" photo" class="fa fa-file-photo-o"></i>
                             &nbsp;&nbsp;Upload</span>
                        </button>
                                                
                        <button class="btn btn-default" type="button" style="line-height: 22px;width: 8rem;margin-left:0;" 
                            (click)="clear()" 
                            [hidden]="(!value) || readonly" 
                            [disabled]="(!value) || readonly || is_downloading || is_uploading">
                            <span ladda-label ><i class="fa fa-close"></i>&nbsp;&nbsp;Clear</span>
                        </button>
                        

                    </div>
      
                  
        </div> 
          </div>      
        
       </div>
       
       {{label}}  in BUTTON !!
       {{name}}  in BUTTON !!

-->
     <div  *ngIf="elemType=='BUTTON'" class="form-group">
         
         <label class="control-label" [ngClass]="label_required" ></label>
         <div class="pull-right" [ngClass]="inputClass">
             <button type="button"  class="btn btn-default pull-left" style="width:10rem;" (click)="buttonClick($event)" >
                 {{label}}
                 <i class="pull-right" [ngClass] = "icon" style="margin-top:0.25rem;"> </i>
             </button>
         </div>
     </div>


     <div *ngIf="elemType=='TABLE'" class="form-group">
         <label class="control-label" [ngClass]="label_required">{{label}}</label>
         <div [ngClass]="inputClass" [ngStyle]="tableStyle">
             <a *ngIf="get_pdf" class="btn btn-link pull-right" (click)="tablePdf()">[Get PDF]</a>
             <a *ngIf="download_csv" class="btn btn-link pull-right" (click)="downloadCSV()">[Download CSV]</a>

             <div class="widget_table_wrapper" style="border:solid 1px #ccc!important;"
                  [ngClass]="{widget_table_scrollY:scroll_y,widget_table_fixed_header:scroll_y,widget_table_fixed_column:fixed_col ==1,
              widget_table_fixed_column_1:fixed_col ==2, widget_table_fixed_column_2:fixed_col == 3}"
                  [ngStyle] = "tableWrapperStyle"
             >    <!-- sticky-header -->
                 <table class="table table-hover table-widget table-striped" style="margin-bottom:0;"
                        [ngClass]="{header_dark:header_dark}" >
                     
                <ng-content></ng-content>
                     
                 </table>

             </div>
         </div>
     </div>


     <div *ngIf="elemType=='PIE-CHART'" class="form-group">
         <label class="control-label" [ngClass]="label_required">{{label}}</label>
         <div [ngClass]="inputClass">
             <div style="border:solid 1px #ccc!important;" [ngStyle]="pieChartStyle">
                 <a *ngIf="get_pdf" class="btn btn-link pull-right" (click)="pdfPieChart()">[Get PDF]</a>
                 <a *ngIf="get_picture" class="btn btn-link pull-right" (click)="printPieChart()">[Get Picture]</a>

                 <sa-flot-chart #pie_chart
                                [title] = "title"  
                                [data]="data"
                                class="chart-large txt-color-red"

                                [options]="pieChartOptions"
                                [height] = "height"

                 ></sa-flot-chart>
             </div >
         </div>
     </div>


     <div *ngIf = "elemType=='TAGS'" class="form-group">
         <label class="control-label" [ngClass]="label_required">{{label}}</label>
         <div [ngClass]="inputClass">

             <select2

                     (selected)="selected2($event)"

                     [disabled]="disabled"
                      
                      [index]="index"
                      
                      [(ngModel)]="value"
                      [placeholder] = "placeholder"
                      [mode] = "mode"
                     
                      [(tag)] = "tag"
                      [required] = "this.pre_required || this.required"
                     
                      [required_values]="required_values"
             >

             </select2>

             <!--  
              required  !!!!  
                  <option value="{{ref.id}}" *ngFor="let ref of index">
                     {{ref.name}}
                 </option>
           
             
                        [ngStyle]="select2_style()"
         
             
             
             <div *ngIf="indexHasGroups" class="col-sm-10">
             
                          <select #tags select2 multiple class="select2" id="tags" class="form-control"
             
                                  style="width:100%;z-index:800!important;"
             
                                  [placeholder]="placeholder">
             
                              <optgroup *ngFor = 'let grp of index' label="{{grp.name}}">
                                  <option value="{{ref.id}}" *ngFor="let ref of grp.children" [attr.selected] = "ref.selected">
                                      {{ref.name}}
                                  </option>
                              </optgroup>
            
                 </select>
             </div>
             
             <div *ngIf="!indexHasGroups" class="col-sm-10">
    
                 <select #tags select2 class="select2" id="tags" class="form-control" multiple
    
                         style="width:100%;z-index:800!important;">
     
              
                </select>
                 
                        [placeholder]="No tag selected">
               (selected)="parent.selectTag($event)"
                 
                                  <option value="{{ref.id}}" *ngFor="let ref of index" [attr.selected] = "ref.selected">
                         {{ref.name}}
                     </option>
    
     
     
                 <select  [(ngModel)]="value" [attr.required]="required ? required:undefined"
                          [attr.disabled]="disabled?true:undefined"
                          class="form-control">
                     <option value="{{ref.id}}" *ngFor="let ref of index">
                         {{ref.name}}
                     </option>
                 </select>
                 -->
         </div>
         
         
     </div>

     <!-- sa-cloak sa-hidden-->

     <div *ngIf = "elemType=='TYPEAHEAD'" class="form-group">
         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10">

             <select class="select3 form-control "

                     [attr.disabled]="isdisabled()"
                     style="width:100%;z-index:800!important;"
                     (selected)="selectTag($event)"
             >

             </select>
         </div>
     </div>

<!--
     <a *ngIf="!photo && value &&!is_uploading" class="btn btn-link pull-left" href="javascript:void(0)" target="_blank" (click)="download()" download="{{fileName}}"
-->

     <div *ngIf="elemType=='PHOTO'" class="form-group">
         <label class="control-label col-sm-2">{{label}}</label>
         <div class="col-sm-10" >
             <span *ngIf="is_uploading || is_downloading"><i class="fa fa-2x fa-spinner fa-spin photo-spin"></i></span>

             <a *ngIf="(value) && !readonlyUpload " class="btn btn-link pull-right" [attr.disabled]="(!value) || readonlyUpload || isdisabled()? true : undefined" (click)="clearPhoto()">[Clear]</a>
             <a *ngIf="!readonlyUpload" class="btn btn-link pull-right" [attr.disabled]="readonlyUpload || isdisabled() ? true : undefined" (click)="upload()">[Upload]</a>

             
             <a *ngIf="!photo && value &&!(is_uploading ||is_downloading) " class="btn btn-link pull-left" (click)="download()" download="{{fileName}}">{{getFileName()}}</a>
             

             <img *ngIf="hasPhoto()" [src]="img_src"  class="img-fluid"  style="max-width:100%;margin-bottom:1rem;">



             <div >

                 <!-- 
                           <a *ngIf="value &&!is_uploading" class="btn btn-link pull-left" href="javascript:void(0)" target="_blank" (click)="download()" download="{{fileName}}"
     
                  
                  id="upload_hidden"  
                 
                             style="position: absolute; display: block; overflow: hidden; width: 0; height: 0; border: 0; padding: 0;"
                  
                 -->

                 <input type="file" name="upload" style="display: none;" load-image
                        
                        [accept]="accept()"
                        
                        (onUpload)="handleUpload($event)"
                        (beforeUpload)="beforeUpload($event)"
                        (onUploadRejected)="onUploadRejected($event)">
                 <!--
                                  <p *ngIf="!photo && value &&!is_uploading" style="margin-right: 1rem;margin-top: 0.5rem">
                                      <a href="javascript:void(0)" target="_blank" role="button" (click)="download()" download="{{fileName}}">{{getFileName()}}</a>
                                  </p>
                                  
                                                   <div >
                                                       <button *ngIf="!photo" class="btn btn-success ladda-button" type="button" style="line-height: 22px;margin-right:0.5rem;"
                                                               (click)="download()"
                                                               [hidden]="(!value) || photo"
                                                               [disabled]="(!value) || is_uploading"
                                                               [ladda]="is_downloading" data-size="xs">
                                                           <span ladda-label><i class="fa fa-download"></i>&nbsp;&nbsp;Download</span>
                                                       </button>
                                  
                                                       <button class="btn btn-primary ladda-button " type="button" style="line-height: 22px;margin-right:0.5rem;margin-left:0;"
                                                               (click)="upload()"
                                                               [hidden]="readonly && !builder"
                                  
                                                               [disabled]="readonly || is_downloading"
                                                               [ladda]="is_uploading" data-size="xs">
                                                               <span ladda-label>
                                                               <i *ngIf="!photo" class="fa fa-upload"></i>
                                                               <i *ngIf=" photo" class="fa fa-file-photo-o"></i>
                                                               &nbsp;&nbsp;Upload</span>
                                                       </button>
                                  
                                                       <button class="btn btn-default" type="button" style="line-height: 22px;width: 8rem;margin-left:0;"
                                                               (click)="clear()"
                                                               [hidden]="(!value) || readonly"
                                                               [disabled]="(!value) || readonly || is_downloading || is_uploading">
                                                           <span ladda-label ><i class="fa fa-close"></i>&nbsp;&nbsp;Clear</span>
                                                       </button>
                                  
                                 
                                  </div>
                                  [class.btn-primary]
          <div class="col-sm-10" [ngClass]="{'shadow-text': isdisabled() && shadow}">
                 -->

             </div>
         </div>

     </div>
     
     
               
     <div *ngIf = "elemType=='ICD10'" class="form-group">
         <label class="control-label col-sm-2" [ngClass]="label_required">{{label}}</label>
         <div class="col-sm-10" [class.shadow-text]="isdisabled() && shadow">

             <select class="select4 form-control"
                     style="width:100%;z-index:800!important;border-color: #ccc!important;"
 
                     (selected)="selectTag($event)"

                     [attr.disabled]="isdisabled()"
             >
             </select>
          </div>
     </div>

     <!--  style="width:100%;z-index:800!important;border-color: #ccc!important;"
-->
     <div *ngIf = "elemType=='USER'" class="form-group">
         <label class="control-label" [ngClass]="label_required">{{label}}</label>
         <div [ngClass] = "inputClass" [class.shadow-text]="isdisabled() && shadow">
             
             
             <select class="select4 form-control"
                    
                     (selected)="selectTag($event)"
                     [attr.disabled]="isdisabled()"
             
                     [attr.required]="attr_required"


                     style="width:100%;z-index:800!important;border-color: #ccc!important;margin-right: 1px;"

             >
             </select>
             
             
             <!--
             <button *ngIf="clearButton" [attr.disabled]="isdisabled()" type="button" class="btn btn-default"
                     (click)="selectClear()">
                 <i class="fa fa-times"></i>
             </button>
             
             -->
             


         </div>

     </div>

<!--     <div class="select2_patient__form" *ngIf="elemLink=='PATIENT' && addButton" name="patient_form">  -->
     <!--
     <popup #add_patient *ngIf="elemLink=='PATIENT' && addButton">
         <item-patient [item]="item"></item-patient>
     </popup>
     -->

     <add-patient-dialog *ngIf="elemLink=='PATIENT' && addButtonText" #add_patient_dialog [openChart]="openChart"></add-patient-dialog>

    <!--

     <form  class=" form-horizontal select2_patient__form" *ngIf="elemLink=='PATIENT' && addButton" name="patient_form">

        <loader [loading]="loading" [loaded]="true" [info]="info">
            {{info}}
        </loader>
        
        <button class="close" data-dismiss="modal" type="button" aria-label="Close"
            style="position: absolute;
            float: right;
            right: 13px;
            top: 10px;"
            (click)="closePatient()"
             ><span aria-hidden="true">×</span></button>

             <fieldset>
                <item-patient [item]="item"></item-patient>
             </fieldset>
             <footer class="form-actions">

                 <button type="button"  class="btn btn-primary" [validation]="{}" (validClick) = "okPatient()">Save</button>
                 <button type="button" class="btn btn-default" (click)="closePatient()">Cancel</button>

             </footer>
         </form>

    -->
<!--     </div>    -->


     <div  *ngIf="elemType=='PERIOD'" class="form-group">

         <label class="control-label" [ngClass]="label_required" >Term</label>
         <div class="pull-right" [ngClass]="inputClass">
             <button type="button"  class="btn btn-default pull-left" style="min-width:10rem;" (click)="periodClick($event)" >
                 {{periodTitle()}}
                 <i class="pull-right" class = "fa fa-calendar" style="margin-top:0.25rem;margin-left:2rem;"> </i>
             </button>
         </div>
     </div>


    `,

    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ItemInputComponent), multi: true },
//        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ItemInputComponent), multi: true },
     ]
})
export class ItemInputComponent implements ControlValueAccessor,OnInit {

    @Input('col-label') colWidth: number = 3;
    @Input('readonly') readonly: boolean = false;


    //The internal data model
    public innerValue: string = '';
    public innerTypedValue: any
    public confirmValue: string = '';

   // validateFn: Function; //any = () => {};

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

//    public get autoCorrectedDatePipe(): any { return createAutoCorrectedDatePipe('dd/mm/yyyy'); }
//    public get timeMask(): any {
//        return [/[0-2]/, /\d/, ':', /[0-6]/, /\d/];
//    }


    //   get labelClass() {
    //       let w = this.colWidth;
    //       return `form-control-label col-md-${w} col-xs-12 text-md-right`;
    //   }
    //   get inputClass() {
    //       let w = 12 - this.colWidth;
    //       return `col-md-${w} col-xs-12`;
    //   }


    labelClass =  'col-sm-2' //null;
    inputClass = 'col-sm-10' // null;

    @Output() click: any = new EventEmitter;


    ngOnDestroy() {
    //    this.validateFn = noop;
    }

    /*
     ngOnChanges(changes) {
     console.log('changes');
     if (changes.confirmValue ) {
     this.validateFn = createMyValidator(this);
     }
     }
     */

  //  innerArray: any = null

    //get accessor
    get value(): any {
    //    if (this.elemType == 'INDEX') return this.innerArray
        return this.innerValue;
    }

    //set accessor including call the onchange callback


    typeaheadArray = [];

    set value(v: any) {
/*
        if (this.elemType == 'INDEX') {
            this.innerArray = v
            this.onChangeCallback(v);
            return
        }
*/
        //console.log('set value',v)

        if (v !== this.innerValue) {

            if (this.elemType == 'INPUT') {
                this.innerValue = v


                if (this.innerTypedValue === null) {
                    this.onChangeCallback(v ? v : null);
                }
                else if (typeof this.innerTypedValue === 'undefined') {
                    this.onChangeCallback(v ? v : undefined);
                }
                else {
                    this.onChangeCallback(this.innerValue);
                }
                return
            }

            if (this.elemType == 'CHECKBOX') {
                this.innerValue = v
                //         console.log('checkbox',this.innerTypedValue,v,typeof this.innerTypedValue)
                if (this.innerTypedValue === null) {
                    //             console.log('null')
                    this.onChangeCallback(v ? 1 : null);
                }
                else if (Number.isInteger(this.innerTypedValue)) {
                    this.onChangeCallback(v ? 1 : 0);
                }
                else if (typeof this.innerTypedValue === 'string') {
                    this.onChangeCallback(v ? '1' : '0');
                }
                else if (typeof this.innerTypedValue === 'undefined') {
                    this.onChangeCallback(v ? '1' : undefined);
                }
                else {
                    this.onChangeCallback(this.innerValue);
                }
                return
                //        console.log('inner value',this.innerValue)
            }


            if (this.elemType == "SELECT" || this.elemType == 'CHECKBOX') {
                this.innerValue = v
                //               console.log('select',this.innerTypedValue,v)
                if (Number.isInteger(this.innerTypedValue)) {
                    this.onChangeCallback(+v);
                }
                else {
                    this.onChangeCallback(this.innerValue);
                }
                return
                //        console.log('inner value',this.innerValue)
            }

            this.innerValue = v;
            this.onChangeCallback(v);

            if (this.elemType == "SUGGEST") {

                this.typeaheadArray = this.index.filter(k => {
                    return k.name.toLowerCase().startsWith(v.toLowerCase())
                });
            }

            //if (this.elemType == "TAGS") {
            //    console.log('TAGS setValue', v)
            //}

            if (this.elemType == "TYPEAHEAD") {
             //   console.log('TYPEAHEAD setValue', v)
            }

            if (this.photo) {
                this.ehis_img_src(v);
            }


        }
    }

    get confirm() {
        return this.confirmValue;
    }

    set confirm(v: any) {
        if (v !== this.confirmValue) {
            this.confirmValue = v;
            this.onChangeCallback(this.innerValue);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    select2_writeValue(data,selectClass) {

        if (data.id) {

            /* TYPE AHEAD
            $(this.el.nativeElement).find("select.select3").each((ind, domElement) => {


                let elem = $(domElement)
                if (elem.hasClass("select2-hidden-accessible")) {

                    if (elem.find("option[value='" + data.id + "']").length) {
                        elem.val(data.id).trigger('change');
                    } else {
                        // Create a DOM Option and pre-select by default
                        var newOption = new Option(data.text, data.id, true, true);
                        elem.prepend(newOption).trigger('change');  // append
                    }


                    //   $(domElement).val(value).trigger('change')

                }
            })
            */
            $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {

                if ($(domElement).find("option[value='" + data.id + "']").length) {
                    $(domElement).val(data.id).trigger('change');


                }
                else {
                    // Create a DOM Option and pre-select by default
                    var newOption = new Option(data.text, data.id, true, true);
                    // Append it to the select
                    $(domElement).append(newOption).trigger('change');
                }
            })
        }
        else
        if (data.text) {

            data.id = data.text

            $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {

                if ($(domElement).find("option[value='" + data.id + "']").length) {
                    $(domElement).val(data.id).trigger('change');
                } else {
                    // Create a DOM Option and pre-select by default
                    var newOption = new Option(data.text, data.id, true, true);
                    // Append it to the select
                    $(domElement).append(newOption).trigger('change');

                   // $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {

                        $(domElement).find("option[value='" + data.id + "']").each( (ind,option)=> {
                            $(option).attr('data-select2-tag',true)
                        })
                  //  })

                }
            })

           // console.log('tad received')
        }

    }


    @ViewChild('dynamic_array') dynamic_array

//    @ViewChild('dynamic_index') dynamic_index

    //From ControlValueAccessor interface
    writeValue(value: any) {

        /*
        if (this.elemType == 'ARRAY') {
            if (this.dynamic_array) this.dynamic_array.data = value
           // console.log('write value',this.dynamic_array, value)
            return
        }
        */

        /*
        if (this.elemType == 'INDEX') {
            this.innerArray = value
           // if (this.dynamic_index) this.dynamic_index.writeValue(value)

            console.log('write_value',value)
            return
        }
        */
        if (value !== this.innerValue) {


            if (this.elemType == 'CHECKBOX') {
                this.innerValue = (+value) ? '1' : '';
            }
            else {
                this.innerValue = value;
            }
            this.innerTypedValue = value

            if (this.photo) {
                this.ehis_img_src(this.value);
            }

            if (this.elemType == "TAGS") {
               // this.initSelect2()   --NEW21
            }

            if (this.elemType == "TYPEAHEAD") {

                let data =  {
                    id: value,
                    text: value
                }

                this.select2_writeValue(data,'select3')
            }


            if (this.elemType == "USER" || this.elemType == "ICD10") {

                if (value) {

                   // console.log('select2_writeValue:3',this.parent)
                    let name = ''
                    if (this.parent) {
                        let p = this.selectMap[this.elemLink]
                        if (!p) p = this.elemLink.toLowerCase()

                        let item =  this.parent.item ? this.parent.item : this.parent

                        let obj = item[p]
                        if (obj) {
                            name = obj ? obj.name : (this.istaged ? item[this.taged] : '')

                        }
                        else {
                            let url:string = this.getLinkUrl() +'/' + value.toString().trim();
                         //   console.log('select2_writeValue:4',url)

                            this.authService.get(url).subscribe(
                                result=> {
                                    name = result.name // : (this.istaged ? item[this.taged] : '')
                                    let data = {
                                        id: value,
                                        text: name
                                    }
                                    if (this.forbilling && this.disabled) {
                                        this.chosen.emit(data)
                                    }
                                    this.select2_writeValue(data, 'select4')


                                },
                                error=>{

                                }
                            )

                            return
                        }


                    }
                    let data = {
                        id: value,
                        text: name
                    }
                    this.select2_writeValue(data, 'select4')
                }
                // SP++ Empty
                else {

                   // console.log('clear select2')

                    this.innerValue = null
                    this.innerTypedValue = null

                    let selectClass= 'select4'
                    $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {
                        $(domElement).val(null).trigger('change');


                    })
                }
            }


        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled: boolean): void {    // new ???
    }

    /*
     validate(c: FormControl) {
     return this.validateFn(c);
     }
     */

    elemType = "INPUT";
    elemLink = ''

//    $el: any;


    updateRequired(elem) {


        if (elem.hasAttribute('required')) {


            if (elem.hasAttribute('disabled')) {
                $(elem).removeAttr('required')
                return
            }

            $(elem).children('div.form-group').each(function () {


                $(this).find(':input').each(function () {
       //         $(elem).find('div.form-group :input').each(function () {

                let $input = $(this);
                $input.attr('required', '1')

                $input.closest('.form-group').each(function () {

                    let $group = $(this);

                    $group.find('label.control-label').each(function () {

                        let $label = $(this)

                        if ($label.text()) {

                            $label.addClass('label-required'); // css( "border", "13px solid red" );
                        }
                    })
                })

            })
        })

        }

    }

    @Input('required') required = false

    pre_required = false

    updateRequired2(elem) {


        if (elem.hasAttribute('required')) {

            //elem.removeAttribute('required')
         //   console.log('reuqired',this.required)


            /*
            if (elem.hasAttribute('disabled')) {
                $(elem).removeAttr('required')
                return
            }
            */
            this.pre_required = true

            /*
            $(elem).children('div.form-group').each(function () {


                $(this).find(':input').each(function () {
                    //         $(elem).find('div.form-group :input').each(function () {

                    let $input = $(this);
                    $input.attr('required', '1')

                    $input.closest('.form-group').each(function () {

                        let $group = $(this);

                        $group.find('label.control-label').each(function () {

                            let $label = $(this)

                            if ($label.text()) {

                                $label.addClass('label-required'); // css( "border", "13px solid red" );
                            }
                        })
                    })

                })
            })
            */

        }

    }

    updateDisabled(elem) {


        this._disabled = elem.hasAttribute('disabled'); // $(elem).attr('disabled');
    }

    setAttr(elem,attrName,value) {
        $(elem).children('div.form-group').each( (ind1,elem1) => {

            $(elem1).find(':input').each((ind2, elem2) => {

                let $elem2 = $(elem2);
                $elem2.attr(attrName, value)

            })
        })
    }

    updateNames() {
        $(this.el.nativeElement).find(':input').not(':radio').each((ind2, elem2) => {

            let $elem2 = $(elem2);

           //
    //        if (name=='password' || name=='password_confirm' || name == 'email') {
                if (name == 'email') {
                    // do nothing: validation rules
            }
            else {
                var id = "id" + Math.random().toString(16).slice(2)

                $elem2.attr('name', id)
            }

          //  if ($elem2.next().is('label')) {
          //      console.log('label')
          //  }

        })

    }

    updateAttr(elem, attrNames) {
        let arr = attrNames.split(',').map(item => item.trim());
        arr.forEach((name) => {

            let attr = $(elem).attr(name);
            if (attr) {

                $(elem).children('div.form-group').each( (ind1,elem1) => {

                    $(elem1).find(':input').each((ind2, elem2) => {

                        let $elem2 = $(elem2);
                        $elem2.attr(name, attr)

                    })

                })
/*
                    $(this).find(':input').each(function () {
                        //         $(elem).find('div.form-group :input').each(function () {

                        let $input = $(this);
                        $input.attr('required', '1')

                        $(elem).find('.form-group :input').each((index, elem) => {

                    let $elem = $(elem);
                    $elem.attr(name, attr)

                })
                */
            }
        })
    }


 //   bs_cols = 2

    updateClasses() {

        let el = this.el
        let $el = $(el.nativeElement);


        let classes = $el.attr('class').split(' ').map(elem => elem.trim()).filter(elem => elem.startsWith('item-col-'))
        if (classes.length > 0) {

            let colclass = classes[0];
            let a = colclass.split('-'); // item-col-md-12
            a.shift()
            this.inputClass = a.join('-')

            let b = this.inputClass.split('-')
            //let x = 12-b[2];
            b[2] = (12 - +b[2]).toString()
            //this.bs_cols = +b[2]

            this.labelClass = b.join('-')
            /*
                        let colclass = classes[0];
                        let a = colclass.split('-');
                        let w = a[2]


                        this.inputClass = 'col-sm-' + w
                        this.labelClass = 'col-sm-'+ (12-w)
            */
        }


        if ($el.is('[class^="col-"]')) {
            
            let arr = $el.attr('class').split(' ').map(elem => elem.trim).filter(elem => elem.startWith('col-'))
            if (arr.lenght > 0) {
                
            }
        }


        if ($el.hasClass('checkbox')) {
            this.elemType = "CHECKBOX"
        }

        if ($el.hasClass('password')) {
            this.elemType = "PASSWORD"
            this.pre_required = true
        }

        if ($el.hasClass('password-confirm')) {
            this.elemType = "PASSWORD-CONFIRM"
            this.pre_required = true
        }

        if ($el.hasClass('text')) {
            this.elemType = "TEXTAREA"
        }

        if ($el.hasClass('suggest')) {
            this.elemType = "SUGGEST"
        }

        if ($el.hasClass('time')) {
            this.elemType = "TIME"
        }

        if ($el.hasClass('date')) {
            this.elemType = "DATE"
        }

        if ($el.hasClass('datepicker')) {
            this.elemType = "DATEPICKER"
        }

        if ($el.hasClass('select')) {
            this.elemType = "SELECT"
        }

        if ($el.hasClass('legend')) {
            this.elemType = "LEGEND"
        }
        if ($el.hasClass('tabset')) {
            this.elemType = "TABSET"
        }

        if ($el.hasClass('lookup')) {
            this.elemType = "LOOKUP"
        }

        if ($el.hasClass('number')) {
           // this.elemType = "NUMBER"
            this.elemType = "INPUT"
            this.number = true
        }

        if ($el.hasClass('tax')) {
            this.elemType = "NUMBER"
            this.currencyOptions = {
                thousands: '',
                decimal: '.',
                precision: 0,
                align: 'left',
                allowNegative: false,
                prefix: ''
            }
        
        }

        if ($el.hasClass('currency')) {
            this.elemType = "NUMBER"
            this.currencyOptions = {
                thousands: ' ',
                decimal: '.',
                precision: 2,
                align: 'left',
                allowNegative: false,
                prefix: ''
            }

        }


        if ($el.hasClass('photo')) {
            this.elemType = "PHOTO"
            this.photo = true;
        }

        if ($el.hasClass('file')) {
            this.elemType = "PHOTO"
            this.photo = false;
        }


        if ($el.hasClass('skip')) {
            this.elemType = "SKIP"
        }

        if ($el.hasClass('info')) {
            this.elemType = "INFO"
        }

        if ($el.hasClass('info-s')) {
            this.elemType = "INFO-S"
        }

        if ($el.hasClass('button')) {
            this.elemType = "BUTTON"
        }

        if ($el.hasClass('color')) {
            this.elemType = "COLOR"
        }

        if ($el.hasClass('table')) {
            this.elemType = "TABLE"
        }

        if ($el.hasClass('pie-chart')) {
            this.elemType = "PIE-CHART"
        }

        // ++select2

        if ($el.hasClass('tags')) {
            this.elemType = "TAGS"
            this.mode = 'multi'
        }

        if ($el.hasClass('tag')) {
            this.elemType = "TAGS"
            this.mode = 'one'
        }

        if ($el.hasClass('taged')) {
            this.elemType = "TAGS"
            this.mode = 'taged'
        }

        // --select2


        if ($el.hasClass('typeahead')) {
            this.elemType = "TYPEAHEAD"
        }

        if ($el.hasClass('warning')) {
            this.elemType = "WARNING"
        }
/*
        if ($el.hasClass('dyn-title')) {
            this.elemType = "DYN-TITLE"
        }
*/
        if ($el.hasClass('array')) {
            this.elemType = "ARRAY"
        }

        if ($el.hasClass('radios')) {
            this.elemType = "RADIOS"
        }

        if ($el.hasClass('index')) {
            this.elemType = "INDEX"
        }

        if ($el.hasClass('user-index')) {
            this.elemType = "USER-INDEX"
        }

        /*
                if ($el.hasClass('icd10')) {
                    this.elemType = "USER" //"ICD10"
                    this.elemLink = "ICD10"
                }

                if ($el.hasClass('e-user')) {
                    this.elemType = "USER"
                    this.elemLink = "USER"
                }

                if ($el.hasClass('e-patient')) {
                    this.elemType = "USER"
                    this.elemLink = "PATIENT"
                }
        */
        let classNames = $el.attr('class').split(/\s+/).map(elem => elem.trim()).filter(elem => elem.startsWith('e-'))
        if (classNames.length) {
            this.elemLink = classNames[0].substr(2).toUpperCase()
            this.elemType = "USER"
        //    if (this.elemLink == "PATIENT") {
        //        console.log('update Classes', this.elemType, this.elemLink)
        //    }

        }

        if ($el.hasClass('period')) {
            this.elemType = "PERIOD"
        }


    }

  //  @Input('rows') rows = 3
/*
    setOption(optionName) {
        let value = (this.options && +this.options[optionName]) ? +this.options[optionName] : 0
        if (value) {
            let elem = this.el.nativeElement
            this.setAttr(elem,optionName,value)
            console.log('rows',value)
        }
    }
*/
    constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef,public authService:AuthService, public csv: CsvService) {

        let $el = $(el.nativeElement);


        this.updateRequired2(el.nativeElement)
        this.updateDisabled(el.nativeElement)


        this.name = $el.attr('name');
        if (this.name) {
            if (this.name.endsWith('_id')) {
                let s = this.name.slice(0, -3)

                this._view = s + '.name'
                this.link = '/' + s;
                this.label = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
            }
        }

        this._datePipe =  createAutoCorrectedDatePipe('dd/mm/yyyy');
       // this.updateClasses()
       // console.log('constructor',this.elemType)
    }

    ngOnInit() {

        this.parent = this.getParentComponent()

         this.updateClasses()

        //this.validateFn = noop;

        if (this.photo) {
            this.ehis_img_src(this.value);
        }

        $(this.el.nativeElement).find('.select3').each((ind, domElement) => {

            $(domElement).removeClass('sa-hidden')
        })

        //this.$form = $(this.el.nativeElement).closest('form');

        let index = (this.options && this.options.index) ? (Array.isArray(this.options.index) ? this.options.index: null ): null
        if (index) {
            this.index = index
        }

    }



    ngAfterContentInit() {
  //      if (this.elemType == "TAGS") console.log('afterCOntentInit',this._index,this.innerValue)
  //     console.log('ngAfterContentInit',this.elemType,this.elemLink)
    }


    _type = 'text'
    getInputType() {
        return this._type
    }

    ngAfterViewInit() {

       //   if (this.elemType == 'INPUT') {
        let elem = this.el.nativeElement

        // dynamic  set options
     //


       // this.updateRequired(elem)

        this.updateAttr(elem, 'name')

        this.updateNames()  // SP++

        // this.updateAttr(elem, 'name,rows,placeholder,minlength')


    //    this.updateAttr(elem, 'name,placeholder')
        this.updateAttr(elem, 'maxlength,data-msg-maxlength,email,data-msg-email,alphanum,data-msg-alphanum')
        this.updateAttr(elem, 'emails,data-msg-emails,digits,data-msg-digits')
        this.updateAttr(elem, 'data-msg-required')  // button.directive.ts: terms

        if (this.labelClass) {

            let $el = $(elem)
            $el.find('.col-sm-2').each((index, elem) => {
                $(elem).removeClass('col-sm-2').addClass(this.labelClass)
            })
        }
        if (this.inputClass) {
            let $el = $(elem)
            $el.find('.col-sm-10').each((index, elem) => {
                $(elem).removeClass('col-sm-10').addClass(this.inputClass)
            })
        }

      //  console.log('init select4 1')
        this.initSelect4()


        if (this.elemType == 'PASSWORD') {
            this._type = 'password'
        }

    }

    _disabled = false

    @Input('disabled') set disabled(value) {

        //if (this.elemType == 'TEXTAREA') {
        //    console.log('disabled TEXTAREA', value)
        //}

        if (this._disabled != value) {

            this._disabled = value

            if (this.elemType == 'USER') {

                if (this.clearButton) {

                    $(this.el.nativeElement).find("select.select4").select2({allowClear: !value})
                }
                /*
                $(this.el.nativeElement).find("select.select4").each((ind, domElement) => {


                    $(domElement).select2({ allowClear: !value})

                })
                */
            }
        }
    }

    get disabled() {
        return this._disabled
    }

    @Input('enabled') set enabled(value) {
        this.disabled = !value
    }



    public get timeMask(): any {
        return [/[0-2]/, /\d/, ':', /[0-6]/, /\d/];
    }

    _datePipe = null


    private get dateTextMask() {
        return {mask: this.dateMask,  keepCharPositions: true, pipe: this.datePipe}
    }
    public get datePipe(): any {
        return this._datePipe
       // return createAutoCorrectedDatePipe('dd/mm/yyyy');
    }

    public get dateMask(): any {
        return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }

    isdisabled() {
        if (this.elemType == 'USER' && this.elemLink == 'USER') {
            if (!this.authService.can_user_list()) {
                return true
            }
        }

        return this.disabled ? true : undefined
    }


    @Input('icon') icon = null;

    // Index: select - drop down

    _index = []

    //   indexHasGroups = false


    get index() {
        return this._index
    }

    // LOOKUP ----------------------------------------------------------

    parent: any = null;

    // https://github.com/angular/angular/issues/10448
    getParentComponent(): any {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component
    }


    @Input('lookupButton') lookupButton = true;
    @Input('clearButton') clearButton = false;
    @Input('viewButton') viewButton = false;

    get disabledColor() {
        return this.disabled ? '#eee' : '#fff'
    }

    //@Input('name')
    name = 'user_id'
    @Input('with') _view = 'user'

    get viewName() {

        if (this.parent.item) {

            //       console.log('lookup',this.parent.item)

            let id = this.parent.item[this.name];
            if (id) {


                let a = this._view.split('.');
                if (a.length < 2) {
                    a.push('name')
                }
                let _with = this.parent.item[a[0]]
                if (_with) {
                    if (id == _with.id) {
                        return _with[a[1]];
                    }
                }
            }


            return id;

        }

        /*
        if (this.value) {
            let a = this._view.split('.');
            if (a.length < 3) {
                a.concat['name']
            }
            let item = this.parent[a[0]];
            if (item) {
                let _with = item[a[1]]
                if (_with) {
                    return _with[a[2]];
                }
            }
        }
        */
        return ''; // '<not selected>'
        //if (this.value) return this._view;
        //return '<not selected>'

        //     let view = this.parent.item[this.value];
        //     return view ? view[this.name] : ""
    }


    clearLink() {
        if (this.parent.item) {

            for (let key of [this.name, this._view]) {
                if (key in this.parent.item) {
                    this.parent.item[key] = null;
                }
                else {
                    delete this.parent.item[key];
                }
            }


            /* let id = this.parent.item[this.name];

            if (id) {
                delete this.parent.item[this.name];
                // sp+++
                //-----
            }
            this.parent.item[this._view] = null;
            */
        }
        this.value = null;

//        delete this.parent.item[this.key];
//        this.parent.item[this.value] = null;
    }

    @Input('link') link = '';

    view() {
        //let id = this.value;
        if (!this.link) return;

        if (this.parent.item) {
            let id = this.parent.item[this.name];

            if (id) {
                let l1 = this.link.split('/').slice(1, 2);
                //           console.log(this.link, l1)
                //            let link = this.link.split('/').concat([id]);  // detail
                let link = l1.concat([id]);  // detail
                let _with = this._view.split('.')[0]
                this.parent.view(link, this.name, _with);
            }
        }
        /*
        let id = this.parent.item[this.key]
        if (id) {
            let link = this.link.concat([id]) ;  // detail
            this.parent.view(link,this.key,this.value);
        }
        */
    }

    lookup() {
        if (this.parent && this.link && this.name) {

            let link = this.link.split('/'); // .concat([id]);  // list
            let _with = this._view.split('.')[0]
            this.parent.lookup(link, this.name, _with);
        }

        // this.parent.lookup(this.link,this.key,this.value);

    }


    //***********  PHOTO ***********************************************************************


    //////////////////////////////////////////////////////////////////////////////////////////////

    is_uploading: boolean = false;
    is_downloading: boolean = false;

    //   @ViewChild('upload_hidden') upload_hidden; // = document.getElementById('module-component');
    @Input() fileName: string = "";
    @Output() fileNameChange = new EventEmitter<string>()

    @Output() onUpload = new EventEmitter();
 //   @Input() tag: any = {};


    upload() {
        if (this.isdisabled()) return

        this.el.nativeElement.querySelector('input').click()

        //   this.upload_hidden.nativeElement.click();
    }

    accept() {
        if (this.photo) {
            return "image/jpeg,image/png";
        }
        else {
            return "application/pdf,image/jpeg,image/png,text/plain";
        }
    }

    uploadOptions() {
        return this.authService.uploadOptions();
    }

    @Output('onClearBlob') public onClearBlob: EventEmitter<any> = new EventEmitter();


    clear_img_src() {    // new upload && clear
        this.value = null
        this.onClearBlob.emit()
        //
    //    console.log('uploading',this.is_uploading)
       // this.img_src = null

       // this.value = null

    }

    test = false

    beforeUpload(uploadingFile: any /* UploadedFile */) {

      //  this.is_uploading = true;
       // return
        let sizeLimit = this.authService.upload_max_size();


        if (uploadingFile.size > sizeLimit) {
            uploadingFile.setAbort();
            let errMsg = 'File is too large! Maximum allowed file size is ' + this.authService.upload_max_size_str() + '.';
            this.authService.showError(errMsg);
        }
        else {

            this.onClearBlob.emit() // -->  dynamic not ready
           // this.clear_img_src()
            this.is_uploading = true;
            this.test = true

        }
    }

    onUploadRejected($event) {
        this.is_uploading = false;
//        this.authService.spinnerStop();
        this.authService.showError('Upload rejected');
    }

    onFileLoaded($event) {

//        this.authService.spinnerStop();
    }

    emitOnUpload() {
        if (this.photo) {
            this.ehis_img_src(this.value);
        }
        else {
            this.is_uploading = false;
        }
        let tag = {}; // let tag = this.tag
        this.onUpload.emit({fileName: this.fileName, link: this.value, tag: tag });
    }

    handleUpload(data): void {
        if (data && data.done && data.response) {
//            this.authService.spinnerStop();
            data = JSON.parse(data.response);

            if (data.error) {
                this.is_uploading = false;

                this.authService.showError('Upload error. Upload rejected.');
                return;
            }
            let link = this.authService.urlName(data.src);

            this.value = link;

            let x = data.done
            //delete x.response
           

            //let s = this.upload_hidden.nativeElement.value;

            let s = this.el.nativeElement.querySelector('input').value


            let fileName = s.split(/(\\|\/)/g).pop();

           

            this.fileName = fileName;

            this.fileNameChange.emit(fileName);

            // this.is_uploading = false;

//            this.is_uploading = false;
            this.emitOnUpload();


            //           this.onUpload.emit({ fileName: this.fileName, link: this.value , tag: this.tag });

            /*
             if (this.uploadModel) {

             if (this.dynamicFormModel.indexOf(this.uploadModel) >= 0) {
             if (this.uploadIndex >=0 && this.uploadIndex < this.uploadModel.options.length) {
             this.uploadModel.options[this.uploadIndex].link = link;
             }
             }
             }
             this.uploadModel = null;
             this.uploadIndex = -1;
             */
        }
    }

    clearPhoto() {
        if (this.isdisabled()) return;

        let s = 'Are you sure to clear?';
        this.authService.confirmationDialog(s)
            .subscribe(result => {
                    if (result) {
                        this.value = '';
                        this.fileName = '';
                        this.emitOnUpload();
                      //  this.onAfterPhoto.emit(null)
                        this.onClearBlob.emit()
                    }
                },
                error => {
                    this.authService.showMessage(error)
                });
    }

    download() {

        if (false && this.photo) {
            var newWin = window.open('about:blank',this.value) // , this.value);
            //newWin.document.write("Привет, мир!");

            newWin.onload = () => {

                console.log('onload')


                var $body = $(newWin.document.body)

                var div = newWin.document.createElement('img')
                div.src = this.img_src

                var body = newWin.document.body;
               // div.innerHTML = 'Добро пожаловать!'
               // div.style.fontSize = '30px'

                // вставить первым элементом в body нового окна
                body.insertBefore(div, body.firstChild);
            }

            return
        }

        this.is_downloading = true;


       // let link = this.urlName(this.value);
       // let url = this.apiUrl(link);

      //  this.authService.getImageBlob(url)
        this.authService.getImageBlob(this.value)
            .subscribe(
                (result) => {
                    var blob = result //  .blob();

                    if (blob.size == 0) {
                        this.is_downloading = false;
                        this.authService.showError('Download file error: no file.')
                    }
                    else {

/*
                        var reader = new FileReader(); //window.FileReader();

                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            let base64data = reader.result;

                            var windowReference = window.open(base64data)

                            //var urlCreator = window.URL;
                            //var locUrl = urlCreator.createObjectURL(base64data);

                          //  this.img_src = base64data;
                            this.is_uploading = false;
                        }

*/
                        if (this.authService.ie) {
                            window.navigator.msSaveOrOpenBlob(blob, this.fileName);
                            console.log('download ie')


                        }
                        else {
                            let ref  = URL.createObjectURL(blob)
                            let win = window.open(ref, this.fileName) // '_blank',)
                            $(win.document).ready(() =>
                            {
                                win.document.title = this.fileName
                                console.log('ready',this.fileName)
                                this.is_downloading = false;
                            });
                            //win.document.title = this.fileName
                            //this.is_downloading = false;
        /*
                             win.onload =()=>{
                                 $(win.document.body).find('head').find('title').innerValue = this.fileName
                                 console.log('onload')
                                 this.is_downloading = false;
                             }
    */
                            // }
                            /*

                            var reader = new FileReader(); //window.FileReader();

                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                                //let base64data = reader.result;
                                window.open(URL.createObjectURL(blob), '_blank')
                                //this.img_src = base64data;
                                this.is_uploading = false;
                             }

                           // window.open(URL.createObjectURL(blob), '_blank')
                           // window.onload =()=>{
                           //     this.is_downloading = false;
                           // }
                            /*
                            dllink.href = window.URL.createObjectURL(myBlob);
                            dllink.setAttribute("download","custom_name.csv"); // Added Line
                            dllink.click();
                            let ref = URL.createObjectURL(blob);
                            */
                            /*
                            let newWindow = window.open(ref);
                            newWindow.onload = () => {
                                newWindow.location.href = ref // URL.createObjectURL(blob);
                                this.is_downloading = false;
                            };
                            */
                           // newWindow.onload = () => {

                            //    let locUrl = URL.createObjectURL(blob);
                           // window.open(locUrl,'_blank')
                          //  var windowReference = window.open(blob, this.fileName);
                            /*
                                                        console.log('download nm')
                                                        var urlCreator = window.URL;
                                                        var locUrl = urlCreator.createObjectURL(blob);

                                                        var windowReference = window.open(locUrl)
                                                      //  windowReference.location.href = locUrl;
                                                        windowReference.document.title = this.fileName;
                                                    }
                                                    */
                        }
                       // this.is_downloading = false;

                    }
                },
                (error) => {
                    this.is_downloading = false;
                    this.authService.showError(error);
                })


    }

    /*
    urlName(value) {

        var i = value.lastIndexOf('/');
        if (i < 0) {
            i = value.lastIndexOf('\\');
        }
        if (i < 0) {
            return value;
        }
        ;

        return value.slice(i + 1);
    }

    apiUrl(value) {
        return this.authService.getApiUrl() + '/api/image/' + this.urlName(value);
    }
    */
//-------------------------------------------------------------------------------------------

    img_src: any = '';

    @Input() photo: boolean = false;


    hasPhoto() {
        return this.photo && (!this.is_uploading) && this.value ;
    }

    @Output('onBeforePhoto') public onBeforePhoto: EventEmitter<any> = new EventEmitter();
    @Output('onAfterPhoto') public onAfterPhoto: EventEmitter<any> = new EventEmitter();

 //   @Output('onAfterPhoto') public onAfterPhoto: EventEmitter<any> = new EventEmitter();

    ehis_img_src(value: string) {

        // map value ...

        if (value) {

            this.is_uploading = true;
         //   this.onBeforePhoto.emit(value)

          //  let url = this.apiUrl(value); // this.authService.getApiUrl() + this.urlName(value);

            this.authService.getImageBlob(value)
                .subscribe(
                    (result) => {

/*
                        if (this.test) {
                            console.log('test')
                            this.onAfterPhoto.emit(this.img_src)  // !!! (this.model as DynamicFileModel).base64data = base64data;

                            this.is_uploading = false;
                            return;

                        }
                        */

                        var blob = result;

                       var reader = new FileReader();

                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {

                            let base64data = reader.result;
                           // console.log('base64data:1')
                            this.img_src = base64data;
                            //console.log('base64data:2')
                            this.is_uploading = false;
                            this.onAfterPhoto.emit(this.img_src)  // !!! (this.model as DynamicFileModel).base64data = base64data;
                        }


                    },
                    (error) => {
                        this.onAfterPhoto.emit(this.img_src)  // !!! (this.model as DynamicFileModel).base64data = base64data;

                        this.is_uploading = false;
                    })

        }
        else {
            this.img_src = '';
            this.is_uploading = false;
            //this.onAfterPhoto.emit(this.img_src)
        }
    }

    //*****************************************************************************************************
    /*
    get authService() {
        return this.parent.baseService.authService
    }
    */

    //** Suggest ******************************************************************************

    public typeaheadOnSelect(e: any): void {

        if (e.item) {
            this.value = e.item.name;
        }
    }


    //*****************************************************************************************************

    //** DATEPICKER *****

    @Input('format') format = 'yy-mm-dd' // 'dd/mm/yy'

    /*
    @Input('placeholder') _placeholder = null;

    get placeholder() {
        if (this._placeholder) return this._placeholder;
        return this.format;
    }
    */
    dateChanged($event) {
        this.value = $event;
    }

    //** NUMBER *********

    currencyOptions = {
        thousands: ' ',
        decimal: '.',
        precision: 0,
        align: 'left',
        allowNegative: false,
        prefix: ''
    }
    infoClick(link) {
        let l = link.split('/')
      //  console.log(l)
        return this.authService.navigate(l)

    }

    buttonClick($event) {
        $event.stopPropagation() // NB!
        this.click.emit()
    }

    @Input('width') width = 160;

    get dt_style() {
        return {width: this.width.toString() + 'px'}
    }

    get dd_style() {

        return {'margin-left': (this.width + 20).toString() + 'px'}

    }

    // TABLE //////////////////////////////////////////////////////////////

    @Input('headerDark') header_dark = true // false
    @Input('scrollY') scroll_y = 1
    @Input('fixedCol') fixed_col = 1;
    //  height:400px;width:100%;overflow: scroll"

    @Input('tableHeight') table_height = '300px' //458px'

    tableStyle = {}

    @Input('max_width') set maxWidth(value) {
        if (value) {
            this.tableStyle = {'max-width': value}
            this.pieChartStyle = {'max-width': value}
        }
        else {
            this.tableStyle = {}
            this.pieChartStyle = {}

        }
    }

    @Input('download_csv') download_csv = true
    @Input('get_pdf') get_pdf = true


    get tableWrapperStyle() {
        if (this.scroll_y) {
            return {height: this.height}
        }
        else {
            return null
        }
    }

    downloadCSV() {
        var tableElem = this.el.nativeElement.querySelector("table");
        var fileName = this.fileName ? this.fileName : 'download.csv'
        this.csv.download(tableElem, fileName)

    }

    tablePdf() {
        var tableElem = this.el.nativeElement.querySelector("table");
        var fileName = this.fileName ? this.fileName : 'download.pdf'
        this.csv.tablePdf(tableElem, fileName)
    }

    tableElem() {
        var tableElem = this.el.nativeElement.querySelector("table")
        if (!tableElem) throw "Html tag <table> not found."
        return tableElem
    }


    // PIE-CHART

    @Input('height') height = '400px'
    @Input('get_picture') get_picture = true

    @Input('mode') mode = ''
    @Input('title') title = ''


    _pieChartOptions :any = {
        series: {
            pie: {show: true}
        }
        ,
        legend: {
            show: false
        }
        ,
        opacity: 0,

        xaxis: {},
        yaxis: {}
    }


    //pieChartData format: [{ label: group.name, data: group.count}]

    get pieChartOptions() {

        var options:any = {

         //   title: '',

            series: {
                pie: {show: false,
                    radius: 0.7, // 3/4,
                    label: {
                        show: true,
                        radius: 0.90,
                        threshold: 0.04,

                    },
                    combine: { threshold: 0.04, label: 'Others' }
                },
            }
            ,
            legend: {
                show: false
            }
            ,
/*
            label: {
                show: false,
                radius: 0.5,
                threshold: 0.04,
            }
*/
           // threshold: 0.04,
         //   opacity: 0,

         //   xaxis: { mode: ''},
         //   yaxis: {},
         //   grid: {}
        }

        //return options  // error temporary fixed

        if (this.mode.toLowerCase() =='time') {

            console.log('pie',this.mode)
            options = {

                title: "Avg, sec",
                /*
                yaxis: {
                    min: 0,
                    max: 200
                },
                */
                xaxis: {

            mode: "time",
                    timeformat: "%d %b", //"%d/%m", // "%H:%M:%S", // %H:%M:%S", // %Y-%m-%d "%Y/%m/%d"
                    minTickSize: [1, "day"], // [1, "month"];// "second" month
//            tickSize: [60,"second"],
                    timezone: "browser",
                    //min: 0,
                    //max: this.seconds
                },
                //colors: [  'rgb(87, 136, 156)' , 'rgb(55, 180, 55)'],
                grid: {
                    show: true,
                    hoverable: false, //true,
                    clickable: false, // true,
                    borderWidth: 0,
                    margin: 10,

                },
                series: {
                   lines: {
                     //   lineWidth: 2,
                        /*
                        fill: true,
                        fillColor: {
                            colors: [
                                {
                                    opacity: 0 //0.4
                                },
                                {
                                    opacity: 0.8
                                }
                            ]
                        },
                        steps: false,
                        */
                    }
                },
                legend : {
                    show : true, //,
                    noColumns : 1, // number of colums in legend table



                    //           labelFormatter : function(label, series) {
                    //               return '<div style="font-size:11px;text-align:center;padding:4px;color:white;">' + label + '<br/>' + Math.round(series.percent) + '%</div>';
                    //           },

                    labelFormatter : null, // fn: string -> string
                    /*
                     function(label, series) {
                        // series is the series object for the label
                        return '<a href="#' + label + '">' + 'label' + '</a>';
                    }, // fn: string -> string or null
        */
                    labelBoxBorderColor : "#000", // border color for the little label boxes
                    container : null, // container (as jQuery object) to put legend in, null means default on top of graph
                    position : "nw", // position of default legend container within plot
                    margin : [10, 6], // distance from grid edge to default legend container within plot
                    backgroundColor : null, // "#efefef", // null means auto-detect
                    backgroundOpacity : 1 // set to 0 to avoid background
                },

            }

        }

        return options
    }

    @ViewChild('pie_chart')  pieChart

    pieChartStyle:any = {}

    printPieChart() {
        this.pieChart.print()
    }

    pdfPieChart() {
        this.pieChart.pdf()
    }

    getCanvas() {
        return this.pieChart.getCanvas()
    }


    @Output('onAdd') public onAdd: EventEmitter<any> = new EventEmitter();
    @Output('onRemove') public onRemove: EventEmitter<any> = new EventEmitter();

    @Input('add') _add = false
    @Input('addButton') _addButton = 'Add'

    addEmit(e) {
        this.onAdd.emit(e)
    }

    removeEmit(e) {
        this.onRemove.emit(e)
    }

    get addButtonText() {

        let _add = this._add || (this.options ? this.options.add : false)

        if (_add || this.onAdd.observers.length > 0) {
            let s = this.selectAdd[this.elemLink]
            return s===undefined ? this._addButton : s
        }
        return ''
    }

    select2_on(domElement) {


        if (!$(domElement).hasClass("select2-hidden-accessible")) {

            $(domElement).on('select2:select', (e) => {
                // e.preventDefault()
                this.getSelect2(e);
            });


            $(domElement).on('change', function () {  // when the value changes
                if ($(domElement).hasClass('invalid')) {
                    $(this).valid(); // trigger validation on this element
                }
            });

            let addButtonText = this.addButtonText
            //console.log('select2_on',this.elemType,this.elemLink,this.onAdd.observers.length,addButtonText)

            if (addButtonText) {
                $(domElement).on('select2:opening', () => {  // when the value changes


                    var nome = $(domElement).data('select2').$dropdown.find("input");


                    nome.each((ind, domField) => {
                        if (!$(domField).hasClass('select2-search__add')) {
                            $(domField).addClass('select2-search__add')

                            var $a = $('<a href="javascript:void(0)" class="select2__add"></a>').text(addButtonText)
                            $(domField).after($a)
                            //let $a = $(domField).after('<a href="javascript:void(0)" class="select2__add">Add New Patient</a>');
                            $a.on('click', () => {

                                var $select2 = $(this.el.nativeElement).find("select.select4")


                                var term = $select2.data('select2').$dropdown.find("input").val();

                                $select2.select2('close')
                                //$select2.each((ind, domElement) => {
                                //    $(domElement).select2('close');
                                //})


                                if (this.onAdd.observers.length > 0) {
                                    this.addEmit(term)
                                 //   this.onAdd.emit(term)
                                }
                                else {
                                    if (this.elemLink == 'PATIENT') {
                                        this.addPatient(term)
                                    }
                                   // this.authService.showMessage(term)
                                }
                            })
                         //   console.log($a)

                        }
                    })
                    /*
                    <a style="
                    display: inline-block;
                    right: 1rem;
                     min-width: 80px;
                    position: absolute;
                    top: 6px;
                    float: right;
                    ">ADD New patient</a>
                        */

                });
            }

            /*
            $(domElement).on('select2:unselecting', ($event) => {
                console.log('unselecting',$event)
                $event.preventDefault()

                $(this.el.nativeElement).find('select ').each((ind,domElement) => {

                    $(domElement).val('').trigger('change')
                    this.value = ''
                    this.selected.emit(this.value)

                })

            });
            */

            $(domElement).on('select2:clearing', ($event) => {
                $event.preventDefault()  // SP++ change to Select2 !!!

                $(this.el.nativeElement).find('select ').each((ind,domElement) => {

                    console.log('clearing',$event)

                    $(domElement).val(null).trigger('change')
                    /*
                                        if (((domElement).hasAttribute('multiple'))) {
                                            this.value = ''
                                        }
                                        else {
                                            this.value = null
                                        }
                                        */
                    this.value = ''
                    this.selected.emit(this.value)

                })

            });


            $(domElement).on('select2:unselect', (e) => {

                // prevent dropdown https://github.com/select2/select2/issues/3209#issuecomment-149663474
                if (!e.params.originalEvent) {
                    return;
                }
                e.params.originalEvent.stopPropagation();

                this.getSelect2(e);
            });

             /*
            $(domElement).on('select2:selecting', function(e) {
                var cur = e.params.args.data.id;
                var old = (e.target.value == '') ? [cur] : $(e.target).val().concat([cur]);
                $(e.target).val(old).trigger('change');
                $(e.params.args.originalEvent.currentTarget).attr('aria-selected', 'true');
                return false;
            })
            */

            $(domElement).on('select2:unselecting', (e) => {

                // console.log('unselecting',e)
                if  (e.params.args.originalEvent) {
                    let currentTarget = e.params.args.originalEvent.currentTarget;

                    if (currentTarget === undefined) {
                        e.preventDefault();
                    }
                    else if (currentTarget.nodeName === 'LI') {
                        e.preventDefault();
                        $(domElement).select2("close");
                    }
                }

            });

            $(domElement).on('select2:open', (e) => {

               // $('.select2-container--open .select2-dropdown--below').css('display','none')
                console.log('index:display',this.index)

            });

            $(domElement).on('results:message', (e) => {

                // $('.select2-container--open .select2-dropdown--below').css('display','none')
                console.log('results:message',e)

            });


        }

     //   $('input.select2-search__field').on('keyup', function() {
      //      console.log('search',$(this).val()); // it alerts with the string that the user is searching
     //   });

    }

   // select2_count = 0;

    select2_tree = false

    select2_addTree() {

        // NEW
        for (let item of this._index) {
            item.text = item.name
        }
        return this._index

        let data = []
        for (let item of this._index) {
            if (item.children) {

                this.select2_tree = true

                let children = []
                for (let sub_item of item.children) {
                    children.push({text: sub_item.name, id: +sub_item.id})
                }
                data.push({text: item.name, children: children})
            }
            else {
                //              data.push({text: item.name, id: +item.id})
                data.push({text: item.name, id: item.id}) // 201909
            }
        }

        return data

    }


    initSelect2_NEW21() {

        $(this.el.nativeElement).find("select.select2").each((ind, domElement) => {

            let data = this.select2_addTree()

            this.select2_on(domElement)

            console.log('init select2')

            $(domElement).select2 ({

                    placeholder: this.placeholder,
                    //width: 'resolve',

                    allowClear: true,

                     data:  data,

                    templateResult:  (state) => {
                        if (!state.id  || !this.select2_tree) {
                            return state.text;
                        }
                        //var baseUrl = "/user/pages/images/flags";
                        let $state = $(
                        //    '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</span>'
                            '<span style="margin-left: 2rem;">'  + state.text + '</span>'
                        )
                        return $state;
                    },

                    matcher: (params, data)  => {
                        // If there are no search terms, return all of the data

                       // console.log('match',data)
                        if ($.trim(params.term) === '') {
                            $('.select2-container--open .select2-dropdown--below').css('display','block')
                            console.log('display:block')
                            return data;
                        }

                        if (typeof data.text === 'undefined') {
                            return null;
                        }

                        // `params.term` should be the term that is used for searching
                        // `data.text` is the text that is displayed for the data object
                        if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                            $('.select2-container--open .select2-dropdown--below').css('display','block')
                            console.log('display:block')
                            return data;
                        }

                        // checking if a value matches (my addition to their example)
                        //if (data.data.some(({Value}) => Value.includes(params.text)) {
                        //    return data;
                        //}

                        // Return `null` if the term should not be displayed
                        return null;
                    },

                language: {

                    loadingMore: function () {
                        return 'More results…';
                    },


                    searching: (params) => {
                        // console.log(params)
                        this.query = params; // !!!
                        return 'Searching…';
                    },

                    noResults: function () {   /// NEW
                        return 'Not system doctor';
                    },
                },

                //+++++  NEW

                maximumSelectionLength: 2, // 1,
                //tags: true,

                //tokenSeparators: null,


                insertTag: (data, tag) => {

                    console.log('insert tag')
                    data.unshift(tag)
                    data.sort((a,b)=> { return a.text.localeCompare(b.text)})
                    return

                    let opt = data.find(item=> {return item.id == tag.id})
                    if (opt) {
                        console.log('found')
                        opt.name = tag.text
                        opt.text = tag.text
                    }
                    else {
                        data.unshift(tag)
                    }

                    //this.index.sort((a,b)=> { return a.name.localeCompare(b.name)
                      //  if (a < b) return -1;
                      //  if (a==b) return 0;
                      //  return 1
                    //})

                    data.sort((a,b)=> { return a.text.localeCompare(b.text)})



                            /*         let $elem = $(this.el.nativeElement).find("select.select2")

                                 if ($elem) {
                                     var $options = $elem.find('option[data-select2-tag]')
                                     $elem.prepend($options)
                                 }
                                */

                },


                createTag: (params) => {
                    // Don't offset to create a tag if there is no @ symbol
                    //if (params.term.indexOf('@') === -1) {
                    //    // Return null to disable tag creation
                    //    return null;
                    //}


                    console.log('create tag',params)
/*
                    let opt = this.index.find(item=> {return item.id == -1})
                    if (opt) {
                        opt.name = params.term
                        opt.text = params.term
                        //this._index.sort((a,b)=> { return a.name.localeCompare(b.name)
                          //  if (a < b) return -1;
                          //  if (a==b) return 0;
                          //  return 1
                        //})
                       // return null
                    }
                    else {
                        this._index.push({ id:-1,name: params.term, text: params.term})

                    }
                    this._index.sort((a,b)=> { return a.name.localeCompare(b.name) })
*/
                    return {
                        id: -1, // params.term,
                        text: params.term
                    }
                }
                //++++++++++++++++   NEW


                });

            let s = this.innerValue;

            // console.log('set Index',s)

            let val = s ? s.split(',').filter((e) => {
                return +e
            }) : [];
            $(domElement).val(val).trigger('change')

        })

    }


    @Input('index') set index(value) {

        //console.log('set index',value)
        if (this.elemType=='SELECT' || this.elemType=='RADIOS' || this.elemType=="ARRAY") {
            this._index = value || []
            //console.log(this.elemType, this.index)
            return
        }


        if (this._index.length == 0) {

           this._index = value || []

           // this.initSelect2()  // SP++ 20190921  NEW21---

            this.initSelect3()

            this.initSelect4()

        }
    }


    @Output('selected') public selected: EventEmitter<any> = new EventEmitter();
 //   @Output('taged') public taged: EventEmitter<any> = new EventEmitter();


    findSelect2(callback) {

        $(this.el.nativeElement).children('div.form-group').each((ind1,elem1)=> {

            $(elem1).find('select').each((ind2, elem2) => {
                callback(elem2)
            })

        })
    }

    getSelect2($event) {

        $event.preventDefault()

        this.findSelect2((domElement)=>{



        //$(this.el.nativeElement).find('select ').each((ind,domElement) => {


            let v =  $(domElement).select2('val');

            if (((domElement).hasAttribute('multiple'))) {


                let arr = v ? v : [];

                // NEW +++
                //this.value = arr.join(',')   // NEW ---

                let old_arr = this.value.split(',')
                for(let val of old_arr) {
                    let ind = arr.indexOf(val)
                    if (ind>= 0) {
                        arr.splice(ind, 1);
                    }
                }

                this.value = arr.length ? arr[0] : ''

                $(domElement).val(this.value).trigger('change');

            }
            else {
                this.value = v
            }

            this.selected.emit(this.value) // ->getList


            //this.taged

            var $options = $(domElement).find('option[data-select2-tag]');

            $options.each((ind,option)  => {

                //if ((option).hasAttribute()'selected')) {

                //console.log('option', option.value)

                if (option.value == $event.params.data.id) {
                    //if ($(option).selected) {
                    let text = $(option).text()

                    //this.taged.emit(text)
                    // this.item.user_id = null;
                    // this.item.user_name = $event;

                    this.parent.item[this.taged] = text;


                    this.onChangeCallback(null);


                 //   console.log('emit tag ', text,$event)
                //}
                }
            //})

        })
/*
                if (this.value == '' ) {
                console.log('getSelect2',this.value)

                $(domElement).find(':selected').each((ind, option) => {
                    let text = $(option).text()
                    this.taged.emit(text)

                    console.log('emit',text)
                })

            }
            */
        })
   }

   getFileName() {
        if (this.photo) return '[Download]'
        return this.fileName ? '[Download '+this.fileName+']' : ''
   }

   get readonlyUpload() {
       return this.readonly || this.is_uploading || this.is_downloading
   }

    select2_style() {
        if (this.disabled) {
            return { 'background-color': '#eee', cursor: 'not-allowed' }
        }
        return {}
    }


    selectChange($event) {

     //   console.log('selectChange',this.value, this.item) // ???
        $event.preventDefault()
        this.selected.emit(this.value)
/*
        $(this.el.nativeElement).find('select ').each((ind,domElement) => {


            let v =  $(domElement).select2('val');

            if (((domElement).hasAttribute('multiple'))) {
                let arr = v ? v : [];
                this.value = arr.join(',')
            }
            else {
                this.value = v
            }
            //           console.log('getSelect2',this.value)
            this.selected.emit(this.value) // ->getList
*/
        }


    selectClear() {
        //this.writeValue(null)
        let selectClass= 'select4'
        $(this.el.nativeElement).find("select." + selectClass).each((ind, domElement) => {
            $(domElement).val(null).trigger('change');

        })

        this.value = null
        this.selected.emit(this.value)

    }

    selectMap = {
        ICD10: 'icd10',
        USER:  'user',
        PATIENT: 'patient',
        SERVICE: 'service'
    }

    selectAdd = {
        USER:  'Add new clinician',
        PATIENT: 'Add new client'
    }

    selectTemplate = {
        ICD10:  (item) => {
            if (item.loading) {
                return item.text;
            }
            //var term = this.query.term || '';
            //console.log(item)
            //var term = 'H04'
            let text = item.id+'\t'+item.text
           // var $result = markMatch(text, term);
            return text;
        },

        PATIENT: (item, nameOnly:boolean = false) => {

            if (!item && !item.id) {
                return ''
            }

            if (this.parent.item) {
                let obj = this.parent.item[this.selectMap[this.elemLink]]
                if (!obj) {
                    obj = this.parent.item[this.elemLink.toLowerCase()]
                }
                if (obj && item.id == obj.id) {
                    item = Object.assign(item, obj)
                }
            }

            // let id = item.id ? item.id: ''
            let name = item.text ? item.text : ''
            let house_address = item.house_address ? item.house_address : ''
            // let email = item.email
            let dob = item.date_of_birth ? moment(item.date_of_birth).format('DD MMM YYYY'): ''
            let text = ''
            if (nameOnly) {
                text+='<div style="display: inline-block;min-width:40%;">'+ name + '</div>'
                
            } else {
                text +=
                '<div style="display: inline-block;min-width:40%;">'+ name + '</div>'
                +'<div class="select2_option__green" style="display: inline-block;min-width:20%;border-left: solid 1px #ccc;padding-left:8px;">'+ dob + '</div>'
                +'<div class="select2_option__green" style="display: inline-block;">'+ house_address + '</div>'
            }
            
            return $(text)
        }
    }

    query:any = {};

    @Input('shadow') shadow = 0
    @Input('forbilling') forbilling = false;

    disableStyle() {
        if (this.shadow && this.isdisabled() ) {
            return { color: '#fafafa'}
        }
        return {}

    }

    initSelect3() {

        $(this.el.nativeElement).find("select.select3").each((ind, domElement) => {
            /*
            let data = []
            for (let item of this._index) {
                if (item.children) {
                    let children = []
                    for (let sub_item of item.children) {
                        children.push({text: sub_item.name, id: +sub_item.id})
                    }
                    data.push({text: item.name, children: children})
                }
                else {
                    data.push({text: item.name, id: +item.id})
                }
            }
            */
            let data = this.select2_addTree()


            this.select2_on(domElement)

            $(domElement).select2 ({
                    placeholder: this.placeholder,
                    //width: 'resolve',

                    allowClear: true,
                    //   closeOnSelect: false,
                    data: data,

                    tags: true,

                language: {

                    loadingMore: function () {
                        return 'More results…';
                    },


                    searching: (params) => {
                        // console.log(params)
                        this.query = params; // !!!
                        return 'Searching…';
                    },

                  //  noResults: function () {
                  //      return 'Not system doctor';
                  //  },
                },


                    insertTag: (data, tag) => {

                        console.log('insert tag')
                        let $elem = $(this.el.nativeElement).find("select.select3")

                        if ($elem) {
                            var $options = $elem.find('option[data-select2-tag]')
                            $elem.prepend($options)
                       }
                        data.unshift(tag)
                    }

                }
            );

            let s = this.innerValue;

            //console.log('set Index', value, s)

            let val = s ? +s :  null;
            $(domElement).val(val).trigger('change')
        })
    }

    //newTagId = 0

    @Input('taged') taged = ''

    get istaged() {
        return this.taged //this.taged.observers.length > 0
    }

    _options = null
    get options () { return this._options}

    @Input('options') set options(val) {

        this._options = val
        return
        console.log('set options')

        let elem = this.el.nativeElement

        /*
        let checkminlength = (this.options && +this.options.checkminlength) ? true: false
        if (checkminlength) {
            let minlength = (this.options && +this.options.minlength) ? +this.options.minlength : 0
            if (minlength) {
                this.setAttr(elem,'minlength',minlength)
                this.setAttr(elem,'required','required')
            }
        }
        */

        let maxlength = (this.options && +this.options.maxlength) ? +this.options.maxlength : 0
        if (maxlength) {
            this.setAttr(elem,'maxlength',maxlength)
        }

        //this.rows = (this.options && +this.options.rows) ? +this.options.rows : 3
        //this.setOption('rows')

       /* if  (this.options && this.options.number) {
            this.mask = { mask: this.getNumberMask()}
        }
        */

    //    if (this.options && this.options.label) {
    //        this.label = this.options.label
    //    }

        if (this.options && this.options.index) {
            this.index = this.options.index
        }
    }



    @Input('minimumInputLength') minimumInputLength = 0

    get ajaxOption() {

//        let headers= this.authService.httpHeaders()
        let ajax = {

            url: this.getLinkUrl(),

            dataType: 'json',
            headers: this.authService.httpHeaders(), // {'Content-Type': 'application/json', 'X-CSRF-TOKEN': this.authService.token()},

            type: 'GET',

            //allowClear: true,

            //minimumInputLength: this.minimumInputLength,
            delay: 250,

            data: params => {
                //     data: (params) => {


                //  console.log($(this))
                var term = params.term
                if (!term) {
                    let $elem = $(this.el.nativeElement).find("select") // find("select.select4")
               //     let val = $(this).val() // impo
                    let val = $elem.val() // impo
                    if (val && val instanceof String) {
                        term = val.substring(0,3)
                    }
                }

                let ops =
                 {
                    //      term: term,
                    filter: term,
                    page: params.page || 1
                }

                if (this.options) {
                    ops = Object.assign(ops,this.options)
                }
                return ops
            },

            processResults: (data,p) => {

                /*

                var arr = []

                if (this.elemType == 'ICD10') {
                    for (let item of data.data) {
                        arr.push({
                            id: item.code,
                            text: item.name // item.code + "\t" + item.name
                        })
                    }
                }
                else {
                    for (let item of data.data) {
                        arr.push({
                            id: item.id,
                            text: item.name
                        })
                    }

                }
                //params.page = data.current_page || 1;
                return {
                    results: arr,
                    pagination: {
                        page: data.current_page,
                        more:  (data.to < data.total)
                    }

                };
                */

                for (let item of data.data) {

                    item.id = item.id || item.code
                    item.text = item.text || item.name
                }

                return {
                    results: data.data,
                    pagination: {
                        page: data.current_page,
                        more:  (data.to < data.total)
                    }

                };
            },
            //cache: true

        }

        return ajax
    }

    get select2Options() {

        let allowClear = this.clearButton && !this.disabled

        let options :any = {

            placeholder: this.placeholder,

            allowClear: allowClear,
            minimumInputLength: this.minimumInputLength,


            language: {

                loadingMore: function () {
                    return 'More results…';
                },


                searching: (params) => {
                    // console.log(params)
                    this.query = params; // !!!
                    return 'Searching…';
                },

                //noResults: function () {
                //    return 'Not system doctor';
                //},

            },

            /*
            templateResult: (item) => {
                if (item.loading) {
                    return item.text;
                }
                var term = this.query.term || '';
                //console.log(item)
                //var term = 'H04'
                var $result = markMatch(item.text, term);
                return $result;
            },
            */
            tags: this.istaged,

            /*
            createTag: params => {
                var term = $.trim(params.term);

             //   this.authService.showMessage(term)

                if (term === '') {
                    return null;
                }

                this.newTagId--;
                return {
                    id: this.newTagId,
                    text: term,
                    newTag: true // add additional parameters
                }
            }

            */

            insertTag: (data, tag) => {
                /*
                let $elem = $(this.el.nativeElement).find("select.select3")

                if ($elem) {
                    var $options = $elem.find('option[data-select2-tag]')
                    $elem.prepend($options)
                    //console.log($options)

                }
                */
                //                        var $options = this.$element.find('option[data-select2-tag]');

                //    var $options = $(this).find('option[data-select2-tag]');

                // Insert the tag at the end of the results
                //console.log(data)
                data.push(tag);
                //data.unshift(tag)  // default
            }



        }

        if (this._index.length) {
            options.data = this.select2_addTree()
            options.scrollAfterSelect = true
        }
        else {
            options.ajax = this.ajaxOption
            options.scrollAfterSelect = false
        }

        var template = this.selectTemplate[this.elemLink]
        if (!template) {

            template = (item) => {
                if (item.loading) {
                    return item.text;
                }
                var term = this.query.term || '';
                //console.log(item)
                //var term = 'H04'
                // var $result = markMatch(item.text, term);
                return item.text //$result;
            }

        }

        options.templateResult = (item) => {
            let text = template(item)
            // var term = this.query.term || '';
            return text
            // return markMatch(text, term);
        }

        options.templateSelection = (item) => {
            if (this.forbilling) {
                this.itemSelected(item);
                return template(item, true);
            }
            return template(item)
        }
        /*
                    options.templateResult = this.selectTemplate[this.elemLink]
                    if (!options.templateResult) {

                        options.templateResult = (item) => {
                            if (item.loading) {
                                return item.text;
                            }
                            var term = this.query.term || '';
                            //console.log(item)
                            //var term = 'H04'
                            var $result = markMatch(item.text, term);
                            return $result;
                        }

                    }
                    options.templateSelection = options.templateResult
        */

        return options
    }

    getLinkUrl() {
        let p = this.selectMap[this.elemLink]
        if (!p) p = this.elemLink.toLowerCase()
        let url = this.authService.getApiUrl() + '/api/' + p // this.selectMap[this.elemLink]

        return url
    }

    initSelect4() {

    //    console.log('initSlect4')

        $(this.el.nativeElement).find("select.select4").each((ind, domElement) => {

            this.select2_on(domElement)

            $(domElement).select2(this.select2Options)

            $(domElement).find('select2-search__field').each((ind,domField) => {
                $(domField).addClass('.select2-search__add')
            })

            let s = this.innerValue;

            let val = s ? +s : null;
            $(domElement).val(val).trigger('change')
        })

    }


    @ViewChild('add_patient_dialog') add_patient_dialog

    addPatient(term) {

        this.add_patient_dialog.open(()=>{

            let patient = this.add_patient_dialog.item

            if (this.parent.item) {

                this.parent.item.patient = patient;
          }

            let data = {
                id: patient.id,
                text: patient.name // + address
            }

            this.select2_writeValue(data, 'select4')
            this.value = data.id

        });
        return

        /*
        if (!this.$addForm) {
            this.$addForm = $(this.el.nativeElement).find('.select2_patient__form')
            var $widget_body = this.findWidgetBody()

            $widget_body.children('form').css('display','none') //addClass('widget_body__form--closed')

            $widget_body.prepend(this.$addForm)
            this.$addForm.addClass('select2_patient__form--opened')
            if (this.isModal) {
                this.$addForm.addClass('modal-content modal-dialog widget-modal-content')
            }
        }
        */

  }

  /*
    closePatientForm() {

        if (this.$addForm) {

            $(this.el.nativeElement).prepend(this.$addForm)

            this.$addForm.removeClass('select2_patient__form--opened')
            var $widget_body = this.findWidgetBody()
            if (this.isModal) {
                this.$addForm.removeClass('modal-content modal-dialog widget-modal-content')
            }

            //$widget_body.find('form').removeClass('select2_patient__form--opened')

            $widget_body.children('form').first().css('display', 'block') // removeClass('select2_patient__form--opened')

            var $validator = this.$addForm.validate()
            $validator.resetForm();//remove error class on name elements and clear history
            $validator.reset();//remove all error and success data
            $('em', this.$addForm).remove()

            this.$addForm = null
        }
   }

    closeForm() {

        if (this.$addForm) {
            this.$addForm.css('display','none') // removeClass('widget_body__form--opened').addClass('widget_body__form--closed')
            $(this.el.nativeElement).prepend(this.$form)

            var $widget_body = this.findWidgetBody()
            $widget_body.find('form').first().css('display','block') // removeClass('widget_body__form--closed')



        }

    }


    closePatient() {

        console.log('closePatient',this.item)

        this.closePatientForm()
    }


    loading = false
    info = null

    okPatient() {

        let postItem = Object.assign({},this.item)
        let d = postItem.date_of_birth.split('/')
        postItem.date_of_birth = d[2]+'-'+d[1]+'-'+d[0]

        if (+postItem.sex) {
            postItem.sex = +postItem.sex-1
        }

       // console.log('OkPatient',this.item,postItem)

        let url = this.getLinkUrl()

        this.loading = true
        this.info = null

        this.authService.post(url,postItem)
            .pipe(
                finalize(() => {
                    this.loading = false
                }),
            )
            .subscribe(
                result => {
                    //this.value = result.id
                    //this.writeValue(result.id)

                    let data = {
                        id: result.id,
                        text: result.name
                    }

                    this.select2_writeValue(data, 'select4')
                    this.value = result.id

                    this.closePatientForm()
                },
                error => {
                   // this.info = error
                    this.authService.showError(error);
                }
            )


    }
    */

    _data = []

    get data() {
        return this._data
    }

    setTableHeight(value) {
        if (value) {
            let h = this.data  ? this.data.length : 1
            let x = Math.min(value,h)
            this.height =  (x*35+1).toString()+'px'
            if (this.data.length <= x) this.scroll_y = 0
        }
    }

    @Input('data') set data(value) {
        this._data = value
        if (this.elemType == "TABLE") {
            this.setTableHeight(this.scroll_y)
        }
    }

    @Input('scrollHeight') set scrollHeight(value) {
        this.scroll_y = value
        this.setTableHeight(value)
    }


    get hint() {
        return (this.options && this.options.hint) ? this.options.hint : ''
    }

    getNumberMask() { //https://github.com/text-mask/text-mask/tree/master/addons/#readme

        let maskOpts :any = { prefix: '', allowNegative: true, suffix: '' };

        let unit= this.unit // this.options && this.options.unit ? this.options.unit: null
        if (unit) { maskOpts.suffix = ' ' + unit;}


        let precision= this.precision// this.options && this.options.precision ? this.options.precision: null
        if (+precision) { maskOpts.allowDecimal = true; maskOpts.decimalLimit = +precision }

        maskOpts.includeThousandsSeparator = true;

        let m = createNumberMask(maskOpts);
       // console.log(this.precision, this.unit,m);
        return {mask: m}
    }

    get innerLabel() {
        return this.label // return this.label.replace(/\n/g , '<br>');
    }


    set clear(value) {
        if (this.parent)  this.parent.clear = value
    }

    // attributes ......................................................................................................

    @Input('label') label = ""
    @Input('placeholder') placeholder = " "
    @Input('rows') rows = 3
    @Input('checkminlength') checkminlength
    @Input('minlength') minlength

    @Input('number') number = false

    _mask:any= {mask: false, guide: false}
    @Input('mask') set mask(val) { //: any = {mask: false, guide: false}
        this._mask = val
    }
    get mask() {
        if (this.number) {
            if (!this.numberMask) {
                this.numberMask = this.getNumberMask()
            }
            return this.numberMask
        }
        return this._mask
    }


    numberMask = null
    _precision = 0
    @Input('precision') set precision(val) {
        if (val!=this._precision) {
            this._precision = val
            this.numberMask = this.getNumberMask()
        }
    }
    get precision() { return this._precision}

    _unit = ''
    @Input('unit') set unit(val) {
        if (val!=this._unit) {
            this._unit = val
            this.numberMask = this.getNumberMask()
        }
    }
    get unit() { return this._unit}


    get attr_placeholder() {
        return this.placeholder ? this.placeholder : undefined
    }
    get attr_minlength() {
        return (this.checkminlength && +this.minlength) ? +this.minlength : undefined
    }

    get label_required() { //{'label-required':label_required}

        let s = this.labelClass//  "col-sm-"+this.bs_cols
        //let s =''
        if (this.pre_required || this.required) {
            return s + " label-required"
        }
        return s
    }

    get attr_required() {
        return (this.pre_required || this.required) ? '': undefined
    }

    focused = false

    focus(val) {
        this.focused = val
    }

    input_focus($event) {
        if (this.disabled) return;

        $event.stopPropagation();
        $event.preventDefault()
       // if (this.focused) return;

       // console.log('click')
        let $input = $(this.el.nativeElement).find('input') //.select().focus()
        let focused = $input.is(':focus')
        //console.log('focused',focused)

        if (focused) {
           // console.log('focus')
            $input.blur() // blur()
            //$input.datepicker( "hide" )
       }
        else {
            if ($input.val()) {
              //  $input.select().focus()
                $input.focus()
            }
            else {
                $input.focus()

            }

        }
        //let focused1 = $input.is(':focus')
       // console.log('post focused',focused1)

    }


    datepicker_icon($event) {
        if (this.disabled) return;

        $event.stopPropagation();
        $event.preventDefault()

        let $input = $(this.el.nativeElement).find('input') //.select().focus()
        let focused = $input.is(':focus')
      //  console.log('focused',focused)

        if (focused) {
            $input.blur() // blur()
            $input.datepicker( "hide" )
       }
        else {
            if ($input.val()) {
                //  $input.select().focus()
                $input.focus()
            }
            else {
                $input.focus()

            }

        }
       // let focused1 = $input.is(':focus')
       // console.log('post focused',focused1)

    }




    @Input('fixed') fixed = false

    selected2($event) {
        this.selected.emit($event)
    }

    itemSelected(event) {
        this.chosen.emit(event)
    }


    // [(ngTag)]

    innerTag = ''

    @Input()
    get tag() {
        return this.innerTag;
    }
    @Output() tagChange = new EventEmitter();
    @Output('chosen') public chosen: EventEmitter<any> = new EventEmitter();
    // @Output() select = new EventEmitter();
    set tag(val) {
        if (this.innerTag != val) {
            this.innerTag = val;
            this.tagChange.emit(this.innerTag);
        }
    }

    @Input('required_values') required_values: string = ''

    @Input('openChart') openChart = false


    @Input('item-width') item_width = ''
    get item_style() {
        if (this.item_width) {
            return { 'width': this.item_width}
        }
        return null
    }

    periodTitle() {
        if (this.parent) {
            let s = this.parent.compPeriodStr()
            return s ? s : '-- Select --'
        }
        return ''
    }

    periodClick() {
        this.parent.selectPeriod()
    }

}
