/**
 * Created by Alexander Spazhev on 31.10.2016.
 */

import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {ParsleyForm} from './parsley-form'
import {BaseService} from './base.service';
import {ElementRef} from "@angular/core";
import {environment} from "../../environments/environment";

export class BaseDetail extends ParsleyForm {

    /* protected */ item:any = {};
    protected error: any = '';
     isLoaded: boolean = false;
    public paramItems: any = {};

    private _busy: boolean = false;


    protected savedItem: string = ''; // any = {};
    protected noChanges: boolean = false;

    constructor(protected el:ElementRef,protected route: ActivatedRoute,protected baseService: BaseService) {

        super();

        this.loadKeys()
//        this.savedItem = this.itemToSaveStr();
    }


    canDeactivate() {
        return true;
    }

    get busy():boolean {
        return this._busy;
    }

    set busy(val:boolean) {
        this._busy = val;
    }

    lookupName(arr,id) {
        return this.baseService.lookupName(arr,id);
    }

    backLink() {
        //if (this.item.id){ return ['../', {id: this.item.id}] }
        return ['../'];
    }

    onBack() {
        this.baseService.authService.router.navigate(this.backLink(), {relativeTo: this.route});
/*
        if (this.item.id) {
            this.baseService.authService.router.navigate(['../', {id: this.item.id}], {relativeTo: this.route});
        }
        else {
            this.baseService.authService.router.navigate(['../'], {relativeTo: this.route});
        }*/
    }

    handleRead(result) {
        this.onReadItem(result);
        this.item = result;
        if (!this.item.id) { this.selectTab(0)}
    }

//---------------------------------------------------------------------------------------------------------

    isadmin():boolean {
        return this.baseService.authService.isadmin();
    }

//-----------------------------------------------------------------------------------------------------------

    equal(a,b) {
        if (a) {
            if (b) {
                //++
                if (a instanceof Object) {
                    if (b instanceof Object) {
                        
                        return JSON.stringify(a) == JSON.stringify(b);

                    }
                    return false;
                }
                //--
                return (a==b);
            }
            return false;
        }

        if (b) return false;
        return true;
    }


    public hasChanges() {

        if (this.noChanges) return false;

        let changedItem = this.itemToSaveStr();

            //console.log('==?',changedItem,this.savedItem);

        return changedItem != this.savedItem;

/*
        let changedItem = this.itemToSave();

        for (let key in changedItem) {
            if (key=='updated_at') continue;

            if (!this.equal(this.savedItem[key],changedItem[key])) {

                //console.log(key);
                //console.log(this.savedItem[key]);
                //console.log(changedItem[key]);

                return true;
            }
        }

        for (let key in this.savedItem) {
            if (key=='updated_at') continue;

            if (!this.equal(this.savedItem[key],changedItem[key])) {

                return true;
            }
        }

        return false;
*/   }


    itemToSave() {
        let save_item = {};
        for (let key in this.item) {  //.filter
            if (key=='updated_at') continue;
            if (!key.startsWith('_')) {
                save_item[key] = this.item[key];
            }
        }
        this.onWriteItem(save_item);

        return save_item;
    }

    itemToSaveStr() {
        return JSON.stringify(this.itemToSave());
    }


    onDelete() {
        if (this.busy) return;
        this.busy = true;
        if (this.item.id){
            this.baseService.delete(this.item.id)
                .subscribe(
                result => { this.busy = false; this.item = {}; this.onBack()},
                error =>  { this.busy = false; this.error = error});

        }
    }

    detailId() {
        return this.route.snapshot.params['id'];
    }

    getDetail() {

        let id = this.detailId();
        if (!isNaN(id) && (id >= 0)) {
            this.baseService.getItem(id,this.paramItems)
                .subscribe(
                    result => {

                        this.handleRead(result);
                        this.savedItem = this.itemToSaveStr();
                        this.isLoaded = true;
                        //console.log(result);
                    },

                    error =>  this.error = error)
        }
        else
          {this.isLoaded = true};

    }


    ngOnInit():void {

        super.ngOnInit();

        this.getDetail();
   }

    onReadItem(result) { }

    onItemLoaded() {}

    onWriteItem(result) {}

    //---------------------------------------------------------------------------------------------------------

    onSubmitAndBack() {
        if (this.isValid()) this.onSave();
    }

    //----------------------------------------------------------------------------------------------------------


    back() {
        this.baseService.authService.back();
    }

    saveAndBack() {

        if (!this.isValid()) return;

        if (this.busy) return;
        this.busy = true;

        let save_item = this.itemToSave();

        this.baseService.post(save_item)
            .subscribe(
                result => {
                    this.busy = false;
                    this.noChanges = true;
                    this.back()
                },
                error =>  { this.busy = false; this.baseService.authService.showError(error); this.error = error}
        );

    }

    onSave(){
        if (this.busy) return;
        this.busy = true;

        let save_item = this.itemToSave();

        this.baseService.post(save_item)
            .subscribe(
                result => {
                    this.busy = false;
                    this.handleRead(result);
                    this.savedItem = this.itemToSaveStr();
                    this.onBack()},
                error =>  { this.busy = false; this.baseService.authService.showError(error); this.error = error}
        );
    }


    saveItem(){
        if (this.busy) return;
        this.busy = true;

        let save_item = this.itemToSave();

        this.baseService.post(save_item)
            .subscribe(
                result => {

                this.busy = false;
                this.handleRead(result);
                this.savedItem = this.itemToSaveStr();
                this.isLoaded = true;
                this.onItemLoaded();
            },
                error =>  { this.busy = false; this.baseService.authService.showError(error); this.error = error}
        );
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////

    tab = 0;

    selectTab(tab) {
        this.tab = tab;
        //  console.log('tab click',tab)
    }


   loadKeys() {
        let locStorage = localStorage.getItem(this.storageKey());
        if (locStorage) {
            let loc = JSON.parse(locStorage);
            if (!environment.STATELESS_APPLICATION) {
                if (loc.tab) this.tab = +loc.tab;
            }
        }
    }

    saveKeys() {
        let loc: any = {};
        loc.tab = this.tab;

        if (loc && !environment.STATELESS_APPLICATION) {
            localStorage.setItem(this.storageKey(), JSON.stringify(loc));
        }
        else {
            localStorage.removeItem(this.storageKey());
        }
    }


    ngOnDestroy() {
        this.saveKeys();
    }


   storageKey() {
        return this.widgetName;
    }

    get tagName () {
        return this.widgetName;
    }

    get widgetName() {
        return this.el.nativeElement.tagName.toLowerCase();

        //return this.baseService.widgetName('list');
    }


}