/**
 * Created by griga on 7/11/16.
 */


import {Routes, RouterModule} from '@angular/router';
import {MainLayoutComponent} from "./shared/layout/app-layouts/main-layout.component";
// import {AuthLayoutComponent} from "./shared/layout/app-layouts/auth-layout.component";
import {ModuleWithProviders} from "@angular/core";

import {AuthGuard,SaveGuard}      from "./auth/auth.module"

/*
{ path: '', redirectTo: 'app', pathMatch: 'full' },
{ path: 'app', loadChildren: () => System.import('./layout/layout.module'), canActivate: [AuthGuard] , canActivateChild:[AuthGuard] },

{ path: 'account', loadChildren: () => System.import('./account/account.module') },
*/

import {ShippingDetail} from "./shipping/detail/shipping-detail.component";
import {ShippingList} from "./shipping/list/shipping-list.component";
import {SubscriptionList} from "./subscription/list/subscription-list.component";
import {SubscriptionDetail} from "./subscription/detail/subscription-detail.component";
import {PaymentDetail} from "./payment/detail/payment-detail.component";
import {PaymentList} from "./payment/list/payment-list.component";

import {SubscriptionAdd} from "./subscription/add/subscription-add.component";
import {InvoiceList} from "./invoice/list/invoice-list.component";
import {InvoiceDetail} from "./invoice/detail/invoice-detail.component"

import {InvoiceItemList} from "./invoice-item/list/invoice-item-list.component";
import {InvoiceItemDetail} from "./invoice-item/detail/invoice-item-detail.component"

import {SettingDetail} from "./setting/detail/setting-detail.component"

// import {ProductList} from "./product/list/product-list.component";
// import {ProductDetail} from "./product/detail/product-detail.component"


import {ChangePasswordComponent} from "./auth/change-password/change-password.component";

import {MailboxList} from "./mailbox/list/mailbox-list.component"
import {MailboxDetail} from "./mailbox/detail/mailbox-detail.component"
import {AdminGuard} from "./auth/admin-guard.service";
import {WarningList} from "./warning/list/warning-list.component";
import {WarningDetail} from "./warning/detail/warning-detail.component";
import {ProductUserDetail} from "./product-user/detail/product-user-detail.component";

//import {Post} from "./post/post/post.component"

import {UserMessage} from "./message/user-message/user-message.component"

////////////////////////////////////////////////////////////////////////////////////////////////////

//import {DiagnosisList} from "./diagnosis/list/diagnosis-list.component"
//import {DiagnosisDetail} from "./diagnosis/detail/diagnosis-detail.component"

//import {AddressList,AddressDetail} from "./address/address.module"

import {AddressList} from "./address/list/address-list.component"
import {AddressDetail} from "./address/detail/address-detail.component";


//import {UserList,UserDetail,UserProfile} from "./user/user.module"


//import {Dashboard}  from "./dashboard/dashboard.module"

import {Dashboard} from "./dashboard/dashboard.component"

//import {SignupComponent} from "./signup/signup.module"
import {SignupComponent} from "./signup/signup.component";

export const routes: Routes = [

//  { path: '', redirectTo: 'app', pathMatch: 'full' },

  {
    path: '',
    component: MainLayoutComponent,  canActivate: [AuthGuard] , canActivateChild:[AuthGuard],
    data: {pageTitle: 'Home'},
    children: [

      { path: '', component: Dashboard , pathMatch: 'full' },

      /*
      {
        path: 'smartadmin',
        loadChildren: 'app/+smartadmin-intel/smartadmin-intel.module#SmartadminIntelModule',
        data: {pageTitle: 'Smartadmin'}
      },


      {
        path: 'app-views',
        loadChildren: 'app/+app-views/app-views.module#AppViewsModule', // canActivate: [AuthGuard] , canActivateChild:[AuthGuard],

      data: {pageTitle: 'App Views'}
      },


      {
        path: 'calendar',
        loadChildren: 'app/+calendar/calendar.module#CalendarModule'
      },
      {
        path: 'e-commerce',
        loadChildren: 'app/+e-commerce/e-commerce.module#ECommerceModule',
        data: {pageTitle: 'E-commerce'}
      },
      {
        path: 'forms',
        loadChildren: 'app/+forms/forms-showcase.module#FormsShowcaseModule',
        data: {pageTitle: 'Forms'}
      },
      {
        path: 'graphs',
        loadChildren: 'app/+graphs/graphs-showcase.module#GraphsShowcaseModule',
        data: {pageTitle: 'Graphs'}
      },
      {
        path: 'maps',
        loadChildren: 'app/+maps/maps.module#MapsModule',
        data: {pageTitle: 'Maps'}
      },

      {
        path: 'outlook',
        loadChildren: 'app/+outlook/outlook.module#OutlookModule',
        data: {pageTitle: 'Outlook'}
      },
      {
        path: 'tables',
        loadChildren: 'app/+tables/tables.module#TablesModule',
        data: {pageTitle: 'Tables'}
      },
      {
        path: 'ui',
        loadChildren: 'app/+ui-elements/ui-elements.module#UiElementsModule',
        data: {pageTitle: 'Ui'}
      },
      {
        path: 'widgets',
        loadChildren: 'app/+widgets/widgets-showcase.module#WidgetsShowcaseModule',
        data: {pageTitle: 'Widgets'}
      },
      */


      {
        path: 'miscellaneous',
        loadChildren: 'app/+miscellaneous/miscellaneous.module#MiscellaneousModule',
        data: {pageTitle: 'Miscellaneous'}
      },

      {
        path: 'user',
        loadChildren: 'app/user/user.routing#UserRouting',
        /*
        children: [

          { path: '', component: UserList , pathMatch: 'full',}, // canActivate: [AdminGuard]  },
          { path: 'profile', component: UserProfile, canDeactivate: [SaveGuard]},
          { path: ':id', component: UserDetail, canActivate: [AdminGuard] ,  canDeactivate: [SaveGuard],
            // resolve: {item: UserDetailResolve}
          },
        ],
        */
        data: {pageTitle: 'Clinicians'}
      },

      {
        path: 'task',
    //      loadChildren: 'app/task/task.routing#TaskRouting',
          loadChildren: 'app/task/task.module#TaskModule',
          data: {pageTitle: 'Tasks'}
      },

      {
        path: 'todo',
     //     loadChildren: 'app/task-todo/task-todo.routing#TaskTodoRouting',
          loadChildren: 'app/task-todo/task-todo.module#TaskTodoModule',
        data: {pageTitle: 'ToDo list'}
      },


      {
        path: 'activity',
        loadChildren: 'app/activity/activity.module#ActivityModule',
        data: {pageTitle: 'Current requests'}
      },

        {
            path: 'throttling',
            loadChildren: 'app/throttling/throttling.module#ThrottlingModule',
            data: {pageTitle: 'Throttling'}
        },

        {
        path: 'subscription',
    //    loadChildren: 'app/subscription/subscription.module#SubscriptionModule',

        children: [
      { path: '', component: SubscriptionList , pathMatch: 'full' },
          { path: 'add/:id', pathMatch: 'full' , component: SubscriptionAdd,/* canDeactivate: [SaveGuard] */},

      { path: ':id', component: SubscriptionDetail, canDeactivate: [SaveGuard]}
        ],
        data: {pageTitle: 'Subscriptions'}
      },

      {
        path: 'subscription_plan',
        loadChildren: 'app/subscription-type/subscription-type.module#SubscriptionTypeModule',
        data: {pageTitle: 'Subscription Plans'}
      },

      {
        path: 'product',
        loadChildren: 'app/product/product.module#ProductModule',
        data: {pageTitle: 'Products'}
      },

      {
        path: 'market',
        loadChildren: 'app/market/market.module#MarketModule',
        data: {pageTitle: 'Markets'}
      },

      ////////////////////////////////////////////////////////////////////////////////////////////

      {
        path: 'blog_category',
        loadChildren: 'app/blog-category/blog-category.module#BlogCategoryModule',
        data: {pageTitle: 'Training area categories'}
      },

      {
        path: 'blog',
        loadChildren: 'app/blog/blog.module#BlogModule',
        data: {pageTitle: 'Training area articles'}
      },

      {
        path: 'training',
        loadChildren: 'app/training/training.module#TrainingModule',
        data: {pageTitle: 'Training area'}
      },

      {
        path: 'task_type',
        loadChildren: 'app/task-type/task-type.module#TaskTypeModule',
        data: {pageTitle: 'Task types'}
      },

      {
        path: 'task_state',
        loadChildren: 'app/task-state/task-state.module#TaskStateModule',
        data: {pageTitle: 'Task states'}
      },


      {
        path: 'task_type_role',
        loadChildren: 'app/task-type-role/task-type-role.module#TaskTypeRoleModule',
        data: {pageTitle: 'Task type roles'}
      },

      /*
      {
        path: 'post',
        loadChildren: 'app/post/post.module#PostModule',
        data: {pageTitle: 'Community forum'}
      },
      */

      {
        path: 'post_section',
        loadChildren: 'app/post/post-section.routing#PostSectionRouting',
 //       children: [
  //        { path: '', component: Post }
  //      ],
        data: {pageTitle: 'Community forum'}
      },

      {
        path: 'post',
        loadChildren: 'app/post/post.routing#PostRouting',
        //       children: [
        //        { path: '', component: Post }
        //      ],
        data: {pageTitle: 'Community forum'}
      },

      {
        path: 'post_thread',
        loadChildren: 'app/post/post-thread.routing#PostThreadRouting',
        data: {pageTitle: 'Forum topics'}
      },

      /*
    {
      path: 'message',
      loadChildren: 'app/message/message.module#MessageModule',
      data: {pageTitle: 'Messages'}
    },
    */
      {
        path: 'message',
        children: [
          { path: '', component: UserMessage }
        ],
        data: {pageTitle: 'Messages'}
      },

      {
        path: 'rstore',
        loadChildren: 'app/rstore/rstore.module#RStoreModule',
        data: {pageTitle: 'Templates'}
      },

      {
          path: 'routine',
          loadChildren: 'app/routine/routine.module#RoutineModule',
          data: {pageTitle: 'Routines'}
      },


        {
        path: 'chart_template',
        loadChildren: 'app/chart-template/chart-template.routing#ChartTemplateRouting',
        data: {pageTitle: 'My templates'}
      },

      {
        path: 'chart',
        loadChildren: 'app/chart/chart.routing#ChartRouting',
        data: {pageTitle: 'My charts'}
      },


      {
        path: 'addressbook',
        children: [
          { path: '', component: AddressList },
          { path: ':id', component: AddressDetail, canDeactivate: [SaveGuard] }
        ],

        //loadChildren: 'app/address/address.module#AddressModule',
        data: {pageTitle: 'Health records'}
      },

      {
        path: 'diagnosis',
        loadChildren: 'app/diagnosis/diagnosis.routing#DiagnosisRouting',
/*
        children: [
          { path: '', component: DiagnosisList, pathMatch: 'full' },
          { path: ':id', component: DiagnosisDetail, canDeactivate: [SaveGuard] }
        ],
        */
        data: {pageTitle: 'Diagnoses'}
      },

      {
        path: 'professional',
        loadChildren: 'app/professional/professional.routing#ProfessionalRouting',
        data: {pageTitle: 'Clinicians'}
      },

        {
            path: 'active_client',
            loadChildren: 'app/professional/professional.routing#ProfessionalRouting',
            data: {pageTitle: 'Active clients'}
        },



        {
            path: 'trusted_user',
            loadChildren: 'app/trusted-user/trusted-user.routing#TrustedUserRouting',
            data: {pageTitle: 'Trusted clinicians'}
        },

        //////////////////////////////////////////////////////////////////////////////////////////


      {
        path: 'market_product',
        loadChildren: 'app/market-product/market-product.module#MarketProductModule',
        data: {pageTitle: "Market's products"}
      },

        {
            path: 'tag',
            loadChildren: 'app/tag/tag.module#TagModule',
            data: {pageTitle: 'Tags'}
        },


      {
        path: 'report_credit',
        loadChildren: 'app/report-credit/report-credit.module#ReportCreditModule',
        data: {pageTitle: 'Credits'}
      },

        {
            path: 'report_registered_users',
            loadChildren: 'app/report-registered-users/report-registered-users.module#ReportRegisteredUsersModule',
            data: {pageTitle: 'User registration'}
        },


      {
        path: 'report_control',
        loadChildren: 'app/report-control/report-control.module#ReportControlModule',
        data: {pageTitle: 'Control cafe'}
      },

      {
            path: 'sales',
            loadChildren: 'app/sales/sales.module#SalesModule',
            data: {pageTitle: 'Sales'}
        },


        {
        path: 'report_whois_balance',
        loadChildren: 'app/report-whois-balance/report-whois-balance.module#ReportWhoisBalanceModule',
        data: {pageTitle: 'WHOIS API Balance'}
      },

      /*{
        path: 'product',
        children: [
          { path: '', component: ProductList },
          { path: ':id', component: ProductDetail, canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'Products'}
      },*/

      {
        path: 'shipping',
        //loadChildren: 'app/shipping/shipping.module#ShippingModule',
        children: [
          { path: '', component: ShippingList },
          { path: ':id', component: ShippingDetail, canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'Credits stats'}
      },

      {
        path: 'product_user',
        //loadChildren: 'app/shipping/shipping.module#ShippingModule',
        children: [
         // { path: '', component: ShippingList },
          { path: ':id', component: ProductUserDetail, canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'User products'}
      },


      {
        path: 'payment',
        //loadChildren: 'app/payment/payment.module#PaymentModule',
        data: {pageTitle: "Payments"},
        children: [
          { path: '', component: PaymentList },
          { path: ':id', component: PaymentDetail, canDeactivate: [SaveGuard] }
        ],
      },

      {
        path: 'invoice',
        children: [
          { path: '', component: InvoiceList },
          { path: ':id', component: InvoiceDetail, canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'Invoices'}
      },


      {
        path: 'invoice-item',
        children: [
          { path: '', component: InvoiceItemList },
          { path: ':id', component: InvoiceItemDetail, canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'Invoice Lines'}
      },

        {
            path: 'warning',
            children: [
                { path: '', component: WarningList },
                { path: ':id', component: WarningDetail, canDeactivate: [SaveGuard] }
            ],
            data: {pageTitle: 'Notifications'}
        },


        {path: 'setting',
        children: [
          { path: '', redirectTo: 'profile',pathMatch: 'full' },
          { path: ':id', component: SettingDetail, canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'Settings'}
      },

      {path: 'mailbox',
        children: [
          { path: '', component: MailboxList, canActivate:[AdminGuard] },
          { path: ':id', component: MailboxDetail, canActivate:[AdminGuard] , canDeactivate: [SaveGuard] }
        ],
        data: {pageTitle: 'Mailboxes'}
      },



    { path: 'changepassword', component: ChangePasswordComponent, canDeactivate:[SaveGuard],
         data: {pageTitle:'Change password'}},

      { path: 'profile', redirectTo: '/user/profile', canDeactivate:[SaveGuard],
        data: {pageTitle:'My profile'}},


        {
            path: 'ah',
            loadChildren: 'app/available-hours/available-hours.module#AvailableHoursModule',
            data: {pageTitle: 'Available hours'}
        },

        {
            path: 'dp',
            loadChildren: 'app/dp-calendar/calendar.module#CalendarModule',
            data: {pageTitle: 'Calendar'}
        },

        {
            path: 'sc',
            loadChildren: 'app/scheduler/scheduler.module#SchedulerModule',
            data: {pageTitle: 'Scheduler'}
        },

        //  +7 -----------------------------------------------------------------
        {
            path: 'clinic',
            loadChildren: 'app/clinic/clinic.module#ClinicModule',
            data: {pageTitle: 'Clinic'}
        },

        {
          path: 'theme',
          loadChildren: 'app/theme/theme.module#ThemeModule',
          data: {pageTitle: 'Theme'}
        },

        {
            path: 'session_type',
            loadChildren: 'app/session-type/session-type.module#SessionTypeModule',
            data: {pageTitle: 'Session types'}
        },

        {
            path: 'session_cancellation_reason',
            loadChildren: 'app/session-cancellation-reason/session-cancellation-reason.module#SessionCancellationReasonModule',
            data: {pageTitle: 'Session cancellation reasons'}
        },

        {
            path: 'session_status',
            loadChildren: 'app/session-status/session-status.module#SessionStatusModule',
            data: {pageTitle: 'Session statuses'}
        },

        {
            path: 'calendar',
            loadChildren: 'app/availability/availability.module#AvailabilityModule',
            data: {pageTitle: 'Calendar'}
        },

        {
            path: 'work_day',
            loadChildren: 'app/work-day/work-day.module#WorkDayModule',
            data: {pageTitle: 'Work days'}
        },


        /*
                {
                    path: 'availability',
                    loadChildren: 'app/availability/availability.module#AvailabilityModule',
                    data: {pageTitle: 'Available hours'}
                },

                {
                    path: 'appointment',
                    loadChildren: 'app/appointment/appointment.module#AppointmentModule',
                    data: {pageTitle: 'Appointments'}
                },
        */
        {
            path: 'r_d1',
            loadChildren: 'app/r-d1/r-d1.module#RD1Module',
            data: {pageTitle: 'Age report'}
        },

        {
            path: 'r_d2',
            loadChildren: 'app/r-d2/r-d2.module#RD2Module',
            data: {pageTitle: 'Diagnosis report'}
        },

        {
            path: 'r_d3',
            loadChildren: 'app/r-d3/r-d3.module#RD3Module',
            data: {pageTitle: 'Location report'}
        },

        {
            path: 'r_p1',
            loadChildren: 'app/r-p1/r-p1.module#RP1Module',
            data: {pageTitle: 'Clinic activity'}   // Chart Activity
        },

        {
            path: 'r_p2',
            loadChildren: 'app/r-p2/r-p2.module#RP2Module',
            data: {pageTitle: 'Appointment activity'}
        },

        {
            path: 'r_p3',
            loadChildren: 'app/r-p3/r-p3.module#RP3Module',
            data: {pageTitle: 'Clinic totals'}
        },

        {
            path: 'r_a1',
            loadChildren: 'app/r-a1/r-a1.module#RA1Module',
            data: {pageTitle: 'New clients'} // 'New Patients'}
        },

        {
            path: 'r_a6',
            loadChildren: 'app/r-a6/r-a6.module#RA6Module',
            data: {pageTitle: 'Active clients'} // 'On Active Caseload'}
        },

        {
            path: 'r_a4',
            loadChildren: 'app/r-a4/r-a4.module#RA4Module',
            data: {pageTitle: 'Inactive charts'}
        },

        {
            path: 'r_a5',
            loadChildren: 'app/r-a5/r-a5.module#RA5Module',
            data: {pageTitle: 'Discharges'} // Inactive Charts'}
        },
        ///////////////////////////// For the new Invoicing Api - Billing ans services section
        {
          path: 'billing',
          loadChildren: 'app/billing/billing.module#BillingModule',
          data: {pageTitle: 'Billing'}
        },

    ]
  },

//  {path: 'auth', component: AuthLayoutComponent, loadChildren: 'app/+auth/auth.module#AuthModule'},

  { path: 'register', component: SignupComponent, pathMatch: 'full'},


  {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule' },


  {path: '**', redirectTo: 'miscellaneous/error404'} // Error component !!!

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});
