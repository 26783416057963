import { Component, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BaseList}       from '../../auth/base-list'
import {BillingServiceService, ClinicService}   from "../billing-service.service";
import {Router} from '@angular/router';
import { runInThisContext } from 'vm';
import { ECONNRESET } from 'constants';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServicesComponent extends BaseList{
  
  public selectedItem = null;
  public clinicDetails = undefined;

  constructor(el: ElementRef,protected route: ActivatedRoute,private billingService: BillingServiceService, public router:Router, private clinicService: ClinicService) {
    super(el, billingService);
    this.serviceItems['page'] = 1
    if (this.clinicService.clinicDetails != undefined) {
      this.clinicDetails = this.clinicService.clinicDetails
      this.paramItems['clinic_id'] = this.clinicDetails.id
      this.doGetList();
      this.clinicService.authService.spinnerStop()
    } else {
      this.clinicService.loadClinicDetails(
        () => {
        this.clinicDetails = this.clinicService.clinicDetails
        this.paramItems['clinic_id'] = this.clinicDetails.id
        this.doGetList();
        this.clinicService.authService.spinnerStop()
        }
      )
    }
  }

  @ViewChild('serviceModal') serviceModal;

  editService(item: any) {
    this.serviceModal.item = item;
    this.serviceModal.add_service = false;
    this.serviceModal.populateFormValues();
    this.addServices()
  }

  serviceModalOK(event: any) {
   if (this.serviceModal.add_service) {
    event.clinicId = this.clinicDetails.id + "";
    let param = {
      clinicId: event.clinicId,
      currencyFormat: event.currencyFormat,
      description: event.description,
      price: event.price,
      priceType: event.priceType,
      serviceName: event.serviceName,
      tax: event.tax
    }  
    this.billingService.post(param).subscribe(success => {
      this.refresh()
    }, error => 
      this.clinicService.showError("error in creating the service, please try again")
     )
   } else {
     this.billingService.put(event, event.id).subscribe(success => {
       this.refresh()
     }, error => this.clinicService.showError("error in modifying the service, please try again")
     )
   }
  }

  addServices() {
    this.serviceModal.show();
  }

  refresh() {
    // this.serviceItems['page'] = 1
    // this.serviceItems['per_page'] = 50;
    this.doGetList()
  }

  deleteService(event: any, id: number) {
    this.deleteItem(id)
  }
}
