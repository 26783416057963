import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SaveGuard} from '../auth/save-guard.service';


import {AuthModule} from "../auth/auth.module";
// SP++ import {ModalComponent } from '../auth/modal-window/modal.component';

import { PaginationModule  } from 'ngx-bootstrap';


//import { ModalModule }             from 'ng2-modal';
//import { TypeaheadModule }         from "ngx-bootstrap" //from 'ng2-bootstrap/ng2-bootstrap';

//import {EhisControlModule}  from '../ehis-control/ehis-control.module';

import { ButtonsModule, BsDropdownModule } from 'ngx-bootstrap'; //'ng2-bootstrap/ng2-bootstrap';  !!!!!!!!



export const routes = [
 //   { path: '', component: ProductUserTable, pathMatch: 'full', },
//    { path: 'build', component: ReportCreditDetail , canDeactivate: [SaveGuard], },
];

import {SmartadminModule} from '../shared/smartadmin.module'
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {ControlModule} from "../controls/controls.module";
import {ProductUserService} from "./product-user.service";
import {ProductUserTable} from "./table/product-user-table.component";
import {ProductUserDetail} from "./detail/product-user-detail.component";
import {ProductUserWarningsComponent} from "./detail/product-user-options.component";
import {CurrencyMaskModule} from "ng2-currency-mask";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
//        ModalModule,
        AuthModule,
        PaginationModule.forRoot(),

        ButtonsModule,

        RouterModule.forChild(routes), // !!!

        SmartadminModule,
        SmartadminInputModule,

        ControlModule,
        CurrencyMaskModule
   ],


    declarations: [
        ProductUserWarningsComponent,
        ProductUserTable,
        ProductUserDetail,
   ],

    exports: [
        ProductUserTable,
        ProductUserDetail
    ],
    providers: [
        SaveGuard,
        ProductUserService,
    ]

})
export class ProductUserModule {
}

