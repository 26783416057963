import {Component, ViewEncapsulation, ViewChild, Input,ViewContainerRef, ElementRef} from '@angular/core';

import {WidgetBase} from "../widget-base";
import {AuthService} from "../../auth/auth.service";

import * as moment from 'moment';

@Component({
    selector: 'widget-pivot',
    templateUrl: './widget-pivot.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class WidgetPivot extends WidgetBase {

    @Input('backButton') backButton = false;

    constructor(protected el:ElementRef, protected viewContainerRef: ViewContainerRef,public  authService:AuthService){
        super(el,viewContainerRef)
    }

    /*
    get selector() {
        return this.el.nativeElement.tagName.toLowerCase();
    }
   */



    /*    get pivot() {
            return this.parent ? this.parent.pivot : null
        }

        get sums() {
            return this.parent ? this.parent.sums : [];
        }

        get pivotcols() {
            return this.parent ? this.parent.pivotcols : [];

        }

        get cols_dim() {
            return this.parent ? this.parent.cols_dim : ''
        }

        get rows_dim() {
            return this.parent ? this.parent.rows_dim : ''
        }

        dict(item) {  // base-list override
            return this.parent ? this.parent.dict(item) : null
        }

        get rows() {
            return this.parent ? this.parent.rows : [];

        }

        get cols() {
            return this.parent ? this.parent.cols : [];
        }


        pivotValue(rid, cid, vid) {
            return this.parent ? this.parent.pivotValue(rid, cid, vid) : '';
        }
    */

    auth_mailbox_id = 0;

    display: boolean = false;

 //   selectedPivot: any = {}
/*
    dimensions = []
    measures = []
    selectedCols = []
    selectedRows = []
    selectedMess = []
*/

    transformArrayToList(arr) {
        let res = []
        if (arr) {
            for (let elem of arr) {
                res = [...res, {label: this.dict(elem), value: elem}]
            }
        }
        return res;
    }

    selectPivot() {

            this.selectedDimensions = this.transformArrayToList(this.dimensions)
            this.selectedMeasures = this.transformArrayToList(this.measures)

            this.selectedCols = this.col //  : [] // this.transformArrayToList(this.parent.pivot.col).slice(0,1)
            this.selectedRows = this.row
            this.selectedMess = this.sums

//        Object.assign(this.selectedPivot,this.parent.pivot)

        this.display = true;
    }

    private selectArr(arr, controArr) {
        this[arr] = this[arr].slice(-1)
        if (this[arr].length > 0) {
            let idx = this[controArr].indexOf(this[arr][0])
            if (idx => 0) {
                delete this[controArr][idx]
            }
        }
    }

    onChangeCol($event) {
        this.selectArr('selectedCols', 'selectedRows')
    }

    onChangeRow($event) {
        this.selectArr('selectedRows', 'selectedCols')
    }

    onChangeMes($event) {

    }

    selectPivotOk() {

        this.col = this.selectedCols;
        this.row = this.selectedRows;
        this.sums = this.measures.filter(a=> this.selectedMess.includes(a)); // order!!!

        let locData = {
            col: this.col,
            row: this.row,
            sums: this.sums
        }
        let loc = JSON.stringify(locData)
        localStorage.setItem(this.storageKey,loc)

        this.display = false
        this.updatePivot()
    }

    //-------------------------------------------------------------------

    _values = {}
    _values_c = {}
    _values_r = {}


    makePivotDict() {

        let data= this.parent.data

        let mes = this.sums // parent.pivot.mes

        let row_fld = this.row.length > 0 ? this.row[0] : ''
        let col_fld = this.col.length > 0 ? this.col[0] : ''


        let piv = {};
        for (let item of data) {

            let rKey = item[row_fld]
            let r = piv[rKey]
            if (!r) {
                r = {};
                piv[rKey] = r
            }

            let cKey = item[col_fld]
            let c = r[cKey]
            if (!c) {
                c = {};
                r[cKey] = c
            }

            for (let val_fld of mes) {
                let val = item[val_fld]
                if (val_fld in c) {
                    //let a = c[val_fld]
                    c[val_fld] +=  val
                    //console.log(a,val,c[val_fld])
                }
                else {
                    c[val_fld] = val;
                }
            }

        }

        let piv_r = {}
        let piv_c = {}
        for (let rKey in piv) {

            piv_r[rKey] = {}

            let r = piv[rKey]
            for (let cKey in r) {
                let c = piv_c[cKey]
                if (c === undefined) {

                    let d = {}
                    for (let val_fld of mes) {
                        d[val_fld] = 0
                    }
                    piv_c[cKey] = d
                }
            }
        }

        for (let val_fld of mes) {

            let val_t = 0
            for (let rKey in piv) {
                let r = piv[rKey]

                let val = 0
                for (let cKey in r) {
                    let c = r[cKey]

                    let n = c[val_fld]
                    val += n
                    piv_c[cKey][val_fld] += n
                }
                piv_r[rKey][val_fld] = val
                val_t +=val
            }
            piv_r[val_fld] = val_t
            piv_c[val_fld] = val_t

        }


        this._values = piv
        this._values_c = piv_c
        this._values_r = piv_r

     }

 /*
    pivotValue(rid,cid,vid) {


        let data= this.parent.data

        let elem = null
        if (this.rows_dim) {

            if (this.cols_dim) {

                elem = data.reduce((accumulator, currentValue) =>
                    (elem[this.rows_dim] == rid) && (elem[this.cols_dim] == cid) ? accumulator + elem[vid] : 0,0)

                elem = this.data.find((elem) => {
                    return (elem[this.rows_dim] == rid) && (elem[this.cols_dim] == cid)
                });
            }
            else {
                elem = this.data.find((elem) => {
                    return (elem[this.rows_dim] == rid)
                });
            }

        }
        else {
            if (this.cols_dim) {
                elem = this.data.find((elem) => {
                    return (elem[this.cols_dim] == cid)
                });
            }
            else {
                elem = this.data[0]
            }
        }
        return elem ? elem[vid] : '';

    }

*/



    dimensions = []
    measures = []
    enums = {}
    _dict = {}
    sums  = []
    col = []
    row = []
    fmts = {}

    pivotcols = []
    cols = []
    rows = []

    //pivot = {}
    selectedDimensions =[]
    selectedMeasures = []
    selectedCols = []
    selectedRows = []
    selectedMess = []


    dict(item) {
        let res = this._dict[item]
        return res ? res : item
    }


    readPivotParam(result,key, def:any =[]) {
         if (result.pivot) {
             let res = result.pivot[key]
             if (res) return res
         }
         return def;
    }

    private updateFromStore(locData,key,arr) {
        if (key in locData) {
        //    this[key] = locData[key].filter( a => arr.includes(a))
            this[key] = arr.filter( a => locData[key].includes(a))
        }
    }

    readPivot(result) {


       this.parent.pivot = result.pivot ? true: false;

 //      this.pivot = result.pivot;

       this.dimensions = this.readPivotParam(result,'dimensions')
       this.measures = this.readPivotParam(result,'measures')

       this.col = this.readPivotParam(result,'cols').slice(0, 1)
       this.row = this.readPivotParam(result,'rows').slice(0, 1)
       this.sums = this.readPivotParam(result,'sums')

       this._dict = this.readPivotParam(result,'dict',{})

       this.enums = this.readPivotParam(result,'enums',{})
       this.fmts = this.readPivotParam(result,'fmts',{})

       let loc = localStorage.getItem(this.storageKey);
       if (loc) {
           let locData = JSON.parse(loc);
           this.updateFromStore(locData,'col',this.dimensions)
           this.updateFromStore(locData,'row',this.dimensions)
           this.updateFromStore(locData,'sums',this.measures)
       }

       this.updatePivot()
    }

    get sum_name() {
        return this.sums.length == 1 && this.rows.length > 0 ? this.dict(this.sums[0]) : ''
    }

    get row_name() {
        return this.row.length > 0 ? this.dict(this.row[0]) : ''
    }

    get col_name() {
        return this.col.length > 0 ? this.dict(this.col[0]) : ''
    }

    get storageKey() {
        return this.parent.ident + '.pivot'
    }


    updatePivot() {

        this.cols = this.col.length > 0 ? (this.enums[this.col[0]] ? this.enums[this.col[0]] : []): []
        this.rows = this.row.length > 0 ? (this.enums[this.row[0]] ? this.enums[this.row[0]] : []) : []

/*        this.pivotcols = [];

        let sums = this.sums.length>0 ? this.sums : ['']
        for (let col of this.cols) {
            for (let sum of sums) {
                this.pivotcols.push({name: sum, col_id: col.id, val: sum});
            }
        }
*/
        this.makePivotDict()

    }


    fmt(vid,n) {
        let f = this.fmts[vid]
        if (f=='c') {
            return this.authService.currencyFmt(n)
        }
        return this.authService.intFmt(n)
    }

    pivotValue(rid, cid, vid) {
        let r = this._values[rid]
        if (r) {
            let c = r[cid]
            if (c) {
                let v = c[vid]
                if (v) return this.fmt(vid,v);
            }
        }
        return '';
    }

    pivotValue_c(cid,vid) {
        let c = this._values_c[cid]
        if (c) {
            let v = c[vid]
            if (v) return this.fmt(vid,v);
        }
        return '';
    }

    pivotValue_r(rid,vid) {
        let r = this._values_r[rid]
        if (r) {
            let v = r[vid]
            if (v) return this.fmt(vid,v);
        }
        return '';
    }

    pivotValue_v(vid) {
        let v = this._values_r[vid]
        if (v) return this.fmt(vid,v);
        return '';
    }


    clickArr = ['date','month']

    getCursor(row) {
        if (this.row.length > 0) {
            let row_dim = this.row[0]
            if (this.clickArr.includes(row_dim)) {
                return 'pointer'
            }
        }
        return 'default'
    }

    clickRow(row) {
        if (this.row.length > 0 ) {
            let row_dim = this.row[0]
            if (this.clickArr.includes(row_dim)) {

                // doClick

                var paramItems = Object.assign({}, this.parent.paramItems);

                paramItems.sales = 1;

                let period1 = row.id;
                let period2 = row.id;

                if (row_dim == 'month') {
                    let s:string = row.id.toString()
                    period1 = s.substr(0,4) + '-'+ s.substr(4,2)+ '-01';
                    period2 = moment(period1).endOf('month').format('YYYY-MM-DD');
                }

             /*   if (Date.parse(row.id))  {  // is date ?
                    paramItems.period1 = row.id;
                    paramItems.period2 = row.id;
                    paramItems.period = 6; //  5  - day - not correct ;// 6 -  from ... to
                }
                */
                paramItems.period1 = period1;
                paramItems.period2 = period2;
                paramItems.period = 6; //  5  - day - not correct ;// 6 -  from ... to

                this.parent.baseService.authService.tag = paramItems;
                this.parent.baseService.authService.navigate(['/pivot/payment_list'])




            }
        }
    }

}