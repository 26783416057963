import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {TrustedUserService}   from "../trusted-user.service";

import {BaseList}       from '../../auth/base-list'

@Component({
  selector: 'trusted-user-table',
  templateUrl: './trusted-user-table.template.html',
})
export class TrustedUserTable extends BaseList {

  @Input('parent') parent = null;

  constructor(el: ElementRef,private trustedUserService: TrustedUserService) {
      super(el,trustedUserService);
  }

  clickAdd() {
      event.stopPropagation();


      if (!this.parent.validate()) return;

      //if (1) return

      if (this.parent && !this.parent.busy) {
          if (this.paramItems.chart_id) {
              super.clickAdd()
          }
          else {

              this.baseService.authService.confirmationDialog('To use this, please save the chart first. Save the chart?').subscribe(result => {
                  if (result) {

                      this.parent.validate()

                      this.parent.busy = true;

                      let save_item = this.parent.itemToSave();
                      this.parent.baseService.post(save_item)
                          .subscribe(
                              result => {

                                  this.parent.busy = false;
                                  this.parent.handleRead(result);

                                  this.parent.savedItem = this.parent.itemToSaveStr();
                                  this.parent.isLoaded = true;
                                  this.parent.onItemLoaded();
                                  this.parent.onItemSaved();

                                  let link = [this.parent.baseService.path,this.parent.item.id];
                                  //console.log(link)
                                  //this.baseService.authService.tag = this.paramItems; // {patient_id:this.paramItems.patient_id}
                                  this.parent.baseService.authService.navigate(link, null);

                                  //this.parent.baseService.authService
                                  //this.parent.refresh()

                                  //super.clickAdd()

                              },
                              error => {
                                  this.parent.busy = false;

                                  this.parent.baseService.authService.showError(error);
                                  this.parent.error = error
                              }
                          );

                  }
              });
          }

   //       console.log(this.paramItems)
      }
      else {
          super.clickAdd()
      }
      //event.stopPropagation();
  }

  msg1() {
      if (this.parent) {
          if (this.parent.isLoaded) {
              if (!this.parent.item.id) return '(Please save the chart first)'
          }
      }
      return ''
  }

}
