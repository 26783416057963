import {Component, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';

declare var $: any;

import {BaseList} from '../../auth/base-list';
import {ProductUserService} from "../product-user.service";

@Component({
  selector: 'product-user-table',
  templateUrl: './product-user-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class ProductUserTable extends BaseList {

    constructor(el: ElementRef,private service: ProductUserService) {

        super(el,service);
    }

    addCredits(item) {
        let _product = [];
        for (let item of this.data) {
            _product.push(item.product);
        }
        this.baseService.authService.navigate(['/subscription/add', item.id],
            {
                user: item.user, product: item.product,
                _product: _product
            });

    }

    viewCabinet(item) {
        this.openCabinet(item,item.product.url);
    }


    viewStagingCabinet(item) {
        this.openCabinet(item,item.product.staging_url);
   }


    openCabinet(item,url) {
        if (url) {
            let pwd = item.user.o_password;
            if (pwd) {
                let email = item.user.email;
                if (email) {
                    let capture = this.baseService.authService.capture;
                    this.baseService.authService.openWindowPost(url + '/login', email,
                        {email: email, password: pwd,'naf-login-token': capture});
                }
            }
        }

    }

}