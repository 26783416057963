import {Component, ViewContainerRef} from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { ApplicationStoreService } from './application-store.service';


import {AuthService} from "./auth/auth.service"

@Component({
  selector: 'app-root',
  //template: '<spinner></spinner><router-outlet></router-outlet>',
  templateUrl: './app.component.html',
//  styleUrls: ['./scss/application.scss']
//styles: [`#theme {background: red;}`]

})
export class AppComponent {
  public title = 'app works!';
  appBackground : string = '';
  public constructor(
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private authService:AuthService,
    private appStore: ApplicationStoreService) {


    /*  Route event types
     NavigationEnd
     NavigationCancel
     NavigationError
     RoutesRecognized
     */

    router.events.forEach((event: NavigationEvent) => {


      //console.log('navigation', event.constructor.name) //, event.url)
      //Before Navigation
      if (event instanceof NavigationStart) {
       //   this.authService.spinnerStart()
      }
      if (event instanceof NavigationEnd) {

 //       authService.announce();
        //console.log('navigation_start', event.url)
        //this.authService.spinnerStop()

      }
    });

    this.appStore.appState$.subscribe(appState => {
      if(appState.pagesColor) {
        this.appBackground = appState.pagesColor;
      }
    });
    
    if(sessionStorage.getItem('theme') != 'undefined') {
      let themeData = JSON.parse(sessionStorage.getItem('theme')) ? JSON.parse(sessionStorage.getItem('theme')) : '';
    
      this.appBackground = themeData['pagescolor'] ? themeData['pagescolor'] : '';
      this.appStore.setsideBarColor(themeData['sidebarcolor'] ? themeData['sidebarcolor'] : '');
      this.appStore.setsideBarFontColor(themeData['sidebarfontcolor'] ? themeData['sidebarfontcolor'] : '');
      this.appStore.setfontStyle(themeData['fontstyle'] ? themeData['fontstyle'] : '');
      this.appStore.setpagesColor(themeData['pagescolor'] ? themeData['pagescolor'] : '');
      this.appStore.setpagesFontColor(themeData['pagesfontcolor'] ? themeData['pagesfontcolor'] : '');
    }
    
  }
}
