import { throwError as observableThrowError, Observable, Subject, BehaviorSubject, AsyncSubject } from 'rxjs';
import { Injectable } from '@angular/core';


import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { Router } from '@angular/router';
import { Location } from '@angular/common';



//import {map, share, first} from 'rxjs/operators'
import { tap, catchError, retry, first, finalize, map } from "rxjs/operators";

import { interval, of } from 'rxjs';

import * as moment from 'moment';

import { SpinnerService } from './spinner/spinner.service'

import { environment } from '../../environments/environment';

//import {API_URL, API_NOTIFICATION, API_NOTIFICATION_INTERVAL, API_REMEMBER_ME } from '../app.env';

//const AUTH_TOKEN_NAME = 'auth_token';
//const LOGGED_USER_ID  = 'logged_user_id'; // real "remember me"

//const THOUSANDS_SEPARATOR = '\\s';

const LOGIN_NAVIGATION = ['auth', 'login']; // navigate to Login form
export const THIS_MARKET_ID = 0;//1;// 0;    // 1 -> free whois subscription
export const WHOIS_API_MARKET_ID = 1; //


//declare var $:any;

@Injectable()
export class AuthService {
    private loggedIn = false;
    private loggedUser: any = {};
    private loggedToken: string = '';

    //  private prev_user_id = 0;
    //    private loggedUserId: number = 0;

    public redirectUrl: string;
    public prevImgUrl: string = '';
    public prevImgBlob: any;

    private photoAnnouncedSource = new Subject<string>();
    private unreadAnnouncedSource = new Subject<number>();
    private unreadProc: boolean = false;

    public userSubject = new BehaviorSubject<any>(null)

    userFire() {
        this.userSubject.next(null)
    }

    userSubscribe(next) {
        return this.userSubject.subscribe(next)
    }


    //    private _uploadOptions: Object = null;

    photoAnnounced$ = this.photoAnnouncedSource.asObservable();
    unreadAnnounced$ = this.unreadAnnouncedSource.asObservable();

    /**
     * Number.prototype.format(n, x, s, c)
     *
     * @param integer n: length of decimal
     * @param integer x: length of whole part
     * @param mixed   s: sections delimiter
     * @param mixed   c: decimal delimiter

     number_format = function(n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = this.toFixed(Math.max(0, ~~n));

            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        };

     //12345678.9.format(2, 3, '.', ',');  // "12.345.678,90"
     //123456.789.format(4, 4, ' ', ':');  // "12 3456:7890"
     //12345678.9.format(0, 3, '-');       // "12-345-679"
     */

    number_format = function (num, n, x, s, c) {  // try http://numeraljs.com
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            str = num.toFixed(Math.max(0, ~~n));

        return (c ? str.replace('.', c) : str).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };


    getApiUrl() {
        //return API_URL;
        return environment.API_URL;
    }

    getInvoicingUrl() {
        return environment.API_TWO;
    }
    getNotificationCount() {
        if (!this.unreadProc && this.isLoggedIn()) {

            this.unreadProc = true;
            let url: string = this.getApiUrl() + `/api/notification/count`;

            this.get(url)
                .subscribe(
                    (result) => {
                        this.unreadProc = false;
                        let unread: number = (+result.count) - (+result.red);
                        this.announceUnread(unread);
                    },
                    (error) => {
                        this.unreadProc = false;
                    });
        }
    }


    constructor(private http: HttpClient, public router: Router, private location: Location, private spinnerService: SpinnerService) {

        if (environment.API_REMEMBER_ME) {

            let loc = localStorage.getItem('user');
            this.loggedUser = loc ? JSON.parse(loc) : {};

            this.loggedToken = this.loggedUser.auth_token ? this.loggedUser.auth_token : '';
            this.loggedIn = this.loggedToken.length > 10;

        }

        if (environment.API_NOTIFICATION) {

            interval(environment.API_NOTIFICATION_INTERVAL * 1000)

            // IntervalObservable.create(environment.API_NOTIFICATION_INTERVAL * 1000)
            //    .subscribe(n => { this.getNotificationCount() });
        }

        this.userFire()

    }



    getUserKey(key) {
        let locStorage = localStorage.getItem(this.user().email);
        if (locStorage) {
            let loc = JSON.parse(locStorage);
            return loc.key;
        }
        return {}
    }


    storeUser(res) {

        this.loggedIn = true;

        this.loggedUser = res;   
        if (environment.STATELESS_APPLICATION) {
            this.redirectUrl = '';
        }
        else {
            let p = localStorage.getItem('p' + res.id);
            this.redirectUrl = p ? p : '';
        }

        this.loggedToken = res.auth_token;

        if (environment.API_REMEMBER_ME) {
            //    localStorage.setItem(AUTH_TOKEN_NAME, res.auth_token);
            localStorage.setItem('user', JSON.stringify(res));
        }
        localStorage.setItem('user', JSON.stringify(res));
        this.userFire()
        if (res._info) {
           
            this.toApproveAnnouncedSource.next(res._info.to_approve)
            this.announceTask(res._info.task_count)
        }


    }

    clearUser() {
        this.loggedIn = false;
        this.loggedUser = {};
        this.loggedToken = '';

        localStorage.removeItem('user');
        sessionStorage.clear();
        this.userFire()
    }


    isLoggedIn() {

        return this.loggedIn;
    }

    checkLoginRetry(email) {
        let url: string = this.getApiUrl() + '/api/getretry';
        let item = { email }

        return this.http.post(url, item);
    }

    sendOTP(email) {
        let url: string = this.getApiUrl() + '/api/sentotp';
        let item = { email }
        return this.http.post(url, item);
    }

    verifyOTP(email, code, validity, userIP) {
        let url: string = this.getApiUrl() + '/api/verifyotp';
        let item = { email, code, validity, userIP }
        return this.http.post(url, item);
    }

    savePhone(phone, country, email) {
        let url: string = this.getApiUrl() + '/api/savephone';
        let item = { phone, country, email }
        return this.http.post(url, item);
    }

    checkRegister(email) {

        let url = this.getApiUrl() + '/api/checkregister?email=' + encodeURIComponent(email);
        return this.http.get<any>(url); //.pipe(map(res => res.json()));
    }

    saveWidget(params) {
        let saveurl:string = this.getApiUrl() + '/api/app/savewidget';
        return this.post(saveurl,params);
    }

    getWidget(params) {
        let widgeturl:string = this.getApiUrl() + '/api/app/getwidget';
        return this.post(widgeturl,params);
    }

    getcalenderdata(params) {
        let calenderurl:string = this.getApiUrl() + '/api/app/getcalenderdata';
        return this.post(calenderurl,params);
    }

    deletewidget(params) {
        let delwidurl:string = this.getApiUrl() + '/api/app/deletewidget';
        return this.post(delwidurl,params);
    }

    getAppointmentData(params) {
        let appointmentUrl:string = this.getApiUrl() + '/api/app/getappointmentdata';
        return this.post(appointmentUrl,params);
    }


    getTheme(clinic_id) {
        let getthemeUrl = this.getApiUrl() + '/api/app/selecttheme';
        let body = {clinicId: clinic_id};
        return this.post(getthemeUrl,body);
    }

    saveUserTheme(params) {
        let savethemeUrl = this.getApiUrl() + '/api/app/savetheme';
        return this.post(savethemeUrl,params);
    }

    deleteThemeLogo(params) {
        let delthemeUrl = this.getApiUrl() + '/api/app/deletethemelogo';
        return this.post(delthemeUrl,params);
    }

    createAccountInStripe(params) {
        let paymentlink = this.getApiUrl() + '/api/registerWithStripe';
        return this.post(paymentlink,params);
    }

    checkInvoiceExist(params) {
        let checkInvUrl = this.getApiUrl() + '/api/checkInvoiceExist';
        return this.post(checkInvUrl,params);
    }

    sendPaymentLink(params) {
        let payLinkUrl = this.getApiUrl() + '/api/sendPaymentLink';
        return this.post(payLinkUrl,params);
    }

    login(email, password, byPass?) {

        let market_id = THIS_MARKET_ID;
        if(byPass) {
            let url: string = this.getApiUrl() + '/api/login';
            let item = { email, market_id, byPass }
            return this.http.post<any>(url, item)
            //.toPromise()
            //.then((res) => {
            .pipe(tap(res => {
                if (res) {
                    sessionStorage.setItem('user', JSON.stringify(res));
                    if (!!res.auth_token) {
                        if (res.need_subscription) {
                            // buy subscription first
                        }
                        this.storeUser(res);
                    }
                };
                //   return res;
            }));
        } else {
            let url: string = this.getApiUrl() + '/api/login';
            let item = { email, password, market_id }
            return this.http.post<any>(url, item)
            //.toPromise()
            //.then((res) => {
            .pipe(tap(res => {
                if (res) {
                    sessionStorage.setItem('user', JSON.stringify(res));
                    if (!!res.auth_token) {
                        if (res.need_subscription) {
                            // buy subscription first
                        }
                        this.storeUser(res);
                    }
                };
                //   return res;
            }));
        }
    }

    token() {
        return this.loggedToken;
    }

    reset(email) {

        let market_id = THIS_MARKET_ID;

        let item = { 'email': email, 'confirm_redirect': 'default', 'market_id': market_id }
        let url: string = this.getApiUrl() + '/api/reset';

        return this.http.post<any>(url, item)

        /*
                let body = JSON.stringify({ 'email':email,'confirm_redirect':'default','market_id': market_id});
                let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
         //       let options = new RequestOptions({ headers: headers, method: "post" });
                let options = { headers: headers, method: "post" };


                let response: any = this.http.post(url,body,options);

                return response
                    .map(res => res.json())
        */
    }


    approve(user_id, email, sendEmail, addMembership) {

        let url: string = this.getApiUrl() + `/api/approve/${user_id}`;

        let body: any = {};
        body.email = sendEmail ? email : '';
        body.membership = addMembership ? true : false;

        return this.post(url, body);
    }



    cancelApprovement(user_id, email, sendEmail) {

        let url: string = this.getApiUrl() + `/api/disapprove/${user_id}`;

        return this.post(url, sendEmail ? { email: email } : '');
    }

    changeregister(password, password_confirm) {

        let auth_token = this.token();


        let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-CSRF-TOKEN': auth_token });

        //     let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        //     headers.append('X-CSRF-TOKEN', `${auth_token}`);
        let options = { headers: headers };

        let item = { password, password_confirm }
        let url: string = this.getApiUrl() + '/api/changeregister';

        return this.http.post(url, item, options);

        /*
                let body = JSON.stringify({password,password_confirm});
                let headers = new Headers({ 'Content-Type': 'application/json' });

                headers.append('X-CSRF-TOKEN', `${auth_token}`);

                let options = new Req uestOptions({ headers: headers, method: "post" });

                let url:string = this.getApiUrl() +'/api/changeregister';

                let response: any = this.http.post(url,body,options);

                return response
                    .map(res => res.json())
                    */
    }


    logout() {

        let auth_token = this.token();

        if (auth_token) {

            //let headers = new HttpHeaders({'Content-Type': 'application/json'});
            //headers.append('X-CSRF-TOKEN', `${auth_token}`);
            //let options = { headers: headers};

            let options = this.httpOptions();

            let item = {}
            let url: string = this.getApiUrl() + '/api/logout';
            localStorage.clear();
            this.http.post(url, item, options)
                .subscribe(
                    (res) => { //console.log('logout on server')

                    },
                    (error) => {

                    });

            /*

                        let body = JSON.stringify({});
                        let headers = new Headers({'Content-Type': 'application/json'});

                        headers.append('X-CSRF-TOKEN', `${auth_token}`);

                        let options = new RequestOptions({headers: headers, method: "post"});

                        //url = url + `?auth_token=${auth_token}`;

                        let response:any = this.http.post(url, body, options);

                        response
                            .subscribe(
                            (res) => { //console.log('logout on server')

                            },
                            (error) => {

                            });
                    */

            //console.log('remove: ' + auth_token);
        };

        //this.prev_user_id = this.loggedUser.id;

        this.clearUser();
    }

    register(item: any) {
        item.login_redirect = 'default';
        item.market_id = THIS_MARKET_ID;

        let url: string = this.getApiUrl() + '/api/register';

        return this.http.post<any>(url, item)
        /*
                let body = JSON.stringify(item);
                let headers = new Headers({ 'Content-Type': 'application/json' });
                let options = new RequestOptions({ headers: headers, method: "post" });

                let url:string = this.getApiUrl() +'/api/register';

                let response: any = this.http.post(url,body,options);

                return response
                    .map(res => res.json());
                    */
    }

    setRedirectUrl(url) {
        this.redirectUrl = url;
        if (environment.STATELESS_APPLICATION) {
            return
        }
        if (this.user_id) {
            localStorage.setItem('p' + this.user_id(), url);
        }
    }

    redirect(info = null) {

        let redir = this.redirectUrl ? this.redirectUrl : '';
        /*
                if (info) {
        
                    this.toApproveAnnouncedSource.next(info.to_approve)
                    this.announceTask(info.task_count)
                }
        */
        this.router.navigate([redir]);
    }

    redirectLogin() {
        this.router.navigate(LOGIN_NAVIGATION); // ['auth','login']);  // '/login '
    }


    extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    handleError = (error: HttpErrorResponse | any) => {

        let errMsg: string;
        if (error instanceof HttpErrorResponse) {
            if ((+error.status == 401)) {
                errMsg = '401 Unauthorized.'

                this.clearUser()
                //this.redirectLogin();  // immediately

            }
            else if (+error.status == 406) {  // not approved
                errMsg = '406 Not Acceptable.'

                this.clearUser()
            }

            else if (+error.status == 403) {
                errMsg = `403 Forbidden.`;
            }
            else if (+error.status == 422) {
                errMsg = `422 Unprocessable Entity.`;
            }

            else if (+error.status == 423) {
                errMsg = `423 Locked.`;
                // console.log(error)
                // errMsg = error.message
            }

            else {

                const err = '';
                errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

            };
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return observableThrowError(errMsg);
    }

    doNothing() { }

    back() {
        // console.log(this.location);
        this.location.back()
    }

    home() {
        this.router.navigate(['']);
        this.setRedirectUrl('');
    }


    toDetail(link: any) {
        this.router.navigate(link);

    }

    //   navigate(link:any,data={}) {

    public httpHeaders() {
        let auth_token = this.token();

        let h: any = { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': auth_token }
        if (this.auth_role) {
            h['X-CSRF-ROLE'] = this.auth_role.toString()
            // console.log('auth_role',h)

        }
        //       let headers = new HttpHeaders({ 'Content-Type': 'application/json','X-CSRF-TOKEN': auth_token });
        return h

    }

    public httpOptions(/* method */) { //: RequestOptions {
        /*
                let auth_token = this.token();
        
                let h: any = { 'Content-Type': 'application/json','X-CSRF-TOKEN': auth_token }
                if (this.auth_role) {
                    h['X-CSRF-ROLE'] = this.auth_role.toString()
                   // console.log('auth_role',h)
        
                }
         //       let headers = new HttpHeaders({ 'Content-Type': 'application/json','X-CSRF-TOKEN': auth_token });
         */
        let headers = new HttpHeaders(this.httpHeaders());

        //let options = new RequestOptions({ headers: headers, /* method: method */});
        let options = { headers: headers };

        return options;
    }

    public get(url: string) {

        let options = this.httpOptions(/* "get" */);

        return this.http.get<any>(url, options)
            .pipe(
                tap(result => {
                    if (result._info) {

                        this.toApproveAnnouncedSource.next(+result._info.to_approve)
                        this.announceTask(+result._info.task_count)
                    }
                }),

                // retry(3), // retry a failed request up to 3 times
                catchError(this.handleError) // then handle the error and throw again
            );

    }


    public csv(url: string, fileName: string = '', useGet: boolean = false) {

        let auth_token = this.token();
        let headers = new HttpHeaders({ 'X-CSRF-TOKEN': auth_token });
        let options: Object = { responseType: 'text', headers: headers };
        if (useGet == false) {
            this.http.post(url, {}, options)
            .pipe(
                catchError(this.handleError) // then handle the error
            )
            .subscribe(
                (result) => {
                    var csvContent = "data:text/csv;charset=utf-8," + result;

                    var encodedUri = encodeURI(csvContent);
                    let link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', fileName);
                    //link.setAttribute('target','_blank');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                },
                (error) => {
                    this.showError(error);
                })
        } else {
            this.http.get(url, options)
            .pipe(
                catchError(this.handleError) // then handle the error
            )
            .subscribe(
                (result) => {
                    var csvContent = "data:text/csv;charset=utf-8," + result;

                    var encodedUri = encodeURI(csvContent);
                    let link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', fileName);
                    //link.setAttribute('target','_blank');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                },
                (error) => {
                    this.showError(error);
                })
        }

    }



    public getBlob(url: string) {

        //this.http.get('...', { responseType: 'text' });
        //responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'
        /*
                let auth_token = this.token();
                let headers = new HttpHeaders({ 'X-CSRF-TOKEN': auth_token });
         //       let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob} );
               // let options = new HttpParams() // { headers: headers, responseType: 'blob'});
               // options.set('headers', headers);
               // options.set('responseType','blob')



                let options =  { headers: headers, params: {responseType: 'blob'}};

        */
        let auth_token = this.token();
        //        let headers = new HttpHeaders({ 'Content-Type': 'application/json','X-CSRF-TOKEN': auth_token });
        //    let headers = new HttpHeaders({ 'X-CSRF-TOKEN': auth_token });
        //        let options = { responseType: 'blob' as 'json', headers: headers};
        //       let options = {  headers: headers, responseType: 'blob' as 'json'};


        let headers = new HttpHeaders({ 'X-CSRF-TOKEN': auth_token });
        let options = { headers: headers, responseType: 'blob' as 'json' };
        //        let options = {  headers: headers};

        return this.http.get<Blob>(url, options)


        //   return this.http.get<any>(url, options)
    }


    public download(url: string, title: string = '') {

        //      console.log(title);
        var windowReference = window.open(); // 'Whois api invoice');

        //this.spinnerStart();
        this.getBlob(url)
            .subscribe(
                (result) => {

                    //                   var blob = new Blob([result], { type: "application/pdf" } );

                    //                  var blob = new Blob([result]); //, {type: "image/png"});


                    var blob = result;
                    if (blob.size == 0) {
                        this.showError('Download file error: no file.')
                    }
                    else {

                        var urlCreator = window.URL;

                        var locUrl = urlCreator.createObjectURL(blob);
                        windowReference.location.href = locUrl;

                        //              window.document.title = title;
                        windowReference.document.title = title;
                        //              windowReference.onload = function(){ this.document.title = 'someFile.xxx';}

                        // $(windowReference.document).find('html').append('<head><title>your title</title></head>');
                    }
                },
                (error) => {
                    //this.spinnerStop();
                    windowReference.close();
                    this.showError(error);
                })

    }




    public post(url: string, item: any) {

        let options = this.httpOptions(/*"post"*/);

        return this.http.post<any>(url, item, options)
        // let body = JSON.stringify(item);

        // return this.http.post(url,body,options).
        //     map(res => res.json());

    }

    public patch(url: string, item: any) {

        let options = this.httpOptions();

        return this.http.patch<any>(url, item, options)

    }


    public put(url: string, item: any) {
        let options = this.httpOptions();
        return this.http.put<any>(url, item, options)
    }

    delete(url: string) {

        let options = this.httpOptions(/* "delete" */);

        return this.http.delete(url, options)
        //   .map(res => res.json());

    }

    // LOCKED  -------------------------------------------------------------


    public locked(url: string) {

        //remove /app/ from client url

        let apiUrl = this.getApiUrl() + '/api/' + url.substring(5) + '/locked';

        //console.log('authService.locked',apiUrl);

        let options = this.httpOptions();
        return this.http.get(apiUrl, options) //.pipe(map(res => res.json()));
    }

    accessUrl(url: string) {
        //remove /app/ from client url

        let normalUrl = url.substring(5).split(';')[0];

        let apiUrl = this.getApiUrl() + '/api/' + normalUrl + '/access';
        return apiUrl;
    }

    public access(url: string) {

        let apiUrl = this.accessUrl(url);

        let options = this.httpOptions();
        return this.http.get(apiUrl, options)//.pipe(map(res => res.json()));
    }

    public accessRequest(url: string, item: any) {

        let apiUrl = this.accessUrl(url);
        return this.post(apiUrl, item);
    }

    // OCCUPATIONS & PROVINCES & other references -------------------------------------------------------------

    public getReference(list: string) {

        let url: string = this.getApiUrl() + '/api/reference?data=' + list;
        let options = this.httpOptions();

        return this.http.get<any>(url, options) //.pipe(map(res => res.json()));
    }

    public getLogReference(list: string) {  // with login

        let url: string = this.getApiUrl() + '/api/logreference?data=' + list;
        let options = this.httpOptions();

        return this.http.get(url, options) // .pipe(map(res => res.json()));
    }

    public dateFmt(value) {
        var fmt = 'DD MMM YYYY'; // 'MMM DD, YYYY';
        return moment(value).format(fmt);
    }

    //  SESSION -----------------------------------------------------------------------------------------------

    public user(): any {
        return this.loggedUser; // JSON.parse(localStorage.getItem('user'));
    }

    private get authUser() {
        return this.user();
    }

    public needMembership() {
        return this.authUser.need_subscription;
    }

    public userFirstName(): string {
        return this.authUser.first_name ? this.authUser.first_name : '';
    }

    public userLastName(): string {
        return this.authUser.last_name ? this.authUser.last_name : '';
    }

    public userPhoto(): string {
        return this.authUser.photo ? this.authUser.photo : '';
    }

    //-----------------------------------------------------------------------------

    public isadmin() {
        return (!!this.authUser.isadmin) // && !this.auth_role
    }

    public isadmin0() {
        return this.isadmin() && this.user_id() < 100;
    }


    public isguest() {
        return false;
        //      return !!this.authUser.isguest;
    }

    public get guestLink() {
        return this.authUser.guest_link
    }


    public isAccountant() {
        return !!this.authUser.isaccountant;
    }

    public isDeveloper() {
        return !!this.authUser.isdeveloper;
    }

    public get capture() {
        return this.authUser.capture;
    }


    public isClient() {
        return (!this.isadmin()) && (!this.isAccountant()) && (!this.isDeveloper());
    }

    public istranscriptionist() {
        return !!this.authUser.istranscriptionist;
    }

    monthFmt(dt) {

        return moment(dt).format('MMMM YYYY');
        //format('MMMM Do YYYY, h:mm:ss a');
    }

    dayFmt(dt) {
        return moment(dt).format('YYYY-MM-DD');
    }


    intFmt(num) {
        //* @param integer n: length of decimal
        //* @param integer x: length of whole part
        //* @param mixed   s: sections delimiter
        //* @param mixed   c: decimal delimiter


        return +num ? this.number_format(+num, 0, 3, ' ', '') : '';
    }


    currencyFmt(num) {
        //* @param integer n: length of decimal
        //* @param integer x: length of whole part
        //* @param mixed   s: sections delimiter
        //* @param mixed   c: decimal delimiter


        return +num ? '$' + this.number_format(+num, 0, 3, ' ', '.') : '';
    }
    /*
    currencyFmt0(num) {
        //* @param integer n: length of decimal
        //* @param integer x: length of whole part
        //* @param mixed   s: sections delimiter
        //* @param mixed   c: decimal delimiter


        return  +num ? '$'+this.number_format(+num,0,3,' ','.'): '';
    }
    */

    toLocalTime(val) {
        let localTime = moment.utc(val).toDate();
        return moment(localTime).format('YYYY-MM-DD HH:mm:ss')  // local time

    }
    //-----------------------------------------------------------------------------

    public stripe_key() {
        return this.authUser.stripe_key;
    }

    public email() {
        return this.authUser.email
    }

    public username() {
        return this.authUser.username

    }

    basicMembershipPaymentAmount() {
        return this.authUser.basic_membership_payment_amount;
    }

    public user_id() {
        return this.user().id;
    }

    public isUser(id) {
        return id == this.user_id();
    }

    announcePhoto(photo: string) {

        this.authUser.photo = photo;
        this.photoAnnouncedSource.next(photo);
    }


    // DROPZONE       ----------------------------------------------------------------------------------------

    public uploadURL() {
        return this.getApiUrl() + '/api/upload';
    }

    public imageURL(id) {
        return this.getApiUrl() + '/api/image/' + id;
    }

    public downloadImage(id, name) {
        this.download(this.imageURL(id), name)
    }


    // IMAGE ---------------------------------------------------------------------------------------------------

    public uploadOptions(): Object {

        //        if (!this._uploadOptions) {

        let auth_token = this.token();
        let options = {
            //          this._uploadOptions = {
            url: this.getApiUrl() + '/api/upload',
            customHeaders: {
                'X-CSRF-TOKEN': auth_token,
            }
        }
        return options;
        //        }

        //        return this._uploadOptions;
    }

    get ie() {
        return window.navigator && window.navigator.msSaveBlob
    }


    // SUBSCRIPTIONS --------------------------------------------------------------------------------------------

    public getSubscription() {

        let user_id = this.user_id();

        let url: string = this.getApiUrl() + `/api/subscription?user_id=${user_id}`;
        let options = this.httpOptions();

        return this.http.get<any>(url, options) // .pipe(map(res => res.json()));

    }


    // TO APPROVE     --------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------------


    public sqlToBoolean(value: any): any {
        return (+value) ? true : false;
    }


    public sqlToDate(value: any): any {

        if (!value) { return null };
        let [yyyy, mm, dd] = value.split(' ')[0].split('-');
        return `${dd}/${mm}/${yyyy}`;
    }


    public dateToSql(value, time?) {

        //console.log(value,time);
        if (!value) { return null };

        let [dd, mm, yyyy] = value.split('/');

        let tm = time ? ' ' + time : '';
        return `${yyyy}-${mm}-${dd}${tm}`
    }

    public timeToSql(value) {

        if (!value) { return null };

        let [hh, mm] = value.split(':');
        return `${hh}:${mm}`
    }

    public sqlToTime(value: any): any {

        if (!value) { return null };
        let [hh, mm] = value.split(' ')[1].split(':');
        return `${hh}:${mm}`;
    }

    public numToSql(value) {
        if (!!value) {
            if (value instanceof String)
                return value.replace(/[^0-9\.\-\+]/g, "");
            //            return value.replace(new RegExp(THOUSANDS_SEPARATOR,'g'), "");
            return value;
        }
        return '0.00';
    }

    showMessage(message: string, header?) {
        this.spinnerService.showMessage(message, header);
    }

    quitDialog() {
        return this.spinnerService.quitDialog().pipe(first());
    }

    showError(error) {
        let errMsg = error;

        //console.log(error)

        if (error instanceof HttpErrorResponse) {
            if ((+error.status == 401)) {
                this.logout();
                this.redirectLogin();
                return;
            }
            /*
            else if (+error.status == 403) {
                errMsg = `403 Forbidden.`;
                // console.log(error)
                // errMsg = error.message
            }
            */
            else if (+error.status == 422) {
                let text = error.error && error.error.message ? error.error.message : error.statusText
                errMsg = `${text}`; // without 422
            }
            /*
            else if (+error.status == 423) {
                errMsg = `423 Locked.`;
                // console.log(error)
                // errMsg = error.message
            }
            */
            else {
                let text = error.error && error.error.message ? error.error.message : error.statusText
                errMsg = `${error.status} ${text}`;

                //      const err = '';
                //      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

            };
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        /*
                if (+error.status == 422) {
                    let body = error.error
                    let errMsg = body.error ? body.error : ( body.message ? body.message : null)
                    if (errMsg) {
                        this.spinnerService.showMessage(errMsg,'Sorry');
                        return
                    }
                }
        
                if (+error.status == 403) {
                    let body = error.error
                    let errMsg = body.error ? body.error : ( body.message ? body.message : null)
                    if (errMsg) {
                        this.spinnerService.showMessage(errMsg,'Sorry');
                        return
                    }
                }
        
        
                if (error instanceof Response) {
        
                    if ((+error.status == 401)) {
        //                console.log(`auth.service ERROR: status ${+error.status}`);
        //                console.log(`do logout`);
        //                console.log(this);
                        const errMsg = error.toString(); // '';
        
        //                this.logout();
        //                this.redirectLogin();
        //                return;
                    }
                    else {
        
        
        
                        //const body = error.json() || '';
                        //const err = body.error || JSON.stringify(body);
                        const errMsg = error.toString(); // '';
                        //errMsg = `${error.status} - ${error.statusText || ''} >>${err}`;
        
                        console.log(error);
                    };
                } else {
                    errMsg = error.message ? error.message : error.toString();
                }
        */

        this.spinnerService.showMessage(errMsg, 'Sorry');
    }

    spinnerStart() {
        this.spinnerService.start();

    }

    spinnerStop() {
        this.spinnerService.stop();

    }

    //------------------------------------------------------------------------------------------------------

    buyRStore(body) {

        let url: string = this.getApiUrl() + `/api/rstore`;
        let options = this.httpOptions();

        return this.http.post<any>(url, body, options)
    }

    doUpdateCard(stripe_token, subscription_id) {

        let body = {
            stripe_token: stripe_token,
            subscription_id: subscription_id,
        };

        let url: string = this.getApiUrl() + `/api/stripe_update_card_details`;
        let options = this.httpOptions();

        return this.http.post<any>(url, body, options)
        //  .map(res => res.json());

    }

    buySubscription(body) {

        let url: string = this.getApiUrl() + `/api/subscription/buy`;
        let options = this.httpOptions();

        return this.http.post<any>(url, body, options) // .pipe(
        //   map(res => res.json()));
    }


    announceUnread(unread) {
        this.unreadAnnouncedSource.next(unread);
    }

    //----------------------------------------------------------------------------------------------------
    /*
    private _routeData: any[] = [];

    public set routeData(data) {
        this._routeData.push(data);
    }

    public get routeData() {
        return this._routeData.shift();
     }
    */
    //----------------------------------------------------------------------------------------------------


    public copy(url, item) {
        let options = this.httpOptions(/*"post"*/);
        url = url + item;
        
        return this.http.post(url, options) // .pipe(
        // map(res => res.json()));
    }


    confirmationDialog(content) {
        return this.spinnerService.yesDialog('Confirmation', content).pipe(first());
    }


    periodDialog(item = {}) {
        return this.spinnerService.periodDialog(item).pipe(first());

    }

    selectDialog(item) {
        if ((item.selections.length > 0) && (typeof item.selections[0] === 'string' || item.selections[0] instanceof String)) {
            throw "call to selectDialog out of date"
        }
        return this.spinnerService.selectDialog(item).pipe(first());

    }

    pivotDialog(item) {
        return this.spinnerService.pivotDialog(item).pipe(first());

    }

    dialog(dialog, item) {
        return this.spinnerService.dialog(dialog, item).pipe(first());
    }

    invitation(email, role) {

        let url: string = `${this.getApiUrl()}/api/invitation?email=${email}&role=${role}`;
        return this.post(url, {});

    }
    /*

    openWindowPost(url,name,params)
    {
        let newWindow = window.open(url, name);
       // if (!newWindow) return false;

        console.log(url,name,params)

        var html = "";
            html += "<html><head></head><body><form id='formid' method='post' action='" + url + "'>";

            for (let key of params) {
                html += "<input type='hidden' name='" + key + "' value='" + params[key] + "'/>";
                html += "</form><script type='text/javascript'>document.getElementById(\"formid\").submit()</script></body></html>";
            }
            newWindow.document.write(html);
            return newWindow;
    }
    */

    openWindowPost(url, name, params) {
        var winName = name;
        //var windowoption='resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
        //var params = { 'param1' : '1','param2' :'2'};
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", winName);
        for (var i in params) {
            if (params.hasOwnProperty(i)) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);

                // console.log(i,params[i]);
            }
        }
        document.body.appendChild(form);
        window.open('', winName); // ,windowoption);
        form.target = winName;
        form.submit();

        document.body.removeChild(form);
    }



    public upload_max_size_str() {
        let upload_max_size = this.authUser.upload_max_size;

        return upload_max_size;
    }

    public upload_max_size() {
        let upload_max_size = this.authUser.upload_max_size;

        let u = upload_max_size.slice(-1);
        if (u.toUpperCase() == "M") {
            return (+upload_max_size.slice(0, -1)) * 1024 * 1024;
        }

        return +upload_max_size;
    }

    menu() {
        return this.user().menu
    }

    private taskAnnouncedSource = new BehaviorSubject<any>(undefined) // Subject<any>();
    public taskAnnounced$ = this.taskAnnouncedSource.asObservable();

    taskCount = 0 //: undefined;

    announceTask(count) {

        this.taskCount = count;
        this.userFire()


        this.taskAnnouncedSource.next(+count);
    }

    private toApproveAnnouncedSource = new Subject<any>();
    public toApproveAnnounced$ = this.toApproveAnnouncedSource.asObservable();


    public toApprove() {
        let url: string = this.getApiUrl() + `/api/user/to_approve`;
        return this.get(url);
    }

    announce() {
        if (this.loggedIn) {
            let url: string = this.getApiUrl() + `/api/announce`;
            this.get(url).subscribe(
                (result) => {
                    this.announceTask(result.task_count)
                }, (error) => {
                })


            this.getNotificationCount()

            this.toApprove().
                subscribe(
                    (result) => {
                        this.toApproveAnnouncedSource.next(result);
                    }, (error) => {
                    })

        }

    }

    backUpContext: any = null

    //-------------------------------------------------------------------------

    public tag: any = null;

    //-------------------------------------------------------------------------

    private _routeData: any = null;

    public set routeData(data) {
        this._routeData = data;
    }

    public get routeData() {
        return this._routeData;
    }

    /*
    private _routeData: any = null;

    public set routeData(data) {
        //console.log('auth.routeData set',this._routeData)

        this._routeData = data;
    }

    public get routeData() {
        //console.log('auth.routeData get',this._routeData)
        return this._routeData;
    }

   */
    navigate(link: any, data = null) {
        this.routeData = data;
        if (!Array.isArray(link)) {
            link = link.split('/');
            //            console.log(link)
        }
        this.router.navigate(link);
    }


    get intf() {
        //  console.log(this.authUser.intf)
        return this.authUser.intf;
    }

    setIntf(value) {
        this.home()
        this.intf.intf = value
        this.userFire();
        if (this.user_id) {
            localStorage.setItem('intf' + this.user_id(), value);
        }

    }

    auth_role = 0

    isAuthAdmin() {
        // console.log('isadmin',this.i)
        return this.isadmin() && (+this.auth_role == 1 || +this.auth_role == 0)
    }

    removeBillingFromMenu(items) {
        const billing_arr = items.filter(elem => elem.name === 'Billing')
        if (billing_arr.length > 0) {
            billing_arr.forEach(element => {
                items.splice(items.indexOf(element), 0)
            });
        }
        return items;
    }

    getMenu(billing_subscribed: boolean = false) {
        let intf = this.intf;
        if (intf) {
            let interfaceName = intf.intf || ''
            let menu = intf.menu
            if (menu) {
                this.auth_role = menu[interfaceName].auth_role
                //     console.log(menu[interfaceName].items)
                let items = menu[interfaceName].items
                if (this.auth_role !== 4 && this.auth_role !== 7) {
                    const billing = items.findIndex(elem => elem.name === 'Billing');
                    if (billing_subscribed) {
                        //     // if (menu[interfaceName].acl.finc(elem => ''))
                        if (billing === -1) {
                            items.splice(6, 0, { name: "Billing", icon: "fa fa-lg fa-fw fa-file-text", path: "/billing/bills", acc: "admin" })
                        } else {
                            const billing_arr = items.filter(elem => elem.name === 'Billing')
                            if (billing_arr.length > 1) {
                                billing_arr.pop()
                                billing_arr.forEach(element => {
                                    items.splice(items.indexOf(element), 0)
                                });
                            }
                        }
                    } else {
                        return this.removeBillingFromMenu(items)
                    }
                }
                return this.removeBillingFromMenu(items);
            }
        }
        return null;
    }

    getACL() {
        let intf = this.intf;
        if (intf) {
            let interfaceName = intf.intf || ''
            let menu = intf.menu
            if (menu) {
                this.auth_role = menu[interfaceName].auth_role
                return menu[interfaceName].acl
            }
        }
        return null;
    }


    interfaceName() {
        let intf = this.intf;
        if (intf) {
            return intf.intf || ''
        }
        return ''
    }


    getStorage(name, value = null) {

        let locStorage = localStorage.getItem(name)
        return locStorage ? JSON.parse(locStorage) : value
    }

    setStorage(name, value) {

        localStorage.setItem(name, JSON.stringify(value));
    }



    isDoctor(item) {
        if (item.role) {
            let role = item.role.split(',').map(item => +item)
            return role.includes(5) || role.includes(6) || role.includes(4)
        }
        return false
    }

    public isOnlyDoctor() {
        return (!!this.authUser.isdoctor)
    }

    isAuthDoctor() {
        return this.isOnlyDoctor() || ((+this.auth_role == 5 || +this.auth_role == 6))
    }



    /*
        private acl(path,access) {
            if (this.isadmin()) return true;

            if (!this.authUser.version) return true;

            let arr = this.authUser.acl
            if (!arr) return false;

            let methods = arr[path]
            if (!methods) return false;

            let acc = access.toLowerCase().split(',');
            return acc.some((item)=> methods.includes(item))

        }
        */

    //private
    acl(path, access) {
        if (this.isadmin() && (this.auth_role == 0 || this.auth_role == 1)) {
            return true
        }
        //console.log(this.auth_role,this.isadmin())
        let acl = this.getACL()
        if (acl) {
            let aclPath = acl[path]
            //     console.log('aclPath',aclPath)
            if (aclPath) {
                return aclPath.includes(access)
            }
        }

        //console.log('acl',path,access)
        return this.user().diagnoses // false
    }

    hasDiagnoses() {
        return this.acl('/diagnosis', 'list') || this.user().diagnoses
    }

    hasCharts() {
        return this.acl('/chart', 'list') || this.user().diagnoses
    }

    can_user_list() {
        return this.acl('/user', 'list')
    }


    urlName(value) {

        var i = value.lastIndexOf('/');
        if (i < 0) {
            i = value.lastIndexOf('\\');
        }
        if (i < 0) {
            return value;
        }
        ;

        return value.slice(i + 1);
    }

    apiUrl(value) {
        return this.getApiUrl() + '/api/image/' + this.urlName(value);
    }

    getImageBlob(value) {
        let url = this.apiUrl(value); // this.authService.getApiUrl() + this.urlName(value);

        return this.getBlob(url)

    }

    public status: Subject<any> = new Subject<any>();
    public dialogResult = new Subject<any>();


    showMessage2(message: string, title?) {
        this.status.next({ id: 'show_message', message: message, title: title });
    }

    get debug() {
        return this.user().debug
    }

    clearDB() {

        let url: string = this.getApiUrl() + `/api/clear_db`;

        this.spinnerStart()
        this.post(url, {})
            .pipe(
                //  finalize(() => {
                //      this.spinnerStop()
                // }),

                //map(result=>{
                //    return result;
                //})
            )
            .subscribe(result => {
                this.spinnerStop()
                this.showMessage('The DB is clean.')
            },
                error => { this.spinnerStop(); this.showError(error) })
    }
    //

}
