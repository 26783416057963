import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'dateLocal'
})
export class DateLocalPipe implements PipeTransform {

  transform(value: any, format?: any): any {

      if (!value || value==="") return "";

      let localTime = moment.utc(value).toDate();
      return  moment(localTime).format('DD MMM YYYY')  // local time ---> datex !!!

   //   return  moment(value).utc().format('HH:mm');  // local time

  //  return  moment(value).format('YYYY-MM-DD HH:mm:ss')  // local time

  //  let localTime = moment.utc(date).toDate();
 //   return  moment(localTime).format('YYYY-MM-DD HH:mm:ss')  // local time


  //  return moment(value).format('YYYY-MM-DD');
  }

}
