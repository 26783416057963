/**
 * Created by Alexander Spazhev on 17.10.2016.
 */

import { Component,ViewEncapsulation, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl,FormArray } from '@angular/forms';
import { ReactiveFormsModule} from "@angular/forms";
import { EventEmitter, ElementRef } from '@angular/core';


import {TrustedUserService} from '../trusted-user.service';
import {BaseItem}  from '../../auth/base-item';

@Component({
    selector: 'trusted-user-detail',
    templateUrl: './trusted-user-detail.template.html',

    encapsulation: ViewEncapsulation.None,
})
export class TrustedUserDetail extends BaseItem {


    constructor(el:ElementRef,protected route: ActivatedRoute, protected trustedUserService: TrustedUserService) {
        super(el,route, trustedUserService);

        this.baseService.authService.backUpContext = { tab:3}
    }


    get disabledColor() {
        return this.isAdmin ? '#fff' : '#eee'
    }

    onReadItem(result) {
        if (result.chart) {
            if (!result.chart.name) {
                result.chart.name= "#"+result.chart.id
            }
        }
    }



}