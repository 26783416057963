
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
/**
 * Created by Alexander Spazhev on 30.10.2016.
 */
import { ViewChild,Input,ElementRef} from '@angular/core';
import {Http, Response} from '@angular/http';

import { FormControl } from '@angular/forms';

import {BaseService} from './base.service';

import {BaseForm} from "./base-form"

import {periodStr} from "./period-str"
import * as moment from 'moment';



import { environment } from '../../environments/environment';

export class BaseList extends BaseForm{

    public serviceItems:any = {paging: true,  maxSize: 5, per_page: 50, invoicing: false}; // max 5,10,15,20,50

    public paramItems: any = {};

    /* protected */ data:any[] = [];
    numPages: number = 1;   // ??? from pagination ex.

    public searchControl = new FormControl();

    pageFirstEnabled: boolean = false;
    pagePrevEnabled: boolean = false;
    pageNextEnabled: boolean = false;
//    pageLastEnabled: boolean = false;

    @ViewChild('pageFirstLink') pageFirstLink: any;
/*
    @ViewChild('pagePrevLink')  pagePrevLink: any;

    @ViewChild('pageNextLink')  pageNextLink: any;
    @ViewChild('pageLastLink')  pageNextLink: any;
    */

    isLoaded: boolean = false;


    serviceKeys() {
        return ['per_page'];
    }

    loadKeys() {
        let locStorage = localStorage.getItem(this.storageKey());
        if (locStorage) {
            let loc = JSON.parse(locStorage);

            if (!environment.STATELESS_APPLICATION) {
                if (this.searchControl) {
                    if (loc.search) {
                        this.searchControl.setValue(loc.search);
                        this.serviceItems.filter = loc.search;
                    }
                }
                for (let key of this.keys()) {
                    if (loc[key]) {
                        this.paramItems[key] = loc[key];
                    }
                }
            }
            for (let key of this.serviceKeys()) {
                if (loc[key]) {
                    this.serviceItems[key] = loc[key];
                }
            }
        }

        this.onReadParams(this.paramItems);
    }

    saveKeys() {

        let loc:any = {};

        if (!environment.STATELESS_APPLICATION) {
            if (this.serviceItems.filter) {
                loc.search = this.serviceItems.filter;
            }

            for (let key of this.keys()) {
                if (this.paramItems[key]) {
                    loc[key] = this.paramItems[key];
                }
            }
        }
        for (let key of this.serviceKeys()) {
            if (this.serviceItems[key]) {
                loc[key] = this.serviceItems[key];
            }
        }


        if (loc) {
            localStorage.setItem(this.storageKey(), JSON.stringify(loc));
        }
        else {
            localStorage.removeItem(this.storageKey());
        }
    }

    @Input('widget_name') widget_name= '';




    constructor(el: ElementRef,protected baseService:BaseService) {

        super(el);

        let x = this.baseService.baseURL.split('/').slice(-1)[0];
        this.widget_name =  '-table-'+x;

      //  console.log('widgetName',this.widgetName)

        this.loadKeys();

        this.searchControl.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(term => {
                this.serviceItems.filter = term;
                this.serviceItems.page = 1;
                this.getList();
            });

    }

    ngOnDestroy() {
        this.saveKeys();
    }

    addLink() {
        return ['0']
    }

    onPageChange(config: any, page: any = {page: this.serviceItems.page, itemsPerPage: this.serviceItems.per_page}): any {

    //    console.log('onPageChange',this.serviceItems.page);
        this.serviceItems.page = page.page;
        this.getList();
    }

    pageChanged() {
   //     console.log('pageChanged',this.serviceItems.page);
        this.getList();
    }


    onReadItems(result) {}


    //-----------------------------------------------------------------------

    _tablePart = null;
    @Input('table-part') set tablePart(value) {
        //console.log('settablepart',value)
        this._tablePart = value;
        if (this._tablePart && ('master' in this._tablePart)) {
            this.getList();
        }
    } ;

    _error:any = null

    doGetList() {
        this.onReadParams(this.paramItems);


        this.baseService.getItems(this.serviceItems, this.paramItems)
            .subscribe(
                result => {
                    this.data = result.data;                 
                    this.serviceItems.page = result.current_page ? result.current_page : result.current_Page;
                    this.serviceItems.total = result.total;
                    this.serviceItems.to = result.to;
                    this.serviceItems.from = result.from;
                    this.serviceItems.last_page = result.last_page ? result.last_page : result.last_Page;
                    this.serviceItems.per_page = result.per_page ? result.per_page : result.per_Page;
                    // this.serviceItems.current_page = result.current_page;
                    this.enablePages();
                    this.onReadSelectList(result);

                    this.readIndex(result)

                   // this.onReadPivot(result)

                    this.onReadItems(result);
                    this.isLoaded = true;

                    //  console.log('base-list-result',result)
                    this._error = null;

                    //               console.log('read:',this.serviceItems.per_page)
                },
                error => {
                    //this.serviceItems.error = error;
                    //  console.log('base-list-error',error)

                    this._error = error;
                    // this.handleError(error);


                    /* this.isLoaded = true; */
                }
            );

    }

    updateParamsFromTablePartQuery() {
        if (this._tablePart && this._tablePart.query && !this._tablePart.done) {
            //console.log(this._tablePart)
            for (let key in this._tablePart.query) {
                let value = this._tablePart.query[key];
                if (key=='search') {
                    if (value) {
                        this.searchControl.setValue(value);
                        this.serviceItems.filter = value

                    }
                    else {
                        this.searchControl.setValue(null);
                        delete this.serviceItems.filter;
                    }

                }
                else {
                    if (value) {
                        this.paramItems[key] = value
                    }
                    else {
                        delete this.paramItems[key];
                    }
                }
            }
            this._tablePart.done = true ; // update paramItems on init only
            //console.log('table part',this._tablePart)
        }
    }

    getList() {
      //  console.log('table part',this._tablePart);
        if (this._tablePart === null) {
            this.doGetList();
        }
        else {

            if (this._tablePart.master) {
                this.updateParamsFromTablePartQuery();
                this.doGetList();

            }

            if (!('master' in this._tablePart)) {
                this.updateParamsFromTablePartQuery();
                this.doGetList();
            }
        }
    }



//-----------------------------------------------------------------------------------------------------------

    showFromTo() {
        if (this.serviceItems) {
            if (this.serviceItems.total > 0) {
                return `Showing ${this.serviceItems.from} - ${this.serviceItems.to} of ${this.serviceItems.total}`;
            }
        }
        return 'No matches';
    }

    showFromToNo() {
        if (this.serviceItems) {
            if (this.serviceItems.total > 0) {
                return `Showing ${this.serviceItems.from} - ${this.serviceItems.to} of ${this.serviceItems.total}`;
            }
        }
        return 'No';
    }



    enablePages() {
        this.pageFirstEnabled = (this.serviceItems.page > 1);
        this.pagePrevEnabled = (this.serviceItems.page > 1);
        this.pageNextEnabled = (this.serviceItems.page < this.serviceItems.last_page);
//        this.pageLastEnabled = (this.serviceItems.page < this.serviceItems.last_page);

        if (this.pageFirstLink) {this.pageFirstLink.disabled = !this.pageFirstEnabled};
    }

    pageFirst() {
        this.serviceItems.page = 1;
        this.getList();
    }

    pagePrev() {
        if (this.serviceItems.page > 1) {
            this.serviceItems.page =this.serviceItems.page -1;
            this.getList();
        }
    }

    pageNext() {
        if (this.serviceItems.page < this.serviceItems.last_page) {
            this.serviceItems.page =this.serviceItems.page + 1;
            this.getList();
        }
    }

    pageLast() {
        this.serviceItems.page = this.serviceItems.last_page;
        this.getList();
    }

    pageLastEnabled() {
        return this.serviceItems.page < this.serviceItems.last_page;
    }


    //-----------------------------------------------------------------------------------------------------------

    handleError (error: Response) {
        this.baseService.authService.showError(error);
    }
    //-----------------------------------------------------------------------------------------------------------

    updateSearchControl() {

        if (this.searchControl && this.searchControl.value != '') {
            this.searchControl.setValue('');
        }
        else {
            this.serviceItems.filter = '';
            this.serviceItems.page = 1;
            this.getList();
        }

    }



    refresh() {
        this.updateSearchControl();
        //this.getList();
    }


    deleteItem(id: any){
        this.baseService.delete(id)
            .subscribe(
                result => {this.getList()},
                error =>  {this.baseService.authService.showError(error); });
    }

    delete_id = 0;

    delete(item) {
        this.delete_id = item.id;
        this.baseService.authService.confirmationDialog('Are you sure to delete selected item?').
            subscribe( result => { if (result) this.deleteItem(this.delete_id)});
    }


    changePerPage() {
//        console.log('set',this.serviceItems.per_page)
        this.serviceItems.page = 1;
        this.getList();

     //   this.updateSearchControl();
    }



    _user = [];

    isSlave(key) {
        if (this._tablePart) {
            let query = this._tablePart.query;
            if (query) {
                return key in query && query[key];
            }
        }
        return false;
    }

    multi(key) {
        return (this._user.length > 0) && (this.paramItems[key]? this.paramItems[key] == 0 : true);
    }
    /*
    multiUser() {
        return this._user.length > 0 && this.baseService.authService.isadmin()

        && (!this.isSlave('user_id'));
    }
    */



    _product = [];
    _period = [];
    _state = [];
    _market= [];
    _dimension=[];
    _totals = [];

    _sales=[];
    _month_or_year =[];
    _expiring_or_expired = [];

    _mode=[];

    //--------------------------------------------------------------------------

   // _blog_category = [];
   // _published = [];

    readSelection(result, name) {
        //if (this[name].length == 0 && result[name]) {
        //    this[name] = result[name];
        //}

        if (!this[name] && result[name]) {
            this[name] = result[name];
        }

    }

    //---------------------------------------------------------------------------

    onReadSelectList(result) {


        if (this._user.length == 0 && result._user && (!this.isSlave('user_id'))) {
            this._user = result._user;
        }


        if (this._product.length == 0 && result._product && (!this.isSlave('product_id'))) {
            this._product = result._product;
        }

        if (this._market.length == 0 && result._market && (!this.isSlave('market_id'))) {
            this._market = result._market;
        }


        if (this._period.length == 0 && result._period) {
            this._period = result._period;
        }

        if (this._state.length == 0 && result._state) {
            this._state = result._state;
        }


        if (this._dimension.length == 0 && result._dimension) {
            this._dimension = result._dimension;
        }

        if (this._sales.length == 0 && result._sales) {
            this._sales = result._sales;
        }

        if (this._totals.length == 0 && result._totals) {
            this._totals = result._totals;
        }

        if (this._month_or_year.length == 0 && result._month_or_year) {
            this._month_or_year = result._month_or_year;
        }

        if (this._expiring_or_expired.length == 0 && result._expiring_or_expired) {
            this._expiring_or_expired = result._expiring_or_expired;
        }

        if (this._mode.length == 0 && result._mode) {
            this._mode = result._mode;
        }

        let keys = this.keys();
        for (let key of keys) {
           if (!this.isSlave(key)) {
              let name = key;
               if (!this[name] && result[name]) {
                   this[name] = result[name];
               }
           }
        }
    //    this.readSelection(result,'_blog_category');
    //    this.readSelection(result,'_published');


    }

    clickUser(id) {
        this.onSelectChange({ name: 'user_id', id: id});
    }


    onUserChange(val) {
        this.paramItems.user_id = val;
        this.updateSearchControl();

    }

    onSelectChange($event) {
        let name = $event.name;
        let id = $event.id;

        //console.log($event);
        if (this.paramItems[name] != id) {
            this.paramItems[name] = id;
            this.updateSearchControl();
        }
    }

//    periodItem = {}


    compPeriodStr() {

        let d1 = this.strToMoment(this.paramItems.period1,'YYYY-MM-DD','DD/MM/YYYY');
        let d2 = this.strToMoment(this.paramItems.period2,'YYYY-MM-DD','DD/MM/YYYY');

        return periodStr(d1,d2,'DD/MM/YYYY');
    }

    strToMoment(s,fmtFrom,fmtTo) {

        return s ? moment(s,fmtFrom).format(fmtTo) : null;
    }


    clearPeriod($event) {
        $event.preventDefault()

        delete this.paramItems.period;
        delete this.paramItems.period1;
        delete this.paramItems.period2;

        this.getList();
    }

    onReadParams(params) {

        if (this.paramItems.period == 7) {  // current month

            this.paramItems.period1 = moment().startOf('month').format('YYYY-MM-DD');
            this.paramItems.period2 = moment().endOf('month').format('YYYY-MM-DD');

        }

        if (this.paramItems.period == 8) {  // today

            this.paramItems.period1 = moment().startOf('day').format('YYYY-MM-DD');
            this.paramItems.period2 = moment().endOf('day').format('YYYY-MM-DD');

        }

    }


    selectPeriod() {

        let item = {

            period: this.paramItems.period,
            dt1: this.strToMoment(this.paramItems.period1,'YYYY-MM-DD','DD/MM/YYYY'),
            dt2: this.strToMoment(this.paramItems.period2,'YYYY-MM-DD','DD/MM/YYYY'),
        }
        this.baseService.authService.periodDialog(item)
            .subscribe(result=> { if (result.result) {
                let item = result.item;
                this.paramItems.period  = item.period;
                if (item.dt1) {
                    this.paramItems.period1 = this.strToMoment(item.dt1, 'DD/MM/YYYY', 'YYYY-MM-DD');
                }
                else {
                    delete this.paramItems.period1;
                }
                if (item.dt2) {
                    this.paramItems.period2 = this.strToMoment(item.dt2, 'DD/MM/YYYY', 'YYYY-MM-DD');
                }
                else {
                    delete this.paramItems.period2;
                }

                this.paramItems.period_id = 0;
                //console.log('period selected',item);
                this.getList()

            }})
    }



    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    multiUser() {  // override
        return this.baseService.authService.isadmin() && (!this.isSlave('user_id'));
    }

    key_widths= { _blog_category: 224, _published: 164}

    getWidth(key) {
        return this.key_widths[key] ? +this.key_widths[key]: 164;
    }

    keys() {
        var key_arr = ['state',  'period_id', 'product_id', 'market_id',
            'period', 'period1', 'period2', 'tags','dimension','sales','totals', //  'mode',
            'month_or_year','expiring_or_expired',

            '_blog_category',
            '_published',
            '_task_state',

            '_task_type_id',
            '_user_role_id',
            '_actor_role_id',

            '_time_selected',
            '_rstore_select',
            '_rcategory',

            '_role_id',

            '_chart_template_user_id',

            '_subscribed',

            'draft_from_task',

            '_clinic_id',
            '_to_approve',

            'tag','activity','patient','_tag','_patient','_activity',

            'clinic',
            '_clinic',
            'clinic_id',

            'client'  // filter patients for clinic manager + doctor
        ];
        // if (this.baseService.authService.isadmin()) {
        //    key_arr.push('user_id');
        //}
        return key_arr;
    }

    get icon() {
        return this.baseService.icon
    }

    serviceIcon() {
        return this.baseService.icon
    }

    addItem() {  // list-select component
        this.baseService.authService.tag = this.paramItems; // {patient_id:this.paramItems.patient_id}
        this.baseService.authService.navigate([this.baseService.path, 0], null)

    }

    clickItemUser($e, item) {
        this.baseService.authService.navigate(['/user', item.user_id]);
        $e.stopPropagation();
    }


    protected _call_lookup:boolean = false;

    call_lookup() {  //used in SaveGuard
        return this._call_lookup;
    }

    routerData = [];

    back() {
        this.baseService.authService.routeData = this.routerData;
        this.baseService.authService.back();
    }

    //------------------------------------------------------------------------------------

    ngOnInit() {

        let routerData = this.baseService.authService.routeData;
        this.baseService.authService.routeData = null;

        if (routerData) {
            let data = routerData.shift();
            this.routerData = routerData;
            if (data) {
                //this.item = data.item;
                //this.savedItem = data.savedItem;
                this.isLoaded = true;
                return;
            }
        }

        this.getList();
    }

    open(link) {
        //let context = { data:1, item:this.parent.item, savedItem: this.parent.savedItem};
        let context = null;
        let data = [null,context].concat(this.routerData);
        this.parent._call_lookup = true;
        this.parent.baseService.authService.navigate(link,data);
    }

    clickItem(item) {


        if (this.routerData.length) {
            let key = this.routerData[0].key;
            let value = this.routerData[0].value;
            this.routerData[0].item[key] = item.id;
            this.routerData[0].item[value] = item;

            if (!item.name) {
                item.name = '#' + item.id
            }
            //console.log('101',this.routerData[0].item[key]);
            this.back();
        }
        else {
            let link = [this.baseService.path,item.id];
            //console.log(link)
            this.baseService.authService.tag = this.paramItems; // {patient_id:this.paramItems.patient_id}
            this.baseService.authService.navigate(link, null);
        }

    }

    clickAdd() {  // list-select component
        this.baseService.authService.tag = this.paramItems; // {patient_id:this.paramItems.patient_id}
        this.baseService.authService.navigate([this.baseService.path,0],null)

    }

     storageKey() {

        //let x = this.baseService.baseURL.split('/').slice(-1)[0];
        //return '-table-'+x;
        //return this.baseService.widgetName('detail');

        return this.widgetName;

    }

    get tagName () {
        return this.selector;
  //      return this.el.nativeElement.tagName.toLowerCase();
    }

    get widgetName() {
        return this.selector;
 //       return this.el.nativeElement.tagName.toLowerCase();

    }

    get isAdmin() {
        return this.baseService.authService.isAuthAdmin();
    }

    get isGuest () {
        return this.baseService.authService.isguest();
    }

    // +7--------------------------------------------------------------------


    get modified() {
        return '';
    }

    get loading() {
        return this.baseService.loading
    }

    get error() {
        return   this._error
    }


    acl(access) {
        return this.baseService.acl(access)
    }

    isDoctor(item) {
        return this.baseService.authService.isDoctor(item)
    }

    navigate($event,root,id) {
        $event.stopPropagation()
        if (id) {
            let arr = id.toString().split(',')
            if (arr.length) {
                let id0 = arr[0]
                this.baseService.authService.navigate([root, id0])
            }
        }
    }

    spec_index_key = ['data','_period',
        'current_page','from','last_page','next_page_url','path','per_page','prev_page_url','to','total']
    index: any = {}

    readIndex(result) {

        for (let key in result) {
            if (!this.spec_index_key.includes(key)) {
                this.index[key] = result[key];
            }
        }
       // console.log(this.index)
    }

    selectedTag() {
        this.getList()
    }


}