import { Component, forwardRef } from '@angular/core';
import { Output, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

const noop = () => {};

/*
export const EHIS_EMAIL_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EhisEmailComponent),
    multi: true
};
*/

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'ehis-email',
    template:
  ` <div class="form-group row">
        <label for="email" class="form-control-label col-md-4 col-xs-12 text-md-right"><ng-content></ng-content></label>
        <div class="col-md-8 col-xs-12">
        <input type="email"  id="email" class="form-control form-control-sm  pull-xs-left" placeholder="E-mail" [(ngModel)]="value"
                [readonly] = "readonly"
                data-parsley-trigger="change"
                data-parsley-required-message=""
                data-parsley-type-message="Should be valid email"
                required="required">
        </div>
    </div>
   `,

    providers: [
        //EHIS_EMAIL_CONTROL_VALUE_ACCESSOR
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EhisEmailComponent), multi: true },
    ]
})
export class EhisEmailComponent implements ControlValueAccessor {

//<input type="email"  id="email" class="form-control form-control-sm  pull-xs-left" [name]="name"  placeholder="E-mail" [(ngModel)]="value"

//    @Input() name: string ='email';

    @Input('readonly') readonly : boolean = false;

    //The internal data model
    private innerValue: any = '';

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled:boolean):void {    // new ???
    }

}
