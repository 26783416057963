import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Output, Input,ViewChild } from '@angular/core';
import { EventEmitter, ElementRef } from '@angular/core';
declare var jQuery: any;

import {BaseList}          from '../../auth/base-list'
import {MessageService}    from "../message.service"
import {MessageThreadService} from "../message-thread.service";

@Component({
  selector: 'user-thread-list',

  styleUrls: [ './user-thread-list.style.scss' ],
  templateUrl: './user-thread-list.template.html',

  encapsulation: ViewEncapsulation.None,
//  host: {
//    class: 'error-page app'
//  },
})
export class UserThreadList extends BaseList {

//  $el: any;

  @Output() onSelect = new EventEmitter();
  @Output() onUserClick = new EventEmitter();

  constructor(el: ElementRef,private ds: MessageThreadService) {
//    constructor(el: ElementRef,private messageService: MessageService) {

    super(el,ds);

//    this.user_id = messageService.authService.user_id();

//    this.$el = jQuery(el.nativeElement);
  }

  //---------------------------------------------------------------

  onReadParams(params) {
 //   params.__path__ = 'thread';
  }

  onReadItems(result) {
  }

  //---------------------------------------------------------------

  clickEventItem($event,item: any): void {
    this.onSelect.emit(item);
  }

  userClick($event,item) {
    $event.stopPropagation();
    this.onUserClick.emit(item.conto_user);
//    this.userInfo.open(item.conto_user.id);
  }

}
