import {Component, ViewEncapsulation, Input, ElementRef} from '@angular/core';

import {BaseList} from '../../auth/base-list';

import {InvoiceItemService} from "../invoice-item.service";

@Component({
  selector: 'invoice-item-table',
  templateUrl: './invoice-item-table.template.html',

  encapsulation: ViewEncapsulation.None,
})
export class InvoiceItemTable extends BaseList {


    constructor(el:ElementRef, private invoiceItemService:InvoiceItemService) {

        super(el,invoiceItemService);
    }
/*
    clickRadio1(val) {

        if (val != this.radio1) {
            this.radio1 = val;
            this.paramItems.state = val;
            this.updateSearchControl();
        }
    }
*/
}
