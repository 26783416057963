import {Directive, ElementRef, OnInit, Input, Output,EventEmitter} from '@angular/core';

declare var $:any;

//import * as Inputmask from "inputmask"

@Directive({
  selector: '[saUiDatepicker]'
})
export class UiDatepickerDirective implements OnInit {

  @Input() saUiDatepicker:any;
  @Output() dateChanged = new EventEmitter();

  nativeElem: any;

  constructor(private el:ElementRef) {
    this.nativeElem = $(this.el.nativeElement);
  }

  ngOnInit() {
    let onSelectCallbacks = [];
    let saUiDatepicker = this.saUiDatepicker || {};
    let element = $(this.el.nativeElement);



    //element.change(()=>{
    //  console.log('changed',element.val())
    //})

    if (saUiDatepicker.minRestrict) {
      onSelectCallbacks.push((selectedDate)=> {
        $(saUiDatepicker.minRestrict).datepicker('option', 'minDate', selectedDate);
      });
    }
    if (saUiDatepicker.maxRestrict) {
      onSelectCallbacks.push((selectedDate)=> {
        $(saUiDatepicker.maxRestrict).datepicker('option', 'maxDate', selectedDate);
      });
    }

    //Let others know about changes to the data field
    onSelectCallbacks.push((selectedDate) => {
      element.triggerHandler("change");


   //   element.serValue(selectedDate)
   //   console.log('this',this)
      this.dateChanged.emit(selectedDate);
   //   console.log('change datapicker',selectedDate)

      let form = element.closest('form');

      if (typeof form.bootstrapValidator == 'function') {
        try {
          form.bootstrapValidator('revalidateField', element);
        } catch (e) {
          console.log(e.message)
        }
      }
    });

    let options = $.extend(saUiDatepicker, {
      prevText: '<i class="fa fa-chevron-left"></i>',
      nextText: '<i class="fa fa-chevron-right"></i>',
      //showOn: 'button',
      onSelect: (selectedDate) =>{
        this.dateChanged.emit(selectedDate);
        this.nativeElem.value = selectedDate;

        onSelectCallbacks.forEach((callback) =>{
          callback.call(callback, selectedDate)
        })
      },

   //   appendText: "(X+U-dd)"
    });

    element.datepicker(options);

    /*
     // element.mask('00/00/0000');
      Inputmask({ alias: 'datetime',
          inputFormat: "dddd, dd mmmm yyyy",
          displayFormat: "dddd, dd mmmm yyyy",
          greedy: true
          // inputFormat: 'dd mm yyyy',
          // clearIncomplete: true

      }).mask(this.el.nativeElement)  //element.inputmask('99-9999999')
    */

  }


}
