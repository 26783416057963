import { Directive, ElementRef, forwardRef,HostListener, Input,Output ,EventEmitter , Renderer2,OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms'

import 'rxjs/Rx';

import {MaskedInputDirective} from "angular2-text-mask/src/angular2TextMask"

declare var $:any;

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe.js';


@Directive({
     selector: '[dateMask]'
/*
    host: {
        '(input)': '_handleInput($event.target.value)',
        '(blur)': 'onTouched()',
        '(compositionstart)': '_compositionStart()',
        '(compositionend)': '_compositionEnd($event.target.value)'
    },
    selector: '[dateMask]',
    exportAs: 'dateMask',
    providers: [  { provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateMaskDirective),
    multi: true }
]
*/
})
export class DateMaskDirective { // } extends MaskedInputDirective  {

    private _defaultColor = 'red';
    private _defaultImgSrc = ''
    private _altImgSrc = ''

    @Output() onImageLoaded = new EventEmitter();

    @Input('dateMask') dateMask: any;

    private textMask : any;
/*
    constructor(private renderer: Renderer2, private el: ElementRef) {

 //   constructor(private el: ElementRef) {

        super(renderer,el,false);

        this.textMaskConfig = this._dateMask;

      //  $(this.el.nativeElement).placeholder=
      //  console.log('date-mask onCreate')

    }
*/
      constructor(private el: ElementRef) {

         // this.el.nativeElement.text_mask = this._dateMask;
      }

    ngOnInit() {

 //       let el = this.inputElement;

        const dateFmt = "dd/mm/yyyy";

        const $el = $(this.el.nativeElement);

        this.el.nativeElement.text_mask = this._dateMask;


        $el.attr('placeholder',dateFmt); // == this.el.nativeElement.placeholder= "xxxxx"

        $el.attr('data-parsley-trigger','change')
        $el.attr('data-parsley-required-message','')
        $el.attr('data-parsley-minlength','8')
        $el.attr('data-parsley-date','3')
        $el.attr('data-parsley-error-message','Should be valid date '+dateFmt)


        //$el.textMaskInputElement("{mask: dateMask,  keepCharPositions: true, pipe: autoCorrectedDatePipe}");

        //console.log(this.el.nativeElement)
        //$el.attr('textMask',"{mask: this.date_Mask,  keepCharPositions: true, pipe: this.autoCorrectedDatePipe}")
        //console.log('date-mask onInit')
    }

    @Input() set defaultColor(colorName: string){
        this._defaultColor = colorName || this._defaultColor;
    }

    @Input() set defaultImgSrc(url: string){
        this._defaultImgSrc = url;
    }

    @Input('alt-img-src') set altImgSrc(url: string){
        this._altImgSrc = url;
    }


/*    @Input('ehis-img-src') set ehis_img_src(value:string) {

       // map value ...
        if (value) {

            var i = value.lastIndexOf('/');
            if (i < 0) {
                i = value.lastIndexOf('\\');
            }

            let url = this.authService.getApiUrl() + '/api/image/' + value.slice(i + 1);

            this.authService.getBlob(url)
                .subscribe(
                (result)=> {
                    var blob = result.blob();

                    if (blob.size==0) {
                        this.el.nativeElement.src = this._altImgSrc;
                        this.onImageLoaded.emit('');
                    }
                    else {
                        var urlCreator = window.URL;//|| window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL(blob);

                        this.el.nativeElement.src = imageUrl;
                        this.onImageLoaded.emit(imageUrl);
                    }//console.log('emit',this.onImageLoaded);
                },
                (error)=> {
                    this.el.nativeElement.src = this._altImgSrc;
                    this.onImageLoaded.emit('');
                })
        }
        else {
            this.el.nativeElement.src = this._defaultImgSrc; // '';
        }
    }

*/

    public get autoCorrectedDatePipe(): any { return createAutoCorrectedDatePipe('dd/mm/yyyy'); }
    public get date_Mask(): any { return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; }

    public get _dateMask () {
        return {
            mask: this.date_Mask,
            keepCharPositions: true,
            pipe: this.autoCorrectedDatePipe
        }
    }


}
